import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody
} from 'reactstrap';

import Isvg from 'react-inlinesvg'

import FormBuilder from '../../components/forms/modalFormBuilder';
import { required } from '../../components/forms/validation';

import ListBuilder from '../../components/listBuilder';
import DeleteModal from '../../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import ErrorModal from '../../components/errorModal';
import moment from 'moment';

import Search from '../../components/search';
import addIcon from '../../assets/svg/add-icon.svg';
import view from '../../assets/svg/eye.svg'

import profileUser from '../../assets/images/user.png';
import notification from '../../assets/svg/notification.svg'
import moreIcon from '../../assets/svg/more.svg'
import exportIcon from '../../assets/svg/export.svg';
import starIcon from '../../assets/svg/star.svg';
import fullStarIcon from '../../assets/svg/full-star.svg';

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

/**
* Video call ratings list
* @author   Milan Stanojevic
*/
class VideoCallRatings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0,
                sortField: 'timestamp',
                sortType: -1
            },
            items: [],
            total: 0,
            loading: true,
            selectedItems: {}
        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, {
                entries: 10, page: 0, sortField: 'timestamp',
                sortType: -1
            })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, {
                entries: 10, page: 0, sortField: 'timestamp',
                sortType: -1
            })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }
    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                    sortField: 'timestamp',
                    sortType: -1
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                    sortField: 'timestamp',
                    sortType: -1
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }


    getParent = (items, logId) => {
        for (let i = 0; i < items.length; i++) {
            if (items[i].id == logId) {
                return items[i];
            }
        }

        return null;
    }



    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }
        return (
            <div className="dashboard">
                <a ref={(node) => this.downloadTag = node} style={{ opacity: 0 }}>download</a>
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="panel">
                                <div className="panel-header">
                                    <h5 className="component-header">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h5>

                                </div>
                                <ListBuilder
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'name', label: 'User'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'rating', label: 'Rating'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'comment', label: 'Comment'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'socketId', label: 'SocketID'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'timestamp', label: 'Time'.translate(this.props.lang), allowSort: true },

                                    ]}
                                    rawItems={this.state.items}
                                    items={this.state.items.map(item => {
                                        return {
                                            ...item,
                                            timestamp: moment.unix(Math.floor(item.timestamp / 1000)).format(`${this.props.dateFormat} HH:mm:ss`),
                                            comment: item.comment && item.comment.length > 50 ? item.comment.substring(0, 50) + '...' : item.comment,
                                        }
                                    })}
                                    actions={
                                        [
                                            {
                                                component: <Isvg src={exportIcon} className="export-icon video-call-export-icon" />,
                                                onClick: (item) => {
                                                    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(item.log, null, 4));
                                                    this.downloadTag.setAttribute("href", dataStr);
                                                    this.downloadTag.setAttribute("download", "log.json");
                                                    this.downloadTag.click();

                                                }
                                            },

                                            {
                                                component: <Isvg src={view} className="view-icon" />,
                                                onClick: (item) => this.setState({ logView: item, selectedItems: {} })
                                            },
                                        ]
                                    }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                            </div>
                        </Col>
                    </Row>

                </Container>
                {
                    this.state.logView ?
                        <Modal isOpen={this.state.logView} centered toggle={() => this.setState({ logView: !this.state.logView })} size="xl" className="clinic-modal">
                            <ModalHeader toggle={() => this.setState({ logView: !this.state.logView })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ logView: !this.state.logView })}>&times;</button>}>{'Preview rating'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody>
                                <Row>
                                    <Col lg="6">
                                        <p><strong>{'SocketID:'.translate(this.props.lang)}</strong> {this.state.logView.socketId}</p>

                                        <p><strong>{'User:'.translate(this.props.lang)}</strong> {this.state.logView.name}</p>
                                        <p style={{ display: 'flex' }}><strong>{'Rating:'.translate(this.props.lang)}</strong> <div className="rating-stars">
                                            {this.state.logView.rating && this.state.logView.rating >= 1 ? <Isvg src={fullStarIcon} /> : <Isvg src={starIcon} />}
                                            {this.state.logView.rating && this.state.logView.rating >= 2 ? <Isvg src={fullStarIcon} /> : <Isvg src={starIcon} />}
                                            {this.state.logView.rating && this.state.logView.rating >= 3 ? <Isvg src={fullStarIcon} /> : <Isvg src={starIcon} />}
                                            {this.state.logView.rating && this.state.logView.rating >= 4 ? <Isvg src={fullStarIcon} /> : <Isvg src={starIcon} />}
                                            {this.state.logView.rating && this.state.logView.rating >= 5 ? <Isvg src={fullStarIcon} /> : <Isvg src={starIcon} />}

                                        </div>

                                        </p>
                                        <p><strong>{'Comment:'.translate(this.props.lang)}</strong> {this.state.logView.comment}</p>
                                        <br />
                                        <p><strong>{'Time:'.translate(this.props.lang)}</strong> {moment.unix(Math.floor(this.state.logView.timestamp / 1000)).format(`${this.props.dateFormat} HH:mm:ss`)}</p>

                                        <br />


                                    </Col>
                                    <Col lg="6">
                                        <pre>{JSON.stringify(this.state.logView.browser, null, 4)}</pre>
                                    </Col>
                                    <Col lg="12">
                                        <ul className="video-call-log-view">
                                            {
                                                this.state.logView.log.map((item, idx) => {
                                                    return (
                                                        <li className={this.state.selectedItems[idx] ? 'active' : ''}>
                                                            <div className={`head ${item.type == 'receive' ? 'receive' : 'emit'}`} onClick={() => {
                                                                let selectedItems = this.state.selectedItems;
                                                                selectedItems[idx] = !selectedItems[idx];
                                                                this.setState({
                                                                    selectedItems
                                                                })
                                                            }}>
                                                                <span className="event-name">{item.event}</span>
                                                                {item.logId && this.getParent(this.state.logView.log, item.logId) ?
                                                                    <span className="peers"> {this.getParent(this.state.logView.log, item.logId).socketId} → {item.socketId}</span>
                                                                    :
                                                                    <span className="peers"> {item.socketId}</span>

                                                                }

                                                                <span className="time">{moment(item.timestamp).format('HH:mm:ss.SSS')}</span>
                                                            </div>
                                                            <div className="content">
                                                                <pre>
                                                                    {JSON.stringify(item, null, 4)}
                                                                </pre>
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </Col>
                                </Row>
                            </ModalBody>
                        </Modal>
                        :
                        null
                }


            </div>
        );
    }
}

export default Page(VideoCallRatings);