import React, { Component } from 'react';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import {
    Button,
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row
} from 'reactstrap';
import { API_ENDPOINT } from '../../constants';
import Page from '../../containers/page';
import { getSearchParams } from '../../helpers/linkHelper';

import moment from 'moment';
import Attach from '../../assets/svg/attach.svg';
import copyIcon from '../../assets/svg/copy-link-icon.svg';
import trashIcon from '../../assets/svg/garbage.svg';
import infoIcon from '../../assets/svg/info-icon.svg';
import ErrorModal from '../../components/errorModal';
import Checkbox from '../../components/forms/fields/checkbox';
import DatePicker from '../../components/forms/fields/datePicker';
import Radio from '../../components/forms/fields/radio';
import TimeZonePicker from '../../components/forms/fields/timeZonePicker';
import InfoModal from '../../components/infoModal';
// import TimePicker from 'react-time-picker';
import * as XLSX from "xlsx";
import xIcon from '../../assets/svg/x.svg';
// or you can use classic theme
import 'react-times/css/classic/default.css';
import SelectWithSearch from '../../components/forms/fields/selectWithSearch';


function parseJSON(response) {
    return response.json().then((json) => {
        return {
            result: json,
            status: response.status,
        };
    });
}

function convertFrom24To12StringFormat(time24) {
    if (time24) {
        let sHours = time24.split(":")[0]
        let minutes = time24.split(":")[1]
        // const [sHours, minutes] = time24.match(timeHs:timeMin).slice(1);
        const period = +sHours < 12 ? 'AM' : 'PM';
        const hours = +sHours % 12 || 12;

        return `${hours}:${minutes}`;
    }
}

class ScheduleMeeting extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            form: {
                topic: '',
                inviteLink: [],
                cohosts: [],
                namesCohosts: [],
                inviteParticipants: false,
                addCoHostsToggle: false,
                whenDate: '04/15/2022',
                timezone: null,
                recurring: false,
                passcode: false,
                mettingId: 521512,
                muteEntryId: false,
                record: false,
                bankid: false,
                passcodeCheckbox: false,
                planType: false,
                dateBy: true,
                dateAfter: false,
                invitedUsers: [],
                choosenAddress: null,
                participants: null,
                durationHs: null,
                durationMin: null,
                dateTime: null,
                dateType: null,
                recurrence: null,
                repeat: null,
                scheduleDate: null,
                scheduleEndDate: null,
                scheduleEndDateAfter: false,
                coppiedInv: false,
                checkMon: false,
                checkTue: false,
                checkWed: false,
                checkThu: false,
                checkFri: false,
                checkSat: false,
                checkSun: false,
            },
            error: {
                topic: null,
                inviteParticipants: null,
                when: null,
                duration: null,
                timeZone: null,
                repeatEveryDay: null,
                repeatEveryWeek: null,
                repeatEveryMonth: null,
                endDate: null,
                email: null
            },
            selectHours: ['12', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11'],
            selectMinutes: ['00', '15', '30', '45']
        };

        this.state.min = new Date();
        this.state.min.setHours(0, 0, 0)
        this.state.max = new Date();
        this.state.max.setHours(23, 59, 59)
    }



    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }
        let arr1 = [];

        for (let i = 0; i < 24; i++) {
            let obj = {};
            obj = {
                hour: i
            }

            arr1.push(obj);

        }

        this.setState({ hours: arr1 });

        setTimeout(() => {

            if (this.props[0].location.state && this.props[0].location.state.form) {
                let scheduledTime = ''
                if (this.props[0].location.state.form && this.props[0].location.state.form.scheduledTime) {
                    let time = this.props[0].location.state.form.scheduledTime
                    scheduledTime = time.split(':')[0] < 10 ? '0' + time : time
                }

                this.setState({
                    form: { ...this.props[0].location.state.form, scheduledTime: scheduledTime }
                }, () => {
                    this.props[0].history.push({
                        state: {}
                    })
                })
            } else if (!this.props[0].location.state || !this.props[0].location.state.form) {
                fetch(API_ENDPOINT + '/data/users/schedule/meeting', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(res => res.json()).then((result) => {
                    if (result) {
                        let scheduleEndDate = new Date()
                        scheduleEndDate.setDate(scheduleEndDate.getDate() + 7)

                        let scheduledTime = new Date()
                        scheduledTime = scheduledTime.getTime()
                        let hours = new Date(scheduledTime).getHours()
                        let minutes = new Date(scheduledTime).getMinutes()

                        if (minutes < 15) {
                            minutes = '15'
                        } else if (minutes < 30) {
                            minutes = '30'
                        } else if (minutes < 45) {
                            minutes = '45'
                        } else if (minutes > 45) {
                            hours = `${Number(hours) + 1}`
                            minutes = '00'
                        }
                        if (hours == '13') {
                            hours = '01'
                        }
                        let time = `${hours}:${minutes}`
                        let time12 = convertFrom24To12StringFormat(time);
                        scheduledTime = time12.split(':')[0] < 10 ? '0' + time12 : time12
                        this.setState({
                            form: { conversationID: result.conversationID, inviteLink: `${window.location.origin}/${result.smallLink}`, longLink: `${window.location.origin}/video-call/${result.conversationID}?roomId=${result.roomId}`, dateBy: true, scheduleEndDate: moment(new Date()).format('YYYY-MM-DD'), scheduledDate: moment(new Date()).format("YYYY-MM-DD"), scheduledTime: scheduledTime, dateType: Number(new Date().getHours()) >= 12 ? 'PM' : 'AM' },
                        })
                    }
                })
            }
        }, 500);

        this.get();
    }

    showCopiedLink = () => {
        this.setState({
            linkCopied: true
        })
        setTimeout(() => {
            this.setState({
                linkCopied: false
            })
        }, 1000)
    }


    validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    setTopic = (e) => {
        let form = this.state.form
        form.name = e.target.value

        let error = this.state.error;
        if (e.target.value && error.topic) {
            error.topic = null;
            this.setState({ error })
        }

        this.setState({
            form
        })
    }

    copyInvite = () => {
        this.showCopiedLink()
        navigator.clipboard.writeText(this.state.form.inviteLink);
    }

    toggleInviteParticipants = () => {
        let form = this.state.form
        form.inviteParticipants = !this.state.form.inviteParticipants;

        this.setState({
            form
        })
        let error = this.state.error;
        if (error.inviteParticipants) {
            error.inviteParticipants = null;
            this.setState({ error })
        }
    }

    renderStoredAddresses = (e) => {
        let form = this.state.form;
        form.choosenAddress = e.target.value,

            this.setState({
                form
            })
    }

    usersInvited = (e) => {

        let form = this.state.form;
        form.invitedEmails = e

        this.setState({
            form
        })

    }

    usersInvitedEnter = (invitedEmails) => {
        let emails = invitedEmails.split(/[;,]+/)
        for (let i = 0; i < emails.length; i++) {
            let form = this.state.form;
            var storedUsers = [];
            if (form && form.invitedUsers) {
                storedUsers = [...form.invitedUsers]
            }
            if (storedUsers && storedUsers.length >= 1) {
                if (storedUsers.findIndex(item => item == emails[i].trim()) == -1)
                    if (this.validateEmail(emails[i].trim())) {
                        storedUsers.push(emails[i].trim())
                        this.setState({
                            error: ''
                        })
                    } else {
                        let error = this.state.error
                        error.email = 'Email address is not valid'.translate(this.props.lang)
                        this.setState({
                            error
                        })
                    }

            } else {
                if (this.validateEmail(emails[i].trim())) {
                    storedUsers.push(emails[i].trim())
                    this.setState({
                        error: ''
                    })
                } else {
                    let error = this.state.error
                    error.email = 'Email address is not valid'.translate(this.props.lang)
                    this.setState({
                        error
                    })
                }

            }
            form.invitedUsers = [...storedUsers]
            form.invitedEmails = ''
            this.setState({
                form
            })

        }
        // if (this.validateEmail(email)) {
        //     let arr = []
        //     arr.push(email)
        //     let form = this.state.form;
        //     if (form.invitedUsers && form.invitedUsers.length) {
        //         form.invitedUsers = [...form.invitedUsers, ...arr]
        //     } else {
        //         form.invitedUsers = arr
        //     }
        //     console.log(form.invitedUsers);
        //     form.invitedEmails = ''
        //     this.setState({
        //         form
        //     })
        // } else {
        //     let error = this.state.error
        //     error.email = 'Email address is not valid'.translate(this.props.lang)
        //     this.setState({
        //         error
        //     })

        // }
    }

    storeUsersInvitiation = (invitedEmails) => {
        let emails = invitedEmails.split(/[;,]+/)
        for (let i = 0; i < emails.length; i++) {
            let form = this.state.form;
            var storedUsers = [];
            if (form && form.invitedUsers) {
                storedUsers = [...form.invitedUsers]
            }
            if (storedUsers && storedUsers.length >= 1) {
                if (storedUsers.findIndex(item => item == emails[i].trim()) == -1)
                    if (this.validateEmail(emails[i].trim())) {
                        storedUsers.push(emails[i].trim())
                        this.setState({
                            error: ''
                        })
                    } else {
                        let error = this.state.error
                        error.email = 'Email address is not valid'.translate(this.props.lang)
                        this.setState({
                            error
                        })
                    }

            } else {
                if (this.validateEmail(emails[i].trim())) {
                    storedUsers.push(emails[i].trim())
                    this.setState({
                        error: ''
                    })
                } else {
                    let error = this.state.error
                    error.email = 'Email address is not valid'.translate(this.props.lang)
                    this.setState({
                        error
                    })
                }

            }
            form.invitedUsers = [...storedUsers]
            form.invitedEmails = ''
            this.setState({
                form
            })

        }
        // if (!this.validateEmail(invitedEmails)) {
        //     let error = this.state.error
        //     error.email = 'Email address is not valid'.translate(this.props.lang)
        //     this.setState({
        //         error
        //     })
        // } else {
        //     if (invitedEmails) {
        //         invitedEmails = invitedEmails.trim();
        //     }
        //     let form = this.state.form;
        //     var storedUsers = [];
        //     if (form && form.invitedUsers) {
        //         storedUsers = [...form.invitedUsers]
        //     }
        //     if (storedUsers && storedUsers.filter(el => el == invitedEmails).length == 0) {
        //         if (this.validateEmail(invitedEmails.trim())) {
        //             storedUsers.push(invitedEmails);
        //         } else if (invitedEmails.indexOf(';') !== -1 || invitedEmails.indexOf(',') !== -1) {

        //             let emails = invitedEmails.split(/[;,]+/)
        //             // console.log(emails);
        //             for (let i = 0; i < emails.length; i++) {
        //                 if (storedUsers && storedUsers.length >= 1) {
        //                     if (storedUsers.findIndex(item => item == emails[i]) == -1)
        //                         storedUsers.push(emails[i])
        //                 } else {
        //                     storedUsers.push(emails[i])
        //                 }
        //             }
        //         }

        //         form.invitedUsers = [...storedUsers]
        //         form.invitedEmails = ''
        //         this.setState({
        //             form
        //         })
        //     } else {
        //         form.invitedEmails = ''
        //         this.setState({
        //             form
        //         })
        //     }

        // }
    }

    setParticipants = (e) => {
        let form = this.state.form;
        form.participants = e.target.value;

        this.setState({
            form
        })
    }

    toggleAddCoHosts = () => {
        let form = this.state.form
        form.addCoHostsToggle = !this.state.form.addCoHostsToggle;

        this.setState({
            form
        })
        let error = this.state.error;
        if (error.addCoHostsToggle) {
            error.addCoHostsToggle = null;
            this.setState({ error })
        }
    }

    copyParticipants = () => {
        navigator.clipboard.writeText(this.state.form.inviteLink);
    }

    chooseDurationHours = (e) => {
        // console.log(e.target.value, "hr");
        let form = this.state.form;
        if (this.props.uData && (!this.props.uData.licenceObj || (this.props.uData.licenceObj && this.props.uData.licenceObj.licenceType == 'basic')) && this.props.uData.activeLicence && this.props.uData.activeLicence == 'basic') {
            form.durationHs = '0'
        } else {
            form.durationHs = e.target.value;
            let error = this.state.error;
            if (e.target.value && e.target.value != '0' && error.duration) {
                error.duration = null;
                this.setState({ error })
            }
        }

        this.setState({
            form
        })


    }

    chooseDurationMinutes = (e) => {
        let form = this.state.form;
        if (this.props.uData && (!this.props.uData.licenceObj || (this.props.uData.licenceObj && this.props.uData.licenceObj.licenceType == 'basic')) && this.props.uData.activeLicence && this.props.uData.activeLicence == 'basic') {
            let value = e.target.value;
            if (value == '0' || value == '5' || value == '10' || value == '15') {
                form.durationMin = value;
            } else {
                form.durationMin = '20'
            }

        } else {
            form.durationMin = e.target.value;
        }
        // form.durationMin = e.target.value;

        this.setState({
            form
        })

        let error = this.state.error;
        if (e.target.value && e.target.value != '0' && error.duration) {
            error.duration = null;
            this.setState({ error })
        }
    }

    scheduleDate = (e) => {
        // console.log(e);
        let form = this.state.form;
        form.scheduledDate = e

        this.setState({
            form
        })

        let error = this.state.error;
        if (error.when) {
            error.when = null;
            this.setState({ error })
        }
        // console.log(e)
    }

    chooseDateType = (e) => {
        let form = this.state.form;
        form.dateType = e;

        this.setState({
            form
        })

        this.forceUpdate()
    }

    selectedTimezone = (e) => {
        let form = this.state.form;
        form.timezone = e

        this.setState({
            form
        })

        let error = this.state.error;
        if (e && error.timeZone) {
            error.timeZone = null;
            this.setState({ error })
        }
    }

    toggleRecuirMeeting = () => {

        let scheduleEndDate = new Date()
        scheduleEndDate.setDate(scheduleEndDate.getDate() + 1)
        // console.log(scheduleEndDate);

        let form = this.state.form;
        form.recurring = !this.state.form.recurring
        if (form && form.recurring && !form.recurringObj) {
            form.recurringObj = {}
            form.recurringObj.recurrence = 'Daily'
            form.recurringObj.repeat = 1
            form.scheduleEndDate = moment(scheduleEndDate).format('YYYY-MM-DD')
        }


        this.setState({
            form
        })
    }

    recurrenceType = (e) => {
        // console.log(e);
        let scheduleEndDate = new Date()
        if (e && e == 'Weekly') {
            scheduleEndDate.setDate(scheduleEndDate.getDate() + 7)
        } else if (e && e == 'Monthly') {
            scheduleEndDate.setDate(scheduleEndDate.getDate() + 30)
        }


        let form = this.state.form;
        if (form && form.recurringObj) {
            form.recurringObj.recurrence = e;
            // if (e && e == 'Weekly') {
            //     if (!form.recurringObj.checkMon && !form.recurringObj.checkTue && !form.recurringObj.checkWed && !form.recurringObj.checkThu && !form.recurringObj.checkFri && !form.recurringObj.checkSat && !form.recurringObj.checkSun) {
            //         form.recurringObj.checkMon = true;
            //     }
            // }
        }

        form.scheduleEndDate = moment(scheduleEndDate).format('YYYY-MM-DD')
        this.setState({
            form
        })
    }

    repeatType = (e) => {

        let form = this.state.form;
        if (form && form.recurringObj)
            form.recurringObj.repeat = e;

        this.setState({
            form
        })

        let error = this.state.error;
        if (e && error.repeatEveryDay) {
            error.repeatEveryDay = null;
            this.setState({ error })
        }
    }

    dateByPicker = () => {
        let form = this.state.form;
        form.dateBy = !this.state.form.dateBy;
        form.dateAfter = !this.state.form.dateAfter;

        this.setState({
            form
        })
    }

    dateAfterPicker = () => {
        let form = this.state.form;
        form.dateAfter = !this.state.form.dateAfter;
        form.dateBy = !this.state.form.dateBy

        if (!form.scheduleDateAfter) {
            form.scheduleDateAfter = 1;
        }

        this.setState({
            form
        })
    }

    scheduleEndDate = (e) => {
        let form = this.state.form;
        form.scheduleEndDate = e;

        this.setState({
            form
        })

        let error = this.state.error;
        if (error.endDate) {
            error.endDate = null;
            this.setState({ error })
        }
    }

    scheduleEndDateAfter = (e) => {

        let form = this.state.form;
        form.scheduleDateAfter = e

        this.setState({
            form
        })

        let error = this.state.error;
        if (error.endDate) {
            error.endDate = null;
            this.setState({ error })
        }
    }

    toggleEntryId = () => {
        let form = this.state.form;
        form.muteParticipants = !this.state.form.muteParticipants

        this.setState({
            form
        })
    }

    toggleRecord = () => {
        let form = this.state.form;
        form.recordConversationAsVideo = !this.state.form.recordConversationAsVideo

        this.setState({
            form
        })
    }

    toggleRecordMembers = () => {
        let form = this.state.form;
        form.recordConversationAsVideoMembers = !this.state.form.recordConversationAsVideoMembers

        this.setState({
            form
        })
    }



    toggleBankid = () => {
        let form = this.state.form;
        form.requireBankID = !this.state.form.requireBankID
        if (!form.requireBankID) {
            form.mandatoryScreenName = false
        }
        this.setState({
            form
        })
    }

    toggleNetID = () => {
        let form = this.state.form;
        form.requireNetID = !this.state.form.requireNetID

        this.setState({
            form
        })
    }

    mandatoryScreenName = () => {
        let form = this.state.form;
        form.mandatoryScreenName = !this.state.form.mandatoryScreenName

        this.setState({
            form
        })
    }

    togglePasscode = (e) => {
        let form = this.state.form;
        form.passcodeCheckbox = e.target.checked,

            this.setState({
                form
            })
    }

    setPasscodeForm = () => {
        this.setState({
            form: true
        })
    }

    previewInvitation = () => {
        this.setState({
            openPreviewInvite: true,
            previewInvite: this.state.form.invitedUsers
        })
    }



    removeItemOnce = (arr, value) => {
        var index = arr.indexOf(value);
        if (index > -1) {
            arr.splice(index, 1);
        }
        this.setState({
            previewInvite: arr
        })
    }


    removeItemAll = (arr) => {
        var i = 0;
        while (i < arr.length) {
            if (arr[i]) {
                arr.splice(i, 1);
            } else {
                ++i;
            }
        }

        this.setState({
            previewInvite: arr,
            openPreviewInvite: false
        })

    }

    importFromXlsx = (e) => {
        var file = e
        this.readExcel(file)
    }

    readExcel = (file) => {
        let form = this.state.form;
        var emailsImport = []
        // console.log(file);
        const fileReader = new FileReader()
        fileReader.readAsArrayBuffer(file)

        fileReader.onload = (e) => {
            const bufferArray = e.target.result;
            const wb = XLSX.read(bufferArray, { type: "binary" });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

            for (let i = 0; i < data.length; i++) {

                for (var key in data[i]) {
                    emailsImport.push(data[i][key])
                }
            }

            if (form.invitedUsers && form.invitedUsers.length) {
                form.invitedUsers = [...form.invitedUsers]
                for (let m = 0; m < emailsImport.length; m++) {
                    let index = form.invitedUsers.findIndex(item => item == emailsImport[m])
                    if (index === -1)
                        form.invitedUsers.push(emailsImport[m])

                }
            } else {
                form.invitedUsers = emailsImport
            }

            this.setState({
                form,
                previewInvite: form.invitedUsers
            }, () => {
                setTimeout(() => {
                    this.setState({
                        openPreviewInvite: this.state.previewInvite && this.state.previewInvite.length ? true : false
                    })
                }, 1000);
            })
        }
    }

    checkValidation = async () => {
        let error = {};

        let errorExist = false;
        let form = this.state.form;
        if (!form.durationHs) {
            form.durationHs = '0';
        }
        if (!form.durationMin) {
            form.durationMin = '0';
        }
        if (!form.timezone && form.timezone !== "") {
            form.timezone = this.props.uData.timezone ? this.props.uData.timezone : null;
        }
        let tsNow = Math.floor(new Date().getTime() / 1000);

        if (form) {
            if (!form.name) {
                errorExist = true;
                error.topic = 'Topic is required'.translate(this.props.lang);
            }
            if (!form.scheduledDate || !form.scheduledTime) {
                errorExist = true;
                error.when = 'Date is required'.translate(this.props.lang);
            } else if (form.scheduledDate && form.scheduledTime) {
                // console.log(form.scheduledTime);
                let time = form.scheduledTime
                if (Number(form.scheduledTime.split(':')[0]) > 12) {
                    time = `${Number(form.scheduledTime.split(':')[0]) - 12}` + ":" + `${Number(form.scheduledTime.split(':')[1])}`
                }

                // console.log(time, moment.unix(tsNow).format('hh:mm'));
                let selectedDate = new Date(`${form.scheduledDate.replace(/-/g, '/')} ${time}`)
                let selectedDateTs = Math.floor(new Date(selectedDate).getTime() / 1000)
                if (form.dateType == 'PM' && selectedDateTs && form.scheduledTime.split(':')[0] != '12') {
                    selectedDateTs += 60 * 60 * 12
                }
                // console.log(selectedDateTs - tsNow);
                if (tsNow > selectedDateTs) {
                    errorExist = true;
                    error.when = 'The date must be in the future'.translate(this.props.lang);
                }
            }

            if (form.durationHs === '0' && form.durationMin === '0') {
                errorExist = true;
                error.duration = 'Duration is required'.translate(this.props.lang);
            }
            if (!form.timezone) {
                errorExist = true;
                error.timeZone = 'Timezone must be selected'.translate(this.props.lang);
            }
            if (form.inviteParticipants && (!form.invitedUsers || (form.invitedUsers && !form.invitedUsers.length))) {
                errorExist = true;
                error.inviteParticipants = 'Participants email address is required if this is selected'.translate(this.props.lang);
            }
            if (form.recurring) {
                if (form.recurringObj) {
                    if (form.recurringObj.recurrence == 'Daily' && !form.recurringObj.repeat) {
                        errorExist = true;
                        error.repeatEveryDay = 'Repeat every is required'.translate(this.props.lang);
                    }
                    if (form.recurringObj.recurrence == 'Weekly') {
                        let checkedDays = [];
                        if (form.recurringObj.checkMon) { checkedDays.push('Mon'.translate(this.props.lang)) }
                        if (form.recurringObj.checkTue) { checkedDays.push('Tue'.translate(this.props.lang)) }
                        if (form.recurringObj.checkWed) { checkedDays.push('Wed'.translate(this.props.lang)) }
                        if (form.recurringObj.checkThu) { checkedDays.push('Thu'.translate(this.props.lang)) }
                        if (form.recurringObj.checkFri) { checkedDays.push('Fri'.translate(this.props.lang)) }
                        if (form.recurringObj.checkSat) { checkedDays.push('Sat'.translate(this.props.lang)) }
                        if (form.recurringObj.checkSun) { checkedDays.push('Sun'.translate(this.props.lang)) }
                        // console.log(checkedDays)
                        if (checkedDays && !checkedDays.length) {
                            errorExist = true;
                            error.repeatEveryWeek = 'Repeat every is required, at least one day must be chosen'.translate(this.props.lang);
                        }

                    }
                    if (form.recurringObj.recurrence == 'Monthly' && !form.recurringObj.frequencyMonthScheduled) {
                        errorExist = true;
                        error.repeatEveryMonth = 'Repeat every is required'.translate(this.props.lang);
                    }
                    if (form.recurringObj.recurrence == 'Daily' || form.recurringObj.recurrence == 'Weekly' || form.recurringObj.recurrence == 'Monthly') {
                        if (form.dateBy) {
                            let selectedDate = new Date(`${form.scheduledDate.replace(/-/g, '/')} ${form.scheduledTime}`)
                            let selectedDateTs = Math.floor(new Date(selectedDate).getTime() / 1000)

                            let scheduleEndDate = new Date(`${form.scheduleEndDate.replace(/-/g, '/')} 23:59`)
                            let scheduleEndDateTs = Math.floor(new Date(scheduleEndDate).getTime() / 1000)
                            if (selectedDateTs > scheduleEndDateTs) {
                                errorExist = true;
                                error.endDate = 'The end date must be greater than selected date'.translate(this.props.lang);
                            }
                        } else {
                            if (!form.scheduleDateAfter) {
                                errorExist = true;
                                error.endDate = 'After field must be at least 1'.translate(this.props.lang);
                            }
                        }
                    }
                }
            }

        } else {
            errorExist = true;
        }
        if (JSON.stringify(this.state.error) != JSON.stringify(error)) {
            this.setState({ error })
        }



        return !errorExist;
    }

    connectWithOutlook = (data) => {
        fetch(API_ENDPOINT + "/auth/callback", {
            method: "POST",
            headers: {
                "content-type": "application/json",
                Authorization: typeof localStorage !== "undefined" ? `Bearer ${localStorage.getItem("authToken")}` : null,
            },
            body: JSON.stringify({
                conversation: data,
            })
        }).then((res) => res.json()).then((result) => {
            if (result && result.error) {
                this.setState({
                    error: result.error
                })
            } else {

                data.linkOutlookFile = result.link
                this.insertOrUpdate(data)

            }
        });
    }

    insertOrUpdate = async (data) => {
        console.error(data)
        this.setState({ formSubmited: true })

        let check = await this.checkValidation()
        if (!check) {
            this.setState({
                errorModal: true
            })
            return
        }

        if (!data.timezone) {
            data.timezone = this.props.uData.timezone
        }

        if (data) {
            data.location = 'schedule-meetings'
        }
        if (this.state.form && this.state.form.passcode) {
            data.passcode = this.state.form.passcode
        }

        // if (data.invitedUsers && data.invitedUsers.length > 0) {
        //     // data.linkOutlookFile = await this.connectWithOutlook(data)
        // }

        this.setState({
            loading: true,
        }, () => {
            fetch(API_ENDPOINT + '/data/users/schedule/new/meeting', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (result.error) {
                    this.setState({ error: result.error })
                }
                this.setState({
                    saveOptions: false
                })
                this.get();
                this.props[0].history.push({
                    pathname: '/users/manage-meeting',
                    state: data
                })
                this.setState({
                    _saving: false
                }, () => {
                    setTimeout(() => {
                        this.setState({ _saving: false, form: null })

                    }, 1000)
                })
            })
        })
    }

    updateOneEvent = async (data) => {
        this.setState({ formSubmited: true })

        let check = await this.checkValidation()
        if (!check) {
            this.setState({
                errorModal: true
            })
            return
        }

        if (!data.timezone) {
            data.timezone = this.props.uData.timezone
        }

        if (data) {
            data.location = 'schedule-meetings'
        }
        if (this.state.form && this.state.form.passcode) {
            data.passcode = this.state.form.passcode
        }

        this.setState({
            loading: true,
        }, () => {
            fetch(API_ENDPOINT + '/data/users/schedule/update/event', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (result.error) {
                    this.setState({ error: result.error })
                }
                this.setState({
                    saveOptions: false
                })
                this.get();
                this.props[0].history.push({
                    pathname: '/users/manage-meeting',
                    state: data
                })
                this.setState({
                    _saving: false
                }, () => {
                    setTimeout(() => {
                        this.setState({ _saving: false, form: null })

                    }, 1000)
                })
            })
        })
    }

    handleDelete = i => {
        // console.log(i);

        if (this.state.form && this.state.form.invitedUsers.length && this.state.form.invitedUsers[i]) {

            let form = this.state.form
            //form.invitedEmails.splice(i, 1)
            form.invitedUsers.splice(i, 1)
            this.setState({
                form
            })
        }

    };

    handleDeleteCoHosts = i => {
        // console.log(i);

        if (this.state.form && this.state.form.namesCohosts.length && this.state.form.namesCohosts[i]) {

            let form = this.state.form
            //form.invitedEmails.splice(i, 1)
            form.namesCohosts.splice(i, 1)
            this.setState({
                form
            })
        }


        if (this.state.form && this.state.form.cohosts.length && this.state.form.cohosts[i]) {

            let form = this.state.form
            //form.invitedEmails.splice(i, 1)
            form.cohosts.splice(i, 1)
            this.setState({
                form
            })
        }

    };



    handleAddition = tag => {

        if (!this.validateEmail(tag.id)) {
            let error = this.state.error
            error.email = 'error'
            this.setState({
                error
            })
        } else if (tag.id.indexOf(';') !== -1 || tag.id.indexOf(',') !== -1) {

            let emails = tag.id.split(/[;,]+/)
            // for (let i = 0; i < emails.length; i++) {
            //     if (storedUsers && storedUsers.length >= 1) {
            //         if (storedUsers.findIndex(item => item == emails[i]) == -1)
            //             storedUsers.push(emails[i])
            //     } else {
            //         storedUsers.push(emails[i])
            //     }
            // }
        } else {
            let error = this.state.error
            error.email = null
            let form = this.state.form;
            let arr = []
            if (this.state.form && this.state.form.invitedEmails) {
                arr = [...this.state.form.invitedEmails]

            }
            if (arr.length) {
                const index = arr.findIndex(email => email.id == tag.id)
                if (!arr[index]) {
                    arr.push(tag)
                }
            } else {
                arr.push(tag)
            }

            form.invitedEmails = arr
            form.invitedUsers = arr.map(item => {
                return item.id
            })
            this.setState({
                form,
                error
            })
        }

    };


    handleDrag = (tag, currPos, newPos) => {
        const newTags = tags.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        // re-render
        setTags(newTags);
    };

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.usersInvitedEnter(e.target.value)

        }
    }


    render() {
        // console.log(this.state.form.scheduledTime);
        // console.log(this.state.form.scheduledDate);

        const KeyCodes = {
            comma: 188,
            enter: 13
        };

        const delimiters = [KeyCodes.comma, KeyCodes.enter];

        const { form, coppiedInv/*inviteParticipants, passcode, passcodeCheckbox, recuirMeeting, inviteLink, mettingId, muteEntryId, record, bankid, whenDate, planType, dateBy, dateAfter, storedUsers, timezone */ } = this.state;

        let checkedDays = [];
        let checkedDaysString = '';
        if (form && form.recurringObj && form.recurringObj.recurrence == 'Weekly') {
            if (form.recurringObj.checkMon) { checkedDays.push('Mon'.translate(this.props.lang)) }
            if (form.recurringObj.checkTue) { checkedDays.push('Tue'.translate(this.props.lang)) }
            if (form.recurringObj.checkWed) { checkedDays.push('Wed'.translate(this.props.lang)) }
            if (form.recurringObj.checkThu) { checkedDays.push('Thu'.translate(this.props.lang)) }
            if (form.recurringObj.checkFri) { checkedDays.push('Fri'.translate(this.props.lang)) }
            if (form.recurringObj.checkSat) { checkedDays.push('Sat'.translate(this.props.lang)) }
            if (form.recurringObj.checkSun) { checkedDays.push('Sun'.translate(this.props.lang)) }
            if (checkedDays && checkedDays.length) {
                checkedDaysString = checkedDays.join(', ')
            }
        }
        let frequencyMonthScheduled = '1st';
        if (form.recurringObj && form.recurringObj.frequencyMonthScheduled) {
            if (form.recurringObj.frequencyMonthScheduled == '1') { frequencyMonthScheduled = '1st'.translate(this.props.lang) }
            if (form.recurringObj.frequencyMonthScheduled == '2') { frequencyMonthScheduled = '2nd'.translate(this.props.lang) }
            if (form.recurringObj.frequencyMonthScheduled == '3') { frequencyMonthScheduled = '3rd'.translate(this.props.lang) }
            if (form.recurringObj.frequencyMonthScheduled == '4') { frequencyMonthScheduled = '4th'.translate(this.props.lang) }
            if (form.recurringObj.frequencyMonthScheduled == '5') { frequencyMonthScheduled = '5th'.translate(this.props.lang) }
            if (form.recurringObj.frequencyMonthScheduled == '6') { frequencyMonthScheduled = '6th'.translate(this.props.lang) }
            if (form.recurringObj.frequencyMonthScheduled == '7') { frequencyMonthScheduled = '7th'.translate(this.props.lang) }
            if (form.recurringObj.frequencyMonthScheduled == '8') { frequencyMonthScheduled = '8th'.translate(this.props.lang) }
            if (form.recurringObj.frequencyMonthScheduled == '9') { frequencyMonthScheduled = '9th'.translate(this.props.lang) }
            if (form.recurringObj.frequencyMonthScheduled == '10') { frequencyMonthScheduled = '10th'.translate(this.props.lang) }
            if (form.recurringObj.frequencyMonthScheduled == '11') { frequencyMonthScheduled = '11th'.translate(this.props.lang) }
            if (form.recurringObj.frequencyMonthScheduled == '12') { frequencyMonthScheduled = '12th'.translate(this.props.lang) }
            if (form.recurringObj.frequencyMonthScheduled == '13') { frequencyMonthScheduled = '13th'.translate(this.props.lang) }
            if (form.recurringObj.frequencyMonthScheduled == '14') { frequencyMonthScheduled = '14th'.translate(this.props.lang) }
            if (form.recurringObj.frequencyMonthScheduled == '15') { frequencyMonthScheduled = '15th'.translate(this.props.lang) }
            if (form.recurringObj.frequencyMonthScheduled == '16') { frequencyMonthScheduled = '16th'.translate(this.props.lang) }
            if (form.recurringObj.frequencyMonthScheduled == '17') { frequencyMonthScheduled = '17th'.translate(this.props.lang) }
            if (form.recurringObj.frequencyMonthScheduled == '18') { frequencyMonthScheduled = '18th'.translate(this.props.lang) }
            if (form.recurringObj.frequencyMonthScheduled == '19') { frequencyMonthScheduled = '19th'.translate(this.props.lang) }
            if (form.recurringObj.frequencyMonthScheduled == '20') { frequencyMonthScheduled = '20th'.translate(this.props.lang) }
            if (form.recurringObj.frequencyMonthScheduled == '21') { frequencyMonthScheduled = '21st'.translate(this.props.lang) }
            if (form.recurringObj.frequencyMonthScheduled == '22') { frequencyMonthScheduled = '22nd'.translate(this.props.lang) }
            if (form.recurringObj.frequencyMonthScheduled == '23') { frequencyMonthScheduled = '23rd'.translate(this.props.lang) }
            if (form.recurringObj.frequencyMonthScheduled == '24') { frequencyMonthScheduled = '24th'.translate(this.props.lang) }
            if (form.recurringObj.frequencyMonthScheduled == '25') { frequencyMonthScheduled = '25th'.translate(this.props.lang) }
            if (form.recurringObj.frequencyMonthScheduled == '26') { frequencyMonthScheduled = '26th'.translate(this.props.lang) }
            if (form.recurringObj.frequencyMonthScheduled == '27') { frequencyMonthScheduled = '27th'.translate(this.props.lang) }
            if (form.recurringObj.frequencyMonthScheduled == '28') { frequencyMonthScheduled = '28th'.translate(this.props.lang) }
            if (form.recurringObj.frequencyMonthScheduled == '29') { frequencyMonthScheduled = '29th'.translate(this.props.lang) }
            if (form.recurringObj.frequencyMonthScheduled == '30') { frequencyMonthScheduled = '30th'.translate(this.props.lang) }
            if (form.recurringObj.frequencyMonthScheduled == '31') { frequencyMonthScheduled = '31st'.translate(this.props.lang) }
        }


        let recurringLabel = '-';
        let recurringLabelFirstPart =
            form.recurringObj && form.recurringObj.recurrence == 'Daily' ?
                form.recurringObj.repeat && Number(form.recurringObj.repeat) == 1 ?
                    'Every day'.translate(this.props.lang)
                    :
                    form.recurringObj.repeat && Number(form.recurringObj.repeat) > 1 ?
                        ('Every'.translate(this.props.lang) + ` ${form.recurringObj.repeat} ` + 'days'.translate(this.props.lang))
                        :
                        ''
                :
                form.recurringObj && form.recurringObj.recurrence == 'Weekly' ?
                    ('Every'.translate(this.props.lang) + ' ' + checkedDaysString)
                    :
                    form.recurringObj && form.recurringObj.recurrence == 'Monthly' && form.recurringObj.frequencyMonthScheduled ?
                        ('Every'.translate(this.props.lang) + ` ${frequencyMonthScheduled} ` + 'of the month'.translate(this.props.lang))
                        :
                        ''

        let recurringLabelSecondPart =
            form.dateBy && form.scheduleEndDate ?
                ('until'.translate(this.props.lang) + ' ' + form.scheduleEndDate)
                :
                form.dateAfter && form.scheduleDateAfter ?
                    ('after'.translate(this.props.lang) + ` ${form.scheduleDateAfter} ` + 'occurrence(s)'.translate(this.props.lang))
                    :
                    '';


        if (recurringLabelFirstPart && recurringLabelSecondPart) {
            recurringLabel = recurringLabelFirstPart + ', ' + recurringLabelSecondPart;
        } else if (recurringLabelFirstPart && !recurringLabelSecondPart) {
            recurringLabel = recurringLabelFirstPart;
        }

        // console.log(form.invitedEmails);
        return (
            <div className="dashboard">
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className='panel schedule-meeting-page'>
                                <Row>
                                    <Col lg="12">
                                        <h2>{'Schedule Meeting'.translate(this.props.lang)}</h2>
                                    </Col>
                                    <Col lg="12">
                                        <div className='sm-row'>
                                            <Label className={this.state.error && this.state.error.topic ? 'field-label-validation field-label-validation-error' : 'field-label-validation'}>
                                                {'Topic'.translate(this.props.lang) + '*'}
                                                {this.state.error && this.state.error.topic ?
                                                    <div className='tooltip-wrap'>
                                                        <Isvg src={infoIcon} />
                                                        <div className='tooltip-text'>{this.state.error.topic}</div>
                                                    </div>
                                                    : null}
                                            </Label>
                                            <Input type='text' name='name' value={form.name} onChange={(e) => this.setTopic(e)} />
                                        </div>
                                        <div className='sm-row'>
                                            <Label>{'Invite link'.translate(this.props.lang)}</Label>
                                            <div className='invite-link-container'>


                                                <div className="tabel-action-tooltip action-inv-link">
                                                    <h6>{form.inviteLink ? form.inviteLink : ''}</h6>
                                                    {/* <div className="chat-icons-tooltip">
                                                        <h6>{form.inviteLink ? (form.inviteLink) : null}</h6>
                                                    </div> */}
                                                </div>

                                                <button className='copy-link-button' onClick={() => this.copyInvite()}>
                                                    <span><Isvg src={copyIcon} /></span>
                                                    <div className='flex'>{this.state.linkCopied ? 'Copied to clipboard'.translate(this.props.lang) : 'Copy link'.translate(this.props.lang)}</div>
                                                    {/* {coppiedInv ? <span className='coppied'>{'Copied to clipboard'.translate(this.props.lang)}</span> : null} */}
                                                </button>
                                            </div>
                                        </div>
                                        <div className='sm-row'>
                                            {/* <Label>{'Invite participants'.translate(this.props.lang)}</Label> */}
                                            <Label className={this.state.error && this.state.error.inviteParticipants ? 'field-label-validation field-label-validation-error' : 'field-label-validation'}>
                                                {'Invite participants'.translate(this.props.lang) + '*'}
                                                {this.state.error && this.state.error.inviteParticipants ?
                                                    <div className='tooltip-wrap'>
                                                        <Isvg src={infoIcon} />
                                                        <div className='tooltip-text'>{this.state.error.inviteParticipants}</div>
                                                    </div>
                                                    : null}
                                            </Label>
                                            <Checkbox onChange={() => this.toggleInviteParticipants()} checked={form.inviteParticipants} />
                                        </div>



                                        {form.inviteParticipants ? <div className='email-form-container'>
                                            <div className={this.state.error && this.state.error.email ? 'field-label-validation field-label-validation-error' : 'sm-row'} style={{ alignItems: 'baseline' }}>
                                                <Label style={this.state.error.email ? { display: 'flex', width: '220px', minWidth: '220px', alignItems: 'center', marginTop: '50px' } : { display: 'flex', width: '220px', minWidth: '220px', alignItems: 'center' }}>{'Enter e-mail address'.translate(this.props.lang)}
                                                    {this.state.error && this.state.error.email ?
                                                        <div className='tooltip-wrap'>
                                                            <Isvg src={infoIcon} />
                                                            <div className='tooltip-text'>{this.state.error.email}</div>
                                                        </div>
                                                        : null}
                                                </Label>
                                                <div className='email-form'>
                                                    <div className='email-form-row'>
                                                        {/* <ReactTags
                                                            classNames={{ tags: 'schedule-tag-wrapper', tagInput: 'schedule-tag-input', tagInputField: 'schedule-tag-input-field', selected: form.invitedEmails && form.invitedEmails.length ? 'schedule-selected' : 'schedule-none-selected', tag: 'schedule-tag' }}
                                                            tags={form.invitedEmails}
                                                            delimiters={delimiters}
                                                            handleDelete={this.handleDelete}
                                                            handleAddition={this.handleAddition}
                                                            placeholder=''
                                                            inputFieldPosition="top"
                                                        /> */}
                                                        <Input type='text' onKeyPress={this._handleKeyDown} placeholder={'Email address'.translate(this.props.lang)} value={form.invitedEmails} onChange={(e) => this.usersInvited(e.target.value)} />
                                                        <button className='add-emails-button' style={form.invitedEmails ? { backgroundColor: 'red' } : { background: 'primary' }} onClick={() => this.storeUsersInvitiation(this.state.form.invitedEmails)}>{'Add'.translate(this.props.lang)}</button>
                                                    </div>
                                                    {<div className={form.invitedUsers && form.invitedUsers.length ? 'email-list' : 'email-none-list'}>
                                                        {form.invitedUsers && form.invitedUsers.map((mail, idx) => {
                                                            return (
                                                                <div className='schedule-tag' key={idx}>
                                                                    <button>
                                                                        {mail}
                                                                        <span onClick={() => this.handleDelete(idx)}>x</span>
                                                                    </button>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>}
                                                    <div className='buy-button ip-buttons'>
                                                        <div className='custom-file-upload-container-wrap'>
                                                            <div className='custom-file-upload-container'>
                                                                <div className="upload-overlay">
                                                                    <Isvg src={Attach} />
                                                                    <p>
                                                                        <span>{'Upload a file'.translate(this.props.lang)}</span>
                                                                    </p>

                                                                </div>

                                                                <Input type='file' className='custom-file-upload' onChange={(e) => { this.importFromXlsx(e.target.files[0]) }}>{'Import form xls'.translate(this.props.lang)}</Input>
                                                            </div>
                                                            <div className='tooltip-wrap'>
                                                                <Isvg src={infoIcon} style={{ width: 20, marginLeft: 10 }} />
                                                                <div className='tooltip-text'>{'Upload a list of email addresses in xls format.'.translate(this.props.lang)}</div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            {/* <button style={form.invitedEmails ? { backgroundColor: 'red' } : { background: 'primary' }} onClick={() => this.storeUsersInvitiation(this.state.form.invitedEmails)}>{'Add emails'.translate(this.props.lang)}</button> */}
                                                            {/* <button style={{ background: '#E6EDF5', color: '#3A3F56' }} onClick={() => this.toggleInviteParticipants()}>{'Close'.translate(this.props.lang)}</button>
                                                            <button onClick={() => { this.previewInvitation() }}>{'Preview invitatios'.translate(this.props.lang)}</button> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className='add-participants-container'>
                                                <div className='add-participants'>

                                                    <div className='add-participants-row'>
                                                        <Input type='text' name='topic' value={form.inviteLink} onChange={(e) => this.setParticipants(e)} />
                                                        <p onClick={() => this.copyParticipants()}><Isvg src={copyIcon} /> <span>{'Copy link'.translate(this.props.lang)}</span></p>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div> : null}
                                        {(form && form.cohosts && form.cohosts.indexOf(this.props.uData._id) === -1) || (this.props.uData && this.props.uData.licenceObj && this.props.uData.activeLicence && this.props.uData.activeLicence !== 'basic') ?
                                            <div className='sm-row'>
                                                <Label className={this.state.error && this.state.error.addCoHostsToggle ? 'field-label-validation field-label-validation-error' : 'field-label-validation'}>
                                                    {'Add co-hosts'.translate(this.props.lang)}
                                                    {this.state.error && this.state.error.addCoHostsToggle ?
                                                        <div className='tooltip-wrap'>
                                                            <Isvg src={infoIcon} />
                                                            <div className='tooltip-text'>{this.state.error.addCoHostsToggle}</div>
                                                        </div>
                                                        : null}
                                                </Label>
                                                <Checkbox onChange={() => this.toggleAddCoHosts()} checked={form.addCoHostsToggle} />
                                            </div>
                                            : null
                                        }
                                        {this.state.form && this.state.form.addCoHostsToggle ?
                                            <div className={this.state.error && this.state.error.cohosts ? 'field-label-validation field-label-validation-error' : 'sm-row'} style={{ alignItems: 'baseline' }}>
                                                <div className='add-cohosts'>
                                                    <div>
                                                        <SelectWithSearch
                                                            lang={this.props.lang}
                                                            items={this.state.cohosts && this.state.cohosts.map((item, idx) => {
                                                                return {
                                                                    name: item.name,
                                                                    value: item._id
                                                                }
                                                            })}
                                                            value={this.state.form.cohosts}
                                                            maxHeight={'50vh'}
                                                            onChange={(e) => {
                                                                if (e && e.value && this.state.cohosts.filter(item => item._id === e.value).length) {

                                                                    let namesCohosts = this.state.form.namesCohosts || [];
                                                                    let cohosts = this.state.form.cohosts || [];

                                                                    const cohost = this.state.cohosts.find(item => item._id === e.value);

                                                                    if (cohost) {

                                                                        if (!namesCohosts.includes(cohost.name)) {
                                                                            namesCohosts.push(cohost.name);
                                                                        }


                                                                        if (!cohosts.includes(e.value)) {
                                                                            cohosts.push(e.value);
                                                                        }


                                                                        let form = { ...this.state.form, namesCohosts, cohosts };
                                                                        this.setState({ form });
                                                                    }

                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    {<div className={form.namesCohosts && form.namesCohosts.length ? 'email-list' : 'email-none-list'}>
                                                        {form.namesCohosts && form.namesCohosts.map((item, idx) => {
                                                            return (
                                                                <div className='schedule-tag' key={idx}>
                                                                    <button>
                                                                        {item}
                                                                        <span onClick={() => this.handleDeleteCoHosts(idx)}>x</span>
                                                                    </button>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>}
                                                </div>
                                            </div>
                                            : null
                                        }

                                        <div className='sm-row when-select'>
                                            {/* <Label>{'When'.translate(this.props.lang)}</Label> */}
                                            <Label className={this.state.error && this.state.error.when ? 'field-label-validation field-label-validation-error' : 'field-label-validation'}>
                                                {'When'.translate(this.props.lang) + '*'}
                                                {this.state.error && this.state.error.when ?
                                                    <div className='tooltip-wrap'>
                                                        <Isvg src={infoIcon} />
                                                        <div className='tooltip-text'>{this.state.error.when}</div>
                                                    </div>
                                                    : null}
                                            </Label>
                                            <div className='fields-row'>
                                                <DatePicker
                                                    label={'Date'.translate(this.props.lang)}
                                                    value={form.scheduledDate}
                                                    onChange={(e) => {
                                                        this.scheduleDate(e)
                                                    }}
                                                />

                                                <Input
                                                    type='select'
                                                    onChange={(e) => {
                                                        let form = this.state.form
                                                        form.scheduledTime = e.target.value
                                                        this.setState({
                                                            form
                                                        })
                                                    }}
                                                    value={this.state.form.scheduledTime}>
                                                    {this.state.selectHours.map(hour => {
                                                        // if ((hour != '00' && form.dateType == 'PM') || (hour != '12' && form.dateType == 'AM'))
                                                        return (
                                                            this.state.selectMinutes.map(minut => {
                                                                return (
                                                                    <option value={`${hour + ':' + minut}`}>{hour + ':' + minut}</option>
                                                                )
                                                            })
                                                        )
                                                    })}
                                                </Input>
                                                <Input type='select' onChange={(e) => this.chooseDateType(e.target.value)} value={form.dateType}>
                                                    <option value='AM'>AM</option>
                                                    <option value='PM'>PM</option>
                                                </Input>
                                            </div>

                                        </div>

                                        <div className='sm-row duration-select'>
                                            {/* <Label>{'Duration'.translate(this.props.lang)}</Label> */}
                                            <Label className={this.state.error && this.state.error.duration ? 'field-label-validation field-label-validation-error' : 'field-label-validation'}>
                                                {'Duration'.translate(this.props.lang) + '*'}
                                                {this.state.error && this.state.error.duration ?
                                                    <div className='tooltip-wrap'>
                                                        <Isvg src={infoIcon} />
                                                        <div className='tooltip-text'>{this.state.error.duration}</div>
                                                    </div>
                                                    : null}
                                            </Label>
                                            <div className='fields-row'>
                                                <Input type='select' onChange={(e) => this.chooseDurationHours(e)} value={this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.licenceMain && this.props.uData.licenceObj.licenceMain == 'basic' ? '0' : form.durationHs}>
                                                    {
                                                        this.state.hours && this.state.hours.map((item, idx) => {
                                                            return (
                                                                <option value={item.hour}>{item.hour}</option>
                                                            )
                                                        })
                                                    }

                                                </Input>
                                                <span>hr</span>
                                                <Input type='select' onChange={(e) => this.chooseDurationMinutes(e)} value={this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.licenceMain && this.props.uData.licenceObj.licenceMain == 'basic' ? 20 : form.durationMin}>
                                                    <option value={0}>0</option>
                                                    <option value={5}>5</option>
                                                    <option value={10}>10</option>
                                                    <option value={15}>15</option>
                                                    <option value={20}>20</option>
                                                    <option value={25}>25</option>
                                                    <option value={30}>30</option>
                                                    <option value={35}>35</option>
                                                    <option value={40}>40</option>
                                                    <option value={45}>45</option>
                                                    <option value={50}>50</option>
                                                    <option value={55}>55</option>
                                                </Input>
                                                <span>min</span>
                                            </div>
                                        </div>

                                        {this.props.uData && (!this.props.uData.licenceObj || (this.props.uData.licenceObj && this.props.uData.licenceObj.licenceType == 'basic')) && this.props.uData.activeLicence && this.props.uData.activeLicence == 'basic' ? <div className='packet-notification'>
                                            <p>{'Your Curoflow Basic plan has a 20-minute time limit on meetings with 2 participants. Upgrade now to enjoy unlimited group meetings.'.translate(this.props.lang)}</p>
                                            <Link to='/buy-licence'>{'Upgrade Now'.translate(this.props.lang)}</Link>
                                        </div> : null}


                                        <div className='sm-row-container'>
                                            <div className='sm-row timezone-container'>
                                                {/* <Label>{'Time Zone'.translate(this.props.lang)}</Label> */}
                                                <Label className={this.state.error && this.state.error.timeZone ? 'field-label-validation field-label-validation-error' : 'field-label-validation'}>
                                                    {'Time Zone'.translate(this.props.lang) + '*'}
                                                    {this.state.error && this.state.error.timeZone ?
                                                        <div className='tooltip-wrap'>
                                                            <Isvg src={infoIcon} />
                                                            <div className='tooltip-text'>{this.state.error.timeZone}</div>
                                                        </div>
                                                        : null}
                                                </Label>
                                                <TimeZonePicker onChange={(e) => this.selectedTimezone(e.target.value)} value={form.timezone ? form.timezone : this.props.uData.timezone} />

                                            </div>
                                            <span className='rm-checkbox'><Checkbox onChange={() => this.toggleRecuirMeeting()} checked={form.recurring} />{'Recurring meeting'.translate(this.props.lang)}</span>
                                        </div>

                                        {form && form.recurring ? <div className='recuir-metting'>
                                            <div className='sm-row' style={{ display: 'block' }}>
                                                <div className='sm-row-container'>
                                                    <Label>{'Recurring meeting'.translate(this.props.lang)}</Label>
                                                    <p>{recurringLabel}</p>
                                                </div>
                                                <div className='sm-row-container'>
                                                    <Label>{'Recurrence'.translate(this.props.lang)}</Label>
                                                    <Input type='select' onChange={(e) => this.recurrenceType(e.target.value)} value={form.recurringObj.recurrence} checked={form.recurringObj && form.recurringObj.recurrence}>
                                                        <option value={'Daily'}>{'Daily'.translate(this.props.lang)}</option>
                                                        <option value={'Weekly'}>{'Weekly'.translate(this.props.lang)}</option>
                                                        <option value={'Monthly'}>{'Monthly'.translate(this.props.lang)}</option>
                                                    </Input>
                                                </div>
                                                {form.recurringObj && form.recurringObj.recurrence === 'Weekly' ? <div className='sm-row sm-row-weekly'>
                                                    {/* <Label>{'Repeat every'.translate(this.props.lang)}</Label> */}
                                                    <Label className={this.state.error && this.state.error.repeatEveryWeek ? 'field-label-validation field-label-validation-error' : 'field-label-validation'}>
                                                        {'Repeat every'.translate(this.props.lang) + '*'}
                                                        {this.state.error && this.state.error.repeatEveryWeek ?
                                                            <div className='tooltip-wrap'>
                                                                <Isvg src={infoIcon} />
                                                                <div className='tooltip-text'>{this.state.error.repeatEveryWeek}</div>
                                                            </div>
                                                            : null}
                                                    </Label>
                                                    <div className='week-name'>
                                                        <Checkbox onChange={() => {
                                                            let form = this.state.form;
                                                            if (form && form.recurringObj) {

                                                                form.recurringObj.checkMon = !this.state.form.recurringObj.checkMon

                                                                let error = this.state.error;
                                                                if (error.repeatEveryWeek) {
                                                                    error.repeatEveryWeek = null;
                                                                    this.setState({ error })
                                                                }


                                                            }
                                                            this.setState({
                                                                form
                                                            })
                                                        }} checked={form.recurringObj.checkMon} label={'Mon'.translate(this.props.lang)} />

                                                        <Checkbox onChange={() => {
                                                            let form = this.state.form;
                                                            if (form && form.recurringObj) {

                                                                form.recurringObj.checkTue = !this.state.form.recurringObj.checkTue;
                                                                let error = this.state.error;
                                                                if (error.repeatEveryWeek) {
                                                                    error.repeatEveryWeek = null;
                                                                    this.setState({ error })
                                                                }
                                                            }


                                                            this.setState({
                                                                form
                                                            })
                                                        }} checked={form.recurringObj.checkTue} label={'Tue'.translate(this.props.lang)} />

                                                        <Checkbox onChange={() => {
                                                            let form = this.state.form;
                                                            if (form && form.recurringObj) {

                                                                form.recurringObj.checkWed = !this.state.form.recurringObj.checkWed;
                                                                let error = this.state.error;
                                                                if (error.repeatEveryWeek) {
                                                                    error.repeatEveryWeek = null;
                                                                    this.setState({ error })
                                                                }
                                                            }


                                                            this.setState({
                                                                form
                                                            })
                                                        }} checked={form.recurringObj.checkWed} label={'Wed'.translate(this.props.lang)} />

                                                        <Checkbox onChange={() => {
                                                            let form = this.state.form;
                                                            if (form && form.recurringObj) {

                                                                form.recurringObj.checkThu = !this.state.form.recurringObj.checkThu;
                                                                let error = this.state.error;
                                                                if (error.repeatEveryWeek) {
                                                                    error.repeatEveryWeek = null;
                                                                    this.setState({ error })
                                                                }
                                                            }

                                                            this.setState({
                                                                form
                                                            })
                                                        }} checked={form.recurringObj.checkThu} label={'Thu'.translate(this.props.lang)} />

                                                        <Checkbox onChange={() => {
                                                            let form = this.state.form;
                                                            if (form && form.recurringObj) {
                                                                form.recurringObj.checkFri = !this.state.form.recurringObj.checkFri;
                                                                let error = this.state.error;
                                                                if (error.repeatEveryWeek) {
                                                                    error.repeatEveryWeek = null;
                                                                    this.setState({ error })
                                                                }
                                                            }


                                                            this.setState({
                                                                form
                                                            })
                                                        }} checked={form.recurringObj.checkFri} label={'Fri'.translate(this.props.lang)} />

                                                        <Checkbox onChange={() => {
                                                            let form = this.state.form;
                                                            if (form && form.recurringObj) {
                                                                form.recurringObj.checkSat = !this.state.form.recurringObj.checkSat;
                                                                let error = this.state.error;
                                                                if (error.repeatEveryWeek) {
                                                                    error.repeatEveryWeek = null;
                                                                    this.setState({ error })
                                                                }
                                                            }


                                                            this.setState({
                                                                form
                                                            })
                                                        }} checked={form.recurringObj.checkSat} label={'Sat'.translate(this.props.lang)} />

                                                        <Checkbox onChange={() => {
                                                            let form = this.state.form;
                                                            if (form && form.recurringObj) {
                                                                form.recurringObj.checkSun = !this.state.form.recurringObj.checkSun;
                                                                let error = this.state.error;
                                                                if (error.repeatEveryWeek) {
                                                                    error.repeatEveryWeek = null;
                                                                    this.setState({ error })
                                                                }
                                                            }

                                                            this.setState({
                                                                form
                                                            })
                                                        }} checked={form.recurringObj.checkSun} label={'Sun'.translate(this.props.lang)} />

                                                    </div>
                                                </div> : null}
                                                {(form.recurringObj && form.recurringObj.recurrence === 'Weekly' || form.recurringObj && form.recurringObj.recurrence === 'Monthly') ? null : <div className='sm-row-container'>
                                                    <Label className={this.state.error && this.state.error.repeatEveryDay ? 'field-label-validation field-label-validation-error' : 'field-label-validation'}>
                                                        {'Repeat every'.translate(this.props.lang) + '*'}
                                                        {this.state.error && this.state.error.repeatEveryDay ?
                                                            <div className='tooltip-wrap'>
                                                                <Isvg src={infoIcon} />
                                                                <div className='tooltip-text'>{this.state.error.repeatEveryDay}</div>
                                                            </div>
                                                            : null}
                                                    </Label>
                                                    <Input type='text'
                                                        className='repeat-input'
                                                        min={0}
                                                        value={form.recurringObj.repeat}
                                                        onChange={(e) => {
                                                            let val = e.target.value
                                                            const re = /^[1-9]+[0-9]*$/;
                                                            if (val == '' || re.test(val)) {
                                                                this.repeatType(e.target.value)
                                                            }
                                                        }}
                                                    >
                                                    </Input>

                                                    <span className='label-span'>{'day'.translate(this.props.lang)}</span>
                                                </div>}
                                                {form.recurringObj && form.recurringObj.recurrence === 'Monthly' ? <div className='sm-row-container'>
                                                    {/* <Label>{'Repeat every'.translate(this.props.lang)}</Label> */}
                                                    <Label className={this.state.error && this.state.error.repeatEveryMonth ? 'field-label-validation field-label-validation-error' : 'field-label-validation'}>
                                                        {'Repeat every'.translate(this.props.lang) + '*'}
                                                        {this.state.error && this.state.error.repeatEveryMonth ?
                                                            <div className='tooltip-wrap'>
                                                                <Isvg src={infoIcon} />
                                                                <div className='tooltip-text'>{this.state.error.repeatEveryMonth}</div>
                                                            </div>
                                                            : null}
                                                    </Label>
                                                    <Input type='select'
                                                        className='repeat-input'
                                                        value={this.state.form.recurringObj.frequencyMonthScheduled}
                                                        onChange={(e) => {
                                                            let val = null;
                                                            if (e.target.value != '-1') {
                                                                val = e.target.value;
                                                            }
                                                            let form = this.state.form;
                                                            if (form && form.recurringObj)
                                                                form.recurringObj.frequencyMonthScheduled = val;
                                                            this.setState({
                                                                form
                                                            })

                                                            let error = this.state.error;
                                                            if (val && error.repeatEveryMonth) {
                                                                error.repeatEveryMonth = null;
                                                                this.setState({ error })
                                                            }

                                                        }}>
                                                        <option value={'-1'}>

                                                        </option>
                                                        <option value={'1'}>
                                                            {'1st'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'2'}>
                                                            {'2nd'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'3'}>
                                                            {'3rd'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'4'}>
                                                            {'4th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'5'}>
                                                            {'5th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'6'}>
                                                            {'6th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'7'}>
                                                            {'7th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'8'}>
                                                            {'8th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'9'}>
                                                            {'9th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'10'}>
                                                            {'10th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'11'}>
                                                            {'11th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'12'}>
                                                            {'12th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'13'}>
                                                            {'13th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'14'}>
                                                            {'14th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'15'}>
                                                            {'15th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'16'}>
                                                            {'16th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'17'}>
                                                            {'17th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'18'}>
                                                            {'18th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'19'}>
                                                            {'19th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'20'}>
                                                            {'20th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'21'}>
                                                            {'21st'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'22'}>
                                                            {'22nd'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'23'}>
                                                            {'23rd'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'24'}>
                                                            {'24th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'25'}>
                                                            {'25th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'26'}>
                                                            {'26th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'27'}>
                                                            {'27th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'28'}>
                                                            {'28th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'29'}>
                                                            {'29th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'30'}>
                                                            {'30th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'31'}>
                                                            {'31st'.translate(this.props.lang)}
                                                        </option>

                                                    </Input>
                                                </div> : null}
                                                <div className='sm-row-container dates'>
                                                    {/* <Label>{'End date'.translate(this.props.lang)}</Label> */}
                                                    <Label className={this.state.error && this.state.error.endDate ? 'field-label-validation field-label-validation-error' : 'field-label-validation'}>
                                                        {'End date'.translate(this.props.lang) + '*'}
                                                        {this.state.error && this.state.error.endDate ?
                                                            <div className='tooltip-wrap'>
                                                                <Isvg src={infoIcon} />
                                                                <div className='tooltip-text'>{this.state.error.endDate}</div>
                                                            </div>
                                                            : null}
                                                    </Label>
                                                    <div className='by'>
                                                        <Radio onChange={() => this.dateByPicker()} value={form.dateBy} />
                                                        <DatePicker onChange={(e) => this.scheduleEndDate(e)} value={form.scheduleEndDate} />


                                                    </div>
                                                    <div className='after'>
                                                        <Radio onChange={() => this.dateAfterPicker()} value={form.dateAfter} label={'After'.translate(this.props.lang)} />
                                                        <Input type='text'
                                                            min={0}
                                                            value={form.scheduleDateAfter}
                                                            onChange={(e) => {
                                                                let val = e.target.value
                                                                const re = /^[1-9]+[0-9]*$/;
                                                                if (val == '' || re.test(val)) {
                                                                    this.scheduleEndDateAfter(e.target.value)
                                                                }
                                                            }}
                                                        >
                                                        </Input>
                                                        <span className='label-span'>{'occurrences'.translate(this.props.lang)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : null}

                                        <div className='sm-row'>
                                            <Label>{'Meeting ID'.translate(this.props.lang)}</Label>
                                            <p>{form.conversationID}</p>
                                        </div>
                                        <div className='sm-row'>
                                            <Label>{'Mute participants upon entry'.translate(this.props.lang)}</Label>
                                            <Checkbox onChange={() => this.toggleEntryId()} checked={form.muteParticipants} />
                                        </div>
                                        <div className='sm-row'>
                                            <Label>{'Record video call on your local computer'.translate(this.props.lang)}</Label>
                                            <Checkbox onChange={() => this.toggleRecord()} checked={form.recordConversationAsVideo} />
                                        </div>
                                        <div style={{ width: '185px' }}>
                                            <span style={{ fontSize: '12px', color: '#818fa5' }}>{'Recording is not supported by mozilla firefox/safari.'.translate(this.props.lang)}</span>
                                        </div>
                                        <div className='sm-row'>
                                            <Label>{'Allow participants to record video call on their local computers'.translate(this.props.lang)}</Label>
                                            <Checkbox onChange={() => this.toggleRecordMembers()} checked={form.recordConversationAsVideoMembers} />
                                        </div>
                                        <div style={{ width: '185px' }}>
                                            <span style={{ fontSize: '12px', color: '#818fa5' }}>{'Recording is not supported by mozilla firefox/safari.'.translate(this.props.lang)}</span>
                                        </div>
                                        {
                                            this.props.uData.activeLicence && this.props.uData.activeLicence !== 'basic' ?
                                                <div className='sm-row'>
                                                    <Label>{'BankID required'.translate(this.props.lang)}</Label>
                                                    <Checkbox onChange={() => this.toggleBankid()} checked={form.requireBankID} />
                                                </div>
                                                :
                                                null
                                        }

                                        {
                                            this.props.uData.activeLicence && this.props.uData.activeLicence !== 'basic' ?
                                                <div className='sm-row'>
                                                    <Label>{'SITHS required'.translate(this.props.lang)}</Label>
                                                    <Checkbox onChange={() => this.toggleNetID()} checked={form.requireNetID} />
                                                </div>
                                                :
                                                null
                                        }


                                        {
                                            this.state.form.requireBankID || this.state.form.requireNetID ?
                                                <div className='sm-row'>
                                                    <Label>{'Mandatory screen name'.translate(this.props.lang)}</Label>
                                                    <Checkbox onChange={() => this.mandatoryScreenName()} checked={form.mandatoryScreenName} />
                                                </div>
                                                : null
                                        }

                                        <div className='sm-row passcode'>
                                            <div className='passcode-container'>
                                                <Label>{'Passcode'.translate(this.props.lang)}</Label>
                                                <Checkbox
                                                    onChange={(e) => {
                                                        var pwdChars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
                                                        var pwdLen = 10;
                                                        var randPassword = Array(pwdLen).fill(pwdChars).map(function (x) { return x[Math.floor(Math.random() * x.length)] }).join('');
                                                        if (e.target.checked) {
                                                            form.passcode = randPassword
                                                        } else {
                                                            form.passcode = ''
                                                        }

                                                        this.togglePasscode(e)
                                                    }}
                                                    checked={form.passcodeCheckbox}
                                                // label={'Passcode'.translate(this.props.lang)}
                                                />
                                                {form.passcodeCheckbox ? <FormGroup>
                                                    <Input type='text' value={this.state.form.passcode ? this.state.form.passcode : ''} onChange={(e) => {
                                                        let form = this.state.form;
                                                        form.passcode = e.target.value;
                                                        this.setState({
                                                            form
                                                        })
                                                        // this.setPasscodeForm()
                                                    }} />
                                                </FormGroup> : null}
                                            </div>
                                            {form.passcodeCheckbox ? <span className='passcode-info'>{'Only users who have the invite link or passcode can join the meeting'.translate(this.props.lang)}</span> : null}
                                        </div>

                                    </Col>

                                    <Col lg="12">
                                        <div className='buy-button'>
                                            {
                                                this.props[0].location.state && this.props[0].location.state.cancel ?
                                                    <button onClick={() => {
                                                        this.props[0].history.push({
                                                            pathname: '/users/manage-meeting',
                                                            state: { ...this.state.form }
                                                        })
                                                    }}>{'Cancel'.translate(this.props.lang)}</button>
                                                    :
                                                    null
                                            }

                                            <button
                                                // disabled={this.state.form && !this.state.form.name}
                                                onClick={() => {
                                                    if (this.state.form && this.state.form._id && this.state.form.recurring) {
                                                        this.setState({
                                                            saveOptions: form
                                                        })
                                                    } else {
                                                        if (form.invitedUsers && form.invitedUsers.length) {
                                                            this.connectWithOutlook(form)
                                                        } else {

                                                            this.insertOrUpdate(form)
                                                        }
                                                    }

                                                    // 
                                                }}>{this.state.form && this.state.form.inviteParticipants && this.state.form.invitedUsers && this.state.form.invitedUsers.length ? 'Save and send email invitation'.translate(this.props.lang) : 'Save'.translate(this.props.lang)}</button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>

                {this.state.openPreviewInvite ?
                    <Modal isOpen={this.state.openPreviewInvite} centered size='md' >

                        <ModalHeader style={{ margin: 'auto' }} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ openPreviewInvite: false })}><Isvg src={xIcon} /></button>}>
                            {"Preview invitatios".translate(this.props.lang)}
                        </ModalHeader>
                        <ModalBody>

                            <div className='preview-invitations'>
                                <div className='preview-invitations-container'>
                                    {this.state.previewInvite && this.state.previewInvite.map((item, idx) => {
                                        return (
                                            <div className='preview-list'>
                                                <p>{item}</p>
                                                <Isvg src={trashIcon} onClick={() => this.removeItemOnce(this.state.previewInvite, item)} />
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                            </div>

                            <div className='preview-button-delete'>
                                <button onClick={() => this.setState({ openPreviewInvite: false })}>{'Confirm'.translate(this.props.lang)}</button>
                                <button onClick={() => this.removeItemAll(this.state.previewInvite)}>{'Delete all'.translate(this.props.lang)}</button>
                            </div>
                        </ModalBody>
                        {/* <ModalFooter>
                         <Button color='primary' disabled={this.state.checkedAll && this.state.checkedAll.length ? false : true} onClick={() => this.props.onClose(this.state.checkedAll)}>{'Insert'.translate(this.props.lang)}</Button>

                     </ModalFooter> */}
                    </Modal>
                    :
                    null
                }
                {
                    this.state.saveOptions ?
                        <InfoModal
                            isOpen={this.state.saveOptions}
                            toggle={() => this.setState({ saveOptions: !this.state.saveOptions })}
                            // image={Brev}
                            header={'Info'.translate(this.props.lang)}
                            info={"Chose save option. Recurring settings will not be applied if you pick 'save for this event'.".translate(this.props.lang)}
                            buttons={[
                                <Button onClick={() => {
                                    let saveOptions = this.state.saveOptions;
                                    // saveOptions.scheduledDate = saveOptions.events[0].scheduledDate
                                    // if(saveOptions.events && saveOptions.recurring){
                                    //     for (let i = 0; i < saveOptions.events.length; i++) {
                                    //        if(Math.floor(new Date(saveOptions.events[i].scheduledDate.replace(/-/g, "/") + ' ' + saveOptions.events[i].scheduledTime).getTime() / 1000) > Math.floor(new Date().getTime() / 1000)){
                                    //         saveOptions.scheduledDate = saveOptions.events[i].scheduledDate

                                    //         break;
                                    //        }

                                    //     }
                                    // }

                                    this.setState({
                                        saveOptions: saveOptions
                                    }, () => {
                                        this.insertOrUpdate(this.state.saveOptions)
                                    })

                                }}>{'Save for all events'.translate(this.props.lang)}</Button>,
                                <Button onClick={() => {
                                    this.updateOneEvent(this.state.saveOptions)

                                }}>{'Save for this event'.translate(this.props.lang)}</Button>

                            ]}

                        />
                        :

                        null
                }
                {
                    this.state.errorModal ?
                        <ErrorModal lang={this.props.lang}
                            isOpen={this.state.errorModal}
                            toggle={() => this.setState({ errorModal: null })}
                        >
                            {'Please fill in the required fields'.translate(this.props.lang)}
                        </ErrorModal>
                        :
                        null
                }
            </div>
        );
    }
}



export default Page(ScheduleMeeting);