import React, { Component } from 'react';
import { API_ENDPOINT } from '../../constants';
/**
* Payson transaction check component 
* @author   Stefan Pantic
*/
class Transaction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            link: []
        };
    }

    componentDidMount() {
        let key = this.props[0].location.search.replace('?code=', '').split('&client_info')[0];

        fetch(API_ENDPOINT + '/auth/callback', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                Authorization: typeof localStorage !== "undefined" ? `Bearer ${localStorage.getItem("authToken")}` : null,
            },
            body: JSON.stringify({ key: key })
        }).then(res => res.json()).then((result) => {
            if (result) {
                if (result.link)
                    window.open(API_ENDPOINT + result.link)
                this.props.verifyUser()
                // this.props[0].history.push({
                //     pathname: '/chat',
                // })

            }
        });

    }
    render() {
        return (
            <p>
                {/* {this.props[0].location.search.replace('?code=', '')} */}

            </p>
        );
    }
}

export default Transaction;