import React, { Component } from "react";
import Link from "../components/link";

import Isvg from "react-inlinesvg";
import Page from "../containers/page";

import { Player, Controls } from "@lottiefiles/react-lottie-player";
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import FormBuilder from "../components/forms/formBuilder";
import { required, phoneNumber } from "../components/forms/validation";
import { API_ENDPOINT } from "../constants";

import logoLogin from "../assets/svg/logo_video.svg";
import InfoModal from "../components/infoModal";
import shapeBlue from "../assets/svg/shape-blue.svg";
import shapeGreen from "../assets/svg/shape-green.svg";
import shapeYellow from "../assets/images/shape-yellow.png";
import registration from "../assets/images/kontaktbanner.png";
import op from "../assets/images/op.png";

import {
    Label
} from 'reactstrap';


const renderCheckField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },

}) => (

    <Check
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        // multilang={multilang}
        // lang={lang}

        {...input}
    />
)

/**
* Login page
* @author   Milan Stanojevic
*/
class LoginPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            promotedProjects: [],
            loginBankIdModal: true,
            ...props.initialData,
            loginForm: true,
            // moreOptions: true,
            registrationForm: true
        };
    }



    componentDidMount() {
        if (typeof window !== "undefined") {
            window.scrollTo(0, 0);
        }

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](
                window.fetch,
                this.props.lang,
                this.props[0].match
            ).then((data) => {
                this.setState(
                    {
                        ...data,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        );
                    }
                );
            });
        }


        // fetch(API_ENDPOINT + `/users/bankid/v2/start/${this.props.lang == 'en' ? this.props.lang : 'sv'}`, {
        //     method: "GET",
        //     headers: {
        //         "content-type": "application/json",
        //     },
        // })
        //     .then((res) => res.json())
        //     .then((result) => {

        //         if (result.redirect_url) {
        //             this.setState({
        //                 bankIdRedirectUrl: result.redirect_url
        //             })
        //         }
        //     });
        window.addEventListener('message',
            (e) => {

                if (e.data == 'loggedin') {

                    this.props.verifyUser(() => {
                        // localStorage.setItem('checkHost', true)
                        this.props[0].history.push('/my-data/account');

                    }, () => {



                    });

                } else if (e.data == 'loggedin_newuser') {


                    this.props.verifyUser(() => {
                        // localStorage.setItem('checkHost', true)
                        this.props[0].history.push('/my-data/account');
                        // console.log(this.props.uData)
                    }, () => {





                    });
                }
            }

            , false)

    }

    registration = (data) => {
        if (this.props.lang) {
            data.lang = this.props.lang
        } else {
            data.lang = 'se'
        }
        if (localStorage.allowCookiesLogin) {
            fetch(API_ENDPOINT + "/users/registration", {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                },
                body: JSON.stringify(data),
            }).then(res => res.json()).then((result) => {
                if (result.error) {
                    this.setState({ error: result.error }, () => {
                        // setTimeout(() => {
                        //     this.setState({ error: null })
                        // }, 3000)
                    })
                } else {
                    // localStorage.setItem('checkHost', true)
                    this.setState({

                        loginForm: true,
                        registrationForm: false,
                        registrationSuccessful: true
                    })
                }
            })
        } else {
            this.props.redLinePopUp()
        }
    }


    componentDidUpdate(prevProps, prevState) {
        if (this.props.uData) {
            this.props[0].history.push("/");
        }

        if ((this.props.email && this.props.email != prevProps.email) || (this.props.repeatPassword && this.props.repeatPassword != prevProps.repeatPassword) || (this.props.password && this.props.password != prevProps.password) /*|| (this.state.moreOptions && this.state.moreOptions != prevState.moreOptions)*/ || (this.state.registrationForm && this.state.registrationForm != prevState.registrationForm)) {
            this.setState({
                error: null
            })
        }
    }

    render() {
        return (
            <div className="login-main-wrap registration-overflow-mobile">
                <div className="login-wrapper">
                    {!this.props.userVerificationInProgress ? (
                        <>
                            <Isvg src={shapeBlue} className="shape-blue" />
                            <Isvg src={shapeGreen} className="shape-green" />
                            <img src={shapeYellow} className="shape-yellow" />
                        </>
                    ) : null}


                    <div className="log-in log-in-register">
                        <Container fluid>
                            <Row>
                                <Col lg={{ size: 12 }}>
                                    {this.props.userVerificationInProgress ? (
                                        <div className="loader-wrap">
                                            <Player
                                                autoplay={true}
                                                loop={true}
                                                src="/lf30_editor_l5cxzdyf.json"
                                                style={{
                                                    height:
                                                        typeof window != "undefined" &&
                                                            window.innerWidth < 768
                                                            ? "128px"
                                                            : "256px",
                                                    width:
                                                        typeof window != "undefined" &&
                                                            window.innerWidth < 768
                                                            ? "128px"
                                                            : "256px",
                                                }}
                                            ></Player>
                                        </div>
                                    ) : (
                                        <div className="panel panel-registraion-box">
                                            <div className="logo-header">
                                                <Link to="/">
                                                    <Isvg src={logoLogin} />
                                                </Link>
                                            </div>
                                            <div className="register-box">
                                                <div>
                                                    <h4>{'Create account'.translate(this.props.lang)}</h4>
                                                    <img src={op} />
                                                    <Label>{'Do you already have an account?'.translate(this.props.lang)}<span>{' '}{'Log in'.translate(this.props.lang)} <a href="/login">{' '}{'here'.translate(this.props.lang)}</a></span></Label>
                                                </div>
                                                <div className="login-container">
                                                    <h4>{"Registration".translate(this.props.lang)} </h4>

                                                    {/* {
                                                        !this.state.moreOptions ?
                                                            this.state.bankIdRedirectUrl === null ?
                                                                <div className="loader-wrap">
                                                                    <Player
                                                                        autoplay={true}
                                                                        loop={true}
                                                                        src="/lf30_editor_l5cxzdyf.json"
                                                                        style={{
                                                                            height:
                                                                                typeof window != "undefined" &&
                                                                                    window.innerWidth < 768
                                                                                    ? "128px"
                                                                                    : "256px",
                                                                            width:
                                                                                typeof window != "undefined" &&
                                                                                    window.innerWidth < 768
                                                                                    ? "128px"
                                                                                    : "256px",
                                                                        }}
                                                                    ></Player>
                                                                </div>
                                                                :
                                                                <>
                                                                    {
                                                                        !this.state._iframeLoaded ?


                                                                            <div className="loader-wrap" style={{ height: 360 }}>
                                                                                <Player
                                                                                    autoplay={true}
                                                                                    loop={true}
                                                                                    src="/lf30_editor_l5cxzdyf.json"
                                                                                    style={{
                                                                                        height:
                                                                                            typeof window != "undefined" &&
                                                                                                window.innerWidth < 768
                                                                                                ? "128px"
                                                                                                : "256px",
                                                                                        width:
                                                                                            typeof window != "undefined" &&
                                                                                                window.innerWidth < 768
                                                                                                ? "128px"
                                                                                                : "256px",
                                                                                    }}
                                                                                ></Player>
                                                                            </div>
                                                                            :
                                                                            null
                                                                    }

                                                                    <iframe ref={(node) => this.bankIdIframe = node} onLoad={() => {
                                                                        try {
                                                                            if (this.bankIdIframe && this.bankIdIframe.contentWindow && this.bankIdIframe.contentWindow.location && this.bankIdIframe.contentWindow.location.href && this.bankIdIframe.contentWindow.location.href.indexOf('/bankid/v2') !== -1) {
                                                                                //alert(true)
                                                                                this.setState({
                                                                                    _hideIframe: true
                                                                                })
                                                                            }
                                                                        } catch (e) { }
                                                                        this.setState({ _iframeLoaded: true })
                                                                    }} src={this.state.bankIdRedirectUrl} style={this.state._hideIframe ? { opacity: 0 } : { width: '100%', maxWidth: '550px', height: this.state._iframeLoaded ? 360 : 0, border: 0 }} />

                                                                </>
                                                            :
                                                            null

                                                    } */}

                                                    {this.state.registrationForm ?
                                                        <div className="login-form">
                                                            <FormBuilder
                                                                dateFormat={this.props.dateFormat}
                                                                lang={this.props.lang}
                                                                onSubmit={(data) => this.registration(data)}
                                                                buttonText={"Create account".translate(this.props.lang)}
                                                                fields={[
                                                                    {
                                                                        type: "row",
                                                                        children: [
                                                                            {
                                                                                type: "col",
                                                                                width: {
                                                                                    lg: 12,
                                                                                    sm: 12,
                                                                                    xs: 12,
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: "email",
                                                                                        name: "email",
                                                                                        label: "Email".translate(this.props.lang),
                                                                                        validate: [required("Email is required!".translate(this.props.lang))],
                                                                                    },
                                                                                ],
                                                                            },
                                                                        ],
                                                                    },
                                                                    {
                                                                        type: "row",
                                                                        children: [
                                                                            {
                                                                                type: "col",
                                                                                width: {
                                                                                    lg: 6,
                                                                                    sm: 12,
                                                                                    xs: 12,
                                                                                },
                                                                                children: [

                                                                                ],
                                                                            },
                                                                            {
                                                                                type: "col",
                                                                                width: {
                                                                                    lg: 12,
                                                                                    sm: 12,
                                                                                    xs: 12,
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: "password",
                                                                                        name: "password",
                                                                                        label: "Password".translate(this.props.lang),
                                                                                        validate: [required("Password is required!".translate(this.props.lang))],
                                                                                    },
                                                                                ],
                                                                            },
                                                                            {
                                                                                type: "col",
                                                                                width: {
                                                                                    lg: 12,
                                                                                    sm: 12,
                                                                                    xs: 12,
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: "password",
                                                                                        name: "repeatPassword",
                                                                                        label: "Repeat password".translate(this.props.lang),
                                                                                        validate: [required("Repeat password is required!".translate(this.props.lang))],
                                                                                    },
                                                                                ],
                                                                            },
                                                                            {
                                                                                type: "col",
                                                                                width: {
                                                                                    lg: 12,
                                                                                    sm: 12,
                                                                                    xs: 12,
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: "checkbox",
                                                                                        name: "checkbox",
                                                                                        label: <>{'Accept the'.translate(this.props.lang)} <span><Link lang={this.props.lang} target='_blank' to="/termsandconditions">{'terms and conditions'.translate(this.props.lang)}</Link> </span> {'and'.translate(this.props.lang)} <span><Link lang={this.props.lang} target='_blank' to="/integritetspolicy-videotjanst">{'privacy policy'.translate(this.props.lang)}</Link></span></>,
                                                                                        validate: [required("Privacy policy required!".translate(this.props.lang))],
                                                                                    },
                                                                                ],
                                                                            },
                                                                        ],
                                                                    },
                                                                ]}

                                                            ></FormBuilder>
                                                        </div>
                                                        : null}
                                                    {/* {
                                                        this.state.registrationForm ?
                                                            <div style={{ marginTop: 10 }} className="login-more-options" onClick={() => { if (localStorage.allowCookiesLogin) this.setState({ moreOptions: !this.state.moreOptions, registrationForm: false }) }}>
                                                                {'Create account with BankID'.translate(this.props.lang)}

                                                            </div>
                                                            :
                                                            <div style={{ marginTop: 10 }} className="login-more-options" onClick={() => this.setState({ moreOptions: !this.state.moreOptions, registrationForm: true })}>
                                                                {'Create account'.translate(this.props.lang)}

                                                            </div>
                                                    } */}


                                                    {this.state.error ? (
                                                        <p style={{ color: 'red' }} className="error-message">
                                                            {this.state.error.translate(this.props.lang)}
                                                        </p>
                                                    ) : null}

                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Col>
                            </Row>

                        </Container>
                    </div>
                </div>

                {
                    this.state.registrationSuccessful ?
                        <InfoModal
                            isOpen={this.state.registrationSuccessful}
                            toggle={() => this.setState({ registrationSuccessful: !this.state.registrationSuccessful })}
                            // image={Brev}
                            header={'Info'.translate(this.props.lang)}
                            info={'Check your email to complete registration and verify your account.'.translate(this.props.lang)}
                            boldText={"NOTE: Don't forget to check your spam.".translate(this.props.lang)}
                            buttons={[
                                <Button color='primary' onClick={() => {
                                    this.setState({
                                        registrationSuccessful: null
                                    }, () => {
                                        this.props[0].history.push('/login')
                                    })
                                }}>{'Ok'.translate(this.props.lang)}</Button>,
                            ]}

                        />
                        :
                        null
                }
            </div >
        );
    }
}


const selector = formValueSelector('form');

export default connect(state => {
    return {

        email: selector(state, 'email'),
        password: selector(state, 'password'),
        repeatPassword: selector(state, 'repeatPassword'),


    }


}, {

    // changeUsername: value => change("form", "username", value),
    // changePassword: value => change("form", "password", value),


})(Page(LoginPage));
