import React, { Component } from "react";
import Page from "../../containers/page";

import {
    Container,
    Row,
    Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, FormGroup, Label
} from "reactstrap";

import ListBuilder from '../../components/listBuilder';
import xIcon from '../../assets/svg/x.svg';
import Isvg from 'react-inlinesvg'
import cancelSubscription from '../../assets/svg/cancel_subscription.svg';
import updatePlan from '../../assets/svg/update_plan.svg';
import InfoModal from "../../components/infoModal";
import viewIcon from '../../assets/svg/eye.svg';
import downloadIcon from '../../assets/svg/download.svg'
import {
    getSearchParams,
    generateSearchLink,
    generateSearchLinkMultiple,
} from "../../helpers/linkHelper";
import { API_ENDPOINT } from "../../constants";
import PdfSave from '../../components/pdvSave';
import moment from 'moment'

function parseJSON(response) {
    return response.json().then((json) => {
        return {
            result: json,
            status: response.status,
        };
    });
}

function parseText(response) {
    return response.text().then(text => {
        return {
            result: text,
            status: response.status
        }
    })
}

class VideoPayments extends Component {
    constructor(props) {
        super(props);

        let currDate = new Date();
        let date1 = new Date(currDate.getFullYear(), 0, 1);
        let date2 = new Date();

        this.player = React.createRef();
        this.player2 = React.createRef();
        this.state = {
            showPlayer: false,
            showPlayer2: false,
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0,
                "query-group": null,
            },
            items: [],
            total: 0,
            loading: true,
            groups: [],
            languages: [],
            clinicIdx: 0,
            showForm: false,
            dateFrom: moment.unix(Math.floor(date1.getTime() / 1000)).format('YYYY-MM-DD'),
            dateTo: moment.unix(Math.floor(date2.getTime() / 1000)).format('YYYY-MM-DD'),
            tabIndex: 1,
            status: false
        };
    }

    showPlayer = () => {
        this.setState({
            showPlayer: true,
        });
    };

    hidePlayer = () => {
        this.setState({
            showPlayer: false,
        });
    };

    get = () => {
        this.props.updateMeta(
            this.props.generateSeoTags(this.state, this.props.lang)
        );

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
                this.state.useState
                    ? this.state.params
                    : getSearchParams(this.props[0].location.search, {
                        entries: 10,
                        page: 0,
                        sortField: 'tsCreated', sortType: -1,
                        "query-group": null,
                        dateFrom: this.state.dateFrom, dateTo: this.state.dateTo,
                        status: this.state.status
                    })
            ).then((data) => {
                this.setState(
                    {
                        ...data,
                        loading: null,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        );
                    }
                );
            });
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
                this.state.useState
                    ? this.state.params
                    : getSearchParams(this.props[0].location.search, {
                        entries: 10,
                        page: 0,
                        sortField: 'tsCreated', sortType: -1,
                        "query-group": null,
                        dateFrom: this.state.dateFrom, dateTo: this.state.dateTo,
                        status: this.state.status
                    })
            ).then((data) => {
                this.setState(
                    {
                        ...data,
                        loading: null,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        );
                    }
                );
            });
        }
    };

    componentDidMount() {
        if (typeof window !== "undefined") {
            window.scrollTo(0, 0);
        }

        this.get();
        this.setState({ showForm: true })

    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState(
                {
                    loading: true,
                },
                () => {
                    this.get();
                }
            );
        }
    }

    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state,
            });
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    [
                        { name: "sortField", value: field },
                        { name: "sortType", value: type },
                    ],
                    false
                )
            );
        }
    };

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj });
            }
        } else {
            this.props[0].history.push(
                generateSearchLink(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    name,
                    value,
                    restart
                )
            );
        }
    };

    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });
            }
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    fields,
                    restart
                )
            );
        }
    };

    stopAnimation = () => {
        this.player.current.play();
    };


    delete = (id) => {
        this.setState({
            loading: true
        }, () => {

            fetch(API_ENDPOINT + '/data/payments/' + id, {
                method: 'DELETE',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(parseJSON).then(({ result, status }) => {
                if (result.error) {
                    this.setState({ error: result.error })
                }
                this.get();
            })
        })

    }

    updateUserRecuringTime = () => {
        fetch(API_ENDPOINT + '/users/users-recuring-time-cancel', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                userId: this.props.uData._id
            })
        }).then(parseJSON).then(({ result, status }) => {
            this.setState({
                cancelSubscriptionModal: false,
                showConfirmationModal: false

            }, () => this.props.verifyUser())
        })
    }

    reactivate = () => {
        fetch(API_ENDPOINT + '/users/users-recuring-time-reactivate', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                userId: this.props.uData._id
            })
        }).then(parseJSON).then(({ result, status }) => {
            this.setState({
                reactivateModal: false
            }, () => this.props.verifyUser())
        })
    }

    downloadInvoice = (item) => {
        if (item.invoiceDetails && item.invoiceDetails.active && item.invoiceDetails.activeDocumentNumber && item.invoiceDetails.invoices && item.invoiceDetails.invoices.filter(el => el.documentNumber === item.invoiceDetails.activeDocumentNumber).length && item.invoiceDetails.invoices.filter(el => el.documentNumber === item.invoiceDetails.activeDocumentNumber)[0].pdf) {
            let link = document.createElement("a");
            link.download = `Invoice_${item.invoiceDetails.invoices.filter(el => el.documentNumber === item.invoiceDetails.activeDocumentNumber)[0].documentNumber}.pdf`;
            link.href = `${API_ENDPOINT}${item.invoiceDetails.invoices.filter(el => el.documentNumber === item.invoiceDetails.activeDocumentNumber)[0].pdf}`;
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, {
                entries: 10,
                page: 0,
            });
        }

        return (
            <div className="dashboard">
                <Container fluid>
                    <Row>
                        <div className='panel'>
                            <h5 className="component-header">{'Payments'.translate(this.props.lang)}</h5>
                            <Col lg="12" className='col-no-padding'>
                                <div className="tabs-buttons-wrap">
                                    <Button className={this.state.tabIndex == 1 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.setState({ tabIndex: 1, status: false }, () => this.get())}>{"Payments".translate(this.props.lang)}</Button>

                                    <Button className={this.state.tabIndex == 2 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.setState({ tabIndex: 2, status: 'denied' }, () => this.get())}>{"Settings".translate(this.props.lang)}</Button>

                                </div>

                            </Col>
                            {this.state.tabIndex == 1 ?

                                <Col lg="12" className='col-no-padding'>

                                    <h5 className="component-header">{'Payments'.translate(this.props.lang)}</h5>
                                    {
                                        <ListBuilder
                                            lang={this.props.lang}
                                            loading={this.state.loading}
                                            total={this.state.total}
                                            showNumeration={false}
                                            fields={[
                                                { type: 'text', name: 'orderNumber', label: 'Order ID'.translate(this.props.lang), allowSort: true },
                                                { type: 'text', name: 'date', label: 'Date'.translate(this.props.lang), allowSort: true },
                                                // { type: 'text', name: 'participant', label: 'Participant'.translate(this.props.lang), allowSort: true },
                                                { type: 'text', name: 'licence', label: 'Licence type'.translate(this.props.lang), allowSort: true },
                                                { type: 'text', name: 'experienceDate', label: 'Renewal date'.translate(this.props.lang), allowSort: true },
                                                { type: 'text', name: 'price', label: 'Price'.translate(this.props.lang), allowSort: true },
                                                { type: 'text', name: 'paymentMethod', label: 'Payment method'.translate(this.props.lang), allowSort: true },
                                                { type: 'text', name: 'reference', label: 'Reference'.translate(this.props.lang), allowSort: true },
                                                { type: 'text', name: 'status', label: 'Status'.translate(this.props.lang), allowSort: true }

                                            ]}
                                            items={this.state.items.map(item => {
                                                return {
                                                    ...item,
                                                    date: <span>{moment.unix(item.tsCreated).format("YYYY-MM-DD")}</span>,
                                                    price: item.licencePrice ? (Number(item.licencePrice)).toFixed(2) + ' SEK' : '',
                                                    status: item.status ? item.status.translate(this.props.lang) : '',
                                                    licence: item.licence && item.licenceSv ?
                                                        item.licenceDuration && item.licenceDuration == 1 ?
                                                            this.props.lang == 'en' ?
                                                                item.licence + " - " + item.licenceDuration + ' month'.translate(this.props.lang) + `${item.upgrade ? ' (upgrade)' : ''}`
                                                                :
                                                                item.licenceSv + " - " + item.licenceDuration + ' month'.translate(this.props.lang) + `${item.upgrade ? ' (upgrade)' : ''}`
                                                            :
                                                            this.props.lang == 'en' ?
                                                                item.licence + " - " + item.licenceDuration + ' months'.translate(this.props.lang) + `${item.upgrade ? ' (upgrade)' : ''}`
                                                                :
                                                                item.licenceSv + " - " + item.licenceDuration + ' months'.translate(this.props.lang) + `${item.upgrade ? ' (upgrade)' : ''}` : '',
                                                    paymentMethod: item.paymentMethod ? item.paymentMethod.translate(this.props.lang) : '',
                                                }
                                            })}
                                            actions={
                                                [
                                                    {
                                                        component: <div className="tabel-action-tooltip"><Isvg src={viewIcon} style={{ width: 24, minWidth: 24 }} /><div style={{ width: 180 }} className="chat-icons-tooltip">{'Preview'.translate(this.props.lang)}</div> </div>,
                                                        onClick: (item) => this.setState({ invoice: item }),

                                                    },
                                                    {
                                                        component: <div className="tabel-action-tooltip"><Isvg src={downloadIcon} style={{ width: 24, minWidth: 24 }} /><div style={{ width: 180 }} className="chat-icons-tooltip">{'Download'.translate(this.props.lang)}</div> </div>,
                                                        onClick: (item) => {
                                                            if (item.invoiceDetails && item.invoiceDetails.active && item.invoiceDetails.activeDocumentNumber && item.invoiceDetails.invoices && item.invoiceDetails.invoices.filter(el => el.documentNumber === item.invoiceDetails.activeDocumentNumber).length && item.invoiceDetails.invoices.filter(el => el.documentNumber === item.invoiceDetails.activeDocumentNumber)[0].pdf) {
                                                                let link = document.createElement("a");
                                                                link.download = `Invoice_${item.invoiceDetails.invoices.filter(el => el.documentNumber === item.invoiceDetails.activeDocumentNumber)[0].documentNumber}.pdf`;
                                                                link.href = `${API_ENDPOINT}${item.invoiceDetails.invoices.filter(el => el.documentNumber === item.invoiceDetails.activeDocumentNumber)[0].pdf}`;
                                                                link.target = '_blank';
                                                                document.body.appendChild(link);
                                                                link.click();
                                                                document.body.removeChild(link);
                                                            }
                                                        },
                                                        condition: (item) => {
                                                            if (item.invoiceDetails && item.invoiceDetails.active && item.invoiceDetails.activeDocumentNumber && item.invoiceDetails.invoices && item.invoiceDetails.invoices.filter(el => el.documentNumber === item.invoiceDetails.activeDocumentNumber).length && item.invoiceDetails.invoices.filter(el => el.documentNumber === item.invoiceDetails.activeDocumentNumber)[0].pdf) {
                                                                return true
                                                            } else {
                                                                return false
                                                            }
                                                        }


                                                    },
                                                ]
                                            }
                                            params={params}
                                            sortField={params.sortField}
                                            sortType={params.sortType}
                                            updateSort={this.updateSort}
                                            updateParams={this.updateParams}
                                            updateMultipleParams={this.updateMultipleParams}
                                        ></ListBuilder>

                                    }

                                </Col>
                                : null}

                            {
                                this.state.tabIndex == 2 ?
                                    <Col lg="12" className='col-no-padding'>
                                        <h5 className="component-header">{'Settings'.translate(this.props.lang)}</h5>

                                        <div className="subscription-tab">
                                            <Row>
                                                <Col lg="8">
                                                    <Row>
                                                        <Col lg="4">
                                                            <FormGroup>
                                                                <Label>{'Plan'.translate(this.props.lang)}</Label>
                                                                <div>
                                                                    {this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.licenceMain}
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="4">
                                                            <FormGroup>
                                                                <Label>{'Number of licenses'.translate(this.props.lang)}</Label>
                                                                <div>
                                                                    {this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.numberOfLicencesMain}
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="4">
                                                            <FormGroup>
                                                                <Label>{'Edit subsscription'.translate(this.props.lang)}</Label>
                                                                <div>
                                                                    <Button onClick={() => {
                                                                        // if (this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.recuringPaymentTime && this.props.uData.licenceObj.recuringPaymentTime < new Date()) {
                                                                        //     this.setState({ showConfirmationModal: true });
                                                                        // } else {
                                                                            this.props[0].history.push('/buy-licence');
                                                                        // }
                                                                    }}>{'Upgrade plan'.translate(this.props.lang)}</Button>
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="4">
                                                            <FormGroup>
                                                                <Label>{'Start date'.translate(this.props.lang)}</Label>
                                                                <div>
                                                                    {this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.licenceBoughtMain && moment.unix(this.props.uData.licenceObj.licenceBoughtMain).format(this.props.dateFormat)}
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        {
                                                            this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.recuringPaymentTimeForReactivate ?
                                                                <Col lg="4">
                                                                    <FormGroup>
                                                                        <Label>{'End date'.translate(this.props.lang)}</Label>
                                                                        <div>
                                                                            {moment.unix(this.props.uData.licenceObj.recuringPaymentTimeForReactivate).format(this.props.dateFormat)}
                                                                        </div>
                                                                    </FormGroup>
                                                                </Col>
                                                                :
                                                                <Col lg="4">
                                                                    <FormGroup>
                                                                        <Label>{'Renewal date'.translate(this.props.lang)}</Label>
                                                                        <div>
                                                                            {this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.recuringPaymentTime && moment.unix(this.props.uData.licenceObj.recuringPaymentTime).format(this.props.dateFormat)}
                                                                        </div>
                                                                    </FormGroup>
                                                                </Col>
                                                        }

                                                        {
                                                            this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.recuringPaymentTime && this.props.uData.licenceObj.recuringPaymentTime > Math.floor(new Date().getTime() / 1000) ?
                                                                <Col lg="4">
                                                                    <FormGroup>
                                                                        <Label></Label>
                                                                        <div className="button-red-container">
                                                                            <Button onClick={() => {
                                                                                this.setState({ cancelSubscriptionModal: true })
                                                                            }}>{'Cancel subscription'.translate(this.props.lang)}</Button>
                                                                        </div>
                                                                    </FormGroup>
                                                                </Col>
                                                                :
                                                                null
                                                        }

                                                    </Row>
                                                </Col>
                                                {
                                                    this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.canceledSubscription ?
                                                        <Col lg='8' className="subscribe-status">
                                                            <div>
                                                                <Label>{`Subscription cancelled`.translate(this.props.lang) + ' ' + moment.unix(this.props.uData.licenceObj.canceledSubscription).format(this.props.dateFormat)}</Label>
                                                                <div>
                                                                    <Button onClick={() => {
                                                                        this.setState({ reactivateModal: true })
                                                                    }}>{'Reactivate'.translate(this.props.lang)}</Button>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        :
                                                        null
                                                }

                                            </Row>



                                        </div>

                                    </Col>
                                    :
                                    null
                            }
                        </div>
                    </Row>
                </Container>

                {this.state.invoice ?
                    <Modal isOpen={this.state.invoice} centered size='lg' className="modal-footer-modifed">
                        <ModalHeader toggle={() => this.setState({ invoice: !this.state.invoice })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ invoice: !this.state.invoice })}><Isvg src={xIcon} /></button>}>{'Kvitto'.translate(this.props.lang)}</ModalHeader>
                        <ModalBody>

                            <div dangerouslySetInnerHTML={{ __html: this.props.lang == 'en' ? this.state.invoice.html : this.state.invoice.htmlSv }} onChange={() => { }}></div>
                            {/* <PrintHelper lang={this.props.lang} html={this.state.invoice.invoiceContent} /> */}
                        </ModalBody>
                        <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }}>
                            {(this.state.invoice.invoiceDetails && this.state.invoice.invoiceDetails.active && this.state.invoice.invoiceDetails.activeDocumentNumber && this.state.invoice.invoiceDetails.invoices && this.state.invoice.invoiceDetails.invoices.filter(el => el.documentNumber === this.state.invoice.invoiceDetails.activeDocumentNumber).length && this.state.invoice.invoiceDetails.invoices.filter(el => el.documentNumber === this.state.invoice.invoiceDetails.activeDocumentNumber)[0].pdf) ?
                                <Button color="primary" onClick={() => this.downloadInvoice(this.state.invoice)}> {'Download invoice'.translate(this.props.lang)} </Button>
                                : null
                            }

                            <PdfSave lang={this.props.lang} html={(this.props.lang == 'en' ? this.state.invoice.html : this.state.invoice.htmlSv).replace(/č/g, 'c').replace(/Č/g, 'C').replace(/ć/g, 'c').replace(/Ć/g, 'C')} />
                            <Button color="primary" onClick={() => this.setState({ invoice: null })}> {'Close'.translate(this.props.lang)} </Button>


                        </ModalFooter>
                    </Modal>
                    : null}
                {
                    this.state.cancelSubscriptionModal ?
                        <InfoModal
                            isOpen={this.state.cancelSubscriptionModal}
                            onClose={(item) => {
                                this.setState({
                                    cancelSubscriptionModal: item
                                })
                            }}
                            toggle={() => this.setState({ cancelSubscriptionModal: !this.state.cancelSubscriptionModal })}
                            header={'Warning'.translate(this.props.lang)}
                            info={'Are you sure you want to cancel your subscription? Your plan is still available until day before renewal date.'.translate(this.props.lang)}
                            buttons={[

                                <Button color='primary' onClick={() => {
                                    this.updateUserRecuringTime();

                                }}>{'Yes'.translate(this.props.lang)}</Button>,

                                <Button color='primary' onClick={() => {
                                    this.setState({
                                        cancelSubscriptionModal: false
                                    })

                                }}>{'No'.translate(this.props.lang)}</Button>


                            ]}
                        />
                        :
                        null
                }
                {
                    this.state.reactivateModal ?
                        <InfoModal
                            isOpen={this.state.reactivateModal}
                            onClose={(item) => {
                                this.setState({
                                    reactivateModal: false
                                })
                            }}
                            toggle={() => this.setState({ reactivateModal: !this.state.reactivateModal })}
                            header={'Info'.translate(this.props.lang)}
                            info={"Are you sure you want to reactivate your subscription?".translate(this.props.lang)}
                            buttons={[

                                <Button color='primary' onClick={() => {
                                    this.reactivate();

                                }}>{'Yes'.translate(this.props.lang)}</Button>,

                                <Button color='primary' onClick={() => {
                                    this.setState({
                                        reactivateModal: false
                                    })

                                }}>{'No'.translate(this.props.lang)}</Button>


                            ]}
                        />
                        :
                        null
                }
                {
                    this.state.showConfirmationModal ?

                        <InfoModal
                            isOpen={this.state.showConfirmationModal}
                            onClose={(item) => {
                                this.setState({
                                    showConfirmationModal: item
                                })
                            }}
                            toggle={() => this.setState({ showConfirmationModal: !this.state.showConfirmationModal })}
                            header={'Warning'.translate(this.props.lang)}
                            info={' If you wish to upgrade your plan, please note that upgrading will result in the termination of your existing subscription.'.translate(this.props.lang)}
                            buttons={[

                                <Button color='primary' onClick={() => {
                                    this.updateUserRecuringTime();

                                }}>{'Yes'.translate(this.props.lang)}</Button>,

                                <Button color='primary' onClick={() => {
                                    this.setState({
                                        showConfirmationModal: false
                                    })

                                }}>{'No'.translate(this.props.lang)}</Button>


                            ]}
                        />

                        :
                        null
                }
            </div>

        );
    }
}


export default Page(VideoPayments);
