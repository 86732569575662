import { Device } from 'mediasoup-client';
import socketIO from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';

function iosDevice() {
    var userAgent = window.navigator.userAgent;
    if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
        return true;
    }
    else {
        return false;
    }
}

const defaultLogger = {
    log: (obj) => { console.log(obj.message) },
    warn: (obj) => { console.warn(obj.message) },
    error: (obj) => { console.error(obj.message) }
}


let abortController = null;

let currentCallUUID = null;

let logger = null;

let socket = null;
let device = null;
let callStatus = false;

let producer = {
    id: null,
    transport: null,
    audioProducer: null,
    videoProducer: null,

};

let consumers = {};

let videoConsumerStatus = {};
let videoConsumerState = {};
let layers = {};

let mediaServerURI;
let authToken;
let networkType = null;

const clearState = () => {
    try {
        if (window && window.navigator && window.navigator.connection){
            window.navigator.connection.onchange = null;
        }
    }catch(e) {

    }

    try {
        if (abortController)
            abortController.abort();
    }catch(e){
        console.log(e);
    }

    try {
        if (socket){
            socket.removeAllListeners('volumes');
            socket.removeAllListeners('newClient');
            socket.close()
        }
    } catch (e) {
        console.log(e);
    }

    try {
        if (producer && producer.transport && producer.transport.close) {
            producer.transport.close();
        }
    } catch (e) {
        console.log(e);
    }


    try {
        for (var client in consumers) {

            if (consumers[client] && consumers[client].reconnectTimeout) {
                clearTimeout(consumers[client].reconnectTimeout);
                consumers[client].reconnectTimeout = null;
            }

            if (consumers[client] && consumers[client].transport && consumers[client].transport.close) {
                consumers[client].transport.close();
                delete consumers[client].transport;
                delete consumers[client];
            }
        }
    } catch (e) {
        console.log(e);
    }

    try {
        if (producer && producer.reconnectTimeout) {
            clearTimeout(producer.reconnectTimeout);
        }
    } catch (e) {
        console.log(e);
    }

    try {
        if (producer && producer.transport && producer.transport.close) {
            producer.transport.close();
            delete producer.transport;
        }
    } catch (e) {
        console.log(e);
    }


    producer = {
        id: null,
        transport: null,
        audioProducer: null,
        videoProducer: null,

    };
    consumers = {};
    videoConsumerStatus = {};
    videoConsumerState = {};
    socket = null;
    device = null;
    callStatus = false;
    authToken = null;
    mediaServerURI = null;
    logger = null;
    networkType = null;
}

const clearStateAndStopWorker = () => {
    stopWorker().then(() => {
        clearState();
    }).catch(() => {
        clearState();
    })
}


const generateHTTPHeaders = () => {
    return {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
    }
}

const initWorker = () => {
    return fetch(mediaServerURI + `/media/init`, {
        method: 'GET',
        headers: generateHTTPHeaders(),
        signal: abortController?.signal
    }).then((res) => {
        if (!res.ok)
            throw new Error(`Error! status: ${res.status}`);

        return res.json();
    });
}

const stopWorker = () => {
    return fetch(mediaServerURI + `/media/stop`, {
        method: 'GET',
        headers: generateHTTPHeaders(),
        signal: abortController?.signal
    }).then((res) => {
        return res.json();
    });
}


const createProducerTransport = () => {
    return fetch(mediaServerURI + `/media/produce/init`, {
        method: 'GET',
        headers: generateHTTPHeaders(),
        signal: abortController?.signal
    }).then((res) => {
        if (!res.ok)
            throw new Error(`Error! status: ${res.status}`);

        return res.json();
    });
}
const connectProducerTransport = (data) => {
    return fetch(mediaServerURI + `/media/produce/connect`, {
        method: 'POST',
        headers: generateHTTPHeaders(),
        signal: abortController?.signal,
        body: JSON.stringify(data)
    }).then((res) => {
        if (!res.ok)
            throw new Error(`Error! status: ${res.status}`);

        return res.json();
    });
}
const produce = (data) => {
    return fetch(mediaServerURI + `/media/produce/start`, {
        method: 'POST',
        headers: generateHTTPHeaders(),
        signal: abortController?.signal,
        body: JSON.stringify(data)
    }).then((res) => {
        if (!res.ok)
            throw new Error(`Error! status: ${res.status}`);

        return res.json();
    });
}


const createConsumerTransport = (client) => {
    return fetch(mediaServerURI + `/media/consume/${client}/init`, {
        method: 'GET',
        headers: generateHTTPHeaders(),
        signal: abortController?.signal,
    }).then((res) => {
        if (!res.ok)
            throw new Error(`Error! status: ${res.status}`);

        return res.json();
    });
}

const connectConsumerTransport = (client, data) => {
    return fetch(mediaServerURI + `/media/consume/${client}/connect`, {
        method: 'POST',
        headers: generateHTTPHeaders(),
        signal: abortController?.signal,
        body: JSON.stringify(data)
    }).then((res) => {
        if (!res.ok)
            throw new Error(`Error! status: ${res.status}`);

        return res.json();
    });
}
const consume = (client, data) => {
    return fetch(mediaServerURI + `/media/consume/${client}/start`, {
        method: 'POST',
        headers: generateHTTPHeaders(),
        signal: abortController?.signal,
        body: JSON.stringify(data)
    }).then((res) => {
        if (!res.ok)
            throw new Error(`Error! status: ${res.status}`);

        return res.json();
    });
}

const resume = (client, data) => {
    return fetch(mediaServerURI + `/media/consume/${client}/resume`, {
        method: 'POST',
        headers: generateHTTPHeaders(),
        signal: abortController?.signal,
        body: JSON.stringify(data)
    }).then((res) => {
        if (!res.ok)
            throw new Error(`Error! status: ${res.status}`);

        return res.json();
    });
}
const pause = (client, data) => {
    return fetch(mediaServerURI + `/media/consume/${client}/pause`, {
        method: 'POST',
        headers: generateHTTPHeaders(),
        signal: abortController?.signal,
        body: JSON.stringify(data)
    }).then((res) => {
        if (!res.ok)
            throw new Error(`Error! status: ${res.status}`);

        return res.json();
    });
}

const changeStreamLayer = (client, layer) => {
    return fetch(mediaServerURI + `/media/consume/${client}/layer/${layer}`, {
        method: 'GET',
        headers: generateHTTPHeaders(),
        signal: abortController?.signal,
    }).then((res) => {
        if (!res.ok)
            throw new Error(`Error! status: ${res.status}`);

        return res.json();
    });
}

const checkProducer = (client) => {
    return fetch(mediaServerURI + `/media/consume/${client}/check`, {
        method: 'GET',
        headers: generateHTTPHeaders(),
        signal: abortController?.signal,
    }).then((res) => {
        if (!res.ok)
            throw new Error(`Error! status: ${res.status}`);

        return res.json();
    });
}


const loadDevice = async (routerRtpCapabilities, callback) => {
    try {
        device = new Device();
    } catch (error) {
        if (error.name === 'UnsupportedError') {
            console.error('browser not supported');
        }
    }
    await device.load({ routerRtpCapabilities });
    callback();
    //socket.emit('createProducerTransport', {});
}




const loadConsumerDevice = async (routerRtpCapabilities) => {
    let consumerDevice = null;

    try {
        consumerDevice = new Device();
    } catch (error) {
        if (error.name === 'UnsupportedError') {
            console.error('browser not supported');
        }
    }
    await consumerDevice.load({ routerRtpCapabilities });
    return consumerDevice;
}

const init = (mediaServer, authorizationToken, id, stream, consumerCallback, volumesCallback, reconnectCallback, screenStream = null, loggerHandler = null, clearLogger = null) => {

    const consumeStream = (client, kind) => {
        if (!consumers[client]) {
            return;
        }

        if (!consumers[client].device) {
            return;
        }


        const { rtpCapabilities } = consumers[client].device;

        consume(client, { rtpCapabilities: rtpCapabilities, kind }).then(async (data) => {
            if (!consumers[client]) {
                return;
            }

            const {
                producerId,
                id,
                kind,
                rtpParameters,

            } = data;

            if (!consumers[client]) {
                return;
            }


            if (producerId) {
                let codecOptions = {};
                const consumer = await consumers[client].transport.consume({
                    id,
                    producerId,
                    kind,
                    rtpParameters,
                    codecOptions,
                });

                if (!consumers[client].consumers) {
                    consumers[client].consumers = {};
                }
                consumers[client].consumers[kind] = consumer;


                consumerCallback({
                    id: client,
                    track: consumer.track
                })


                if (consumer) {
                    if (kind === 'video') {
                        videoConsumerStatus[client] = true;

                        if (videoConsumerState[client]) {
                            resume(client, { kind: kind })
                        }

                    }
                    else {
                        resume(client, { kind: kind })
                    }
                }
                else {
                    return null;
                }
            }
            else {
                setTimeout(() => {
                    consumeStream(client, kind);
                }, 2000);
                return null;
            }

        }).catch(err => {
            if (err && err.name === 'AbortError'){
                return;
            }

            if (logger && logger.error) {
                logger.error({
                    message: `Error while trying to consume stream from [${client}]\n${err}`,
                    type: 'consume',
                    data: {
                        client,
                        kind
                    },
                    tryAgain: () => consumeStream(client, kind)
                });
            }
        })
    }

    const initConsumer = (client) => {
        if (consumers[client] && consumers[client].isInitialised){
            return;
        }

        if (consumers[client] && consumers[client].transport) {
            consumers[client].transport.close();
            delete consumers[client].transport;
            delete consumers[client];
        }


        consumers[client] = {
            isInitialised: true
        }

        consumerCallback({
            id: client,
            status: 'closed'
        })


        createConsumerTransport(client).then(async (data) => {
            videoConsumerStatus[client] = false;

            consumerCallback({
                id: client,
                status: 'connecting',
                _new: true
            })

            consumers[client] = {
                device: await loadConsumerDevice(data.rtpCapabilities),
                transport: null,
                videoConsumer: null,
                audioConsumer: null,
                createConsumerTransportCallback: null,
                state: null

            }

            consumers[client].transport = consumers[client].device.createRecvTransport(data);

            consumers[client].transport.on('connect', async ({ dtlsParameters }, callback, errback) => {
                connectConsumerTransport(client, { dtlsParameters: dtlsParameters }).then((data) => {
                    consumers[client].isInitialised = false;
                    callback(data);
                }).catch(err => {
                    errback();

                    if (err && err.name === 'AbortError'){
                        return;
                    }

                    if (logger && logger.error) {

                        logger.error({
                            message: `Failed to connect consumer transport[${client}]\n${err}`,
                            type: 'connectConsumerTransport',
                            data: {
                                client
                            },
                            tryAgain: () => {
                                checkProducer(client).then(() => {
                                    initConsumer(client);
                                }).catch(err => {
                                    if (err && err.name === 'AbortError'){
                                        return;
                                    }
                                    
                                    if (logger && logger.warn)
                                        logger.warn(`Error producer [${client}] died\n`, err);
                                })
                            }
                        });
                    }

                })

            });

            let callUUID = String(currentCallUUID);

            consumers[client].transport.on('connectionstatechange', (state) => {
                logger.log({ message: `connectionstatechange [${client}:${state}]` });
                consumers[client].state = state;

                switch (state) {
                    case 'connecting':
                        consumerCallback({
                            id: client,
                            status: 'connecting'
                        })

                        break;

                    case 'connected':
                        consumerCallback({
                            id: client,
                            status: 'connected'
                        })

                        if (consumers[client] && consumers[client].reconnectTimeout) {
                            clearTimeout(consumers[client].reconnectTimeout);
                            consumers[client].reconnectTimeout = null;
                        }

                        break;

                    case 'disconnected':
                        consumerCallback({
                            id: client,
                            status: 'disconnected'
                        })

                        if (callUUID !== currentCallUUID)
                            return;

                        consumers[client].reconnectTimeout = setTimeout(() => {
                            if (callUUID !== currentCallUUID)
                                return;

                            if (consumers[client]){
                                consumers[client].isInitialised = false;
                                consumers[client].reconnectTimeout = null;
                            }
                            
    
                            consumerCallback({
                                id: client,
                                status: 'closed'
                            })
    

                            checkProducer(client).then(() => {
                                initConsumer(client);
                            }).catch(err => {
                                if (err && err.name === 'AbortError'){
                                    return;
                                }
                                
                                if (logger && logger.warn)
                                    logger.warn(`Error producer [${client}] died\n`, err);
                            })
                        }, 3000);


                        break;
                    case 'closed':
                        consumerCallback({
                            id: client,
                            status: 'closed'
                        })

                        if (consumers[client] && consumers[client].reconnectTimeout) {
                            clearTimeout(consumers[client].reconnectTimeout);
                            consumers[client].reconnectTimeout = null;
                        }


                        delete consumers[client];


                        break;

                    case 'failed':
                        consumerCallback({
                            id: client,
                            status: 'failed'
                        })


                        if (consumers[client] && consumers[client].reconnectTimeout) {
                            clearTimeout(consumers[client].reconnectTimeout);
                            consumers[client].reconnectTimeout = null;
                        }


                        delete consumers[client];
                        break;


                    default:
                        break;
                }
            });


            consumeStream(client, 'video');
            consumeStream(client, 'audio');


        }).catch(err => {
            if (err && err.name === 'AbortError'){
                return;
            }

            if (consumers[client]){
                consumers[client].isInitialised = false;
            }

            if (logger && logger.error) {

                logger.error({
                    message: `Failed to create consumer transport[${client}]\n${err}`,
                    type: 'createConsumerTransport',
                    data: {
                        client
                    },
                    tryAgain: () => {
                        checkProducer(client).then(() => {
                            initConsumer(client);
                        }).catch(err => {
                            if (err && err.name === 'AbortError'){
                                return;
                            }
                            
                            if (logger && logger.warn)
                                logger.warn(`Error producer [${client}] died\n`, err);
                        })
                    }
                });
            }

        })
    }

    const initProducer = (fromErrorHandler=false, initialiseFromErrorHandler=false) => {
        if (producer) {

            if (producer.transport) {
                producer.transport.close();
                delete producer.transport;
            }

            producer.transport = null;
            producer.audioProducer = null;
            producer.videoProducer = null;

        }

        createProducerTransport().then(async (data) => {



            producer.transport = device.createSendTransport(data);
            

            producer.transport.on('connect', async ({ dtlsParameters }, callback, errback) => {
                //console.log('--trasnport connect');


                connectProducerTransport({ dtlsParameters: dtlsParameters }).then((data) => {
                    let clients = data.clients;
                    delete data.clients;

                    callback(data);

                    clients.forEach(client => {
                        if (client && !consumers[client])
                            initConsumer(client)
                    });

                }).catch(err => {
                    if (err && err.name === 'AbortError'){
                        return;
                    }

                    errback();

                    if (logger && logger.error) {

                        logger.error({
                            message: `Failed to connect producer transport\n${err}`,
                            type: 'connectProducerTransport',
                            data: {

                            },
                            tryAgain: () => {
                                checkProducer(producer.id).then(() => {
                                    initProducer()
                                }).catch(err => {
                                    if (err && err.name === 'AbortError'){
                                        return;
                                    }
                        
                                    initialise();
                                })
                            }
                        });
                    }


                });

            });

            producer.transport.on('produce', async ({ kind, rtpParameters }, callback, errback) => {
                produce({
                    transportId: producer.transport.id,
                    kind,
                    rtpParameters,
                }).then((data) => {
                    callback({ id: data.id });
                }).catch(err => {
                    if (err && err.name === 'AbortError'){
                        return;
                    }


                    if (logger && logger.error) {

                        logger.error({
                            message: `Failed to produce stream "${kind}"\n${err}`,
                            type: 'produce',
                            data: {
                                kind
                            },
                            tryAgain: () => {
                                checkProducer(producer.id).then(() => {
                                    initProducer()
                                }).catch(err => {
                                    if (err && err.name === 'AbortError'){
                                        return;
                                    }
                        
                                    initialise();
                                })
                            }
                        });
                    }

                    errback(err);
                });
            });

            let callUUID = String(currentCallUUID);

            producer.transport.on('connectionstatechange', (state) => {
                logger.log({ message: `connectionstatechange [producer:${state}]` });

                if (!callStatus) {
                    return;
                }

                switch (state) {
                    case 'connecting':
                        break;

                    case 'connected':
                        if (producer.reconnectTimeout) {
                            clearTimeout(producer.reconnectTimeout);
                            producer.reconnectTimeout = null;
                        }
                        break;
                    case 'disconnected':
                        producer.reconnectTimeout = setTimeout(() => {
                            if (callUUID !== currentCallUUID)
                                return;


                            try {
                                if (producer && producer.transport && producer.transport.close) {
                                    producer.transport.close();
                                }
                            } catch (e) {
                                console.log(e);
                            }

                            console.log('producer failed');

                            checkProducer(producer.id).then(() => {
                                if (callUUID !== currentCallUUID)
                                    return;

                                initProducer()
                            }).catch(err => {
                                if (err && err.name === 'AbortError'){
                                    return;
                                }
                    
                                initialise();
                            })

                            // if (reconnectCallback) {
                            //     reconnectCallback();
                            // }

                        }, 3000);

                        break;

                    case 'failed':
                        if (callUUID !== currentCallUUID)
                            return;

                        if (producer.reconnectTimeout) {
                            clearTimeout(producer.reconnectTimeout);
                            producer.reconnectTimeout = null;
                        }


                        try {
                            if (producer && producer.transport && producer.transport.close) {
                                producer.transport.close();
                            }
                        } catch (e) {
                            console.log(e);
                        }

                        console.log('producer failed');

                        checkProducer(producer.id).then(() => {
                            initProducer()
                        }).catch(err => {
                            if (err && err.name === 'AbortError'){
                                return;
                            }
                
                            initialise();
                        })

                        // if (reconnectCallback) 
                        //     reconnectCallback();

                        break;

                    default:
                        break;
                }

            });

            if (screenStream) {

                producer.videoProducer = await producer.transport.produce({ track: screenStream.getVideoTracks()[0], encodings: [{ scaleResolutionDownBy: 3, maxBitrate: 400000 }, { scaleResolutionDownBy: 1, maxBitrate: 4000000 }, { scaleResolutionDownBy: 6, maxBitrate: 300000 }], stopTracks: false });
                producer.audioProducer = await producer.transport.produce({ track: stream.getAudioTracks()[0], stopTracks: false });




            } else {

                producer.videoProducer = await producer.transport.produce({
                    track: stream.getVideoTracks()[0], codec: typeof window !== 'undefined' && iosDevice() ? device.rtpCapabilities.codecs
                        .find((codec) => codec.mimeType.toLowerCase() === 'video/h264') : device.rtpCapabilities.codecs
                            .find((codec) => codec.mimeType.toLowerCase() === 'video/vp8')
                    , encodings: [{ scaleResolutionDownBy: 3, maxBitrate: 400000 }, { scaleResolutionDownBy: 1, maxBitrate: 4000000 }, { scaleResolutionDownBy: 6, maxBitrate: 300000 }], codecOptions: { videoGoogleStartBitrate: 1000 }, stopTracks: false
                });


                producer.audioProducer = await producer.transport.produce({ track: stream.getAudioTracks()[0], stopTracks: false });
            }



        }).catch(err => {
            if (err && err.name === 'AbortError'){
                return;
            }

            if (logger && logger.error) {
                logger.error({
                    message: `Failed to create producer transport\n${err}`,
                    type: 'createProducerTransport',
                    data: {

                    },
                    tryAgain: () => {

                        if (initialiseFromErrorHandler){
                            reconnectCallback();
                            return;
                        }


                        if (fromErrorHandler){
                            initialise(true);
                        }else{
                            checkProducer(producer.id).then(() => {
                                initProducer(true)
                            }).catch(err => {
                                if (err && err.name === 'AbortError'){
                                    return;
                                }
                    
                                initialise();
                            })
    
    
                        }


                    }
                });
            }

        });;

    }

    const trackNetwork = (e) => {
        //alert("Network status change "+ navigator.connection.type)
        if (networkType === 'none' && navigator.connection.type !== 'none') {
            initialise();

        }

        networkType = navigator.connection.type;
    }


    const initialise = (initialiseFromErrorHandler=false) => {
        try {
            if (clearLogger){
                clearLogger();
            }
        }catch(e){ }

    

        clearState();

        abortController = new window.AbortController();

        currentCallUUID = String(uuidv4());
        mediaServerURI = mediaServer;
        authToken = authorizationToken;
        callStatus = true;
        producer.id = id;

        if (!loggerHandler) {
            logger = defaultLogger;
        } else {
            logger = loggerHandler;
        }


        if (window && window.navigator && window.navigator.connection){
            window.navigator.connection.onchange = trackNetwork;
        }

        stopWorker().then(() => {
            initWorker().then((data) => {
                loadDevice(data.rtpCapabilities, () => {
                    initProducer(false, initialiseFromErrorHandler);
    
    
                    socket = socketIO.connect(mediaServerURI, { transports: ['websocket', 'polling'], query: { token: authToken } })
    
                    socket.on('connect', () => {
    
                    })
    
                    socket.on('newClient', ({ client }) => {
                        if (!client) return;
    
    
                        if (consumers && consumers[client] && consumers[client].reconnectTimeout){
                            return;
                        }
    
                        initConsumer(client);
                    })
    
                    socket.on('volumes', (data) => {
    
                        if (producer && data.length && data.length == 2 && data[0].id == producer.id) {
                            let tmp = { ...data[0] };
                            data[0] = data[1];
                            data[1] = tmp;
                        }
    
                        volumesCallback(data)
                    })
    
    
                }).catch(err => {
                    if (err && err.name === 'AbortError'){
                        return;
                    }
    
                    if (logger && logger.error) {
    
                        logger.error({
                            message: `Failed to load device\n${err}`,
                            type: 'loadDevice',
                            data: {
    
                            },
                            tryAgain: () => initialise()
                        });
                    }
    
                });
            }).catch(err => {
    
                if (err && err.name === 'AbortError'){
                    return;
                }
    
                if (logger && logger.error) {
                    logger.error({
                        message: `Failed to init worker\n${err}`,
                        type: 'initWorker',
                        data: {
    
                        },
                        tryAgain: () => initialise()
                    });
                }
            });
        }).catch(err => {
    
            if (err && err.name === 'AbortError'){
                return;
            }

            if (logger && logger.error) {
                logger.error({
                    message: `Failed to init worker\n${err}`,
                    type: 'initWorker',
                    data: {

                    },
                    tryAgain: () => initialise()
                });
            }
        });
        
    }


    initialise();
}

const replaceStream = (stream, onlyAudio=false) => {
    try {
        if (!onlyAudio && producer && producer.videoProducer && stream.getVideoTracks() && stream.getVideoTracks()[0] && stream.getVideoTracks()[0].readyState !== 'ended')
            producer.videoProducer.replaceTrack({ track: stream.getVideoTracks()[0] });

        if (producer && producer.audioProducer && stream.getAudioTracks() && stream.getAudioTracks()[0] && stream.getAudioTracks()[0].readyState !== 'ended')
            producer.audioProducer.replaceTrack({ track: stream.getAudioTracks()[0] });
    } catch (e) {
        console.log(e);
    }
}

const replaceVideoStream = async (stream, enabled) => {
    if (producer && producer.videoProducer) {
        await producer.videoProducer.replaceTrack({ track: stream.getVideoTracks()[0] });
        setTimeout(() => {
            producer.videoProducer.track.enabled = enabled;
        }, 500);
    }
}


const changeLayer = (id, layer) => {

    if (layers[id] == layer) {
        return;
    }

    changeStreamLayer(id, layer);
    layers[id] = layer;
}

const playVideo = (id) => {

    let cnt = 0;
    for (var key in videoConsumerState) {
        if (videoConsumerState[key])
            cnt++;
    }

    if (cnt >= 20) {
        return;
    }

    if (!videoConsumerState[id]) {
        if (videoConsumerStatus[id])
            resume(id, { kind: 'video' });

        if (consumers[id] && consumers[id].consumers && consumers[id].consumers['video']) {
            consumers[id].consumers['video'].resume();
        }
        videoConsumerState[id] = true;

    }
}
const pauseVideo = (id) => {
    if (videoConsumerState[id]) {
        if (videoConsumerStatus[id])
            pause(id, { kind: 'video' });

        if (consumers[id] && consumers[id].consumers && consumers[id].consumers['video']) {
            consumers[id].consumers['video'].pause();
        }

        videoConsumerState[id] = false;
    }
}

module.exports = {
    init,
    changeLayer,
    replaceStream,
    replaceVideoStream,
    playVideo,
    pauseVideo,
    clearState: clearStateAndStopWorker,
}