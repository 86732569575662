import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import Link from '../link';

import Check from './fields/check';
import Text from './fields/text';
import Select from './fields/select';
import Textarea from './fields/textarea';
import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
    Label
} from 'reactstrap';

const required = value => value ? undefined : "Required"

function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}


const renderCheckField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },

}) => (

    <Check
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        // multilang={multilang}
        // lang={lang}

        {...input}
    />
)

const renderSelectField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    children,
    additionalAction,
    scope,
}) => (

    <Select
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        additionalAction={additionalAction}
        scope={scope}
        {...input}
        children={children}
    />
)

const renderTextField = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type
}) => (

    <Text
        placeholder={placeholder}
        errorText={touched && error}
        label={label}
        error={touched && error}
        type={type}
        {...input}
    />
)

const renderTextareaField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
}) => (

    <Textarea
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        {...input}
    />
)


class form extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
        }
    }



    render() {

        const { handleSubmit, pristine, reset, submitting, menu, supportCategories } = this.props;
        // console.log(pristine, submitting);
        console.log(this.props)

        return (
            <form onSubmit={handleSubmit} className="contact-form support-form">
            <h3>{'Contact our support'.translate(this.props.lang)}</h3>
                <Row>
                    {/* <Col lg="6">
                        <div className="field-wrap"> */}
                            {/*
                            <Field
                                name="support"
                                component={renderSelectField}
                                label={"Support".translate(this.props.lang)}
                                //validate={[required]}
                            >
                             {
                                 this.props.supportCategories && this.props.supportCategories.map((supportCategorie, idx) => {
                                     return (
                                         <option value={supportCategorie.name}>
                                             {supportCategorie.name}
                                         </option>
                                     )
                                 })
                             }
                        
                            </Field>
                            */}
                        {/* </div>
                    </Col> */}
                    <Col lg="6">
                        <div className="field-wrap">
                            <Field
                                name="name"
                                component={renderTextField}
                                // label={"Name".translate(this.props.lang)}
                                // validate={[required]}
                                placeholder={'Name'.translate(this.props.lang)}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="6">
                        <div className="field-wrap">
                            <Field
                                name="country"
                                component={renderTextField}
                                // label={"Country".translate(this.props.lang)}
                                placeholder={'Country'.translate(this.props.lang)}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="6">
                        <div className="field-wrap">
                            <Field
                                name="title"
                                component={renderTextField}
                                // label={"Title".translate(this.props.lang)}
                                placeholder={'Job title'.translate(this.props.lang)}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="6">
                        <div className="field-wrap">
                            <Field
                                name="organization"
                                component={renderTextField}
                                // label={"Organization".translate(this.props.lang)}
                                placeholder={'Organization'.translate(this.props.lang)}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="6">
                        <div className="field-wrap">
                            <Field
                                name="email"
                                type="email"
                                component={renderTextField}
                                // label={"Email".translate(this.props.lang)}
                                validate={[required]}
                                placeholder={'Work email'.translate(this.props.lang)}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="6">
                        <div className="field-wrap">
                            <Field
                                name="phone"
                                component={renderTextField}
                                // label={"Phone".translate(this.props.lang)}
                                placeholder={'Phone'.translate(this.props.lang)}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name="message"
                                component={renderTextareaField}
                                // label={"Message".translate(this.props.lang)}
                                // validate={[required]}
                                placeholder={'Message'.translate(this.props.lang)}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="12">
                        <div className="field-wrap">
                             <Field
                                name="checked"
                                component={renderCheckField}
                                placeholder=""
                                label={<Label>{"I accept the website".translate(this.props.lang)}  
                                <span> <Link lang={this.props.lang} to="/integritetspolicy-webbsida" target="_blank">{'privacy policy'.translate(this.props.lang)}</Link> </span></Label>}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="12" className="btn-wrap">
                        <button type="submit" >{'Send'.translate(this.props.lang)}</button>
                    </Col>
                </Row>
            </form>

        )
    }
}

export default reduxForm({
    form: 'supportForm'  // a unique identifier for this form
})(form)