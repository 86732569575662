import React, { Component } from 'react';

import Isvg from 'react-inlinesvg';
import {
    Input
} from 'reactstrap';
import arrowDown from '../../../assets/svg/search.svg';

class SelectWithSearch extends Component {
    constructor(props) {
        super(props);
        this.wrapperRef = React.createRef();

        this.state = {
        };
    }

    componentDidMount() {

    }

    render() {
        let items = [];
        if (this.props.items && this.props.items.filter(item => !this.state.search || (this.state.search && item.name && item.name.toLowerCase().indexOf(this.state.search) !== -1)).length) {
            items = this.props.items.filter(item => !this.state.search || (this.state.search && item.name && item.name.toLowerCase().indexOf(this.state.search) !== -1));
        }

        return (
            <div className='custom-select-with-search-wrap' style={this.props.minWidth ? {minWidth: this.props.minWidth} : {}}>
                <div className='custom-select-with-search-input-wrap'>
                    <Input placeholder={'Search'.translate(this.props.lang)} type='text' value={this.state.search} onChange={(e) => { this.setState({ search: e.target.value }) }} />
                    <Isvg src={arrowDown} />
                </div>
                <div className='custom-select-with-search-items-wrap' style={this.props.maxHeight ? { maxHeight: this.props.maxHeight } : {}}>
                    {
                        items.map((item, idx) => {
                            return (
                                <div key={idx} className={this.props.value && item && item.value && this.props.value === item?.value ? "custom-select-with-search-item acitve-item" : "custom-select-with-search-item"} onClick={() => {
                                    // this.setState({
                                    //     search: item.name
                                    // })
                                    this.props.onChange(item)}}>
                                    {item?.name}
                                </div>
                            )
                        })
                    }
                </div>
            </div>

        );
    }
}

export default SelectWithSearch;
