import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    Button,
    Modal, ModalBody, ModalFooter, ModalHeader,
    Input
} from 'reactstrap';


import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

import ListBuilder from '../../components/listBuilder';

import viewIcon from '../../assets/svg/eye.svg';
import Isvg from 'react-inlinesvg'

import { Document, Page as PdfPage, pdfjs } from 'react-pdf';



function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}


class FortnoxSettings extends Component {
    constructor(props) {
        super(props);

        if (typeof window != 'undefined')
            pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

        this.player = React.createRef();
        this.player2 = React.createRef();
        this.state = {
            ...props.initialData,
            form: false,
            checkedConnection: false,
            invoices: [],
            myinvoices: [],
            numPages: 0,
            pageNumber: 1

            // loading: true,
        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();
        this.checkConnection()
        this.getInvoices()

        this.checkInterval = setInterval(() => {
            if (!this.state.fortnoxConnected) {
                this.checkConnection()
            } else {
                if (this.checkInterval) {
                    clearInterval(this.checkInterval)
                }
            }
        }, 5000);


    }
    componentWillUnmount() {
        if (this.checkInterval) {
            clearInterval(this.checkInterval)
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }
    }


    stopAnimation = () => {
        this.player.current.play();
    }
    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 },
                    [
                        {
                            name: 'sortField', value: field
                        }
                        ,
                        {
                            name: 'sortType', value: type
                        }
                    ],
                    false));

        }
    }


    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 }, fields, restart));
        }
    }


    connectFortnox = () => {

        fetch(API_ENDPOINT + "/fortnox/connect", {
            method: "GET",
            headers: {
                Authorization:
                    typeof localStorage !== "undefined"
                        ? `Bearer ${localStorage.getItem("authToken")}`
                        : null,
                "content-type": "application/json",
            }
        }).then(parseJSON).then(({ result, status }) => {
            if (result && result.url) {
                this.setState({ fortnoxUrl: result.url })
                window.open(result.url, '_blank', `resizable=0,width=360,height=640,top=30,left=30`)
            }
        });
    }

    checkConnection = () => {


        fetch(API_ENDPOINT + "/fortnox/connection/check", {
            method: "GET",
            headers: {
                Authorization:
                    typeof localStorage !== "undefined"
                        ? `Bearer ${localStorage.getItem("authToken")}`
                        : null,
                "content-type": "application/json",
            }
        }).then(parseJSON).then(({ result, status }) => {
            if (result && result.connection && !this.state.fortnoxConnected) {
                this.setState({ fortnoxConnected: true, checkedConnection: true })
            }else{
                this.setState({ checkedConnection: true })
            }

        });
    }

    getInvoices = () => {


        fetch(API_ENDPOINT + "/fortnox/invoices", {
            method: "GET",
            headers: {
                Authorization:
                    typeof localStorage !== "undefined"
                        ? `Bearer ${localStorage.getItem("authToken")}`
                        : null,
                "content-type": "application/json",
            }
        }).then(parseJSON).then(({ result, status }) => {
            if (result && result.invoices) {
                this.setState({ invoices: result.invoices })
            }
            if (result && result.myinvoices) {
                this.setState({ myinvoices: result.myinvoices })
            }


        });
    }


    previewInvoice = (item) => {
        if (item && item.DocumentNumber) {
            this.setState({ loading: true }, () => {
                fetch(API_ENDPOINT + "/fortnox/invoices/preview/" + item.DocumentNumber, {
                    method: "GET",
                    headers: {
                        Authorization:
                            typeof localStorage !== "undefined"
                                ? `Bearer ${localStorage.getItem("authToken")}`
                                : null,
                        "content-type": "application/json",
                    }
                }).then(parseJSON).then(({ result, status }) => {
                    if (result && result.invoice) {
                        // this.setState({ invoices: result.invoices })
                        // window.open(`${API_ENDPOINT}${result.invoice}`)
                        this.setState({ previewIvoiceModal: { ...item, preview: `${API_ENDPOINT}${result.invoice}` }, loading: false })
                    } else {
                        this.setState({ loading: false })

                    }

                });
            })
        }

    }

    onDocumentLoadSuccess = ({ numPages }) => {
        if (this.state.numPages != numPages)
            this.setState({
                numPages: numPages
            })
    }


    nextPage = () => {
        if (this.state.pageNumber < this.state.numPages)
            this.setState({
                pageNumber: this.state.pageNumber + 1
            })
    }
    backPage = () => {
        if (this.state.pageNumber != 1)
            this.setState({
                pageNumber: this.state.pageNumber - 1
            })
    }


    insertInvoice = () => {
        fetch(API_ENDPOINT + "/fortnox/invoices/create-new", {
            method: "POST",
            headers: {
                Authorization:
                    typeof localStorage !== "undefined"
                        ? `Bearer ${localStorage.getItem("authToken")}`
                        : null,
                "content-type": "application/json"

            },
            body: JSON.stringify({ id: this.state.billId })
        }).then(parseJSON).then(({ result, status }) => {
            if (!result.error) {
                this.setState({ billId: '' })
                this.getInvoices()
            }


        });
    }
    cancelInvoice = () => {
        fetch(API_ENDPOINT + "/fortnox/invoices/delete", {
            method: "POST",
            headers: {
                Authorization:
                    typeof localStorage !== "undefined"
                        ? `Bearer ${localStorage.getItem("authToken")}`
                        : null,
                "content-type": "application/json"

            },
            body: JSON.stringify({ documentNumber: this.state.invoiceForDelete })
        }).then(parseJSON).then(({ result, status }) => {
            this.setState({ invoiceForDelete: '' })
            this.getInvoices()


        });

    }
    bookkeepingInvoice = () => {
        fetch(API_ENDPOINT + "/fortnox/invoices/bookkeeping", {
            method: "POST",
            headers: {
                Authorization:
                    typeof localStorage !== "undefined"
                        ? `Bearer ${localStorage.getItem("authToken")}`
                        : null,
                "content-type": "application/json"

            },
            body: JSON.stringify({ documentNumber: this.state.invoiceForBookkeeping })
        }).then(parseJSON).then(({ result, status }) => {
            this.setState({ invoiceForBookkeeping: '' })
            this.getInvoices()


        });

    }
    setDoneInvoice = () => {
        fetch(API_ENDPOINT + "/fortnox/invoices/done", {
            method: "POST",
            headers: {
                Authorization:
                    typeof localStorage !== "undefined"
                        ? `Bearer ${localStorage.getItem("authToken")}`
                        : null,
                "content-type": "application/json"

            },
            body: JSON.stringify({ documentNumber: this.state.invoiceDone })
        }).then(parseJSON).then(({ result, status }) => {
            this.setState({ invoiceDone: '' })
            this.getInvoices()


        });

    }


    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 })
        }
        return (
            <div className="dashboard fortnox-wrap" >

                <Container fluid >
                    <Row >
                        <Col lg="12">
                            <div className="panel">
                                <div className="flex-between-panel">
                                    <h5 className="component-header">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h5>
                                </div>

                                <div className='connect-fortnox-wrap'>
                                    <div className={`fortnox-box ${this.state.fortnoxConnected && this.state.checkedConnection ? 'connected' : this.state.checkedConnection ? 'not-connected' : ''}`}>
                                        <h4>{'FORTNOX'.translate(this.props.lang)}</h4>
                                        {
                                            this.state.fortnoxConnected && this.state.checkedConnection ?
                                                <p >{'Connected'.translate(this.props.lang)}</p>
                                                :
                                                this.state.checkedConnection ?
                                                    <p>{'Not connected'.translate(this.props.lang)}</p>
                                                    :
                                                    <p>{'Checking'.translate(this.props.lang)}...</p>
                                        }
                                        {
                                            this.state.checkedConnection ?
                                                <div className='connect-btn-wrap'>
                                                    <Button color="primary" disabled={!this.state.checkedConnection} onClick={() => { this.connectFortnox() }}>{this.state.fortnoxConnected ? 'RECONNECT'.translate(this.props.lang) : 'CONNECT'.translate(this.props.lang)}</Button>
                                                </div>
                                                :
                                                null
                                        }

                                    </div>
                                    {/* {
                                        this.state.checkedConnection ?
                                            <h6>
                                                {'Fortnox'.translate(this.props.lang)}:
                                                {
                                                    this.state.fortnoxConnected ?
                                                        <span style={{ fontWeight: 400, marginLeft: 10, color: 'green' }}>{'Connected'.translate(this.props.lang)}</span>
                                                        :
                                                        <span style={{ fontWeight: 400, marginLeft: 10, color: 'red' }}>{'Not connected'.translate(this.props.lang)}</span>
                                                }
                                            </h6>
                                            :
                                            null
                                    }

                                    <Button color="primary" onClick={() => { this.connectFortnox() }}>{'Connect'.translate(this.props.lang)}</Button> */}

                                    {/* <div style={{ marginTop: 50, display: 'flex', alignItems: 'center' }}>
                                        <Input type='text' style={{ marginRight: 30, width: 300 }} value={this.state.billId} onChange={(e) => this.setState({ billId: e.target.value })} />

                                        <Button color="primary" disabled={this.state.billId && this.state.billId.length === 24 ? false : true} onClick={() => { this.insertInvoice() }}>{'INSERT INVOICE'.translate(this.props.lang)}</Button>
                                    </div>

                                    <div style={{ marginTop: 50, display: 'flex', alignItems: 'center' }}>
                                        <Input type='text' style={{ marginRight: 30, width: 300 }} value={this.state.invoiceForDelete} onChange={(e) => this.setState({ invoiceForDelete: e.target.value })} />

                                        <Button color="primary" disabled={this.state.invoiceForDelete ? false : true} onClick={() => { this.cancelInvoice() }}>{'CANCEL INVOICE'.translate(this.props.lang)}</Button>
                                    </div>
                                    <div style={{ marginTop: 50, display: 'flex', alignItems: 'center' }}>
                                        <Input type='text' style={{ marginRight: 30, width: 300 }} value={this.state.invoiceForBookkeeping} onChange={(e) => this.setState({ invoiceForBookkeeping: e.target.value })} />

                                        <Button color="primary" disabled={this.state.invoiceForBookkeeping ? false : true} onClick={() => { this.bookkeepingInvoice() }}>{'BOOKKEEPING INVOICE'.translate(this.props.lang)}</Button>
                                    </div>
                                    <div style={{ marginTop: 50, display: 'flex', alignItems: 'center' }}>
                                        <Input type='text' style={{ marginRight: 30, width: 300 }} value={this.state.invoiceDone} onChange={(e) => this.setState({ invoiceDone: e.target.value })} />

                                        <Button color="primary" disabled={this.state.invoiceDone ? false : true} onClick={() => { this.setDoneInvoice() }}>{'SET AN INVOICE DONE'.translate(this.props.lang)}</Button>
                                    </div> */}


                                </div>

                            </div>
                        </Col>
                        {/* <Col lg='12'>
                            <div className='panel'>
                                <ListBuilder
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'DocumentNumber', label: 'DocumentNumber'.translate(this.props.lang), allowSort: false },
                                        { type: 'text', name: 'CustomerName', label: 'CustomerName'.translate(this.props.lang), allowSort: false },
                                        { type: 'text', name: 'CustomerNumber', label: 'CustomerNumber'.translate(this.props.lang), allowSort: false },
                                        { type: 'text', name: 'DueDate', label: 'DueDate'.translate(this.props.lang), allowSort: false },
                                        { type: 'text', name: 'InvoiceType', label: 'InvoiceType'.translate(this.props.lang), allowSort: false },
                                        { type: 'text', name: 'Total', label: 'Total'.translate(this.props.lang), allowSort: false },
                                        { type: 'text', name: 'Currency', label: 'Currency'.translate(this.props.lang), allowSort: false },

                                    ]}
                                    rawItems={this.state.myinvoices}
                                    items={this.state.myinvoices.map((item => {
                                        return {
                                            ...item,

                                        }
                                    }))}
                                    actions={
                                        [
                                            {
                                                component: <div className="tabel-action-tooltip"><Isvg src={viewIcon} style={{ width: 24, minWidth: 24 }} /><div style={{ width: 180 }} className="chat-icons-tooltip">{'Preview'.translate(this.props.lang)}</div> </div>,
                                                onClick: (item) => this.previewInvoice(item),

                                            },


                                        ]
                                    }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                    hideFilters={true}
                                    hidePagination={true}
                                    hideEntries={true}
                                ></ListBuilder>
                            </div>
                        </Col>
                        <Col lg='12'>
                            <div className='panel'>
                                <ListBuilder
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'DocumentNumber', label: 'DocumentNumber'.translate(this.props.lang), allowSort: false },
                                        { type: 'text', name: 'CustomerName', label: 'CustomerName'.translate(this.props.lang), allowSort: false },
                                        { type: 'text', name: 'CustomerNumber', label: 'CustomerNumber'.translate(this.props.lang), allowSort: false },
                                        { type: 'text', name: 'DueDate', label: 'DueDate'.translate(this.props.lang), allowSort: false },
                                        { type: 'text', name: 'InvoiceType', label: 'InvoiceType'.translate(this.props.lang), allowSort: false },
                                        { type: 'text', name: 'Total', label: 'Total'.translate(this.props.lang), allowSort: false },
                                        { type: 'text', name: 'Currency', label: 'Currency'.translate(this.props.lang), allowSort: false },

                                    ]}
                                    rawItems={this.state.invoices}
                                    items={this.state.invoices.map((item => {
                                        return {
                                            ...item,

                                        }
                                    }))}
                                    actions={
                                        [
                                            {
                                                component: <div className="tabel-action-tooltip"><Isvg src={viewIcon} style={{ width: 24, minWidth: 24 }} /><div style={{ width: 180 }} className="chat-icons-tooltip">{'Preview'.translate(this.props.lang)}</div> </div>,
                                                onClick: (item) => this.previewInvoice(item),

                                            },


                                        ]
                                    }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                    hideFilters={true}
                                    hidePagination={true}
                                    hideEntries={true}
                                ></ListBuilder>
                            </div>
                        </Col> */}

                        {
                            this.state.previewIvoiceModal && this.state.previewIvoiceModal.preview ?
                                <Modal isOpen={this.state.previewIvoiceModal} centered size="lg">
                                    <ModalHeader>
                                        {'Preview invoce'.translate(this.props.lang)}{this.state.previewIvoiceModal.DocumentNumber ? ` - ${this.state.previewIvoiceModal.DocumentNumber}` : ''}

                                    </ModalHeader>
                                    <ModalBody className='preview-invoice-body-wrap'>
                                        {
                                            typeof window != 'undefined' ?
                                                <div style={{ width: '100%' }} ref={(node) => this.pdfDocument = node}>
                                                    <Document
                                                        file={{ url: this.state.previewIvoiceModal.preview }}
                                                        onLoadSuccess={this.onDocumentLoadSuccess}

                                                        error={"Failed to load PDF file.".translate(this.props.lang)}
                                                        loading={"Loading PDF...".translate(this.props.lang)}
                                                        noData={"No page specified.".translate(this.props.lang)}
                                                    >
                                                        <PdfPage width={this.pdfDocument ? this.pdfDocument.clientWidth : null} pageNumber={this.state.pageNumber} />
                                                    </Document>
                                                    {
                                                        this.state.numPages > 1 ?
                                                            <div className="page-pagination">
                                                                <Button onClick={this.backPage}>{'Previous'.translate(this.props.lang)}</Button>
                                                                <p>{'Page'.translate(this.props.lang)} {this.state.pageNumber} {'of'.translate(this.props.lang)} {this.state.numPages}</p>
                                                                <Button onClick={this.nextPage}>{'Next'.translate(this.props.lang)}</Button>
                                                            </div>
                                                            :
                                                            null
                                                    }


                                                </div>
                                                :
                                                null
                                        }

                                    </ModalBody>
                                    <ModalFooter className='buttons-right-blue' style={{ justifyContent: 'flex-end' }}>
                                        {
                                            this.state.previewIvoiceModal.preview ?
                                                <Button color="primary" onClick={() => {
                                                    let link = document.createElement("a");
                                                    link.download = `Invoice_${this.state.previewIvoiceModal.DocumentNumber}.pdf`;
                                                    link.href = this.state.previewIvoiceModal.preview;
                                                    link.target = '_blank';
                                                    document.body.appendChild(link);
                                                    link.click();
                                                    document.body.removeChild(link);

                                                }}>{"Download pdf".translate(this.props.lang)}</Button>
                                                :
                                                null
                                        }
                                        <Button color="primary" onClick={() => {
                                            this.setState({
                                                previewIvoiceModal: null,
                                                numPages: 0,
                                                pageNumber: 1
                                            })
                                            this.pdfDocument = null;
                                        }}>{"Close".translate(this.props.lang)}</Button>

                                    </ModalFooter>
                                </Modal>
                                :
                                null

                        }

                    </Row>
                </Container>
            </div>
        );
    }
}

export default Page(FortnoxSettings);