import React, { Component } from 'react';
import ListBuilder from './listBuilder';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../helpers/linkHelper';
import { API_ENDPOINT} from '../constants';
import moment from 'moment';
import Isvg from 'react-inlinesvg';
import attachIcon from '../assets/svg/attach.svg';
import viewIcon from '../assets/svg/eye.svg';
import unpinIcon from '../assets/svg/unpin.svg';
import xIcon from '../assets/svg/x.svg';
import denyIcon from '../assets/svg/deny_icon.svg';
import info from '../assets/svg/info.svg'
import PreviewFortnoxInvoice from './previewFortnoxInvoice';
import { Player } from "@lottiefiles/react-lottie-player";
import InfoModal from './infoModal';
import ErrorModal from './errorModal';

import userIcon from '../assets/svg/l-user.svg';
import billingIcon from '../assets/svg/l-card.svg';

import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Button, Table, Input, Label, FormGroup

} from 'reactstrap';


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}




const loadDataWithQuery = [
    (fetchFunction, lang, match, query) => {

        let queryFields = {};
        for (var key in query) {
            if (key && key.indexOf('query-') === 0) {
                queryFields[key.replace('query-', '')] = query[key];
            }
        }

        return fetchFunction(API_ENDPOINT + '/data/invoice/requests', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                page: query.page,
                entries: query.entries,
                filter: query.filter,
                sortType: query.sortType,
                sortField: query.sortField,
                group: query.group,
                query: queryFields,
                request: true
            })
        }).then(parseJSON).then(({ result, status }) => {
            if (status >= 200 && status < 300)
                return {
                    page: query.page ? query.page : 0,
                    items: result.items,
                    total: result.total,
                    resultFilter: result.filter
                }
        })


    },

]

/**
* List of patients for selected clinic and selected department
* @author   Milan Stanojevic
*/
class DeletionRequestsComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            regions: [],
            cities: [],
            groups: [],
            subgroups: [],
            ...props.initialData,
            useState: true,
            params: {
                entries: 10,
                page: 0,
            },
            items: [],
            total: 0,
            loading: true,
            suggestions: [],

        };
    }


    get = () => {
        let params = this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic });
        params.group = this.props.selectedGroup;
        params.clinic = this.props.selectedClinic;
        params._ts = new Date().getTime();

        this.setState({
            _ts: params._ts
        }, () => {
            for (let i = 0; i < loadDataWithQuery.length; i++) {
                loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, params).then((data) => {

                    this.setState({
                        ...data,
                        loading: null
                    })
                })
            }

        })


    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();




    }

    componentWillUnmount() {
    }



    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })

        }

        if (this.state.useState && prevState._useStateTs != this.state._useStateTs) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })

        }
        if (prevProps.selectedGroup != this.props.selectedGroup) {
            this.get();
        }
        if (prevProps.getRequests != this.props.getRequests) {
            this.get();
        }



    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field == 'waitingTime' ? 'waitingMin' : field;
            state.sortType = type;
            this.setState({
                params: state
            })

            this.setState({
                _useStateTs: new Date().getTime()
            })

        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field == 'waitingTime' ? 'waitingMin' : field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };

                obj[name] = value;

                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;


                this.setState({ params: obj })
            }

            this.setState({
                _useStateTs: new Date().getTime()
            })
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }

    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {

                    obj[fields[i].name] = fields[i].value;

                }
                this.setState({ params: obj })
            }

            this.setState({
                _useStateTs: new Date().getTime()
            })

        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }

    attachInvoice = (item) => {

        if (item && item._id) {
            this.setState({ loadingPage: true }, () => {
                fetch(API_ENDPOINT + "/fortnox/invoices/create-new", {
                    method: "POST",
                    headers: {
                        Authorization:
                            typeof localStorage !== "undefined"
                                ? `Bearer ${localStorage.getItem("authToken")}`
                                : null,
                        "content-type": "application/json"

                    },
                    body: JSON.stringify({ id: item._id })
                }).then(parseJSON).then(({ result, status }) => {
                    if (!result.error) {
                        this.setState({ loadingPage: false, successMessage: 'Invoice successfully attached'.translate(this.props.lang), attachInvoice: null, accountDetailsModal: null })
                        this.props.reloadList()
                        this.get()
                    } else {
                        this.setState({ loadingPage: false, error: result.error ? result.error.translate(this.props.lang) : 'Error'.translate(this.props.lang), attachInvoice: null, accountDetailsModal: null })
                    }
                });
            })
        }


    }
    // denyInvoice = (item) => {
    //     if (item && item._id && item.invoiceDetails && item.invoiceDetails.activeDocumentNumber) {
    //         this.setState({ loadingPage: true }, () => {
    //             fetch(API_ENDPOINT + "/fortnox/invoices/delete", {
    //                 method: "POST",
    //                 headers: {
    //                     Authorization:
    //                         typeof localStorage !== "undefined"
    //                             ? `Bearer ${localStorage.getItem("authToken")}`
    //                             : null,
    //                     "content-type": "application/json"

    //                 },
    //                 body: JSON.stringify({ billId: item._id, documentNumber: item.invoiceDetails.activeDocumentNumber, reason: this.state.denyReason ? this.state.denyReason : '' })
    //             }).then(parseJSON).then(({ result, status }) => {
    //                 if (!result.error) {
    //                     this.setState({ loadingPage: false, successMessage: 'Invoice successfully denied'.translate(this.props.lang), denyInvoice: null, denyReason: '' })
    //                     this.get()
    //                 } else {
    //                     this.setState({ loadingPage: false, error: 'Error'.translate(this.props.lang), denyInvoice: null, denyReason: '' })
    //                 }


    //             });
    //         })
    //     }
    // }

    denyInvoice = (data) => {
        console.log(data);
        fetch(API_ENDPOINT + "/update-deny-bill", {
            method: "POST",
            headers: {
                Authorization:
                    typeof localStorage !== "undefined"
                        ? `Bearer ${localStorage.getItem("authToken")}`
                        : null,
                "content-type": "application/json"

            },
            body: JSON.stringify({ id: data._id, notes: this.state.denyReason ? this.state.denyReason : '' })
        }).then(parseJSON).then(({ result, status }) => {
            if (result) {
                this.setState({
                    denyReason: '',
                    markAsPaidModal: null,
                    accountDetailsModal: null,
                    denyInvoice: null
                }, () => { this.get() })

            }

        });
    }




    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }

        console.log(this.state.accountDetailsModal, 'THIS')

        return (
            <div className='delete-request-list'>
                {
                    this.state.loadingPage ?
                        <div className="loader-wrap-page">
                            <Player
                                autoplay={true}
                                loop={true}
                                src="/lf30_editor_l5cxzdyf.json"
                                style={{
                                    height:
                                        typeof window != "undefined" &&
                                            window.innerWidth < 768
                                            ? "128px"
                                            : "256px",
                                    width:
                                        typeof window != "undefined" &&
                                            window.innerWidth < 768
                                            ? "128px"
                                            : "256px",
                                }}
                            ></Player>
                        </div>
                        :
                        null

                }
                <ListBuilder
                    lang={this.props.lang}
                    loading={this.state.loading}
                    total={this.state.total}
                    showNumeration={false}
                    fields={[
                        { type: 'text', name: 'companyName', label: 'Company'.translate(this.props.lang), allowSort: true },
                        { type: 'text', name: 'countryName', label: 'Country'.translate(this.props.lang), allowSort: true },
                        { type: 'text', name: 'startDate', label: 'Date'.translate(this.props.lang), allowSort: true },
                        { type: 'text', name: 'experienceDate', label: 'Renewal'.translate(this.props.lang), allowSort: true },


                    ]}
                    rawItems={this.state.items}
                    items={this.state.items.map(item => {
                        let company
                        let country
                        if (item && item.billingDetails && item.billingDetails.accountDetails && item.billingDetails.accountDetails.sameAsAccountDetails && item.billingDetails.accountDetails.organizationName && item.billingDetails.accountDetails.country) {
                            company = item.billingDetails.accountDetails.organizationName
                            country = item.billingDetails.accountDetails.country
                        } else if (item && item.billingDetails && item.billingDetails.accountDetailsBilling && !item.billingDetails.accountDetails.sameAsAccountDetails && item.billingDetails.accountDetailsBilling.organizationName && item.billingDetails.accountDetailsBilling.country) {
                            company = item.billingDetails.accountDetailsBilling.organizationName
                            country = item.billingDetails.accountDetailsBilling.country
                        }
                        return {
                            ...item,
                            companyName: company,
                            countryName: country,
                            startDate: <span>{moment.unix(item.tsCreated).format("YYYY-MM-DD")}</span>,
                        }
                    })}
                    actions={
                        [
                            {
                                component: <div className="tabel-action-tooltip"><Isvg src={viewIcon} style={{ width: 24, minWidth: 24 }} /><div style={{ width: 180 }} className="chat-icons-tooltip">{'Preview'.translate(this.props.lang)}</div> </div>,
                                onClick: (item) => this.setState({ accountDetailsModal: item }),

                            },
                            // {
                            //     component: <div className="tabel-action-tooltip"><Isvg src={viewIcon} style={{ width: 24, minWidth: 24 }} /><div style={{ width: 180 }} className="chat-icons-tooltip">{'Preview invoice'.translate(this.props.lang)}</div> </div>,
                            //     onClick: (item) => {

                            //         let previewFortnoxInvoice = {};
                            //         if (item.invoiceDetails && item.invoiceDetails.active && item.invoiceDetails.activeDocumentNumber) {
                            //             if (item.invoiceDetails.invoices && item.invoiceDetails.invoices.filter(el => el.documentNumber === item.invoiceDetails.activeDocumentNumber).length && item.invoiceDetails.invoices.filter(el => el.documentNumber === item.invoiceDetails.activeDocumentNumber)[0].pdf) {
                            //                 previewFortnoxInvoice = { ...previewFortnoxInvoice, ...item.invoiceDetails.invoices.filter(el => el.documentNumber === item.invoiceDetails.activeDocumentNumber)[0] }
                            //             }
                            //         } else if (item.invoiceDetails.invoices && item.invoiceDetails.invoices.length) {
                            //             previewFortnoxInvoice = { ...previewFortnoxInvoice, ...item.invoiceDetails.invoices[item.invoiceDetails.invoices.length - 1] }
                            //         }
                            //         if (previewFortnoxInvoice.pdf) {
                            //             previewFortnoxInvoice.pdf = `${API_ENDPOINT}${previewFortnoxInvoice.pdf}`
                            //         }
                            //         this.setState({ previewFortnoxInvoice })
                            //     },
                            //     condition: (item) => {
                            //         if (item.invoiceDetails && item.invoiceDetails.invoices && item.invoiceDetails.invoices.length) {
                            //             return true
                            //         } else {
                            //             return false
                            //         }
                            //     }
                            // },
                            // {
                            //     component: <div className="tabel-action-tooltip"><Isvg src={attachIcon} style={{ width: 24, minWidth: 24 }} /><div style={{ width: 180 }} className="chat-icons-tooltip">{'Attach invoice'.translate(this.props.lang)}</div> </div>,
                            //     onClick: (item) => this.setState({ attachInvoice: item }),
                            //     condition: (item) => {
                            //         if (item.invoiceDetails && item.invoiceDetails.active) {
                            //             return false
                            //         } else {
                            //             return true
                            //         }
                            //     }
                            // },
                            // {
                            //     component: <div className="tabel-action-tooltip deny-icon"><Isvg src={denyIcon} style={{ width: 24, minWidth: 24 }} /><div style={{ width: 180 }} className="chat-icons-tooltip">{'Deny invoice'.translate(this.props.lang)}</div> </div>,
                            //     onClick: (item) => this.setState({ denyInvoice: item, denyReason: '' }),
                            //     condition: (item) => {
                            //         if (item.invoiceDetails && item.invoiceDetails.active && !item.invoiceDetails.bookkeeping && !item.invoiceDetails.done) {
                            //             return true
                            //         } else {
                            //             return false
                            //         }
                            //     }

                            // },

                            // {
                            //     component: <div className="tabel-action-tooltip deny-icon"><Isvg src={attachIcon} style={{ width: 24, minWidth: 24 }} /><div style={{ width: 180 }} className="chat-icons-tooltip">{'Bookkeping invoice'.translate(this.props.lang)}</div> </div>,
                            //     onClick: (item) => this.setState({ bookkeepingInvoice: item }),
                            //     condition: (item) => {
                            //         if (item.invoiceDetails && item.invoiceDetails.active && !item.invoiceDetails.bookkeeping) {
                            //             return true
                            //         } else {
                            //             return false
                            //         }
                            //     }

                            // },
                            // {
                            //     component: <div className="tabel-action-tooltip deny-icon"><Isvg src={attachIcon} style={{ width: 24, minWidth: 24 }} /><div style={{ width: 180 }} className="chat-icons-tooltip">{'Done invoice'.translate(this.props.lang)}</div> </div>,
                            //     onClick: (item) => this.setState({ setDoneInvoice: item }),
                            //     condition: (item) => {
                            //         if (item.invoiceDetails && item.invoiceDetails.active && !item.invoiceDetails.done) {
                            //             return true
                            //         } else {
                            //             return false
                            //         }
                            //     }

                            // },
                        ]
                    }
                    params={params}
                    sortField={params.sortField}
                    sortType={params.sortType}
                    updateSort={this.updateSort}
                    updateParams={this.updateParams}
                    updateMultipleParams={this.updateMultipleParams}
                ></ListBuilder>


                {this.state.accountDetailsModal ?
                    <Modal isOpen={this.state.accountDetailsModal} centered size='lg' className="modal-footer-modifed">
                        <ModalHeader toggle={() => this.setState({ accountDetailsModal: !this.state.accountDetailsModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ accountDetailsModal: !this.state.accountDetailsModal })}><Isvg src={xIcon} /></button>}>{'Preview'.translate(this.props.lang)}</ModalHeader>
                        {/* <ModalBody>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>{'Name'.translate(this.props.lang)}</th>
                                        <th>{'Company'.translate(this.props.lang)}</th>
                                        <th>{'Country'.translate(this.props.lang)}</th>
                                        <th>{'Street'.translate(this.props.lang)}</th>
                                        <th>{'Zip'.translate(this.props.lang)}</th>
                                        <th>{'Phone'.translate(this.props.lang)}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetails.name}</td>
                                        <td>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetails.organizationName}</td>
                                        <td>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetails.country}</td>
                                        <td>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetails.street}</td>
                                        <td>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetails.zip}</td>
                                        <td>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetails.phone}</td>
                                    </tr>
                                </tbody>
                            </Table>
                            {!this.state.accountDetailsModal.billingDetails.accountDetails.sameAsAccountDetails ?
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>{'Name'.translate(this.props.lang)}</th>
                                            <th>{'Company'.translate(this.props.lang)}</th>
                                            <th>{'Country'.translate(this.props.lang)}</th>
                                            <th>{'Street'.translate(this.props.lang)}</th>
                                            <th>{'Zip'.translate(this.props.lang)}</th>
                                            <th>{'Phone'.translate(this.props.lang)}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetailsBilling && this.state.accountDetailsModal.billingDetails.accountDetailsBilling.name}</td>
                                            <td>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetailsBilling && this.state.accountDetailsModal.billingDetails.accountDetailsBilling.organizationName}</td>
                                            <td>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetailsBilling && this.state.accountDetailsModal.billingDetails.accountDetailsBilling.country}</td>
                                            <td>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetailsBilling && this.state.accountDetailsModal.billingDetails.accountDetailsBilling.street}</td>
                                            <td>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetailsBilling && this.state.accountDetailsModal.billingDetails.accountDetailsBilling.zip}</td>
                                            <td>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetailsBilling && this.state.accountDetailsModal.billingDetails.accountDetailsBilling.phone}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                : null}

                            <Table>
                                <thead>
                                    <tr>
                                        {this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.billingDetailsVatNumber ?
                                            <th>{'VAT ID Number'.translate(this.props.lang)}</th>
                                            : null
                                        }
                                        {this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.billingDetailsOrgNumber ?
                                            <th>{'Organisation ID'.translate(this.props.lang)}</th>
                                            : null
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        {this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.billingDetailsVatNumber ?
                                            <td> {this.state.accountDetailsModal.billingDetails.billingDetailsVatNumber}</td>
                                            : null
                                        }
                                        {this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.billingDetailsOrgNumber ?
                                            <td>{this.state.accountDetailsModal.billingDetails.billingDetailsOrgNumber}</td>
                                            : null}
                                    </tr>
                                </tbody>
                            </Table>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>{'Licence'.translate(this.props.lang)}</th>
                                        <th>{'Licence duration'.translate(this.props.lang)}</th>
                                        <th>{'Licence price'.translate(this.props.lang)}</th>
                                        <th>{'Number Of licences'.translate(this.props.lang)}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td> {this.state.accountDetailsModal && this.state.accountDetailsModal.licence && this.props.lang == 'en' ? this.state.accountDetailsModal.licence : this.state.accountDetailsModal.licenceSv}</td>
                                        <td> {this.state.accountDetailsModal && this.state.accountDetailsModal.licenceDuration}</td>
                                        <td> {this.state.accountDetailsModal && this.state.accountDetailsModal.licencePrice ?  Number(this.state.accountDetailsModal.licencePrice) + 'SEK' : 0}</td>
                                        <td> {this.state.accountDetailsModal && this.state.accountDetailsModal.numberOfLicences  ? Number(this.state.accountDetailsModal.numberOfLicences) : 1}</td>

                                    </tr>
                                </tbody>
                            </Table>

                        </ModalBody> */}

                        <ModalBody className='active-order-tables'>


                            <h6 className='preview-heading' style={{ marginTop: 10 }}>
                                <Isvg src={userIcon} />
                                {'Account details'.translate(this.props.lang)}
                            </h6>
                            <Row className='preview-details-modal'>
                                <Col lg="6">
                                    <div className='info-group'>
                                        <label>{'First and last name'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetails.name}</h6>
                                    </div>
                                    <div className='info-group'>
                                        <label>{'Email'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetails.email}</h6>
                                    </div>
                                    <div className='info-group'>
                                        <label>{'Phone number'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetails.phone}</h6>
                                    </div>
                                    <div className='info-group'>
                                        <label>{'Organization name'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetails.organizationName}</h6>
                                    </div>
                                    <div className='info-group'>
                                        <label>{'Number of employees'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetails.numberOfEmployees ? this.state.accountDetailsModal.billingDetails.accountDetails.numberOfEmployees : null}</h6>
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className='info-group'>
                                        <label>{'Address'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetails.street}</h6>
                                    </div>
                                    <div className='info-group'>
                                        <label>{'City'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetails.city}</h6>
                                    </div>
                                    <div className='info-group'>
                                        <label>{'State'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetails.state ? this.state.accountDetailsModal.billingDetails.accountDetails.state : null}</h6>
                                    </div>
                                    <div className='info-group'>
                                        <label>{'Zip/Postal code'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetails.zip}</h6>
                                    </div>
                                    <div className='info-group'>
                                        <label>{'Country'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetails.country}</h6>
                                    </div>
                                </Col>
                            </Row>
                            <hr />


                            {!this.state.accountDetailsModal.billingDetails.accountDetails.sameAsAccountDetails ?
                                <>
                                    <h6 className='preview-heading'>
                                        <Isvg src={billingIcon} />
                                        {'Billing details'.translate(this.props.lang)}
                                    </h6>
                                    <Row className='preview-details-modal'>
                                        <Col lg="6">
                                            <div className='info-group'>
                                                <label>{'First and last name'.translate(this.props.lang)}</label>
                                                <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetailsBilling.name}</h6>
                                            </div>
                                            <div className='info-group'>
                                                <label>{'Email'.translate(this.props.lang)}</label>
                                                <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetailsBilling.email}</h6>
                                            </div>
                                            <div className='info-group'>
                                                <label>{'Phone number'.translate(this.props.lang)}</label>
                                                <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetailsBilling.phone}</h6>
                                            </div>
                                            <div className='info-group'>
                                                <label>{'Organization name'.translate(this.props.lang)}</label>
                                                <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetailsBilling.organizationName}</h6>
                                            </div>
                                            <div className='info-group'>
                                                <label>{'Number of employees'.translate(this.props.lang)}</label>
                                                <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetailsBilling.numberOfEmployees}</h6>
                                            </div>

                                        </Col>
                                        <Col lg="6">
                                            <div className='info-group'>
                                                <label>{'Address'.translate(this.props.lang)}</label>
                                                <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetailsBilling.street}</h6>
                                            </div>
                                            <div className='info-group'>
                                                <label>{'City'.translate(this.props.lang)}</label>
                                                <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetailsBilling.city}</h6>
                                            </div>
                                            <div className='info-group'>
                                                <label>{'State'.translate(this.props.lang)}</label>
                                                <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetailsBilling.state}</h6>
                                            </div>
                                            <div className='info-group'>
                                                <label>{'Zip/Postal code'.translate(this.props.lang)}</label>
                                                <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetailsBilling.zip}</h6>
                                            </div>
                                            <div className='info-group'>
                                                <label>{'Country'.translate(this.props.lang)}</label>
                                                <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetailsBilling.country}</h6>
                                            </div>


                                        </Col>
                                    </Row>
                                </> : null}

                            {this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.billingDetailsVatNumber || this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.billingDetailsOrgNumber ?
                                <>
                                    {this.state.accountDetailsModal.billingDetails.accountDetails.sameAsAccountDetails ?
                                        <h6 className='preview-heading'>
                                            <Isvg src={billingIcon} />
                                            {'Billing details'.translate(this.props.lang)}
                                        </h6>
                                        : null}
                                    <Row className='preview-details-modal' style={{ paddingTop: 0, marginTop: 0 }}>
                                        {this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.billingDetailsVatNumber ?
                                            <Col lg="6">

                                                <div className='info-group' style={!this.state.accountDetailsModal.billingDetails.accountDetails.sameAsAccountDetails ? { marginTop: 0, paddingTop: 0 } : null}>
                                                    <label>{'VAT ID Number'.translate(this.props.lang)}</label>
                                                    <h6>{this.state.accountDetailsModal.billingDetails.billingDetailsVatNumber}</h6>
                                                </div>

                                            </Col> : null}
                                        {this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.billingDetailsOrgNumber ?
                                            <Col lg="6">

                                                <div className='info-group' style={!this.state.accountDetailsModal.billingDetails.accountDetails.sameAsAccountDetails ? { marginTop: 0, paddingTop: 0 } : null}>
                                                    <label>{'Organisation ID'.translate(this.props.lang)}</label>
                                                    <h6>{this.state.accountDetailsModal.billingDetails.billingDetailsOrgNumber}</h6>
                                                </div>

                                            </Col> : null}
                                    </Row>
                                    <hr />
                                </>
                                :
                                null}


                            <h6 className='preview-heading'>
                                <Isvg src={billingIcon} />
                                {'Licence details'.translate(this.props.lang)}
                            </h6>
                            <Row className='preview-details-modal'>
                                <Col lg="6">
                                    <div className='info-group'>
                                        <label>{'Licence'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.licence && this.props.lang == 'en' ? this.state.accountDetailsModal.licence : this.state.accountDetailsModal.licenceSv}</h6>
                                    </div>
                                    <div className='info-group'>
                                        <label>{'Licence price'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.licencePrice ? (Number(this.state.accountDetailsModal.licencePrice)).toFixed(2) + ' ' + 'SEK' : 0}</h6>
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className='info-group'>
                                        <label>{'Licence duration'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.licenceDuration + ' ' + 'month(s)'.translate(this.props.lang)}</h6>
                                    </div>
                                    <div className='info-group'>
                                        <label>{'Number of licences'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.numberOfLicences ? Number(this.state.accountDetailsModal.numberOfLicences) : 1}</h6>
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className='info-group'>
                                        <label>{'Renewel date'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.experienceDate}</h6>
                                    </div>
                                    {/* <div className='info-group'>
                                        <label>{'Number of licences'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.numberOfLicences ? Number(this.state.accountDetailsModal.numberOfLicences) : 1}</h6>
                                    </div> */}
                                </Col>
                            </Row>

                        </ModalBody>

                        <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }}>
                            <div className='button-separated'>
                                <Button color="primary" onClick={() => this.setState({ attachInvoice: this.state.accountDetailsModal })}> {'Attach invoice'.translate(this.props.lang)} </Button>
                                <Button color="primary" onClick={() => this.setState({ denyInvoice: this.state.accountDetailsModal })}> {'Deny request'.translate(this.props.lang)} </Button>
                            </div>



                        </ModalFooter>
                    </Modal>
                    : null}




                {
                    this.state.attachInvoice ?
                        <InfoModal
                            isOpen={this.state.attachInvoice}
                            onClose={(item) => {
                                this.setState({
                                    attachInvoice: item
                                })
                            }}
                            toggle={() => this.setState({ attachInvoice: !this.state.attachInvoice })}
                            header={'Info'.translate(this.props.lang)}
                            info={'Are you sure you want to attach invoce?'.translate(this.props.lang)}
                            buttons={[

                                <Button color='primary' onClick={() => {
                                    this.attachInvoice(this.state.attachInvoice)

                                }}>{'Yes'.translate(this.props.lang)}</Button>,

                                <Button color='primary' onClick={() => {
                                    this.setState({
                                        attachInvoice: false

                                    })

                                }}>{'No'.translate(this.props.lang)}</Button>


                            ]}
                        />
                        :
                        null
                }
                {
                    this.state.denyInvoice ?
                        <Modal isOpen={this.state.denyInvoice} centered>
                            <ModalHeader style={{ margin: 'auto' }}>
                                {'Info'.translate(this.props.lang)}
                            </ModalHeader>
                            <ModalBody className="delete-modal">
                                <Isvg src={info} />
                                <div className='message-info'>
                                    {'Are you sure you want to deny this request?'.translate(this.props.lang)}
                                </div>
                                <FormGroup style={{ marginTop: 20 }}>
                                    <Label>{'Notes'.translate(this.props.lang)}</Label>
                                    <Input type='textarea' style={{ height: 100 }} value={this.state.denyReason} onChange={(e) => this.setState({ denyReason: e.target.value })} />
                                </FormGroup>
                            </ModalBody>
                            <ModalFooter className="buttons-right-blue">
                                <Button color='primary' onClick={() => {
                                    this.denyInvoice(this.state.denyInvoice)

                                }}>{'Yes'.translate(this.props.lang)}</Button>

                                <Button color='primary' onClick={() => {
                                    this.setState({
                                        denyInvoice: false
                                    })

                                }}>{'No'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        :
                        null
                }

                {
                    this.state.bookkeepingInvoice ?
                        <InfoModal
                            isOpen={this.state.bookkeepingInvoice}
                            onClose={(item) => {
                                this.setState({
                                    bookkeepingInvoice: item
                                })
                            }}
                            toggle={() => this.setState({ bookkeepingInvoice: !this.state.bookkeepingInvoice })}
                            header={'Info'.translate(this.props.lang)}
                            info={'Are you sure you want to bookeeping invoce?'.translate(this.props.lang)}
                            buttons={[

                                <Button color='primary' onClick={() => {
                                    this.bookkeepingInvoice(this.state.bookkeepingInvoice)

                                }}>{'Yes'.translate(this.props.lang)}</Button>,

                                <Button color='primary' onClick={() => {
                                    this.setState({
                                        bookkeepingInvoice: false
                                    })

                                }}>{'No'.translate(this.props.lang)}</Button>


                            ]}
                        />
                        :
                        null
                }
                {
                    this.state.setDoneInvoice ?
                        <InfoModal
                            isOpen={this.state.setDoneInvoice}
                            onClose={(item) => {
                                this.setState({
                                    setDoneInvoice: item
                                })
                            }}
                            toggle={() => this.setState({ setDoneInvoice: !this.state.setDoneInvoice })}
                            header={'Info'.translate(this.props.lang)}
                            info={'Are you sure you want to done invoce?'.translate(this.props.lang)}
                            buttons={[

                                <Button color='primary' onClick={() => {
                                    this.doneInvoice(this.state.setDoneInvoice)

                                }}>{'Yes'.translate(this.props.lang)}</Button>,

                                <Button color='primary' onClick={() => {
                                    this.setState({
                                        setDoneInvoice: false
                                    })

                                }}>{'No'.translate(this.props.lang)}</Button>


                            ]}
                        />
                        :
                        null
                }

                {
                    this.state.successMessage ?
                        <InfoModal
                            isOpen={this.state.successMessage}
                            onClose={(item) => {
                                this.setState({
                                    successMessage: item
                                })
                            }}
                            toggle={() => this.setState({ successMessage: !this.state.successMessage })}
                            header={'Info'.translate(this.props.lang)}
                            info={this.state.successMessage}
                            buttons={[
                                <Button color='primary' onClick={() => {
                                    this.setState({
                                        successMessage: false
                                    })
                                }}>{'Ok'.translate(this.props.lang)}</Button>,

                            ]}

                        />
                        :
                        null
                }
                {this.state.error ?
                    <ErrorModal lang={this.props.lang}
                        isOpen={this.state.error}
                        toggle={() => this.setState({ error: null })}
                    >
                        {this.state.error.translate(this.props.lang)}
                    </ErrorModal>

                    :

                    null
                }

                {
                    this.state.previewFortnoxInvoice ?
                        <PreviewFortnoxInvoice
                            data={this.state.previewFortnoxInvoice}
                            lang={this.props.lang}
                            closePreview={() => {
                                this.setState({ previewFortnoxInvoice: null })
                            }}
                        />
                        :
                        null
                }
            </div>
        );


    }
}
export default DeletionRequestsComponent;