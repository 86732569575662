import React, { Component } from "react";
import { Container, Row, Col, Button, Modal, ModalBody, ModalHeader, } from "reactstrap";
import { API_ENDPOINT } from "../constants";
import { Player, Controls } from "@lottiefiles/react-lottie-player";

/**
* Swish component
* @author   Stefan Pantic
*/
class Swish extends Component {
  constructor(props) {
    super(props);

  }
  state = {
    originalPaymentReference: "", //Nakon izvrsenog placanja, ovde se belezi referenca uplate koju treba sacuvati u bazi za potrebe povracaja novca
    identifier: "", //ID broj Swish transakcije se kreira nakon uspesnog pokretanja postupka placanja. Treba ga sacuvati u bazi kao identifikator transakcije
    currentStatus: "Wait...", //Trenutni status postupka placanja
    formData: {
      payee: "1231181189", //Ubaciti broj racuna klinike iz Clinic Group 
      payer: "46707590899", //Ubaciti broj telefona pacijenta, bez + na pocetku
      amount: "200", //Ubaciti iznos koji treba naplatiti u SEK
      message: "100046", //Ubaciti broj remissa. Ovo polje se kod primaoca uplate vidi kao poruka.
    },
  };




  handleStartPayment() { //Pokretanje zahteva za placanje
    const url = API_ENDPOINT + "/swish/paymentrequests";
    // console.log(this.state.formData)
    // console.log(this.props.referral)
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        payerAlias: this.state.formData.payer,
        amount: this.state.formData.amount,
        message: this.state.formData.message,
        // licenceDuration: this.state.formData.licenceDuration,
        licence: this.state.formData.licence,
        licenceID: this.state.formData.licenceID,
      }),
    })
      .then((response) => {
        if (response.status != 201) {
          this.setState({
            currentStatus: "Request failure: " + response.statusText,
          });
          return;
        }
        return response.json();
      })
      .then((json) => {
        if (json) {
          if (json["status"] === "CREATED") {
            this.setState({ identifier: json["id"] });
            this.setState({ currentStatus: "Open your Swish app to confirm payment." });
            this.registerNewPayment(); //Registrovanje nove naplate
            this.checkPaymentStatus(); //Provera statusa naplate (autorefresh na 2 sekunde)
          }
        }
      })
      .catch((error) => {
        // console.log("Request failure: ", error);
      });
  }

  checkPaymentStatus = () => {
    if (!this.state.identifier || this.state.identifier.length <= 0) {
      this.setState({ currentStatus: "No payment Id" });
      return;
    }
    const url = API_ENDPOINT + "/swish/paymentrequests/" + this.state.identifier;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (json.status === "PAID") {
          this.setState({ originalPaymentReference: json["paymentReference"] });
          this.setState({ currentStatus: "Payment successfull." });
          this.paymentSuccessfull(); //Registrovanje uspesno izvrsenog placanja
        }
        else {
          // console.log("Checking for payment");
          setTimeout(this.checkPaymentStatus, 2000);
        }
      })
      .catch((error) => {
        console.log("Request failure: ", error);
      });
  }

  handlePayerChanged(event) {
    var formData = this.state.formData;
    var payer = event.target.value;
    formData.payer = payer;
    this.setState({
      formData: formData,
    });
  }

  /**
  * Insert new transaction 
  * @author   Stefan Pantic
  * @Objectparam    {String} identifier     transaction id
  * @Objectparam    {String} referral       referral id
  */
  registerNewPayment() {
    //Pokrenut je postupak placanja.
    //Registrovati u bazi this.state.identifier uz remiss
    console.log('Payment process started');

    fetch(API_ENDPOINT + '/swish/transaction', {
      method: 'POST',
      headers: {
        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
        'content-type': 'application/json'
      },
      body: JSON.stringify({ identifier: this.state.identifier, organisationPaying: this.props.organisationPaying, upgradeLicence: this.props.upgradeLicence,discount: Number(this.props.discount), price: Number(this.props.price), oldPrice: this.props.price, numberOfLicences: this.props.numberOfLicences, userID: this.props.uData._id, licence: this.props.licence, licenceID: this.props.licenceID, licenceDuration: this.props.licenceDuration })
    }).then(res => res.json()).then((result) => {

    })
  }

  /**
  * Update transaction data with status successfull
  * @author   Stefan Pantic
  * @Objectparam    {String} identifier                   transaction id
  * @Objectparam    {String} originalPaymentReference     transaction reference
  */
  paymentSuccessfull() {
    //Placanje je izvrseno uspesno.
    //Registrovati u bazi, prema this.state.identifier, da je remiss placen i ubeleziti uz njega originalPaymentReference this.state.originalPaymentReference
    console.log('Payment successfull');

    fetch(API_ENDPOINT + '/swish/transaction-successfull', {
      method: 'POST',
      headers: {
        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
        'content-type': 'application/json'
      },
      body: JSON.stringify({ identifier: this.state.identifier, userID: this.props.uData._id, licenceID: this.props.licenceID })
    }).then(res => res.json()).then((result) => {
      if (result)
        if (this.props.redirectUrl == '/users/buy-packet') {
          this.props.history.history.push({
            pathname: '/users/buy-packet',
            state: {
              cfOrder: true,
              step: 4
            }
          })
        }

      window.location.reload();

    })
  }

  componentDidMount() {
    // console.log(this.props);
    if (this.props.open && this.props.price && this.props.swishNumber && this.props.licenceID) {
      let phone
      if (this.props.phone) {
        if (this.props.phone.indexOf('+') == 0) {
          phone = this.props.phone.replace('+', '')
        } else if (this.props.phone.indexOf('00') == 0) {
          phone = this.props.phone.replace(/^00/, '')

        } else {
          phone = this.props.phone
        }
      }


      let formData = this.state.formData;
      formData.payer = phone ? phone : null;
      formData.amount = this.props.price;
      formData.message = this.props.swishNumber;
      formData.licenceDuration = this.props.licenceDuration
      formData.licence = this.props.licence;
      formData.licenceID = this.props.licenceID;

      this.setState({
        swishModal: true,
        formData
      })


    }
    this.setState({ currentStatus: "Ready" });

  }
  render() {
    return (
      <div className="dashboard">
        {this.state.swishModal ?
          <Modal isOpen={this.state.swishModal} centered>
            <ModalHeader toggle={() => this.setState({ swishModal: null }, () => {
              this.props.openModal(false);
            })}
              close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ swishModal: null }, () => {
                this.props.openModal(false);
              })}
              >&times;</button>}>{'Payment'.translate(this.props.lang)}</ModalHeader>

            <ModalBody >
              <div className="login-main-wrap" style={{ height: '50vh', minHeight: '50vh', display: "flex", alignItems: "center" }}>

                <Container fluid>
                  <Row>
                    <Col lg={{ size: 12 }}>
                      <div className="panel" style={{ paddingTop: 0 }}>
                        <div className="login-container">
                          <h4>{"Swish payment".translate(this.props.lang)}</h4>
                          <div className="login-form">
                            <form>
                              <Row>
                                <Col lg={{ size: 12 }}>
                                  <div className="form-group">
                                    <label className="col-form-label-sm">
                                      {"Your Swish mobile number".translate(this.props.lang)}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-contro-sm form-control"
                                      value={this.state.formData.payer}
                                      onChange={this.handlePayerChanged.bind(this)}
                                    />
                                  </div>
                                </Col>
                                <Col lg={{ size: 6 }}>
                                  <div>{"Amount:".translate(this.props.lang)}<br /><strong>{this.state.formData.amount} kr.</strong></div>
                                </Col>
                                <Col lg={{ size: 6 }}>
                                  <div>{"Reference:".translate(this.props.lang)}<br /><strong>{this.state.formData.message}</strong></div>
                                </Col>
                              </Row>
                            </form>
                          </div>
                          <Row>
                            <Col lg={{ size: 12 }}>
                              <div className="bank-id-button">
                                <Button disabled={this.state.identifier}
                                  onClick={this.handleStartPayment.bind(this)}
                                >
                                  {"Start Payment".translate(this.props.lang)}
                                </Button>
                              </div>
                            </Col>
                          </Row>
                          <div style={{ position: "relative" }}>
                            {this.state.currentStatus == 'Open your Swish app to confirm payment.' ?
                              <div className="loader-wrap loader-wrap-account" >
                                <Player
                                  autoplay={true}
                                  loop={true}
                                  src="/lf30_editor_l5cxzdyf.json"
                                  style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", position: 'absolute', top: "50%", left: '50%', transform: 'translate(-50%, -50%)' }}
                                ></Player>
                              </div>
                              :
                              null
                            }
                          </div>
                          <p>{this.state.currentStatus.translate(this.props.lang)}</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
              {/* </div> */}
              {/* </div> */}

            </ModalBody>
          </Modal>
          : null}
      </div>

    );
  }
}

export default Swish;
