import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Page from '../containers/page';
import AOS from 'aos';
import 'aos/dist/aos.css'
import { getSearchParams } from '../helpers/linkHelper';
import landingHome from '../assets/images/landing-home.jpg';
import talk from '../assets/images/talk.png';
import lf2 from '../assets/images/lf2.jpg';
import lf3 from '../assets/images/lf3.jpg';
import fs1 from '../assets/images/fs1.jpg';
import penna from '../assets/images/penna.png';
import telefon from '../assets/images/telefon.png';

import { API_ENDPOINT } from '../constants';

import {
    Container,
    Row,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Input,
    Button
} from 'reactstrap';

var striptags = require('striptags');
function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

class FortnoxActivation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            promotedProjects: [],
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            }
        };
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        let params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        let checkParams = {};
        if (params && Object.keys(params).length > 0) {
            for (var key in params) {
                if (key !== 'page' && key !== 'entries') {
                    checkParams[key] = params[key];
                }
            }
        }
        console.log(checkParams)
        if (checkParams.code && checkParams.state === 'somestate123') {
            // /fortnox/connect/:code

            fetch(API_ENDPOINT + "/fortnox/connect/" + checkParams.code, {
                method: "GET",
                headers: {
                    Authorization:
                        typeof localStorage !== "undefined"
                            ? `Bearer ${localStorage.getItem("authToken")}`
                            : null,
                    "content-type": "application/json",
                }
            }).then(parseJSON).then(({ result, status }) => {
                if (result) {
                    if (result.success) {
                        this.setState({ successModal: true })
                    } else {
                        this.setState({ errorModal: true })
                    }
                }
            });
        }


    }




    render() {

        return (

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100vh' }}>
                {
                    this.state.successModal ?
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', padding: '0 20px' }} >
                            <h6 style={{ textAlign: 'center'}}>{'You have successfully connected to Frotnox!'.translate(this.props.lang)}</h6>
                            <Button style={{ marginTop: 30, width: 150}} color="primary" onClick={() => {
                                if (typeof window != 'undefined') {
                                    window.close()
                                }
                            }}>{'Close'.translate(this.props.lang)}</Button>
                        </div>
                        :
                        null
                }
                {
                    this.state.errorModal ?
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', padding: '0 20px' }} >
                            <h6 style={{ textAlign: 'center'}}>{'An error occurred, please try again!'.translate(this.props.lang)}</h6>
                            <Button style={{ marginTop: 30, width: 150}} color="primary" onClick={() => {
                                if (typeof window != 'undefined') {
                                    window.close()
                                }
                            }}>{'Close'.translate(this.props.lang)}</Button>

                        </div>
                        :
                        null
                }
                {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    {
                        checkParams && Object.keys(checkParams).length > 0 && Object.keys(checkParams).map((key, index) => {
                            return (
                                <div key={index} >
                                    <h5>
                                        {key}: <span style={{ fontWeight: 400, fontStyle: 'italic' }}>{checkParams[key]}</span>
                                    </h5>

                                    <hr />
                                </div>
                            );
                        })
                    }
                </div> */}
            </div>
        );
    }
}

export default FortnoxActivation;