import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Button, Table, Input, Label, FormGroup

} from 'reactstrap';

import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import InvoiceRequestsNewOrders from '../../components/invoiceRequestNewOrder';
import InvoiceRequestsActiveOrders from '../../components/invoiceRequestActiveOrders';
import InvoiceRequestsPaidOrders from '../../components/invoiceRequestPaidOrders'
import InvoiceRequestHistoryOrders from '../../components/invoiceRequestHistoryOrders';



function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

/**
* Clinic users page
* @author   Milan Stanojevic
*/
class invoiceRequests extends Component {
    constructor(props) {
        super(props);
        this.player = React.createRef();
        this.player2 = React.createRef();
        this.state = {
            ...props.initialData,
            form: false,
            pero: [],
            djuro: [],
            test: [],
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            tabIndex: 1,
        };
    }


    showPlayer = () => {
        this.setState({
            showPlayer: true,
        });
    };

    hidePlayer = () => {
        this.setState({
            showPlayer: false,
        });
    };

    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }



    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }



        this.get();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }

    }

    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }


    stopAnimation = () => {
        this.player.current.play();
    };

    listActive = () => {
        console.log('fdsdsadassa');
        this.setState({
            reloadListState: true
        })
    }


    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }

        let selectedGroupIdx = 0;
        if (this.props.selectedGroup && this.props.uData.clinicGroups) {
            for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                    selectedGroupIdx = i;
                    break;
                }
            }
        }
   
        return (
            <div className="dashboard">

                <Container fluid>
                    <Row>
                        <div className='panel'>

                            <h5 className="component-header">{'Invoice requests'.translate(this.props.lang)}</h5>
                            <Col lg="12" className='col-no-padding'>
                                <div className="tabs-buttons-wrap">
                                    <Button className={this.state.tabIndex == 1 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.setState({ tabIndex: 1 })}>{"Active".translate(this.props.lang)}</Button>
                                    <Button className={this.state.tabIndex == 2 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.setState({ tabIndex: 2 })}>{"Paid".translate(this.props.lang)}</Button>
                                    <Button className={this.state.tabIndex == 3 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.setState({ tabIndex: 3 })}>{"Order history".translate(this.props.lang)}</Button>
                                </div>

                            </Col>
                            {this.state.tabIndex == 1 ?

                                <Row>

                                    <Col lg="12">

                                        <h5 className="component-header">{'New orders'.translate(this.props.lang)}</h5>

                                        <InvoiceRequestsNewOrders
                                            {...this.props}
                                            reloadList={() => this.listActive()}
                                        />

                                    </Col>
                                    <Col lg="12" >

                                        <h5 style={{ paddingTop: '30px' }} className="component-header">{'Active orders'.translate(this.props.lang)}</h5>
                                        <InvoiceRequestsActiveOrders
                                            {...this.props}
                                            changeTab={
                                                () => {
                                                    this.setState({ tabIndex: 2 })
                                                }
                                            }
                                            reloadList= {this.state.reloadListState}

                                        />

                                    </Col>
                                </Row>
                                : null}

                            {
                                this.state.tabIndex == 2 ?
                                    <Col lg="12" >

                                        <h5 className="component-header">{'Paid'.translate(this.props.lang)}</h5>
                                        <InvoiceRequestsPaidOrders
                                            {...this.props}
                                            changeTab={
                                                () => {
                                                    this.setState({ tabIndex: 1 })
                                                }
                                            }
                                        />

                                    </Col>
                                    :
                                    null
                            }
                            {
                                this.state.tabIndex == 3 ?
                                    <Col lg="12">

                                        <h5 className="component-header">{'Order history'.translate(this.props.lang)}</h5>
                                        <InvoiceRequestHistoryOrders
                                            {... this.props}
                                        />

                                    </Col>
                                    :
                                    null
                            }
                        </div>
                    </Row>
                </Container>




            </div>

        );
    }
}


const selector = formValueSelector('modalForm');

export default connect(state => {
    return {


    }


}, {


})(Page(invoiceRequests));