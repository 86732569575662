import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Table
} from 'reactstrap';

import Isvg from 'react-inlinesvg'

import ListBuilder from '../../components/listBuilder';
import ErrorModal from '../../components/errorModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';
import viewIcon from '../../assets/svg/eye.svg';
import xIcon from '../../assets/svg/x.svg';
import garbageIcon from '../../assets/svg/garbage.svg';
import walletIcon from '../../assets/svg/wallet.svg';
import warrning from '../../assets/svg/warrning.svg';
import attachInvoice from '../../assets/svg/attach.svg'
import Payson from '../../components/payson';
import Swish from '../swish';
import FormGroup from 'reactstrap/lib/FormGroup';
import Label from 'reactstrap/lib/Label';
import settingsIcon from '../../assets/svg/settings.svg';
import PdfSave from '../../components/pdvSave';
import InfoModal from '../../components/infoModal';
import striptags from 'striptags';
import userIcon from '../../assets/svg/l-user.svg';
import billingIcon from '../../assets/svg/l-card.svg';

function parseText(response) {
    return response.text().then(text => {
        return {
            result: text,
            status: response.status
        }
    })
}
/**
* Customers page
* @author   Stefan Pantic
*/
class Customers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            itemsActive: [],
            itemsInactive: [],
            totalActive: 0,
            totalInactive: 0,
            loading: true,
            tabIndex: 1,


        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1, alias: localStorage.getItem('groupAlias') })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1, alias: localStorage.getItem('groupAlias'), clinic: this.props.selectedClinic, group: this.props.selectedGroup })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();

    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }

    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 },
                    [
                        {
                            name: 'sortField', value: field
                        }
                        ,
                        {
                            name: 'sortType', value: type
                        }
                    ],
                    false));

        }
    }


    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 }, fields, restart));
        }
    }

    changeLicenceStatus = (data, status) => {
        // console.log(data);
        fetch(API_ENDPOINT + '/users-change-licence-status', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({ status: status, userId: data._id, licenceId: data.lastBill && data.lastBill.licenceID, billId: data.lastBill._id })
        }).then(res => res.json()).then((res) => {
            this.setState({
                settingsModal: false
            })
            this.get();
        })
    }

    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 })
        }

        // console.log(this.state.accountDetailsModal);
        return (
            <div className="dashboard">

                <Container fluid>
                    <Row>
                        <div className='panel'>
                            <h5 className="component-header">{'Customers'.translate(this.props.lang)}</h5>
                            <Col lg="12" className='col-no-padding'>
                                <div className="tabs-buttons-wrap">
                                    <Button className={this.state.tabIndex == 1 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.setState({ tabIndex: 1 }, () => {
                                        this.updateMultipleParams([
                                            { name: 'page', value: 0 },
                                            { name: 'entries', value: 10 }
                                        ], true)
                                    })}>{"Active".translate(this.props.lang)}</Button>
                                    <Button className={this.state.tabIndex == 2 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.setState({ tabIndex: 2 }, () => {
                                        this.updateMultipleParams([
                                            { name: 'page', value: 0 },
                                            { name: 'entries', value: 10 }
                                        ], true)
                                    })}>{"Inactive".translate(this.props.lang)}</Button>
                                </div>
                            </Col>
                            {this.state.tabIndex == 1 ?

                                <Col lg="12" className='col-no-padding'>

                                    <ListBuilder
                                        lang={this.props.lang}
                                        loading={this.state.loading}
                                        total={this.state.totalActive}
                                        showNumeration={false}
                                        fields={[

                                            { type: 'text', name: 'company', label: 'Company'.translate(this.props.lang), allowSort: true },
                                            { type: 'text', name: 'email', label: 'Email'.translate(this.props.lang), allowSort: false },
                                            { type: 'text', name: 'vat_org', label: 'VAT/Org.nr.'.translate(this.props.lang), allowSort: false },
                                            { type: 'text', name: 'startDate', label: 'Renewal date'.translate(this.props.lang), allowSort: true },
                                            { type: 'text', name: 'plan', label: 'Plan'.translate(this.props.lang), allowSort: true },
                                            { type: 'text', name: 'notes', label: 'Notes'.translate(this.props.lang), allowSort: true },

                                        ]}
                                        rawItems={this.state.itemsActive}
                                        items={this.state.itemsActive.map((item => {
                                            return {
                                                ...item,
                                                company: item.userData && item.userData.companyName ? item.userData.companyName : '',
                                                vat_org: item.userData && item.userData.vatNumber && item.userData.orgNr ? item.userData.vatNumber + ' / ' + item.userData.orgNr : item.userData && item.userData.vatNumber ? item.userData.vatNumber : item.userData && item.userData.orgNr ? item.userData.orgNr : 'N/A',
                                                startDate: item.licenceObj && item.licenceObj.experienceDateMain,
                                                plan: item.licenceObj && item.licenceObj.licenceMain,
                                                notes: item.lastBill && item.lastBill.notes ? <div onClick={() => {
                                                    this.setState({
                                                        notesModal: item.lastBill.notes
                                                    })
                                                }}>{item.lastBill.notes.substring(0, 30) + '...'}</div> : 'N/A'

                                            }
                                        }))}
                                        actions={
                                            [
                                                {
                                                    component: <div className="tabel-action-tooltip"><Isvg src={viewIcon} style={{ width: 24, minWidth: 24 }} /><div style={{ width: 180 }} className="chat-icons-tooltip">{'Preview'.translate(this.props.lang)}</div> </div>,
                                                    onClick: (item) => this.setState({ accountDetailsModal: item.lastBill }),

                                                },
                                                {
                                                    component: <div className="tabel-action-tooltip"><Isvg src={settingsIcon} className={'tabel-settings-svg'} /><div style={{ width: 180 }} className="chat-icons-tooltip">{'Settings'.translate(this.props.lang)}</div> </div>,
                                                    onClick: (item) => this.setState({ settingsModal: item }),

                                                },

                                            ]
                                        }
                                        params={params}
                                        sortField={params.sortField}
                                        sortType={params.sortType}
                                        updateSort={this.updateSort}
                                        updateParams={this.updateParams}
                                        updateMultipleParams={this.updateMultipleParams}
                                    ></ListBuilder>

                                </Col>
                                : null}

                            {
                                this.state.tabIndex == 2 ?
                                    <Col lg="12" className='col-no-padding'>
                                        <ListBuilder
                                            lang={this.props.lang}
                                            loading={this.state.loading}
                                            total={this.state.totalInactive}
                                            showNumeration={false}
                                            fields={[
                                                { type: 'text', name: 'company', label: 'Company'.translate(this.props.lang), allowSort: true },
                                                { type: 'text', name: 'email', label: 'Email'.translate(this.props.lang), allowSort: false },
                                                { type: 'text', name: 'vat_org', label: 'VAT/Org.nr.'.translate(this.props.lang), allowSort: false },
                                                { type: 'text', name: 'startDate', label: 'Renewal date'.translate(this.props.lang), allowSort: true },
                                                { type: 'text', name: 'plan', label: 'Plan'.translate(this.props.lang), allowSort: true },
                                                { type: 'text', name: 'notes', label: 'Notes'.translate(this.props.lang), allowSort: true },

                                            ]}
                                            rawItems={this.state.itemsInactive}
                                            items={this.state.itemsInactive.map((item => {
                                                // console.log(item);
                                                return {
                                                    ...item,
                                                    company: item.userData && item.userData.companyName ? item.userData.companyName : '',
                                                    vat_org: item.userData && item.userData.vatNumber && item.userData.orgNr ? item.userData.vatNumber + ' / ' + item.userData.orgNr : item.userData && item.userData.vatNumber ? item.userData.vatNumber : item.userData && item.userData.orgNr ? item.userData.orgNr : 'N/A',
                                                    startDate: item.licenceObj && item.licenceObj.experienceDateMain,
                                                    plan: item.licenceObj && item.licenceObj.licenceMain ? item.licenceObj.licenceMain : item.licenceObj && item.licenceObj.licenceType ? item.licenceObj.licenceType : 'N/A',
                                                    notes: item.notes ? item.notes : 'N/A'

                                                }
                                            }))}
                                            actions={
                                                [
                                                    {
                                                        component: <div className="tabel-action-tooltip"><Isvg src={viewIcon} style={{ width: 24, minWidth: 24 }} /><div style={{ width: 180 }} className="chat-icons-tooltip">{'Preview'.translate(this.props.lang)}</div> </div>,
                                                        onClick: (item) => this.setState({ accountDetailsModal: item.lastBill }),
                                            

                                                    },
                                                    {
                                                        component: <div className="tabel-action-tooltip"><Isvg src={settingsIcon} className={'tabel-settings-svg'} /><div style={{ width: 180 }} className="chat-icons-tooltip">{'Settings'.translate(this.props.lang)}</div> </div>,
                                                        onClick: (item) => this.setState({ settingsModal: item }),

                                                    },

                                                ]
                                            }

                                            params={params}
                                            sortField={params.sortField}
                                            sortType={params.sortType}
                                            updateSort={this.updateSort}
                                            updateParams={this.updateParams}
                                            updateMultipleParams={this.updateMultipleParams}

                                        ></ListBuilder>

                                    </Col>
                                    :
                                    null
                            }
                        </div>
                    </Row>


                </Container>

                {
                    this.state.notesModal ?
                        <Modal isOpen={this.state.notesModal} centered size='lg' className="modal-footer-modifed">
                            <ModalHeader toggle={() => this.setState({ notesModal: !this.state.notesModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ notesModal: !this.state.notesModal })}><Isvg src={xIcon} /></button>}>{'Notes'.translate(this.props.lang)}</ModalHeader>
                            <ModalBody>
                                <div>
                                    {this.state.notesModal}
                                </div>
                            </ModalBody>
                            <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }}>
                                <Button color="primary" onClick={() => this.setState({ notesModal: null })}> {'Close'.translate(this.props.lang)} </Button>


                            </ModalFooter>
                        </Modal>
                        :
                        null
                }

                {
                    this.state.settingsModal ?
                        <Modal isOpen={this.state.settingsModal} centered size='xs' className="modal-footer-modifed">
                            <ModalHeader toggle={() => this.setState({ settingsModal: !this.state.settingsModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ settingsModal: !this.state.settingsModal })}><Isvg src={xIcon} /></button>}>{this.state.tabIndex == 1 ? 'Deactivate'.translate(this.props.lang) : 'Activate'.translate(this.props.lang)}</ModalHeader>
                            <ModalBody>
                                <div>
                                    {this.state.tabIndex == 1 ? 'Deactivate the license.'.translate(this.props.lang) : 'Activate the license.'.translate(this.props.lang)}
                                </div>
                            </ModalBody>
                            <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }}>
                                {
                                    this.state.tabIndex == 2 ?
                                        <Button color="primary" onClick={() => this.changeLicenceStatus(this.state.settingsModal, 'activate')}> {'Activate'.translate(this.props.lang)} </Button>
                                        :
                                        <Button color="primary" onClick={() => this.changeLicenceStatus(this.state.settingsModal, 'deactivate')}> {'Deactivate'.translate(this.props.lang)} </Button>
                                }



                            </ModalFooter>
                        </Modal>
                        :
                        null
                }

                {this.state.accountDetailsModal ?
                    <Modal isOpen={this.state.accountDetailsModal} centered size='lg' className="modal-footer-modifed">
                        <ModalHeader toggle={() => this.setState({ accountDetailsModal: !this.state.accountDetailsModal })} style={{ paddingLeft: 30 }} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ accountDetailsModal: !this.state.accountDetailsModal })}><Isvg src={xIcon} /></button>}>{'Preview'.translate(this.props.lang)}</ModalHeader>
                        <ModalBody className='active-order-tables'>

                            <h6 className='preview-heading' style={{ marginTop: 10 }}>
                                <Isvg src={userIcon} />
                                {'Account details'.translate(this.props.lang)}
                            </h6>
                            <Row className='preview-details-modal'>
                                <Col lg="6">
                                    <div className='info-group'>
                                        <label>{'First and last name'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetails.name}</h6>
                                    </div>
                                    <div className='info-group'>
                                        <label>{'Email'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetails.email}</h6>
                                    </div>
                                    <div className='info-group'>
                                        <label>{'Phone number'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetails.phone}</h6>
                                    </div>
                                    <div className='info-group'>
                                        <label>{'Organization name'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetails.organizationName}</h6>
                                    </div>
                                    <div className='info-group'>
                                        <label>{'Number of employees'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetails.numberOfEmployees ? this.state.accountDetailsModal.billingDetails.accountDetails.numberOfEmployees : null}</h6>
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className='info-group'>
                                        <label>{'Address'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetails.street}</h6>
                                    </div>
                                    <div className='info-group'>
                                        <label>{'City'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetails.city}</h6>
                                    </div>
                                    <div className='info-group'>
                                        <label>{'State'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetails.state ? this.state.accountDetailsModal.billingDetails.accountDetails.state : null}</h6>
                                    </div>
                                    <div className='info-group'>
                                        <label>{'Zip/Postal code'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetails.zip}</h6>
                                    </div>
                                    <div className='info-group'>
                                        <label>{'Country'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetails.country}</h6>
                                    </div>
                                </Col>
                            </Row>
                            <hr />

                            {/* {this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && !this.state.accountDetailsModal.billingDetails.accountDetails.sameAsAccountDetails ?
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>{'Name'.translate(this.props.lang)}</th>
                                            <th>{'Company'.translate(this.props.lang)}</th>
                                            <th>{'Country'.translate(this.props.lang)}</th>
                                            <th>{'Street'.translate(this.props.lang)}</th>
                                            <th>{'Zip'.translate(this.props.lang)}</th>
                                            <th>{'Phone'.translate(this.props.lang)}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetailsBilling && this.state.accountDetailsModal.billingDetails.accountDetailsBilling.name}</td>
                                            <td>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetailsBilling && this.state.accountDetailsModal.billingDetails.accountDetailsBilling.organizationName}</td>
                                            <td>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetailsBilling && this.state.accountDetailsModal.billingDetails.accountDetailsBilling.country}</td>
                                            <td>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetailsBilling && this.state.accountDetailsModal.billingDetails.accountDetailsBilling.street}</td>
                                            <td>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetailsBilling && this.state.accountDetailsModal.billingDetails.accountDetailsBilling.zip}</td>
                                            <td>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetailsBilling && this.state.accountDetailsModal.billingDetails.accountDetailsBilling.phone}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                : null
                            } */}

                            {this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && !this.state.accountDetailsModal.billingDetails.accountDetails.sameAsAccountDetails ?

                                <>
                                    <h6 className='preview-heading'>
                                        <Isvg src={billingIcon} />
                                        {'Billing details'.translate(this.props.lang)}
                                    </h6>
                                    <Row className='preview-details-modal'>
                                        <Col lg="6">
                                            <div className='info-group'>
                                                <label>{'First and last name'.translate(this.props.lang)}</label>
                                                <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetailsBilling.name}</h6>
                                            </div>
                                            <div className='info-group'>
                                                <label>{'Email'.translate(this.props.lang)}</label>
                                                <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetailsBilling.email}</h6>
                                            </div>
                                            <div className='info-group'>
                                                <label>{'Phone number'.translate(this.props.lang)}</label>
                                                <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetailsBilling.phone}</h6>
                                            </div>
                                            <div className='info-group'>
                                                <label>{'Organization name'.translate(this.props.lang)}</label>
                                                <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetailsBilling.organizationName}</h6>
                                            </div>
                                            <div className='info-group'>
                                                <label>{'Number of employees'.translate(this.props.lang)}</label>
                                                <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetailsBilling.numberOfEmployees ? this.state.accountDetailsModal.billingDetails.accountDetailsBilling.numberOfEmployees : null}</h6>
                                            </div>

                                            {/* <div className='info-group'>
                                                {this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.billingDetailsVatNumber ?
                                                    <>
                                                        <label>{'VAT ID Number'.translate(this.props.lang)}</label>
                                                        <h6>{this.state.accountDetailsModal.billingDetails.billingDetailsVatNumber}</h6>
                                                    </>
                                                    :
                                                    null}
                                            </div> */}
                                        </Col>
                                        <Col lg="6">
                                            <div className='info-group'>
                                                <label>{'Address'.translate(this.props.lang)}</label>
                                                <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetailsBilling.street}</h6>
                                            </div>
                                            <div className='info-group'>
                                                <label>{'City'.translate(this.props.lang)}</label>
                                                <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetailsBilling.city}</h6>
                                            </div>
                                            <div className='info-group'>
                                                <label>{'State'.translate(this.props.lang)}</label>
                                                <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetailsBilling.state ? this.state.accountDetailsModal.billingDetails.accountDetailsBilling.state : null}</h6>
                                            </div>
                                            <div className='info-group'>
                                                <label>{'Zip/Postal code'.translate(this.props.lang)}</label>
                                                <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetailsBilling.zip}</h6>
                                            </div>
                                            <div className='info-group'>
                                                <label>{'Country'.translate(this.props.lang)}</label>
                                                <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetailsBilling.country}</h6>
                                            </div>

                                            {/* <div className='info-group'>
                                                {this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.billingDetailsOrgNumber ?
                                                    <>
                                                        <label>{'Organisation ID'.translate(this.props.lang)}</label>
                                                        <h6>{this.state.accountDetailsModal.billingDetails.billingDetailsOrgNumber}</h6>
                                                    </>
                                                    :
                                                    null}
                                            </div> */}
                                        </Col>
                                    </Row>
                                    {/* <hr /> */}
                                </> : null}
                            {this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.billingDetailsVatNumber || this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.billingDetailsOrgNumber ?
                                <>
                                    {this.state.accountDetailsModal.billingDetails.accountDetails.sameAsAccountDetails ?
                                        <h6 className='preview-heading'>
                                            <Isvg src={billingIcon} />
                                            {'Billing details'.translate(this.props.lang)}
                                        </h6>
                                        : null}
                                    <Row className='preview-details-modal' style={{ paddingTop: 0, marginTop: 0 }}>
                                        {this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.billingDetailsVatNumber ?
                                            <Col lg="6">

                                                <div className='info-group' style={!this.state.accountDetailsModal.billingDetails.accountDetails.sameAsAccountDetails ? { marginTop: 0, paddingTop: 0 } : null}>
                                                    <label>{'VAT ID Number'.translate(this.props.lang)}</label>
                                                    <h6>{this.state.accountDetailsModal.billingDetails.billingDetailsVatNumber}</h6>
                                                </div>

                                            </Col> : null}
                                        {this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.billingDetailsOrgNumber ?
                                            <Col lg="6">

                                                <div className='info-group' style={!this.state.accountDetailsModal.billingDetails.accountDetails.sameAsAccountDetails ? { marginTop: 0, paddingTop: 0 } : null}>
                                                    <label>{'Organisation ID'.translate(this.props.lang)}</label>
                                                    <h6>{this.state.accountDetailsModal.billingDetails.billingDetailsOrgNumber}</h6>
                                                </div>

                                            </Col> : null}
                                    </Row>
                                    <hr />
                                </>
                                :
                                null}

                            {/* <Table>
                                <thead>
                                    <tr>
                                        {this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.billingDetailsVatNumber ?
                                            <th>{'VAT ID Number'.translate(this.props.lang)}</th>
                                            : null
                                        }
                                        {this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.billingDetailsOrgNumber ?
                                            <th>{'Organisation ID'.translate(this.props.lang)}</th>
                                            : null
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        {this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.billingDetailsVatNumber ?
                                            <td> {this.state.accountDetailsModal.billingDetails.billingDetailsVatNumber}</td>
                                            : null
                                        }
                                        {this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.billingDetailsOrgNumber ?
                                            <td>{this.state.accountDetailsModal.billingDetails.billingDetailsOrgNumber}</td>
                                            : null}
                                    </tr>
                                </tbody>
                            </Table> */}

                            {/* <Table>
                                <thead>
                                    <tr>
                                        <th>{'Licence'.translate(this.props.lang)}</th>
                                        <th>{'Licence duration'.translate(this.props.lang)}</th>
                                        <th>{'Licence price'.translate(this.props.lang)}</th>
                                        <th>{'Number Of licences'.translate(this.props.lang)}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td> {this.state.accountDetailsModal && this.state.accountDetailsModal.licence && this.props.lang == 'en' ? this.state.accountDetailsModal.licence : this.state.accountDetailsModal.licenceSv}</td>
                                        <td> {this.state.accountDetailsModal && this.state.accountDetailsModal.licenceDuration}</td>
                                        <td> {this.state.accountDetailsModal && this.state.accountDetailsModal.licencePrice ? Number(this.state.accountDetailsModal.licencePrice) + 'SEK' : 0}</td>
                                        <td> {this.state.accountDetailsModal && this.state.accountDetailsModal.numberOfLicences ? Number(this.state.accountDetailsModal.numberOfLicences) : 1}</td>

                                    </tr>
                                </tbody>
                            </Table> */}

                            <h6 className='preview-heading'>
                                <Isvg src={billingIcon} />
                                {'Licence details'.translate(this.props.lang)}
                            </h6>
                            <Row className='preview-details-modal'>
                                <Col lg="6">
                                    <div className='info-group'>
                                        <label>{'Licence'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.licence && this.props.lang == 'en' ? this.state.accountDetailsModal.licence : this.state.accountDetailsModal.licenceSv}</h6>
                                    </div>
                                    <div className='info-group'>
                                        <label>{'Licence price'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.licencePrice ? Number(this.state.accountDetailsModal.licencePrice) + " " + 'SEK' : 0}</h6>
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className='info-group'>
                                        <label>{'Licence duration'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.licenceDuration ? this.state.accountDetailsModal && this.state.accountDetailsModal.licenceDuration + " " + 'month(s)'.translate(this.props.lang): ''}</h6>
                                    </div>
                                    <div className='info-group'>
                                        <label>{'Number of licences'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.numberOfLicences ? Number(this.state.accountDetailsModal.numberOfLicences) : 1}</h6>
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className='info-group'>
                                        <label>{'Renewel date'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.experienceDate}</h6>
                                    </div>
                                    {/* <div className='info-group'>
                                        <label>{'Number of licences'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.numberOfLicences ? Number(this.state.accountDetailsModal.numberOfLicences) : 1 }</h6>
                                    </div> */}
                                </Col>
                            </Row>

                        </ModalBody>
                        <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }}>
                            <Button color="primary" onClick={() => this.setState({ accountDetailsModal: null })}> {'Close'.translate(this.props.lang)} </Button>




                        </ModalFooter>
                    </Modal>
                    : null}

            </div >
        );
    }
}

const selector = formValueSelector('modalForm');

export default connect(state => {
    return {}

}, {

})(Page(Customers));