import React, { Component } from 'react';
import Link from '../../link';

/**
* Custom checkbox
* @author   Milan Stanojevic
*/
class Checkbox extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {
        const { disabled, checked, label, onChange } = this.props;

        const checkboxClasses = disabled
            ? "checkbox-wrap-v2 checkbox-wrap-v2-disabled"
            : "checkbox-wrap-v2";

        return (
            <div
                className={checkboxClasses}
                onClick={(e) => {
                    if (disabled || !onChange) {
                        return;
                    }

                    const obj = {
                        target: {
                            checked: !checked,
                        },
                    };

                    onChange(obj);
                }}
            >
                <div className={checked ? "checkbox-v2 checkbox-v2-checked" : "checkbox-v2"}></div>
                <span>{label}</span>
            </div>
        );
    }
}

export default Checkbox;