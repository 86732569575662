import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    FormGroup,
    Label,
    Input,
    Button,
} from 'reactstrap';

import DeleteModal from '../../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import ErrorModal from '../../components/errorModal';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import Image from '../../components/forms/fields/image';
import FormBuilder from '../../components/forms/formBuilder';


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}
/**
* User level page
* @author   Milan Stanojevic
*/
class HomeLanding extends Component {
    constructor(props) {
        super(props);
        this.player = React.createRef();
        this.player2 = React.createRef();
        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            endpoints: [],
            formModules: [],
            languages: [],
            total: 0,
            loading: true,
            section1: { heading: '', image: '', description: '' },
            section2: { heading: '', image: '', description: '' },
            showPlayer: false,
            showPlayer2: false,
            // section3: { heading: '', image: '', description: '' }
        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.level != this.props.level && this.props.level > 90) {
            this.props.changeReadOnly(null);
        }

        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }
    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }


    /**
    * Create new or update user level
    * @author   Milan Stanojevic
    * @Objectparam    {String}  name        user level name
    * @Objectparam    {String}  level       user level
    */
    insertOrUpdate = (data) => {

        this.setState({
            loading: true,
            showPlayer: true,
        }, () => {
            fetch(API_ENDPOINT + '/data/home-landing/new', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (result) {
                    this.setState({
                        showPlayer: false,
                        showPlayer2: true
                    }, () => {
                        setTimeout(() => {
                            this.setState({ showPlayer2: false })
                        }, 1000)
                    })
                    this.get();
                }



            })
            // }
        })
    }

    stopAnimation = () => {
        this.player.current.play();
    }

    /**
 * Update language for user
 * @author   Milan Stanojevic
 * @Objectparam    {Object} data    form data
 */
    updateLanguage = (data) => {
        this.setState(
            {
                showPlayer: true,
                loadLang: true
            },
            () => {
                this.player.current.play();
                fetch(API_ENDPOINT + "/users/account/update-userdata", {
                    method: "POST",
                    headers: {
                        Authorization:
                            typeof localStorage !== "undefined"
                                ? `Bearer ${localStorage.getItem("authToken")}`
                                : null,
                        "content-type": "application/json",
                    },
                    body: JSON.stringify(data),
                })
                    .then(parseJSON)
                    .then(({ result, status }) => {
                        if (result.error) {
                            this.setState({ error: result.error, showPlayer: false });
                        } else {
                            this.setState(
                                {
                                    message: result.message,
                                    showPlayer: false,
                                    showPlayer2: true,

                                },
                                () => {
                                    setTimeout(() => {
                                        this.setState({ showPlayer2: false, loadLang: false });
                                    }, 1000);
                                }
                            );
                            this.props.verifyUser(() => {
                                this.setState({
                                    showForm: null,
                                }, () => {
                                    this.setState({
                                        showForm: true
                                    })
                                })
                            });
                        }
                    });
            }
        );
    };

    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }

        return (

            <div className="dashboard">
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="panel">
                                <div className="panel-header">
                                    <h5 className="component-header">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h5>
                                    <div className="language">
                                        <label for="languages">{'Language'.translate(this.props.lang)}</label>
                                        <select
                                            value={this.props.uData && this.props.uData.language}
                                            onChange={(e) => {
                                                this.updateLanguage({ language: e.target.value });
                                            }}
                                        >
                                            {this.state.languages &&
                                                this.state.languages.map((item, idx) => {
                                                    return (
                                                        <option key={idx} value={item._id}>
                                                            {item.name}
                                                        </option>
                                                    );
                                                })}
                                        </select>
                                    </div>
                                </div>
                                <div className="panel">
                                    <FormBuilder dateFormat={this.props.dateFormat}
                                        onSubmit={(data) => {
                                            this.insertOrUpdate(data)
                                        }
                                        }
                                        initialValues={this.state.initialValues}
                                        buttonText={
                                            this.state.showPlayer ? (
                                                <Player
                                                    onEvent={(event) => {
                                                        if (event === "load") this.stopAnimation();
                                                    }}
                                                    onStateChange={this.toggleVisibility}
                                                    ref={this.player} // set the ref to your class instance
                                                    autoplay={true}
                                                    loop={true}
                                                    controls={true}
                                                    src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                    style={{ height: "30px", width: "30px" }}
                                                ></Player>

                                            ) : this.state.showPlayer2 ? (
                                                <div style={{ display: 'flex', alignItems: 'center' }}><Player
                                                    onStateChange={this.toggleVisibility}
                                                    ref={this.player2} // set the ref to your class instance
                                                    autoplay={true}
                                                    loop={true}
                                                    controls={true}
                                                    src="https://assets10.lottiefiles.com/packages/lf20_CxyxLk.json"
                                                    style={{ height: "30px", width: "30px" }}
                                                ></Player>
                                                    {'Saved'.translate(this.props.lang)}
                                                </div>
                                            ) : (
                                                "Save".translate(this.props.lang)
                                            )
                                        }
                                        lang={this.props.lang}
                                        fields={[
                                            {
                                                type: 'row',
                                                children: [
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 6,
                                                            sm: 12,
                                                            xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'text',
                                                                name: 'section0.heading',
                                                                label: 'Section heading'.translate(this.props.lang),
                                                                multilang: true,
                                                                lang: this.props.lang
                                                            },
                                                            {
                                                                type: 'text',
                                                                name: 'section0.buttontext1',
                                                                label: 'Button text'.translate(this.props.lang),
                                                                multilang: true,
                                                                lang: this.props.lang
                                                            },
                                                            {
                                                                type: 'text',
                                                                name: 'section0.buttonlink1',
                                                                label: 'Button link'.translate(this.props.lang),
                                                                multilang: true,
                                                                lang: this.props.lang
                                                            },
                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 6,
                                                            sm: 12,
                                                            xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'text',
                                                                name: 'section0.subheading',
                                                                label: 'Section subheading'.translate(this.props.lang),
                                                                multilang: true,
                                                                lang: this.props.lang
                                                            },
                                                            {
                                                                type: 'text',
                                                                name: 'section0.buttontext2',
                                                                label: 'Button text'.translate(this.props.lang),
                                                                multilang: true,
                                                                lang: this.props.lang
                                                            },
                                                            {
                                                                type: 'text',
                                                                name: 'section0.buttonlink2',
                                                                label: 'Button link'.translate(this.props.lang),
                                                                multilang: true,
                                                                lang: this.props.lang
                                                            },
                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 12,
                                                            sm: 12,
                                                            xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'image',
                                                                name: 'section0.image',
                                                                label: 'Image'.translate(this.props.lang),
                                                            },
                                                        ]
                                                    },
                                                    // Section01
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 12,
                                                            sm: 12,
                                                            xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'text',
                                                                name: 'section01.heading01',
                                                                label: 'Section heading'.translate(this.props.lang),
                                                                multilang: true,
                                                                lang: this.props.lang
                                                            },
                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 3,
                                                            sm: 12,
                                                            xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'image',
                                                                name: 'section01.image0',
                                                                label: 'Image 1'.translate(this.props.translate)
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 3,
                                                            sm: 12,
                                                            xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'image',
                                                                name: 'section01.image1',
                                                                label: 'Image 2'.translate(this.props.translate)
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 3,
                                                            sm: 12,
                                                            xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'image',
                                                                name: 'section01.image2',
                                                                label: 'Image 3'.translate(this.props.translate)
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 3,
                                                            sm: 12,
                                                            xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'image',
                                                                name: 'section01.image3',
                                                                label: 'Image 4'.translate(this.props.translate)
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 3,
                                                            sm: 12,
                                                            xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'image',
                                                                name: 'section01.image4',
                                                                label: 'Image 1'.translate(this.props.translate)
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 3,
                                                            sm: 12,
                                                            xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'image',
                                                                name: 'section01.image5',
                                                                label: 'Image 1'.translate(this.props.translate)
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 3,
                                                            sm: 12,
                                                            xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'image',
                                                                name: 'section01.image6',
                                                                label: 'Image 1'.translate(this.props.translate)
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 3,
                                                            sm: 12,
                                                            xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'image',
                                                                name: 'section01.image7',
                                                                label: 'Image 1'.translate(this.props.translate)
                                                            }
                                                        ]
                                                    },
                                                    // End section01
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 6,
                                                            sm: 12,
                                                            xs: 12
                                                        },

                                                        children: [
                                                            {
                                                                type: 'col',
                                                                width: {
                                                                    lg: 12,
                                                                    sm: 12,
                                                                    xs: 12
                                                                },
                                                                children: [
                                                                    {
                                                                        type: 'image',
                                                                        name: 'section1.image',
                                                                        label: 'Section image'.translate(this.props.lang),

                                                                    },
                                                                ]
                                                            },

                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 6,
                                                            sm: 12,
                                                            xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'text',
                                                                name: 'section1.heading',
                                                                label: 'Heading'.translate(this.props.lang),
                                                                multilang: true,
                                                                lang: this.props.lang
                                                            },
                                                            {
                                                                type: 'html',
                                                                name: 'section1.description',
                                                                label: 'Description'.translate(this.props.lang),
                                                                multilang: true,
                                                                lang: this.props.lang,
                                                            },
                                                            {
                                                                type: 'text',
                                                                name: 'section1.buttonText',
                                                                label: 'Button text'.translate(this.props.lang),
                                                                multilang: true,
                                                                lang: this.props.lang
                                                            },
                                                            {
                                                                type: 'text',
                                                                name: 'section1.buttonLink',
                                                                label: 'Button Link'.translate(this.props.lang),
                                                                multilang: true,
                                                                lang: this.props.lang
                                                            },
                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 12,
                                                            sm: 12,
                                                            xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'text',
                                                                name: 'section3.heading',
                                                                label: 'Heading section 3'.translate(this.props.lang),
                                                                multilang: true,
                                                                lang: this.props.lang
                                                            },
                                                            {
                                                                type: 'textarea',
                                                                name: 'section3.text',
                                                                label: 'Text section 3'.translate(this.props.lang),
                                                                multilang: true,
                                                                lang: this.props.lang
                                                            }

                                                        ]
                                                    },

                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 3,
                                                            sm: 12,
                                                            xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'image',
                                                                name: 'section3.image1',
                                                                label: 'Image'.translate(this.props.lang),
                                                            },
                                                            {
                                                                type: 'text',
                                                                name: 'section3.text1',
                                                                label: 'Heading'.translate(this.props.lang),
                                                                multilang: true,
                                                                lang: this.props.lang
                                                            }

                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 3,
                                                            sm: 12,
                                                            xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'image',
                                                                name: 'section3.image2',
                                                                label: 'Image'.translate(this.props.lang),
                                                            },
                                                            {
                                                                type: 'text',
                                                                name: 'section3.text2',
                                                                label: 'Heading'.translate(this.props.lang),
                                                                multilang: true,
                                                                lang: this.props.lang
                                                            }

                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 3,
                                                            sm: 12,
                                                            xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'image',
                                                                name: 'section3.image3',
                                                                label: 'Image'.translate(this.props.lang),
                                                            },
                                                            {
                                                                type: 'text',
                                                                name: 'section3.text3',
                                                                label: 'Heading'.translate(this.props.lang),
                                                                multilang: true,
                                                                lang: this.props.lang
                                                            }

                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 3,
                                                            sm: 12,
                                                            xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'image',
                                                                name: 'section3.image4',
                                                                label: 'Image'.translate(this.props.lang),
                                                            },
                                                            {
                                                                type: 'text',
                                                                name: 'section3.text4',
                                                                label: 'Heading'.translate(this.props.lang),
                                                                multilang: true,
                                                                lang: this.props.lang
                                                            }

                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 3,
                                                            sm: 12,
                                                            xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'image',
                                                                name: 'section3.image5',
                                                                label: 'Image'.translate(this.props.lang),
                                                            },
                                                            {
                                                                type: 'text',
                                                                name: 'section3.text5',
                                                                label: 'Heading'.translate(this.props.lang),
                                                                multilang: true,
                                                                lang: this.props.lang
                                                            }

                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 3,
                                                            sm: 12,
                                                            xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'image',
                                                                name: 'section3.image6',
                                                                label: 'Image'.translate(this.props.lang),
                                                            },
                                                            {
                                                                type: 'text',
                                                                name: 'section3.text6',
                                                                label: 'Heading'.translate(this.props.lang),
                                                                multilang: true,
                                                                lang: this.props.lang
                                                            }

                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 3,
                                                            sm: 12,
                                                            xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'image',
                                                                name: 'section3.image7',
                                                                label: 'Image'.translate(this.props.lang),
                                                            },
                                                            {
                                                                type: 'text',
                                                                name: 'section3.text7',
                                                                label: 'Heading'.translate(this.props.lang),
                                                                multilang: true,
                                                                lang: this.props.lang
                                                            }

                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 3,
                                                            sm: 12,
                                                            xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'image',
                                                                name: 'section3.image8',
                                                                label: 'Image'.translate(this.props.lang),
                                                            },
                                                            {
                                                                type: 'text',
                                                                name: 'section3.text8',
                                                                label: 'Heading'.translate(this.props.lang),
                                                                multilang: true,
                                                                lang: this.props.lang
                                                            }

                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 6,
                                                            sm: 12,
                                                            xs: 12
                                                        },

                                                        children: [
                                                            
                                                            {
                                                                type: 'col',
                                                                width: {
                                                                    lg: 12,
                                                                    sm: 12,
                                                                    xs: 12
                                                                },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'section5.heading',
                                                                        label: 'Heading'.translate(this.props.lang),
                                                                        multilang: true,
                                                                        lang: this.props.lang
                                                                    },
                                                                    {
                                                                        type: 'html',
                                                                        name: 'section5.description',
                                                                        label: 'Description'.translate(this.props.lang),
                                                                        multilang: true,
                                                                        lang: this.props.lang,
                                                                    },
                                                                 
                                                                ]
                                                            },
                                                            
                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 6,
                                                            sm: 12,
                                                            xs: 12
                                                        },

                                                        children: [
                                                        
                                                            {
                                                                type: 'col',
                                                                width: {
                                                                    lg: 12,
                                                                    sm: 12,
                                                                    xs: 12
                                                                },
                                                                children: [
                                                                    {
                                                                        type: 'image',
                                                                        name: 'section5.image',
                                                                        label: 'Section image'.translate(this.props.lang),

                                                                    },
                                                                ]
                                                            },

                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 6,
                                                            sm: 12,
                                                            xs: 12
                                                        },

                                                        children: [
                                                            {
                                                                type: 'col',
                                                                width: {
                                                                    lg: 12,
                                                                    sm: 12,
                                                                    xs: 12
                                                                },
                                                                children: [
                                                                    {
                                                                        type: 'image',
                                                                        name: 'section4.image',
                                                                        label: 'Section image'.translate(this.props.lang),

                                                                    },
                                                                ]
                                                            },

                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 6,
                                                            sm: 12,
                                                            xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'text',
                                                                name: 'section4.heading',
                                                                label: 'Heading'.translate(this.props.lang),
                                                                multilang: true,
                                                                lang: this.props.lang
                                                            },
                                                            {
                                                                type: 'text',
                                                                name: 'section4.description',
                                                                label: 'Description'.translate(this.props.lang),
                                                                multilang: true,
                                                                lang: this.props.lang,
                                                            },
                                                            {
                                                                type: 'text',
                                                                name: 'section4.buttontext',
                                                                label: 'Button text'.translate(this.props.lang),
                                                                multilang: true,
                                                                lang: this.props.lang
                                                            },
                                                            {
                                                                type: 'text',
                                                                name: 'section4.buttonlink',
                                                                label: 'Button link'.translate(this.props.lang),
                                                                multilang: true,
                                                                lang: this.props.lang
                                                            },
                                                            {
                                                                type: 'text',
                                                                name: 'section4.description1',
                                                                label: 'Paragraph'.translate(this.props.lang),
                                                                multilang: true,
                                                                lang: this.props.lang,
                                                            },
                                                            {
                                                                type: 'text',
                                                                name: 'section4.buttontext1',
                                                                label: 'Button 1 text'.translate(this.props.lang),
                                                                multilang: true,
                                                                lang: this.props.lang
                                                            },
                                                            {
                                                                type: 'text',
                                                                name: 'section4.buttonlink1',
                                                                label: 'Button 1 link'.translate(this.props.lang),
                                                                multilang: true,
                                                                lang: this.props.lang
                                                            }
                                                        ]
                                                    },
                                                    


                                                ]
                                            },
                                        ]}
                                    ></FormBuilder>
                                </div>

                                <DeleteModal
                                    lang={this.props.lang}
                                    isOpen={this.state.deleteModal}
                                    toggle={() => this.setState({ deleteModal: null })}
                                    handler={() => { this.delete(this.state.deleteModal._id); this.setState({ deleteModal: null }) }}
                                >
                                    {'Delete user level'.translate(this.props.lang)} <strong>{this.state.deleteModal && this.state.deleteModal.name}</strong>?
                                </DeleteModal>
                                {
                                    this.state.error ?
                                        <ErrorModal lang={this.props.lang}
                                            isOpen={this.state.error}
                                            toggle={() => this.setState({ error: null })}

                                        >
                                            {this.state.error.translate(this.props.lang)}
                                        </ErrorModal>

                                        :

                                        null
                                }

                                {
                                    this.state.loadingImage ?
                                        <div className="file-progress-loader">
                                            <div>
                                                <Player
                                                    onEvent={(event) => {
                                                        if (event === "load" && this.player && this.player.current) this.player.current.play();
                                                    }}
                                                    ref={this.player} // set the ref to your class instance
                                                    autoplay={true}
                                                    loop={true}
                                                    controls={true}
                                                    src="/lf30_editor_l5cxzdyf.json"
                                                    renderer={'svg'}
                                                    style={{ height: "128px", width: "128px", }}
                                                ></Player>
                                                <h6>{'Processing file...'.translate(this.props.lang)}</h6>
                                            </div>

                                        </div>
                                        : null
                                }

                            </div>
                        </Col>
                    </Row>

                </Container>

            </div>
        );
    }
}

export default Page(HomeLanding);