import React, { Component } from 'react';
import Link from '../components/link';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import { API_ENDPOINT } from '../constants';

/**
* BankId login component
* @author   Milan Stanojevic
*/
class BankId extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        let loginStorageKey = this.props[0].match.params.loginStorageKey;
        // let groupAlias;
        // if (typeof window !== 'undefined' && localStorage.getItem('groupAlias')) {
        //     groupAlias = localStorage.getItem('groupAlias');
        // }

        let roomId;
        if (typeof window !== 'undefined' && localStorage.getItem('roomId')) {
            roomId = localStorage.getItem('roomId');

            localStorage.removeItem('roomId')
        }

        fetch(API_ENDPOINT + '/users/bankid/v2/login', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ loginStorageKey, roomId })
        }).then(res => res.json()).then((result) => {
            if (result.error) {
                this.setState({
                    error: result.error
                }, () => {
                    if(window.top){
                        window.top.postMessage('loggedin_error', '*')
                    }
                    // console.log(this.state.error);
                })
            } else {

                if (window.top) {
                    if (result.conversation) {
                        window.top.postMessage('conversation', '*')
                    } else {
                        if (result.newUser) {
                            window.top.postMessage('loggedin_newuser', '*')

                        } else {
                            window.top.postMessage('loggedin', '*')
                        }
                    }
                }

                localStorage.setItem('authToken', result.token);
                localStorage.setItem('loginMethod', 'bankid');
                localStorage.setItem('time', Math.floor(Date.now() / 1000))
                localStorage.setItem('profile', 'doctor');
                localStorage.setItem('userLevel', 20);
                // localStorage.setItem('logoutLandingPage', true);
                // if (localStorage.getItem('groupAlias'))
                //     localStorage.setItem('loginGroupAlias', localStorage.getItem('groupAlias'));

                //this.props.verifyUser();
                // this.props[0].history.push("/")
                //window.location.reload(false)

                if (result.message) {
                    this.props[0].history.push({
                        pathname: '/',
                        state: { bankIdError: result.message }
                    })
                } else {
                    // if (result.conversation) {
                    //     window.location.href = "/chat"
                    //     // this.props.verifyUser(() => {

                    //     //     this.props[0].history.push("/chat");

                    //     // });
                    // } else {
                    window.location.href = "/my-data/account"
                    // }

                }


            }
        });
    }

    render() {
        return (
            <div>            </div>
        );
    }
}

export default BankId;