import React, { Component } from 'react';
import Page from '../containers/page';
import BankIdModal from '../components/bankIdModal';
import { API_ENDPOINT } from '../constants';
import ErrorModal from './errorModal';

class VideoCallStarted extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.initialData,
            check: true

        };

    }


    componentDidMount() {

    }

    componentDidUpdate() {
        if (this.state.check) {
            if (this.props[0].location) {
                fetch(API_ENDPOINT + '/data/video-call-started/' + this.props[0].match.params.id, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(res => res.json()).then((result) => {
                    console.log(result);
                    if (result) {
                        this.props[0].history.push(result.link)
                    } else {
                        this.props[0].history.push('/')
                    }
                })

                this.setState({
                    check: false
                })
            }
        }

    }

    render() {


        return (
            <div>
            </div >
        );
    }
}



export default Page(VideoCallStarted);