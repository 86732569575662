import React, { Component } from "react";
import Page from "../../containers/page";

import {
    Container,
    Row,
    Col, Button, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

import FormBuilder from '../../components/forms/modalFormBuilder';
import ListBuilder from '../../components/listBuilder';
import { required, requiredNumber } from "../../components/forms/validation";
import DeleteModal from '../../components/deleteModal';
import resendEmail from '../../assets/svg/resend_email.svg';
import Isvg from 'react-inlinesvg'
import addIcon from '../../assets/svg/add-icon.svg';
import edit from '../../assets/svg/edit.svg';
import garabage from '../../assets/svg/garbage.svg';
import { formValueSelector, change } from "redux-form"; // ES6
import { connect } from "react-redux";
import {
    getSearchParams,
    generateSearchLink,
    generateSearchLinkMultiple,
} from "../../helpers/linkHelper";
import { API_ENDPOINT } from "../../constants";
import ErrorModal from "../../components/errorModal";
import InfoModal from "../../components/infoModal";
import Checkbox from "../../components/forms/fields/checkbox";

function parseJSON(response) {
    return response.json().then((json) => {
        return {
            result: json,
            status: response.status,
        };
    });
}

function parseText(response) {
    return response.text().then(text => {
        return {
            result: text,
            status: response.status
        }
    })
}

function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

class MyUserAccounts extends Component {
    constructor(props) {
        super(props);
        this.player = React.createRef();
        this.player2 = React.createRef();

        this.state = {
            showPlayer: false,
            showPlayer2: false,
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0,
                "query-group": null,
            },
            items: [],
            total: 0,
            numberLicenceUsed: 0,
            loading: true,
            groups: [],
            languages: [],
            clinicIdx: 0,
            showForm: false,
            tabIndex: 1,
            smsLicence: [],
            edit: false,
            admins: [],
            validateEmail: false,
        };
    }

    showPlayer = () => {
        this.setState({
            showPlayer: true,
        });
    };

    hidePlayer = () => {
        this.setState({
            showPlayer: false,
        });
    };

    get = () => {
        this.props.updateMeta(
            this.props.generateSeoTags(this.state, this.props.lang)
        );

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
                this.state.useState
                    ? this.state.params
                    : getSearchParams(this.props[0].location.search, {
                        entries: 10,
                        page: 0,
                        "query-group": null,
                    })
            ).then((data) => {
                this.setState(
                    {
                        ...data,
                        loading: null,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        );
                    }
                );
            });
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
                this.state.useState
                    ? this.state.params
                    : getSearchParams(this.props[0].location.search, {
                        entries: 10,
                        page: 0,
                        "query-group": null,
                        adminUser: this.state.adminUser
                    })
            ).then((data) => {
                this.setState(
                    {
                        ...data,
                        loading: null,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        );
                    }
                );
            });
        }
    };

    componentDidMount() {
        if (typeof window !== "undefined") {
            window.scrollTo(0, 0);
        }

        this.get();
        this.setState({ showForm: true })



    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState(
                {
                    loading: true,
                },
                () => {
                    this.get();
                }
            );
        }

        if (this.props.uData && this.props.uData.firstAdminUser && this.props.uData._id !== this.props.uData.adminUser && !this.state.adminUser) {
            this.setState({
                adminUser: this.props.uData.firstAdminUser
            }, () => this.get())
        }

        if (this.props.uData && !this.state.licenceArr) {

            if (this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.licenceMain == 'Enterprise') {
                // console.log(this.props.uData.licenceObj.licenceMain);
                let licenceArr = []
                licenceArr.push({ value: 'Enterprise', name: 'Enterprise'.translate(this.props.lang) }, { value: 'Pro', name: 'Pro'.translate(this.props.lang) }, { value: 'Standard', name: 'Standard'.translate(this.props.lang) })
                this.setState({ licenceArr: licenceArr })
            } else if (this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.licenceMain == 'Pro') {
                // console.log(this.props.uData.licenceObj.licenceMain);
                let licenceArr = []
                licenceArr.push({ value: 'Pro', name: 'Pro'.translate(this.props.lang) }, { value: 'Standard', name: 'Standard'.translate(this.props.lang) })
                this.setState({ licenceArr: licenceArr })
            } else {
                let licenceArr = []
                licenceArr.push({ value: 'Standard', name: 'Standard'.translate(this.props.lang) })
                this.setState({ licenceArr: licenceArr })
            }
        }

        if (prevProps.emailAddresses && this.props.emailAddresses && prevProps.emailAddresses != this.props.emailAddresses && this.props.userType === 'basic') {
            let emails = []
            if (this.props.emailAddresses && (this.props.emailAddresses.indexOf(';') !== -1 || this.props.emailAddresses.indexOf(',') !== -1)) {
                emails = this.props.emailAddresses.split(/[;,]+/);
            }

            if (emails && !emails.length && this.props.emailAddresses) {
                if (validateEmail(this.props.emailAddresses.trim())) {
                    // emails.push(this.props.emailAddresses)
                    this.setState({
                        validateEmail: false
                    })
                } else {
                    this.setState({
                        validateEmail: true,
                    })
                }
            } else if (emails && emails.length) {
                for (let i = 0; i < emails.length; i++) {
                    if (validateEmail(emails[i].trim())) {
                        // emails.push(emails[i])
                        this.setState({
                            validateEmail: false
                        })
                    } else {
                        this.setState({
                            validateEmail: true,
                        })
                    }

                }
            }
        }

        if (prevProps.emailAddresses && this.props.emailAddresses && prevProps.emailAddresses != this.props.emailAddresses && (this.state.adminLicence && this.state.adminLicence.numberOfLicencesMain || this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.numberOfLicencesMain) && this.props.userType === 'licensed') {
            let emails = []
            if (this.props.emailAddresses && (this.props.emailAddresses.indexOf(';') !== -1 || this.props.emailAddresses.indexOf(',') !== -1)) {
                emails = this.props.emailAddresses.split(/[;,]+/);
            }

            if (emails && !emails.length && this.props.emailAddresses) {
                if (validateEmail(this.props.emailAddresses.trim())) {
                    emails.push(this.props.emailAddresses)
                    this.setState({
                        validateEmail: false
                    })
                } else {
                    this.setState({
                        validateEmail: true,
                    })
                }
            } else if (emails && emails.length) {
                for (let i = 0; i < emails.length; i++) {
                    if (validateEmail(emails[i].trim())) {
                        // emails.push(emails[i])
                        this.setState({
                            validateEmail: false
                        })
                    } else {
                        this.setState({
                            validateEmail: true,
                        })
                    }

                }
            }

            if (this.state.validateEmail && (this.props.uData && Number(this.props.uData.licenceObj.numberOfLicencesMain) - 1 < this.state.numberLicenceUsed + emails.length)) {
                this.setState({
                    licenceWarning: true,
                    emailsInput: Number(this.props.uData.licenceObj.numberOfLicencesMain) - 1 - this.state.numberLicenceUsed,
                    disabledSave: true
                })
            } else if (this.state.validateEmail && (this.props.uData && Number(this.props.uData.licenceObj.numberOfLicencesMain) - 1 < emails.length)) {
                this.setState({
                    licenceWarning: true,
                    emailsInput: Number(this.props.uData.licenceObj.numberOfLicencesMain) - 1 - this.state.numberLicenceUsed,
                    disabledSave: true
                })
            }
        }

        if (this.props.userType && this.props.userType === 'licensed' && prevProps.userType && prevProps.userType != this.props.userType) {
            let licenceNumber = this.state.adminLicence && this.state.adminLicence.numberOfLicencesMain ? this.state.adminLicence.numberOfLicencesMain :
                this.props.uData && this.props.uData.licenceObj.numberOfLicencesMain
            // console.log(licenceNumber);
            let emails = []

            if (this.props.emailAddresses && (this.props.emailAddresses.indexOf(';') !== -1 || this.props.emailAddresses.indexOf(',') !== -1)) {
                emails = this.props.emailAddresses.split(/[;,]+/);
            }

            if (emails && !emails.length && this.props.emailAddresses) {
                if (validateEmail(this.props.emailAddresses.trim())) {
                    // console.log(validateEmail(this.props.emailAddresses));
                    emails.push(this.props.emailAddresses)
                    this.setState({
                        validateEmail: false
                    })
                } else {
                    this.setState({
                        validateEmail: true,
                    })
                }
            } else if (emails && emails.length) {
                for (let i = 0; i < emails.length; i++) {
                    if (validateEmail(emails[i].trim())) {
                        // emails.push(emails[i])
                        this.setState({
                            validateEmail: false
                        })
                    } else {
                        this.setState({
                            validateEmail: true,
                        })
                    }

                }
            }

            // console.log(emails.length, licenceNumber, this.state.numberLicenceUsed);
            if (Number(licenceNumber) - 1 < this.state.numberLicenceUsed + emails.length) {
                this.setState({
                    licenceWarning: true,
                    emailsInput: Number(licenceNumber) - 1 - this.state.numberLicenceUsed,
                    disabledSave: true
                })
            } else if (this.props.uData && emails && Number(licenceNumber) - 1 < emails.length) {
                this.setState({
                    licenceWarning: true,
                    emailsInput: Number(licenceNumber) - 1 - this.state.numberLicenceUsed,
                    disabledSave: true
                })
            }
        }


    }

    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state,
            });
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    [
                        { name: "sortField", value: field },
                        { name: "sortType", value: type },
                    ],
                    false
                )
            );
        }
    };

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj });
            }
        } else {
            this.props[0].history.push(
                generateSearchLink(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    name,
                    value,
                    restart
                )
            );
        }
    };

    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });
            }
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    fields,
                    restart
                )
            );
        }
    };

    stopAnimation = () => {
        this.player.current.play();
    };


    insertOrUpdate = (data) => {

        if (this.state.chekEmails) {
            this.setState({
                chekEmails: false,

            })
        }
        if (!data.license) {
            data.license = this.state.licenceArr[0].value
        }

        this.setState({
            loading: true,
        }, () => {
            if (this.state.adminUser) {
                data.adminUser = this.state.adminUser
            }

            fetch(API_ENDPOINT + '/data/users/accounts/new', {
                method: 'PUT',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (result.error) {
                    this.setState({ error: result.error })
                }
                this.get();
                this.setState({
                    _saving: false
                }, () => {
                    setTimeout(() => {
                        this.setState({ _saving: false, form: null })
                    }, 1000)
                })
            })
        })
    }

    checkUsers = (data) => {
        if (!data.invitedUsers) {
            this.setState({
                emailError: true
            })
            return
        }

        if (data.invitedUsers) {
            data.emailAddresses = data.invitedUsers.toString()
            delete data.invitedUsers;
            delete data.invitedEmails;
        }

        // console.log(data);
        fetch(API_ENDPOINT + '/data/users/number/accounts', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(parseJSON).then(({ result, status }) => {
            if (result.sentEmails && result.sentEmails.length && !data.edit) {
                let obj = {
                    userExists: result.sentEmails,
                    data: data
                }
                this.setState({
                    chekEmails: obj
                })
            } else {
                this.insertOrUpdate(data)
            }

        })
    }



    delete = (usedBy) => {
        // console.log(usedBy);
        this.setState({
            loading: true
        }, () => {

            fetch(API_ENDPOINT + '/data/users/accounts/delete', {
                method: 'DELETE',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ usedBy: usedBy, admin: this.props.uData._id })
            }).then(parseJSON).then(({ result, status }) => {
                if (result.error) {
                    this.setState({
                        error: result.error,
                        loading: false
                    })
                    this.get();
                } else {
                    this.setState({
                        loading: false
                    })
                    this.get();
                }
            })
        })

    }

    resendInvitation = (data) => {
        this.setState({
            loading: true
        }, () => {

            fetch(API_ENDPOINT + '/data/users/accounts/resend-invitation', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ email: data.email })
            }).then(parseJSON).then(({ result, status }) => {
                if (result.error) {
                    this.setState({ error: result.error })
                }
                this.get();
            })
        })
    }

    usersInvited = (e) => {

        let form = this.state.form;
        form.invitedEmails = e

        this.setState({
            form,
            emailError: false
        })

    }

    storeUsersInvitiation = (invitedEmails) => {
        let emails = invitedEmails.split(/[;,]+/)
        for (let i = 0; i < emails.length; i++) {
            let form = this.state.form;
            var storedUsers = [];
            if (form && form.invitedUsers) {
                storedUsers = [...form.invitedUsers]
            }
            if (storedUsers && storedUsers.length >= 1) {
                if (storedUsers.findIndex(item => item == emails[i].trim()) == -1)
                    if (validateEmail(emails[i].trim())) {
                        storedUsers.push(emails[i].trim())
                        this.setState({
                            error: ''
                        })
                    } else {
                        let error = this.state.error
                        error.email = 'Email address is not valid'.translate(this.props.lang)
                        this.setState({
                            error
                        })
                    }

            } else {
                if (validateEmail(emails[i].trim())) {
                    storedUsers.push(emails[i].trim())
                    this.setState({
                        error: ''
                    })
                } else {
                    let error = this.state.error
                    error.email = 'Email address is not valid'.translate(this.props.lang)
                    this.setState({
                        error
                    })
                }

            }
            form.invitedUsers = [...storedUsers]
            form.invitedEmails = ''
            if (form.invitedUsers && form.invitedUsers.length > 1) {
                form.userType = 'basic';
                form.department = '';
                form.jobTitle = '';
                form.location = '';
                form.manager = '';
            }
            this.setState({
                form,
                emailError: false
            })

        }
    }

    handleDelete = i => {
        if (this.state.form && this.state.form.invitedUsers.length && this.state.form.invitedUsers[i]) {

            let form = this.state.form
            form.invitedUsers.splice(i, 1)
            this.setState({
                form
            })
        }

    };

    render() {
        const isInvitedUsersEmpty = !this.state.form?.invitedUsers || this.state.form.invitedUsers.length === 0;

        const numberOfLicencesMain = Number(this.props.uData?.licenceObj?.numberOfLicencesMain) || 0;
        const remainingLicences = numberOfLicencesMain - 1 - (this.state.items ? this.state.items.filter(item => item.userType !== 'basic').length : 0);
        const invitedUsersLength = Number(this.state.form?.invitedUsers?.length) || 0;
        
        const shouldDisableCheckbox = isInvitedUsersEmpty || invitedUsersLength > remainingLicences;
    
        
        
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, {
                entries: 10,
                page: 0,
            });
        }
        return (
            <div className="dashboard">
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="panel">
                                <div className="panel-header">
                                    <h5 className="component-header">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h5>
                                    {
                                        !(this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.unpaidInvoice) ?
                                            <button className="add-button" onClick={() => this.setState({
                                                form: { userType: 'basic' }
                                            })}><Isvg src={addIcon} /> {'Add users'.translate(this.props.lang)}</button>
                                            :
                                            null
                                    }

                                </div>

                                {
                                    this.state.admins && this.state.admins.length && this.props.uData ?
                                        <div style={{ marginBottom: 35, width: '30%' }}>

                                            <Label>{'Select a list to manage'.translate(this.props.lang)}</Label>
                                            <Input type='select' value={this.state.adminUser} onChange={(e) => {
                                                // console.log(e.target.value);

                                                this.setState({
                                                    adminUser: e.target.value
                                                }, () => {

                                                    for (let i = 0; i < this.state.admins.length; i++) {
                                                        if (this.state.admins[i]._id == this.state.adminUser) {
                                                            let licenceObj = this.state.admins[i].licenceObj
                                                            this.setState({
                                                                adminLicence: licenceObj
                                                            }, () => {
                                                                if (this.state.adminLicence && this.state.adminLicence.licenceMain && this.state.adminLicence.licenceMain == 'Enterprise') {
                                                                    let licenceArr = []
                                                                    licenceArr.push({ value: 'Enterprise', name: 'Enterprise'.translate(this.props.lang) }, { value: 'Pro', name: 'Pro'.translate(this.props.lang) }, { value: 'Standard', name: 'Standard'.translate(this.props.lang) })
                                                                    this.setState({ licenceArr: licenceArr })
                                                                } else if (this.state.adminLicence && this.state.adminLicence.licenceMain && this.state.adminLicence.licenceMain == 'Pro') {
                                                                    let licenceArr = []
                                                                    licenceArr.push({ value: 'Pro', name: 'Pro'.translate(this.props.lang) }, { value: 'Standard', name: 'Standard'.translate(this.props.lang) })
                                                                    this.setState({ licenceArr: licenceArr })
                                                                } else {
                                                                    let licenceArr = []
                                                                    licenceArr.push({ value: 'Standard', name: 'Standard'.translate(this.props.lang) })
                                                                    this.setState({ licenceArr: licenceArr })
                                                                }
                                                            })
                                                        }

                                                    }

                                                    this.get()
                                                })
                                            }}>
                                                {this.props.uData.profileType == 'company' ?
                                                    <option value={this.props.uData._id}>{'My list'.translate(this.props.lang)}</option>
                                                    :
                                                    null
                                                }

                                                {this.state.admins.map(ad => {
                                                    return (
                                                        <option value={ad._id}>{ad.name + "list".translate(this.props.lang)} </option>
                                                    )
                                                })}
                                            </Input>
                                        </div>
                                        :
                                        null
                                }

                                <Col lg="12" className='col-no-padding form-clean'>
                                    {
                                        <ListBuilder
                                            licence={
                                                this.props && this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.licenceMain && this.props.uData.licenceObj.numberOfLicencesMain >= 1 ?
                                                    <div className="licence-listbuilder">
                                                        <div className="licence-type">

                                                            <strong> {'Licence type:'.translate(this.props.lang)}  </strong>

                                                            <p style={{ marginBottom: 0, marginLeft: 8 }}>
                                                                {this.props.uData && this.props.uData.licenceObj ? this.props.lang == 'en' ? this.props.uData.licenceObj.licenceMain : this.props.uData.licenceObj.licenceMainSv : ''}
                                                                - {
                                                                    this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.numberOfLicencesMain ?
                                                                        this.props.uData.licenceObj.numberOfLicencesMain + " " + 'licences'.translate(this.props.lang)
                                                                        :
                                                                        null
                                                                }
                                                            </p>
                                                        </div>
                                                        <div className="user-reneval-info">
                                                            <div>
                                                                <strong> {'Renewal date:'.translate(this.props.lang)} </strong>
                                                                <p> {
                                                                    this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.experienceDateMain ?
                                                                        this.props.uData.licenceObj.experienceDateMain
                                                                        :
                                                                        null
                                                                } </p>
                                                            </div>
                                                            {
                                                                this.state.renewLicence && this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.licenceMainDuration >= 1 ?
                                                                    <div>
                                                                        <Button color='primary' onClick={() => this.setState({ addLicence: true })}>{'Renew licence'.translate(this.props.lang)}</Button>
                                                                        {this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.licenceMain && this.props.uData.licenceObj.licenceMain.indexOf('Enterprise') == -1 ?
                                                                            <Button color='primary' onClick={() => this.props[0].history.push('/buy-licence')  /*this.setState({ upgradeLicence: true })*/}>{'Upgrade licence'.translate(this.props.lang)}</Button>
                                                                            :
                                                                            null}
                                                                    </div>
                                                                    :
                                                                    this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.licenceMain && this.props.uData.licenceObj.licenceMain.indexOf('Enterprise') == -1 ?
                                                                        <Button color='primary' onClick={() => this.props[0].history.push('/buy-licence')  /*this.setState({ upgradeLicence: true })*/}>{'Upgrade licence'.translate(this.props.lang)}</Button>
                                                                        :
                                                                        null

                                                            }
                                                        </div>

                                                    </div>
                                                    : null
                                            }
                                            lang={this.props.lang}
                                            loading={this.state.loading}
                                            total={this.state.total}
                                            showNumeration={false}
                                            fields={[
                                                { type: 'text', name: 'email', label: 'Email address/Username'.translate(this.props.lang), allowSort: true },
                                                { type: 'text', name: 'name', label: 'First and last name/Company name'.translate(this.props.lang), allowSort: true },
                                                { type: 'text', name: 'roleType', label: 'Role'.translate(this.props.lang), allowSort: true },
                                                { type: 'text', name: 'type', label: 'Type'.translate(this.props.lang), allowSort: true },
                                                { type: 'text', name: 'department', label: 'Department'.translate(this.props.lang), allowSort: true },
                                                { type: 'text', name: 'invitation', label: 'Invitation'.translate(this.props.lang), allowSort: false },


                                            ]}
                                            items={this.state.items.map(item => {
                                                // console.log(item && item.role);
                                                return {
                                                    ...item,
                                                    roleType: item && item.role && item.role == 'admin' ? 'Admin'.translate(this.props.lang) : 'Member'.translate(this.props.lang),
                                                    type: item && item.userType && item.userType == 'licensed' ? 'Licensed'.translate(this.props.lang) : 'Basic'.translate(this.props.lang),
                                                    invitation: item && item.verify ? 'Accepted'.translate(this.props.lang) : 'Sent'.translate(this.props.lang)


                                                }
                                            })}
                                            actions={
                                                [
                                                    {
                                                        component: <Isvg src={edit} className="edit-icon" />,
                                                        onClick: (item) => this.setState({ form: { ...item, emailAddresses: item.email, invitedUsers: [item.email], role: item.role, edit: true }, edit: true, adminUser: this.state.adminUser })
                                                    },
                                                    {
                                                        component: <Isvg src={garabage} className="delete-icon" />,
                                                        onClick: (item) => this.setState({ deleteModal: item })
                                                    },
                                                    {
                                                        component: <div className="tabel-action-tooltip"><Isvg src={resendEmail} className="delete-icon" /><div style={{ width: 180 }} className="chat-icons-tooltip">{'Resend invitation'.translate(this.props.lang)}</div> </div>,
                                                        onClick: (item) => this.resendInvitation(item),
                                                        // condition: (item) => {
                                                        //     if (item && item.registrationLinkDuration && item.registrationLinkDuration < Math.floor(new Date().getTime() / 1000)) {
                                                        //         return true
                                                        //     } else {
                                                        //         return false
                                                        //     }
                                                        // }
                                                    },
                                                ]
                                            }
                                            params={params}
                                            sortField={params.sortField}
                                            sortType={params.sortType}
                                            updateSort={this.updateSort}
                                            updateParams={this.updateParams}
                                            updateMultipleParams={this.updateMultipleParams}
                                        ></ListBuilder>
                                    }
                                    <DeleteModal
                                        lang={this.props.lang}
                                        isOpen={this.state.deleteModal}
                                        toggle={() => this.setState({ deleteModal: null })}
                                        handler={() => { this.delete(this.state.deleteModal); this.setState({ deleteModal: null }) }}
                                    >
                                        {'Delete user'.translate(this.props.lang)}<strong>{this.state.deleteModal && this.state.deleteModal.name ? ' ' + this.state.deleteModal.name : ''}</strong>?
                                    </DeleteModal>

                                    {this.state.error ?
                                        <ErrorModal lang={this.props.lang}
                                            isOpen={this.state.error}
                                            toggle={() => this.setState({ error: null })}
                                        >
                                            {this.state.error.translate(this.props.lang)}
                                        </ErrorModal>
                                        : null}

                                </Col>

                            </div>
                        </Col>
                    </Row>

                </Container>
                {
                    this.state.form ?
                        <Modal isOpen={this.state.form} centered size="lg">
                            <ModalHeader>
                                {this.state.edit ? 'Edit user'.translate(this.props.lang) : 'Add users'.translate(this.props.lang)}

                            </ModalHeader>
                            <ModalBody>

                                <div style={{ marginBottom: 10 }}>
                                    <div style={{ display: 'flex' }}>

                                        <Input disabled={this.state.form.edit ? true : false} type='text' onKeyPress={this._handleKeyDown} placeholder={'Email address'.translate(this.props.lang)} value={this.state.form.invitedEmails} onChange={(e) => this.usersInvited(e.target.value)} />
                                        <button className='add-emails-button' style={this.state.form.invitedEmails ? { backgroundColor: 'red' } : { background: 'primary' }} onClick={() => this.storeUsersInvitiation(this.state.form.invitedEmails)}>{'Add'.translate(this.props.lang)}</button>
                                    </div>
                                    {<div className={this.state.form.invitedUsers && this.state.form.invitedUsers.length ? 'email-list' : 'email-none-list'}>
                                        {this.state.form.invitedUsers && this.state.form.invitedUsers.map((mail, idx) => {
                                            return (
                                                <div className='schedule-tag' key={idx}>
                                                    <button>
                                                        {mail}
                                                        <span onClick={() => {
                                                            if (!this.state.form.edit) {
                                                                this.handleDelete(idx)
                                                            }
                                                        }}>x</span>
                                                    </button>
                                                </div>
                                            )
                                        })}
                                    </div>}



                                </div>
                                {
                                    this.state.emailError ?
                                        <div>
                                            <p style={{ color: 'red' }}>{'Add users with their email addresses!'.translate(this.props.lang)}</p>
                                        </div>
                                        :
                                        null
                                }

                                <div>
                                    <h5>{'User type'.translate(this.props.lang)}</h5>
                                    <div className="add-users-usertype">
                                        <div className="checkbox">
                                            <Checkbox onChange={() => {
                                                let form = this.state.form;
                                                form.userType = 'basic'
                                                this.setState({
                                                    form
                                                })
                                            }}
                                                checked={this.state.form.userType === 'basic' ? true : false} label={'Basic'.translate(this.props.lang)} />
                                        </div>
                                        <div className="checkbox">
                                            <Checkbox onChange={() => {
                                                let form = this.state.form;
                                                form.userType = 'licensed';
                                                this.setState({
                                                    form
                                                })
                                            }}
                                                disabled={shouldDisableCheckbox}
                                                checked={this.state.form.userType === 'licensed' ? true : false} label={'Licensed'.translate(this.props.lang)} />
                                        </div>
                                        {
                                            this.state.form.userType && this.state.form.userType === 'licensed' ?
                                                <div>
                                                    <Label>{'Licensed'.translate(this.props.lang)}</Label>
                                                    <select
                                                        value={this.state.form && this.state.form.license}
                                                        onChange={(e) => {
                                                            let form = this.state.form;
                                                            form.license = e.target.value
                                                            this.setState({
                                                                form
                                                            })
                                                        }}
                                                    >
                                                        {this.state.licenceArr &&
                                                            this.state.licenceArr.map((item, idx) => {
                                                                return (
                                                                    <option key={idx} value={item._id}>
                                                                        {item.name}
                                                                    </option>
                                                                );
                                                            })}
                                                    </select>
                                                </div>
                                                :
                                                null
                                        }
                                    </div>

                                </div>
                                <div>
                                    {
                                        this.state.form.edit ?
                                            <div style={{ marginBottom: 10 }}>
                                                <Label>{'User role'.translate(this.props.lang)}</Label>
                                                <select
                                                    value={this.state.form && this.state.form.role}
                                                    onChange={(e) => {
                                                        let form = this.state.form;
                                                        form.role = e.target.value
                                                        this.setState({
                                                            form
                                                        })
                                                    }}
                                                >
                                                    {[{ value: 'member', name: 'Member'.translate(this.props.lang) }, { value: 'admin', name: 'Admin'.translate(this.props.lang) }].map((item, idx) => {
                                                        return (
                                                            <option key={idx} value={item.value}>
                                                                {item.name}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                            :
                                            null
                                    }
                                    <div style={{ marginBottom: 10 }}>
                                        <Label>{'Department'.translate(this.props.lang)}</Label>
                                        <Input
                                            disabled={this.state.form.invitedUsers && this.state.form.invitedUsers.length > 1}
                                            type='text' name='department' value={this.state.form.department} onChange={(e) => {
                                                let form = this.state.form;
                                                form.department = e.target.value;
                                                this.setState({
                                                    form
                                                })
                                            }} />
                                    </div>
                                    <div style={{ marginBottom: 10 }}>
                                        <Label>{'Job Title'.translate(this.props.lang)}</Label>
                                        <Input
                                            disabled={this.state.form.invitedUsers && this.state.form.invitedUsers.length > 1}
                                            type='text' name='jobTitle' value={this.state.form.jobTitle} onChange={(e) => {
                                                let form = this.state.form;
                                                form.jobTitle = e.target.value;
                                                this.setState({
                                                    form
                                                })
                                            }} />
                                    </div>
                                    <div style={{ marginBottom: 10 }}>
                                        <Label>{'Location'.translate(this.props.lang)}</Label>
                                        <Input
                                            disabled={this.state.form.invitedUsers && this.state.form.invitedUsers.length > 1}
                                            type='text' name='location' value={this.state.form.location} onChange={(e) => {
                                                let form = this.state.form;
                                                form.location = e.target.value;
                                                this.setState({
                                                    form
                                                })
                                            }} />
                                    </div>
                                    <div style={{ marginBottom: 10 }}>
                                        <Label>{'Manager'.translate(this.props.lang)}</Label>
                                        <Input
                                            disabled={this.state.form.invitedUsers && this.state.form.invitedUsers.length > 1}
                                            type='text' name='manager' value={this.state.form.manager} onChange={(e) => {
                                                let form = this.state.form;
                                                form.manager = e.target.value;
                                                this.setState({
                                                    form
                                                })
                                            }} />
                                    </div>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button style={{ marginLeft: 15, marginBottom: '10px' }} onClick={() => this.setState({ form: null, role: false, edit: null })}>{'Close'.translate(this.props.lang)}</Button>
                                <Button onClick={() => this.checkUsers(this.state.form)}>{this.state.edit ? 'Save'.translate(this.props.lang) : 'Save and send email invitation'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        :
                        null
                }

                {
                    this.state.licenceWarning ?
                        <InfoModal
                            isOpen={this.state.licenceWarning}
                            toggle={() => this.setState({ licenceWarning: !this.state.licenceWarning })}
                            header={'Info'.translate(this.props.lang)}
                            info={'You have only'.translate(this.props.lang) + " " + (this.state.emailsInput < 0 ? 0 : this.state.emailsInput) + " " + 'licenses available.'.translate(this.props.lang)}
                            buttons={[
                                <Button color='primary' onClick={() => {
                                    this.setState({
                                        licenceWarning: false,
                                        disabledSave: false

                                    }, () => this.props.changeUserType('basic'))
                                }}>{'Ok'.translate(this.props.lang)}</Button>,
                            ]}

                        />
                        :
                        null
                }

                {
                    this.state.chekEmails ?
                        <InfoModal
                            isOpen={this.state.chekEmails}
                            toggle={() => this.setState({ chekEmails: !this.state.chekEmails })}
                            header={'Info'.translate(this.props.lang)}
                            info={'Mail address(es)'.translate(this.props.lang) + ': ' + this.state.chekEmails.userExists + ' ' + 'already exists.'.translate(this.props.lang)}
                            buttons={[
                                <Button color='primary' onClick={() =>
                                    this.insertOrUpdate({ ...this.state.chekEmails.data, userExists: this.state.chekEmails.userExists })
                                }>{'Ok'.translate(this.props.lang)}</Button>,
                            ]
                            }

                        />
                        :
                        null
                }

            </div >

        );
    }
}



const selector = formValueSelector('modalForm')
export default connect(
    (state) => {
        return {
            userType: selector(state, 'userType'),
            emailAddresses: selector(state, 'emailAddresses')
        };
    }, {
    changeUserType: (value) => change("modalForm", "userType", value),
}


)(Page(MyUserAccounts));
