import React, { Component } from 'react';
import Page from '../../containers/page';
import { API_ENDPOINT } from '../../constants';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';

import Isvg from 'react-inlinesvg';
import copyIcon from '../../assets/svg/copy-link-icon.svg';
import checkIcon from '../../assets/svg/check.svg';
import uncheckIcon from '../../assets/svg/close.svg';
import eyeIcon from '../../assets/svg/eye.svg';
import googleCalendarIcon from '../../assets/images/google-calendar.png';
import outlookCalendarIcon from '../../assets/svg/outlook-calendar.svg';
import xIcon from '../../assets/svg/x.svg';
import moment from 'moment';

import {
    Container,
    Row,
    Col,
    Label, Input, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Button, Table
} from 'reactstrap';


function parseJSON(response) {
    return response.json().then((json) => {
        return {
            result: json,
            status: response.status,
        };
    });
}

function convertFrom24To12Format(time24) {
    const [sHours, minutes] = time24.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
    const period = +sHours < 12 ? 'AM' : 'PM';
    const hours = +sHours % 12 || 12;
    return `${hours}:${minutes}`;
}

function convertFrom24To12StringFormat(time24) {
    if (time24) {
        let sHours = time24.split(":")[0]
        let minutes = time24.split(":")[1]
        // const [sHours, minutes] = time24.match(timeHs:timeMin).slice(1);
        const period = +sHours < 12 ? 'AM' : 'PM';
        const hours = +sHours % 12 || 12;

        return `${hours}:${minutes}`;
    }
}

function convertFrom24To12TSFormat(time24) {
    if (time24) {
        let time = moment.unix(time24).format('hh:mm')
        const [sHours, minutes] = time.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
        const period = +sHours < 12 ? 'AM' : 'PM';
        const hours = +sHours % 12 || 12;
        return `${hours}:${minutes} `;
    }
}

function convertFrom12To24Format(time12) {
    const [sHours, minutes, period] = time12.match(/([0-9]{1,2}):([0-9]{2}) (AM|PM)/).slice(1);
    const PM = period === 'PM';
    const hours = (+sHours % 12) + (PM ? 12 : 0);
    return `${('0' + hours).slice(-2)}:${minutes}`;
}



class ManageMeetting extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            passwordVisibility: true,
            coppiedInv: false,
            form: false,
            conversation: false
        };
    }

    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }
        // console.log(this.props && this.props[0] && this.props[0].location && this.props[0].location.state);

        if (this.props && this.props[0] && this.props[0].location && this.props[0].location.state && this.props[0].location.state.form) {
            this.setState({
                form: this.props[0].location.state.form
            })
        } else if (this.props && this.props[0] && this.props[0].location && this.props[0].location.state && this.props[0].location.state.conversationID) {
            fetch(API_ENDPOINT + '/data/users/schedule/meeting/' + this.props[0].location.state.conversationID, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(),
            }).then(parseJSON).then(({ result, status }) => {
                if (result) {
                    // let conversationDateAndTime = false
                    if (result.recurring) {
                        // conversationDateAndTime = {}
                        if (this.props[0].location.state.scheduledDate !== 'undefined') {
                            // conversationDateAndTime.scheduledDate = result.scheduledDate
                            result.scheduledDate = this.props[0].location.state.scheduledDate
                        }
                        if (this.props[0].location.state.scheduledTime !== 'undefined') {
                            // conversationDateAndTime.scheduledTime = result.scheduledTime
                            result.scheduledTime = this.props[0].location.state.scheduledTime
                        }
                        if (this.props[0].location.state.passcode !== 'undefined') {
                            result.passcode = this.props[0].location.state.passcode


                        }
                        if (this.props[0].location.state.invitedUsers !== 'undefined') {
                            result.invitedUsers = this.props[0].location.state.invitedUsers
                        }
                        if (this.props[0].location.state.members !== 'undefined') {
                            result.members = this.props[0].location.state.members
                        }
                        if (this.props[0].location.state.requireBankID !== 'undefined') {
                            result.requireBankID = this.props[0].location.state.requireBankID
                        }
                        if (this.props[0].location.state.requireNetID !== 'undefined') {
                            result.requireNetID = this.props[0].location.state.requireNetID
                        }
                        if (this.props[0].location.state.muteParticipants !== 'undefined') {
                            result.muteParticipants = this.props[0].location.state.muteParticipants
                        }
                        if (this.props[0].location.state.recordConversationAsVideo !== 'undefined') {
                            result.recordConversationAsVideo = this.props[0].location.state.recordConversationAsVideo
                        }
                        if (this.props[0].location.state.mandatoryScreenName !== 'undefined') {
                            result.mandatoryScreenName = this.props[0].location.state.mandatoryScreenName
                        }
                        if (this.props[0].location.state.recordConversationAsVideoMembers !== 'undefined') {
                            result.recordConversationAsVideoMembers = this.props[0].location.state.recordConversationAsVideoMembers
                        }
                    }

                    if (this.props[0].location.state.passcode && this.props[0].location.state.passcode.length) {
                        result.passcodeCheckbox = this.props[0].location.state.passcode ? true : false
                    }

                    this.setState({
                        form: result,
                        // conversationDateAndTime: conversationDateAndTime
                    })
                }
            })
        }




        this.get();
    }

    togglePasswordVisibility = () => {
        this.setState({
            passwordVisibility: !this.state.passwordVisibility
        })
    }


    copyLink = () => {
        this.showCopiedLink()
        navigator.clipboard.writeText(this.state.form.smallLink ? `${window.location.origin}/${this.state.form.smallLink}` : this.state.form.inviteLink ? this.state.form.inviteLink : '');
    }

    copyInvite = () => {
        this.setState({
            inviteModal: this.state.form
        })

    }

    signInGoogle = () => {

        return new Promise((resolve, reject) => {
            if (this.gapi) {
                resolve(this.gapi);
                return;
            }

            var gapi = window.gapi
            var CLIENT_ID = '884844421012-efq8lc9gim4e52510i0tnst1q0hfkcnr.apps.googleusercontent.com';
            var API_KEY = 'AIzaSyCDx-ZCVWi8K3ge5W6vhsXqreNFhH2-1XU';
            var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
            var SCOPES = "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events";

            gapi.load('client:auth2', () => {

                gapi.client.init({
                    apiKey: API_KEY,
                    clientId: CLIENT_ID,
                    discoveryDocs: DISCOVERY_DOCS,
                    scope: SCOPES,
                })

                gapi.client.load('calendar', 'v3', () => gapi.auth2.getAuthInstance().signIn()
                    .then(() => {
                        this.gapi = gapi;
                        resolve(gapi);
                    }))
            })
        })
    }

    // getGmailEvents = (data, callback) => {

    //     this.signInGoogle().then((gapi) => {
    //         gapi.client.calendar.events.list({
    //             'calendarId': 'primary',
    //             'timeMin': (new Date()).toISOString(), // vratitti datum iz konverzacije
    //             'showDeleted': false,
    //             'singleEvents': true,
    //             'maxResults': 10,
    //             'orderBy': 'startTime'
    //         }).then(function (response) {
    //             var events = response.result.items;
    //             callback(events)
    //         });

    //     })
    // }

    formatGmt = (dateStr, targetTimezone) => {
        let year = Number(dateStr.split('-')[0]);
        let month = Number(dateStr.split('-')[1]);
        let day = Number(dateStr.split('-')[2].split(' ')[0]);

        let hour = Number(dateStr.split(' ')[1].split(':')[0]);
        let minutes = Number(dateStr.split(':')[1]);

        let date = new Date(Date.UTC(year, month, day, hour, minutes, 0, 0));
        // console.log('UTC Date: ', date)
        // console.log(year, month, day, hour, minutes, targetTimezone)
        let tzDateTarget = new Date(date.toLocaleString('en-US', { timeZone: targetTimezone }));
        // console.log(tzDateTarget);
        let gmt = tzDateTarget.toString().substring(25, 31);
        // console.log(gmt);
        return gmt;
    }

    showCopiedLink = () => {
        this.setState({
            linkCopied: true
        })
        setTimeout(() => {
            this.setState({
                linkCopied: false
            })
        }, 1000)
    }


    // add event gmail calendar
    addEventGmail = () => {
        // let duration = '00:30:00';
        let data = this.state.form
        // if (this.state.conversationDateAndTime) {
        //     if (this.state.conversationDateAndTime.scheduledDate) {
        //         data.scheduledDate = this.state.conversationDateAndTime.scheduledDate
        //     }
        //     if (this.state.conversationDateAndTime.scheduledTime) {
        //         data.scheduledTime = this.state.conversationDateAndTime.scheduledTime
        //     }
        // }
        let hours = this.state.form.durationHs ? this.state.form.durationHs < 10 ? '0' + this.state.form.durationHs : this.state.form.durationHs : '0';
        let minute = this.state.form.durationMin ? this.state.form.durationMin < 10 ? '0' + this.state.form.durationMin : this.state.form.durationMin : '20'
        let duration = `${hours}:${minute}:00`;
        let startDate = moment.unix(new Date(data.scheduledDate + ' ' + convertFrom12To24Format(`${data.scheduledTime} ${data.dateType}`)).getTime() / 1000);

        let msDuration = (Number(duration.split(':')[0]) * 60 * 60 + Number(duration.split(':')[1]) * 60 + Number(duration.split(':')[2])) * 1000;
        let endDate = new Date(startDate + msDuration);
        let isoStartDate = new Date(startDate - new Date(startDate).getTimezoneOffset() * 60 * 1000).toISOString().split(".")[0];
        let isoEndDate = new Date(endDate.getTime() - (new Date().getTimezoneOffset(data.timezone)) * 60 * 1000).toISOString().split(".")[0];
        let recuringDateUntil;
        let frequency;
        let frequencyInterval;
        let frequencyDayOfWeek = [];
        let scheduledEndDateUTC
        let date = new Date(data.scheduledDate)
        date.setHours(0, 0, 0, 0)
        if (data.dateAfter && data.scheduleDateAfter) {
            scheduledEndDateUTC = Math.floor(new Date(date).getTime() / 1000)
            if (data.recurringObj && data.recurringObj.recurrence == 'Daily') {
                scheduledEndDateUTC += data.recurringObj.repeat * 60 * 60 * 24 * Number(data.scheduleDateAfter) - 60 * 60 * 24
                scheduledEndDateUTC = moment.unix(scheduledEndDateUTC).format('YYYY-MM-DD')

            } else if (data.recurringObj && data.recurringObj.recurrence == 'Weekly') {
                let weekArr = []
                if (data.recurringObj.checkMon) {
                    weekArr.push(1)
                }
                if (data.recurringObj.checkTue) {
                    weekArr.push(2)
                }
                if (data.recurringObj.checkWed) {
                    weekArr.push(3)
                }
                if (data.recurringObj.checkThu) {
                    weekArr.push(4)
                }
                if (data.recurringObj.checkFri) {
                    weekArr.push(5)
                }
                if (data.recurringObj.checkSat) {
                    weekArr.push(6)
                }
                if (data.recurringObj.checkSun) {
                    weekArr.push(7)
                }
                let date = new Date(data.scheduledDate)
                // console.log(date, "1");
                let changed = false
                let skip = 0
                while (!changed) {
                    date = new Date(date)
                    // console.log(date, "2");
                    let day = date.getDay() == 0 ? 7 : date.getDay()
                    for (let i = 0; i < weekArr.length; i++) {
                        if (day <= weekArr[i]) {
                            date.setDate(date.getDate() + (weekArr[i] - day))
                            changed = true
                            break
                        }
                        skip++

                    }
                    if (!changed)
                        date.setDate(date.getDate() + (8 - day))
                    // console.log(date, "3");
                }


                let endDateWeek = new Date(date)
                let wholeWeek = Number(data.scheduleDateAfter) / weekArr.length
                // console.log(endDateWeek, "date1");
                if (wholeWeek > 2) {
                    // console.log("2 week");
                    let used = weekArr.length - skip
                    // console.log(used, 'used');
                    let day = endDateWeek.getDay() == 0 ? 7 : endDateWeek.getDay()
                    endDateWeek.setDate(endDateWeek.getDate() + (8 - day))
                    endDateWeek = new Date(endDateWeek)
                    // console.log(endDateWeek, "date2");
                    endDateWeek.setDate(endDateWeek.getDate() + (Math.floor(wholeWeek) - 1) * 7)
                    used += (Math.floor(wholeWeek) - 1) * weekArr.length
                    endDateWeek = new Date(endDateWeek)
                    // day = endDateWeek.getDay() == 0 ? 7 : endDateWeek.getDay()
                    // console.log(endDateWeek, "date3");
                    // console.log((Number(data.scheduleDateAfter) / weekArr.length) % 2 != 0);
                    if ((Number(data.scheduleDateAfter) / weekArr.length) % 2 != 0)
                        endDateWeek.setDate(endDateWeek.getDate() + weekArr[Number(data.scheduleDateAfter) - used - 1] - 1)
                } else {
                    let used = weekArr.length - skip
                    // console.log("week");
                    let day = endDateWeek.getDay() == 0 ? 7 : endDateWeek.getDay()
                    // console.log(day, "day");
                    endDateWeek.setDate(endDateWeek.getDate() + (8 - day))
                    // console.log(endDateWeek, "endDateWeek");
                    endDateWeek = new Date(endDateWeek)
                    // console.log(endDateWeek, "date3");
                    // day = endDateWeek.getDay() == 0 ? 7 : endDateWeek.getDay()
                    // console.log(weekArr[weekArr.length - 1] - 1);
                    endDateWeek.setDate(endDateWeek.getDate() + weekArr[Number(data.scheduleDateAfter) - used - 1] - 1)
                }


                // console.log(endDateWeek, "date4");

                let scheduledDate = moment(new Date(date)).format('YYYY-MM-DD')
                // console.log(scheduledDate);
                startDate = moment.unix(new Date(scheduledDate + ' ' + convertFrom12To24Format(`${data.scheduledTime} ${data.dateType}`)).getTime() / 1000);
                msDuration = (Number(duration.split(':')[0]) * 60 * 60 + Number(duration.split(':')[1]) * 60 + Number(duration.split(':')[2])) * 1000;
                endDate = new Date(startDate + msDuration);
                isoStartDate = new Date(startDate - new Date().getTimezoneOffset() * 60 * 1000).toISOString().split(".")[0];

                isoEndDate = new Date(endDate.getTime() /*- (new Date().getTimezoneOffset(data.timezone)) * 60 * 1000*/).toISOString().split(".")[0];
                scheduledEndDateUTC = endDate
                // scheduledEndDateUTC = new Date(endDate)
                // scheduledEndDateUTC += scheduledEndDateUTC.setDate((scheduledEndDateUTC.getDate()) + (Number(data.scheduleDateAfter) + 7));
                scheduledEndDateUTC = moment(new Date(endDateWeek)).format('YYYY-MM-DD')
                // console.log(scheduledEndDateUTC, "ssssdsdgfdfdfd");
            } else if (data.recurringObj && data.recurringObj.recurrence == 'Monthly') {
                let d = new Date();
                let scheduled = new Date(data.scheduledDate)
                scheduled = scheduled.setDate(Number(data.recurringObj.frequencyMonthScheduled))
                if (d.getDate() > new Date(scheduled).getDate()) {
                    scheduled = new Date(scheduled)
                    scheduled = scheduled.setMonth(scheduled.getMonth() + 1)
                }

                scheduled = moment(scheduled).format('YYYY-MM-DD')

                startDate = moment.unix(new Date(scheduled + ' ' + convertFrom12To24Format(`${data.scheduledTime} ${data.dateType}`)).getTime() / 1000);
                msDuration = (Number(duration.split(':')[0]) * 60 * 60 + Number(duration.split(':')[1]) * 60 + Number(duration.split(':')[2])) * 1000;
                endDate = new Date(startDate + msDuration);
                isoStartDate = new Date(startDate - new Date().getTimezoneOffset() * 60 * 1000).toISOString().split(".")[0];
                isoEndDate = new Date(endDate.getTime() - (new Date().getTimezoneOffset(data.timezone)) * 60 * 1000).toISOString().split(".")[0];

                scheduledEndDateUTC = new Date(data.scheduledDate)
                scheduledEndDateUTC = scheduledEndDateUTC.setMonth(scheduledEndDateUTC.getMonth() + Number(data.scheduleDateAfter) - 1)
                scheduledEndDateUTC = new Date(scheduledEndDateUTC)
                scheduledEndDateUTC = scheduledEndDateUTC.setDate(Number(data.recurringObj.frequencyMonthScheduled))
                scheduledEndDateUTC = moment(scheduledEndDateUTC).format('YYYY-MM-DD')
            }
        } else {
            scheduledEndDateUTC = String(data.scheduleEndDate);
        }

        if (data.recurring && data.recurringObj.recurrence == 'Daily') {

            let day = new Date(scheduledEndDateUTC)
            day.setHours(23, 59, 59)
            let endDateRecuring = moment.unix(new Date(day).getTime() / 1000);
            recuringDateUntil = new Date(endDateRecuring /*- new Date().getTimezoneOffset() * 60 * 1000*/).toISOString().replace(/-|:|\./g, '').replace('000', '');
            frequency = 'DAILY' + ';UNTIL=' + recuringDateUntil + ';INTERVAL=' + data.recurringObj.repeat
        } else if (data.recurring && data.recurringObj.recurrence == 'Weekly') {
            let day = new Date(scheduledEndDateUTC)
            day.setHours(23, 59, 59)
            let endDateRecuring = moment.unix(new Date(day).getTime() / 1000);
            recuringDateUntil = new Date(endDateRecuring - new Date().getTimezoneOffset() * 60 * 1000).toISOString().replace(/-|:|\./g, '').replace('000', '');
            let arr = []
            let count = 0
            frequencyDayOfWeek = ';BYDAY='
            if (data.recurringObj.checkMon) {
                count++
                arr.push('MO')
            }
            if (data.recurringObj.checkTue) {
                count++
                arr.push('TU')
            }
            if (data.recurringObj.checkWed) {
                count++
                arr.push('WE')
            }
            if (data.recurringObj.checkThu) {
                count++
                arr.push('TH')
            }
            if (data.recurringObj.checkFri) {
                count++
                arr.push('FR')
            }
            if (data.recurringObj.checkSat) {
                count++
                arr.push('SA')
            }
            if (data.recurringObj.checkSun) {
                count++
                arr.push('SU')
            }

            frequencyDayOfWeek += arr.join(',')
            // console.log(frequencyDayOfWeek)

            if (frequencyDayOfWeek) {
                frequencyInterval = ';INTERVAL=1' //+ count
            }

            let countt = ';COUNT=' + count
            frequency = 'WEEKLY' + frequencyDayOfWeek + ';UNTIL=' + recuringDateUntil

        } else if (data.recurring && data.recurringObj.recurrence == 'Monthly') {
            let d = new Date();
            let scheduled = new Date(data.scheduledDate)
            scheduled = scheduled.setDate(Number(data.recurringObj.frequencyMonthScheduled))
            if (d.getDate() > new Date(scheduled).getDate()) {
                scheduled = new Date(scheduled)
                scheduled = scheduled.setMonth(scheduled.getMonth() + 1)
            }

            scheduled = moment(scheduled).format('YYYY-MM-DD')

            startDate = moment.unix(new Date(scheduled + ' ' + convertFrom12To24Format(`${data.scheduledTime} ${data.dateType}`)).getTime() / 1000);
            msDuration = (Number(duration.split(':')[0]) * 60 * 60 + Number(duration.split(':')[1]) * 60 + Number(duration.split(':')[2])) * 1000;
            endDate = new Date(startDate + msDuration);
            isoStartDate = new Date(startDate - new Date().getTimezoneOffset() * 60 * 1000).toISOString().split(".")[0];
            isoEndDate = new Date(endDate.getTime() - (new Date().getTimezoneOffset(data.timezone)) * 60 * 1000).toISOString().split(".")[0];

            scheduledEndDateUTC = new Date(data.scheduledDate)
            scheduledEndDateUTC = scheduledEndDateUTC.setMonth(scheduledEndDateUTC.getMonth() + Number(data.scheduleDateAfter) - 1)
            scheduledEndDateUTC = new Date(scheduledEndDateUTC)
            scheduledEndDateUTC = scheduledEndDateUTC.setDate(Number(data.recurringObj.frequencyMonthScheduled))
            let day = new Date(scheduledEndDateUTC)
            scheduledEndDateUTC = moment(scheduledEndDateUTC).format('YYYY-MM-DD')


            day.setHours(23, 59, 59)
            let endDateRecuring = moment.unix(new Date(day).getTime() / 1000);
            recuringDateUntil = new Date(endDateRecuring - new Date().getTimezoneOffset() * 60 * 1000).toISOString().replace(/-|:|\./g, '').replace('000', '');
            frequency = 'MONTHLY' + ';BYMONTHDAY=' + data.recurringObj.frequencyMonthScheduled + ';UNTIL=' + recuringDateUntil
            // console.log(recuringDateUntil, "recuringDateUntil");
        } else {
            frequency = 'DAILY;COUNT=1'
        }
        // console.log(isoStartDate, isoEndDate, recuringDateUntil);
        // console.log(data.scheduledTime);
        // console.log(isoStartDate);

        let gmt = this.formatGmt(data.scheduledDate + ' ' + data.scheduledTime, data.timezone)
        this.signInGoogle()
            .then((gapi) => {
                let passcode = data.passcode ? 'Passcode:' + data.passcode : ''
                var event = {
                    'summary': `${data.name}`,
                    'location': `${window.location.origin}/${data.smallLink}`,
                    'description': `You have been invited to a video call ${data.name}\n\n The video call is scheduled for ${data.scheduledDate} at:${convertFrom24To12StringFormat(data.scheduledTime)} ${data.dateType ? data.dateType : ''} ${gmt}\n\nClick on the link below and follow the on-screen instructions to join the video call.\n\n<a href='${window.location.origin}/${data.smallLink}'>${window.location.origin}/${data.smallLink}</a>\n\n ${passcode}`,
                    'start': {
                        'dateTime': isoStartDate,
                        'timeZone': `${data.timezone}`
                    },
                    'end': {
                        'dateTime': isoEndDate,
                        'timeZone': `${data.timezone}`
                    },
                    'recurrence': [
                        `RRULE:FREQ=${frequency}`,
                    ],
                    'attendees': [
                        { 'email': `${this.props.uData.email}` },
                    ],
                    'reminders': {
                        'useDefault': false,
                        'overrides': [
                            { 'method': 'email', 'minutes': 24 * 60 },
                            { 'method': 'popup', 'minutes': 10 }
                        ]
                    }
                }

                var request = gapi.client.calendar.events.insert({
                    'calendarId': 'primary',
                    // 'eventId': `${data.conversationID}`,
                    'resource': event,
                })

                request.execute(event => {
                    // console.log(event)
                    let obj = {
                        conversationID: data.conversationID,
                        eventGmail: event.id
                    }
                    fetch(API_ENDPOINT + "/gmail/add/conversation/" + data._id, {
                        method: "POST",
                        headers: {
                            "content-type": "application/json",
                            Authorization: typeof localStorage !== "undefined" ? `Bearer ${localStorage.getItem("authToken")}` : null,
                        },
                        body: JSON.stringify(obj)
                    }).then((res) => res.json()).then((result) => {
                        if (result && result.error) {
                            this.setState({
                                error: result.error
                            })
                        }
                        this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage.profile : null });
                    });
                    let htmllink = event.htmlLink.split('=')
                    // console.log(htmllink)
                    // console.log('https://calendar.google.com/calendar/u/0/r/eventedit/'+htmllink[1]+'?pli=1')
                    window.open('https://calendar.google.com/calendar/u/0/r/eventedit/' + htmllink[1])
                    // window.open(event.htmlLink)
                })

                this.setState({
                    calendarConnectModal: false
                })

            })
    }

    getGmailEvents = (data, callback) => {

        this.signInGoogle().then((gapi) => {
            gapi.client.calendar.events.instances({
                'calendarId': 'primary',
                'eventId': data.eventGmail
            }).then(function (response) {
                var events = response.result.items;
                callback(events)
            });

        })
    }

    updateGmailEvent = (dataa, events) => {
        // console.log(dataa, 'data', events, 'events');
        let data = dataa

        for (let i = 0; i < events.length; i++) {
            if (events[i].start && events[i].start.dateTime) {
                let check = events[i].start.dateTime.split('T')[0]
                if (check === data.scheduledDate) {
                    data.id = events[i].id
                }
            }

        }

        let duration = '00:30:00';
        let startDate = moment.unix(new Date(data.scheduledDate + ' ' + data.scheduledTime).getTime() / 1000);
        let msDuration = (Number(duration.split(':')[0]) * 60 * 60 + Number(duration.split(':')[1]) * 60 + Number(duration.split(':')[2])) * 1000;
        let endDate = new Date(startDate + msDuration);
        let isoStartDate = new Date(startDate - new Date().getTimezoneOffset() * 60 * 1000).toISOString().split(".")[0];
        let isoEndDate = new Date(endDate.getTime() - (new Date().getTimezoneOffset(data.timezone)) * 60 * 1000).toISOString().split(".")[0];

        this.signInGoogle()
            .then((gapi) => {
                gapi.client.calendar.events.update({
                    "calendarId": "primary",
                    "eventId": data.id,
                    "alwaysIncludeEmail": false,
                    "sendNotifications": true,
                    "sendUpdates": "all",
                    "supportsAttachments": true,
                    "prettyPrint": true,
                    "alt": "json",
                    "resource": {

                        "start": {
                            'dateTime': isoStartDate,
                            'timeZone': `${data.timezone}`
                        },
                        "end": {
                            'dateTime': isoEndDate,
                            'timeZone': `${data.timezone}`
                        },
                        "summary": `${data.name}`
                    }
                }).then(function (response) {

                    // Handle the results here (response.result has the parsed body).
                    // console.log("Response", response);
                },
                    function (err) { console.error("Execute error", err); });

                this.setState({
                    calendarConnectModal: false
                })

            })



    }

    connectWithOutlook = (data) => {
        fetch(API_ENDPOINT + "/auth/callback", {
            method: "POST",
            headers: {
                "content-type": "application/json",
                Authorization: typeof localStorage !== "undefined" ? `Bearer ${localStorage.getItem("authToken")}` : null,
            },
            body: JSON.stringify({
                conversation: data,
            })
        }).then((res) => res.json()).then((result) => {
            // console.log(result, 'menage');
            if (result && result.error) {
                this.setState({
                    error: result.error
                })
            } else {

                this.setState({
                    calendarConnectModal: false
                })

                if (result.link)
                    window.open(API_ENDPOINT + result.link)

            }
        });
    }
    checkIfEventIsActive = (edit = false) => {
        let check = true
        if (this.state.form && this.state.form.events && this.state.form.recurring) {
            for (let m = 0; m < this.state.form.events.length; m++) {
                if (this.props[0].location.state && this.props[0].location.state.scheduledDate && this.state.form.events[m] && this.state.form.events[m].scheduledDate === this.props[0].location.state.scheduledDate) {

                    let date = new Date(this.state.form.events[m].scheduledDate)
                    let dateNow = new Date()
                    if (edit) {
                        if (date.getMonth() >= dateNow.getMonth() && date.getDate() >= dateNow.getDate()) {
                            check = false
                        } else {
                            check = true
                        }
                    } else {
                        if (date.getMonth() === dateNow.getMonth() && date.getDate() === dateNow.getDate()) {
                            check = false
                        } else {
                            check = true
                        }
                    }


                    break;
                }
            }
        } else {
            check = false
        }

        return check
    }


    checkIfEventIsStatus = () => {
        let pastFuture = null
        let date
        if (this.props[0] && this.props[0].location && this.props[0].location.state && this.props[0].location.state.scheduledDate) {
            date = new Date(this.props[0].location.state.scheduledDate)
            let dateNow = new Date()
            if (date.getMonth() >= dateNow.getMonth() && date.getDate() > dateNow.getDate()) {
                pastFuture = 'future'
            } else if (date.getMonth() <= dateNow.getMonth() && date.getDate() < dateNow.getDate()) {
                pastFuture = 'past'
            }
        }

        return pastFuture
    }

    render() {
        const { passwordVisibility, coppiedInv } = this.state;

        let checkedDays = [];
        let checkedDaysString = '';
        if (this.state.form && this.state.form.recurringObj && this.state.form.recurringObj.recurrence == 'Weekly') {
            if (this.state.form.recurringObj.checkMon) { checkedDays.push('Mon'.translate(this.props.lang)) }
            if (this.state.form.recurringObj.checkTue) { checkedDays.push('Tue'.translate(this.props.lang)) }
            if (this.state.form.recurringObj.checkWed) { checkedDays.push('Wed'.translate(this.props.lang)) }
            if (this.state.form.recurringObj.checkThu) { checkedDays.push('Thu'.translate(this.props.lang)) }
            if (this.state.form.recurringObj.checkFri) { checkedDays.push('Fri'.translate(this.props.lang)) }
            if (this.state.form.recurringObj.checkSat) { checkedDays.push('Sat'.translate(this.props.lang)) }
            if (this.state.form.recurringObj.checkSun) { checkedDays.push('Sun'.translate(this.props.lang)) }
            if (checkedDays && checkedDays.length) {
                checkedDaysString = checkedDays.join(', ')
            }
        }
        let frequencyMonthScheduled = '1st';
        if (this.state.form.recurringObj && this.state.form.recurringObj.frequencyMonthScheduled) {
            if (this.state.form.recurringObj.frequencyMonthScheduled == '1') { frequencyMonthScheduled = '1st'.translate(this.props.lang) }
            if (this.state.form.recurringObj.frequencyMonthScheduled == '2') { frequencyMonthScheduled = '2nd'.translate(this.props.lang) }
            if (this.state.form.recurringObj.frequencyMonthScheduled == '3') { frequencyMonthScheduled = '3rd'.translate(this.props.lang) }
            if (this.state.form.recurringObj.frequencyMonthScheduled == '4') { frequencyMonthScheduled = '4th'.translate(this.props.lang) }
            if (this.state.form.recurringObj.frequencyMonthScheduled == '5') { frequencyMonthScheduled = '5th'.translate(this.props.lang) }
            if (this.state.form.recurringObj.frequencyMonthScheduled == '6') { frequencyMonthScheduled = '6th'.translate(this.props.lang) }
            if (this.state.form.recurringObj.frequencyMonthScheduled == '7') { frequencyMonthScheduled = '7th'.translate(this.props.lang) }
            if (this.state.form.recurringObj.frequencyMonthScheduled == '8') { frequencyMonthScheduled = '8th'.translate(this.props.lang) }
            if (this.state.form.recurringObj.frequencyMonthScheduled == '9') { frequencyMonthScheduled = '9th'.translate(this.props.lang) }
            if (this.state.form.recurringObj.frequencyMonthScheduled == '10') { frequencyMonthScheduled = '10th'.translate(this.props.lang) }
            if (this.state.form.recurringObj.frequencyMonthScheduled == '11') { frequencyMonthScheduled = '11th'.translate(this.props.lang) }
            if (this.state.form.recurringObj.frequencyMonthScheduled == '12') { frequencyMonthScheduled = '12th'.translate(this.props.lang) }
            if (this.state.form.recurringObj.frequencyMonthScheduled == '13') { frequencyMonthScheduled = '13th'.translate(this.props.lang) }
            if (this.state.form.recurringObj.frequencyMonthScheduled == '14') { frequencyMonthScheduled = '14th'.translate(this.props.lang) }
            if (this.state.form.recurringObj.frequencyMonthScheduled == '15') { frequencyMonthScheduled = '15th'.translate(this.props.lang) }
            if (this.state.form.recurringObj.frequencyMonthScheduled == '16') { frequencyMonthScheduled = '16th'.translate(this.props.lang) }
            if (this.state.form.recurringObj.frequencyMonthScheduled == '17') { frequencyMonthScheduled = '17th'.translate(this.props.lang) }
            if (this.state.form.recurringObj.frequencyMonthScheduled == '18') { frequencyMonthScheduled = '18th'.translate(this.props.lang) }
            if (this.state.form.recurringObj.frequencyMonthScheduled == '19') { frequencyMonthScheduled = '19th'.translate(this.props.lang) }
            if (this.state.form.recurringObj.frequencyMonthScheduled == '20') { frequencyMonthScheduled = '20th'.translate(this.props.lang) }
            if (this.state.form.recurringObj.frequencyMonthScheduled == '21') { frequencyMonthScheduled = '21st'.translate(this.props.lang) }
            if (this.state.form.recurringObj.frequencyMonthScheduled == '22') { frequencyMonthScheduled = '22nd'.translate(this.props.lang) }
            if (this.state.form.recurringObj.frequencyMonthScheduled == '23') { frequencyMonthScheduled = '23rd'.translate(this.props.lang) }
            if (this.state.form.recurringObj.frequencyMonthScheduled == '24') { frequencyMonthScheduled = '24th'.translate(this.props.lang) }
            if (this.state.form.recurringObj.frequencyMonthScheduled == '25') { frequencyMonthScheduled = '25th'.translate(this.props.lang) }
            if (this.state.form.recurringObj.frequencyMonthScheduled == '26') { frequencyMonthScheduled = '26th'.translate(this.props.lang) }
            if (this.state.form.recurringObj.frequencyMonthScheduled == '27') { frequencyMonthScheduled = '27th'.translate(this.props.lang) }
            if (this.state.form.recurringObj.frequencyMonthScheduled == '28') { frequencyMonthScheduled = '28th'.translate(this.props.lang) }
            if (this.state.form.recurringObj.frequencyMonthScheduled == '29') { frequencyMonthScheduled = '29th'.translate(this.props.lang) }
            if (this.state.form.recurringObj.frequencyMonthScheduled == '30') { frequencyMonthScheduled = '30th'.translate(this.props.lang) }
            if (this.state.form.recurringObj.frequencyMonthScheduled == '31') { frequencyMonthScheduled = '31st'.translate(this.props.lang) }
        }


        let recurringLabel = '-';
        let recurringLabelFirstPart =
            this.state.form.recurringObj && this.state.form.recurringObj.recurrence == 'Daily' ?
                this.state.form.recurringObj.repeat && Number(this.state.form.recurringObj.repeat) == 1 ?
                    'Every day'.translate(this.props.lang)
                    :
                    this.state.form.recurringObj.repeat && Number(this.state.form.recurringObj.repeat) > 1 ?
                        ('Every'.translate(this.props.lang) + ` ${this.state.form.recurringObj.repeat} ` + 'days'.translate(this.props.lang))
                        :
                        ''
                :
                this.state.form.recurringObj && this.state.form.recurringObj.recurrence == 'Weekly' ?
                    ('Every'.translate(this.props.lang) + ' ' + checkedDaysString)
                    :
                    this.state.form.recurringObj && this.state.form.recurringObj.recurrence == 'Monthly' && this.state.form.recurringObj.frequencyMonthScheduled ?
                        ('Every'.translate(this.props.lang) + ` ${frequencyMonthScheduled} ` + 'of the month'.translate(this.props.lang))
                        :
                        ''

        let recurringLabelSecondPart =
            this.state.form.dateBy && this.state.form.scheduleEndDate ?
                ('until'.translate(this.props.lang) + ' ' + this.state.form.scheduleEndDate)
                :
                this.state.form.dateAfter && this.state.form.scheduleDateAfter ?
                    ('after'.translate(this.props.lang) + ` ${this.state.form.scheduleDateAfter} ` + 'occurrence(s)'.translate(this.props.lang))
                    :
                    '';


        if (recurringLabelFirstPart && recurringLabelSecondPart) {
            recurringLabel = recurringLabelFirstPart + ', ' + recurringLabelSecondPart;
        } else if (recurringLabelFirstPart && !recurringLabelSecondPart) {
            recurringLabel = recurringLabelFirstPart;
        }
        // console.log(this.state.form);
        return (

            <div className="dashboard">
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className='panel schedule-meeting-page manage-meeting-page'>
                                <Row>
                                    <Col lg="12">
                                        <div className='manage-meeting-header'>
                                            <div className='manage-meeting-header-content'>
                                                <h2>{'Manage my Meeting'.translate(this.props.lang)}</h2>
                                                <ul>
                                                    <li>
                                                        <a href='/data-managment/meetings'>{'My meetings'.translate(this.props.lang)}</a>
                                                    </li>
                                                    <li>
                                                        <a>{'Manage'.translate(this.props.lang)} {`${this.state.form.name}`}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className='menage-meeting-tooltip-wrap'>
                                            <p>{this.state.form.cohosts && this.state.form.cohosts.indexOf(this.props.uData && this.props.uData._id) !== -1 ? ' ' + 'You are co-hosting this meeting.'.translate(this.props.lang) : ''}</p>
                                                <button disabled={this.checkIfEventIsActive()} style={this.checkIfEventIsActive() ? { backgroundColor: '#bcc3cf' } : {}} onClick={() => {
                                                    var strWindowFeatures = `height=${window.innerHeight - 1},width=${window.innerWidth - 1},resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes`;
                                                    window.open(`/video-call/${this.state.form._id}`, "_blank", strWindowFeatures);
                                                }}>{'Start this Meeting'.translate(this.props.lang)}  </button>
                                                {this.checkIfEventIsStatus() && this.checkIfEventIsActive() ? <div className="menage-meeting-tooltip">{this.checkIfEventIsStatus() === 'past' ? 'This event has expired'.translate(this.props.lang) : this.checkIfEventIsStatus() === 'future' ? 'This event is not scheduled to start yet'.translate(this.props.lang) : null}</div> : null}
                                            </div>

                                        </div>
                                    </Col>
                                    <Col lg="12">
                                        <div >
                                            <div className='sm-row'>
                                                <Label>{'Topic'.translate(this.props.lang)}</Label>
                                                <h6>{this.state.form.name}</h6>

        
                                            </div>
                                            <div className='sm-row' style={{ alignItems: 'flex-start' }}>
                                                <Label>{'Time'.translate(this.props.lang)}</Label>
                                                <div className='time-details'>
                                                    {this.state.form && this.state.form.scheduledTime && this.state.form.dateType ?
                                                        <h6>{this.state.form.scheduledDate + " " + convertFrom24To12Format(this.state.form.scheduledTime) + " " + this.state.form.dateType}</h6>
                                                        : this.state.form && this.state.form.scheduledTime ?
                                                            <h6>{this.state.form.scheduledDate + " " + this.state.form.scheduledTime}</h6>
                                                            :
                                                            null
                                                    }
                                                    <h6>
                                                        {recurringLabel}
                                                        {/* {this.state.form.dateBy ? (this.state.form && this.state.form.recurringObj && this.state.form.recurringObj.recurrence == 'Daily' ?
                                                        recurringLabel : this.state.form && this.state.form.recurringObj && this.state.form.recurringObj.recurrence == 'Weekly' ?
                                                        recurringLabel : this.state.form && this.state.form.recurringObj && this.state.form.recurringObj.recurrence == 'Monthly' ?
                                                        recurringLabel : '') + " " : ''} {this.state.form && this.state.form.dateBy && this.state.form.scheduleEndDate ? this.state.form.scheduleEndDate : ``} {this.state.form.dateAfter ? this.state.form.scheduleDateAfter + ' occurrence(s)'.translate(this.props.lang) : ''}  */}
                                                    </h6>
                                                    <div className='time-details-container'>
                                                        <h6>{'Add to'.translate(this.props.lang)}</h6>
                                                        {/* <button className='google-button' onClick={() => this.addEventGmail()}>
                                                            <span>
                                                                <img src={googleCalendarIcon} />
                                                                <span class="icon"></span>

                                                            </span>Google
                                                        </button> */}

                                                        <div id="customBtn" className="customBtn" onClick={() => {
                                                            if (this.state.form.eventGmail) {
                                                                this.getGmailEvents(this.state.form, (events) => {
                                                                    this.updateGmailEvent(this.state.form, events);
                                                                })
                                                            } else {
                                                                this.addEventGmail()
                                                            }
                                                        }}>
                                                            <span className="icon"></span>
                                                            <span className="buttonText">Google Calendar</span>
                                                        </div>
                                                        <button onClick={() => this.connectWithOutlook(this.state.form)}>
                                                            <span>
                                                                <Isvg src={outlookCalendarIcon} />
                                                            </span>Outlook Calendar(.ics)
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='sm-row'>
                                                <Label>{'Meeting ID'.translate(this.props.lang)}</Label>
                                                <h6>{this.state.form.conversationID}</h6>
                                            </div>

                                            <div className='sm-row sm-row-passcode passcode-manage'>
                                                <Label>{'Passcode'.translate(this.props.lang)}</Label>
                                                {this.state.form.passcodeCheckbox || (this.state.form.passcode && this.state.form.passcode.length) ? <Isvg className='uncheck-icon' src={checkIcon} /> : null}
                                                {this.state.form.passcodeCheckbox || (this.state.form.passcode && this.state.form.passcode.length) ?
                                                    <div className='passcode-input'>
                                                        <Input type={passwordVisibility ? 'password' : 'text'} value={this.state.form.passcode} />
                                                        <Isvg src={eyeIcon} onClick={() => this.togglePasswordVisibility()} />
                                                    </div>
                                                    :
                                                    <Isvg className='uncheck-icon' src={this.state.form.passcodeCheckbox || (this.state.form.passcode && this.state.form.passcode.length) ? checkIcon : uncheckIcon} />
                                                }
                                            </div>

                                            <div className='sm-row passcode-invite'>
                                                <Label>{'Invite link'.translate(this.props.lang)}</Label>
                                                <div className="tabel-action-tooltip-meeting action-inv-link">
                                                    <h6>{this.state.form.smallLink ? `${window.location.origin}/${this.state.form.smallLink}` : ''}</h6>
                                                    {/* <div className="chat-icons-tooltip">
                                                        <h6>{this.state.form.longinviteLink ? (this.state.form.longinviteLink) : this.state.form.inviteLink ? this.state.form.inviteLink : ''}</h6>
                                                    </div> */}
                                                </div>
                                                <button className='copy-link-button' onClick={() => this.copyLink()}>
                                                    <span><Isvg src={copyIcon} /></span>
                                                    <div className='flex'>{this.state.linkCopied ? 'Copied to clipboard'.translate(this.props.lang) : 'Copy link'.translate(this.props.lang)}</div>
                                                    {coppiedInv ? <span className='coppied'>{'Coppied'.translate(this.props.lang)}</span> : null}
                                                </button>

                                                <button className='copy-link-button' onClick={() => this.copyInvite()}>
                                                    <span><Isvg src={copyIcon} /></span>
                                                    <div className='flex'>{this.state.linkCopied ? 'Copied to clipboard'.translate(this.props.lang) : 'Copy invitation'.translate(this.props.lang)}</div>
                                                    {coppiedInv ? <span className='coppied'>{'Coppied'.translate(this.props.lang)}</span> : null}
                                                </button>
                                            </div>
                                            <div className='sm-row sm-row-invite-p'>
                                                <Label>{'Invited participants'.translate(this.props.lang)}</Label>
                                                {this.state.form.invitedUsers ?
                                                    <button onClick={() => this.setState({
                                                        // openPreviewInvite: true,
                                                        previewInvite: this.state.form.invitedUsers
                                                    })}>{'Open participant list'.translate(this.props.lang)}</button>

                                                    : null}

                                            </div>
                                            <div className='sm-row sm-row-invite-p'>
                                                <Label>{'Invited co-hosts'.translate(this.props.lang)}</Label>
                                                {this.state.form.namesCohosts ?
                                                    <button onClick={() => this.setState({
                                                        // openPreviewInvite: true,
                                                        previewCohostsInvite: this.state.form.namesCohosts
                                                    })}>{'Open list'.translate(this.props.lang)}</button>

                                                    : null}

                                            </div>
                                            
                                            <div className='sm-row'>
                                                <Label>{'Bank ID required'.translate(this.props.lang)}</Label>
                                                <Isvg src={this.state.form.requireBankID ? checkIcon : uncheckIcon} className='uncheck-icon' />
                                            </div>
                                            <div className='sm-row'>
                                                <Label>{'SITHS required'.translate(this.props.lang)}</Label>
                                                <Isvg src={this.state.form.requireNetID ? checkIcon : uncheckIcon} className='uncheck-icon' />
                                            </div>
                                            <div className='sm-row'>
                                                <Label>{'Mandatory screen name'.translate(this.props.lang)}</Label>
                                                <Isvg src={this.state.form.mandatoryScreenName ? checkIcon : uncheckIcon} className='uncheck-icon' />
                                            </div>
                                            <div className='sm-row'>
                                                <Label>{'Mute participants upon entry'.translate(this.props.lang)}</Label>
                                                <Isvg className='uncheck-icon' src={this.state.form.muteParticipants ? checkIcon : uncheckIcon} />
                                            </div>
                                            <div className='sm-row'>
                                                <Label>{'Record video call on your local computer'.translate(this.props.lang)}</Label>
                                                <Isvg className='uncheck-icon' src={this.state.form.recordConversationAsVideo ? checkIcon : uncheckIcon} />
                                            </div>
                                            <div className='sm-row'>
                                                <Label>{'Allow participants to record video call on their local computers'.translate(this.props.lang)}</Label>
                                                <Isvg className='uncheck-icon' src={this.state.form.recordConversationAsVideoMembers ? checkIcon : uncheckIcon} />
                                            </div>
                                            <div className='buy-button'>

                                                <button onClick={() => {
                                                    this.state.form && this.state.form.location == 'schedule-meetings' ?
                                                        this.props[0].history.push({
                                                            pathname: '/data-managment/meetings',
                                                            state: { form: this.state.form }
                                                        })
                                                        :
                                                        this.props[0].history.push('/data-managment/meetings')
                                                }}>{this.state.form && this.state.form.location == 'schedule-meetings' ? 'Go to calendar'.translate(this.props.lang) : 'Back to Calendar'.translate(this.props.lang)}</button>

                                                {/* <button disabled={this.checkIfEventIsActive(true)} style={this.checkIfEventIsActive(true) ? { backgroundColor: '#bcc3cf' } : {}} onClick={() => {
                                                    this.props[0].history.push({
                                                        pathname: '/users/schedule-meeting',
                                                        state: { form: this.state.form, cancel: true }
                                                    })
                                                }}>{'Edit'.translate(this.props.lang)}</button> */}

                                                <div className='menage-meeting-tooltip-wrap-bottom'>
                                                    <button disabled={this.checkIfEventIsActive(true)} style={this.checkIfEventIsActive(true) ? { backgroundColor: '#bcc3cf' } : {}} onClick={() => {
                                                        this.props[0].history.push({
                                                            pathname: '/users/schedule-meeting',
                                                            state: { form: this.state.form, cancel: true }
                                                        })
                                                    }}>{'Edit'.translate(this.props.lang)}</button>
                                                    {this.checkIfEventIsActive(true) ? <div className="menage-meeting-tooltip-bottom">{`This event has expired and can't be edited`.translate(this.props.lang)}</div> : null}
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>

                </Container>
                {this.state.previewInvite ?
                    <Modal isOpen={this.state.previewInvite} centered size='md' >

                        <ModalHeader style={{ margin: 'auto' }} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ previewInvite: !this.state.previewInvite })}><Isvg src={xIcon} /></button>}>
                            {"Preview invitatios".translate(this.props.lang)}
                        </ModalHeader>
                        <ModalBody>

                            <div className='preview-invitations'>
                                <div className='preview-invitations-container'>
                                    {this.state.previewInvite && this.state.previewInvite.map((item, idx) => {
                                        return (
                                            <div className='preview-list'>
                                                <p>{item}</p>
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                            </div>

                        </ModalBody>
                    </Modal>
                    :
                    null
                }
                  {this.state.previewCohostsInvite ?
                    <Modal isOpen={this.state.previewCohostsInvite} centered size='md' >

                        <ModalHeader style={{ margin: 'auto' }} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ previewCohostsInvite: !this.state.previewCohostsInvite })}><Isvg src={xIcon} /></button>}>
                            {"Preview invitatios".translate(this.props.lang)}
                        </ModalHeader>
                        <ModalBody>

                            <div className='preview-invitations'>
                                <div className='preview-invitations-container'>
                                    {this.state.previewCohostsInvite && this.state.previewCohostsInvite.map((item, idx) => {
                                        return (
                                            <div className='preview-list'>
                                                <p>{item}</p>
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                            </div>

                        </ModalBody>
                    </Modal>
                    :
                    null
                }
                {
                    this.state.inviteModal ?
                        <Modal isOpen={this.state.inviteModal} size="lg" centered>
                            <ModalHeader close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ inviteModal: !this.state.inviteModal })}>&times;</button>}>{'Copy Meeting Invitation'.translate(this.props.lang)}</ModalHeader>
                            <ModalBody>
                                <FormGroup>
                                    <Input type="textarea" style={{ height: '350px' }} value={'\n' + 'Topic: ' + this.state.inviteModal.name + '\n' + 'Time: ' + `${this.state.inviteModal.scheduledDate} ${this.state.inviteModal.scheduledTime} ${this.state.inviteModal.dateType}` + '\n\n' + 'Invite link: ' + '\n' + `${this.state.inviteModal.smallLink ? `${window.location.origin}/${this.state.inviteModal.smallLink}` : this.state.inviteModal.inviteLink}` + '\n\n' + 'Meeting ID: ' + this.state.inviteModal.conversationID + '\n' + `${this.state.inviteModal.passcode ? 'Passcode: ' + this.state.inviteModal.passcode : ''} `} onChange={(e) => { }} />
                                </FormGroup>
                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>

                                <Button onClick={() => {
                                    let value = '\n' + 'Topic: ' + this.state.inviteModal.name + '\n' + 'Time: ' + `${this.state.inviteModal.scheduledDate} ${this.state.inviteModal.scheduledTime} ${this.state.inviteModal.dateType}` + '\n\n' + 'Invite link: ' + '\n' + `${this.state.inviteModal.smallLink ? `${window.location.origin}/${this.state.inviteModal.smallLink}` : this.state.inviteModal.inviteLink}` + '\n\n' + 'Meeting ID: ' + this.state.inviteModal.conversationID + '\n' + `${this.state.inviteModal.passcode ? 'Passcode: ' + this.state.inviteModal.passcode : ''}`
                                    navigator.clipboard.writeText(value);
                                    this.setState({
                                        inviteModal: !this.state.inviteModal
                                    })
                                }} >{'Copy invitation'.translate(this.props.lang)}</Button>

                                <Button onClick={() => this.setState({ inviteModal: !this.state.inviteModal })}>{'Close'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        :
                        null
                }
            </div>
        );
    }
}



export default Page(ManageMeetting);