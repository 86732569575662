import React, { Component } from "react";
import Link from "../components/link";

import Isvg from "react-inlinesvg";
import Page from "../containers/page";

import { Player, Controls } from "@lottiefiles/react-lottie-player";
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import FormBuilder from "../components/forms/formBuilder";
import { required, phoneNumber } from "../components/forms/validation";
import { API_ENDPOINT } from "../constants";

import logoLogin from '../assets/svg/logo_video.svg';
import InfoModal from "../components/infoModal";
import logoDark from "../assets/images/logo-dark.png";
import shapeBlue from "../assets/svg/shape-blue.svg";
import shapeGreen from "../assets/svg/shape-green.svg";
import shapeYellow from "../assets/images/shape-yellow.png";
import bankIdIcon from "../assets/svg/bankid-vector-logo.svg";
import BankIDModal_v5 from "../components/bankIDModal_v5";



/**
* Login page
* @author   Milan Stanojevic
*/
class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      promotedProjects: [],
      loginBankIdModal: true,
      ...props.initialData,
      loginForm: true,
      moreOptions: true
    };
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }

    for (let i = 0; i < this.props.loadData.length; i++) {
      this.props.loadData[i](
        window.fetch,
        this.props.lang,
        this.props[0].match
      ).then((data) => {
        this.setState(
          {
            ...data,
          },
          () => {
            this.props.updateMeta(
              this.props.generateSeoTags(this.state, this.props.lang)
            );
          }
        );
      });
    }

    // fetch(API_ENDPOINT + `/users/bankid/v2/start/${this.props.lang == 'en' ? this.props.lang : 'sv'}`, {
    //   method: "GET",
    //   headers: {
    //     "content-type": "application/json",
    //   },
    // })
    //   .then((res) => res.json())
    //   .then((result) => {

    //     if (result.redirect_url) {
    //       this.setState({
    //         bankIdRedirectUrl: result.redirect_url
    //       })
    //     }
    //   });
    window.addEventListener('message',
      (e) => {

        if (e.data == 'loggedin') {

          this.props.verifyUser(() => {
            // localStorage.setItem('checkHost', true)
            this.props[0].history.push('/my-data/account');

          }, () => {



          });

        } else if (e.data == 'loggedin_newuser') {


          this.props.verifyUser(() => {
            // localStorage.setItem('checkHost', true)
            this.props[0].history.push('/my-data/account');
            // console.log(this.props.uData)
          }, () => {





          });
        } else if (e.data == 'loggedin_error') {
          this.setState({
            errorModal: "BankID login works only for existing accounts with connected BankID. In order to use BankID login, you must first create an account and connect it to your BankID."
          }, () => {
            console.log(this.state.errorModal);
          })
        }
      }

      , false)
  }

  /**
  * Login function
  * @author   Milan Stanojevic
  */
  login = (data) => {
    // if(this.props[0].location && this.props[0].location.search){
    //   data.group = this.props[0].location.search.split('=')[1]
    // }

    if (localStorage.allowCookiesLogin) {

      fetch(API_ENDPOINT + "/users/login", {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.error) {
            if (result.error.text) {
              this.setState({
                error: result.error.text
              })
            } else {
              this.setState({
                error: result.error
              })
            }
          } else {
            localStorage.setItem("authToken", result.token);
            localStorage.setItem("profile", "doctor");
            localStorage.setItem("time", Math.floor(Date.now() / 1000));
            localStorage.setItem('loginMethod', 'code');
            this.props.registerLog("Logged in");
            this.props.verifyUser(() => {

              if (typeof window != 'undefined' && (localStorage.getItem('groupAlias') == null || localStorage.getItem('groupAlias') == 'undefined')) {
                if (this.props.uData && this.props.uData.userLevel && this.props.uData.userLevel == 20 && this.props.uData.clinicGroups && this.props.uData.clinicGroups.length) {
                  localStorage.setItem('groupAlias', this.props.uData.clinicGroups[0].alias)
                }
              }
              this.props[0].history.push("/my-data/account");
            });



          }
        });

    } else {
      this.props.redLinePopUp()
    }

  };


  registration = (data) => {
    if (this.props.lang) {
      data.lang = this.props.lang
    } else {
      data.lang = 'se'
    }
    fetch(API_ENDPOINT + "/users/registration", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    }).then(res => res.json()).then((result) => {
      if (result.error) {
        this.setState({ error: result.error }, () => {
          // setTimeout(() => {
          //   this.setState({ error: null })
          // }, 3000)
        })
      } else {
        this.setState({

          loginForm: true,
          registrationForm: false,
          registrationSuccessful: true
        })
      }
    })
  }


  componentDidUpdate(prevProps, prevState) {
    if (this.props.uData) {
      this.props[0].history.push("/");
    }

    if ((this.props.username && this.props.username != prevProps.username) || (this.props.password && this.props.password != prevProps.password) || (this.state.moreOptions && this.state.moreOptions != prevState.moreOptions) || (this.state.registrationForm && this.state.registrationForm != prevState.registrationForm)) {
      this.setState({
        error: null
      })
    }
  }

  render() {
    return (
      <div className="login-main-wrap">
        <div className="login-wrapper">
          {!this.props.userVerificationInProgress ? (
            <>
              <Isvg src={shapeBlue} className="shape-blue" />
              <Isvg src={shapeGreen} className="shape-green" />
              <img src={shapeYellow} className="shape-yellow" />
            </>
          ) : null}

          <div className="log-in">
            <Container fluid>
              <Row>
                <Col lg={{ size: 12 }}>
                  {this.props.userVerificationInProgress ? (
                    <div className="loader-wrap">
                      <Player
                        autoplay={true}
                        loop={true}
                        src="/lf30_editor_l5cxzdyf.json"
                        style={{
                          height:
                            typeof window != "undefined" &&
                              window.innerWidth < 768
                              ? "128px"
                              : "256px",
                          width:
                            typeof window != "undefined" &&
                              window.innerWidth < 768
                              ? "128px"
                              : "256px",
                        }}
                      ></Player>
                    </div>
                  ) : (
                    <div className="panel login-in-panel" style={{
                      minWidth: typeof window != "undefined" &&
                        window.innerWidth < 768
                        ? null
                        : "738px",
                    }}>
                      <div className="login-container">
                        <div className="logo-header">
                          <Link to="/">
                            <Isvg src={logoLogin} />
                          </Link>
                        </div>
                        <h4>{this.state.registrationForm ? "Registration".translate(this.props.lang) : "Login".translate(this.props.lang)} </h4>

                        {
                          !this.state.moreOptions && this.state.loginForm ?
                            this.state.bankIdRedirectUrl === null ?
                              <div className="loader-wrap">
                                {/* <div className="loader" /> */}
                                <Player
                                  autoplay={true}
                                  loop={true}
                                  src="/lf30_editor_l5cxzdyf.json"
                                  style={{
                                    height:
                                      typeof window != "undefined" &&
                                        window.innerWidth < 768
                                        ? "128px"
                                        : "256px",
                                    width:
                                      typeof window != "undefined" &&
                                        window.innerWidth < 768
                                        ? "128px"
                                        : "256px",
                                  }}
                                ></Player>
                              </div>
                              :
                              <>
                                {
                                  // !this.state._iframeLoaded ?


                                  //   <div className="loader-wrap" style={{ height: 360 }}>
                                  //     {/* <div className="loader" /> */}
                                  //     <Player
                                  //       autoplay={true}
                                  //       loop={true}
                                  //       src="/lf30_editor_l5cxzdyf.json"
                                  //       style={{
                                  //         height:
                                  //           typeof window != "undefined" &&
                                  //             window.innerWidth < 768
                                  //             ? "128px"
                                  //             : "256px",
                                  //         width:
                                  //           typeof window != "undefined" &&
                                  //             window.innerWidth < 768
                                  //             ? "128px"
                                  //             : "256px",
                                  //       }}
                                  //     ></Player>
                                  //   </div>
                                  //   :
                                  //   null
                                }

                                {/* <iframe ref={(node) => this.bankIdIframe = node} onLoad={() => {
                                  try {
                                    if (this.bankIdIframe && this.bankIdIframe.contentWindow && this.bankIdIframe.contentWindow.location && this.bankIdIframe.contentWindow.location.href && this.bankIdIframe.contentWindow.location.href.indexOf('/bankid/v2') !== -1) {
                                      //alert(true)
                                      this.setState({
                                        _hideIframe: true
                                      })
                                    }
                                  } catch (e) { }
                                  this.setState({ _iframeLoaded: true })
                                }} src={this.state.bankIdRedirectUrl} style={this.state._hideIframe ? { opacity: 0 } : { width: '100%', maxWidth: '450px', height: this.state._iframeLoaded ? 360 : 0, border: 0 }} /> */}
                                <BankIDModal_v5 {...this.props}  errorModal={(data) => {
                                  this.setState({
                                    errorModal: data,
                                    moreOptions: !this.state.moreOptions
                                  })
                                }}/>
                                <p style={{paddingBottom: 20}}></p>
                              </>
                            :
                            null

                        }
                        {this.state.loginForm ?
                          <div className={this.state.moreOptions ? "login-more-options-wrap-show" : "login-more-options-wrap-hidden"}>
                            <div className="login-form">
                              <FormBuilder
                                dateFormat={this.props.dateFormat}
                                lang={this.props.lang}
                                onSubmit={(data) => this.login(data)}
                                buttonText={"Log in".translate(this.props.lang)}
                                fields={[
                                  {
                                    type: "row",
                                    children: [
                                      {
                                        type: "col",
                                        width: {
                                          lg: 12,
                                          sm: 12,
                                          xs: 12,
                                        },
                                        children: [
                                          {
                                            type: "text",
                                            name: "username",
                                            label: "E-mail".translate(
                                              this.props.lang
                                            ),
                                            validate: [
                                              required(
                                                "E-mail is required!".translate(
                                                  this.props.lang
                                                )
                                              ),
                                            ],
                                          },
                                        ],
                                      },
                                      {
                                        type: "col",
                                        width: {
                                          lg: 12,
                                          sm: 12,
                                          xs: 12,
                                        },
                                        children: [
                                          {
                                            type: "password",
                                            name: "password",
                                            label: "Password".translate(
                                              this.props.lang
                                            ),
                                            validate: [
                                              required(
                                                "Password is required!".translate(
                                                  this.props.lang
                                                )
                                              ),
                                            ],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                  {
                                    type: "row",
                                    children: [
                                      {
                                        type: "col",
                                        width: {
                                          lg: 6,
                                          sm: 6,
                                          xs: 6,
                                        },
                                        children: [
                                          {
                                            type: "hidden",
                                            name: "newAccount",
                                            afterText: (
                                              <Link
                                                to="/registration"
                                                className="forget"
                                                style={{ float: 'left' }}
                                              >
                                                {"Create new account".translate(
                                                  this.props.lang
                                                )}
                                              </Link>
                                            ),
                                          }
                                        ],
                                      },
                                      {
                                        type: "col",
                                        width: {
                                          lg: 6,
                                          sm: 6,
                                          xs: 6,
                                        },
                                        children: [
                                          {
                                            type: "hidden",
                                            name: "forget",
                                            afterText: (
                                              <a
                                                href={`/forgotpassword/?lang=${this.props.lang}`}
                                                className="forget"
                                              >
                                                {"Forgot password?".translate(
                                                  this.props.lang
                                                )}
                                              </a>
                                            ),
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ]}
                              ></FormBuilder>
                            </div>
                            <div className="login-divider">
                              <hr /> <span>{"or".translate(this.props.lang)}</span>{" "}
                              <hr />
                            </div>

                          </div>

                          : null
                        }

                        {this.state.loginForm ?
                          this.state.moreOptions ?
                            <div>
                              <div className="login-more-options" onClick={() => { if (localStorage.allowCookiesLogin) this.setState({ moreOptions: !this.state.moreOptions }) }}>
                                {'Login using BankID'.translate(this.props.lang)}
                              </div>
                              <div className="login-more-options" onClick={() => {
                                if (localStorage.allowCookiesLogin) {
                                  fetch(API_ENDPOINT + "/users/netid/start", {
                                    method: "GET",
                                    headers: {
                                      "content-type": "application/json",
                                      // Authorization: typeof localStorage !== "undefined" ? `Bearer ${localStorage.getItem("authToken")}` : null,
                                    },
                                  }).then((res) => res.json()).then((result) => {
                                    if (result.redirectUrl) {
                                      window.location.replace(
                                        result.redirectUrl
                                      );
                                    }
                                  });
                                }
                              }}>
                                {'Login using SITHS'.translate(this.props.lang)}
                              </div>
                            </div>

                            :
                            <div className="login-more-options" onClick={() => this.setState({ moreOptions: !this.state.moreOptions })}>
                              {'Login using email and password'.translate(this.props.lang)}
                            </div>
                          : null
                        }
                        {
                          this.state.loginForm && this.state.moreOptions ?
                            <p style={{ fontSize: 12 }}>{"BankID or SITHS login works only for existing accounts with connected BankID or SITHS.".translate(this.props.lang)}</p>
                            :
                            null
                        }

                        {this.state.error ? (
                          <p style={{ color: 'red' }} className="error-message">
                            {this.state.error.translate(this.props.lang)}
                          </p>
                        ) : null}

                      </div>


                    </div>
                  )}
                </Col>
              </Row>

            </Container>
          </div>
        </div>
        {
          this.state.errorModal ?
            <Modal isOpen={this.state.errorModal} centered size="lg">
              <ModalHeader>{'Error'.translate(this.props.lang)}</ModalHeader>
              <ModalBody>
                <div>
                  {this.state.errorModal.translate(this.props.lang)}
                </div>
              </ModalBody>
              <ModalFooter>
                <Button onClick={() => {
                  this.props[0].history.push('/registration')
                }}>{'Create account'.translate(this.props.lang)}</Button>
                <Button onClick={() => {
                  // this.setState({
                  //   errorModal: null,
                  //   loginForm: true,
                  //   moreOptions: true
                  // })
                  // this.props[0].history.push('/')
                  window.location.reload()

                }}>{'Cancel'.translate(this.props.lang)}</Button>
              </ModalFooter>
            </Modal>
            :
            null
        }
        {
          this.state.registrationSuccessful ?
            <InfoModal
              isOpen={this.state.registrationSuccessful}
              toggle={() => this.setState({ registrationSuccessful: !this.state.registrationSuccessful })}
              // image={Brev}
              header={'Info'.translate(this.props.lang)}
              info={'Check your email to complete registration and verify your account.'.translate(this.props.lang)}
              buttons={[
                <Button color='primary' onClick={() => {
                  this.setState({
                    registrationSuccessful: null
                  })
                }}>{'Ok'.translate(this.props.lang)}</Button>,
              ]}

            />
            :
            null
        }
      </div >
    );
  }
}

const selector = formValueSelector('form');

export default connect(state => {
  return {

    username: selector(state, 'username'),
    password: selector(state, 'password'),


  }


}, {

  // changeUsername: value => change("form", "username", value),
  // changePassword: value => change("form", "password", value),


})(Page(LoginPage));
