import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { formValueSelector, change, isDirty, submit } from 'redux-form';

import { connect } from 'react-redux';

import Isvg from 'react-inlinesvg';

import { FormGroup, Label, Input, FormFeedback, FormText } from 'reactstrap';


const ParsedNotif = require('../../../../parsed-replaced.json')
/**
* Generate name for sent sms messages
* @author   Milan Stanojevic
*/
class HighlightsText extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {

        if (this.props.value) {

            let params = '';

            params = this.props.value.match(/\<.*?\>.*?\<.*?\>|(https?:\/\/\{)[0-9]\}\/\{[0-9]\}|(https?:\/\/\{)[0-9]\}\/\{[0-9][0-9]\}|(https?:\/\/\{)[0-9][0-9]\}\/\{[0-9][0-9]\}/g)



            let message = this.props.value



            this.setState({
                message: message,
            })
        }

        let paramsArr = []
        for (let i = 0; i < ParsedNotif.length; i++) {
            if (ParsedNotif[i].type == this.props.type && ParsedNotif[i].key == this.props.keyNotif) {
                for (let j = 0; j < ParsedNotif[i].params.length; j++) {
                    
                    paramsArr.push(ParsedNotif[i].params[j].split('|')[1])

                }

            }
        }

        this.setState({
            paramsArr: paramsArr
        })
    }

    messageOut = () => {

        let re = /\{[0-9]\}|\{[0-9][0-9]\}/g

        let m

        if (this.state.message) {
            let preview = this.state.message
            let i = 0
            do {
                m = re.exec(this.state.message);
                
                if (m) {
                    if (m[0] == `{${i}}`) {
                        
                        let params = this.state.message.match(/\<.*?\>|(https?:\/\/\{)[0-9]\}\/\{[0-9]\}|(https?:\/\/\{)[0-9]\}\/\{[0-9][0-9]\}|(https?:\/\/\{)[0-9][0-9]\}\/\{[0-9][0-9]\}/g)
                        let check = false
                        if (params && params.length) {
                            
                            for (let j = 0; j < params.length; j++) {

                                if (!check && params[j].indexOf(`{${i}}`) != -1) {
                                    check = true
                                }

                            }

                            if (!check) {

                                let linkData = this.state.message.match(/\<.*?\>.*?\<.*?\>/g)
                                if (linkData && linkData.length) {
                                    let check2 = false
                                    for (let d = 0; d < linkData.length; d++) {
                                        let links = linkData[d].match(/\>.*?\</g)

                                        if (links && links.length) {
                                            if (links[0] == `>{${i}}<`) {
                                                check2 = true
                                            }

                                        }

                                    }
                                    if (check2) {
                                        preview = preview.replace(`{${i}}`, `${this.state.paramsArr[i]}`)
                                    } else {
                                        preview = preview.replace(`{${i}}`, `<span style="color: red;">${this.state.paramsArr[i]}</span>`)

                                    }

                                } else {
                                    preview = preview.replace(`{${i}}`, `<span style="color: red;">${this.state.paramsArr[i]}</span>`)

                                }

                            }
                        }else {
                            preview = preview.replace(`{${i}}`, `<span style="color: red;">${this.state.paramsArr[i]}</span>`)

                        }


                    }
                }

                i++
            } while (m)
            preview = preview.replaceAll(/\s/g, ' ')
            preview = preview.replaceAll(/(https?:\/\/\{)[0-9]\}\/\{[0-9]\}|(https?:\/\/\{)[0-9]\}\/\{[0-9][0-9]\}|(https?:\/\/\{)[0-9][0-9]\}\/\{[0-9][0-9]\}/g, '#')
            return preview
        }


    }

    render() {
        return (
            <FormGroup>
                {this.props.label ? <Label check={this.props.type == 'checkbox'} size="sm" className={this.props.error ? "required" : ""}>{this.props.label}{this.props.haveValidation ? <span className="required-label-span">*</span> : null}</Label> : null}
                <div className='container-text'>
                    <Input type='textarea' className='navi' disabled={this.props.disabled} name={this.props.name} invalid={this.props.error ? true : false} onChange={(e) => {
                        if (e.target.value != this.state.message && this.state.message.match(/\{[0-9]\}|\{[0-9][0-9]\}|\<.*?\>|(https?:\/\/\{)[0-9]\}\/\{[0-9]\}|(https?:\/\/\{)[0-9]\}\/\{[0-9][0-9]\}|(https?:\/\/\{)[0-9][0-9]\}\/\{[0-9][0-9]\}/g)) {
                            let arr = e.target.value.match(/\{[0-9]\}|\{[0-9][0-9]\}|\<.*?\>|(https?:\/\/\{)[0-9]\}\/\{[0-9]\}|(https?:\/\/\{)[0-9]\}\/\{[0-9][0-9]\}|(https?:\/\/\{)[0-9][0-9]\}\/\{[0-9][0-9]\}/g);
                            let check = this.state.message.match(/\{[0-9]\}|\{[0-9][0-9]\}|\<.*?\>|(https?:\/\/\{)[0-9]\}\/\{[0-9]\}|(https?:\/\/\{)[0-9]\}\/\{[0-9][0-9]\}|(https?:\/\/\{)[0-9][0-9]\}\/\{[0-9][0-9]\}/g)

                            let items = true
                            if (arr && check && arr.length && check.length && arr.length == check.length) {
                                for (let i = 0; i < arr.length; i++) {
                                    if (arr[i] != check[i]) {
                                        items = false
                                    }

                                }
                            }

                            if (arr && check && arr.length && check.length && arr.length == check.length && items) {

                                let value = e.target.value;
                                this.setState({
                                    message: value
                                }, () => {
                                    let message = this.state.message
                                    this.props.onChange(message)
                                })

                            } else {
                                let value = this.state.message
                                this.setState({
                                    message: value
                                }, () => {
                                    let message = this.state.message
                                    this.props.onChange(message)
                                })
                            }
                        } else {
                            let value = e.target.value;
                            this.setState({
                                message: value
                            }, () => {
                                let message = this.state.message
                                this.props.onChange(message)
                            })
                        }


                    }} value={this.state.message} placeholder={this.props.placeholder}></Input>

                    {typeof window != 'undefined' && window.innerWidth < 768 ?
                        <div className='infoi' contentEditable='true' dangerouslySetInnerHTML={{
                            __html: `<div style="
                        height: 250px;
                        background-color: #E6EDF5;
                        padding-left: 15px;
                        
                        padding-right: 15px;
                        padding-top: 5px;
                        font-size: 15px;
                        color: #3A3F56;
                        font-weight: 500;
                        border: none;
                        border-radius: 10px;">${this.state.message && this.state.message.replaceAll('<a', '&lt;a').replaceAll('</a', '&lt;/a').replaceAll(/\&.*?\a\>|(https?:\/\/\{)[0-9]\}\/\{[0-9]\}|(https?:\/\/\{)[0-9]\}\/\{[0-9][0-9]\}|(https?:\/\/\{)[0-9][0-9]\}\/\{[0-9][0-9]\}/g, (e) => {
                                return `<span style=\"color: blue;\">${e}</span>`
                            }).replace(/\{[0-9]\}|\{[0-9][0-9]\}/g, (e) => {
                                return `<span style=\"color: red;\">${e}</span>`
                            })}</div>`
                        }}></div>
                        :
                        <div className='infoi' contentEditable='true' dangerouslySetInnerHTML={{
                            __html: `<div style="
                        height: 100px;
                        background-color: #E6EDF5;
                        padding-left: 15px;
                        
                        padding-right: 15px;
                        padding-top: 5px;
                        font-size: 15px;
                        color: #3A3F56;
                        font-weight: 500;
                        border: none;
                        border-radius: 10px;">${this.state.message && this.state.message.replaceAll('<a', '&lt;a').replaceAll('</a', '&lt;/a').replaceAll(/\&.*?\a\>|(https?:\/\/\{)[0-9]\}\/\{[0-9]\}|(https?:\/\/\{)[0-9]\}\/\{[0-9][0-9]\}|(https?:\/\/\{)[0-9][0-9]\}\/\{[0-9][0-9]\}/g, (e) => {
                                return `<span style=\"color: blue;\">${e}</span>`
                            }).replace(/\{[0-9]\}|\{[0-9][0-9]\}/g, (e) => {
                                return `<span style=\"color: red;\">${e}</span>`
                            })}</div>`
                        }}></div>
                    }
                    {typeof window != 'undefined' && window.innerWidth < 768 ?
                        <div style={{ marginTop: 10 }}>
                            {'Preview:'.translate(this.props.lang)}
                            <div style={{ marginTop: 10 }} className='infoi' contentEditable='false' dangerouslySetInnerHTML={{
                                __html: `<div style="
                        
                        background-color: #E6EDF5;
                        padding-left: 15px;
                        word-break: break-all;
                        padding-right: 15px;
                        padding-top: 5px;
                        font-size: 15px;
                        color: #3A3F56;
                        font-weight: 500;
                        border: none;
                        border-radius: 10px;">${this.messageOut()}</div>`
                            }}></div>
                        </div>
                        :
                        <div style={{ marginTop: 10 }}>
                            {'Preview:'.translate(this.props.lang)}
                            <div style={{ marginTop: 10 }} className='infoi' contentEditable='false' dangerouslySetInnerHTML={{
                                __html: `<div style="
                        
                        background-color: #E6EDF5;
                        padding-left: 15px;
                        word-break: break-all;
                        padding-right: 15px;
                        padding-top: 5px;
                        font-size: 15px;
                        color: #3A3F56;
                        font-weight: 500;
                        border: none;
                        border-radius: 10px;">${this.messageOut()}</div>`
                            }}></div>
                        </div>
                    }
                </div>
            </FormGroup>
        );
    }
}
const selector = formValueSelector('modalForm');

export default connect(state => {
    return {
        type: selector(state, 'type'),
        keyNotif: selector(state, 'key'),

    }
}, {})(HighlightsText);