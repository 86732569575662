import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Sidebar from '../components/sidebar';
import Notification from '../components/notifications';
import PatientAccountModal from '../components/patientAccountModal';
import Header from '../components/header';
import Nav from '../components/nav';
import Footer from '../components/footer';

function iosDevice() {
    var userAgent = window.navigator.userAgent;
    if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
        return true;
    }
    else {
        return false;
    }
}


export const DefaultLayout = (Wrapped) => (props) => {
    // console.log(props[0].location.pathname)
    return (
        <div style={typeof window != 'undefined' && iosDevice() ? { maxWidth: `${window.innerWidth}px` } : {}} className={props.sidebarWide && !props[0].match.path != '/chat/:windowConversation' ? `home-content home-content-sidebar-wide ${props.uData ? 'logged-in' : ''}` : `home-content home-content-sidebar-short ${props.uData ? '' : 'not-logged-in'}`}>
            {props.uData && props[0].location.pathname != '/' && props[0].location.pathname != '/termsandconditions' && props[0].location.pathname != '/integritetspolicy-video-service' && props[0].location.pathname != '/integritetspolicy-website' && props[0].location.pathname != '/website/cookies' && props[0].location.pathname != '/contact' && props[0].location.pathname != '/gdpr-compilance-in-secure-video-meetings' && props[0].location.pathname != '/plans-for-secure-video-meetings' && props[0].location.pathname != '/integritetspolicy-webbsida' && props[0].location.pathname != '/en/privacy-policy-website' && props[0].location.pathname != '/integritetspolicy-videotjanst' && props[0].location.pathname !='/en/privacy-policy-video-service'  ? <Sidebar className="sidebar" {...props} /> : null}

            {
                props[0].location.pathname == '/' || props[0].location.pathname == '/contact' || props[0].location.pathname == '/gdpr-compilance-in-secure-video-meetings' || props[0].location.pathname == '/our-solution' || props[0].location.pathname == '/technical-requirements' || props[0].location.pathname == '/tekniska-krav' || props[0].location.pathname == '/about-us' || props[0].location.pathname == '/integritetspolicy-video-service' || props[0].location.pathname == '/cookies' || props[0].location.pathname == '/website/cookies' || props[0].location.pathname == '/termsandconditions' || props[0].location.pathname == '/plans-for-secure-video-meetings' || props[0].location.pathname == '/integritetspolicy-video-service-website' || props[0].location.pathname == '/integritetspolicy-webbsida' || props[0].location.pathname == '/en/privacy-policy-website' || props[0].location.pathname == '/integritetspolicy-videotjanst' || props[0].location.pathname =='/en/privacy-policy-video-service' ?
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <Nav {...props} />
                        <Wrapped {...props} />
                        <Footer {...props} />
                    </div>
                    :
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <Notification {...props} />
                        <Wrapped {...props} />
                    </div>
            }

            {props.uData && props[0].location.pathname != '/termsandconditions' && props[0].location.pathname != '/integritetspolicy-video-service' ? <PatientAccountModal {...props} /> : null}

        </div>
    );
};

export default DefaultLayout;