import HomePage from './views/homePage';
import UserNotifications from './views/dataManagment/userNotifications';
import PatientError from './views/patientError';
import Notifications from '././views/user/notifications';
import Support from './views/user/support';
import ReceivedSupport from './views/user/reeivedSupport';
import SupportAssined from './views/user/assignedSupport';
import SupportCategory from './views/user/supportCategory';
import SupportArchive from './views/user/supportArchive';
import AllSupportTickets from './views/user/allSupportTickets';
import ManageModules from './views/modules/manageModules';
import SortModules from './views/modules/sortModules';
import UserLevels from './views/users/userLevels';
import Users from './views/users/users';
import BuyPacket from './views/user/buy';
import ScheduleMeeting from './views/user/scheduleMeeting';
import ManageMeeting from './views/user/manageMeeting';
import QuickPhrases from './views/dataManagment/quickPhrases'
import Login from './views/loginPage';
import { API_ENDPOINT } from './constants';
import Account from './views/user/account';
import Translation from './views/languages/translation';
import BankId from './views/bankId';
import BankIdIdentityResponse from './views/bankIdIdentityResponse';
import NetId from './views/netid';
import Logs from './views/logs/logs';
import Tracking from './views/logs/tracking';
import Chat from './views/chat/chat';
import Payments from './views/dataManagment/payments';
import ForgotPassword from './views/forgotPassword';
import RecoveryPassword from './views/recoveryPassword';
import Swish from "./views/swish";
import VideoCallRatings from './views/logs/videoCallRatings';
import VideoCallLogs from './views/logs/videoCallLogs';
import Payson from './components/payson';
import Transaction from './views/transactionId';
import SubscriptionPage from './views/subscriptionPage';
import PaymentPage from './views/paymentPage';
import sentSupport from './views/user/sentSupport';
import Meetings from './views/dataManagment/listMeetings';
import UserVerify from './views/dataManagment/userVerify'
import HomeLanding from './views/dataManagment/homeLanding';
import DeletionAccounts from './views/user/deletionAccounts';
import Licence from './views/dataManagment/licence';
import VideoPayment from './views/dataManagment/videoPayments';
import InvoiceRequests from './views/dataManagment/invoiceRequests';
import PaymentSuperUser from './views/dataManagment/paymentSuperUser';
import Price from './views/dataManagment/prices';
import PricesPage from './views/dataManagment/pricesPage';
import TehnicalRequirements from './views/dataManagment/technicalRequirements';
import ContactPage from './views/dataManagment/contactPage';
import Contact from './views/dataManagment/contact';
import Gdpr from './views/dataManagment/gdprPage';
import GdprPage from './views/dataManagment/gdpr';
import OurSolution from './views/dataManagment/ourSolution';
import AboutPage from './views/dataManagment/aboutPage';
import About from './views/dataManagment/about';
import OurSolutionPage from './views/dataManagment/ourSolutionsPage';
import BuyLicencePage from './views/dataManagment/buyLicencePage';
import TeamManagment from './views/dataManagment/team';
import PrivatePolicy from './views/dataManagment/privatePolicy';
import PrivatePolicyPage from './views/dataManagment/privacyPolicyPage';
import PrivatePolicyWebSite from './views/dataManagment/privacyPolicyWebSite';
import Registration from './views/registration';
import ContactAdminPage from './views/dataManagment/contactAdminPage';
import Outlook from './views/outlook/outlook';
import CookiesPage from './views/dataManagment/cookiesPage';
import CookiesWebSitePage from './views/dataManagment/cookiesWebSitePage'
import CookiesAdminPage from './views/dataManagment/cookiesAdminPage';
import TermsAndConditions from './views/dataManagment/termsAndConditions';
import TermsAndConditionsPage from './views/dataManagment/termsAndConditionsPage';
import MyUserAccounts from './views/user/myUserAccounts';
import TempPage from './views/tempPages';
import BackgroundVideoImages from './views/dataManagment/backgroundVideoImages';
import FortnoxActivation from './views/fortnoxActivation';
import FortnoxSettings from './views/fortnox/fortnoxSettings';
import Customers from './views/users/customers';
import SystemMonitoring from './views/dataManagment/systemMonitoring';
import VideoCallRedirect from './components/videoCallRedirect'
import NewLetter from './views/newsLetter/newsLetter';
import ListOfSubscribers from './views/newsLetter/listOfSubscribers';
import VideoCallStarted from './components/videoCallStarted';
import verificationCodePage from './views/verificationCodePage';
import VideoCallErrorLog from './views/logs/videoCallErrorLog';

const linkTranslates = {

    'en': {
        '/': '/en',
        '/var-losning': '/en/our-solutions',
        '/om-oss': '/en/about-us',
        '/contact': '/en/contact',
        '/gdpr': '/en/gdpr',
        '/for-partners': '/en/for-partners',
        '/cookies': '/en/cookies',
        '/integritetspolicy-webbsida': '/en/privacy-policy-website',
        '/integritetspolicy-videotjanst': '/en/privacy-policy-video-service'
    },
}


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}


export const routes = [
    {
        path: "/activation",
        component: FortnoxActivation,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Curoflow video'.translate(lang),
                description: ''

            }
        },
        loadData: [

        ]
    },

    {
        path: "/",
        component: HomePage,
        disableAvailablePaths: true,
        site: true,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'GDPR-säkra videomöten med Curoflow Video'.translate(lang, langs),
                description: 'GDPR-säkra videomöten med krypterad databehandling på svenska servrar. För säkra, enkla och välfungerande videosamtal.'.translate(lang, langs),
                "og:image": 'https://video-api.curoflow.se/uploads/GDPR%20secure%20video%20conference%20in%20cellphone%20with%20Curoflow.jpg'

            }
        },
        loadData: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/home-landing', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            section0: result.section0,
                            section01: result.section01,
                            section1: result.section1,
                            section2: result.section2,
                            section3: result.section3,
                            section4: result.section4,
                            section5: result.section5
                        }
                })

            },
        ]
    },
    {
        path: "/data-managment/system-monitoring",
        component: SystemMonitoring,
        loginNeeded: true,
        preAuthComponent: Login,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'System monitoring'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [

        ]
    },
    {
        path: "/plans-for-secure-video-meetings",
        component: PricesPage,
        site: true,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'GDPR-säkra videosamtal med upp till 100 deltagare'.translate(lang, langs),
                description: 'Välj den lösning som passar din organisation. För GDPR-säkra videomöten med krypterad databehandling på svenska servrar.'.translate(lang, langs),
                "og:image": 'https://video-api.curoflow.se/uploads/GDPR%20secure%20video%20conference%20in%20cellphone%20with%20Curoflow.jpg'
            }
        },
        loadData: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/all-price', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            licence: result

                        }
                })
            },
        ]
    },
    {
        path: "/technical-requirements",
        component: TehnicalRequirements,

        // loginNeeded: true,
        // preAuthComponent: HomePage,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Curoflow video'.translate(lang),
                description: ''

            }
        },
        loadData: [
        ]
    },

    {
        path: "/pages/prices",
        component: Price,
        //redirectUser: '/',
        disableAvailablePaths: true,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Curoflow video'.translate(lang),


            }
        },
        loadData: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/price', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)

                        return {
                            initialValues: result,
                        }
                })
            },
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/languages', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        sortType: 1,
                        sortField: 'key',
                        query: {}
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            languages: result
                        }
                })

            },
        ]

    },
    {
        path: "/contact",
        component: ContactPage,
        site: true,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Kontakta oss för GDPR-säkra lösningar för videomöten'.translate(lang, langs),
                description: 'Vi kan hjälpa din organisation att hålla säkra digitala möten. Kontakta oss för att få veta mer om våra GDPR-kompatibla videolösningar.'.translate(lang, langs),
                "og:image": 'https://video-api.curoflow.se/uploads/GDPR%20secure%20video%20conference%20in%20cellphone%20with%20Curoflow.jpg'
            }
        },
        loadData: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/all-contact-admin', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (result && result.length && result[0].section)
                        return {
                            contactPage: result[0].section
                        }
                })
            },
        ]
    },


    {
        path: "/data/contact",
        component: Contact,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Contact'.translate(lang) + ' - Nek'.translate(lang),

            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/contact', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            }
        ]
    },
    {

        path: "/refresh/page",
        component: TempPage,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Refresh page'.translate(lang),
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: []

    },
    {
        path: "/login",
        component: Login,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Välkommen till digital kommunikation som följer GDPR'.translate(lang),
                description: 'Delta i GDPR-kompatibla videokonferenser, samtal och möten med krypterad databehandling på servrar i EU.'.translate(lang),
                'og:image': 'https://video-api.curoflow.se/uploads/GDPR%20secure%20video%20conference%20in%20cellphone%20with%20Curoflow%20Video%20.jpg'
            }
        },
        loadData: [

        ]

    },
    {
        path: "/verification-code",
        component: verificationCodePage,
        //redirectUser: '/',
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),
                description: ''

            }
        },
        loadData: [

        ]

    },
    {
        path: "/registration",
        component: Registration,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Registrera dig idag för GDPR-kompatibla videosamtal'.translate(lang),
                description: 'Registrera ditt konto och starta GDPR-kompatibla videokonferenser, samtal och möten med databehandling på servrar i EU.'.translate(lang),
                'og:image': 'https://video-api.curoflow.se/uploads/GDPR%20secure%20video%20conference%20in%20cellphone%20with%20Curoflow%20Video%20.jpg'
            }
        },
        loadData: [

        ]

    },
    {
        path: "/auth/callback",
        component: Outlook,
        //redirectUser: '/',
        disableAvailablePaths: true,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Curoflow video'.translate(lang),
                description: ''

            }
        },
        loadData: [

        ]

    },
    {
        path: "/notifications",
        component: Notifications,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Curoflow video'.translate(lang),
                description: ''

            }
        },
        loadData: [

        ],
        loadDataWithQuery: [

        ]

    },
    {
        path: "/support",
        component: Support,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Support'.translate(lang) + ' - Curoflow'.translate(lang),
                description: ''

            }
        },
        loadData: [

        ],
        loadDataWithQuery: [
        ]

    },
    {
        path: "/support-sent",
        component: sentSupport,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Curoflow video'.translate(lang),
                description: ''

            }
        },
        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                return fetchFunction(API_ENDPOINT + '/support-category/all', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({

                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            items: result

                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                return fetchFunction(API_ENDPOINT + '/support-category/all-tikets/doctor', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.pageNumber
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            doctorTikets: result.items

                        }
                })

            },
        ]

    },
    {
        path: "/all-support-tickets",
        component: AllSupportTickets,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'All Support'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''

            }
        },
        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/support/all-support-ticket', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            resultFilter: result.filter
                        }
                })

            },
        ]

    },
    {
        path: "/pages/newsletter",
        component: NewLetter,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Curoflow'.translate(lang),


            }
        },
        loadData: [

        ],

        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {
                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/newsletter', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })
            },
        ]

    },
    {
        path: "/pages/list-newsletters",
        component: ListOfSubscribers,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Newsletters '.translate(lang),

            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/list/subscribers', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            },
        ]
    },
    {
        path: "/video/contact-admin",
        component: ContactAdminPage,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Curoflow video'.translate(lang),
                description: ''

            }
        },
        loadData: [],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/contact-admin', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    // console.log(result)
                    // if (result && result.length && result[0].section)
                    return {
                        initialValues: result
                    }
                })
            },


            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/languages', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        sortType: 1,
                        sortField: 'key',
                        query: {}
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            languages: result
                        }
                })

            },
        ]
    },
    {
        path: "/support-received",
        component: ReceivedSupport,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Curoflow video'.translate(lang),
                description: ''

            }
        },
        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/support-category/all-tikets', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        group: query.group,
                        alias: query.alias,
                        page: query.pageNumber
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            tikets: result.items

                        }
                })

            },
        ]

    },
    {
        path: "/support-assigned",
        component: SupportAssined,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Curoflow video'.translate(lang),
                description: ''

            }
        },
        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                return fetchFunction(API_ENDPOINT + '/support-category/all-tikets/assigned', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.pageNumber
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            assignedTickets: result.items,

                        }
                })

            },
        ]

    },
    {
        path: "/support-category",
        component: SupportCategory,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Support category'.translate(lang) + ' - Curoflow'.translate(lang),
                description: ''

            }
        },
        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/support-categories', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            }
        ]

    },
    {
        path: "/support-archive",
        component: SupportArchive,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Support archive'.translate(lang) + ' - Curoflow'.translate(lang),
                description: ''

            }
        },
        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/support-category/closed-tickets', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        // group: query.group
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        console.log(result);
                    return {
                        items: result,
                        total: result.length
                    }
                })

            },
        ]

    },
    {
        path: "/licence",
        component: Licence,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Price list'.translate(lang),

            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/pages/licence', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/pages/sms/licence', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        console.log(result)
                    return {
                        page: query.page ? query.page : 0,
                        smsLicence: result.items,
                        total: result.total,
                    }
                })

            },

        ]
    },
    {
        path: "/buy-licence",
        component: BuyLicencePage,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Payments'.translate(lang),

            }
        },

        loadData: [

        ],
        loadDataWithQuery: [

            (fetchFunction, lang, match, query) => {

                return fetchFunction(API_ENDPOINT + '/data/licence/all', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            licence: result

                        }
                })

            },
            // (fetchFunction, lang, match, query) => {

            //     return fetchFunction(API_ENDPOINT + '/data/sms/licence/all', {
            //         method: 'GET',
            //         headers: {
            //             'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            //             'content-type': 'application/json'
            //         },
            //     }).then(parseJSON).then(({ result, status }) => {
            //         if (status >= 200 && status < 300)
            //             return {
            //                 smsLicence: result

            //             }
            //     })

            // },
        ]
    },
    {
        path: "/video-payments",
        component: VideoPayment,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Payments'.translate(lang),

            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/payments', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields,
                        dateFrom: query.dateFrom,
                        dateTo: query.dateTo,
                        status: query.status
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            },

        ]
    },
    {
        path: "/video-invoice-request",
        component: InvoiceRequests,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Payments'.translate(lang),

            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/invoice/requests', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields,
                        dateFrom: query.dateFrom,
                        dateTo: query.dateTo
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            },

        ]
    },
    {
        path: "/video-payments-list",
        component: PaymentSuperUser,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Payments'.translate(lang),

            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/payments', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields,
                        dateFrom: query.dateFrom,
                        dateTo: query.dateTo
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            },

        ]
    },
    {
        path: "/gdpr-compilance-in-secure-video-meetings",
        disableAvailablePaths: true,
        site: true,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'GDPR-efterlevnad med databehandling på svenska servrar'.translate(lang, langs),
                description: 'Curoflow Video har utvecklats för strikt skydd av personuppgifter, efterlevnad av GDPR och utan tredjepartsleverantörer utanför EU.'.translate(lang, langs),
                "og:image": 'https://video-api.curoflow.se/uploads/GDPR%20secure%20video%20conference%20in%20cellphone%20with%20Curoflow.jpg'
            }
        },
        component: Gdpr,
        loadData: [

        ],
        loadDataWithQuery: [

            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/all-gdpr', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    console.log(result)
                    if (result && result.length && result[0].section)
                        return {
                            gdprPage: result[0].section
                        }
                })
            },

        ]
    },
    {
        path: "/video-started-call/:id",
        component: VideoCallStarted,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Video Call Started'.translate(lang),
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [


        ]
    },
    {
        path: "/video/gdpr",
        component: GdprPage,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Curoflow video'.translate(lang),


            }
        },
        loadData: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/gdpr', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)

                        return {
                            initialValues: result,
                        }
                })
            }
        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/languages', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        sortType: 1,
                        sortField: 'key',
                        query: {}
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            languages: result
                        }
                })

            },
        ]

    },
    {
        path: "/our-solution",
        disableAvailablePaths: true,
        site: true,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'GDPR'.translate(lang),

            }
        },
        component: OurSolutionPage,
        loadData: [

        ],
        loadDataWithQuery: [

            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/all-solution', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (result && result.length && result[0].section) {
                        return {
                            ourSolutions: result[0].section
                        }
                    }
                })
            },

        ]
    },
    {
        path: "/video/our-solution",
        component: OurSolution,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Curoflow video'.translate(lang),


            }
        },
        loadData: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/our-solution', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)

                        return {
                            initialValues: result,
                        }
                })
            }
        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/languages', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        sortType: 1,
                        sortField: 'key',
                        query: {}
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            languages: result
                        }
                })

            },
        ]

    },
    // {
    //     path: "/about-us",
    //     component: AboutPage,
    //     site: true,
    //     disableAvailablePaths: true,
    //     generateSeoTags: (data, lang, langs=null) => {
    //         return {
    //             title: 'Curoflow video'.translate(lang),


    //         }
    //     },
    //     component: AboutPage,
    //     loadData: [

    //     ],
    //     loadDataWithQuery: [
    //         (fetchFunction, lang, match, query) => {
    //             return fetchFunction(API_ENDPOINT + '/data/pages/all-aboutas', {
    //                 method: 'GET',
    //                 headers: {
    //                     'content-type': 'application/json'
    //                 },
    //             }).then(parseJSON).then(({ result, status }) => {
    //                 if (result && result.length && result[0].section)
    //                     return {
    //                         aboutUs: result[0].section
    //                     }
    //             })
    //         },

    //         (fetchFunction, lang, match, query) => {
    //             return fetchFunction(API_ENDPOINT + '/data/pages/team/all', {
    //                 method: 'GET',
    //                 headers: {
    //                     'content-type': 'application/json'
    //                 },
    //             }).then(parseJSON).then(({ result, status }) => {
    //                 if (result)
    //                     return {
    //                         team: result
    //                     }
    //             })
    //         },

    //     ]
    // },
    {
        path: "/video/about",
        component: About,
        site: true,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Curoflow video'.translate(lang),


            }
        },
        loadData: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/aboutas', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)

                        return {
                            initialValues: result,
                        }
                })
            },
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/languages', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        sortType: 1,
                        sortField: 'key',
                        query: {}
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            languages: result
                        }
                })

            },

        ]

    },
    {
        path: "/cookies",
        disableAvailablePaths: true,
        site: true,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Curoflow video'.translate(lang),
            }
        },
        component: CookiesPage,
        loadData: [

        ],
        loadDataWithQuery: [

            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/all-coockies', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    console.log(result)
                    return {
                        cookiesPage: result
                    }
                })
            },

        ]

    },
    {
        path: "/website/cookies",
        site: true,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Curoflow video'.translate(lang),
            }
        },
        component: CookiesWebSitePage,
        loadData: [

        ],
        loadDataWithQuery: [

            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/all-coockies', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    console.log(result)
                    return {
                        cookiesPage: result
                    }
                })
            },

        ]

    },
    {
        path: "/pages/terms-and-conditions-page",
        component: TermsAndConditions,
        site: true,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Curoflow video'.translate(lang),


            }
        },
        loadData: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/termsandconditions', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)

                        return {
                            initialValues: result,
                        }
                })
            }
        ]

    },
    {
        path: "/termsandconditions",
        disableAvailablePaths: true,
        site: true,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Curoflow video'.translate(lang),
            }
        },
        component: TermsAndConditionsPage,
        loadData: [

        ],
        loadDataWithQuery: [

            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/all-termsandconditions', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    console.log(result)
                    return {
                        termsAndConditions: result
                    }
                })
            },

        ]

    },
    {
        path: "/pages/coockies-page",
        component: CookiesAdminPage,
        site: true,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Curoflow video'.translate(lang),


            }
        },
        loadData: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/coockies', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)

                        return {
                            initialValues: result,
                        }
                })
            },

            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/languages', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        sortType: 1,
                        sortField: 'key',
                        query: {}
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            languages: result
                        }
                })

            },
        ]

    },
    {
        path: "/video/team-management-page",
        component: TeamManagment,
        loginNeeded: true,
        site: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Management team'.translate(lang),

            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/pages/teams', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            },
        ]
    },
    {
        path: "/patient-error",
        component: PatientError,
        preAuthComponent: Login,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Curoflow Error'.translate(lang),
                description: ''

            }
        },
        loadData: [

        ],
        loadDataWithQuery: [

        ]

    },
    {
        path: "/forgotpassword",
        component: ForgotPassword,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Curoflow video'.translate(lang),
                description: ''

            }
        },
        loadData: [

        ]

    },
    {
        path: "/bankid/v2/modal/:loginStorageKey",
        component: BankId,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'BankId'.translate(lang) + ' - Curoflow'.translate(lang),
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [

        ]
    },
    {
        path: "/bankid-identity/v2/:loginStorageKey",
        component: BankIdIdentityResponse,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'BankId'.translate(lang) + ' - Curoflow'.translate(lang),
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [

        ]
    },

    {
        path: "/bankid/v2/:loginStorageKey",
        component: BankId,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'BankId'.translate(lang) + ' - Curoflow'.translate(lang),
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [

        ]
    },
    {
        path: "/check-transaction/:id",
        component: Transaction,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Transaction'.translate(lang) + ' - Curoflow'.translate(lang),
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [

        ]
    },
    {
        path: "/payson-recurring/checking/subscription/:id",
        component: SubscriptionPage,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Subscription'.translate(lang) + ' - Curoflow'.translate(lang),
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [

        ]
    },
    {
        path: "/payson-recurring/checking/payment/:id",
        component: PaymentPage,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Payment'.translate(lang) + ' - Curoflow'.translate(lang),
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [

        ]
    },


    {
        path: "/forgotpassword/password-recovery/:key",
        component: RecoveryPassword,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Password recovery'.translate(lang) + ' - Curoflow'.translate(lang),
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [

        ]
    },
    {
        path: "/netid/:loginStorageKey",
        component: NetId,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'NetId'.translate(lang) + ' - Curoflow'.translate(lang),
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [

        ]
    },

    {
        path: "/data-managment/meetings",
        component: Meetings,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Meetings'.translate(lang) + ' - Curoflow'.translate(lang),
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/meetings/v2', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: null,
                        entries: null,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            items: result.items,
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/all/meetings', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        expiredMeetings: query.expiredMeetings,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            meetings: result.items,
                            total: result.total,
                        }
                })

            }

        ]
    },
    {
        path: "/data-managment/user-notifications",
        component: UserNotifications,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Groups'.translate(lang) + ' - Curoflow'.translate(lang),
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/notificationsSettings', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            }
        ]
    },
    {
        path: "/my-data/deletion-requests",
        component: DeletionAccounts,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Deletion requests'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [

        ]
    },
    {
        path: "/my-data/background-video-images",
        component: BackgroundVideoImages,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Background video images'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [

        ]
    },
    {
        path: "/logs/system-logs",
        component: Logs,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang, langs = null) => {
            return {

            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/system-logs', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            }
        ]
    },
    {
        path: "/logs/video-call-ratings",
        component: VideoCallRatings,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang, langs = null) => {
            return {

            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/video-call-ratings', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            }
        ]
    },
    {
        path: "/logs/video-call-logs",
        component: VideoCallLogs,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang, langs = null) => {
            return {

            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/video-call-logs', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            }
        ]
    },

    {
        path: "/logs/user-logs",
        component: Tracking,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang, langs = null) => {
            return {

            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/logs', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            }
        ]
    },
    {
        path: "/logs/video-call-error-logs",
        component: VideoCallErrorLog,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang, langs = null) => {
            return {

            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/video/call/error/log', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            }
        ]
    },
    {
        path: "/modules/manage-modules",
        component: ManageModules,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Modules'.translate(lang) + ' - Curoflow'.translate(lang),
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/modules', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                return fetchFunction(API_ENDPOINT + '/modules', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: null,
                        entries: null,
                        filter: null,
                        sortType: 1,
                        sortField: 'name',
                        query: {}
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            formModules: result
                        }
                })

            },
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/modules/endpoints', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            endpoints: result
                        }
                })

            },

        ]
    },
    {
        path: "/modules/sort-modules",
        component: SortModules,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Modules'.translate(lang) + ' - Curoflow'.translate(lang),
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/modules', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: null,
                        entries: null,
                        filter: null,
                        sortType: 1,
                        sortField: 'position',
                        query: {}
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300) {

                        let tree = [];
                        for (let i = 0; i < result.length; i++) {
                            if (result[i].type == 'menu') {
                                let obj = result[i];
                                obj.submenu = [];
                                for (let j = 0; j < result.length; j++) {
                                    if (result[j].type == 'submenu' && result[j].parent == result[i]._id) {
                                        obj.submenu.push(result[j]);
                                    }

                                }
                                tree.push(obj);
                            }
                        }
                        // console.log(tree)
                        return {
                            items: tree
                        }

                    }
                })

            },
        ]
    },
    {
        path: "/users/user-levels",
        component: UserLevels,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'User levels'.translate(lang) + ' - Curoflow'.translate(lang),
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/users/user-levels', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                return fetchFunction(API_ENDPOINT + '/modules', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: null,
                        entries: null,
                        filter: null,
                        sortType: 1,
                        sortField: 'name',
                        query: {}
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            formModules: result
                        }
                })

            },

        ]
    },
    {
        path: "/users/buy-packet",
        component: BuyPacket,
        loginNeeded: true,
        preAuthComponent: Login,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'User levels'.translate(lang) + ' - Curoflow'.translate(lang),
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                return fetchFunction(API_ENDPOINT + '/data/licence/all', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            licence: result

                        }
                })

            },
        ]
    },
    {
        path: "/users/schedule-meeting",
        component: ScheduleMeeting,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'User levels'.translate(lang) + ' - Curoflow'.translate(lang),
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                return fetchFunction(API_ENDPOINT + '/data/licence/all', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            licence: result

                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                return fetchFunction(API_ENDPOINT + '/users/cohosts', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    console.log('results', result);
                    if (status >= 200 && status < 300)
                        return {
                            cohosts: result

                        }
                })

            },

        ]
    },
    {
        path: "/users/manage-meeting",
        component: ManageMeeting,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'User levels'.translate(lang) + ' - Curoflow'.translate(lang),
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                return fetchFunction(API_ENDPOINT + '/data/licence/all', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            licence: result

                        }
                })

            },
        ]
    },
    {
        path: "/home-landing",
        component: HomeLanding,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Home landing'.translate(lang) + ' - Curoflow'.translate(lang),

            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/home-landing', {
                    method: 'GET',
                    headers: {
                        // 'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },

                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        console.log(result);
                    return {
                        initialValues: result,
                    }
                })

            },

            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/languages', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        sortType: 1,
                        sortField: 'key',
                        query: {}
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            languages: result
                        }
                })

            },

        ]
    },
    {
        path: "/data/quick-phrases",
        component: QuickPhrases,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Quick phrases'.translate(lang) + ' - Curoflow'.translate(lang),
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/quickPhrases', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            },

        ]
    },
    {
        path: "/users/users",
        component: Users,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Users'.translate(lang) + ' - Curoflow'.translate(lang),
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/users/users', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            },

            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/users/user-levels', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        sortType: -1,
                        sortField: 'level',
                        query: {}
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            userLevels: result
                        }
                })

            },

            (fetchFunction, lang, match, query) => {

                return fetchFunction(API_ENDPOINT + '/support-category/all', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        sortType: -1,
                        sortField: 'level',
                        query: {}
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            supportCategory: result

                        }
                })

            },
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/languages', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        sortType: 1,
                        sortField: 'key',
                        query: {}
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            languages: result
                        }
                })

            },

        ]
    },
    {
        path: "/users/customers",
        component: Customers,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Customers'.translate(lang) + ' - Curoflow'.translate(lang),
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [

            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/users/customers-active', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            itemsActive: result.items,
                            totalActive: result.total,
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/users/customers-inactive', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            itemsInactive: result.items,
                            totalInactive: result.total,
                        }
                })

            },
        ]
    },
    {
        path: "/my-data/account",
        component: Account,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Account'.translate(lang) + ' - Curoflow'.translate(lang),
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [

            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/languages', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        sortType: 1,
                        sortField: 'key',
                        query: {}
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            languages: result
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                return fetchFunction(API_ENDPOINT + '/data/licence/all', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            licence: result

                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                return fetchFunction(API_ENDPOINT + '/data/sms/licence/all', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            smsLicence: result

                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/users/account/connected-users', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields,
                        user: query.user
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                return fetchFunction(API_ENDPOINT + '/users/account/assigned/licence', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        console.log(result);
                    return {
                        assignedLicence: result.assignedLicence

                    }
                })

            },

        ]
    },
    {
        path: "/languages/translation",
        component: Translation,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Translation'.translate(lang) + ' - Curoflow'.translate(lang),
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/languages/translation', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            }
        ]
    },
    {
        path: "/my-data/personal-referrals/payments",
        component: Payments,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Payments'.translate(lang) + ' - Curoflow'.translate(lang),
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                return fetchFunction(API_ENDPOINT + '/bills/payments', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        group: query.group,
                        clinic: query.clinic
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)

                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            },

            (fetchFunction, lang, match, query) => {

                return fetchFunction(API_ENDPOINT + '/referrals/patient/invoice', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        alias: query.alias,
                        group: query.group,
                        clinic: query.clinic
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            itemsInvoice: result.items,
                            totalInvoice: result.total,
                        }
                })

            }
        ]
    },
    {
        path: "/payson",
        component: Payson,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Payson'.translate(lang) + ' - Curoflow'.translate(lang),
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [


        ]
    },
    {
        path: "/chat",
        component: Chat,
        disableAvailablePaths: true,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Chat'.translate(lang) + ' - Curoflow'.translate(lang),

            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};


                return fetchFunction(API_ENDPOINT + '/users/account/clinic-phrase', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({

                        group: query.groupId,
                        clinic: query.clinic,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            phrases: result,
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                return fetchFunction(API_ENDPOINT + '/data/licence/all', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            licence: result

                        }
                })

            },
        ]
    },
    {
        path: "/video-call/:conversation",
        disableAvailablePaths: true,
        component: Chat,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Video'.translate(lang) + ' - Curoflow'.translate(lang),
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};


                return fetchFunction(API_ENDPOINT + '/users/account/clinic-phrase', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({

                        group: query.groupId,
                        clinic: query.clinic,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            phrases: result,
                        }
                })

            },
        ]
    },
    {
        path: "/integritetspolicy-videotjanst",
        disableAvailablePaths: true,
        site: true,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Integritets policy'.translate(lang) + ' - Curoflow'.translate(lang),
                description: ''
            }
        },
        component: PrivatePolicyPage,
        loadData: [

        ],
        loadDataWithQuery: [

            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/all-privatepolicy', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    console.log(result)
                    return {
                        privacyPolicy: result
                    }
                })
            },
        ]
    },
    {
        path: "/integritetspolicy-webbsida",
        disableAvailablePaths: true,
        site: true,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Integritets policy'.translate(lang) + ' - Curoflow'.translate(lang),
                description: ''
            }
        },
        component: PrivatePolicyWebSite,
        loadData: [

        ],
        loadDataWithQuery: [

            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/all-privatepolicy', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    console.log(result)
                    return {
                        privacyPolicy: result
                    }
                })
            },
        ]
    },
    {
        path: "/video/private-policy-page",
        component: PrivatePolicy,
        site: true,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Curoflow video'.translate(lang),


            }
        },
        loadData: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/privatepolicy', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)

                        return {
                            initialValues: result,
                        }
                })
            },
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/languages', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        sortType: 1,
                        sortField: 'key',
                        query: {}
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            languages: result
                        }
                })

            },
        ]

    },
    {
        path: "/chat/:windowConversation",
        disableAvailablePaths: true,
        component: Chat,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Chat'.translate(lang) + ' - Curoflow'.translate(lang),
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [

        ]
    },
    {
        path: "/users/registration/verify/:id/:join",
        disableAvailablePaths: true,
        component: UserVerify,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Verify'.translate(lang) + ' - Curoflow'.translate(lang),
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [

        ]
    },
    {
        path: "/swish",
        component: Swish,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: "Swish test",
                description: ''
            };
        },
        loadData: [],
    },
    {
        path: "/users/user-accounts",
        component: MyUserAccounts,
        loginNeeded: true,
        preAuthComponent: Login,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Curoflow video'.translate(lang),
            }
        },
        loadData: [
        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/users/accounts', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        alias: query.alias,
                        group: query.group,
                        clinic: query.clinic,
                        adminUser: query.adminUser
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                            numberLicenceUsed: result.numberLicences
                        }
                })

            },
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/users/admin/all', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        console.log(result);
                    return {
                        admins: result,
                    }
                })
            }

        ]

    },
    {
        path: "/video-call-gateway/:conversation/:roomid/:initiator/:userId",
        component: VideoCallRedirect,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Video Call'.translate(lang) /*+ ' - Curoflow'.translate(lang)*/,
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [


        ]
    },
    {
        path: "/fortnox/settings",
        component: FortnoxSettings,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang, langs = null) => {
            return {
                title: 'Fortnox'.translate(lang) + ' - Curoflow'.translate(lang),
                description: ''
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
        ]
    },
];




export function generateRoutes(r) {
    let newRoutes = [];
    for (let i = 0; i < r.length; i++) {
        newRoutes.push(r[i]);

        if (linkTranslates.en[r[i].path]) {
            newRoutes.push({
                ...r[i],
                path: linkTranslates.en[r[i].path]
            })
        }
    }
    return newRoutes;
}