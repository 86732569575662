import React, { Component } from 'react';
import Page from '../containers/page';

class TempPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.initialData,
            loading: true
        };

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        if (this.props[0].location && this.props[0].location.state && this.props[0].location.state.reditectTo) {
            this.props[0].history.push(this.props[0].location.state.reditectTo)
        }
    }


    render() {


        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>

            </div >
        );
    }
}



export default Page(TempPage);