import React, { Component } from 'react';
import Page from '../../containers/page';
import { API_ENDPOINT } from "../../constants";
import { Player, Controls } from '@lottiefiles/react-lottie-player';

import {
    Container,
    Row,
    Col
} from 'reactstrap';

import chat from '../../assets/images/chat.png';
import ChatForm from '../../components/forms/chatForm';

var striptags = require('striptags');

function parseJSON(response) {
    return response.json().then((json) => {
      return {
        result: json,
        status: response.status,
      };
    });
  }

class TermsAndConditionsPage extends Component {
    constructor(props) {
        super(props);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.player = React.createRef(); 

        this.state = {
            promotedProjects: [],
            poolActive: false,
            chatForm: false,
            yScroll2: 0,
            termsAndConditions:[],
            ...props.initialData
        };
    }

    listenToScroll = () => {
        const yScroll2 =
            document.body.scrollTop || document.documentElement.scrollTop


        this.setState({
            yScroll2: yScroll2,
        })
    }

    sendMessage1 = (data) => {
        if(data.checked) {
            data.lang = this.props.lang
            this.setState({
                loading1: true
            }, () => {3
    
                fetch(API_ENDPOINT + '/data/contact/new', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({data})   
                }).then(parseJSON).then(({result, status}) => {
                    if (result.error) {
                        this.setState({ error: result.error })
                    } else {
                    }
                })
            })
        }
    }

    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }
           
        this.updateWindowDimensions();
        window.addEventListener('scroll', this.listenToScroll)

        this.get();
        
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            // this.get()
        }
    }
    
    render() {
        // console.log(this.state.privacyPolicy)
        return (
            <div>

                <div className="chat-icon">
                    <img src={chat} alt="chat" onClick={() => {
                        this.setState({
                            chatForm: !this.state.chatForm
                        })
                    }} />

                    <div className={this.state.chatForm ? 'chat-form-container opened' : 'chat-form-container'}>
                    <span className="close-icon" onClick={() => {
                            this.setState({
                                chatForm: false
                            })
                        }}>x</span>
                        <h2>{'Kontakta oss'.translate(this.props.lang)}</h2>
                                    {   
                                        !this.state.loading1 ? 
                                        <ChatForm onSubmit={this.sendMessage1} lang={this.props.lang} />
                                        :null
                                    }
                                    {
                                        this.state.loading1 ? 
                                        <Player
                                            ref={this.player} 
                                            autoplay={true}
                                            keepLastFrame={true}
                                            //loop={true}
                                            controls={true}
                                            src="https://assets4.lottiefiles.com/packages/lf20_oaw8d1yt.json"
                                            style={{ height: '100px', width: '100px' }}
                                        ></Player>
                                        :
                                        null
                                    }
                        <div className="chat-info">
                            <h6>
                                {'Du kan aven kontakta oss via telefon:'.translate(this.props.lang)}
                                <a href="tel:0107500655">{this.props.lang == 'en' ? '+46 (0)10-750 06 55'  : '010-750 06 55'}</a>
                            </h6>
                        </div>
                    </div>

                </div>

                <div className="news-header-coockies">
                    <Container>
                        <Row>
                            <Col lg="6">
                               
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className="cookies">
                    <Container>
                        <Row>
                            <Col lg="12" className="m-auto">
                                
                                <div dangerouslySetInnerHTML={{__html: Object.translate(this.state.termsAndConditions && this.state.termsAndConditions[0], 'shortDescription', this.props.lang)}}></div>
                                
                            </Col>
                        </Row>
                    </Container>
                </div>

            </div>
        );
    }
}

export default Page(TermsAndConditionsPage);

