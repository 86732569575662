import React, { Component } from 'react';
import { API_ENDPOINT } from '../constants';
/**
* Payson transaction check component 
* @author   Stefan Pantic
*/
class Transaction extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        let key = this.props[0].match.params.id;

        fetch(API_ENDPOINT + '/confirmation/transaction', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ key })
        }).then(res => res.json()).then((result) => {
            if (result.error) {
                this.setState({
                    error: result.error
                })
            } else {

                if (result.status == 'readyToShip') {
                    if (result.redirectUrl) {
                        this.props.verifyUser(() => {
                            this.props[0].history.push({
                                pathname: '/users/buy-packet',
                                state: {
                                  cfOrder: true,
                                  step: 4
                                }
                              })
                        });
                    } else {
                        this.props.verifyUser(() => {
                            this.props[0].history.push('/my-data/account')
                        });
                    }

                } else {
                    if (result.upgradeLicence) {
                        this.props[0].history.push({
                            pathname: '/my-data/account',
                            state: { failureModalUpgradeLicence: true }
                        })
                    }else if (result.smsLicence) {
                        this.props[0].history.push({
                            pathname: '/my-data/account',
                            state: { failureModalSMS: true }
                        })
                    } else {
                        this.props[0].history.push({
                            pathname: '/my-data/account',
                            state: { failureModal: result.licence }
                        })
                    }

                }



            }


        });
    }
    render() {
        return (
            <h1>
                {/* {this.state.status ? this.state.status : this.state.error} */}

            </h1>
        );
    }
}

export default Transaction;