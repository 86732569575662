
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

import { FormGroup, Label, Input, FormFeedback, FormText, Row, Col, Form } from 'reactstrap';

/**
* Generate options
* @author David Rakic
*/
class Radio extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {

        return (
            <FormGroup className="referal-checkbox radio-short">
                <Label>
                    <Input checked={this.props.value} type="radio" onChange={() => this.props.onChange(this.props.value)} />
                    <span className="field-after-text">{this.props.label}</span>
                </Label>
            </FormGroup>
        )
    }
}

export default Radio;