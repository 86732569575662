import React, { Component } from 'react';
import Page from '../containers/page';
import { API_ENDPOINT } from '../constants';
import ErrorModal from './errorModal';
import Link from "../components/link";
import Isvg from "react-inlinesvg";
import { Player } from "@lottiefiles/react-lottie-player";
import { Container, Row, Col } from "reactstrap";
import FormBuilder from "../components/forms/formBuilder";
import { required } from "../components/forms/validation";
import logoLogin from '../assets/svg/logo_video.svg';
import shapeBlue from "../assets/svg/shape-blue.svg";
import shapeGreen from "../assets/svg/shape-green.svg";
import shapeYellow from "../assets/images/shape-yellow.png";
import BankIDModal_v5 from './bankIDModal_v5';

class VideoCallRedirect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginBankIdModal: true,
            ...props.initialData,
            loginForm: true,
            moreOptions: true,
            _registerEvents: false
        };

    }


    componentDidMount() {

        this.props.verifyUser(() => {
            if (this.props.uData && this.props.uData._id === this.props[0].match.params.userId) {

                if (this.props.uData && this.props.socket && !this.state._registerEvents) {

                    this.setState({
                        _registerEvents: true
                    }, () => {
                        this.registerEvents();
                        this.props.socket.emit('callStatus', { conversation: this.props[0].match.params.conversation, uid: this.props.uData._id })


                    })
                } else {
                    this.socketInterval = setInterval(() => {
                        if (this.props.uData && this.props.socket && !this.state._registerEvents) {

                            if (this.socketInterval) {
                                clearInterval(this.socketInterval)
                            }

                            this.setState({
                                _registerEvents: true
                            }, () => {
                                this.registerEvents();
                                this.props.socket.emit('callStatus', { conversation: this.props[0].match.params.conversation, uid: this.props.uData._id })


                            })
                        }

                    }, 150);
                }

                // this.props[0].history.push(link)
            }
        });

        // if (!this.props.uData) {
        //     // this.fetchBankIdLogin()
        //     fetch(API_ENDPOINT + `/users/bankid/v2/start/${this.props.lang == 'en' ? this.props.lang : 'sv'}`, {
        //         method: "GET",
        //         headers: {
        //             "content-type": "application/json",
        //         },
        //     })
        //         .then((res) => res.json())
        //         .then((result) => {

        //             if (result.redirect_url) {
        //                 this.setState({
        //                     bankIdRedirectUrl: result.redirect_url
        //                 })
        //             }
        //         });
        //     window.addEventListener('message',
        //         (e) => {

        //             if (e.data == 'loggedin') {

        //                 this.props.verifyUser(() => {

        //                     if (this.props.uData && this.props.socket && !this.state._registerEvents) {

        //                         this.setState({
        //                             _registerEvents: true
        //                         }, () => {
        //                             this.registerEvents();
        //                             this.props.socket.emit('callStatus', { conversation: this.props[0].match.params.conversation, uid: this.props.uData._id })


        //                         })
        //                     } else {
        //                         this.socketInterval = setInterval(() => {
        //                             if (this.props.uData && this.props.socket && !this.state._registerEvents) {

        //                                 if (this.socketInterval) {
        //                                     clearInterval(this.socketInterval)
        //                                 }

        //                                 this.setState({
        //                                     _registerEvents: true
        //                                 }, () => {
        //                                     this.registerEvents();
        //                                     this.props.socket.emit('callStatus', { conversation: this.props[0].match.params.conversation, uid: this.props.uData._id })


        //                                 })
        //                             }

        //                         }, 150);
        //                     }


        //                     // this.props[0].history.push(link)

        //                 });

        //             } else if (e.data == 'loggedin_newuser') {


        //                 this.props.verifyUser(() => {
        //                     this.props[0].history.push('/my-data/account');
        //                 });
        //             } else if (e.data == 'loggedin_error') {
        //                 this.setState({
        //                     errorModal: "BankID login works only for existing accounts with connected BankID. In order to use BankID login, you must first create an account and connect it to your BankID."
        //                 }, () => {
        //                     console.log(this.state.errorModal);
        //                 })
        //             }
        //         }

        //         , false)

        // }
    }

    componentWillUnmount() {
        if (this.props.socket) {


            this.props.socket.removeListener('callStatus');
        }
    }

    registerEvents = async () => {
        this.props.socket.on('callStatus', (data) => {
            if (data.activeConversation === 'none') {
                this.setState({
                    errorModal: 'Video call ended'.translate(this.props.lang)
                })
            }

            if (data.activeConversation === this.props[0].match.params.conversation) {
                const link = `/video-call/${this.props[0].match.params.conversation}?initiator=${this.props[0].match.params.initiator}`
                this.props[0].history.push(link)
            }
        })

    }



    /**
  * Login function
  * @author   Milan Stanojevic
  */
    login = (data) => {

        if (localStorage.allowCookiesLogin) {

            fetch(API_ENDPOINT + "/users/login", {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                },
                body: JSON.stringify(data),
            })
                .then((res) => res.json())
                .then((result) => {
                    if (result.error) {
                        if (result.error.text) {
                            this.setState({
                                error: result.error.text
                            })
                        } else {
                            this.setState({
                                error: result.error
                            })
                        }
                    } else {
                        // localStorage.setItem('checkHost', true)
                        localStorage.setItem("authToken", result.token);
                        localStorage.setItem('loginMethod', 'code');
                        localStorage.setItem("profile", "doctor");
                        localStorage.setItem("time", Math.floor(Date.now() / 1000));
                        this.props.registerLog("Logged in");
                        this.props.verifyUser(() => {

                            if (typeof window != 'undefined' && (localStorage.getItem('groupAlias') == null || localStorage.getItem('groupAlias') == 'undefined')) {
                                if (this.props.uData && this.props.uData.userLevel && this.props.uData.userLevel == 20 && this.props.uData.clinicGroups && this.props.uData.clinicGroups.length) {
                                    localStorage.setItem('groupAlias', this.props.uData.clinicGroups[0].alias)
                                }
                            }
                            if (this.props.uData) {
                                if (this.props.uData._id === this.props[0].match.params.userId) {
                                    const link = `/video-call/${this.props[0].match.params.conversation}?initiator=${this.props[0].match.params.initiator}`

                                    if (this.props.uData && this.props.socket && !this.state._registerEvents) {

                                        this.setState({
                                            _registerEvents: true
                                        }, () => {
                                            this.registerEvents();
                                            this.props.socket.emit('callStatus', { conversation: this.props[0].match.params.conversation, uid: this.props.uData._id })


                                        })
                                    } else {
                                        this.socketInterval = setInterval(() => {
                                            if (this.props.uData && this.props.socket && !this.state._registerEvents) {

                                                if (this.socketInterval) {
                                                    clearInterval(this.socketInterval)
                                                }

                                                this.setState({
                                                    _registerEvents: true
                                                }, () => {
                                                    this.registerEvents();
                                                    this.props.socket.emit('callStatus', { conversation: this.props[0].match.params.conversation, uid: this.props.uData._id })


                                                })
                                            }

                                        }, 150);
                                    }
                                    // this.props[0].history.push(link)
                                } else {
                                    this.setState({
                                        errorModal: 'Error, you are not the person who should answer'.translate(this.props.lang)
                                    })
                                }
                            }

                        });
                    }
                });

        } else {
            this.props.redLinePopUp()
        }

    };


    render() {

        return (

            <div className="login-main-wrap">
                <div className="login-wrapper">
                    {!this.props.userVerificationInProgress ? (
                        <>
                            <Isvg src={shapeBlue} className="shape-blue" />
                            <Isvg src={shapeGreen} className="shape-green" />
                            <img src={shapeYellow} className="shape-yellow" />
                        </>
                    ) : null}
                    {!this.state.errorModal ?
                        <div className="log-in">
                            <Container fluid>
                                <Row>
                                    <Col lg={{ size: 12 }}>
                                        {this.props.userVerificationInProgress ? (
                                            <div className="loader-wrap">
                                                <Player
                                                    autoplay={true}
                                                    loop={true}
                                                    src="/lf30_editor_l5cxzdyf.json"
                                                    style={{
                                                        height:
                                                            typeof window != "undefined" &&
                                                                window.innerWidth < 768
                                                                ? "128px"
                                                                : "256px",
                                                        width:
                                                            typeof window != "undefined" &&
                                                                window.innerWidth < 768
                                                                ? "128px"
                                                                : "256px",
                                                    }}
                                                ></Player>
                                            </div>
                                        ) : (
                                            <div className="panel login-in-panel" style={{
                                                minWidth: typeof window != "undefined" &&
                                                    window.innerWidth < 768
                                                    ? null
                                                    : "738px",
                                            }}>
                                                <div className="login-container">
                                                    <div className="logo-header">
                                                        <Link to="/">
                                                            <Isvg src={logoLogin} />
                                                        </Link>
                                                    </div>
                                                    <h4>{"Login".translate(this.props.lang)} </h4>

                                                    {
                                                        !this.state.moreOptions && this.state.loginForm ?
                                                            this.state.bankIdRedirectUrl === null ?
                                                                <div className="loader-wrap">
                                                                    {/* <div className="loader" /> */}
                                                                    <Player
                                                                        autoplay={true}
                                                                        loop={true}
                                                                        src="/lf30_editor_l5cxzdyf.json"
                                                                        style={{
                                                                            height:
                                                                                typeof window != "undefined" &&
                                                                                    window.innerWidth < 768
                                                                                    ? "128px"
                                                                                    : "256px",
                                                                            width:
                                                                                typeof window != "undefined" &&
                                                                                    window.innerWidth < 768
                                                                                    ? "128px"
                                                                                    : "256px",
                                                                        }}
                                                                    ></Player>
                                                                </div>
                                                                :
                                                                <>
                                                                    {
                                                                        // !this.state._iframeLoaded ?


                                                                        //     <div className="loader-wrap" style={{ height: 360 }}>
                                                                        //         {/* <div className="loader" /> */}
                                                                        //         <Player
                                                                        //             autoplay={true}
                                                                        //             loop={true}
                                                                        //             src="/lf30_editor_l5cxzdyf.json"
                                                                        //             style={{
                                                                        //                 height:
                                                                        //                     typeof window != "undefined" &&
                                                                        //                         window.innerWidth < 768
                                                                        //                         ? "128px"
                                                                        //                         : "256px",
                                                                        //                 width:
                                                                        //                     typeof window != "undefined" &&
                                                                        //                         window.innerWidth < 768
                                                                        //                         ? "128px"
                                                                        //                         : "256px",
                                                                        //             }}
                                                                        //         ></Player>
                                                                        //     </div>
                                                                        //     :
                                                                        //     null
                                                                    }

                                                                    {/* <iframe ref={(node) => this.bankIdIframe = node} onLoad={() => {
                                                                        try {
                                                                            if (this.bankIdIframe && this.bankIdIframe.contentWindow && this.bankIdIframe.contentWindow.location && this.bankIdIframe.contentWindow.location.href && this.bankIdIframe.contentWindow.location.href.indexOf('/bankid/v2') !== -1) {
                                                                                //alert(true)
                                                                                this.setState({
                                                                                    _hideIframe: true
                                                                                })
                                                                            }
                                                                        } catch (e) { }
                                                                        this.setState({ _iframeLoaded: true })
                                                                    }} src={this.state.bankIdRedirectUrl} style={this.state._hideIframe ? { opacity: 0 } : { width: '100%', maxWidth: '450px', height: this.state._iframeLoaded ? 360 : 0, border: 0 }} /> */}
                                                                    <BankIDModal_v5 {...this.props} />
                                                                    <p style={{ paddingBottom: 20 }}></p>
                                                                </>
                                                            :
                                                            null

                                                    }
                                                    {this.state.loginForm ?
                                                        <div className={this.state.moreOptions ? "login-more-options-wrap-show" : "login-more-options-wrap-hidden"}>
                                                            <div className="login-form">
                                                                <FormBuilder
                                                                    dateFormat={this.props.dateFormat}
                                                                    lang={this.props.lang}
                                                                    onSubmit={(data) => this.login(data)}
                                                                    buttonText={"Log in".translate(this.props.lang)}
                                                                    fields={[
                                                                        {
                                                                            type: "row",
                                                                            children: [
                                                                                {
                                                                                    type: "col",
                                                                                    width: {
                                                                                        lg: 12,
                                                                                        sm: 12,
                                                                                        xs: 12,
                                                                                    },
                                                                                    children: [
                                                                                        {
                                                                                            type: "text",
                                                                                            name: "username",
                                                                                            label: "E-mail".translate(
                                                                                                this.props.lang
                                                                                            ),
                                                                                            validate: [
                                                                                                required(
                                                                                                    "E-mail is required!".translate(
                                                                                                        this.props.lang
                                                                                                    )
                                                                                                ),
                                                                                            ],
                                                                                        },
                                                                                    ],
                                                                                },
                                                                                {
                                                                                    type: "col",
                                                                                    width: {
                                                                                        lg: 12,
                                                                                        sm: 12,
                                                                                        xs: 12,
                                                                                    },
                                                                                    children: [
                                                                                        {
                                                                                            type: "password",
                                                                                            name: "password",
                                                                                            label: "Password".translate(
                                                                                                this.props.lang
                                                                                            ),
                                                                                            validate: [
                                                                                                required(
                                                                                                    "Password is required!".translate(
                                                                                                        this.props.lang
                                                                                                    )
                                                                                                ),
                                                                                            ],
                                                                                        },
                                                                                    ],
                                                                                },
                                                                            ],
                                                                        },
                                                                        {
                                                                            type: "row",
                                                                            children: [
                                                                                {
                                                                                    type: "col",
                                                                                    width: {
                                                                                        lg: 6,
                                                                                        sm: 6,
                                                                                        xs: 6,
                                                                                    },
                                                                                    children: [
                                                                                        {
                                                                                            type: "hidden",
                                                                                            name: "newAccount",
                                                                                            afterText: (
                                                                                                <Link
                                                                                                    to="/registration"
                                                                                                    className="forget"
                                                                                                    style={{ float: 'left' }}
                                                                                                >
                                                                                                    {"Create new account".translate(
                                                                                                        this.props.lang
                                                                                                    )}
                                                                                                </Link>
                                                                                            ),
                                                                                        }
                                                                                    ],
                                                                                },
                                                                                {
                                                                                    type: "col",
                                                                                    width: {
                                                                                        lg: 6,
                                                                                        sm: 6,
                                                                                        xs: 6,
                                                                                    },
                                                                                    children: [
                                                                                        {
                                                                                            type: "hidden",
                                                                                            name: "forget",
                                                                                            afterText: (
                                                                                                <a
                                                                                                    href={`/forgotpassword/?lang=${this.props.lang}`}
                                                                                                    className="forget"
                                                                                                >
                                                                                                    {"Forgot password?".translate(
                                                                                                        this.props.lang
                                                                                                    )}
                                                                                                </a>
                                                                                            ),
                                                                                        },
                                                                                    ],
                                                                                },
                                                                            ],
                                                                        },
                                                                    ]}
                                                                ></FormBuilder>
                                                            </div>
                                                            <div className="login-divider">
                                                                <hr /> <span>{"or".translate(this.props.lang)}</span>{" "}
                                                                <hr />
                                                            </div>

                                                        </div>

                                                        : null
                                                    }

                                                    {this.state.loginForm ?
                                                        this.state.moreOptions ?
                                                            <div className="login-more-options" onClick={() => { if (localStorage.allowCookiesLogin) this.setState({ moreOptions: !this.state.moreOptions }) }}>
                                                                {'Login using BankID'.translate(this.props.lang)}
                                                            </div>
                                                            :
                                                            <div className="login-more-options" onClick={() => this.setState({ moreOptions: !this.state.moreOptions })}>
                                                                {'Login using email and password'.translate(this.props.lang)}
                                                            </div>
                                                        : null
                                                    }
                                                    {
                                                        this.state.loginForm && this.state.moreOptions ?
                                                            <p style={{ fontSize: 12 }}>{"BankID login works only for existing accounts with connected BankID.".translate(this.props.lang)}</p>
                                                            :
                                                            null
                                                    }

                                                    {this.state.error ? (
                                                        <p style={{ color: 'red' }} className="error-message">
                                                            {this.state.error.translate(this.props.lang)}
                                                        </p>
                                                    ) : null}

                                                </div>


                                            </div>
                                        )}
                                    </Col>
                                </Row>

                            </Container>
                        </div>
                        : null}
                    {this.state.errorModal ?

                        <ErrorModal lang={this.props.lang}
                            isOpen={this.state.errorModal}
                            toggle={() => this.setState({ errorModal: null }, () => {
                                if (this.props.uData) {
                                    this.props[0].history.push('/my-data/account')
                                } else {
                                    this.props[0].history.push('/')
                                }
                            })}

                        >
                            {this.state.errorModal.translate(this.props.lang)}
                        </ErrorModal>
                        :
                        null
                    }
                </div>
                {/* {
                    this.state.loginBankIdModal ?
                        <BankIdModal
                            {...this.props}
                            isOpen={this.state.loginBankIdModal}
                            videoCallGateway={true}
                            toggle={() => {

                                if (this.props.uData) {
                                    if (this.props.uData._id === this.props[0].match.params.userId) {
                                        const link = `/video-call/${this.props[0].match.params.conversation}?initiator=${this.props[0].match.params.initiator}`
                                        this.props[0].history.push(link)
                                    } else {
                                        this.setState({
                                            error: 'Error, you are not the person who should answer'.translate(this.props.lang)
                                        })
                                    }

                                }

                                this.setState({ loginBankIdModal: null })

                            }}
                            redirect_url={this.state.loginBankIdModal}></BankIdModal>
                        :

                        null
                }

                {this.state.error ?

                    <ErrorModal lang={this.props.lang}
                        isOpen={this.state.error}
                        toggle={() => this.setState({ error: null }, () => {
                            this.props[0].history.push('/')
                        })}

                    >
                        {this.state.error.translate(this.props.lang)}
                    </ErrorModal>
                    :
                    null
                } */}
            </div >
        );
    }
}



export default Page(VideoCallRedirect);