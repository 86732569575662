import React, { Component } from 'react';
import { API_ENDPOINT } from '../constants';
import ErrorModal from '../components/errorModal';
/**
* Payson transaction check component 
* @author   Stefan Pantic
*/
class Transaction extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    checkSubscription = () => {
        let key = this.props[0].match.params.id;

        fetch(API_ENDPOINT + '/payson-recurring/check/subscription', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ key })
        }).then(res => res.json()).then((result) => {
            if (result.error) {
                // this.setState({
                //     error: result.error
                // })
                setTimeout(() => {
                    this.checkSubscription()
                }, 1000)
            } else {


                this.props.verifyUser(() => {
                    this.props[0].history.push({
                        pathname: '/users/buy-packet',
                        state: {
                            cfOrder: true,
                            step: 4
                        }
                    })
                });

            }


        });
    }

    componentDidMount() {
        this.checkSubscription()
    }
    render() {
        return (
            <h1>
                {this.state.error ?
                    <ErrorModal lang={this.props.lang}
                        isOpen={this.state.error}
                        toggle={() => {
                            this.setState({ error: null }, () => {
                                this.props[0].history.push({
                                    pathname: '/buy-licence',

                                })
                            })
                        }}
                    >
                        {this.state.error.translate(this.props.lang)}
                    </ErrorModal>
                    : null}


            </h1>
        );
    }
}

export default Transaction;