import React, { Component } from 'react';
import ListBuilder from './listBuilder';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../helpers/linkHelper';
import { API_ENDPOINT} from '../constants';
import moment from 'moment';
import Isvg from 'react-inlinesvg';
import DeleteModal from './deleteModal';
import ErrorModal from './errorModal';
import garabage from '../assets/svg/garbage.svg'
import cancelIcon from '../assets/svg/cancel_icon.svg'




function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}




const loadDataWithQuery = [
    (fetchFunction, lang, match, query) => {

        let queryFields = {};
        for (var key in query) {
            if (key && key.indexOf('query-') === 0) {
                queryFields[key.replace('query-', '')] = query[key];
            }
        }

        return fetchFunction(API_ENDPOINT + '/data/deletion-requests', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                page: query.page,
                entries: query.entries,
                filter: query.filter,
                sortType: query.sortType,
                sortField: query.sortField,
                group: query.group,
                query: queryFields,
                request: true
            })
        }).then(parseJSON).then(({ result, status }) => {
            if (status >= 200 && status < 300)
                return {
                    page: query.page ? query.page : 0,
                    items: result.items,
                    total: result.total,
                    resultFilter: result.filter
                }
        })


    },

]

/**
* List of patients for selected clinic and selected department
* @author   Milan Stanojevic
*/
class DeletionRequestsComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            regions: [],
            cities: [],
            groups: [],
            subgroups: [],
            ...props.initialData,
            useState: true,
            params: {
                entries: 10,
                page: 0,
            },
            items: [],
            total: 0,
            loading: true,
            suggestions: [],

        };
    }


    get = () => {
        let params = this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic });
        params.group = this.props.selectedGroup;
        params.clinic = this.props.selectedClinic;
        params._ts = new Date().getTime();

        this.setState({
            _ts: params._ts
        }, () => {
            for (let i = 0; i < loadDataWithQuery.length; i++) {
                loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, params).then((data) => {

                    this.setState({
                        ...data,
                        loading: null
                    })
                })
            }

        })


    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();




    }

    componentWillUnmount() {
    }



    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })

        }

        if (this.state.useState && prevState._useStateTs != this.state._useStateTs) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })

        }
        if (prevProps.selectedGroup != this.props.selectedGroup) {
            this.get();
        }
        if (prevProps.getRequests != this.props.getRequests) {
            this.get();
        }



    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field == 'waitingTime' ? 'waitingMin' : field;
            state.sortType = type;
            this.setState({
                params: state
            })

            this.setState({
                _useStateTs: new Date().getTime()
            })

        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field == 'waitingTime' ? 'waitingMin' : field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };

                obj[name] = value;

                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;


                this.setState({ params: obj })
            }

            this.setState({
                _useStateTs: new Date().getTime()
            })
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }

    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {

                    obj[fields[i].name] = fields[i].value;

                }
                this.setState({ params: obj })
            }

            this.setState({
                _useStateTs: new Date().getTime()
            })

        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }




    deletePatient = (item) => {
        if (item && item.user) {
            this.setState({
                loading: true
            }, () => {

                fetch(API_ENDPOINT + '/data/deletion-requests/delete/' + item.user, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ deletionRequest: item._id })
                }).then(parseJSON).then(({ result, status }) => {
                    if (result.error) {
                        this.setState({ error: result.error})
                    }
                    this.get();
                    this.props.getLogs()
                })
            })
        }

    }


    deleteRequest = (item) => {
        if (item && item.user) {
            this.setState({
                loading: true
            }, () => {
                fetch(API_ENDPOINT + '/data/deletion-requests/remove/' + item._id, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    }
                }).then(parseJSON).then(({ result, status }) => {
                    if (result.error) {
                        this.setState({ error: result.error })
                    }
                    this.get();
                    this.props.getLogs()
                })
            })
        }
    }




    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }
        return (
            <div className="users-filter">
                {this.state.error ?

                    <ErrorModal lang={this.props.lang}
                        isOpen={this.state.error}
                        toggle={() => this.setState({ error: null })}

                    >
                        {this.state.error.translate(this.props.lang) + ' '}
                        <span style={{ fontWeight: 600 }}>
                            {
                                this.state.connectedAccountsNames && this.state.connectedAccountsNames.length && this.state.connectedAccountsNames.map((item, idx) => {
                                    if (idx != this.state.connectedAccountsNames.length - 1)
                                        return (
                                            <>
                                                {item},&nbsp;
                                            </>
                                        )
                                    else {
                                        return (
                                            <>
                                                {item}
                                            </>
                                        )
                                    }
                                })
                            }
                        </span>
                    </ErrorModal>

                    :
                    null
                }
                <div className='delete-request-list'>
                    <ListBuilder
                        lang={this.props.lang}
                        loading={this.state.loading}
                        total={this.state.total}
                        showNumeration={false}
                        fields={[
                            { type: 'text', name: 'name', label: 'User'.translate(this.props.lang), allowSort: true },
                            { type: 'text', name: 'companyName', label: 'Company name'.translate(this.props.lang), allowSort: true },
                            { type: 'text', name: 'email', label: 'Email'.translate(this.props.lang), allowSort: true },
                            { type: 'text', name: 'tsCreatedDate', label: 'Request date'.translate(this.props.lang), allowSort: true },
                            { type: 'text', name: 'reason', label: 'Reason for deletion'.translate(this.props.lang), allowSort: true },
                           

                        ]}
                        items={this.state.items.map(item => {
                            console.log(item);
                            return {
                                ...item,
                                // requestDate: item.tsModified ? moment.unix(item.tsModified).format(this.props.dateFormat + ' HH:mm') : item.tsCreated ? moment.unix(item.tsCreated).format(this.props.dateFormat + ' HH:mm') : null

                            }
                        })}
                        actions={
                            [
                                {
                                    component: <div className="tabel-action-tooltip"> <Isvg src={cancelIcon} className="cancel-icon" /> <div className="chat-icons-tooltip">{'Cancel request'.translate(this.props.lang)}</div> </div>,
                                    onClick: (item) => this.setState({ removeRequestModal: item })
                                },

                                {
                                    component: <div className="tabel-action-tooltip"> <Isvg src={garabage} className="delete-icon" /> <div className="chat-icons-tooltip">{'Delete'.translate(this.props.lang)}</div> </div>,
                                    onClick: (item) => this.setState({ deleteModal: item })
                                }

                            ]
                        }
                        rawItems={this.state.items}
                        params={params}
                        sortField={params.sortField}
                        sortType={params.sortType}
                        updateSort={this.updateSort}
                        updateParams={this.updateParams}
                        updateMultipleParams={this.updateMultipleParams}
                    ></ListBuilder>
                </div>



                <DeleteModal
                    lang={this.props.lang}
                    isOpen={this.state.removeRequestModal}
                    toggle={() => this.setState({ removeRequestModal: null })}
                    handler={() => { this.deleteRequest(this.state.removeRequestModal); this.setState({ removeRequestModal: null }) }}
                >
                    {'Cancel request'.translate(this.props.lang)} <strong>{this.state.removeRequestModal && this.state.removeRequestModal.name}</strong>?
                </DeleteModal>
                <DeleteModal
                    lang={this.props.lang}
                    isOpen={this.state.deleteModal}
                    toggle={() => this.setState({ deleteModal: null })}
                    handler={() => { this.deletePatient(this.state.deleteModal); this.setState({ deleteModal: null }) }}
                >
                    {'Delete user'.translate(this.props.lang)} <strong>{this.state.deleteModal && this.state.deleteModal.name}</strong>?
                </DeleteModal>




            </div >
        );


    }
}
export default DeletionRequestsComponent;