import React, { Component } from 'react'
import Link from './sidebarLink';

import Isvg from 'react-inlinesvg'

import moment from 'moment'
import logoutIcon from '../assets/svg/sidebar-icons/logout-icon.svg'
import shapeBlue from '../assets/svg/shape-blue.svg'
import arrowSidebar from '../assets/images/down_white_arrow.png'
import logoIcon from '../assets/svg/logo_video_light.svg';
import menuIcon from '../assets/svg/menu-icon-cropped.svg';

import { API_ENDPOINT } from '../constants'
moment.locale('sv')


function iosDevice() {
  var userAgent = window.navigator.userAgent;
  if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
    return true;
  }
  else {
    return false;
  }
}


/**
* Sidebar component
* @author   Milan Stanojevic
*/
export class Sidebar extends Component {
  constructor(props) {
    super(props)

    this.menuToTop = React.createRef();
    this.linkRefs = {};

    this.state = {
      showData: false,
      showManagment: false,
      selectedMobileItems: {},
      showSelectGroup: false,
      showSelectClinic: false,
      unpaidCount: 0,
      invoicesRequested: 0,
      closeDropdown: false,
      showMenuMobile: false,
      windowWidth: typeof window != 'undefined' && window.innerWidth ? window.innerWidth : 0,
    }
  }

  scrollToElement = () => this.sidebarToTop.current.scrollIntoView();

  uDataCondition = (condition) => {
    let arr = condition.split('||');
    for (let i = 0; i < arr.length; i++) {
      if (this.props.uData[arr[i]]) {
        return true;
      }
    }

    return false;
  }

  clinicEntryModuleCondition = (group, condition) => {
    let arr = condition.split('||');
    for (let i = 0; i < arr.length; i++) {
      if (group[arr[i]]) {
        return true;
      }
    }

    return false;
  }

  componentDidUpdate(prevProps) {
    // console.log('prop', this.props[0].location.pathname);
    // console.log('state', this.state.activeScreen);
    // if (this.state.activeScreen !== this.props[0].location.pathname) {
    //   console.log('Not the same!');
    //   this.setState({activeScreen: this.props[0].location.pathname})
    // }
    // console.log(this.linkRefs);
    /*
    if (this.linkRefs[this.props[0].location.pathname] && (this.props.sidebarScrollTop === null || this.props.sidebarScrollTop === undefined)) {
      if (window.location.href == `${window.location.origin}/` || window.location.href == `${window.location.origin}/my-data/account`) {
        this.sidebarNode.scrollTop = 0;
        this.props.updateSidebarScrollTop(this.sidebarNode.scrollTop);



      } else {
        this.sidebarNode.scrollTop = this.linkRefs[this.props[0].location.pathname].offsetTop;
        this.props.updateSidebarScrollTop(this.sidebarNode.scrollTop);

      }

    }
    */

    if (this.props.uData && prevProps.uData && this.props.uData.lastNotifications != prevProps.uData.lastNotifications) {
      if (this.props.uData && this.props.uData.lastNotifications) {
        let count = 0
        for (let i = 0; i < this.props.uData.lastNotifications.length; i++) {
          if (!this.props.uData.lastNotifications[i].seen) {
            count++
          }
        }

        this.setState({
          notifCount: count
        })
      }
    }


    if (prevProps.lang != this.props.lang) {
      moment.locale(this.props.lang == 'se' ? 'sv' : 'en')
    }
  }
  check = () => {
    fetch(API_ENDPOINT + '/notification/last', {
      method: 'POST',
      headers: {
        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
        'content-type': 'application/json'
      },

    }).then(res => res.json()).then((result) => {

      if (result) {
        if (localStorage.getItem('notif') != result._id) {
          this.props.verifyUser()
          this.setState({
            newNotif: true
          })

        } else {
          this.setState({
            newNotif: false
          })
        }
      }

    })


  }


  componentDidMount() {
    window.addEventListener('resize', this.resizeWindow);

    this.menuToTop.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    })

    moment.locale(this.props.lang == 'se' ? 'sv' : 'en')


    if (this.props.sidebarScrollTop && this.sidebarNode) {
      if (window.location.href == `${window.location.origin}/` || window.location.href == `${window.location.origin}/my-data/account`) {
        this.sidebarNode.scrollTop = 0
      } else {
        this.sidebarNode.scrollTop = this.props.sidebarScrollTop;
      }


    }

    if (this.props.uData && this.props.uData.lastNotifications) {
      let count = 0
      for (let i = 0; i < this.props.uData.lastNotifications.length; i++) {
        if (!this.props.uData.lastNotifications[i].seen) {
          count++
        }
      }

      this.setState({
        notifCount: count
      })
    }

  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval)
    }

    window.removeEventListener('resize', this.resizeWindow);
  }

  resizeWindow = () => {
    let windowWidth = this.state.windowWidth;
    if (typeof window != 'undefined' && window.innerWidth && Math.abs(windowWidth - window.innerWidth) > 50) {
      this.setState({ windowWidth: window.innerWidth })
    }

  }

  setToLocalstorage = (alias) => {
    localStorage.setItem('groupAlias', alias)

    if (alias == -1) {
      localStorage.removeItem('groupAlias')
      this.props[0].history.push('/home-patient-level')
    } else {
      for (let i = 0; i < this.props.uData.patientGroups.length; i++) {
        if (alias == this.props.uData.patientGroups[i].alias) {
          this.props.verifyUser()
          this.props[0].history.push(`/${alias}`)
        }
      }
    }

  }

  executeScroll = () => {

    if (this.menuToTop && this.menuToTop.scrollHeight) {
      this.menuToTop.scrollTop = this.menuToTop.scrollHeight;
    }

  }


  render() {


    if (this.props.uData && this.props.uData.userLevel == 1) {
      return null;
    }

    if (this.props[0].match.path == '/chat/:windowConversation') {
      return null;
    }

    if (this.props[0].match.path == '/my-data/clinic-data/landing-configurator/:id/:tab/:group/:type')
      return null;
    if (this.props[0].match.path == '/data-managment/landing-configurator/:tab/:id/:type')
      return null;
    if (this.props[0].match.path == '/video-call/:conversation')
      return null;




    let isMobile = typeof window != 'undefined' && window.innerWidth < 768;
    return (
      <div className={this.props.sidebarWide ? 'sidebar sidebar-video active' : 'sidebar sidebar-video'} style={typeof window != 'undefined' && iosDevice() ? { maxWidth: `${window.innerWidth}px` } : {}}>
        {this.props.uData.userLevel <= 20 ?
          <div onClick={this.props.toggleSidebar} className='sidebar-cta'>
            <img src={arrowSidebar} />
          </div>
          : null}
        <div className="sidebar-container">
          <div className='sidebar-content'>

            <div className="sidebar-header">
              <Link to="/my-data/account" currentPathname={this.props[0].location.pathname}>
                <Isvg src={logoIcon} className="logo" />
              </Link>

              {
                typeof window != 'undefined' && window.innerWidth < 1199 ?

                  <Isvg className="menu-icon" src={menuIcon} onClick={() => {
                    this.setState({
                      showMenuMobile: !this.state.showMenuMobile
                    })
                  }} />

                  :

                  null
              }
            </div>
            <div className={this.state.showMenuMobile ? 'sidebar-body opened' : 'sidebar-body'} /*ref={
              (node) => {
                //this.sidebarNode = node
              }} 
              onScroll={() => this.props.updateSidebarScrollTop(this.sidebarNode.scrollTop)}*/>



              <ul className='menu'>

                {
                  this.props?.modulesTree.map((item, idx) => {


                    let subpaths = [];
                    for (let i = 0; i < item.modules.length; i++) {
                      let sub = item.modules[i];
                      if ((!sub.clinicEntryModule || (sub.clinicEntryModule && this.props.uData && this.props.uData.groupPermissions && this.props.uData.groupPermissions[this.props.selectedGroup] && this.props.uData.groupPermissions[this.props.selectedGroup][this.props.selectedClinic] && this.props.uData.groupPermissions[this.props.selectedGroup][this.props.selectedClinic].indexOf(sub._id) !== -1) &&
                        (!sub.clinicEntryModuleCondition ||
                          (
                            sub.clinicEntryModuleCondition &&
                            this.props.uData &&
                            this.props.uData.clinicGroups &&
                            this.props.uData.clinicGroups[selectedGroupIdx] &&
                            this.clinicEntryModuleCondition(this.props.uData.clinicGroups[selectedGroupIdx], sub.clinicEntryModuleCondition)
                          )
                        )) && (!sub.uDataCondition || (sub.uDataCondition && this.props.uData && this.uDataCondition(sub.uDataCondition)))) {

                        if ((sub.link && sub.link.indexOf(':alias') !== -1 && localStorage.getItem('groupAlias')) || (sub.link && sub.link.indexOf(':alias') == -1)) {
                          subpaths.push(sub.link && sub.link.indexOf(':alias') !== -1 ? sub.link.replace(':alias', localStorage.getItem('groupAlias')) : sub.link);
                        }
                      }
                    }
                    if ((!item.clinicEntryModule ||
                      (
                        item.clinicEntryModule &&
                        this.props.uData &&
                        this.props.uData.groupPermissions &&
                        this.props.uData.groupPermissions[this.props.selectedGroup] &&
                        this.props.uData.groupPermissions[this.props.selectedGroup][this.props.selectedClinic] &&
                        this.props.uData.groupPermissions[this.props.selectedGroup][this.props.selectedClinic].indexOf(item._id) !== -1 &&
                        (!item.clinicEntryModuleCondition ||
                          (
                            item.clinicEntryModuleCondition &&
                            this.props.uData &&
                            this.props.uData.clinicGroups &&
                            this.props.uData.clinicGroups[selectedGroupIdx] &&
                            this.clinicEntryModuleCondition(this.props.uData.clinicGroups[selectedGroupIdx], item.clinicEntryModuleCondition)
                          )
                        )
                      )) && (!item.uDataCondition || (item.uDataCondition && this.props.uData && this.uDataCondition(item.uDataCondition)))
                    )
                      return (
                        <li
                          onMouseEnter={(e) => {
                            this.setState({
                              tooltip: {
                                name: Object.translate(item, 'name', this.props.lang),
                                top: e.target.getBoundingClientRect().top + e.target.getBoundingClientRect().height / 2 - 10,
                                left: e.clientX
                              }
                            })
                          }} onMouseLeave={() => {
                            this.setState({
                              tooltip: null
                            })

                          }}
                          key={item.id} ref={(node) =>
                            this.linkRefs[subpaths.length ? subpaths[0] : item.link] = node} className={(((subpaths.length != 0 && subpaths.indexOf(this.props[0].location.pathname) !== -1) || (subpaths.length == 0 && item.link == this.props[0].location.pathname)) && !isMobile) || (this.state.selectedMobileItems[item._id] && isMobile) || (item.link == this.props[0].location.pathname && isMobile) || (this.state.openDropdown && this.state.openDropdown == idx) ? 'menu__item active' : 'menu__item'}>

                          {subpaths && subpaths.length ?
                            <a check={this.props.groupSettingsModal} openModal={(item) => this.props.openModal(item)} className='menu__link' onClick={(e) => {
                              this.props.registerLog(`Open module: ${item.name.en} / ${item.modules && item.modules[0] && item.modules[0].name ? item.modules[0].name.en : '-'}`);
                              if (isMobile && subpaths.length != 0) {
                                let selectedMobileItems = this.state.selectedMobileItems;
                                selectedMobileItems[item._id] = !selectedMobileItems[item._id];
                                this.setState({ selectedMobileItems });
                                e.preventDefault();

                                return false;
                              }
                              if (isMobile && item.link == this.props[0].location.pathname) {
                                this.props.toggleSidebar()
                              }


                              if (this.state.openDropdown && this.state.openDropdown == idx) {
                                this.setState({
                                  openDropdown: !this.state.openDropdown
                                })
                              } else {
                                if (!this.state.openDropdown && subpaths.indexOf(this.props[0].location.pathname) !== -1 && !isMobile) {
                                  this.setState({
                                    closeDropdown: !this.state.closeDropdown
                                  })
                                } else {
                                  this.setState({
                                    openDropdown: idx,
                                    closeDropdown: true
                                  })
                                }

                              }


                            }} >
                              <span>

                                {' '}
                                <Isvg src={API_ENDPOINT + item.svgIcon} className='header-icon' />{' '}
                                {item.link == `/notifications` && this.props.newNotif && this.state.notifCount ?
                                  <div className='notif-sidebar'></div>
                                  : null}
                                {item.link == `/data/contact` && this.props.uData && this.props.uData.unseenContacts ?
                                  <div className='notif-sidebar'></div>
                                  : null}
                                {item.link == `/support` && this.props.uData && this.props.uData.unseenSupport ?
                                  <div className='notif-sidebar'></div>
                                  : null}
                              </span>

                              {
                                item.link == `/notifications` && this.state.notifCount > 0 && this.props.newNotif ?
                                  <span className="menu__text">{Object.translate(item, 'name', this.props.lang)}{' ' + '(' + this.state.notifCount + ')'}</span>
                                  :
                                  item.link == `/data/contact` && this.props.uData && this.props.uData.unseenContacts ?
                                    <span className="menu__text">{Object.translate(item, 'name', this.props.lang)}{' ' + '(' + this.props.uData.unseenContacts + ')'}</span>
                                    :
                                    item.link == `/support` && this.props.uData && this.props.uData.unseenSupport ?
                                      <span className="menu__text">{Object.translate(item, 'name', this.props.lang)}{' ' + '(' + this.props.uData.unseenSupport + ')'}</span>
                                      :
                                      item.link == `/my-data/personal-referrals/my-payments` && this.state.unpaidCount && this.state.unpaidCount > 0 ?
                                        <span className="menu__text">{Object.translate(item, 'name', this.props.lang)}{' ' + '(' + this.state.unpaidCount + ')'}</span>
                                        :
                                        item.link == `/my-data/personal-referrals/payments` && this.state.invoicesRequested && this.state.invoicesRequested > 0 ?
                                          <span className="menu__text">{Object.translate(item, 'name', this.props.lang)}{' ' + '(' + this.state.invoicesRequested + ')'}</span>
                                          :
                                          <span className="menu__text">{Object.translate(item, 'name', this.props.lang)}</span>
                              }

                            </a>
                            :
                            <Link check={this.props.groupSettingsModal} openModal={(item) => this.props.openModal(item)} className='menu__link' onClick={(e) => {
                              this.props.registerLog(`Open module: ${item.name.en} / ${item.modules && item.modules[0] && item.modules[0].name ? item.modules[0].name.en : '-'}`);
                              if (isMobile && subpaths.length != 0) {
                                let selectedMobileItems = this.state.selectedMobileItems;
                                selectedMobileItems[item._id] = !selectedMobileItems[item._id];
                                this.setState({ selectedMobileItems });
                                e.preventDefault();

                                return false;
                              }
                              if (isMobile && item.link == this.props[0].location.pathname) {
                                this.props.toggleSidebar()
                              }

                            }} to={subpaths.length ? subpaths[0] : item.link} currentPathname={this.props[0].location.pathname}>
                              <span>

                                {' '}
                                <Isvg src={API_ENDPOINT + item.svgIcon} className='header-icon' />{' '}
                                {item.link == `/notifications` && this.props.newNotif && this.state.notifCount ?
                                  <div className='notif-sidebar'></div>
                                  : null}

                                {item.link == `/data/contact` && this.props.uData && this.props.uData.unseenContacts ?
                                  <div className='notif-sidebar'></div>
                                  : null}

                              </span>

                              {
                                item.link == `/notifications` && this.state.notifCount > 0 && this.props.newNotif ?
                                  <span className="menu__text">{Object.translate(item, 'name', this.props.lang)}{' ' + '(' + this.state.notifCount + ')'}</span>
                                  :
                                  item.link == `/data/contact` && this.props.uData && this.props.uData.unseenContacts ?
                                    <span className="menu__text">{Object.translate(item, 'name', this.props.lang)}{' ' + '(' + this.props.uData.unseenContacts + ')'}</span>
                                    :
                                    item.link == `/my-data/personal-referrals/my-payments` && this.state.unpaidCount && this.state.unpaidCount > 0 ?
                                      <span className="menu__text">{Object.translate(item, 'name', this.props.lang)}{' ' + '(' + this.state.unpaidCount + ')'}</span>
                                      :
                                      item.link == `/my-data/personal-referrals/payments` && this.state.invoicesRequested && this.state.invoicesRequested > 0 ?
                                        <span className="menu__text">{Object.translate(item, 'name', this.props.lang)}{' ' + '(' + this.state.invoicesRequested + ')'}</span>
                                        :
                                        <span className="menu__text">{Object.translate(item, 'name', this.props.lang)}</span>
                              }

                            </Link>
                          }
                          {item.modules && item.modules.length ?
                            <ul className={(this.state.openDropdown && this.state.openDropdown == idx) || (!this.state.openDropdown && !this.state.closeDropdown && subpaths.indexOf(this.props[0].location.pathname) !== -1 && !isMobile) || (!this.state.openDropdown && !this.state.closeDropdown && this.state.selectedMobileItems[item._id] && isMobile) ? 'dropdown show' : 'dropdown'}>
                              {
                                item.modules.map((sub, sidx) => {
                                  if ((!sub.clinicEntryModule || (sub.clinicEntryModule && this.props.uData && this.props.uData.groupPermissions && this.props.uData.groupPermissions[this.props.selectedGroup] && this.props.uData.groupPermissions[this.props.selectedGroup][this.props.selectedClinic] && this.props.uData.groupPermissions[this.props.selectedGroup][this.props.selectedClinic].indexOf(sub._id) !== -1) &&
                                    (!sub.clinicEntryModuleCondition ||
                                      (
                                        sub.clinicEntryModuleCondition &&
                                        this.props.uData &&
                                        this.props.uData.clinicGroups &&
                                        this.props.uData.clinicGroups[selectedGroupIdx] &&
                                        this.clinicEntryModuleCondition(this.props.uData.clinicGroups[selectedGroupIdx], sub.clinicEntryModuleCondition)
                                      )
                                    )) && (!sub.uDataCondition || (sub.uDataCondition && this.props.uData && this.uDataCondition(sub.uDataCondition)))) {

                                    if ((sub.link && sub.link.indexOf(':alias') !== -1 && localStorage.getItem('groupAlias')) || (sub.link && sub.link.indexOf(':alias') == -1))

                                      return (
                                        <li className={sub.link == this.props[0].location.pathname ? 'active' : ''} onClick={() => {
                                          if (isMobile && sub.link == this.props[0].location.pathname) {
                                            this.props.toggleSidebar()
                                          }



                                          this.props.registerLog(`Open module: ${item.name.en} / ${sub.name.en}`);

                                        }}> <Link check={this.props.groupSettingsModal} openModal={(item) => this.props.openModal(item)} to={sub.link && sub.link.indexOf(':alias') !== -1 ? sub.link.replace(':alias', localStorage.getItem('groupAlias')) : sub.link} currentPathname={this.props[0].location.pathname}>
                                            <span>
                                              {' '}
                                              <Isvg src={API_ENDPOINT + sub.svgIcon} className='header-icon' />{' '}
                                            </span>
                                            <span className="menu__text">{Object.translate(sub, 'name', this.props.lang)}</span>
                                          </Link> </li>

                                      )
                                  }
                                })
                              }

                            </ul>
                            :
                            null
                          }
                        </li>

                      )
                  })
                }


                <li className='menu__item'>
                  <div
                    onMouseEnter={(e) => {
                      this.setState({
                        tooltip: {
                          name: 'Logout'.translate(this.props.lang),
                          top: e.target.getBoundingClientRect().top + e.target.getBoundingClientRect().height / 2 - 10,
                          left: e.clientX
                        }
                      })
                    }} onMouseLeave={() => {
                      this.setState({
                        tooltip: null
                      })

                    }}

                    onClick={() => {
                      this.props.abortAction()
                      this.props.registerLog('Logged out');

                      this.props.signOut()


                    }} className='menu__link'>
                    <span>
                      {' '}
                      <Isvg src={logoutIcon} className='header-icon' />{' '}
                    </span>
                    <span className='menu__text' > {'Logout'.translate(this.props.lang)} </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <img src={shapeBlue} className='shape-sidebar' />
        </div>
        {this.state.tooltip && this.props.sidebarWide ?
          <div className='sidebar-tooltip' style={{ position: 'absolute', top: this.state.tooltip.top, left: 110 }}>{this.state.tooltip.name}</div>
          :
          null
        }
      </div >
    )
  }
}

export default Sidebar
