

import React, { Component } from 'react';
//import { Link, Redirect } from 'react-router-dom';
import Link from '../components/link';
import { connect } from 'react-redux';
import { API_ENDPOINT } from '../constants';
import Isvg from 'react-inlinesvg';
import Icon from '../assets/svg/Icon.svg'
import Logo from '../assets/images/CuroflowVideologo.png'
import eng from '../assets/svg/eng.svg'
import swe from '../assets/svg/swe.svg'

import {
    Container,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Modal,
    Input,
    Button,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem

} from 'reactstrap';


import bars from '../assets/svg/bars.svg';
function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

export class Nav extends Component {
    constructor(props) {
        super(props);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

        this.state = {
            mobileMenu: false,
            yScroll: 0,
        };
    }

    listenToScroll = () => {
        const yScroll =
            document.body.scrollTop || document.documentElement.scrollTop


        this.setState({
            yScroll: yScroll,
        })
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('scroll', this.listenToScroll)
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    logout = () => {
        localStorage.removeItem('token');
        window.location.reload(false);
    }

    checkIfHost = () => {
        // localStorage.setItem('checkHost', true)

        this.props[0].history.push('/login')
    }

    findConversation = (id) => {
        fetch(API_ENDPOINT + "/data/home-landing/conversation/" + id, {
            method: "GET",
            headers: {
                'content-type': 'application/json'
            }
        }).then(parseJSON).then(({ result, status }) => {
            if (result) {
                this.props[0].history.push(`/video-call/${result._id}?roomId=${result.roomId}`);
                // window.location.reload()
            }
        })
    }

    updateLanguage = (data) => {
        this.setState({
            loadLang: true
        }, () => {
            fetch(API_ENDPOINT + "/users/account/update-userdata", {
                method: "POST",
                headers: {
                    Authorization: typeof localStorage !== "undefined" ? `Bearer ${localStorage.getItem("authToken")}` : null,
                    "content-type": "application/json",
                },
                body: JSON.stringify(data),
            }).then(parseJSON).then(({ result, status }) => {
                if (result.error) {
                    this.setState({ error: result.error });
                } else {
                    this.setState({
                        message: result.message
                    });
                    this.props.verifyUser();
                }
            });
        }
        );
    };


    render() {
        return (
            <div className={this.state.yScroll > 5 ? 'nav nav-fixed' : 'nav'}>
                <Container className='home-navigation-header'>
                    <div className="nav-content">
                            <Link to="/" className="logo logo-take-space">
                                 <img src={Logo} />
                            </Link>
                        {
                            typeof window !== 'undefined' && window.innerWidth < 1199 ?
                                <Isvg src={bars} alt="bars" className="menu-icon" onClick={() => {
                                    this.setState({
                                        mobileMenu: !this.state.mobileMenu
                                    })
                                }} />
                                :
                                null
                        }
                        <ul className={this.state.mobileMenu ? 'nav-list nav-list-opened' : 'nav-list'}>
                            {/*
                            <li>

                                <Link to='/our-solution'>{'Our Solution'.translate(this.props.lang)}</Link>
                            </li>
                            <li>

                                <Link to="/about-us">{'About us'.translate(this.props.lang)}</Link>
                            </li>
                            */}
                            <li>
                                <Link key={this.props.lang} to='/plans-for-secure-video-meetings'>{'Choose plan'.translate(this.props.lang)}</Link>
                            </li>
                            <li>

                                <Link key={this.props.lang} to='/contact'>{'Contact us'.translate(this.props.lang)}</Link>
                            </li>
                            <li>

                                <Link to="/gdpr-compilance-in-secure-video-meetings">{'GDPR'.translate(this.props.lang)}</Link>
                            </li>


                            <li className="push-right">
                                {/*
                                <span className="transparent-container">
                                    <a onClick={() => {
                                        this.checkIfHost()
                                    }}>{'Host a Meeting'.translate(this.props.lang)}</a>
                                </span>
                                <span className="transparent-container">
                                    <a className="mobile-pt-0" onClick={() => {
                                        this.setState({
                                            joinConversationModal: true
                                        })
                                    }}>{'Join a Meeting'.translate(this.props.lang)}</a>
                                </span>
                                */}
                                {this.props.uData && this.props.uData._id ?
                                    <span>
                                        <Link to="/my-data/account" lang={this.props.lang}>{'My profile'.translate(this.props.lang)}</Link>
                                    </span>
                                    :
                                    <>
                                        <span>
                                            <Link to="/registration" className="create-acc" lang={this.props.lang}>{'Create account'.translate(this.props.lang)}</Link>
                                        </span>
                                        <span>
                                            <Link to="/login" style={{ backgroundColor: '#35DAC9', color: '#fff' }} className="login-link" lang={this.props.lang}>{'Log in'.translate(this.props.lang)}</Link>
                                        </span>
                                    </>
                                }
                                <span>
                                    <a onClick={() => this.setState({ joinConversationModal: true })} className="blue-bg" lang={this.props.lang}>{'Join a meeting'.translate(this.props.lang)}</a>
                                </span>
                                <span className="language">


                           <Input type='select' value={this.props.lang} onChange={(e) => {
                            this.props.setLang(e.target.value)
                            if(this.props.uData){
                                console.log('language update');
                                this.updateLanguage({ language: e.target.value });
                            }

                            this.setState({
                               mobileMenu:!this.state.mobileMenu 
                            })
                           }}>
                           <option value={'en'}>ENG</option>
                           <option value={'se'}>SWE</option>
                           </Input>
                            <Isvg src={Icon}/>
                                </span>
               

                                
                            </li>

                            {
                                typeof window !== 'undefined' && window.innerWidth < 768 ?

                                    <div className="nav-help mobile-nav-help">
                                        <span onClick={() => {
                                            let lang = this.props.lang == 'en' ? 'se' : 'en'
                                            this.props.setLang(lang);
                                        }}>{this.props.lang == 'en' ? 'Se' : 'Eng'}</span>
                                    </div>

                                    :

                                    null
                            }

                        </ul>
                    </div>
                </Container>

                {
                    this.state.joinConversationModal ?
                        <Modal isOpen={this.state.joinConversationModal} size="lg" centered>
                            <ModalHeader close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ joinConversationModal: !this.state.joinConversationModal })}>&times;</button>}>{'Enter meeting code'.translate(this.props.lang)}</ModalHeader>
                            <ModalBody>

                                <Input value={this.state.joinConversationIDNumber} placeholder={'Code'.translate(this.props.lang)} onChange={(e) => {

                                    this.setState({
                                        joinConversationIDNumber: e.target.value,
                                    })
                                }} />


                            </ModalBody>
                            <ModalFooter className='buttons-right-green'>

                                <Button onClick={() => {
                                    this.findConversation(this.state.joinConversationIDNumber)
                                }} >{'Join'.translate(this.props.lang)}</Button>

                                <Button onClick={() => this.setState({ joinConversationModal: !this.state.joinConversationModal })}>{'Close'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        :
                        null
                }
            </div>
        )
    }
}

export default Nav;
