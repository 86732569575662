import React, { Component } from 'react';
import Isvg from "react-inlinesvg";
import infoIcon from './../../../assets/svg/info.svg';
import { Field, reduxForm } from 'redux-form'

import BootstrapField from './field';
import OptionsField from './options';
import HTMLField from './html';
import Image from './image';
import Tags from './tags';
import EasySelect from './easySelect';
import Table from './table';
import Conditions from './conditions';
import Answers from './answers';
import Toggle from './toggle'

import ColorPicker from './colorpicker';

import Phone from './phone';
import File from '../../forms/fields/file'
import SocialSecurityNumber from '../../forms/fields/socialSecurityNumber';
import NetId from '../../forms/fields/netId'
import SmsName from '../../forms/fields/smsName'
import TextWithStyle from '../../forms/fields/textWithStyle'
import CustomCheckbox from '../../forms/fields/customCheckbox';
import ListCheckbox from '../../forms/fields/listCheckbox';
import Map from '../../forms/fields/map';
import HighlightsText from './highlightsText';
import TimeZonePicker from './timeZonePicker';
import SelectCountryOption from './selectCountryOption';



// import DatePicker from '../fields/datePicker';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';


/**
* Generate fields for forms
* @author   Milan Stanojevic
*/
const renderTagsField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
}) => (

    <Tags
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        {...input}
    />
)
const renderColorPicker = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    disabled
}) => (

    <ColorPicker
        placeholder={placeholder}
        disabled={disabled}
        label={label}
        errorText={touched && error}
        error={touched && error}
        {...input}
    />
)
const renderToggleField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
}) => (

    <Toggle
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        {...input}
    />
)
const renderMapField = ({
    input,
    label,
    meta: { touched, error },
    height
}) => (

    <Map
        label={label}
        height={height}
        errorText={touched && error}
        error={touched && error}
        {...input}
    />
)

const renderConditionsField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    questions,
}) => (

    <Conditions
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        questions={questions}
        {...input}
    />
)
const renderCustomCheckbox = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    questions,
    afterText,
    haveValidation,
    disabled
}) => (

    <CustomCheckbox
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        questions={questions}
        afterText={afterText}
        haveValidation={haveValidation}
        disabled={disabled}
        {...input}
    />
)


export const renderCustomListCheckbox = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type,
    children,
    haveValidation,
    optionValue,
    afterText,
    beforeText,
    multiple,
    disabled,
    displayFlex,
    disableFilter,
    values,
    lang
}) => (

    <ListCheckbox
        placeholder={placeholder}
        errorText={touched && error}
        label={label}
        error={touched && error}
        type={type}
        haveValidation={haveValidation}
        optionValue={optionValue}
        afterText={afterText}
        beforeText={beforeText}
        multiple={multiple}
        disabled={disabled}
        displayFlex={displayFlex}
        values={values}
        disableFilter={disableFilter}
        lang={lang}
        {...input}

    >{children}</ListCheckbox>
)
const renderAnswersField = ({
    input,
    placeholder,
    label,
    disablePrintableAnswer,
    meta: { touched, error },
}) => (

    <Answers
        disablePrintableAnswer={disablePrintableAnswer}
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        {...input}
    />
)


export const renderEasySelect = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type,
    children,
    haveValidation,
    optionValue,
    afterText,
    beforeText,
    multiple,
    disabled,
    disableFilter,
    values,
    lang
}) => (

    <EasySelect
        placeholder={placeholder}
        errorText={touched && error}
        label={label}
        error={touched && error}
        type={type}
        haveValidation={haveValidation}
        optionValue={optionValue}
        afterText={afterText}
        beforeText={beforeText}
        multiple={multiple}
        disabled={disabled}
        values={values}
        disableFilter={disableFilter}
        lang={lang}
        {...input}

    >{children}</EasySelect>
)

export const renderTable = ({
    input,
    label,
    meta: { touched, error },
    type,
    children,
    actions,
    fields,
    values
}) => (

    <Table
        label={label}
        values={values}
        actions={actions}
        fields={fields}

        {...input}

    ></Table>
)


export const renderField = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type,
    children,
    haveValidation,
    optionValue,
    afterText,
    beforeText,
    multiple,
    disabled,
    autoComplete,
    multilang,
    pattern,
    minValue,
    errorMessage,
    login,
    lang,
    labelInfo,
    className
}) => (

    <BootstrapField
        placeholder={placeholder}
        errorText={touched && error}
        label={label}
        error={touched && error}
        type={type}
        haveValidation={haveValidation}
        optionValue={optionValue}
        labelInfo={labelInfo}
        afterText={afterText}
        beforeText={beforeText}
        multiple={multiple}
        disabled={disabled}
        minValue={minValue}
        multilang={multilang}
        errorMessage={errorMessage}
        lang={lang}
        login={login}
        autoComplete={autoComplete}
        pattern={pattern}
        className={className}
        {...input}

    >{children}</BootstrapField>
)

export const renderSocialSecurityNumber = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type,
    haveValidation,
    disabled,
    lang
}) => (

    <SocialSecurityNumber
        placeholder={placeholder}
        errorText={touched && error}
        label={label}
        error={touched && error}
        type={type}
        haveValidation={haveValidation}
        disabled={disabled}
        lang={lang}
        {...input}

    ></SocialSecurityNumber>
)
export const renderSmsName = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type,
    haveValidation,
    disabled,
    lang
}) => (

    <SmsName
        placeholder={placeholder}
        errorText={touched && error}
        label={label}
        error={touched && error}
        type={type}
        haveValidation={haveValidation}
        disabled={disabled}
        lang={lang}
        {...input}

    ></SmsName>
)
export const renderHighlihtsText = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type,
    haveValidation,
    disabled,
    lang
}) => (

    <HighlightsText
        placeholder={placeholder}
        errorText={touched && error}
        label={label}
        error={touched && error}
        type={type}
        haveValidation={haveValidation}
        disabled={disabled}
        lang={lang}
        {...input}

    ></HighlightsText>
)
export const renderTextWithStyle = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type,
    haveValidation,
    disabled,
    lang
}) => (

    <TextWithStyle
        placeholder={placeholder}
        errorText={touched && error}
        label={label}
        error={touched && error}
        type={type}
        haveValidation={haveValidation}
        disabled={disabled}
        lang={lang}
        {...input}

    ></TextWithStyle>
)

export const renderNetId = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type,
    haveValidation,
    disabled,
    lang
}) => (

    <NetId
        placeholder={placeholder}
        errorText={touched && error}
        label={label}
        error={touched && error}
        type={type}
        haveValidation={haveValidation}
        disabled={disabled}
        lang={lang}
        {...input}

    ></NetId>
)



export const renderPhone = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type,
    children,
    haveValidation,
    disabled,
    multilang,
    lang
}) => (

    <Phone
        placeholder={placeholder}
        errorText={touched && error}
        label={label}
        error={touched && error}
        type={type}
        haveValidation={haveValidation}
        disabled={disabled}
        multilang={multilang}
        lang={lang}
        {...input}

    >{children}</Phone>
)


export const renderOptionsField = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type,
    haveValidation,
    disabled,
    values
}) => (

    <OptionsField
        placeholder={placeholder}
        errorText={touched && error}
        label={label}
        error={touched && error}
        type={type}
        haveValidation={haveValidation}
        disabled={disabled}
        values={values}
        {...input}

    ></OptionsField>
)

export const renderTimeZonePickerField = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type,
    haveValidation,
    disabled,
    values
}) => (

    <TimeZonePicker
        placeholder={placeholder}
        errorText={touched && error}
        label={label}
        error={touched && error}
        type={type}
        haveValidation={haveValidation}
        disabled={disabled}
        values={values}
        {...input}

    ></TimeZonePicker>
)


export const renderSelectCountryOptionField = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type,
    haveValidation,
    disabled,
    lang,
    values
}) => (

    <SelectCountryOption
        placeholder={placeholder}
        errorText={touched && error}
        label={label}
        error={touched && error}
        type={type}
        haveValidation={haveValidation}
        disabled={disabled}
        values={values}
        lang={lang}
        {...input}

    ></SelectCountryOption>
)

export const renderHTMLField = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type,
    haveValidation,
    disabled,
    lang,
    multilang,
    name
}) => (

    <HTMLField
        placeholder={placeholder}
        disabled={disabled}
        errorText={touched && error}
        label={label}
        error={touched && error}
        type={type}
        name={name}
        multilang={multilang}
        lang={lang}
        haveValidation={haveValidation}
        {...input}

    ></HTMLField>
)


export const renderImageField = ({
    input,
    label,
    description,
    height,
    group,
    meta: { touched, error },
    lang
}) => (

    <Image
        label={label}
        description={description}
        errorText={touched && error}
        error={touched && error}
        height={height}
        group={group}
        lang={lang}
        {...input}
    />
)

export const renderFileField = ({
    input,
    label,
    height,
    meta: { touched, error },
    lang,
    group,
    haveValidation
}) => (

    <File
        label={label}
        errorText={touched && error}
        error={touched && error}
        height={height}
        group={group}
        lang={lang}
        haveValidation={haveValidation}
        {...input}
    />
)


export const renderFields = (fields, lang = 'en') => {
    return fields && fields.map((item, idx) => {
        if (!item) {
            return null;
        }

        if (item.type == 'col') {
            return (
                <Col lg={item.width.lg} sm={item.width.sm} xs={item.width.xs}>
                    {item.children && item.children.length ? renderFields(item.children) : null}
                </Col>
            )
        } else if (item.type == 'row') {
            return (
                <Row>
                    {item.children && item.children.length ? renderFields(item.children) : null}
                </Row>
            )
        } else if (item.type == 'h6') {
            return (
                <h6 style={{ fontSize: 18 }}>{item.text}</h6>
            )
        } else if (item.type == 'h5') {
            return (
                <h5>{item.text}</h5>
            )
        } else if (item.type == 'h4') {
            return (
                <h4>{item.text}</h4>
            )
        } else if (item.type == 'empty') {
            return (
                <></>
            )
        } else if (item.type == 'color-picker') {
            return (
                <Field
                    name={item.name}
                    component={renderColorPicker}
                    label={item.label}
                    validate={item.validate}
                    haveValidation={item.validate && item.validate.length ? true : false}
                    disabled={item.disabled}
                    values={item.values}
                ></Field>

            )
        } else if (item.type == 'options') {
            return (
                <Field
                    name={item.name}
                    component={renderOptionsField}
                    validate={item.validate}
                    haveValidation={item.validate && item.validate.length ? true : false}
                    disabled={item.disabled}
                    values={item.values}
                ></Field>

            )
        } else if (item.type == 'html') {
            return (
                <Field
                    name={item.name}
                    label={item.label}
                    component={renderHTMLField}
                    validate={item.validate}
                    disabled={item.disabled}
                    multilang={item.multilang}
                    lang={item.lang}
                    haveValidation={item.validate && item.validate.length ? true : false}
                ></Field>

            )
        } else if (item.type == 'image') {
            return (
                <Field
                    name={item.name}
                    label={item.label}
                    group={item.group}
                    description={item.description}
                    height={item.height}
                    component={renderImageField}
                    validate={item.validate}
                    haveValidation={item.validate && item.validate.length ? true : false}
                    lang={item.lang ? item.lang : lang}
                ></Field>

            )
        } else if (item.type == 'file') {
            return (
                <Field
                    name={item.name}
                    label={item.label}
                    height={item.height}
                    group={item.group}
                    component={renderFileField}
                    validate={item.validate}
                    haveValidation={item.validate && item.validate.length ? true : false}
                    lang={item.lang ? item.lang : lang}
                ></Field>

            )
        } else if (item.type == 'checkbox') {
            return (
                <Field
                    name={item.name}
                    label={item.label}
                    height={item.height}
                    component={renderCustomCheckbox}
                    validate={item.validate}
                    disabled={item.disabled}
                    afterText={item.afterText}
                    haveValidation={item.validate && item.validate.length ? true : false}
                    lang={lang}
                ></Field>

            )
        } else if (item.type == 'map') {
            return (
                <Field
                    name={item.name}
                    label={item.label}
                    height={item.height}
                    component={renderMapField}
                    validate={item.validate}
                    disabled={item.disabled}
                    lang={lang}
                ></Field>

            )
        } else if (item.type == 'list-checkbox') {
            return (
                <Field
                    name={item.name}
                    component={renderCustomListCheckbox}
                    label={item.label}
                    placeholder={item.placeholder}
                    validate={item.validate}
                    type={'select'}
                    displayFlex={item.displayFlex}
                    haveValidation={item.validate && item.validate.length ? true : false}
                    multiple={item.multiple}
                    disabled={item.disabled}
                    disableFilter={item.disableFilter}
                    values={item.values}
                    lang={item.lang ? item.lang : lang}
                >

                </Field>
            )
        } else if (item.type == 'tags') {
            return (
                <Field
                    name={item.name}
                    label={item.label}
                    component={renderTagsField}
                    validate={item.validate}
                    haveValidation={item.validate && item.validate.length ? true : false}
                ></Field>

            )
        } else if (item.type == 'conditions') {
            return (
                <Field
                    name={item.name}
                    label={item.label}
                    component={renderConditionsField}
                    validate={item.validate}
                    haveValidation={item.validate && item.validate.length ? true : false}
                    questions={item.questions}
                ></Field>

            )
        } else if (item.type == 'answers') {
            return (
                <Field
                    name={item.name}
                    label={item.label}
                    component={renderAnswersField}
                    disablePrintableAnswer={item.disablePrintableAnswer}
                    validate={item.validate}
                    haveValidation={item.validate && item.validate.length ? true : false}
                ></Field>

            )
        } else if (item.type == 'easy-select') {
            return (
                <Field
                    name={item.name}
                    component={renderEasySelect}
                    label={item.label}
                    placeholder={item.placeholder}
                    validate={item.validate}
                    type={'select'}
                    haveValidation={item.validate && item.validate.length ? true : false}
                    multiple={item.multiple}
                    disabled={item.disabled}
                    disableFilter={item.disableFilter}
                    values={item.values}
                    lang={item.lang ? item.lang : lang}
                >

                </Field>
            )
        } else if (item.type == 'table') {
            return (
                <Field
                    name={item.name}
                    component={renderTable}
                    label={item.label}
                    values={item.values}
                    fields={item.fields}
                    actions={item.actions}
                ></Field>
            )
        } else if (item.type == 'phone') {
            return (
                <Field
                    name={item.name}
                    component={renderPhone}
                    label={item.label}
                    validate={item.validate}
                    haveValidation={item.validate && item.validate.length ? true : false}
                    disabled={item.disabled}
                ></Field>
            )
        } else if (item.type == 'timeZonePicker') {
            return (
                <Field
                    name={item.name}
                    component={renderTimeZonePickerField}
                    label={item.label}
                    validate={item.validate}
                    haveValidation={item.validate && item.validate.length ? true : false}
                    disabled={item.disabled}
                ></Field>
            )
        }
        else if (item.type == 'selectCountryOption') {
            return (
                <Field
                    name={item.name}
                    component={renderSelectCountryOptionField}
                    label={item.label}
                    validate={item.validate}
                    haveValidation={item.validate && item.validate.length ? true : false}
                    disabled={item.disabled}
                    lang={item.lang}
                ></Field>
            )
        }
        else if (item.type == 'socialSecurityNumber') {
            return (
                <Field
                    name={item.name}
                    component={renderSocialSecurityNumber}
                    placeholder={item.placeholder}
                    validate={item.validate}
                    haveValidation={item.validate && item.validate.length ? true : false}
                    label={item.label}
                    disabled={item.disabled}
                ></Field>
            )
        } else if (item.type == 'smsName') {
            return (
                <Field
                    name={item.name}
                    component={renderSmsName}
                    placeholder={item.placeholder}
                    validate={item.validate}
                    haveValidation={item.validate && item.validate.length ? true : false}
                    label={item.label}
                    disabled={item.disabled}
                ></Field>
            )
        }
        else if (item.type == 'highlightText') {
            return (
                <Field
                    name={item.name}
                    component={renderHighlihtsText}
                    placeholder={item.placeholder}
                    validate={item.validate}
                    haveValidation={item.validate && item.validate.length ? true : false}
                    label={item.label}
                    disabled={item.disabled}
                ></Field>
            )
        }
        else if (item.type == 'switch') {
            return (
                <Field
                    name={item.name}
                    label={item.label}
                    component={renderToggleField}
                    validate={item.validate}
                    haveValidation={item.validate && item.validate.length ? true : false}
                ></Field>

            )
        } else if (item.type == 'textWithStyle') {
            return (
                <Field
                    name={item.name}
                    component={renderTextWithStyle}
                    placeholder={item.placeholder}
                    validate={item.validate}
                    haveValidation={item.validate && item.validate.length ? true : false}
                    label={item.label}
                    disabled={item.disabled}
                ></Field>
            )
        } else if (item.type == 'netId') {
            return (
                <Field
                    name={item.name}
                    component={renderNetId}
                    placeholder={item.placeholder}
                    validate={item.validate}
                    haveValidation={item.validate && item.validate.length ? true : false}
                    label={item.label}
                    disabled={item.disabled}
                ></Field>
            )
        } else {
            if (item.label == 'VAT Number')
                console.log(item)
            return (
                <Field
                    name={item.name}
                    component={renderField}
                    label={item.label}
                    placeholder={item.placeholder}
                    validate={item.validate}
                    type={item.type}
                    haveValidation={item.hideValidation ? false : item.validate && item.validate.length ? true : false}
                    optionValue={item.optionValue}
                    afterText={item.afterText}
                    beforeText={item.beforeText}
                    multiple={item.multiple}
                    disabled={item.disabled}
                    multilang={item.multilang}
                    errorMessage={item.errorMessage}
                    lang={item.lang}
                    login={item.login}
                    labelInfo={item.labelInfo}
                    autoComplete={item.autoComplete}
                    pattern={item.pattern}
                    minValue={item.minValue}
                    className={item.className}
                >
                    {item.values && item.values.length ? item.values.map((option, oidx) => {
                        return (
                            <option key={oidx} value={option.value}>{option.name}</option>
                        )
                    })
                        :
                        null
                    }

                </Field>
            )
        }

    })
}
