import React, { Component } from 'react';
import { API_ENDPOINT } from '../constants';
/**
* Payson transaction check component 
* @author   Stefan Pantic
*/
class PaymentPage extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        let key = this.props[0].match.params.id;
        // console.log(key)
        console.log(this.props[0].location.pathname)
        fetch(API_ENDPOINT + '/recurring-payments/notification/' + key, {
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            },
        }).then(res => res.json()).then((result) => {
            if (result.error) {
                this.setState({
                    error: result.error
                })
            } else {
                this.props.verifyUser(() => {
                    this.props[0].history.push({
                        pathname: '/users/buy-packet',
                        state: {
                            cfOrder: true,
                            step: 4
                        }
                    })
                });



            }


        });
    }
    render() {
        return (
            <h1>

            </h1>
        );
    }
}

export default PaymentPage;