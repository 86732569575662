import React, { Component } from 'react';
import Page from '../../containers/page';
import { API_ENDPOINT } from '../../constants';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import warrning from '../../assets/svg/warrning.svg'
function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

/**
* List of meetings
* @author   Pantelija Simeunovic
*/
class UserVerify extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            params: {}
        };

    }


    get = () => {

    }

    componentDidMount() {
        // console.log(this.props[0].match.params.id);
        if (this.props[0].match.params.id)
            fetch(API_ENDPOINT + '/users/registration/verify/user/' + this.props[0].match.params.id, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ join: this.props[0].match.params.join })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300) {

                    localStorage.setItem("authToken", result.token);
                    localStorage.setItem('loginMethod', 'code');
                    localStorage.setItem("profile", "doctor");
                    localStorage.setItem("time", Math.floor(Date.now() / 1000));
                    this.props.invationRegistration(true)
                    this.props.registerLog("Logged in");
                    this.props.verifyUser(() => {

                        // if (typeof window != 'undefined' && (localStorage.getItem('groupAlias') == null || localStorage.getItem('groupAlias') == 'undefined')) {
                        //     if (this.props.uData && this.props.uData.userLevel == 20 && this.props.uData.clinicGroups && this.props.uData.clinicGroups.length) {
                        //         localStorage.setItem('groupAlias', this.props.uData.clinicGroups[0].alias)
                        //     }
                        // }
                        // console.log("ulazi");
                        if (this.props[0].match.params.join.toString().length > 5) {
                            // console.log( this.props[0].match.params,"aaaaaa");
                            this.props[0].history.push({
                                pathname: '/my-data/account',
                                state: {
                                    successModal: 'You have joined the list successfully'
                                }
                            });
                        } else {
                            // console.log( this.props[0].match.params,"ffff");
                            this.props[0].history.push({
                                pathname: '/my-data/account',
                                state: {
                                    successModal: false
                                }
                            });
                        }

                    });
                } else {
                    // this.props[0].history.push('/login')
                    this.setState({
                        error: 'Invitation link expired!'.translate(this.props.lang)
                    })
                }

            })

    }
    componentWillUnmount() {

    }

    componentDidUpdate(prevProps) {


    }

    render() {
        return (
            this.state.error ?
                <div className='registration-error-screen'>
                    <img src={warrning} />
                    <h2>{'Information'.translate(this.props.lang)}</h2>
                    <div>{'Invitation link expired!'.translate(this.props.lang)}</div>
                    <div>
                        <Button onClick={() => {
                            this.props[0].history.push('/registration')
                        }}>{'Re-register'.translate(this.props.lang)}</Button>
                        <Button color='primary' onClick={() => {
                            this.props[0].history.push('/')
                        }}>{'Close'.translate(this.props.lang)}</Button>
                    </div>
                    {/* {
                    this.state.error ?
                        <Modal isOpen={this.state.error} centered>
                            <ModalHeader>
                                {'Information'.translate(this.props.lang)}
                            </ModalHeader>
                            <ModalBody>
                                {'Invitation link expired!'.translate(this.props.lang)}
                            </ModalBody>
                            <ModalFooter>
                                <Button onClick={() => {
                                    this.props[0].history.push('/registration')
                                }}>{'Re-register'.translate(this.props.lang)}</Button>
                                <Button onClick={() => {
                                    this.props[0].history.push('/')
                                }}>{'Close'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        :
                        null
                } */}
                </div>
                : null

        );

    }






}

export default Page(UserVerify);