import React, { Component } from "react";
import Page from "../../containers/page";
import {
    Container,
    Row,
    Col, Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Input
} from "reactstrap";
import Isvg from 'react-inlinesvg'
import garabage from '../../assets/svg/garbage.svg'
import addIcon from '../../assets/svg/add-icon.svg';
import {
    getSearchParams,
} from "../../helpers/linkHelper";
import { API_ENDPOINT } from "../../constants";

function parseJSON(response) {
    return response.json().then((json) => {
        return {
            result: json,
            status: response.status,
        };
    });
}

function parseText(response) {
    return response.text().then(text => {
        return {
            result: text,
            status: response.status
        }
    })
}

class Licence extends Component {
    constructor(props) {
        super(props);
        this.player = React.createRef();
        this.player2 = React.createRef();
        this.state = {
            showPlayer: false,
            showPlayer2: false,
            ...props.initialData,

            params: {
                entries: 10,
                page: 0,
                "query-group": null,
            },
            libraryIcons: []
        };
    }

    showPlayer = () => {
        this.setState({
            showPlayer: true,
        });
    };

    hidePlayer = () => {
        this.setState({
            showPlayer: false,
        });
    };

    get = () => {
        this.props.updateMeta(
            this.props.generateSeoTags(this.state, this.props.lang)
        );

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
                this.state.useState
                    ? this.state.params
                    : getSearchParams(this.props[0].location.search, {
                        entries: 10,
                        page: 0,
                        "query-group": null,
                    })
            ).then((data) => {
                this.setState(
                    {
                        ...data,
                        loading: null,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        );
                    }
                );
            });
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
                this.state.useState
                    ? this.state.params
                    : getSearchParams(this.props[0].location.search, {
                        entries: 10,
                        page: 0,
                        "query-group": null,
                    })
            ).then((data) => {
                this.setState(
                    {
                        ...data,
                        loading: null,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        );
                    }
                );
            });
        }

        this.setState({ loadLibrary: true }, () => {
            fetch(API_ENDPOINT + '/data-managment/background-video/images', {
                method: 'GET',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300) {
                    if (result && result.gallery) {
                        this.setState({
                            libraryIcons: result.gallery,
                            loadLibrary: false
                        })
                    } else {
                        this.setState({
                            libraryIcons: [],
                            loadLibrary: false
                        })
                    }


                }

            })
        })

        this.setState({ loadLibrary: true }, () => {
            fetch(API_ENDPOINT + '/data-managment/background-video/images-mobile', {
                method: 'GET',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300) {
                    if (result && result.gallery) {
                        this.setState({
                            libraryIconsMobile: result.gallery,
                            loadLibrary: false
                        })
                    } else {
                        this.setState({
                            libraryIconsMobile: [],
                            loadLibrary: false
                        })
                    }


                }

            })
        })
    };

    componentDidMount() {
        if (typeof window !== "undefined") {
            window.scrollTo(0, 0);
        }

        this.get();

    }

    stopAnimation = () => {
        this.player.current.play();
    };

    deleteIcon = (idx) => {
        this.setState({ loadLibrary: true }, () => {
            let gallery = this.state.libraryIcons;
            let newGallery = [];
            if (gallery && gallery.length) {
                for (let i = 0; i < gallery.length; i++) {
                    if (i != idx) {
                        newGallery.push(gallery[i]);
                    }
                }
            }

            fetch(API_ENDPOINT + '/data-managment/background-video/images', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ newGallery: newGallery, deleteItem: true })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300) {
                    this.setState({ loadLibrary: false })
                    this.get()

                }

            })
        })

    }
    uploadImage = (e) => {
        let input = e.target;
        if (input.files && input.files[0]) {

            let fsize = input.files[0].size;
            let file = Math.round((fsize / 1024))
            this.setState({
                uploadTimestamp: Math.floor(new Date().getTime() / 1000)
            })
            if (file > 10240) {
                this.setState({ error: "File size limit has been reached! (max 10 MB)".translate(this.props.lang) })
            } else {
                let formData = new FormData();
                formData.append('file', input.files[0]);

                fetch(API_ENDPOINT + '/data/upload-image', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,

                    },
                    body: formData
                }).then((res) => res.text()).then((img) => {
                    this.setState({
                        image: img
                    }, () => this.updateLibrary())


                });
            }
        }
    }
    updateLibrary = () => {
        this.setState({ loadLibrary: true }, () => {
            fetch(API_ENDPOINT + '/data-managment/background-video/images', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ image: this.state.image })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300) {
                    this.setState({
                        image: null,
                        loadLibrary: false
                    }, () => this.get())

                }

            })
        })

    }
    deleteIconMobile = (idx) => {
        this.setState({ loadLibrary: true }, () => {
            let gallery = this.state.libraryIconsMobile;
            let newGallery = [];
            if (gallery && gallery.length) {
                for (let i = 0; i < gallery.length; i++) {
                    if (i != idx) {
                        newGallery.push(gallery[i]);
                    }
                }
            }

            fetch(API_ENDPOINT + '/data-managment/background-video/images-mobile', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ newGallery: newGallery, deleteItem: true })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300) {
                    this.setState({ loadLibrary: false })
                    this.get()

                }

            })
        })

    }
    uploadImageMobile = (e) => {
        let input = e.target;
        if (input.files && input.files[0]) {

            let fsize = input.files[0].size;
            let file = Math.round((fsize / 1024))
            this.setState({
                uploadTimestampMobile: Math.floor(new Date().getTime() / 1000)
            })
            if (file > 10240) {
                this.setState({ error: "File size limit has been reached! (max 10 MB)".translate(this.props.lang) })
            } else {
                let formData = new FormData();
                formData.append('file', input.files[0]);

                fetch(API_ENDPOINT + '/data/upload-image', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,

                    },
                    body: formData
                }).then((res) => res.text()).then((img) => {
                    this.setState({
                        image: img
                    }, () => this.updateLibraryMobile())


                });
            }
        }
    }
    updateLibraryMobile = () => {
        this.setState({ loadLibrary: true }, () => {
            fetch(API_ENDPOINT + '/data-managment/background-video/images-mobile', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ image: this.state.image })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300) {
                    this.setState({
                        image: null,
                        loadLibrary: false
                    }, () => this.get())

                }

            })
        })

    }
    render() {
        return (
            <div className="dashboard">
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className='panel'>
                                {
                                    <div className="licence-table-container">
                                        <h5 className="component-header">
                                            {this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ""}
                                        </h5>

                                    </div>
                                }
                                <input ref={(node) => this.imageInput = node} type="file" accept="image/png, image/jpeg, image/jpg, image/gif, image/svg" key={this.state.uploadTimestamp} onChange={this.uploadImage} style={{ pointerEvents: 'none', opacity: 0 }} />

                                <div className="icon-library-wrap">
                                    <h4>{'Desktop background images'.translate(this.props.lang)}</h4>
                                    {
                                        this.state.libraryIcons && this.state.libraryIcons.length ?
                                            <div className="icon-items">
                                                {
                                                    this.state.libraryIcons && this.state.libraryIcons.map((icon, idx) => {
                                                        return (
                                                            <div className="icon-item">
                                                                <img src={API_ENDPOINT + icon} />
                                                                <div className="remove-image" onClick={() => this.deleteIcon(idx)}>
                                                                    <Isvg src={garabage} />
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }

                                            </div>
                                            :
                                            <div className="empty-array">
                                                <h6>{'No icons uploaded'.translate(this.props.lang)}</h6>
                                            </div>

                                    }
                                </div>

                                <Button onClick={() => {
                                    if (this.imageInput) this.imageInput.click()
                                }}><Isvg src={addIcon} /> {'Add'.translate(this.props.lang)}</Button>


                                <input ref={(node) => this.imageInputMobile = node} type="file" accept="image/png, image/jpeg, image/jpg, image/gif, image/svg" key={this.state.uploadTimestampMobile} onChange={this.uploadImageMobile} style={{ pointerEvents: 'none', opacity: 0 }} />

                                <div className="icon-library-wrap">
                                    <h4>{'Mobile background images'.translate(this.props.lang)}</h4>
                                    {
                                        this.state.libraryIconsMobile && this.state.libraryIconsMobile.length ?
                                            <div className="icon-items">
                                                {
                                                    this.state.libraryIconsMobile && this.state.libraryIconsMobile.map((icon, idx) => {
                                                        return (
                                                            <div className="icon-item">
                                                                <img src={API_ENDPOINT + icon} />
                                                                <div className="remove-image" onClick={() => this.deleteIconMobile(idx)}>
                                                                    <Isvg src={garabage} />
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }

                                            </div>
                                            :
                                            <div className="empty-array">
                                                <h6>{'No icons uploaded'.translate(this.props.lang)}</h6>
                                            </div>

                                    }
                                </div>

                                <Button onClick={() => {
                                    if (this.imageInputMobile) this.imageInputMobile.click()
                                }}><Isvg src={addIcon} /> {'Add'.translate(this.props.lang)}</Button>
                            </div>
                        </Col>
                    </Row>


                </Container>

            </div>

        );
    }
}


export default Page(Licence);
