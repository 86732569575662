import React, { Component } from "react";
import Page from "../../containers/page";

import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Input, Label, Table
} from "reactstrap";

import FormBuilder from '../../components/forms/modalFormBuilder';
import ListBuilder from '../../components/listBuilder';
import { required } from "../../components/forms/validation";
import DeleteModal from '../../components/deleteModal';

import Isvg from 'react-inlinesvg'
import addIcon from '../../assets/svg/add-icon.svg';
import edit from '../../assets/svg/edit.svg';
import eye from '../../assets/svg/eye.svg';
import garabage from '../../assets/svg/garbage.svg';
import moment from 'moment';
import Checkbox from '../../components/forms/fields/checkbox';
import TimeZonePicker from '../../components/forms/fields/timeZonePicker';
import DatePicker from '../../components/forms/fields/datePicker';
import TimePicker from '../../components/forms/fields/timepicker';
import striptags from 'striptags'
import ChatIcon from '../../assets/svg/call.svg';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import { API_ENDPOINT } from '../../constants';
import momentTimezone from 'moment-timezone';

import { getTimezone } from 'countries-and-timezones';

import {
    getSearchParams,
    generateSearchLink,
    generateSearchLinkMultiple,
} from "../../helpers/linkHelper";
import ErrorModal from "../../components/errorModal";

function parseJSON(response) {
    return response.json().then((json) => {
        return {
            result: json,
            status: response.status,
        };
    });
}

function parseText(response) {
    return response.text().then(text => {
        return {
            result: text,
            status: response.status
        }
    })
}

function getStartTS(date) {
    let start = null;
    let checkDate = new Date(date);
    checkDate.setHours(0, 0, 0, 0)

    let timestamp = Math.floor(new Date(checkDate).getTime() / 1000)
    if (timestamp) {
        start = timestamp;
    }

    return start;
}
function getEndTS(date) {
    let start = null;
    let checkDate = new Date(date);
    checkDate.setHours(23, 59, 59, 59)

    let timestamp = Math.floor(new Date(checkDate).getTime() / 1000)
    if (timestamp) {
        start = timestamp;
    }

    return start;
}

function convertFrom24To12Format(time24) {
    if (time24) {
        let time = moment.unix(time24).format('hh:mm')
        const [sHours, minutes] = time.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
        const period = +sHours < 12 ? 'AM' : 'PM';
        const hours = +sHours % 12 || 12;
        return `${hours}:${minutes} `;
    }
}

// function convertFrom24To12Format(time24){
//     if (time24) {
//         let sHours = time24.split(":")[0]
//         let minutes = time24.split(":")[1]
//         // const [sHours, minutes] = time24.match(timeHs:timeMin).slice(1);
//         const period = +sHours < 12 ? 'AM' : 'PM';
//         const hours = +sHours % 12 || 12;

//         return `${hours}:${minutes}`;
//     }
// }

function convertFrom24To12FormatPeriod(time24) {
    if (time24) {
        let sHours = time24.split(":")[0]
        let minutes = time24.split(":")[1]
        // const [sHours, minutes] = time24.match(timeHs:timeMin).slice(1);
        const period = +sHours < 12 ? 'AM' : 'PM';
        const hours = +sHours % 12 || 12;

        return `${hours}:${minutes} ${period}`;
    }
}


class Licence extends Component {
    constructor(props) {
        super(props);
        this.player = React.createRef();
        this.player2 = React.createRef();
        this.state = {
            showPlayer: false,
            showPlayer2: false,
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0,
                "query-group": null,
            },
            items: [],
            total: 0,
            loading: true,
            groups: [],
            languages: [],
            clinicIdx: 0,
            showForm: false,
            checkDate: new Date(),
            monthView: false,
            //activeBGHost: 'transparent',
            //activeBG: 'transparent',
            activeBGHost: 'rgb(97, 255, 168)',
            activeBg: 'rgb(252, 250, 76)',
            inTime: 'rgb(89, 189, 134)',
            meetings: false,
            expiredMeetings: false
        };

        this.state.min = new Date();
        this.state.min.setHours(0, 0, 0)
        this.state.max = new Date();
        this.state.max.setHours(23, 59, 59)

        this.state.scheduledCheckTime = new Date()
        this.state.scheduledCheckTime.getTime() - 15 * 60 * 1000
    }

    showPlayer = () => {
        this.setState({
            showPlayer: true,
        });
    };

    hidePlayer = () => {
        this.setState({
            showPlayer: false,
        });
    };

    get = (loadingEdit = false) => {
        this.props.updateMeta(
            this.props.generateSeoTags(this.state, this.props.lang)
        );

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
                this.state.useState
                    ? this.state.params
                    : getSearchParams(this.props[0].location.search, {
                        entries: 10,
                        page: 0,
                        sortField: 'sortTimeZone',
                        sortType: 1,
                        "query-group": null,
                    })
            ).then((data) => {
                this.setState(
                    {
                        ...data,
                        loading: null,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        );
                    }
                );
            });
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
                this.state.useState
                    ? this.state.params
                    : getSearchParams(this.props[0].location.search, {
                        entries: 10,
                        page: 0,
                        sortField: 'sortTimeZone',
                        sortType: 1,
                        "query-group": null,
                        expiredMeetings: this.state.expiredMeetings,
                    })
            ).then((data) => {
                this.setState(
                    {
                        ...data,
                        loading: null,
                    },
                    () => {
                        if (loadingEdit) {
                            this.setState({ editLoading: false, form: null }, () => {
                                if (this.state.previewItem && this.state.items) {
                                    let index = this.state.items.findIndex(el => el.id == this.state.previewItem.id)
                                    if (index > -1) {
                                        let previewItem = this.state.items[index];
                                        this.setState({ previewItem })
                                    }
                                }
                            })
                        }
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        );
                    }
                );
            });
        }
    };

    componentDidMount() {
        if (typeof window !== "undefined") {
            window.scrollTo(0, 0);
        }


        this.get();
        this.setState({ showForm: true })


        if (this.props.uData && this.props.socket && !this.state._registerEvents) {
            this.setState({
                _registerEvents: true
            }, () => {
                this.registerEvents();

                this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage.profile : null });

            })
        } else {
            this.socketInterval = setInterval(() => {
                if (this.props.uData && this.props.socket && !this.state._registerEvents) {
                    if (this.socketInterval) {
                        clearInterval(this.socketInterval)
                    }

                    this.setState({
                        _registerEvents: true
                    }, () => {
                        this.registerEvents();


                    })
                }

            }, 150);
        }

        /*
        this.backgroundColorInterval = setInterval(() => {
            let activeBG = this.state.activeBG;
            let activeBGHost = this.state.activeBGHost;
            if (activeBG == 'transparent') {
                activeBG = 'rgb(252, 250, 76)'
            } else {
                activeBG = 'transparent'
            }
            if (activeBGHost == 'transparent') {
                activeBGHost = 'rgb(97, 255, 168)'
            } else {
                activeBGHost = 'transparent'
            }

            this.setState({ activeBG: activeBG, activeBGHost: activeBGHost })
        }, 1000);
        */

        this.getInterval = setInterval(() => {
            this.get()
        }, 60 * 1000);


    }

    registerEvents = () => {


        this.props.socket.on('editEventConversation', (data) => {
            // console.log('editEventConversation', data)
            this.setState({
                _saving: null,

            }, () => {
                this.get(true)
            })

            // console.log(data)

        })
        this.props.socket.on('deleteEventConversation', (data) => {
            this.setState({
                _saving: null,
                deleteEventModal: null,
                previewItem: null

            }, () => {
                this.get(true)
            })

            // console.log(data)

        })
        this.props.socket.on('checkActiveConversation', (data) => {
            this.get()

        })



    }
    componentWillUnmount() {


        if (this.props.socket) {

            if (this.state.call) {
                this.props.socket.emit('hangUp', { conversation: this.state.conversation._id, uuids: Object.keys(this.peers) })
            }

            this.props.socket.removeListener('editEventConversation');
            this.props.socket.removeListener('deleteEventConversation');


        }
        // if (this.backgroundColorInterval) {
        //     clearInterval(this.backgroundColorInterval)
        // }
        if (this.getInterval) {
            clearInterval(this.getInterval)
        }



    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState(
                {
                    loading: true,
                },
                () => {
                    this.get();
                }
            );
        }
    }

    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state,
            });
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    [
                        { name: "sortField", value: field },
                        { name: "sortType", value: type },
                    ],
                    false
                )
            );
        }
    };

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj });
            }
        } else {
            this.props[0].history.push(
                generateSearchLink(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    name,
                    value,
                    restart
                )
            );
        }
    };

    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });
            }
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    fields,
                    restart
                )
            );
        }
    };

    stopAnimation = () => {
        this.player.current.play();
    };

    editConversation = (data) => {
        let timezone = this.props.uData.timezone;
        let userTimezoneOffset = 0;
        if (data.timezone && getTimezone(timezone) && getTimezone(timezone).utcOffsetStr && getTimezone(timezone).utcOffsetStr.split(":") && getTimezone(timezone).utcOffsetStr.split(":")[0] && Number(getTimezone(timezone).utcOffsetStr.split(":")[0])) {
            userTimezoneOffset = Number(getTimezone(timezone).utcOffsetStr.split(":")[0]);
        }

        if (data.scheduledDate && data.scheduledTime) {
            let timestamp = Math.floor(new Date(`${data.scheduledDate.replace(/-/g, '/')} ${data.scheduledTime}`).getTime() / 1000) - userTimezoneOffset * 60 * 60;
            data.scheduledDate = moment.unix(timestamp).format('YYYY-MM-DD')
            data.scheduledTime = moment.unix(timestamp).format('HH:mm')
        }
        if (data.name == '' || !data.name) {
            this.setState({
                error: 'You have to give some name to the meeting!'
            })
        } else {
            if (!data.scheduledTime) {
                data.scheduledTime = moment(new Date()).format('HH:mm')
            }
            if (!data.scheduledDate) {
                data.scheduledDate = moment(new Date()).format('YYYY-MM-DD')
            }
            this.setState({
                _saving: true,
                openInNewTab: data.openInNewTab,
                editLoading: true,
            }, () => {
                this.props.socket.emit('editEventConversation', data);
                setTimeout(() => {
                    if (data && data.eventGmail) {
                        this.getGmailEvents(data, (events) => {
                            this.updateGmailEvent(data, events);
                        })
                    } else if (data && data.eventOutlook) {
                        this.connectWithOutlook({
                            conversation: data._id,
                            update: true,
                            updateMeetings: true,
                            eventMeetingId: data.id,
                            // outlookDate: data.outlookDate,
                            // outlookTime: data.outlookTime
                        })
                    }
                }, 1000);
            })
        }

    }


    connectWithOutlook = (data) => {
        // console.log(data, "sssssssssssssssssssss")
        fetch(API_ENDPOINT + "/outlook/signin", {
            method: "POST",
            headers: {
                "content-type": "application/json",
                Authorization: typeof localStorage !== "undefined" ? `Bearer ${localStorage.getItem("authToken")}` : null,
            },
            body: JSON.stringify({
                conversationID: data.conversation,
                update: data.update,
                updateMeetings: data.updateMeetings,
                eventMeetingId: data.eventMeetingId,
                deleteMeetings: data.deleteMeetings,
                outlookDate: data.outlookDate,
                outlookTime: data.outlookTime
            })
        }).then((res) => res.json()).then((result) => {
            if (result && result.error) {
                this.setState({
                    error: result.error
                })
            } else {

                this.setState({
                    calendarConnectModal: false
                })

                window.location.href = `${result.url}`;
            }
        });
    }


    signInGoogle = () => {
        return new Promise((resolve, reject) => {
            if (this.gapi) {
                resolve(this.gapi);
                return;
            }


            var gapi = window.gapi
            var CLIENT_ID = '884844421012-efq8lc9gim4e52510i0tnst1q0hfkcnr.apps.googleusercontent.com';
            var API_KEY = 'AIzaSyCDx-ZCVWi8K3ge5W6vhsXqreNFhH2-1XU';
            var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
            var SCOPES = "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events";

            gapi.load('client:auth2', () => {

                gapi.client.init({
                    apiKey: API_KEY,
                    clientId: CLIENT_ID,
                    discoveryDocs: DISCOVERY_DOCS,
                    scope: SCOPES,
                })

                gapi.client.load('calendar', 'v3', () => gapi.auth2.getAuthInstance().signIn()
                    .then(() => {
                        this.gapi = gapi;
                        resolve(gapi);
                    }))
            })
        })
    }

    getGmailEvents = (data, callback) => {

        this.signInGoogle().then((gapi) => {
            gapi.client.calendar.events.list({
                'calendarId': 'primary',
                'timeMin': (new Date()).toISOString(), // vratitti datum iz konverzacije
                'showDeleted': false,
                'singleEvents': true,
                'maxResults': 10,
                'orderBy': 'startTime'
            }).then(function (response) {
                var events = response.result.items;
                callback(events)
            });

        })
    }


    deleteEventConversation = (data) => {
        let obj = {};
        if (data._id && data.id) {
            obj._id = data._id;
            obj.id = data.id;

            this.setState({
                _saving: true,
            }, () => {
                this.props.socket.emit('deleteEventConversation', obj);
                if (data && data.eventGmail) {
                    // console.log('error');
                    this.deleteGmailEvent(data)
                } else if (data && data.eventOutlook) {
                    this.connectWithOutlook({
                        conversation: data._id,
                        update: true,
                        updateMeetings: false,
                        eventMeetingId: data.id,
                        deleteMeetings: true,
                        outlookDate: data.outlookDate,
                        outlookTime: data.outlookTime
                    })
                }
            })
        } else {
            this.setState({
                deleteEventModal: null
            })
        }
    }

    delete = (data) => {
        // console.log(data);
        this.setState({
            loading: true
        }, () => {

            fetch(API_ENDPOINT + '/data/meetings/' + data._id, {
                method: 'DELETE',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(parseJSON).then(({ result, status }) => {
                if (result.error) {
                    this.setState({ error: result.error })
                }
                this.get();
            })
        })

    }

    updateGmailEvent = (dataa, events) => {
        // console.log(dataa)
        // console.log(events, "eventssssssss")
        let data = dataa
        // console.log({ ...data })
        let timezone = this.props.uData.timezone;
        let userTimezoneOffset = 0;
        if (data.timezone && getTimezone(timezone) && getTimezone(timezone).utcOffsetStr && getTimezone(timezone).utcOffsetStr.split(":") && getTimezone(timezone).utcOffsetStr.split(":")[0] && Number(getTimezone(timezone).utcOffsetStr.split(":")[0])) {
            userTimezoneOffset = Number(getTimezone(timezone).utcOffsetStr.split(":")[0]);
        }

        if (data.scheduledDate && data.scheduledTime) {
            let timestamp = Math.floor(new Date(`${data.scheduledDate.replace(/-/g, '/')} ${data.scheduledTime}`).getTime() / 1000) + userTimezoneOffset * 60 * 60;
            data.scheduledDate = moment.unix(timestamp).format('YYYY-MM-DD')
            data.scheduledTime = moment.unix(timestamp).format('HH:mm')
        }
        // console.log({ ...data })

        let duration = '00:30:00';
        let startDate = moment.unix(new Date(data.scheduledDate + ' ' + data.scheduledTime).getTime() / 1000);
        let msDuration = (Number(duration.split(':')[0]) * 60 * 60 + Number(duration.split(':')[1]) * 60 + Number(duration.split(':')[2])) * 1000;
        let endDate = new Date(startDate + msDuration);
        let isoStartDate = new Date(startDate - new Date().getTimezoneOffset() * 60 * 1000).toISOString().split(".")[0];
        let isoEndDate = new Date(endDate.getTime() - (new Date().getTimezoneOffset(data.timezone)) * 60 * 1000).toISOString().split(".")[0];
        let recuringDateUntil;
        // console.log(isoStartDate + ' ' + isoEndDate)
        let recurring = data.recurringObj
        let frequency;
        let frequencyInterval;
        let frequencyDayOfWeek = [];

        if (recurring && recurring.frequencyType && recurring.frequencyType == 'day') {
            let endDateRecuring = moment.unix(new Date(recurring.frequencyDayScheduledDateTo).getTime() / 1000);
            recuringDateUntil = new Date(endDateRecuring - new Date().getTimezoneOffset() * 60 * 1000).toISOString().replace(/-|:|\./g, '').replace('000', '');
            frequency = 'DAILY' + ';UNTIL=' + recuringDateUntil + ';INTERVAL=' + recurring.frequencyDayText

        } else if (recurring && recurring.frequencyType && recurring.frequencyType == 'week') {

            let endDateRecuring = moment.unix(new Date(recurring.frequencyWeekScheduledDateTo).getTime() / 1000);
            recuringDateUntil = new Date(endDateRecuring - new Date().getTimezoneOffset() * 60 * 1000).toISOString().replace(/-|:|\./g, '').replace('000', '');

            let arr = []
            let count = 0
            frequencyDayOfWeek = ';BYDAY='
            if (recurring.checkedMonday) {
                count++
                arr.push('MO')
            }
            if (recurring.checkedTuesday) {
                count++
                arr.push('TU')
            }
            if (recurring.checkedWednesday) {
                count++
                arr.push('WE')
            }
            if (recurring.checkedThursday) {
                count++
                arr.push('TH')
            }
            if (recurring.checkedFriday) {
                count++
                arr.push('FR')
            }
            if (recurring.checkedSaturday) {
                count++
                arr.push('SA')
            }
            if (recurring.checkedSunday) {
                count++
                arr.push('SU')
            }

            frequencyDayOfWeek += arr.join(',')
            // console.log(frequencyDayOfWeek)

            if (frequencyDayOfWeek) {
                frequencyInterval = ';INTERVAL=1' //+ count
            }

            let countt = ';COUNT=' + count

            frequency = 'WEEKLY' + frequencyDayOfWeek + ';UNTIL=' + recuringDateUntil
            // console.log(frequency)

        } else if (recurring && recurring.frequencyType && recurring.frequencyType == 'month') {
            let endDateRecuring = moment.unix(new Date(recurring.frequencyMonthScheduledDateTo).getTime() / 1000);
            recuringDateUntil = new Date(endDateRecuring - new Date().getTimezoneOffset() * 60 * 1000).toISOString().replace(/-|:|\./g, '').replace('000', '');
            frequency = 'MONTHLY' + ';BYMONTHDAY=' + recurring.frequencyMonthScheduled + ';UNTIL=' + recuringDateUntil
        } else {
            frequency = 'DAILY;COUNT=1'
        }

        this.signInGoogle()
            .then((gapi) => {
                gapi.client.calendar.events.patch({
                    "calendarId": "primary",
                    // "eventId": dataa.eventGmail + '_' + (dataa.__scheduledDate + 'T' + dataa.__scheduledTime).replace(/-|:|\./g, '') + '00Z',
                    "eventId": dataa.eventGmail + '_' + (dataa.scheduledDate + 'T' + dataa.scheduledTime).replace(/-|:|\./g, '') + '00Z',
                    "alwaysIncludeEmail": false,
                    "sendNotifications": true,
                    "sendUpdates": "all",
                    "supportsAttachments": true,
                    "prettyPrint": true,
                    "alt": "json",
                    "resource": {
                        "recurrence": [
                            `RRULE:FREQ=${frequency}`
                        ],
                        "start": {
                            'dateTime': isoStartDate,
                            'timeZone': `${data.timezone}`
                        },
                        "end": {
                            'dateTime': isoEndDate,
                            'timeZone': `${data.timezone}`
                        }
                    }
                }).then(function (response) {
                    // Handle the results here (response.result has the parsed body).
                    // console.log("Response", response);
                },
                    function (err) { console.error("Execute error", err); });

                this.setState({
                    calendarConnectModal: false
                })

            })

        // gapi.load("client:auth2", function () {
        //     gapi.auth2.init({ client_id: CLIENT_ID });
        // });

    }

    deleteGmailEvent = (data) => {
        this.signInGoogle()
            .then((gapi) => {

                gapi.client.calendar.events.delete({
                    "calendarId": "primary",
                    // "eventId": data.eventGmail + '_' + (data.__scheduledDate + 'T' + data.__scheduledTime).replace(/-|:|\./g, '') + '00Z',
                    "eventId": data.eventGmail + '_' + (data.scheduledDate + 'T' + data.scheduledTime).replace(/-|:|\./g, '') + '00Z',
                }).then(function (response) {
                    // Handle the results here (response.result has the parsed body).
                    // console.log("Response", response);
                },
                    function (err) { console.error("Execute error", err); });

                this.setState({
                    calendarConnectModal: false
                })

            })
    }




    setMonthView = () => {
        let array = [];
        let dateNow = new Date();
        if (this.state.monthViewDate) {
            dateNow = new Date(this.state.monthViewDate);
        }


        let month = dateNow.getMonth();
        let year = dateNow.getFullYear();

        let lastDay = new Date(year, month + 1, 0);
        let lastDayString = lastDay.getDate()

        for (let i = 1; i <= lastDayString; i++) {
            let date = new Date();
            date.setMonth(month)
            date.setDate(i)
            date.setFullYear(year)

            let obj = {
                day: i,
                date: date,
                start: getStartTS(date),
                end: getEndTS(date)
            }
            if (moment.unix(Math.floor(new Date(obj.date).getTime() / 1000)).format('MM.DD.YYYY') == moment.unix(Math.floor(new Date().getTime() / 1000)).format('MM.DD.YYYY')) {
                obj.today = true;
            }
            array.push(obj)
        }
        let total = array.length
        if (total) {
            let check = new Date(dateNow);
            check.setDate(1);
            let checkDate = check.getDay();
            let condition = checkDate - 1;
            if (condition < 0) {
                condition = 7 + condition;
            }
            if (condition > 0) {
                let checkMonth = month - 1;
                let checkYear = year;
                if (checkMonth == -1) {
                    checkMonth = 11;
                    checkYear--;
                }
                let lastDay = new Date(checkYear, checkMonth + 1, 0);
                let lastDayString = lastDay.getDate()
                let newMonth = [];
                let start = lastDayString - (condition) + 1;
                for (let i = start; i <= lastDayString; i++) {
                    let date = new Date(lastDay);

                    date.setDate(i);

                    let obj = {
                        day: i,
                        date: date,
                        notThisMonth: true,
                        start: getStartTS(date),
                        end: getEndTS(date)
                    }
                    newMonth.push(obj)
                }
                array = [...newMonth, ...array]

            }

            check.setDate(total);
            checkDate = check.getDay();

            if (7 - checkDate > 0 && checkDate != 0) {

                let checkMonth = month + 1;
                let checkYear = year;
                if (checkMonth == 12) {
                    checkMonth = 0;
                    checkYear++;
                }
                let lastDay = new Date(checkYear, checkMonth, 1);

                let newMonth = [];
                let end = 7 - checkDate;
                for (let i = 1; i <= end; i++) {
                    let date = new Date(lastDay);
                    date.setDate(i);

                    let obj = {
                        day: i,
                        date: date,
                        notThisMonth: true,
                        start: getStartTS(date),
                        end: getEndTS(date)
                    }
                    newMonth.push(obj)
                }
                array = [...array, ...newMonth]

            }

        }
        this.setState({ calendarMonth: array }, this.checkDays)


    }
    checkDays = () => {
        if (this.state.calendarMonth && this.state.calendarMonth.length && this.state.items && this.state.items.length) {
            let calendarMonth = this.state.calendarMonth;
            let duration = 15;

            let itemsArray = []
            if (this.state.items && this.state.items.length) {
                for (let i = 0; i < this.state.items.length; i++) {
                    itemsArray.push({
                        ...this.state.items[i],
                        start: Math.floor(new Date(`${this.state.items[i].newtimezonedate.replace(/-/g, '/')} ${this.state.items[i].newtimezonetime}`).getTime() / 1000),
                        end: Math.floor(new Date(`${this.state.items[i].newtimezonedate.replace(/-/g, '/')} ${this.state.items[i].newtimezonetime}`).getTime() / 1000) + duration * 60
                    })
                }
            }

            for (let i = 0; i < calendarMonth.length; i++) {
                if (itemsArray.filter(item => item.start > calendarMonth[i].start && item.end < calendarMonth[i].end).length) {
                    calendarMonth[i].haveFreeTime = true;
                }
            }
            this.setState({ calendarMonth })
        }

    }

    checkThisDay = (item) => {
        let check = false;
        if (item && item.newtimezonedate && item.newtimezonetime) {
            let date = new Date(`${item.newtimezonedate.replace(/-/g, '/')} ${item.newtimezonetime}`);
            let dateTs = Math.floor(new Date(date).getTime() / 1000)

            let todayStart = new Date();
            todayStart.setHours(0, 0, 0, 0)
            let todayEnd = new Date();
            todayEnd.setHours(23, 59, 59, 59)

            let todayStartTs = Math.floor(new Date(todayStart).getTime() / 1000);
            let todayEndTs = Math.floor(new Date(todayEnd).getTime() / 1000);

            if (dateTs > todayStartTs && dateTs < todayEndTs) {
                check = true
            }
        }
        return check;
    }


    formatDate = (dateStr, targetTimezone) => {
        let year = Number(dateStr.split('-')[0]);
        let month = Number(dateStr.split('-')[1]);
        let day = Number(dateStr.split('-')[2].split(' ')[0]);

        let hour = Number(dateStr.split(' ')[1].split(':')[0]);
        let minutes = Number(dateStr.split(':')[1]);

        let date = new Date(Date.UTC(year, month, day, hour, minutes, 0, 0));
        // console.log('UTC Date: ', date)
        // console.log(year, month, day, hour, minutes, targetTimezone)
        let tzDateTarget = new Date(date.toLocaleString('en-US', { timeZone: targetTimezone }));
        let tzTimeTarget = tzDateTarget.toString().substring(11, 16);
        let months = moment(tzDateTarget).format('YYYY-MM-dd')
        let hours = moment(tzDateTarget).format('HH:mm')
        // console.log(tzDateTarget, tzTimeTarget, months,hours);
        return hours;
    }

    scheduleInTime = (item) => {
        let scheduledCheckTime = Math.floor(new Date().getTime() / 1000)

        let duration = item.startNew
        if (item.durationHs && item.durationMin) {
            duration = item.startNew + (Number(item.durationHs) * 60 * 60 + Number(item.durationMin) * 60)
        } else if (item.durationMin) {
            duration = item.startNew + (Number(item.durationMin) * 60)
        }
        let darkGreenStart = item.startNew - (15 * 60)
        if (scheduledCheckTime > darkGreenStart && scheduledCheckTime < duration) {
            //return 'rgb(17, 207, 51)'
            return '#03a358'

        } else {
            return '#42e357'
        }


    }
    scheduleInTimeUser = (item) => {
        let scheduledCheckTime = Math.floor(new Date().getTime() / 1000)

        let duration = item.startNew
        if (item.durationHs && item.durationMin) {
            duration = item.startNew + (Number(item.durationHs) * 60 * 60 + Number(item.durationMin) * 60)
        } else if (item.durationMin) {
            duration = item.startNew + (Number(item.durationMin) * 60)
        }
        let darkGreenStart = item.startNew - (15 * 60)

        if (scheduledCheckTime > darkGreenStart && scheduledCheckTime < duration) {
            return '#03a358'
            //return 'rgb(17, 207, 51)'
        } else {
            return '#d6d42b'
        }


    }

    onClickEditMeetings = (item) => {
        if (item.host == this.props.uData._id)
            if (item.host != this.props.uData._id) {
                this.setState({ form: item })
            } else {
                item.scheduledTime = item.scheduledTime.split(' ')[0]
                this.props[0].history.push({
                    pathname: '/users/manage-meeting',
                    state: { ...item }
                })
            }
    }


    render() {
        let timezoneOffet = new Date().getTimezoneOffset();
        let params = {};
        if (this.state.useState) {
            params = this.state.params;

        } else {
            params = getSearchParams(this.props[0].location.search, {
                entries: 10,
                page: 0,
                sortField: 'sortTimeZone', sortType: 1
            });
        }



        let duration = 15;
        let itemsArray = []
        if (this.state.items && this.state.items.length) {
            for (let i = 0; i < this.state.items.length; i++) {


                itemsArray.push({
                    ...this.state.items[i],
                    startNew: Math.floor(new Date(new Date(`${this.state.items[i].scheduledDate.replace(/-/g, '/')} ${this.state.items[i].scheduledTime}`).toLocaleString("en-US", { timeZone: this.props.uData.timezone })).getTime() / 1000) + (this.state.items[i].dateType == 'PM' ? 12 * 60 * 60 : 0),

                    start: Math.floor(new Date(new Date(`${this.state.items[i].scheduledDate.replace(/-/g, '/')} ${this.state.items[i].scheduledTime}`).toLocaleString("en-US", { timeZone: this.props.uData.timezone })).getTime() / 1000) - timezoneOffet * 60,
                    end: Math.floor(new Date(new Date(`${this.state.items[i].scheduledDate.replace(/-/g, '/')} ${this.state.items[i].scheduledTime}`).toLocaleString("en-US", { timeZone: this.props.uData.timezone })).getTime() / 1000) - timezoneOffet * 60 + duration * 60,
                    startT: convertFrom24To12Format(Math.floor(new Date(new Date(`${this.state.items[i].scheduledDate.replace(/-/g, '/')} ${this.state.items[i].scheduledTime} ${this.state.items[i].dateType}`).toLocaleString("en-US", { timeZone: this.props.uData.timezone })).getTime() / 1000)),
                    endT: convertFrom24To12Format(Math.floor(new Date(new Date(`${this.state.items[i].scheduledDate.replace(/-/g, '/')} ${this.state.items[i].scheduledTime}`).toLocaleString("en-US", { timeZone: this.props.uData.timezone })).getTime() / 1000) + duration * 60),
                    sortTime: Number(this.state.items[i].scheduledTime.split(':')[0]) * 60 + Number(this.state.items[i].scheduledTime.split(':')[1]) + (this.state.items[i].dateType == 'PM' ? this.state.items[i].scheduledTime.split(':')[0] == '12' ? 0 : 12 * 60 : this.state.items[i].scheduledTime.split(':')[0] == '12' ? -12 * 60 : 0)
                })
            }
        }
        // let ref = this.state.referrals.filter((item) => item._id == this.state.referralId);
        // console.log(itemsArray);

        const blockHeight = 120;

        let days = [];
        let daySplitted = [];
        // console.log(this.state.items)
        let checkDate = this.state.checkDate;
        for (let i = 0; i < 7; i++) {
            let newDate = new Date(checkDate.getFullYear(), checkDate.getMonth(), checkDate.getDate(), this.state.min ? this.state.min.getHours() : 0, this.state.min ? this.state.min.getMinutes() : 0, 0, 0);
            newDate.setDate(newDate.getDate() + i);
            let endDate = new Date(checkDate.getFullYear(), checkDate.getMonth(), checkDate.getDate(), this.state.max ? this.state.max.getHours() : 23, this.state.max ? this.state.max.getMinutes() : 59, 59, 999);
            endDate.setDate(endDate.getDate() + i);



            let items = [];
            if (itemsArray) {

                let startTs = newDate.getTime() / 1000;
                let endTs = endDate.getTime() / 1000;
                // console.log(startTs);
                for (let j = 0; j < itemsArray.length; j++) {
                    if (itemsArray[j].start >= startTs && itemsArray[j].end <= endTs) {
                        items.push(itemsArray[j]);
                    }
                }
            }

            items.sort((a, b) => {
                return a.sortTime - b.sortTime
            })
            // console.log(items.map(item => { return { sortTime: item.sortTime, newtimezonetime: item.newtimezonetime } }))
            days.push({ date: newDate, timestamp: Math.floor(newDate.getTime() / 1000), items });
        }

        let newDate = new Date(checkDate.getFullYear(), checkDate.getMonth(), checkDate.getDate(), this.state.min ? this.state.min.getHours() : 0, this.state.min ? this.state.min.getMinutes() : 0, 0, 0);
        let endDate = new Date(checkDate.getFullYear(), checkDate.getMonth(), checkDate.getDate(), this.state.max ? this.state.max.getHours() : 23, this.state.max ? this.state.max.getMinutes() : 59, 59, 999);
        for (let i = 0; i < (endDate.getTime() - newDate.getTime()) / 1000 / 60 / 20; i++) {
            let newDate = new Date(checkDate.getFullYear(), checkDate.getMonth(), checkDate.getDate(), this.state.min ? this.state.min.getHours() : 0, this.state.min ? this.state.min.getMinutes() : 0, 0, 0);
            newDate.setMinutes(newDate.getMinutes() + i * 20);
            daySplitted.push({ date: newDate, timestamp: Math.floor(newDate.getTime() / 1000) });

        }


        return (
            <div className="dashboard">
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="panel">

                                <div className="web-booking-calendar-header-wrap">
                                    <h5 className="component-header">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h5>
                                    {
                                        !this.state.monthView ?
                                            <Button color='primary' className='button-green' onClick={() => {
                                                this.setState({ monthView: true, monthViewDate: new Date() }, () => {
                                                    this.setMonthView()
                                                })
                                            }}>
                                                {'Month view'.translate(this.props.lang)}
                                            </Button>
                                            :
                                            <Button color='primary' className='button-green' onClick={() => { this.setState({ monthView: false, monday: new Date() }) }}>
                                                {'Week view'.translate(this.props.lang)}
                                            </Button>
                                    }
                                </div>
                                <div className="meetings-calendar-wrap">
                                    {
                                        !this.state.monthView ?
                                            <div ref={node => {
                                                if (!this.state.calendarRef) {
                                                    this.setState({
                                                        calendarRef: node
                                                    })
                                                }
                                            }}
                                                className='web-booking-calendar-custom-wrap'
                                            >
                                                <div className='navigation-wrap'>
                                                    <div onClick={() => {
                                                        let prevWeekMonday = new Date(this.state.checkDate);
                                                        prevWeekMonday.setDate(this.state.checkDate.getDate() - 7);
                                                        this.setState({ checkDate: prevWeekMonday })
                                                    }}
                                                        className='next-prev-btn'
                                                    >
                                                        <p >
                                                            {'Previous'.translate(this.props.lang)}
                                                        </p>
                                                    </div>
                                                    <p className='date-range'>
                                                        {moment.unix(days[0].timestamp).format('DD')} - {moment.unix(days[6].timestamp).format('DD')} {(moment.unix(days[1].timestamp).format('MMM')).translate(this.props.lang)}
                                                    </p>
                                                    <div onClick={() => {
                                                        let prevWeekMonday = new Date(this.state.checkDate);
                                                        prevWeekMonday.setDate(this.state.checkDate.getDate() + 7);
                                                        this.setState({ checkDate: prevWeekMonday })
                                                    }} className='next-prev-btn'>
                                                        <p >
                                                            {'Next'.translate(this.props.lang)}
                                                        </p>
                                                    </div>

                                                </div>
                                                <div className='week-calendar-wrap'>
                                                    <div className='days-wrap'>
                                                        {
                                                            days.map((date, didx) => {
                                                                return (
                                                                    <div key={didx} style={{ width: ((this.state.calendarRef && this.state.calendarRef.getBoundingClientRect() ? this.state.calendarRef.getBoundingClientRect().width : 360) - 30) / 7, alignItems: 'center' }}>
                                                                        <p style={{ textAlign: 'center', color: date.items.length ? '#000' : '#cacaca' }}>
                                                                            {moment.unix(date.timestamp).format('DD')}
                                                                        </p>
                                                                        {
                                                                            new Date().getDate() == date.date.getDate() && new Date().getMonth() == date.date.getMonth() ?
                                                                                <div className='date-now-marker-wrap'>
                                                                                    <div className='date-now-marker'></div>
                                                                                </div>
                                                                                :

                                                                                null
                                                                        }
                                                                    </div>
                                                                )

                                                            })
                                                        }

                                                    </div>
                                                    <div>
                                                        <div className='items-wrap'>

                                                            {
                                                                days.map((date, didx) => {
                                                                    // console.log(date);
                                                                    return (
                                                                        <div key={didx} className='one-day-wrap' style={{ width: ((this.state.calendarRef && this.state.calendarRef.getBoundingClientRect() ? this.state.calendarRef.getBoundingClientRect().width : 360) - 30) / 7 }}>
                                                                            {

                                                                                date.items.length ? date.items.map((item, idx) => {
                                                                                    return (
                                                                                        <div key={idx} onClick={() => {

                                                                                            fetch(API_ENDPOINT + '/data/users/schedule/meeting/' + item.conversationID, {
                                                                                                method: 'POST',
                                                                                                headers: {
                                                                                                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                                                                                    'content-type': 'application/json'
                                                                                                },
                                                                                                body: JSON.stringify(),
                                                                                            }).then(parseJSON).then(({ result, status }) => {
                                                                                            
                                                                                                if (result) {
                                                                                                    if (result.host && result.host === this.props.uData._id) {
                                                                                                        this.props[0].history.push({
                                                                                                            pathname: '/users/manage-meeting',
                                                                                                            state: { ...item }
                                                                                                        })
                                                                                                    } else if (result.cohosts && result.cohosts.indexOf(this.props.uData._id) !== -1) {
                                                                                                        this.props[0].history.push({
                                                                                                            pathname: '/users/manage-meeting',
                                                                                                            state: { ...item }
                                                                                                        })
                                                                                                    }else{
                                                                                                        this.setState({ previewItem: { ...item } })
                                                                                                    }


                                                                                                    // this.setState({
                                                                                                    //     isHost: result.host
                                                                                                    // }, () => {

                                                                                                    //     if ((this.state.isHost != this.props.uData._id)) {
                                                                                                    //         this.setState({ previewItem: { ...item } })
                                                                                                    //     } else {
                                                                                                    //         this.props[0].history.push({
                                                                                                    //             pathname: '/users/manage-meeting',
                                                                                                    //             state: { ...item }
                                                                                                    //         })
                                                                                                    //     }
                                                                                                    // })
                                                                                                }
                                                                                            })



                                                                                        }}>
                                                                                            <div style={{ marginBottom: 5 }} >
                                                                                                <div className='item-wrap'
                                                                                                    // style={{ border: item.active ? item.host != this.props.uData._id ? '1px solid rgba(86, 138, 200, 0.2)' : '1px solid rgba(43, 187, 120, 0.2)' : 'none', backgroundColor: item.active ? item.host != this.props.uData._id ? this.state.activeBG : this.state.activeBGHost : item.host != this.props.uData._id ? this.state.activeBG : this.state.activeBGHost }}>
                                                                                                    style={{ border: item.invitedUsers && item.invitedUsers.findIndex(us => us == this.props.uData.email) != -1 ? 'rgb(252, 250, 76)' : '1px solid rgba(43, 187, 120, 0.2)', backgroundColor: item.invitedUsers && item.invitedUsers.findIndex(us => us == this.props.uData.email) != -1 ? /*'rgb(252, 250, 76)'*/ this.scheduleInTimeUser(item) : this.scheduleInTime(item) }}>

                                                                                                    <p style={{ color: item.host != this.props.uData._id ? /*'rgba(86, 138, 200, 1)'*/ '#fcfcfc' : '#fcfcfc', marginBottom: 0 }}>
                                                                                                        {`${item.startT} ${item.dateType}`}
                                                                                                        {/* {moment.unix(item.start).format('HH:mm')} */}
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </div>

                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            :
                                            <div className='web-booking-calendar-custom-wrap'>
                                                <div className='navigation-wrap'>
                                                    <div onClick={() => {
                                                        let monthViewDate = new Date(this.state.monthViewDate);
                                                        monthViewDate.setDate(1);
                                                        let prevMonth = monthViewDate.getMonth();
                                                        let prevYear = monthViewDate.getFullYear()
                                                        prevMonth--;
                                                        if (prevMonth == -1) {
                                                            prevMonth = 11;
                                                            prevYear--;
                                                        }
                                                        monthViewDate.setMonth(prevMonth)
                                                        monthViewDate.setFullYear(prevYear)

                                                        this.setState({ monthViewDate }, () => this.setMonthView())
                                                    }}
                                                        className='next-prev-btn'
                                                    >
                                                        <p >
                                                            {'Previous'.translate(this.props.lang)}
                                                        </p>
                                                    </div>
                                                    <p className='date-range'>
                                                        {this.state.monthViewDate ? ((moment.unix(Math.floor(new Date(this.state.monthViewDate).getTime() / 1000)).format('MMMM').translate(this.props.lang)) + ' ' + moment.unix(Math.floor(new Date(this.state.monthViewDate).getTime() / 1000)).format('YYYY')) : ''}
                                                    </p>
                                                    <div onClick={() => {
                                                        let monthViewDate = new Date(this.state.monthViewDate);
                                                        monthViewDate.setDate(1);
                                                        let nextMonth = monthViewDate.getMonth();
                                                        let nextYear = monthViewDate.getFullYear()
                                                        nextMonth++;
                                                        if (nextMonth == 12) {
                                                            nextMonth = 0;
                                                            nextYear++;
                                                        }
                                                        monthViewDate.setMonth(nextMonth)
                                                        monthViewDate.setFullYear(nextYear)

                                                        this.setState({ monthViewDate }, () => this.setMonthView())
                                                    }} className='next-prev-btn'>
                                                        <p >
                                                            {'Next'.translate(this.props.lang)}
                                                        </p>
                                                    </div>

                                                </div>
                                                <div className='web-booking-calendar-month-wrap'>
                                                    {
                                                        this.state.weekDays && this.state.weekDays.map((item, idx) => {
                                                            return (
                                                                <div className='web-booking-calendar-month-week-days'>
                                                                    {item.translate(this.props.lang)}
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        this.state.calendarMonth && this.state.calendarMonth.map((item, idx) => {
                                                            return (
                                                                <div className={item.notThisMonth && !item.haveFreeTime ? 'web-booking-calendar-month-item free-time-box-not-this-month' : item.notThisMonth && item.haveFreeTime ? 'web-booking-calendar-month-item free-time-box-not-this-month-have-free' : item.haveFreeTime ? 'web-booking-calendar-month-item free-time-box' : 'web-booking-calendar-month-item'}
                                                                    onClick={() => {
                                                                        if (item.haveFreeTime) {
                                                                            this.setState({ monthView: false, checkDate: new Date(item.date) })
                                                                        }
                                                                    }}
                                                                >
                                                                    <h6 className={item.today ? 'today-date' : ''}>{item.day}</h6>

                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>




                                            </div>
                                    }
                                </div>

                                {/* <div className="meetgins-2">
                                    <<ListBuilder
                                        lang={this.props.lang}
                                        loading={this.state.loading}
                                        total={this.state.total}
                                        showNumeration={false}
                                        fields={[
                                            { type: 'text', name: 'name', label: 'Name'.translate(this.props.lang), allowSort: true },
                                            { type: 'text', name: 'conversationID', label: 'Meeting ID'.translate(this.props.lang), allowSort: true },
                                            { type: 'text', name: 'newtimezonedate', label: 'Date'.translate(this.props.lang), allowSort: true },
                                            { type: 'text', name: 'newtimezonetime', label: 'Time'.translate(this.props.lang), allowSort: true },
                                            { type: 'text', name: 'timezone', label: 'Time zone'.translate(this.props.lang), allowSort: true },

                                        ]}
                                        rawItems={this.state.items}
                                        items={this.state.items.map(item => {
                                            return {
                                                name: <div style={item.currentCall ? { background: 'green', color: 'white', borderRadius: '6px', paddingLeft: '10px', height: '100%', display: 'flex', alignItems: 'center' } : {}}>{item.name}</div>,
                                                conversationID: <div style={item.currentCall ? { background: 'green', color: 'white', borderRadius: '6px', paddingLeft: '10px', height: '100%', display: 'flex', alignItems: 'center' } : {}} >{item.conversationID}</div>,
                                                newtimezonedate: <div style={item.currentCall ? { background: 'green', color: 'white', borderRadius: '6px', paddingLeft: '10px', height: '100%', display: 'flex', alignItems: 'center' } : {}}>{item.newtimezonedate}</div>,
                                                newtimezonetime: <div style={item.currentCall ? { background: 'green', color: 'white', borderRadius: '6px', paddingLeft: '10px', height: '100%', display: 'flex', alignItems: 'center' } : {}}>{item.newtimezonetime}</div>,
                                                timezone: <div style={item.currentCall ? { background: 'green', color: 'white', borderRadius: '6px', paddingLeft: '10px', height: '100%', display: 'flex', alignItems: 'center' } : {}}>{this.props.uData.timezone}</div>,
                                                host: item.host
                                            }
                                        })}
                                        actions={
                                            [
                                                {
                                                    component: <Isvg src={edit} className="edit-icon" />,
                                                    onClick: (item) => this.setState({ form: item }),
                                                    condition: (item) => {
                                                        if (item.host == this.props.uData._id) {
                                                            return true
                                                        } else {
                                                            return false
                                                        }
                                                    }
                                                },
                                                {
                                                    component: <Isvg src={ChatIcon} className="call-button-list-meetings" />,
                                                    onClick: (item) => {
                                                                var strWindowFeatures = `height=${window.innerHeight-1},width=${window.innerWidth-1},resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes`;
                                                        this.videoCallWindow = window.open(`/video-call/${item._id}`, "_blank", strWindowFeatures);
                                                        this.videoCallWindow.addEventListener('message',
                                                            (e) => {
                                                                if (e.data == 'end-video-call') {
                                                                    this.videoCallWindow.close();
                                                                }
                                                            }, false)
                                                    }
                                                },
                                            ]
                                        }
                                        params={params}
                                        sortField={params.sortField}
                                        sortType={params.sortType}
                                        updateSort={this.updateSort}
                                        updateParams={this.updateParams}
                                        updateMultipleParams={this.updateMultipleParams}
                                    ></ListBuilder>
                                        lang={this.props.lang}
                                        loading={this.state.loading}
                                        total={this.state.total}
                                        showNumeration={false}
                                        fields={[
                                            { type: 'text', name: 'name', label: 'Name'.translate(this.props.lang), allowSort: true },
                                            { type: 'text', name: 'conversationID', label: 'Meeting ID'.translate(this.props.lang), allowSort: true },
                                            { type: 'text', name: 'newtimezonedate', label: 'Date'.translate(this.props.lang), allowSort: true },
                                            { type: 'text', name: 'newtimezonetime', label: 'Time'.translate(this.props.lang), allowSort: true },
                                            { type: 'text', name: 'timezone', label: 'Time zone'.translate(this.props.lang), allowSort: true },

                                        ]}
                                        rawItems={this.state.items}
                                        items={this.state.items.map(item => {
                                            return {
                                                name: <div style={item.currentCall ? { background: 'green', color: 'white', borderRadius: '6px', paddingLeft: '10px', height: '100%', display: 'flex', alignItems: 'center' } : {}}>{item.name}</div>,
                                                conversationID: <div style={item.currentCall ? { background: 'green', color: 'white', borderRadius: '6px', paddingLeft: '10px', height: '100%', display: 'flex', alignItems: 'center' } : {}} >{item.conversationID}</div>,
                                                newtimezonedate: <div style={item.currentCall ? { background: 'green', color: 'white', borderRadius: '6px', paddingLeft: '10px', height: '100%', display: 'flex', alignItems: 'center' } : {}}>{item.newtimezonedate}</div>,
                                                newtimezonetime: <div style={item.currentCall ? { background: 'green', color: 'white', borderRadius: '6px', paddingLeft: '10px', height: '100%', display: 'flex', alignItems: 'center' } : {}}>{item.newtimezonetime}</div>,
                                                timezone: <div style={item.currentCall ? { background: 'green', color: 'white', borderRadius: '6px', paddingLeft: '10px', height: '100%', display: 'flex', alignItems: 'center' } : {}}>{this.props.uData.timezone}</div>,
                                                host: item.host
                                            }
                                        })}
                                        actions={
                                            [
                                                {
                                                    component: <Isvg src={edit} className="edit-icon" />,
                                                    onClick: (item) => this.setState({ form: item }),
                                                    condition: (item) => {
                                                        if (item.host == this.props.uData._id) {
                                                            return true
                                                        } else {
                                                            return false
                                                        }
                                                    }
                                                },
                                                {
                                                    component: <Isvg src={ChatIcon} className="call-button-list-meetings" />,
                                                    onClick: (item) => {
                                                                var strWindowFeatures = `height=${window.innerHeight-1},width=${window.innerWidth-1},resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes`;
                                                        this.videoCallWindow = window.open(`/video-call/${item._id}`, "_blank", strWindowFeatures);
                                                        this.videoCallWindow.addEventListener('message',
                                                            (e) => {
                                                                if (e.data == 'end-video-call') {
                                                                    this.videoCallWindow.close();
                                                                }
                                                            }, false)
                                                    }
                                                },
                                            ]
                                        }
                                        params={params}
                                        sortField={params.sortField}
                                        sortType={params.sortType}
                                        updateSort={this.updateSort}
                                        updateParams={this.updateParams}
                                        updateMultipleParams={this.updateMultipleParams}
                                    ></ListBuilder>
                                </div> */}
                                <DeleteModal
                                    lang={this.props.lang}
                                    isOpen={this.state.deleteModal}
                                    toggle={() => this.setState({ deleteModal: null })}
                                    handler={() => { this.delete(this.state.deleteModal._id); this.setState({ deleteModal: null }) }}
                                >
                                    {'Delete price'.translate(this.props.lang)} <strong>{this.state.deleteModal && Object.translate(this.state.deleteModal, 'name', this.props.lang)}</strong>?
                                </DeleteModal>




                                {this.state.error ?
                                    <ErrorModal lang={this.props.lang}
                                        isOpen={this.state.error}
                                        toggle={() => this.setState({ error: null })}
                                    >
                                        {this.state.error.translate(this.props.lang)}
                                    </ErrorModal>
                                    : null}

                            </div>
                        </Col>
                    </Row>

                </Container>

                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="panel">
                                <div className="expired-meetings-checkbox">
                                    <Checkbox
                                        onChange={(e) => {
                                            this.setState({
                                                expiredMeetings: !this.state.expiredMeetings
                                            }, () => {
                                                this.get();
                                            })
                                        }}
                                        checked={this.state.expiredMeetings}
                                        label={'Expired meetings'.translate(this.props.lang)}
                                    />
                                </div>
                                <ListBuilder
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'name', label: 'Title'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'conversationID', label: 'Meeting ID'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'scheduledDate', label: 'Date'.translate(this.props.lang), allowSort: true },
                                        // { type: 'text', name: 'scheduledTime', label: 'Time'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'participantsNumber', label: 'Number of participants'.translate(this.props.lang), allowSort: false },
                                        { type: 'text', name: 'bankId', label: 'BankID'.translate(this.props.lang), allowSort: false },
                                        { type: 'text', name: 'passcode', label: 'Passcode'.translate(this.props.lang), allowSort: false },
                                        { type: 'text', name: 'inviteLink', label: 'Invite link'.translate(this.props.lang), allowSort: false },
                                    ]}
                                    rawItems={this.state.meetings}
                                    items={this.state.meetings && this.state.meetings.map((item => {
                                        return {
                                            ...item,
                                            name: <div onClick={() => this.onClickEditMeetings(item)}>{item.name}</div>,
                                            conversationID: <div onClick={() => this.onClickEditMeetings(item)}>{item.conversationID}</div>,
                                            scheduledDate: <div style={{ whiteSpace: 'nowrap' }} onClick={() => this.onClickEditMeetings(item)}><p>{item.scheduledDate}{item.recurringObj ? ' - ' + item.scheduleEndDate : ''} <br /> {item.scheduledTime ? convertFrom24To12FormatPeriod(item.scheduledTime) : ''}</p></div>,
                                            longinviteLink: <div onClick={() => this.onClickEditMeetings(item)}>{item.inviteLink}</div>,
                                            passcode: <div onClick={() => this.onClickEditMeetings(item)}>{item.passcode}</div>,
                                            participantsNumber: <div onClick={() => this.onClickEditMeetings(item)}>{item.participantsNumber}</div>,
                                            // scheduledTime: <div onClick={() => this.onClickEditMeetings(item)}>{item.scheduledTime ? convertFrom24To12FormatPeriod(item.scheduledTime) : ''}</div>,
                                            inviteLink: <div onClick={() => this.onClickEditMeetings(item)}>{item.inviteLink ? item.inviteLink.substring(0, 43) + '...' : ''}</div>,
                                            bankId: <div onClick={() => this.onClickEditMeetings(item)}>{item.requireBankID ? 'Yes'.translate(this.props.lang) : 'No'.translate(this.props.lang)}</div>

                                        }
                                    }))}
                                    actions={
                                        [
                                            {
                                                component: <Isvg src={edit} className="edit-icon" />,
                                                onClick: (item) => {
                                                    // console.log(item);
                                                    if (item.host != this.props.uData._id) {
                                                        this.setState({ form: item })
                                                    } else {
                                                        // console.log(item);
                                                        item.scheduledTime = item.scheduledTime.split(' ')[0]
                                                        this.props[0].history.push({
                                                            pathname: '/users/manage-meeting',
                                                            state: { ...item }
                                                        })
                                                    }
                                                },
                                                condition: (item) => {
                                                    if (item.host == this.props.uData._id) {
                                                        return true
                                                    } else {
                                                        return false
                                                    }
                                                }
                                            },
                                            {
                                                renderComponent: (item) => {
                                                    return (
                                                        <div className="tabel-action-tooltip">
                                                            <Isvg src={eye} className="call-button-list-meetings" />
                                                            {
                                                                item && item.host && item.host == this.props.uData._id ?
                                                                    <div className="chat-icons-tooltip">
                                                                        {'Start video meeting'.translate(this.props.lang)}
                                                                    </div>
                                                                    :
                                                                    <div>
                                                                        {'Join video meeting'.translate(this.props.lang)}
                                                                    </div>
                                                            }

                                                        </div>)
                                                },
                                                // component:  <div className="chat-icons-tooltip">{ this.state.meetings && this.state.meetings.host == this.props.uData._id ? }</div></div>,
                                                onClick: (item) => {
                                                    var strWindowFeatures = `height=${window.innerHeight - 1},width=${window.innerWidth - 1},resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes`;
                                                    this.videoCallWindow = window.open(`/video-call/${item._id}`, "_blank", strWindowFeatures);
                                                    this.videoCallWindow.addEventListener('message',
                                                        (e) => {
                                                            if (e.data == 'end-video-call') {
                                                                this.videoCallWindow.close();
                                                            }
                                                        }, false)
                                                },
                                                condition: (item) => {

                                                    let date = new Date(`${item.scheduleInTime} ${item.scheduleInTime}`);
                                                    let dateTs = Math.floor(new Date(date).getTime() / 1000)
                                                    let scheduledCheckTime = Math.floor(new Date().getTime() / 1000)
                                                    let duration = dateTs
                                                    if (item.durationHs && item.durationMin) {
                                                        duration = dateTs + (Number(item.durationHs) * 60 * 60 + Number(item.durationMin) * 60)
                                                    } else if (item.durationMin) {
                                                        duration = dateTs + (Number(item.durationMin) * 60)
                                                    }
                                                    let darkGreenStart = dateTs - (15 * 60)
                                                    if (scheduledCheckTime > darkGreenStart && scheduledCheckTime < duration) {
                                                        return true
                                                    } else {
                                                        return false
                                                    }

                                                }
                                            },
                                            {
                                                component: <Isvg src={garabage} className="delete-icon" />,
                                                onClick: (item) => this.setState({ deleteModal: item })
                                            },
                                        ]
                                    }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <DeleteModal
                    lang={this.props.lang}
                    isOpen={this.state.deleteModal}
                    toggle={() => this.setState({ deleteModal: null })}
                    handler={() => { this.delete(this.state.deleteModal); this.setState({ deleteModal: null }) }}
                >
                    {'Delete meeting'.translate(this.props.lang)} <strong>{this.state.deleteModal && this.state.deleteModal.name}</strong>?
                </DeleteModal>


                {
                    this.state.previewItem ?
                        <Modal isOpen={this.state.previewItem} centered>
                            <ModalHeader toggle={() => this.setState({ previewItem: !this.state.previewItem })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ previewItem: !this.state.previewItem })}>&times;</button>}>
                                <h4>{'Preview conversation'.translate(this.props.lang)}</h4>
                            </ModalHeader>
                            <ModalBody className="preview-meeting-wrap">
                                <div className="action-buttons-wrap">
                                    {
                                        this.state.previewItem.host == this.props.uData._id ?
                                            <div onClick={() => {
                                                this.setState({
                                                    deleteEventModal: {
                                                        ...this.state.previewItem,
                                                        outlookDate: this.state.previewItem.scheduledDate,
                                                        outlookTime: this.state.previewItem.scheduledTime
                                                    }
                                                })
                                            }}>
                                                <Isvg src={garabage} className="edit-icon" />
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        this.state.previewItem.host == this.props.uData._id && (new Date().setHours(0, 0, 0, 0) / 1000 - 1) < Math.floor(new Date(this.state.previewItem.scheduledDate).getTime() / 1000) ?
                                            <div onClick={() => {
                                                let scheduledDate = this.state.previewItem.scheduledDate;
                                                let scheduledTime = this.state.previewItem.scheduledTime;
                                                if (this.state.previewItem.newtimezonedate && this.state.previewItem.newtimezonetime) {
                                                    scheduledDate = String(moment.unix(Math.floor(new Date(new Date(`${this.state.previewItem.scheduledDate.replace(/-/g, '/')} ${this.state.previewItem.scheduledTime}`).toLocaleString("en-US", { timeZone: this.props.uData.timezone })).getTime() / 1000) - timezoneOffet * 60).format(this.props.dateFormat));
                                                    scheduledTime = String(moment.unix(Math.floor(new Date(new Date(`${this.state.previewItem.scheduledDate.replace(/-/g, '/')} ${this.state.previewItem.scheduledTime}`).toLocaleString("en-US", { timeZone: this.props.uData.timezone })).getTime() / 1000) - timezoneOffet * 60).format("HH:mm"));
                                                }
                                                // moment(new Date(this.state.form.scheduledDate.replace(/-/g, "/") + " " + this.state.form.scheduledTime))

                                                this.setState({
                                                    form: {
                                                        ...this.state.previewItem,
                                                        scheduledDate,
                                                        scheduledTime,
                                                        _scheduledDate: scheduledDate,
                                                        _scheduledTime: scheduledTime,
                                                        outlookDate: this.state.previewItem.scheduledDate,
                                                        outlookTime: this.state.previewItem.scheduledTime

                                                    }
                                                })
                                            }}>
                                                <Isvg src={edit} className="edit-icon" />
                                            </div>
                                            :
                                            null
                                    }

                                    {
                                        this.checkThisDay(this.state.previewItem) ?
                                            this.state.previewItem.host == this.props.uData._id || (this.state.previewItem.host != this.props.uData._id && this.state.previewItem.active) ?
                                                <div onClick={() => {
                                                    var strWindowFeatures = `height=${window.innerHeight - 1},width=${window.innerWidth - 1},resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes`;
                                                    this.videoCallWindow = window.open(`/video-call/${this.state.previewItem._id}`, "_blank", strWindowFeatures);
                                                    this.videoCallWindow.addEventListener('message',
                                                        (e) => {
                                                            if (e.data == 'end-video-call') {
                                                                this.videoCallWindow.close();
                                                            }
                                                        }, false)

                                                }}>
                                                    <Isvg src={ChatIcon} className="call-button-list-meetings" />
                                                </div>
                                                :
                                                null
                                            :
                                            null
                                    }
                                </div>
                                <table>
                                    <tr>
                                        <td>{'Name'.translate(this.props.lang)}</td>
                                        <td>{this.state.previewItem.name}</td>
                                    </tr>
                                    <tr>
                                        <td>{'Meeting ID'.translate(this.props.lang)}</td>
                                        <td>{this.state.previewItem.conversationID}</td>
                                    </tr>
                                    <tr>
                                        <td>{'Date'.translate(this.props.lang)}</td>
                                        <td>{moment.unix(Math.floor(new Date(new Date(`${this.state.previewItem.newtimezonedate.replace(/-/g, '/')} ${this.state.previewItem.newtimezonetime}`).toLocaleString("en-US", { timeZone: this.props.uData.timezone })).getTime() / 1000) - timezoneOffet * 60).format(this.props.dateFormat)}</td>
                                    </tr>
                                    <tr>
                                        <td>{'Time'.translate(this.props.lang)}</td>
                                        <td>{convertFrom24To12FormatPeriod(this.state.previewItem.scheduledTime)}</td>
                                        {/* <td>{this.formatDate(this.state.previewItem.scheduledDate + " " + this.state.previewItem.scheduledTime, this.props.uData.timezone)}</td> */}
                                        {/* <td>{moment.unix(Math.floor(new Date(new Date(`${this.state.previewItem.scheduledDate} ${this.state.previewItem.scheduledTime}`).toLocaleString("en-US", { timeZone: this.props.uData.timezone })).getTime() / 1000) - timezoneOffet * 60).format("HH:mm")}</td> */}
                                    </tr>
                                    <tr>
                                        <td>{'Time zone'.translate(this.props.lang)}</td>
                                        <td>{this.props.uData.timezone}</td>
                                    </tr>
                                </table>

                            </ModalBody>
                            <ModalFooter className="modal-footer-buttons-wrap">

                                <Button color={'primary'} onClick={() => this.setState({ previewItem: null })}>{'Close'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        :
                        null
                }
                {
                    this.state.editLoading ?
                        <div className="loader-wrap-edit-conversation">
                            <Player
                                autoplay={true}
                                loop={true}
                                src="/lf30_editor_l5cxzdyf.json"
                                style={{
                                    height:
                                        typeof window != "undefined" &&
                                            window.innerWidth < 768
                                            ? "128px"
                                            : "256px",
                                    width:
                                        typeof window != "undefined" &&
                                            window.innerWidth < 768
                                            ? "128px"
                                            : "256px",
                                }}
                            ></Player>
                        </div>

                        :
                        null
                }
                {
                    this.state.form ?
                        <Modal isOpen={this.state.form} centered>
                            <ModalHeader toggle={() => this.setState({ form: !this.state.form })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ form: !this.state.form })}>&times;</button>}>
                                <h4>{'Edit conversation'.translate(this.props.lang)}</h4>
                            </ModalHeader>
                            <ModalBody>
                                {/* <FormGroup>
                                <Label>{'Conversation name'.translate(this.props.lang)}</Label>
                                <Input type='text' value={this.state.form.name} onChange={(e) => {
                                    let form = this.state.form;
                                    form.name = e.target.value;
                                    this.setState({
                                        form
                                    })
                                }} />
                            </FormGroup> */}
                                <FormGroup>
                                    {/* <Label>{'Schedule'.translate(this.props.lang)}</Label> */}
                                    <DatePicker
                                        label={'Date'.translate(this.props.lang)}
                                        value={this.state.form.scheduledDate ? this.state.form.scheduledDate : moment(new Date()).format("YYYY-MM-DD")}
                                        onChange={(e) => {
                                            let form = this.state.form;
                                            // if(form.scheduledDate)
                                            // form.outlookDate = form.scheduledDate
                                            form.scheduledDate = e;
                                            this.setState({
                                                form
                                            })
                                        }}
                                    />
                                    <Label>{'Select time'.translate(this.props.lang)}</Label>
                                    <TimePicker
                                        format={'HH:mm'}
                                        value={this.state.form.scheduledDate && this.state.form.scheduledTime ? moment(new Date(this.state.form.scheduledDate.replace(/-/g, "/") + " " + convertFrom24To12Format(this.state.form.scheduledTime))) : moment(new Date())}
                                        minValue={moment.unix(this.state.min.getTime() / 1000).format("HH:mm")}
                                        maxValue={moment.unix(this.state.max.getTime() / 1000).format("HH:mm")}
                                        flag={0}
                                        onChange={(time1, timeString) => {
                                            let form = this.state.form;
                                            // if(form.scheduledTime)
                                            // form.outlookTime = form.scheduledTime
                                            form.scheduledTime = timeString

                                            this.setState({ form })
                                        }}
                                        allowClear={false}
                                    />

                                    <TimeZonePicker
                                        disabled={true}
                                        label={"Select time zone".translate(this.props.lang)}
                                        value={this.state.form.timezone ? this.state.form.timezone : this.props.uData.timezone}
                                        onChange={(e) => {
                                            let form = this.state.form;
                                            form.timezone = e.target.value ? e.target.value : this.props.uData.timezone;
                                            this.setState({ form })
                                        }}
                                    >

                                    </TimeZonePicker>

                                </FormGroup>

                            </ModalBody>
                            <ModalFooter className="modal-footer-buttons-wrap">
                                <Button color={'primary'} onClick={() => this.editConversation({
                                    ...this.state.form,
                                    // outlookDate: this.state.previewI.scheduledDate,
                                    // outlookTime: this.state.form.scheduledTime

                                })}>{'Save'.translate(this.props.lang)}</Button>
                                <Button color={'primary'} onClick={() => this.setState({ form: null })}>{'Close'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        :
                        null
                }

                <DeleteModal
                    lang={this.props.lang}
                    isOpen={this.state.deleteEventModal}
                    toggle={() => this.setState({ deleteEventModal: null })}
                    zIndex={9999}
                    handler={() => {
                        this.deleteEventConversation(this.state.deleteEventModal);
                    }}
                >
                    {'Delete conversation'.translate(this.props.lang)} <strong>
                        {
                            this.state.deleteEventModal ?
                                `${this.state.deleteEventModal.name} ${this.state.deleteEventModal.conversationID}`
                                :
                                null
                        }
                    </strong>?
                </DeleteModal>



                {/* {this.state.meetings && this.props.offerData && this.props.offerData.conversation == this.state.meetings._id ?
                    // <button onClick={() => {
                                                                var strWindowFeatures = `height=${window.innerHeight-1},width=${window.innerWidth-1},resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes`;
                    this.videoCallWindow = window.open(`/video-call/${this.state.meetings._id}`, "_blank", strWindowFeatures);
                    this.videoCallWindow.addEventListener('message',
                        (e) => {
                            if (e.data == 'end-video-call') {
                                this.videoCallWindow.close();
                            }
                        }, false)
                    // }

                } className="btn btn-primary">{'Start conversation'.translate(this.props.lang)}</button>
                : null
                } */}
            </div >

        );
    }
}


export default Page(Licence);
