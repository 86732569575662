import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import curoflowBigPrices from '../../assets/svg/curoflowBigPrices.svg';
import Page from '../../containers/page';
import { CircularProgressbar } from 'react-circular-progressbar';
// import 'react-circular-progressbar/dist/styles.css';

import { API_ENDPOINT } from "../../constants";
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import curoflowBig from '../../assets/svg/curoflowBig.svg';

import AOS from 'aos';
import 'aos/dist/aos.css'



import {
    Container,
    Row,
    Col,
    Button
} from 'reactstrap';

import ContactForm from '../../components/forms/contactForm';


var striptags = require('striptags');

function parseJSON(response) {
    return response.json().then((json) => {
        return {
            result: json,
            status: response.status,
        };
    });
}

class PricesPage extends Component {
    constructor(props) {
        super(props);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.player = React.createRef();

        this.state = {
            promotedProjects: [],
            poolActive: false,
            chatForm: false,
            yScroll2: 0,
            team: [],
            aboutUs: [],
            ...props.initialData,
            licence: []
        };
    }

    listenToScroll = () => {
        const yScroll2 =
            document.body.scrollTop || document.documentElement.scrollTop


        this.setState({
            yScroll2: yScroll2,
        })
    }

    sendMessage1 = (data) => {
        if (data.checked) {
            data.lang = this.props.lang
            this.setState({
                loading1: true
            }, () => {

                fetch(API_ENDPOINT + '/data/contact/new', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ data })
                }).then(parseJSON).then(({ result, status }) => {
                    if (result.error) {
                        this.setState({ error: result.error })
                    } else {
                    }
                })
            })
        }
    }

    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();
        this.updateWindowDimensions();
        window.addEventListener('scroll', this.listenToScroll)

        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        AOS.init({
            duration: 1000
        })

    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            // this.get()
        }
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    buyOrUpgrade = (item) => {
        let buy = true
        let disable = false
        if (this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.licenceMain) {
            buy = false
            if (this.props.uData.licenceObj.licenceIDMain == item._id && (this.props.uData.licenceObj.numberOfLicencesMain) == Number(item.numberOfLicenses)) {
                disable = true
            } else if ((this.props.uData.licenceObj.licenceMain == 'Pro' && item.name == 'Standard') || (this.props.uData.licenceObj.licenceMain == 'Enterprise' && (item.name == 'Standard' || item.name == 'Pro'))) {
                disable = true
            }


        }

        if (buy) {
            return (
                <Button onClick={() => this.props[0].history.push({ pathname: '/registration', state: item })}>{'Buy now'.translate(this.props.lang)}</Button>
            )
        } else {
            return (
                <Button disabled={disable} onClick={() => this.props[0].history.push({ pathname: '/registration', state: item })}>{'Upgrade'.translate(this.props.lang)}</Button>
            )
        }

    }

    render() {


        return (
            <div>
                <section className='price-title-org'>
                    <div>
                        <h1>{'Choose the right plan for you or your organization'.translate(this.props.lang)}</h1>
                        <h6>{'Curoflow Video is the easy, safe option. The main difference between the different plans is the maximum number of participants in a video meeting'.translate(this.props.lang)}!</h6>
                    </div>
                    <Isvg src={curoflowBigPrices} />
                    {/* <Isvg src={curoflowBig} /> */}
                </section>
                <section className='price-section'>
                    <div className='price-content-boxes'>
                        <div className='box-price '>
                            <h2>{'Basic'.translate(this.props.lang)}</h2>
                            <h4>{'Try Curoflow Video and invite people to meetings'.translate(this.props.lang)}</h4>
                            <h5>{'Free'.translate(this.props.lang)}</h5>
                            <Button onClick={() => this.props[0].history.push('/registration')}>{'Start meeting'.translate(this.props.lang)}</Button>
                            <p>{'Max participants: 2'.translate(this.props.lang)}</p>
                            <p>{'Max meeting length: 20 min'.translate(this.props.lang)}</p>
                            <ul >
                                <li>
                                    <span> {'Unlimited number of meetings'.translate(this.props.lang)} </span>
                                </li>
                                <li>
                                    <span> {'Chat'.translate(this.props.lang)} </span>
                                </li>
                                <li>
                                    <span> {'Screen sharing'.translate(this.props.lang)} </span>
                                </li>
                                <li>
                                    <span> {'Invite participants via email or link'.translate(this.props.lang)} </span>
                                </li>
                                <li>
                                    <span> {'Authenticate participants with code'.translate(this.props.lang)} </span>
                                </li>
                                <li>
                                    <span> {'Secure login through multifactor, SITHS or BankID'.translate(this.props.lang)} </span>
                                </li>
                                <li className='list-style-price'>
                                    <div> <span> {'Co-host'.translate(this.props.lang)} </span></div>
                                </li>
                                <li className='list-style-price'>
                                    <div> <span> {'Authenticate participants with BankID'.translate(this.props.lang)} </span></div>
                                </li>
                                <li className='list-style-price'>
                                    <div> <span>{'File sharing'.translate(this.props.lang)} </span></div>
                                </li>
                                <li className='list-style-price'>
                                    <div> <span>{'Record meetings'.translate(this.props.lang)} </span></div>
                                </li>
                            </ul>
                        </div>

                        {this.state.licence.map(item => {
                            return (
                                <div className='box-price'>
                                    <h2>{this.props.lang == 'en' ? item.name : item.nameSv}</h2>
                                    <h4>{this.props.lang == 'en' ? item.shorDescriptionEn : item.shorDescriptionSv}</h4>
                                    <h5><span className='small-txt'>{'From'.translate(this.props.lang)}</span> <span>{Math.ceil(Number(item.monthlyPriceWithDisount) - Number(item.monthlyPriceWithDisount) * 0.20)} </span><span>{"SEK".translate(this.props.lang)}</span><span className='small-txt'>{'/mån/anv'.translate(this.props.lang)}</span></h5>
                                    <div className="button-container">
                                        {this.buyOrUpgrade(item)}
                                    </div>
                                    <p>{'Max participants: '.translate(this.props.lang) + item.numberOfParticipants.translate(this.props.lang)}</p>
                                    <p>{'Unlimited meeting length'.translate(this.props.lang)}</p>
                                    <div dangerouslySetInnerHTML={{ __html: this.props.lang == 'en' ? item.longDescriptionEn : item.longDescriptionSv }} onChange={() => { }}></div>
                                </div>
                            )
                        })}

                    </div>
                </section>
                <section className='price-info'>
                    <div>
                        {/* <h3>{'All prices quoted include VAT'.translate(this.props.lang)}</h3> */}
                        <p>{'All prices are excluding VAT and the quoted monthly fees apply when purchasing an annual license.'.translate(this.props.lang)}</p>
                    </div>
                </section>
            </div>

        );
    }
}

export default Page(PricesPage);

