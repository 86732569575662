import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import curoflowBigContact from '../../assets/svg/curoflowBigContact.svg';
import Page from '../../containers/page';
import { API_ENDPOINT } from "../../constants";
import chat from '../../assets/images/chat.png';
import ChatForm from '../../components/forms/chatForm';
import InfoModal from '../../components/infoModal';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
// import AOS from 'aos';
// import 'aos/dist/aos.css'

import {
    Container,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button
} from 'reactstrap';

import SupportForm from '../../components/forms/supportForm';
import ContactForm from '../../components/forms/contactForm';

function parseJSON(response) {
    return response.json().then((json) => {
        return {
            result: json,
            status: response.status,
        };
    });
}

var striptags = require('striptags');

class ContactPage extends Component {
    constructor(props) {
        super(props);
        this.player = React.createRef();

        this.state = {
            promotedProjects: [],
            poolActive: false,
            chatForm: false,
            supportForm: false,
            contactUs: [],
            contactPageShow: true,
            ...props.initialData
        };
    }

    sendMessage1 = (data) => {
        if (data.checked) {
            data.lang = this.props.lang
            this.setState({
                loading1: true
            }, () => {
                fetch(API_ENDPOINT + '/data/contact/new', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ data })
                }).then(parseJSON).then(({ result, status }) => {
                    if (result.error) {
                        this.setState({ error: result.error })
                    } else {
                    }
                })
            })
        }
    }

    sendMessage2 = (data) => {
        if (data.checked) {
            data.lang = this.props.lang
            this.setState({
                loading2: true
            }, () => {

                fetch(API_ENDPOINT + '/data/contact/new', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ data })
                }).then(parseJSON).then(({ result, status }) => {
                    if (result.error) {
                        this.setState({ error: result.error })
                    } else {
                        this.setState({
                            supportSuccesModal: true,
                            contactPageShow: false
                        }, () => {
                            this.setState({
                                contactPageShow: true
                            })
                        })
                    }
                })
            })
        }
    }

    sendMessage3 = (data) => {

        if (data.checked && data.email) {
            data.lang = this.props.lang
            this.setState({
                loading3: true
            }, () => {

                // fetch(window.location.origin == 'https://qa.curoflow.se' ? 'https://testapi.curoflow.se/support-category/tiket-site' : 'https://dashboardapi.curoflow.se/support-category/tiket-site', {
                // fetch('https://video-api.curoflow.se/support-category/tiket-site', {
                fetch(API_ENDPOINT + '/support-category/tiket-site', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(parseJSON).then(({ result, status }) => {
                    if (result.error) {
                        this.setState({ error: result.error })
                    } else {
                    }
                })

            })
        }
    }

    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        // AOS.init({
        //     // initialise with other settings
        //     duration: 1000
        // });

        this.get();
    }

    render() {

        return (
            <div style={{ background: 'white' }}>
                <div className={this.state.supportForm ? 'contact-popup open' : 'contact-popup'}>
                    <span className="close-icon" onClick={() => {
                        this.setState({
                            supportForm: false
                        })
                    }}>x</span>
                    {
                        !this.state.loading3 ?
                            <SupportForm onSubmit={this.sendMessage3} lang={this.props.lang} />
                            : null
                    }
                    {/* {
                        this.state.loading3 ?
                            <Player
                                ref={this.player}
                                autoplay={true}
                                keepLastFrame={true}
                                //loop={false}
                                src="https://assets4.lottiefiles.com/packages/lf20_oaw8d1yt.json"
                                style={{ height: '300px', width: '300px' }}
                            ></Player>
                            :
                            null
                    } */}
                    {
                        this.state.loading3 ?
                            <Player
                                ref={this.player}
                                autoplay={true}
                                onEvent={(event) => {
                                    if (event == 'complete') {
                                        this.setState({
                                            supportForm: false,
                                            loading3: false
                                        })
                                    }
                                }}
                                //keepLastFrame={true}
                                //loop={false}
                                controls={true}
                                src="https://assets4.lottiefiles.com/packages/lf20_oaw8d1yt.json"
                                style={{ height: '300px', width: '300px' }}
                            ></Player>
                            :
                            null
                    }
                </div>

                {/* <div className="chat-icon">
                    <img src={chat} alt="chatHealthcare chat Curoflow telemedicine" onClick={() => {
                        this.setState({
                            chatForm: !this.state.chatForm
                        })
                    }} />

                    <div className={this.state.chatForm ? 'chat-form-container opened' : 'chat-form-container'}>
                        <span className="close-icon" onClick={() => {
                            this.setState({
                                chatForm: false
                            })
                        }}>x</span>
                        <h2>{'Contact us'.translate(this.props.lang)}</h2>
                        {
                            !this.state.loading1 ?
                                <ChatForm onSubmit={this.sendMessage1} lang={this.props.lang} />
                                : null
                        }
                        {
                            this.state.loading1 ?
                                <Player
                                    ref={this.player}
                                    autoplay
                                    keepLastFrame
                                    // loop
                                    src="https://assets4.lottiefiles.com/packages/lf20_oaw8d1yt.json"
                                    style={{ height: '100px', width: '100px' }}
                                ></Player>
                                :
                                null
                        }
                        <div className="chat-info">
                            <h6>
                                {'You can also contact us by phone:'.translate(this.props.lang)}
                                <a href="tel:0107500655">{this.props.lang == 'en' ? '+46 (0)10-750 06 55' : '010-750 06 55'}</a>
                            </h6>
                        </div>
                    </div>
                </div> */}






                <div className={this.state.supportForm ? 'overlay overlay-active' : 'overlay'} onClick={() => {
                    this.setState({
                        supportForm: false
                    })
                }}></div>

                <section className="contactUs-info">
                    <div className="contactUs-content">
                        <h1 dangerouslySetInnerHTML={{ __html: Object.translate(this.state.contactPage && this.state.contactPage[0], "title", this.props.lang), }}></h1>
                        <div dangerouslySetInnerHTML={{ __html: Object.translate(this.state.contactPage && this.state.contactPage[0], 'shortDescription', this.props.lang) }}></div>
                        <ul>
                            <li>{'Email'.translate(this.props.lang)}: <a href={`mailto:${Object.translate(this.state.contactPage && this.state.contactPage[0], 'email', this.props.lang)}`}><span dangerouslySetInnerHTML={{ __html: Object.translate(this.state.contactPage && this.state.contactPage[0], 'email', this.props.lang) }}></span></a></li>
                            <li>{'Telephone'.translate(this.props.lang)}: <span dangerouslySetInnerHTML={{ __html: Object.translate(this.state.contactPage && this.state.contactPage[0], 'phone', this.props.lang) }}></span></li>
                        </ul>
                        <h2 dangerouslySetInnerHTML={{ __html: Object.translate(this.state.contactPage && this.state.contactPage[1], 'title', this.props.lang) }}></h2>
                                <div className='contact-p' dangerouslySetInnerHTML={{ __html: Object.translate(this.state.contactPage && this.state.contactPage[1], 'supportDescription', this.props.lang) }}></div>
                                <button
                                    onClick={() => {
                                        this.setState({
                                            supportForm: true,
                                        });
                                    }}
                                >
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: Object.translate(
                                                this.state.contactPage && this.state.contactPage[1],
                                                "buttonText",
                                                this.props.lang
                                            ),
                                        }}
                                    ></div>
                                </button>
                    </div>
                    <Isvg src={curoflowBigContact} />
                </section>
                <section className="sectionContactForm">

                    <div className="contact-form-bg">

                        <div className="main-container">
                            <div className="left">
                       
                                <h2 dangerouslySetInnerHTML={{
                                    __html: Object.translate(
                                        this.state.contactPage && this.state.contactPage[1],
                                        "title2",
                                        this.props.lang
                                    ),
                                }}></h2>
                                <div dangerouslySetInnerHTML={{
                                    __html: Object.translate(
                                        this.state.contactPage && this.state.contactPage[1],
                                        "text2",
                                        this.props.lang
                                    ),
                                }}></div>
                            </div>
                            <div className="right">
                                {
                                    this.state.contactPageShow ?
                                        <ContactForm
                                            onSubmit={this.sendMessage2}
                                            lang={this.props.lang}
                                        />
                                        :
                                        null
                                }

                            </div>

                        </div>

                    </div>
                </section>

                {
                    this.state.supportSuccesModal ?
                        <InfoModal
                            isOpen={this.state.supportSuccesModal}
                            toggle={() => this.setState({ supportSuccesModal: !this.state.supportSuccesModal })}
                            header={'Info'.translate(this.props.lang)}
                            info={'We will respond to your message as soon as posible.'.translate(this.props.lang)}
                            buttons={[
                                <button className='modal-button' onClick={() => this.setState({ supportSuccesModal: false })}>{'Close'.translate(this.props.lang)}</button>,
                            ]}

                        />
                        :
                        null
                }
            </div>
        );
    }
}


export default Page(ContactPage);
