import React from 'react';
import { reduxForm } from 'redux-form'
import moment from 'moment';
import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button
} from 'reactstrap';
import Isvg from 'react-inlinesvg';
import { renderFields } from './fields/fields';
import xIcon from '../../assets/svg/x.svg';
import { Player, Controls } from "@lottiefiles/react-lottie-player";


/**
* Generate modal form
* @author   Milan Stanojevic
*/
class form extends React.Component {

    constructor(props) {
        super(props);
        this.player = React.createRef();
        this.player2 = React.createRef();

        this.state = {};
    }

    componentDidUpdate(prevProps) {
        if (prevProps._saving && !this.props._saving) {
            this.setState({
                _saved: true
            }, () => {
                //this.player2.current.play();
                setTimeout(() => {
                    this.setState({
                        _saved: null
                    })
                }, 3000)
            })
        }

        if (!prevProps._saving && this.props._saving) {
            this.player.current.play();
        }
    }


    render() {

        const { handleSubmit, pristine, reset, submitting } = this.props;
        // console.log(this.props)
        return (

            <Modal isOpen={this.props.isOpen} className={this.props.className} centered size={this.props.size ? this.props.size : 'lg'}>
                <ModalHeader toggle={this.props.toggle} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={this.props.toggle}><Isvg src={xIcon} /></button>}>{this.props.title}</ModalHeader>
                <form onSubmit={handleSubmit}>

                    <ModalBody>
                        {this.props.beforeComponent ? this.props.beforeComponent : null}
                        {
                            renderFields(this.props.fields, this.props.lang)
                        }
                    
                        {
                            this.props.afterComponent ? this.props.afterComponent : null
                        }
                        {
                             renderFields(this.props.fieldsLast, this.props.lang)
                        }
                    </ModalBody>
                    <ModalFooter>
                        {
                            !this.props.disabledCreatedInfo && this.props.initialValues && (this.props.initialValues.tsCreated || this.props.initialValues.tsModified) ?

                                <div className="change-info">
                                    <p>{'Created'.translate(this.props.lang)} {this.props.initialValues.createdByUsername ? <>by <strong>{this.props.initialValues.createdByUsername}</strong></> : null} on <strong>{moment.unix(this.props.initialValues.tsCreated).format(`${this.props.dateFormat} HH:mm:ss`)}</strong></p>
                                    {this.props.initialValues.modifiedByUsername && this.props.initialValues.tsModified ?
                                        <p>{'Modified'.translate(this.props.lang)}  {this.props.initialValues.modifiedByUsername ? <>by  <strong>{this.props.initialValues.modifiedByUsername}</strong></> : null} on <strong>{moment.unix(this.props.initialValues.tsModified).format(`${this.props.dateFormat} HH:mm:ss`)}</strong></p>
                                        :
                                        <p>{'Not modified'.translate(this.props.lang)}</p>
                                    }

                                </div>
                                :
                                <div></div>
                        }
                        <div>
                        {!this.props.disableSave ?
                            <>

                                <Button type="submit" color="primary" onClick={() => this.setState({ modal: !this.state.modal })}>
                                    {
                                        this.props._saving ?
                                            <Player
                                                onEvent={(event) => {
                                                    if (event === "load") this.player.current.play();
                                                }}
                                                onStateChange={this.toggleVisibility}
                                                ref={this.player} // set the ref to your class instance
                                                autoplay={true}
                                                loop={true}
                                                controls={true}
                                                src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                style={{ height: "30px", width: "30px" }}
                                            ></Player>
                                            :

                                            null
                                    }
                                    {
                                        this.state._saved ?
                                            <div style={{ display: 'flex', alignItems: 'center' }}><Player
                                                onEvent={(event) => {
                                                    if (event === "load") this.player2.current.play();
                                                }}

                                                onStateChange={this.toggleVisibility}
                                                ref={this.player2} // set the ref to your class instance
                                                autoplay={true}
                                                loop={true}
                                                controls={true}
                                                src="https://assets10.lottiefiles.com/packages/lf20_CxyxLk.json"
                                                style={{ width: "30px" }}
                                            ></Player>
                                                {this.props.buttonConfirmText}
                                            </div>
                                            :
                                            null
                                    }
                                    {!this.props._saving && !this.state._saved ? this.props.buttonText : null}

                                </Button>{' '}
                            </>
                            :
                            null
                        }

                        {
                            this.props.additionalButton ?
                                this.props.additionalButton : null
                        }
</div>
                    </ModalFooter>
                </form>
            </Modal>
        )
    }
}

export default reduxForm({
    form: 'modalForm'  // a unique identifier for this form
})(form)
