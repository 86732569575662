import React, { Component } from "react";
import Link from "../components/link";

import Isvg from "react-inlinesvg";
import Page from "../containers/page";

import { Player, Controls } from "@lottiefiles/react-lottie-player";

import { Container, Row, Col, Button } from "reactstrap";

import FormBuilder from "../components/forms/formBuilder";
import ErrorModal from "../components/errorModal";

import { required } from "../components/forms/validation";
import { API_ENDPOINT } from "../constants";

import logoLogin from "../assets/svg/logo_login.svg";
import logoDark from "../assets/images/logo-dark.png";
import shapeBlue from "../assets/svg/shape-blue.svg";
import shapeGreen from "../assets/svg/shape-green.svg";
import shapeYellow from "../assets/images/shape-yellow.png";
import bankIdIcon from "../assets/svg/bankid-vector-logo.svg";
import lock from "../assets/images/lock.png";
import lockIcon from "../assets/svg/lock.svg";



/**
* Login page
* @author   Milan Stanojevic
*/
class VerificationCodePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
        };
    }

    componentDidMount() {
        if (typeof window !== "undefined") {
            window.scrollTo(0, 0);
        }

        if (this.props[0].location.state && this.props[0].location.state.email) {
            this.setState({ email: this.props[0].location.state.email }, () => {
                this.props[0].history.push({
                    state: { email: null }
                })
            })
        }
        let alias = localStorage.loginGroupAlias
        if (alias && alias !== 'noGroupAlias') {
            fetch(API_ENDPOINT + '/landing/page', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ alias })
            }).then(res => res.json()).then((result) => {
                if (result.error) {
                    this.props[0].history.push('/')
                } else {
                    // console.log(result)
                    this.setState({
                        data: result
                    })

                }
            });
        }

    }


    checkCode = (data, simulateFaild = false) => {
        if (typeof window != 'undefined') {
            data.token = localStorage.authToken;
        }
        if (typeof window != 'undefined' && localStorage.sessionId) {
            data.sessionId = localStorage.sessionId;
        }
        if (data.code && data.token) {
            this.setState({ loading: true, error: null }, () => {
                fetch(API_ENDPOINT + "/users/login/verify/code", {
                    method: "POST",
                    headers: {
                        "content-type": "application/json",
                    },
                    body: JSON.stringify(data),
                })
                    .then((res) => res.json())
                    .then((result) => {
                        this.setState({ loading: false })
                        if (result && result.verifyStatus) {
                            if (result.sessionId) {
                                localStorage.setItem('sessionId', result.sessionId)
                            }
                            this.props.verifyUser(() => {
                                this.props[0].history.push('/my-data/account')
                            }, () => {
                                // if (this.state.data) {
                                //     if (this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups.length) {
                                //         let arr = [];
                                //         for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                                //             arr.push(this.props.uData.clinicGroups[i]);
                                //         }
                                //         if (arr.filter((item) => item._id == this.state.data._id).length == 0) {
                                //             this.props.changeGroup(arr[0]._id)
                                //             localStorage.setItem('groupAlias', arr[0].alias);
                                //         } else {
                                //             this.props.changeGroup(this.state.data._id)
                                //         }
                                //     } else {
                                //         this.props.changeGroup(this.state.data._id)
                                //     }
                                // }

                            })
                        } else {
                            // if (result && result.invalid >= 5) {
                            //     this.setState({ logoutError: 'Login blocked on 30 minutes'.translate(this.props.lang) })
                            // }
                            if (!simulateFaild) {
                                this.setState({ error: 'Invalid code!'.translate(this.props.lang) })
                            } else {
                                this.props.signOut()
                            }

                        }
                    });
            })
        }

    }
    componentDidUpdate(prevProps) {
        if (this.props.uData) {
            this.props[0].history.push("/");
        }

    }

    render() {

        return (
            <div className="login-main-wrap">
                {this.state.loading ?
                    <div className="loader-wrap-verifcation-code">
                        <Player
                            autoplay={true}
                            loop={true}
                            src="/lf30_editor_l5cxzdyf.json"
                            style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px" }}
                        ></Player>

                    </div>
                    :
                    null
                }
                {this.state.logoutError ?
                    <ErrorModal lang={this.props.lang}
                        isOpen={this.state.logoutError}
                        toggle={() => this.props.signOut()}

                    >
                        {this.state.logoutError.translate(this.props.lang)}
                    </ErrorModal>
                    :
                    null

                }
                <div className="login-wrapper">
                    {!this.props.userVerificationInProgress ? (
                        <>
                            <Isvg src={shapeBlue} className="shape-blue" />
                            <Isvg src={shapeGreen} className="shape-green" />
                            <img src={shapeYellow} className="shape-yellow" />
                        </>
                    ) : null}
                    <div className="logo-header">
                        {/* <Isvg src={lockIcon} /> */}
                        {/* <img src={lock}/> */}

                    </div>

                    <div className="log-in">
                        <Container fluid>
                            <Row>
                                <Col lg={{ size: 12 }}>
                                    <div className='panel panel-login-warning' style={{
                                        minWidth: typeof window != "undefined" &&
                                            window.innerWidth < 768
                                            ? null
                                            : "",
                                    }}>

                                        <div className="lock-icon-wrap">
                                            <Isvg src={lockIcon} />
                                        </div>
                                        <div className="login-container">
                                            <div style={{ width: 400 }}>
                                                <h6 style={{ maxWidth: '100%', marginBottom: 20, fontWeight: 600, fontSize: 18, textAlign: 'center' }}>{"Please enter the authentication code sent to your email address.".translate(this.props.lang)} </h6>
                                                <h6 style={{ maxWidth: '100%', marginBottom: 20, fontWeight: 600, fontSize: 18, textAlign: 'center' }}>{this.state.email} </h6>
                                            </div>

                                            <div>
                                                <div className="login-form">
                                                    <FormBuilder
                                                        country={this.props.country}
                                                        dateFormat={this.props.dateFormat}
                                                        getStringDateTs={this.props.getStringDateTs}
                                                        lang={this.props.lang}
                                                        onSubmit={(data) => this.checkCode(data)}
                                                        buttonText={"Login".translate(this.props.lang)}
                                                        // buttonsWrapClassName={!this.state.error ? 'verification-buttons-wrap' : 'button-red'}
                                                        buttonsWrapClassName={'verification-buttons-wrap'}
                                                        additionalButton={
                                                            <Button color="primary" onClick={() => {
                                                                this.props.signOut()
                                                                // this.checkCode({ code: 'fail' }, true)
                                                            }}>{'Cancel'.translate(this.props.lang)}</Button>
                                                        }
                                                        // hideSubmitButton={this.state.error ? true : false}
                                                        fields={[
                                                            {
                                                                type: "row",
                                                                children: [
                                                                    {
                                                                        type: "col",
                                                                        width: {
                                                                            lg: 12,
                                                                            sm: 12,
                                                                            xs: 12,
                                                                        },
                                                                        children: [
                                                                            {
                                                                                type: "text",
                                                                                name: "code",

                                                                                placeholder: "6 digit code".translate(this.props.lang),
                                                                                validate: [
                                                                                    required(
                                                                                        "Code is required!".translate(
                                                                                            this.props.lang
                                                                                        )
                                                                                    ),
                                                                                ],
                                                                            },
                                                                            this.state.error ?
                                                                                {
                                                                                    type: "h6",
                                                                                    text: this.state.error.translate(this.props.lang),
                                                                                    style: { color: '#dc3545' }
                                                                                } :
                                                                                {
                                                                                    type: 'empty'
                                                                                },
                                                                        ],
                                                                    },

                                                                ],
                                                            },

                                                        ]}
                                                    ></FormBuilder>
                                                </div>


                                            </div>


                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        );
    }
}

export default Page(VerificationCodePage);
