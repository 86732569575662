import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import Page from '../../containers/page';
import { CircularProgressbar } from 'react-circular-progressbar';
// import 'react-circular-progressbar/dist/styles.css';

import { API_ENDPOINT } from "../../constants";
import { Player, Controls } from '@lottiefiles/react-lottie-player';

import AOS from 'aos';
import 'aos/dist/aos.css'

import {
    Container,
    Row,
    Col,
    Button
} from 'reactstrap';



var striptags = require('striptags');

function parseJSON(response) {
    return response.json().then((json) => {
        return {
            result: json,
            status: response.status,
        };
    });
}

class TehnicalRequirements extends Component {
    constructor(props) {
        super(props);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.player = React.createRef();

        this.state = {
            promotedProjects: [],
            poolActive: false,
            chatForm: false,
            yScroll2: 0,
            team: [],
            aboutUs: [],
            ...props.initialData,
            licence: []
        };
    }

    listenToScroll = () => {
        const yScroll2 =
            document.body.scrollTop || document.documentElement.scrollTop


        this.setState({
            yScroll2: yScroll2,
        })
    }

    sendMessage1 = (data) => {
        if (data.checked) {
            data.lang = this.props.lang
            this.setState({
                loading1: true
            }, () => {

                fetch(API_ENDPOINT + '/data/contact/new', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ data })
                }).then(parseJSON).then(({ result, status }) => {
                    if (result.error) {
                        this.setState({ error: result.error })
                    } else {
                    }
                })
            })
        }
    }

    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();
        this.updateWindowDimensions();
        window.addEventListener('scroll', this.listenToScroll)

        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        AOS.init({
            duration: 1000
        })

    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            // this.get()
        }
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }


    render() {
        return (
            <div className='tehnical-requierements-page'>

                <Container>
                    <Row>
                        {this.props.lang == 'en' ?
                            <Col lg="12">
                                <h2>Requirements for using Curoflow video</h2>
                                <h6>For video calls, you’ll need:</h6>
                                <ul>
                                    <li>Supported web browser (see the list below).</li>
                                    <li>A compatible device with minimum system requirements.</li>
                                    <li>A broadband connection to the internet.</li>
                                    <li>A built-in web camera or external USB camera.</li>
                                </ul>
                                <h6 className='highlight'>Bandwidth requirements per participant.</h6>
                                <p>The bandwidth used by Curoflow video varies for the best possible results on the participants’ networks.</p>

                                <table className='bandwidth-per-p-table'>
                                    <thead>
                                        <tr>
                                            <th>Average bandwidth per participant</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Meeting type</td>
                                            <td>Outgoing</td>
                                            <td>Incoming</td>
                                        </tr>
                                        <tr>
                                            <td>HD video</td>
                                            <td>2.2 Mbit/s</td>
                                            <td>1.6 Mbit/s</td>
                                        </tr>
                                        <tr>
                                            <td>Audio only</td>
                                            <td>12 Kbit/s</td>
                                            <td>18 Kbit/s</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <h6 className='highlight'>Use a supported web browser</h6>
                                <p>We recommend using the current version of one of the browsers listed below:</p>
                                <ul>
                                    <li>Chrome Browser</li>
                                    <li>Mozilla Firefox</li>
                                    <li>Microsoft Edge</li>
                                    <li>Apple Safari</li>
                                </ul>
                                <h6 className='highlight'>Allow Curoflow video to use your camera and microphone</h6>
                                <p>Before you start using Curoflow video, you need to allow access to your computer's camera and microphone.</p>
                                <h6 className='highlight'>Hardware recommendations</h6>
                                <h6>To use Curoflow video, the minimum system requirements are:</h6>
                                <ul>
                                    <li>Dual Core processor</li>
                                    <li>8GB memory</li>
                                </ul>
                                <h6>Recommended hardware:</h6>
                                <ul>
                                    <li>Quad Core processor</li>
                                    <li>16GB memory</li>
                                </ul>
                                <h6 className='highlight'>Fix problems with Curoflow video</h6>
                                <p>If you’re experiencing performance issues with Curoflow video, try the following steps:</p>
                                <ul>
                                    <li>Make sure you only have one Curoflow video tab open at a time. Close any open browser tabs, windows, or apps that aren’t currently being used.</li>
                                    <li>Turn off any browser extensions.</li>
                                    <li>Put your device on a flat and elevated surface to prevent your device from overheating. Avoid using your device on the floor or bed for a long period of time.</li>
                                    <li>Change the quality of your device’s video to a lower resolution.</li>
                                </ul>
                                <h6 className='highlight'>Participant limits</h6>

                                <table className='limits-table'>
                                    <thead>
                                        <tr>
                                            <th>Plan</th>
                                            <th>Number of participants</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Basic</td>
                                            <td>2</td>
                                        </tr>
                                        <tr>
                                            <td>Standard</td>
                                            <td>10</td>
                                        </tr>
                                        <tr>
                                            <td>Pro</td>
                                            <td>30</td>
                                        </tr>
                                        <tr>
                                            <td>Enterprise</td>
                                            <td>100</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <h6 className='highlight'>Meeting length limits</h6>

                                <table className='limits-table'>
                                    <thead>
                                        <tr>
                                            <th>Plan</th>
                                            <th>Max meeting length </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Basic</td>
                                            <td>20 minutes</td>
                                        </tr>
                                        <tr>
                                            <td>Standard</td>
                                            <td>Unlimited</td>
                                        </tr>
                                        <tr>
                                            <td>Pro</td>
                                            <td>Unlimited</td>
                                        </tr>
                                        <tr>
                                            <td>Enterprise</td>
                                            <td>Unlimited</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </Col> : <Col lg="12">
                                <h2>Requirements for using Curoflow video </h2>
                                <h6>For video calls, you’ll need:</h6>
                                <ul>
                                    <li>Supported web browser (see the list below).</li>
                                    <li>A compatible device with minimum system requirements.</li>
                                    <li>A broadband connection to the internet.</li>
                                    <li>A built-in web camera or external USB camera.</li>
                                </ul>
                                <h6 className='highlight'>Bandwidth requirements per participant.</h6>
                                <p>The bandwidth used by Curoflow video varies for the best possible results on the participants’ networks.</p>

                                <table className='bandwidth-per-p-table'>
                                    <thead>
                                        <tr>
                                            <th>Average bandwidth per participant</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Meeting type</td>
                                            <td>Outgoing</td>
                                            <td>Incoming</td>
                                        </tr>
                                        <tr>
                                            <td>HD video</td>
                                            <td>2.2 Mbit/s</td>
                                            <td>1.6 Mbit/s</td>
                                        </tr>
                                        <tr>
                                            <td>Audio only</td>
                                            <td>12 Kbit/s</td>
                                            <td>18 Kbit/s</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <h6 className='highlight'>Use a supported web browser</h6>
                                <p>We recommend using the current version of one of the browsers listed below:</p>
                                <ul>
                                    <li>Chrome Browser</li>
                                    <li>Mozilla Firefox</li>
                                    <li>Microsoft Edge</li>
                                    <li>Apple Safari</li>
                                </ul>
                                <h6 className='highlight'>Allow Curoflow video to use your camera and microphone</h6>
                                <p>Before you start using Curoflow video, you need to allow access to your computer's camera and microphone.</p>
                                <h6 className='highlight'>Hardware recommendations</h6>
                                <h6>To use Curoflow video, the minimum system requirements are:</h6>
                                <ul>
                                    <li>Dual Core processor</li>
                                    <li>8GB memory</li>
                                </ul>
                                <h6>Recommended hardware:</h6>
                                <ul>
                                    <li>Quad Core processor</li>
                                    <li>16GB memory</li>
                                </ul>
                                <h6 className='highlight'>Fix problems with Curoflow video</h6>
                                <p>If you’re experiencing performance issues with Curoflow video, try the following steps:</p>
                                <ul>
                                    <li>Make sure you only have one Curoflow video tab open at a time. Close any open browser tabs, windows, or apps that aren’t currently being used.</li>
                                    <li>Turn off any browser extensions.</li>
                                    <li>Put your device on a flat and elevated surface to prevent your device from overheating. Avoid using your device on the floor or bed for a long period of time.</li>
                                    <li>Change the quality of your device’s video to a lower resolution.</li>
                                </ul>
                                <h6 className='highlight'>Participant limits</h6>

                                <table className='limits-table'>
                                    <thead>
                                        <tr>
                                            <th>Plan</th>
                                            <th>Number of participants</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Basic</td>
                                            <td>2</td>
                                        </tr>
                                        <tr>
                                            <td>Standard</td>
                                            <td>10</td>
                                        </tr>
                                        <tr>
                                            <td>Pro</td>
                                            <td>30</td>
                                        </tr>
                                        <tr>
                                            <td>Enterprise</td>
                                            <td>100</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <h6 className='highlight'>Meeting length limits</h6>

                                <table className='limits-table'>
                                    <thead>
                                        <tr>
                                            <th>Plan</th>
                                            <th>Max meeting length </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Basic</td>
                                            <td>20 minutes</td>
                                        </tr>
                                        <tr>
                                            <td>Standard</td>
                                            <td>Unlimited</td>
                                        </tr>
                                        <tr>
                                            <td>Pro</td>
                                            <td>Unlimited</td>
                                        </tr>
                                        <tr>
                                            <td>Enterprise</td>
                                            <td>Unlimited</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </Col>}
                    </Row>
                </Container>
            </div>

        );
    }
}

export default Page(TehnicalRequirements);

