import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Button,
    Col,
    Input,
    Row
} from 'reactstrap';

import moment from 'moment';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import creditCardIcon from '../../assets/svg/ccard.svg';
import completed from '../../assets/svg/completed.svg';
import cycleIcon from '../../assets/svg/cycle.svg';
import detail from '../../assets/svg/detail.svg';
import infoIcon from '../../assets/svg/info-icon.svg';
import invoiceIcon from '../../assets/svg/invoice.svg';
import cardIcon from '../../assets/svg/l-card.svg';
import userIcon from '../../assets/svg/l-user.svg';
import paymIcon from '../../assets/svg/paym.svg';
import tabCfIcon from '../../assets/svg/t-cf.svg';
import tabCompletedIcon from '../../assets/svg/t-completed.svg';
import tabPaymentIcon from '../../assets/svg/t-payment.svg';
import tabReviewicon from '../../assets/svg/t-review.svg';
import Checkbox from '../../components/forms/fields/checkbox';
import SelectCountryOption from '../../components/forms/fields/selectCountryOption';
import InfoModal from '../../components/infoModal';
import { API_ENDPOINT } from '../../constants';
import { getSearchParams } from '../../helpers/linkHelper';

function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

class BuyPacket extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            form: {
                sameAsAccountDetails: true,
                notApplicable: false
            },
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            groups: [],
            subgroups: [],
            total: 0,
            loading: true,
            category: {},
            step: 1,
            moneyLeft: 0,
            leftDuration: 0,
            licencesNumber: this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.numberOfLicencesMain ? this.props.uData.licenceObj.numberOfLicencesMain : 1,
            cfOrder: false,
            licence: false,
            activeLicence: {},
            formAccountDetails: {
                country: 'Sweden'
            },
            upgradeDiscount: null,
            setDiscount: false
        };

    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }


    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }

        // if (prevProps.selectedGroup != this.props.selectedGroup) {
        //     this.get();
        // }

        // if (prevProps.selectedClinic != this.props.selectedClinic) {
        //     this.get();
        // }

        if (this.props[0].location && this.props[0].location.state && this.props[0].location.state._id && !Object.keys(this.state.activeLicence).length) {
            this.setState({ activeLicence: this.props[0].location.state, payment: 'Payson' }, () => {
                if (this.state.activeLicence && this.state.activeLicence.numberOfLicenses && this.state.licencesNumber && Number(this.state.activeLicence.numberOfLicenses) < Number(this.state.licencesNumber)) {
                    this.setState({
                        licencesNumber: Number(this.state.activeLicence.numberOfLicenses),

                    })
                }
                if (!this.state.ceckMonthyYearly) {
                    if (this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.licenceMain && this.props.uData.licenceObj.recuringPaymentTime && this.props.uData.licenceObj.recuringPaymentTime > Math.floor(new Date().getTime() / 1000) && this.props.uData.licenceObj.licenceMainDuration > 11) {
                        this.setState({
                            ceckMonthyYearly: 'Yearly'
                        })
                    } else {
                        this.setState({
                            ceckMonthyYearly: 'Monthly'
                        })
                    }

                }

            })

        } else if (this.state.licence && this.state.licence.length && !Object.keys(this.state.activeLicence).length) {
            this.setState({ activeLicence: this.state.licence[0], payment: 'Payson' }, () => {
                if (this.state.activeLicence && this.state.activeLicence.numberOfLicenses && this.state.licencesNumber && Number(this.state.activeLicence.numberOfLicenses) < Number(this.state.licencesNumber)) {
                    this.setState({
                        licencesNumber: Number(this.state.activeLicence.numberOfLicenses),

                    })
                }
                if (!this.state.ceckMonthyYearly) {
                    if (this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.licenceMain && this.props.uData.licenceObj.recuringPaymentTime && this.props.uData.licenceObj.recuringPaymentTime > Math.floor(new Date().getTime() / 1000) && this.props.uData.licenceObj.licenceMainDuration > 11) {
                        this.setState({
                            ceckMonthyYearly: 'Yearly'
                        })
                    } else {
                        this.setState({
                            ceckMonthyYearly: 'Monthly'
                        })
                    }
                }

            })
        } else if (!this.state.paymentConfm && this.state.licence && this.props[0].location && this.props[0].location.state && this.props[0].location.state.cfOrder) {
            this.setState({
                cfOrder: true,
                step: 4,
                paymentConfm: true
            })
        }

        if (this.state.step && prevState.step && this.state.step != prevState.step && this.state.step === 2) {
            this.accountDetails()

        }
        if (this.state.step && prevState.step && this.state.step != prevState.step) {
            if (this.paymentContainer) {
                this.paymentContainer.scrollTop = 0
            }
        }

        if (!this.state.setDiscount && this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.recuringPaymentTime && this.props.uData.licenceObj.recuringPaymentTime > Math.floor(new Date().getTime() / 1000)) {
            let firstDay = this.props.uData.licenceObj.licenceBoughtMain * 1000
            let lastDay = this.props.uData.licenceObj.recuringPaymentTime * 1000
            let leftDays = Math.round((new Date().getTime() - firstDay) / (1000 * 24 * 60 * 60))
            let upgradeDiscount = 100 / Math.round((Number(lastDay) - Number(firstDay)) / (1000 * 24 * 60 * 60)) * Number(leftDays)

            let start = moment.unix(this.props.uData.licenceObj.recuringPaymentTime)
            let end = moment.unix(Math.floor(new Date().getTime() / 1000))
            this.setState({
                setDiscount: true,
                upgradeDiscount: upgradeDiscount,
                moneyLeft: (Number(this.props.uData.licenceObj.price)).toFixed(2),
                leftDuration: Math.ceil(moment.duration(start.diff(end)).asDays())
            })
        }
    }


    buyOrUpgrade = (item) => {
        let disable = true
        if (this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.licenceIDMain) {
            if (this.props.uData.licenceObj.licenceIDMain == item._id && (this.props.uData.licenceObj.numberOfLicencesMain) == Number(item.numberOfLicenses)) {
                disable = false
            } else if ((this.props.uData.licenceObj.licenceMain == 'Pro' && item.name == 'Standard') || (this.props.uData.licenceObj.licenceMain == 'Enterprise' && (item.name == 'Standard' || item.name == 'Pro'))) {
                disable = false
            }


        }


        return disable

    }
    netsPayment = () => {
        fetch(API_ENDPOINT + '/create-payment', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({

                accountNumber: this.props.uData.accountNumber,
                phone: this.props.uData.phone,
                discount: this.state.moneyLeft,
                leftDuration: this.state.leftDuration,
                price: this.state.form.country == 'Sweden' ? Number(this.price(true)) + Number(this.vatPrice()) : Number(this.price(false)),
                fullPackagePrice:  (Number(this.priceNewPackage(false))).toFixed(2),
                numberOfLicences: this.state.licencesNumber ? this.state.licencesNumber : 1,
                licenceDuration: 1,
                licenceID: this.state.activeLicence._id ? this.state.activeLicence._id : null,
                upgradeLicence: this.state.moneyLeft > 0 ? true : false,
                accountDetails: this.state.form ? this.state.form : '',
                accountDetailsBilling: this.state.formAccountDetails ? this.state.formAccountDetails : '',
                billingDetailsSamAsAccount: this.state.form.sameAsAccountDetails,
                billingDetailsVatNumber: this.state.form.vatNumber,
                billingDetailsOrgNumber: this.state.form.organizationID,
                billingDetailsNotApplicable: this.state.form.notApplicable,
                billingDetailsConfirm: this.state.form.confirm,
            })
        }).then(res => res.json()).then((result) => {
            if (result.paymentId) {
                const checkoutKey = typeof window !== 'undefined' && window.origin == 'https://video.curoflow.se' ? 'live-checkout-key-b1879f9dacb34ae79f81d5995d50c385' : 'test-checkout-key-435faea3387243239006fd958155a9b8'
                const checkoutOptions = {
                    checkoutKey: checkoutKey,
                    paymentId: result.paymentId,
                    containerId: "checkout-container-div",
                    // theme: {
                    //     buttonRadius: "5px"
                    // }
                };
                const checkout = new window.Dibs.Checkout(checkoutOptions);
                // console.log(checkout);

                checkout.on('payment-completed', (response) => {
                    if (result.paymentId === checkoutOptions.paymentId) {
                        this.setState({
                            step: this.state.step + 1
                        }, () => {
                            fetch(API_ENDPOINT + '/get-created-payment/' + result.paymentId, {
                                method: 'GET',
                                headers: {
                                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                    'content-type': 'application/json'
                                },
                            }).then(res => res.json()).then((res) => {
                                this.props.verifyUser()
                            })
                        })
                    }

                });


            }
        })
    }

    invoicePayment = () => {
        fetch(API_ENDPOINT + '/create-invoice-bill', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({

                accountNumber: this.props.uData.accountNumber,
                phone: this.props.uData.phone,
                discount: this.state.moneyLeft,
                leftDuration: this.state.leftDuration,
                price: this.state.form.country == 'Sweden' ? Number(this.price(true)) + Number(this.vatPrice()) : Number(this.price(false)),
                numberOfLicences: this.state.licencesNumber ? this.state.licencesNumber : 1,
                licenceDuration: this.state.activeLicence && this.state.ceckMonthyYearly == 'Monthly' ? 1 : 12,
                licenceID: this.state.activeLicence._id ? this.state.activeLicence._id : null,
                upgradeLicence: this.state.moneyLeft > 0 && !(!(this.props.uData.licenceObj.numberOfLicencesMain < this.state.licencesNumber || this.props.uData.licenceObj.licenceIDMain != this.state.activeLicence._id) && this.props.uData.licenceObj.licenceMainDuration === 1 && this.state.ceckMonthyYearly == 'Yearly') ? true : false,
                cycleLeftMoney: this.cycleLeftMoneyFunc(),
                accountDetails: this.state.form ? this.state.form : '',
                accountDetailsBilling: this.state.formAccountDetails ? this.state.formAccountDetails : '',
                billingDetailsSamAsAccount: this.state.form.sameAsAccountDetails,
                billingDetailsVatNumber: this.state.form.vatNumber,
                billingDetailsOrgNumber: this.state.form.organizationID,
                billingDetailsNotApplicable: this.state.form.notApplicable,
                billingDetailsConfirm: this.state.form.confirm,
            })
        }).then(res => res.json()).then((result) => {
            this.props.verifyUser()
        })
    }

    accountDetails = () => {
        let form = this.state.form
        if (this.props.uData && this.props.uData.userData) {
            if (this.props.uData.userData.name && !form.name) {
                form.name = this.props.uData.userData.name
            }
            if (this.props.uData.email && !form.email) {
                form.email = this.props.uData.email
            }
            if (this.props.uData.phone && !form.phone) {
                form.phone = this.props.uData.phone
            }
            if (this.props.uData.street && !form.street) {
                form.street = this.props.uData.street
            }
            if (this.props.uData.city && !form.city) {
                form.city = this.props.uData.city
            }
            if (this.props.uData.zip && !form.zip) {
                form.zip = this.props.uData.zip
            }
            if (this.props.uData.userData.country && !form.country) {
                form.country = this.props.uData.userData.country
            }
            if (this.props.uData.userData.orgNr) {
                form.organizationID = this.props.uData.userData.orgNr
            }
            if (this.props.uData.userData.companyName && !form.companyName) {
                form.organizationName = this.props.uData.userData.companyName
            }
            if (this.props.uData.userData.vatNumber) {
                form.vatNumber = this.props.uData.userData.vatNumber
            }
        }
        this.setState({
            form
        })
    }
    vatNumberValidation = (value) => {
        let valid = true;

        if (value) {
            if (!/^[A-Za-z0-9]*$/.test(value)) {
                valid = false;
            } else {
                if (value.length < 2 || (value.length >= 2 && !/[a-zA-Z]{2}/.test(value.substr(0, 2)))) {
                    valid = false;
                }
                if (value.length < 6) {
                    valid = false;
                }

            }
        }


        return valid;
    }

    checkRequiredField = (goToNextTab = false) => {
        let nextStep = true
        this.setState({
            error: false,
            errorAccountDetails: false,
            errorAdditionalCountyOragisationId: false,
            errorAdditionalCountyVatId: false
        }, () => {
            let checkForm = this.state.form
            // console.log(checkForm);
            if (!checkForm.name || checkForm.name == '') {
                this.setState({
                    error: true
                })
                nextStep = false
            }
            if (!checkForm.email || checkForm.email == '') {
                this.setState({
                    error: true
                })
                nextStep = false
            }
            // if (!checkForm.phone || checkForm.phone == '') {
            //     this.setState({
            //         error: true
            //     })
            //     nextStep = false
            // }
            if (!checkForm.organizationName || checkForm.organizationName == '') {
                this.setState({
                    error: true
                })
                nextStep = false
            }
            if (!checkForm.street || checkForm.street == '') {
                this.setState({
                    error: true
                })
                nextStep = false
            }
            if (!checkForm.city || checkForm.city == '') {
                this.setState({
                    error: true
                })
                nextStep = false
            }
            if (!checkForm.zip || checkForm.zip == '') {
                this.setState({
                    error: true
                })
                nextStep = false
            }
            if (!checkForm.country || checkForm.country == '') {
                this.setState({
                    error: true
                })
                nextStep = false
            }
            if (checkForm.country != 'Sweden' && !checkForm.notApplicable && (!checkForm.vatNumber || checkForm.vatNumber == '')) {
                this.setState({
                    error: true
                })
                nextStep = false
            }



            if ((checkForm.country == 'Sweden' || (checkForm.notApplicable && checkForm.country != 'Sweden')) && (checkForm.organizationID == '' || !checkForm.organizationID)) {
                this.setState({
                    error: true
                })
                nextStep = false
            }
            if (!checkForm.confirm) {
                this.setState({
                    error: true
                })
                nextStep = false
            }

            if (!checkForm.sameAsAccountDetails) {
                let checkFormAccountDetails = this.state.formAccountDetails
                if (!checkFormAccountDetails.name || checkFormAccountDetails.name == '') {
                    this.setState({
                        errorAccountDetails: true
                    })
                    nextStep = false
                }
                if (!checkFormAccountDetails.email || checkFormAccountDetails.email == '') {
                    this.setState({
                        errorAccountDetails: true
                    })
                    nextStep = false
                }
                // if (!checkFormAccountDetails.phone || checkFormAccountDetails.phone == '') {
                //     this.setState({
                //         errorAccountDetails: true
                //     })
                //     nextStep = false
                // }
                if (!checkFormAccountDetails.organizationName || checkFormAccountDetails.organizationName == '') {
                    this.setState({
                        errorAccountDetails: true
                    })
                    nextStep = false
                }
                if (!checkFormAccountDetails.street || checkFormAccountDetails.street == '') {
                    this.setState({
                        errorAccountDetails: true
                    })
                    nextStep = false
                }
                if (!checkFormAccountDetails.city || checkFormAccountDetails.city == '') {
                    this.setState({
                        errorAccountDetails: true
                    })
                    nextStep = false
                }
                if (!checkFormAccountDetails.zip || checkFormAccountDetails.zip == '') {
                    this.setState({
                        errorAccountDetails: true
                    })
                    nextStep = false
                }
                if (checkFormAccountDetails.country != 'Sweden' && !checkForm.notApplicable && (!checkForm.vatNumber || checkForm.vatNumber == '')) {
                    this.setState({
                        errorAdditionalCountyVatId: true
                    })
                    nextStep = false
                }

                if ((checkFormAccountDetails.country == 'Sweden' || (checkForm.notApplicable && checkFormAccountDetails.country != 'Sweden')) && (checkForm.organizationID == '' || !checkForm.organizationID)) {
                    this.setState({
                        errorAdditionalCountyOragisationId: true
                    })
                    nextStep = false
                }

            }
            if (checkForm.vatNumber) {
                if (!this.vatNumberValidation(checkForm.vatNumber)) {
                    this.setState({
                        error: true
                    })
                    nextStep = false
                }
            }


            if (nextStep && goToNextTab) {
                this.setState({ step: this.state.step + 1 })
            }
        })
    }


    checkPaymentMethod = () => {
        this.state.ceckMonthyYearly == 'Monthly' && (this.state.activeLicence.monthlyPrice * this.state.licencesNumber) < 50000 ?
            this.setState({ payment: 'Card' })
            : null
        this.state.ceckMonthyYearly == 'Yearly' || (this.state.activeLicence.monthlyPrice && (this.state.activeLicence.monthlyPrice * this.state.licencesNumber) > 50000) ?
            this.setState({ payment: 'Invoice' })
            : null
    }

    cycleLeftMoneyFunc = () => {
        let cycleLeftMoney = 0
        if (this.state.activeLicence && this.state.moneyLeft > 0) {
            cycleLeftMoney = Number(this.state.licencesNumber) * Number(this.state.activeLicence.monthlyPrice) - Number(this.state.moneyLeft)
            cycleLeftMoney = Number(cycleLeftMoney) - (Number(cycleLeftMoney) * this.state.upgradeDiscount / 100)
        }

        return (cycleLeftMoney).toFixed(2)
    }

    price = (vat = false) => {
        let price = 0
        if (this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.licenceMainDuration) {
            if (this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.licenceMainDuration === 1 && this.state.ceckMonthyYearly === 'Yearly') {
                if (this.state.form && this.state.form.sameAsAccountDetails && this.state.form.country && this.state.form.country != 'Sweden' || (!this.state.form.sameAsAccountDetails && this.state.formAccountDetails.country != 'Sweden')) {
                    price = (Number(this.priceYearlyNewPackage(false))).toFixed(2)
                } else {
                    price = (Number(this.priceYearlyNewPackage(true))).toFixed(2)
                }

            } else {
                if (this.state.form && this.state.form.sameAsAccountDetails && this.state.form.country && this.state.form.country != 'Sweden' || (!this.state.form.sameAsAccountDetails && this.state.formAccountDetails.country != 'Sweden')) {
                    price = (Number(this.priceNewPackage(false)) - (Number(this.state.moneyLeft)) - Number(this.cycleMoney(false))).toFixed(2)
                } else {
                    price = (Number(this.priceNewPackage(true)) - (Number(this.state.moneyLeft - (this.state.moneyLeft * 0.20))) - Number(this.cycleMoney(true))).toFixed(2)
                }
            }
        } else {
            if (this.state.form && this.state.form.sameAsAccountDetails && this.state.form.country && this.state.form.country != 'Sweden' || (!this.state.form.sameAsAccountDetails && this.state.formAccountDetails.country != 'Sweden')) {
                price = (Number(this.priceNewPackage(false))).toFixed(2)
            } else {
                price = (Number(this.priceNewPackage(true))).toFixed(2)
            }
        }
        // console.log(price, 'price');
        return price
    }

    vatPrice = () => {
        let vat = 0
        if (this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.licenceMainDuration) {
            this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.licenceMainDuration === 1 && this.state.ceckMonthyYearly === 'Yearly' ?
                (this.state.form.country == 'Sweden' && this.state.form.sameAsAccountDetails) || (!this.state.form.sameAsAccountDetails && this.state.formAccountDetails.country == 'Sweden') ?
                    vat = (Number(this.priceYearlyNewPackage(true)) * 0.25).toFixed(2)
                    :
                    vat = 0

                :
                (this.state.form.country == 'Sweden' && this.state.form.sameAsAccountDetails) || (!this.state.form.sameAsAccountDetails && this.state.formAccountDetails.country == 'Sweden') ?
                    vat = ((Number(this.priceNewPackage(true)) - (Number(this.state.moneyLeft - (this.state.moneyLeft * 0.20))) - Number(this.cycleMoney(true))) * 0.25).toFixed(2)
                    :

                    vat = 0
        } else {
            (this.state.form.country == 'Sweden' && this.state.form.sameAsAccountDetails) || (!this.state.form.sameAsAccountDetails && this.state.formAccountDetails.country == 'Sweden') ?
                vat = ((Number(this.priceNewPackage(true))) * 0.25).toFixed(2)
                :
                vat = 0
        }


        // console.log(vat, 'vat');
        return vat
    }

    priceNewPackage = (vat = false) => {
        let newPrice = 0

        if (vat) {
            if (this.state.ceckMonthyYearly == 'Monthly') {
                newPrice = Number(this.state.licencesNumber) * Number(this.state.activeLicence.monthlyPrice)
                newPrice = Number(newPrice) - (Number(newPrice) * 0.20).toFixed(2)
            } else {
                newPrice = Number(this.state.licencesNumber) * Number(this.state.activeLicence.yearlyPrice)
                newPrice = Number(newPrice) - (Number(newPrice) * 0.20).toFixed(2)
            }
        } else {
            if (this.state.ceckMonthyYearly == 'Monthly') {
                newPrice = (Number(this.state.licencesNumber) * Number(this.state.activeLicence.monthlyPrice)).toFixed(2)
            } else {
                newPrice = (Number(this.state.licencesNumber) * Number(this.state.activeLicence.yearlyPrice)).toFixed(2)
            }
        }
        // console.log(newPrice, 'newPrice1');
        return newPrice
    }


    cycleMoney = (vat = false) => {
        let cycleLeftMoney = 0
        let price = 0
        let moneyLeft = 0
        let firstDay = this.props.uData.licenceObj.licenceBoughtMain * 1000
        let lastDay = this.props.uData.licenceObj.recuringPaymentTime * 1000
        let leftDays = Math.round((new Date().getTime() + (new Date().getTimezoneOffset() * 1000) - firstDay) / (1000 * 24 * 60 * 60))

        // console.log(firstDay, 'firstDay', lastDay, 'lastDay', leftDays, 'leftDays');
        if (this.state.ceckMonthyYearly !== 'Yearly' || (this.state.ceckMonthyYearly === 'Yearly' && this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.licenceMainDuration === 1)) {
            //    console.log('bbbbb');
            // if (vat) {
            //     moneyLeft = (Number(this.state.moneyLeft) - Number(this.state.moneyLeft) * 0.20).toFixed(2)
            //     price = Number(this.state.licencesNumber) * Number(this.state.activeLicence.monthlyPrice) - (Number(this.state.licencesNumber) * Number(this.state.activeLicence.monthlyPrice) * 0.20).toFixed(2)
            // } else {
            moneyLeft = Number(this.state.moneyLeft)
            price = (Number(this.state.licencesNumber) * Number(this.state.activeLicence.monthlyPrice)).toFixed(2)
            // }

        } else {
            // console.log('aaaaaaa');
            // if (vat) {
            //     moneyLeft = (Number(this.state.moneyLeft) - Number(this.state.moneyLeft) * 0.20).toFixed(2)
            //     price = Number(this.state.licencesNumber) * Number(this.state.activeLicence.yearlyPrice) - (Number(this.state.licencesNumber) * Number(this.state.activeLicence.monthlyPrice) * 0.20).toFixed(2)
            // } else {
            moneyLeft = Number(this.state.moneyLeft)
            price = (Number(this.state.licencesNumber) * Number(this.state.activeLicence.yearlyPrice)).toFixed(2)
            // }
        }

        if ((this.props.uData.licenceObj.numberOfLicencesMain != this.state.licencesNumber || this.props.uData.licenceObj.licenceIDMain != this.state.activeLicence._id) && vat) {
            cycleLeftMoney = (((Number(price) - Number(moneyLeft)) - (Number(price) - Number(moneyLeft)) * 0.2) / (Math.ceil((Number(lastDay) - Number(firstDay)) / (1000 * 24 * 60 * 60))) * Number(leftDays))
        } else if (this.props.uData.licenceObj.numberOfLicencesMain != this.state.licencesNumber || this.props.uData.licenceObj.licenceIDMain != this.state.activeLicence._id) {
            // console.log((Number(price) - Number(moneyLeft)), (Math.ceil((Number(lastDay) - Number(firstDay)), (1000 * 24 * 60 * 60))) * Number(leftDays));
            cycleLeftMoney = ((Number(price) - Number(moneyLeft)) / (Math.ceil((Number(lastDay) - Number(firstDay)) / (1000 * 24 * 60 * 60))) * Number(leftDays))
        }
        // console.log(cycleLeftMoney, 'cycleLeftMoney');
        return cycleLeftMoney
    }

    priceYearlyNewPackage = (vat = false) => {
        let newPrice = 0
        let monthlyPrice = (Number(this.state.licencesNumber) * Number(this.state.activeLicence.monthlyPrice)).toFixed(2)
        let monthlyPriceWithOutVat = (Number(this.state.licencesNumber) * Number(this.state.activeLicence.monthlyPrice) - (Number(this.state.licencesNumber) * Number(this.state.activeLicence.monthlyPrice) * 0.20)).toFixed(2)
        let yearlyPrice = (Number(this.state.licencesNumber) * Number(this.state.activeLicence.yearlyPrice)).toFixed(2)
        let yearlyPriceWithOutVat = (Number(this.state.licencesNumber) * Number(this.state.activeLicence.yearlyPrice) - (Number(this.state.licencesNumber) * Number(this.state.activeLicence.yearlyPrice) * 0.20)).toFixed(2)
        let moneyLeftYearly = (Number(monthlyPrice) - (Number(this.state.moneyLeft)) - Number(this.cycleMoney(false))).toFixed(2)
        let moneyLeftYearlyWithOutVat = (Number(monthlyPriceWithOutVat) - (Number(this.state.moneyLeft - (this.state.moneyLeft * 0.20))) - Number(this.cycleMoney(true))).toFixed(2)

        if (vat) {
            newPrice = (Number(yearlyPriceWithOutVat) + Number(moneyLeftYearlyWithOutVat)).toFixed(2)

        } else {
            newPrice = (Number(yearlyPrice) + Number(moneyLeftYearly)).toFixed(2)
        }
        // console.log(newPrice, 'newPrice2');
        return newPrice
    }

    render() {
        // console.log(this.props.uData.licenceObj, 'udata');
        // console.log(this.state.ceckMonthyYearly);
        // console.log(new Date(this.props.uData.licenceObj.recuringPaymentTime * 1000).getFullYear(), 'full');
        // console.log(new Date(this.props.uData.licenceObj.recuringPaymentTime * 1000).getMonth() + 12, '12');
        // console.log(new Date(this.props.uData.licenceObj.recuringPaymentTime * 1000).getMonth(), '12');
        // console.log(new Date(this.props.uData.licenceObj.recuringPaymentTime * 1000).getDate(), 'date');
        // console.log(moment(new Date(new Date(this.props.uData.licenceObj.recuringPaymentTime * 1000).getFullYear(), new Date(this.props.uData.licenceObj.recuringPaymentTime * 1000).getMonth() + 12, new Date(this.props.uData.licenceObj.recuringPaymentTime * 1000).getDate())).format('YYYY-MM-DD'));
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic })
        }

        let subgroups = [{ name: 'None', value: null }];
        if (this.props.group) {
            for (let i = 0; i < this.state.subgroups.length; i++) {
                if (this.state.subgroups[i].groups && this.state.subgroups[i].groups.indexOf(this.props.group) !== -1) {
                    subgroups.push(this.state.subgroups[i]);
                }
            }
        }

        let nextYearTs;
        if (this.props.uData?.licenceObj?.recuringPaymentTime) {
            nextYearTs = this.props.uData.licenceObj.recuringPaymentTime + 60 * 60 * 24 * 365;
        }

        return (
            <div className="dashboard dashboard-cf" style={this.state.step == 2 ? { marginTop: 25 } : null}>

                <div className='container page-cf' ref={(node) => this.paymentContainer = node}>
                    <Row>
                        <Col lg="12">
                            <div className='panel'>
                                <div className='buypacket-page'>

                                    <div className='tabs-progressbar'>
                                        <div className={this.state.step >= 1 ? 'tab active' : 'tab'}>
                                            <Isvg src={tabCfIcon} />
                                            <h3>{'Configure service'.translate(this.props.lang)}</h3>
                                        </div>
                                        <div className={this.state.step >= 2 ? 'tab active' : 'tab'}>
                                            <Isvg src={detail} />
                                            <h3>{'Billing details'.translate(this.props.lang)}</h3>
                                        </div>
                                        <div className={this.state.step >= 3 ? 'tab active' : 'tab'}>
                                            <Isvg src={tabReviewicon} />
                                            <h3>{'Review order'.translate(this.props.lang)}</h3>
                                        </div>
                                        <div className={this.state.step >= 4 ? 'tab active' : 'tab'}>
                                            <Isvg src={tabPaymentIcon} />
                                            <h3>{'Payment'.translate(this.props.lang)}</h3>
                                        </div>
                                        <div className={this.state.step == 5 ? 'tab active' : 'tab'}>
                                            <Isvg src={tabCompletedIcon} />
                                            <h3>{'Order completed'.translate(this.props.lang)}</h3>
                                        </div>
                                    </div>



                                    {this.state.step == 1 ?
                                        <div className='buypacket-options'>

                                            <div className='buypacket-options-content'>
                                                <div className='title'>
                                                    <div className='step'>
                                                        <span>1</span>
                                                    </div>
                                                    <h3>{'How many licenses do you need?'.translate(this.props.lang)}</h3>
                                                    <span className='info'></span>
                                                </div>
                                                <div className='body body-step-1'>
                                                    <div class="quantity">
                                                        <input type="number" value={this.state.licencesNumber} onChange={(e) => {
                                                            let val = e.target.value;
                                                            if (Number(val) > 0) {
                                                                if (this.state.activeLicence && Number(val) <= this.state.activeLicence.numberOfLicenses) {
                                                                    this.setState({
                                                                        licencesNumber: e.target.value
                                                                    })
                                                                } else if (this.state.activeLicence && this.state.activeLicence.numberOfLicenses) {
                                                                    this.setState({
                                                                        licencesNumber: this.state.activeLicence.numberOfLicenses
                                                                    })
                                                                }
                                                            }


                                                        }} />
                                                        <div class="quantity-nav">
                                                            <div class="quantity-button quantity-up" onClick={() => this.setState({
                                                                licencesNumber: this.state.activeLicence && this.state.licencesNumber + 1 > this.state.activeLicence.numberOfLicenses ? this.state.activeLicence.numberOfLicenses : Number(this.state.licencesNumber) + 1
                                                            })}>+</div>
                                                            <div class="quantity-button quantity-down" onClick={() => this.setState({
                                                                licencesNumber: this.props.uData.licenceObj && this.props.uData.licenceObj.numberOfLicencesMain && Number(this.state.licencesNumber) - 1 <= this.props.uData.licenceObj.numberOfLicencesMain ? this.props.uData.licenceObj.numberOfLicencesMain : this.state.licencesNumber && this.state.licencesNumber > 1 ? Number(this.state.licencesNumber) - 1 : 1
                                                            })}>-</div>
                                                        </div>
                                                    </div>
                                                    <span></span>
                                                </div>
                                            </div>

                                            <div className='buypacket-options-content'>
                                                <div className='title'>
                                                    <div className='step'>
                                                        <span>2</span>
                                                    </div>
                                                    <h3>{'Choose your plan'.translate(this.props.lang)}</h3>
                                                </div>
                                                <div className='body body-flex'>
                                                    {
                                                        this.state.licence && this.state.licence.length ?
                                                            this.state.licence.map(item => {
                                                                if (this.buyOrUpgrade(item))
                                                                    return (
                                                                        <div onClick={() => {
                                                                            if (this.buyOrUpgrade(item)) {
                                                                                this.setState({ activeLicence: item, payment: 'Payson' }, () => {
                                                                                    if (this.state.activeLicence && this.state.activeLicence.numberOfLicenses && this.state.licencesNumber && Number(this.state.activeLicence.numberOfLicenses) < Number(this.state.licencesNumber)) {
                                                                                        this.setState({
                                                                                            licencesNumber: Number(this.state.activeLicence.numberOfLicenses),

                                                                                        })
                                                                                    }
                                                                                    if (!this.state.ceckMonthyYearly) {
                                                                                        this.setState({
                                                                                            ceckMonthyYearly: 'Monthly'
                                                                                        })
                                                                                    }

                                                                                })
                                                                            }

                                                                        }} className={this.state.activeLicence && this.state.activeLicence._id == item._id ? 'bp-plan bn-plan-active' : 'bp-plan'}>
                                                                            <h6>{this.props.lang == 'se' ? item.nameSv : item.name}</h6>
                                                                            <span> {item.numberOfParticipants} {'participants/meeting'.translate(this.props.lang)}</span>
                                                                            {item.numberOfLicenses && item.numberOfLicenses < 999 ?
                                                                                <p>{'Maximum'.translate(this.props.lang)} {item.numberOfLicenses} {'licenses'.translate(this.props.lang)}</p>
                                                                                :
                                                                                <p>{'Unlimited number of licenses'.translate(this.props.lang)}</p>
                                                                            }
                                                                            <h4>{this.state.licencesNumber * (this.state.activeLicence.monthlyPrice ? item.monthlyPrice : item.yearlyPrice)} {'SEK/month'.translate(this.props.lang)}</h4>

                                                                        </div>
                                                                    )
                                                            })
                                                            :
                                                            null

                                                    }
                                                </div>
                                            </div>

                                            <div className='buypacket-options-content'>
                                                <div className='title'>
                                                    <div className='step'>
                                                        <span>3</span>
                                                    </div>
                                                    <h3>{'Choose your subscription cycle'.translate(this.props.lang)}</h3>
                                                </div>
                                                <div className='body body-flex'>
                                                    {
                                                        this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.licenceMain && this.props.uData.licenceObj.recuringPaymentTime && this.props.uData.licenceObj.recuringPaymentTime > Math.floor(new Date().getTime() / 1000) && this.props.uData.licenceObj.licenceMainDuration > 11 ?
                                                            null
                                                            :
                                                            <div onClick={() => this.setState({ ceckMonthyYearly: 'Monthly' })} className={this.state.activeLicence && this.state.ceckMonthyYearly == 'Monthly' ? 'sub active' : 'sub'}>
                                                                <h6>{'Monthly'.translate(this.props.lang)}</h6>
                                                                <h4>{this.state.activeLicence.monthlyPrice * this.state.licencesNumber} {'SEK/month'.translate(this.props.lang)}</h4>
                                                                {this.state.activeLicence.numberOfLicenses && this.state.activeLicence.numberOfLicenses < 999 ?
                                                                    <p>{'Maximum'.translate(this.props.lang)} {this.state.activeLicence.numberOfLicenses} {'licenses'.translate(this.props.lang)}</p>
                                                                    :
                                                                    <p>{'Unlimited number of licenses'.translate(this.props.lang)}</p>
                                                                }
                                                                {/* {
                                                                    this.state.moneyLeft > 0 ?
                                                                        <div style={{ display: 'flex' }}>
                                                                            <div className='tooltip-wrap-buy'>
                                                                                <Isvg src={infoIcon} />
                                                                                <div className='tooltip-text'>{'Amount that will be credited based on the pre-paid amount from your current plan.'.translate(this.props.lang)}</div>
                                                                            </div>
                                                                            <p>{'Credit'.translate(this.props.lang) + ': ' + this.state.moneyLeft + ' SEK'}</p>
                                                                        </div>
                                                                        :
                                                                        null
                                                                } */}
                                                            </div>
                                                    }

                                                    {/* {
                                                        this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.licenceMain && this.props.uData.licenceObj.recuringPaymentTime && this.props.uData.licenceObj.recuringPaymentTime > Math.floor(new Date().getTime() / 1000) && this.props.uData.licenceObj.licenceMainDuration < 11 ?
                                                            null
                                                            : */}
                                                    <div onClick={() => this.setState({ ceckMonthyYearly: 'Yearly' })} className={this.state.activeLicence && this.state.ceckMonthyYearly == 'Yearly' ? 'sub active' : 'sub'}>
                                                        <h6>{'Yearly'.translate(this.props.lang)}</h6>
                                                        <h4>{this.state.activeLicence.yearlyPrice * this.state.licencesNumber} {'SEK'.translate(this.props.lang)}</h4>
                                                        {this.state.activeLicence.numberOfLicenses && this.state.activeLicence.numberOfLicenses < 999 ?
                                                            <p>{'Maximum'.translate(this.props.lang)} {this.state.activeLicence.numberOfLicenses} {'licenses'.translate(this.props.lang)}</p>
                                                            :
                                                            <p>{'Unlimited number of licenses'.translate(this.props.lang)}</p>
                                                        }
                                                        <span className='save'>{'Save'.translate(this.props.lang)} {this.state.activeLicence.yearlyDiscountInPercent + ' %'}</span>
                                                        {/* {
                                                            this.state.moneyLeft > 0 ?
                                                                <div style={{ display: 'flex' }}>
                                                                    <div className='tooltip-wrap-buy'>
                                                                        <Isvg src={infoIcon} />
                                                                        <div className='tooltip-text'>{'Amount that will be credited based on the pre-paid amount from your current plan.'.translate(this.props.lang)}</div>
                                                                    </div>
                                                                    <p>{'Credit'.translate(this.props.lang) + ': ' + this.state.moneyLeft + ' SEK'}</p>
                                                                </div>
                                                                :
                                                                null
                                                        } */}
                                                    </div>
                                                    {/* } */}
                                                </div>
                                            </div>
                                            {
                                                (!this.state.moneyLeft || (this.state.moneyLeft && !(this.props.uData.licenceObj.numberOfLicencesMain < this.state.licencesNumber || this.props.uData.licenceObj.licenceIDMain != this.state.activeLicence._id) && this.props.uData.licenceObj.licenceMainDuration === 1 && this.state.ceckMonthyYearly == 'Yearly') || (this.props.uData.licenceObj.numberOfLicencesMain === this.state.licencesNumber && this.props.uData.licenceObj.licenceIDMain === this.state.activeLicence._id)) ?
                                                    null
                                                    :
                                                    <div className='buypacket-options-content'>
                                                        <div className='title'>
                                                            <div className='step'>
                                                                <span>4</span>
                                                            </div>
                                                            <h3>{'Credit'.translate(this.props.lang)}</h3>
                                                        </div>
                                                        <div className='body body-flex'>
                                                            {
                                                                <p>{this.state.moneyLeft + ' SEK' + ' will be credited based on the pre-paid amount from your current plan.'.translate(this.props.lang)}</p>
                                                            }
                                                        </div>
                                                    </div>

                                            }


                                        </div>
                                        : null}

                                    {this.state.step == 2 ?
                                        <div className='order-info'>

                                            <div className='order-info-title'>
                                                <Isvg src={userIcon} />
                                                <h4>{'Account Details'.translate(this.props.lang)}</h4>
                                            </div>
                                            <div className='order-info-column-container'>
                                                <div className='order-info-column form-payment'>
                                                    <div className='order-info-column'>
                                                        <div className={this.state.error && (!this.state.form.name || this.state.form.name == '') ? 'order-info-row error' : 'order-info-row'}>
                                                            <label>{'First and last name'.translate(this.props.lang) + '*'}</label>
                                                            <Input onChange={(e) => {

                                                                let form = this.state.form
                                                                form.name = e.target.value
                                                                this.setState({
                                                                    form
                                                                }, () => this.checkRequiredField())
                                                            }} value={this.state.form.name} />
                                                        </div>
                                                        <div className={this.state.error && (!this.state.form.email || this.state.form.email == '') ? 'order-info-row error' : 'order-info-row'}>
                                                            <label>{'Email'.translate(this.props.lang) + '*'}</label>
                                                            <Input onChange={(e) => {
                                                                let form = this.state.form
                                                                form.email = e.target.value
                                                                this.setState({
                                                                    form
                                                                }, () => this.checkRequiredField())
                                                            }} value={this.state.form.email} />
                                                        </div>
                                                        <div className={/*this.state.error && (!this.state.form.phone || this.state.form.phone == '') ? 'order-info-row error' : */'order-info-row'}>
                                                            <label>{'Phone number'.translate(this.props.lang)}</label>
                                                            <Input onChange={(e) => {
                                                                let form = this.state.form
                                                                form.phone = e.target.value
                                                                this.setState({
                                                                    form
                                                                }, () => this.checkRequiredField())
                                                            }} value={this.state.form.phone} />
                                                        </div>
                                                        <div className={this.state.error && (!this.state.form.organizationName || this.state.form.organizationName == '') ? 'order-info-row error' : 'order-info-row'}>
                                                            <label>{'Organization name'.translate(this.props.lang) + '*'}</label>
                                                            <Input onChange={(e) => {
                                                                let form = this.state.form
                                                                form.organizationName = e.target.value
                                                                this.setState({
                                                                    form
                                                                }, () => this.checkRequiredField())
                                                            }} value={this.state.form.organizationName} />
                                                        </div>
                                                        <div className='order-info-row'>
                                                            <label>{'Number of employees'.translate(this.props.lang)}</label>
                                                            <Input onChange={(e) => {
                                                                let form = this.state.form
                                                                form.numberOfEmployees = e.target.value
                                                                this.setState({
                                                                    form
                                                                }, () => this.checkRequiredField())
                                                            }} value={this.state.form.numberOfEmployees} />
                                                        </div>

                                                    </div>
                                                    <div className='order-info-column'>
                                                        <div className={this.state.error && (!this.state.form.street || this.state.form.street == '') ? 'order-info-row error' : 'order-info-row'}>
                                                            <label>{'Address'.translate(this.props.lang) + '*'}</label>
                                                            <Input onChange={(e) => {
                                                                let form = this.state.form
                                                                form.street = e.target.value
                                                                this.setState({
                                                                    form
                                                                }, () => this.checkRequiredField())
                                                            }} value={this.state.form.street} />
                                                        </div>
                                                        <div className={this.state.error && (!this.state.form.city || this.state.form.city == '') ? 'order-info-row error' : 'order-info-row'}>
                                                            <label>{'City'.translate(this.props.lang) + '*'}</label>
                                                            <Input onChange={(e) => {
                                                                let form = this.state.form
                                                                form.city = e.target.value
                                                                this.setState({
                                                                    form
                                                                }, () => this.checkRequiredField())
                                                            }} value={this.state.form.city} />
                                                        </div>
                                                        <div className='order-info-row'>
                                                            <label>{'State'.translate(this.props.lang)}</label>
                                                            <Input onChange={(e) => {
                                                                let form = this.state.form
                                                                form.state = e.target.value
                                                                this.setState({
                                                                    form
                                                                }, () => this.checkRequiredField())
                                                            }} value={this.state.form.state} />
                                                        </div>
                                                        <div className={this.state.error && (!this.state.form.zip || this.state.form.zip == '') ? 'order-info-row error' : 'order-info-row'}>
                                                            <label>{'Zip/Postal code'.translate(this.props.lang) + '*'}</label>
                                                            <Input onChange={(e) => {
                                                                let form = this.state.form
                                                                form.zip = e.target.value
                                                                this.setState({
                                                                    form
                                                                }, () => this.checkRequiredField())
                                                            }} value={this.state.form.zip} />
                                                        </div>
                                                        <div className={this.state.error && (!this.state.form.country || this.state.form.country == '') ? 'order-info-row error' : 'order-info-row'}>
                                                            <label>{'Country'.translate(this.props.lang) + '*'}</label>
                                                            <SelectCountryOption hideLabel onChange={(e) => {
                                                                let form = this.state.form
                                                                form.country = e.target.value
                                                                this.setState({
                                                                    form
                                                                }, () => this.checkRequiredField())
                                                            }} type='select' value={this.state.form.country} />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className='order-info-title'>
                                                <Isvg src={userIcon} />
                                                <h4>{'Billing details'.translate(this.props.lang)}</h4>
                                            </div>
                                            <div className='order-info-column-container'>
                                                <div className='order-info-column form-payment'>
                                                    <div className='order-info-column form-biling-details'>
                                                        <div className='order-info-row'>
                                                            <Checkbox
                                                                onChange={(e) => {
                                                                    let form = this.state.form;
                                                                    form.sameAsAccountDetails = e.target.checked
                                                                    this.setState({
                                                                        form
                                                                    }, () => this.checkRequiredField())
                                                                }}
                                                                checked={this.state.form.sameAsAccountDetails}
                                                                label={'Same as Account details'.translate(this.props.lang)}
                                                            />
                                                        </div>
                                                        {!this.state.form.sameAsAccountDetails ?

                                                            <div className='order-info-column form-payment'>
                                                                <div className='order-info-column'>
                                                                    <div className={this.state.errorAccountDetails && (!this.state.formAccountDetails.name || this.state.formAccountDetails.name == '') ? 'order-info-row error' : 'order-info-row'}>
                                                                        <label>{'First and last name'.translate(this.props.lang) + '*'}</label>
                                                                        <Input onChange={(e) => {

                                                                            let formAccountDetails = this.state.formAccountDetails
                                                                            formAccountDetails.name = e.target.value
                                                                            this.setState({
                                                                                formAccountDetails
                                                                            }, () => this.checkRequiredField())
                                                                        }} value={this.state.formAccountDetails.name} />
                                                                    </div>
                                                                    <div className={this.state.errorAccountDetails && (!this.state.formAccountDetails.email || this.state.formAccountDetails.email == '') ? 'order-info-row error' : 'order-info-row'}>
                                                                        <label>{'Email'.translate(this.props.lang) + '*'}</label>
                                                                        <Input onChange={(e) => {
                                                                            let formAccountDetails = this.state.formAccountDetails
                                                                            formAccountDetails.email = e.target.value
                                                                            this.setState({
                                                                                formAccountDetails
                                                                            }, () => this.checkRequiredField())
                                                                        }} value={this.state.formAccountDetails.email} />
                                                                    </div>
                                                                    <div className={/*this.state.errorAccountDetails && (!this.state.formAccountDetails.phone || this.state.formAccountDetails.phone == '') ? 'order-info-row error' : */'order-info-row'}>
                                                                        <label>{'Phone number'.translate(this.props.lang)}</label>
                                                                        <Input onChange={(e) => {
                                                                            let formAccountDetails = this.state.formAccountDetails
                                                                            formAccountDetails.phone = e.target.value
                                                                            this.setState({
                                                                                formAccountDetails
                                                                            }, () => this.checkRequiredField())
                                                                        }} value={this.state.formAccountDetails.phone} />
                                                                    </div>
                                                                    <div className={this.state.errorAccountDetails && (!this.state.formAccountDetails.organizationName || this.state.formAccountDetails.organizationName == '') ? 'order-info-row error' : 'order-info-row'}>
                                                                        <label>{'Organization name'.translate(this.props.lang) + '*'}</label>
                                                                        <Input onChange={(e) => {
                                                                            let formAccountDetails = this.state.formAccountDetails
                                                                            formAccountDetails.organizationName = e.target.value
                                                                            this.setState({
                                                                                formAccountDetails
                                                                            }, () => this.checkRequiredField())
                                                                        }} value={this.state.formAccountDetails.organizationName} />
                                                                    </div>
                                                                    <div className='order-info-row'>
                                                                        <label>{'Number of employees'.translate(this.props.lang)}</label>
                                                                        <Input onChange={(e) => {
                                                                            let formAccountDetails = this.state.formAccountDetails
                                                                            formAccountDetails.numberOfEmployees = e.target.value
                                                                            this.setState({
                                                                                formAccountDetails
                                                                            }, () => this.checkRequiredField())
                                                                        }} value={this.state.formAccountDetails.numberOfEmployees} />
                                                                    </div>

                                                                </div>
                                                                <div className='order-info-column'>
                                                                    <div className={this.state.errorAccountDetails && (!this.state.formAccountDetails.street || this.state.formAccountDetails.street == '') ? 'order-info-row error' : 'order-info-row'}>
                                                                        <label>{'Address'.translate(this.props.lang) + '*'}</label>
                                                                        <Input onChange={(e) => {
                                                                            let formAccountDetails = this.state.formAccountDetails
                                                                            formAccountDetails.street = e.target.value
                                                                            this.setState({
                                                                                formAccountDetails
                                                                            }, () => this.checkRequiredField())
                                                                        }} value={this.state.formAccountDetails.street} />
                                                                    </div>
                                                                    <div className={this.state.errorAccountDetails && (!this.state.formAccountDetails.city || this.state.formAccountDetails.city == '') ? 'order-info-row error' : 'order-info-row'}>
                                                                        <label>{'City'.translate(this.props.lang) + '*'}</label>
                                                                        <Input onChange={(e) => {
                                                                            let formAccountDetails = this.state.formAccountDetails
                                                                            formAccountDetails.city = e.target.value
                                                                            this.setState({
                                                                                formAccountDetails
                                                                            }, () => this.checkRequiredField())
                                                                        }} value={this.state.formAccountDetails.city} />
                                                                    </div>
                                                                    <div className='order-info-row'>
                                                                        <label>{'State'.translate(this.props.lang)}</label>
                                                                        <Input onChange={(e) => {
                                                                            let formAccountDetails = this.state.formAccountDetails
                                                                            formAccountDetails.state = e.target.value
                                                                            this.setState({
                                                                                formAccountDetails
                                                                            }, () => this.checkRequiredField())
                                                                        }} value={this.state.formAccountDetails.state} />
                                                                    </div>
                                                                    <div className={this.state.errorAccountDetails && (!this.state.formAccountDetails.zip || this.state.formAccountDetails.zip == '') ? 'order-info-row error' : 'order-info-row'}>
                                                                        <label>{'Zip/Postal code'.translate(this.props.lang) + '*'}</label>
                                                                        <Input onChange={(e) => {
                                                                            let formAccountDetails = this.state.formAccountDetails
                                                                            formAccountDetails.zip = e.target.value
                                                                            this.setState({
                                                                                formAccountDetails
                                                                            }, () => this.checkRequiredField())
                                                                        }} value={this.state.formAccountDetails.zip} />
                                                                    </div>
                                                                    <div className={this.state.errorAccountDetails && (!this.state.formAccountDetails.country || this.state.formAccountDetails.country == '') ? 'order-info-row error' : 'order-info-row'}>
                                                                        <label>{'Country'.translate(this.props.lang) + '*'}</label>
                                                                        <SelectCountryOption hideLabel onChange={(e) => {
                                                                            let formAccountDetails = this.state.formAccountDetails
                                                                            formAccountDetails.country = e.target.value
                                                                            this.setState({
                                                                                formAccountDetails
                                                                            }, () => this.checkRequiredField())
                                                                        }} type='select' value={this.state.formAccountDetails.country} />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            : null}
                                                        <div className={this.state.errorAdditionalCountyVatId || (this.state.error && this.state.form.vatNumber && !this.vatNumberValidation(this.state.form.vatNumber)) ? 'order-info-row error input-with-check' : this.state.form.sameAsAccountDetails && this.state.error && (this.state.form.country != 'Sweden' && !this.state.form.notApplicable && (!this.state.form.vatNumber || this.state.form.vatNumber == '')) ? 'order-info-row error input-with-check' : 'order-info-row input-with-check'}>
                                                            <label>{'VAT ID Number'.translate(this.props.lang)}</label>
                                                            <div className="vat-number-input-wrap">
                                                                <Input onChange={(e) => {
                                                                    let form = this.state.form
                                                                    form.vatNumber = e.target.value
                                                                    this.setState({
                                                                        form
                                                                    }, () => this.checkRequiredField())
                                                                }} value={this.state.form.vatNumber} />
                                                                {
                                                                    this.state.form.vatNumber && !this.vatNumberValidation(this.state.form.vatNumber) ?
                                                                        <div style={{ marginTop: 24 }} className='tooltip-wrap'>
                                                                            <Isvg src={infoIcon} />
                                                                            <div className='tooltip-text'>{'Vat Number is not valid'.translate(this.props.lang)}</div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }

                                                            </div>
                                                            {
                                                                (this.state.form.sameAsAccountDetails && this.state.form && this.state.form.country && this.state.form.country != 'Sweden') || (this.state.formAccountDetails && this.state.formAccountDetails.country && this.state.formAccountDetails.country != 'Sweden') ?
                                                                    <div>
                                                                        <Checkbox
                                                                            onChange={(e) => {
                                                                                let form = this.state.form;
                                                                                form.notApplicable = e.target.checked
                                                                                this.setState({
                                                                                    form
                                                                                }, () => this.checkRequiredField())
                                                                            }}
                                                                            checked={this.state.form.notApplicable}
                                                                            label={'Not applicable'.translate(this.props.lang)}
                                                                        />
                                                                    </div>
                                                                    : null
                                                            }
                                                        </div>
                                                        <div className={this.state.errorAdditionalCountyOragisationId ? 'order-info-row error input-with-check' : this.state.form.sameAsAccountDetails && this.state.error && (this.state.form.country == 'Sweden' || (this.state.form.notApplicable && this.state.form.country != 'Sweden')) && (this.state.form.organizationID == '' || !this.state.form.organizationID) ? 'order-info-row error input-with-check' : 'order-info-row input-with-check'}>
                                                            <label>{'Organisation ID'.translate(this.props.lang)}</label>
                                                            <Input onChange={(e) => {
                                                                let form = this.state.form
                                                                form.organizationID = e.target.value
                                                                this.setState({
                                                                    form
                                                                }, () => this.checkRequiredField())
                                                            }} value={this.state.form.organizationID} />
                                                        </div>
                                                        <div className={this.state.error && this.state.form && !this.state.form.confirm ? 'order-info-row error check-confirm' : 'order-info-row check-confirm'}>
                                                            <Checkbox
                                                                onChange={(e) => {
                                                                    let form = this.state.form;
                                                                    form.confirm = e.target.checked
                                                                    this.setState({
                                                                        form
                                                                    }, () => this.checkRequiredField())
                                                                }}
                                                                checked={this.state.form.confirm}
                                                                label={(this.state.form.sameAsAccountDetails && this.state.form && this.state.form.country && this.state.form.country == 'Sweden') || (!this.state.form.sameAsAccountDetails && this.state.formAccountDetails && this.state.formAccountDetails.country && this.state.formAccountDetails.country == 'Sweden') ? 'I/we confirm that provided information is correct.'.translate(this.props.lang) : 'I/we confirm that provided information is correct and understand that reverse charge (reverse VAT liability) is applied in accordance with your local regulations.'.translate(this.props.lang)}
                                                            />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <hr />
                                            <div className='order-info-title' style={{ marginTop: 40 }}>
                                                <Isvg src={cardIcon} />
                                                <h4>{'Select Payment Method'.translate(this.props.lang)}</h4>
                                            </div>
                                            <div className='choose-payment'>
                                                <div className='choose-payment-container'>
                                                    <div className='method-container'>
                                                        {this.state.ceckMonthyYearly == 'Monthly' && (this.state.activeLicence.monthlyPrice * this.state.licencesNumber) < 50000 ?
                                                            < div onClick={() => this.setState({ payment: 'Card' })} className={this.state.payment && this.state.payment == 'Card' ? 'method active' : 'method'}>
                                                                <Isvg src={creditCardIcon} />
                                                                <h6>{'Card'.translate(this.props.lang)}</h6>
                                                            </div>
                                                            : null
                                                        }
                                                        {
                                                            this.state.ceckMonthyYearly == 'Yearly' || (this.state.activeLicence.monthlyPrice && (this.state.activeLicence.monthlyPrice * this.state.licencesNumber) > 50000) ?

                                                                <div onClick={() => this.setState({ payment: 'Invoice' })} className={this.state.payment && this.state.payment == 'Invoice' ? 'method active' : 'method'}>
                                                                    <img src={invoiceIcon} />
                                                                    <h6>{'Invoice'.translate(this.props.lang)}</h6>
                                                                </div>

                                                                : null
                                                        }

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        :
                                        null}

                                    {

                                        this.state.step == 3 ?
                                            <div className='order-info preview review-order-preview'>
                                                <div className='order-info-title'>
                                                    <Isvg src={userIcon} />
                                                    <h4>{'Billing details'.translate(this.props.lang)}</h4>
                                                    {/* <span>{'Edit'.translate(this.props.lang)}</span> */}
                                                </div>
                                                <div className='order-info-column-container column-short'>
                                                    {this.state.form.sameAsAccountDetails ?
                                                        <div className='order-info-column'>
                                                            <div className='order-info-row'>
                                                                <label>{'First and last name'.translate(this.props.lang)}</label>
                                                                <p>{this.state.form.name}</p>
                                                            </div>
                                                            <div className='order-info-row'>
                                                                <label>{'Email'.translate(this.props.lang)}</label>
                                                                <p>{this.state.form.email}</p>
                                                            </div>
                                                            <div className='order-info-row'>
                                                                <label>{'Phone number'.translate(this.props.lang)}</label>
                                                                <p>{this.state.form.phone}</p>
                                                            </div>
                                                            <div className='order-info-row'>
                                                                <label>{'Country'.translate(this.props.lang)}</label>
                                                                <p>{this.state.form.country}</p>
                                                            </div>
                                                            <div className='order-info-row'>
                                                                <label>{'Company'.translate(this.props.lang)}</label>
                                                                <p>{this.state.form.organizationName}</p>
                                                            </div>
                                                            <div className='order-info-row'>
                                                                <label>{'Address'.translate(this.props.lang)}</label>
                                                                <p>{this.state.form.street}</p>
                                                            </div>
                                                            <div className='order-info-row'>
                                                                <label>{'State'.translate(this.props.lang)}</label>
                                                                <p>{this.state.form.state}</p>
                                                            </div>
                                                            <div className='order-info-row'>
                                                                <label>{'Zip/Postal code'.translate(this.props.lang)}</label>
                                                                <p>{this.state.form.zip}</p>
                                                            </div>
                                                            <div className='order-info-row'>
                                                                <label>{'Organization ID'.translate(this.props.lang)}</label>
                                                                <p>{this.state.form.country == 'Sweden' || this.state.form.country == 'Sweden' ? this.state.form.organizationID : this.state.form.vatNumber}</p>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className='order-info-column'>
                                                            <div className='order-info-row'>
                                                                <label>{'First and last name'.translate(this.props.lang)}</label>
                                                                <p>{this.state.formAccountDetails.name}</p>
                                                            </div>
                                                            <div className='order-info-row'>
                                                                <label>{'Email'.translate(this.props.lang)}</label>
                                                                <p>{this.state.formAccountDetails.email}</p>
                                                            </div>
                                                            <div className='order-info-row'>
                                                                <label>{'Phone number'.translate(this.props.lang)}</label>
                                                                <p>{this.state.formAccountDetails.phone}</p>
                                                            </div>
                                                            <div className='order-info-row'>
                                                                <label>{'Country'.translate(this.props.lang)}</label>
                                                                <p>{this.state.formAccountDetails.country}</p>
                                                            </div>
                                                            <div className='order-info-row'>
                                                                <label>{'Company'.translate(this.props.lang)}</label>
                                                                <p>{this.state.formAccountDetails.organizationName}</p>
                                                            </div>
                                                            <div className='order-info-row'>
                                                                <label>{'Address'.translate(this.props.lang)}</label>
                                                                <p>{this.state.formAccountDetails.street}</p>
                                                            </div>
                                                            <div className='order-info-row'>
                                                                <label>{'State'.translate(this.props.lang)}</label>
                                                                <p>{this.state.formAccountDetails.state}</p>
                                                            </div>
                                                            <div className='order-info-row'>
                                                                <label>{'Zip/Postal code'.translate(this.props.lang)}</label>
                                                                <p>{this.state.formAccountDetails.zip}</p>
                                                            </div>
                                                            <div className='order-info-row'>
                                                                <label>{'Organization ID'.translate(this.props.lang)}</label>
                                                                <p>{this.state.form.country == 'Sweden' || this.state.form.country == 'Sweden' ? this.state.form.organizationID : this.state.form.vatNumber}</p>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className='total'>
                                                        <div className='total-title'>
                                                            <Isvg src={userIcon} />
                                                            <h4>{'Price Details'.translate(this.props.lang)}</h4>
                                                        </div>
                                                        <div className='total-body'>
                                                            {
                                                                this.state.moneyLeft && this.state.moneyLeft != 0 ?

                                                                    <div className='total-body-row'>
                                                                        <label>{'Price new package'.translate(this.props.lang)}</label>
                                                                        {
                                                                            this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.licenceMainDuration === 1 && this.state.ceckMonthyYearly === 'Yearly' ?

                                                                                <p>{this.state.form && this.state.form.sameAsAccountDetails && this.state.form.country && this.state.form.country != 'Sweden' || (!this.state.form.sameAsAccountDetails && this.state.formAccountDetails.country != 'Sweden') ?
                                                                                    (Number(this.state.activeLicence.monthlyPrice) * Number(this.state.licencesNumber)).toFixed(2) + ' SEK'
                                                                                    :
                                                                                    (Number(this.state.activeLicence.monthlyPrice) * Number(this.state.licencesNumber) - (Number(this.state.activeLicence.monthlyPrice) * Number(this.state.licencesNumber) * 0.20)).toFixed(2) + ' SEK'

                                                                                }</p>
                                                                                :
                                                                                <p>{this.state.form && this.state.form.sameAsAccountDetails && this.state.form.country && this.state.form.country != 'Sweden' || (!this.state.form.sameAsAccountDetails && this.state.formAccountDetails.country != 'Sweden') ?
                                                                                    (Number(this.priceNewPackage(false))).toFixed(2) + ' SEK'
                                                                                    :
                                                                                    (Number(this.priceNewPackage(true))).toFixed(2) + ' SEK'

                                                                                }</p>
                                                                        }

                                                                    </div>
                                                                    :

                                                                    null
                                                            }
                                                            {
                                                                this.state.moneyLeft && this.state.moneyLeft != 0 ?

                                                                    <div className='total-body-row'>
                                                                        <label>{'Price pay'.translate(this.props.lang)}</label>
                                                                        {
                                                                            <div>
                                                                                <p>{this.state.form && this.state.form.sameAsAccountDetails && this.state.form.country && this.state.form.country != 'Sweden' || (!this.state.form.sameAsAccountDetails && this.state.formAccountDetails.country != 'Sweden') ?
                                                                                    '- ' + (Number(this.state.moneyLeft)).toFixed(2) + ' SEK'
                                                                                    :
                                                                                    '- ' + (Number(this.state.moneyLeft - (this.state.moneyLeft * 0.20))).toFixed(2) + ' SEK'

                                                                                }</p>
                                                                                {
                                                                                    !this.state.upgradeDiscount && this.state.upgradeDiscount == 0 && this.state.ceckMonthyYearly !== 'Yearly' ?
                                                                                        <hr style={{ marginBottom: '0px' }} />
                                                                                        : null
                                                                                }
                                                                            </div>
                                                                        }

                                                                    </div>
                                                                    : null
                                                            }

                                                            {
                                                                this.state.upgradeDiscount && this.state.upgradeDiscount != 0 ?

                                                                    <div className='total-body-row'>
                                                                        <label>{'Reduction based on package duration'.translate(this.props.lang)}</label>
                                                                        {
                                                                            <div>
                                                                                <p>{this.state.form && this.state.form.sameAsAccountDetails && this.state.form.country && this.state.form.country != 'Sweden' || (!this.state.form.sameAsAccountDetails && this.state.formAccountDetails.country != 'Sweden') ?
                                                                                    '- ' + Number(this.cycleMoney(false)).toFixed(2) + ' SEK'
                                                                                    :
                                                                                    '- ' + Number(this.cycleMoney(true)).toFixed(2) + ' SEK'

                                                                                }</p>
                                                                                {
                                                                                    this.state.ceckMonthyYearly !== 'Yearly' ?
                                                                                        <hr style={{ marginBottom: '0px' }} />
                                                                                        :
                                                                                        null

                                                                                }
                                                                            </div>



                                                                        }

                                                                    </div>

                                                                    : null

                                                            }
                                                            {
                                                                this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.licenceMainDuration === 1 && this.state.ceckMonthyYearly === 'Yearly' ?
                                                                    <div className='total-body-row'>
                                                                        <label>{'Price yearly'.translate(this.props.lang)}</label>
                                                                        {
                                                                            <div>
                                                                                <p>{this.state.form && this.state.form.sameAsAccountDetails && this.state.form.country && this.state.form.country != 'Sweden' || (!this.state.form.sameAsAccountDetails && this.state.formAccountDetails.country != 'Sweden') ?
                                                                                    (Number(this.state.activeLicence.yearlyPrice) * Number(this.state.licencesNumber)).toFixed(2) + ' SEK'
                                                                                    :
                                                                                    (Number(this.state.licencesNumber) * Number(this.state.activeLicence.yearlyPrice) - Number(this.state.licencesNumber) * Number(this.state.activeLicence.yearlyPrice) * 0.20).toFixed(2) + ' SEK'

                                                                                }</p>
                                                                                <hr style={{ marginBottom: '0px' }} />
                                                                            </div>

                                                                        }

                                                                    </div>
                                                                    : null
                                                            }
                                                            <div className='total-body-row'>
                                                                <label>{'Price'.translate(this.props.lang)}</label>
                                                                {
                                                                    this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.licenceMainDuration === 1 && this.state.ceckMonthyYearly === 'Yearly' ?
                                                                        <p>{this.state.form && this.state.form.sameAsAccountDetails && this.state.form.country && this.state.form.country != 'Sweden' || (!this.state.form.sameAsAccountDetails && this.state.formAccountDetails.country != 'Sweden') ?
                                                                            (Number(this.priceYearlyNewPackage(false))).toFixed(2) + ' SEK'
                                                                            :
                                                                            (Number(this.priceYearlyNewPackage(true))).toFixed(2) + ' SEK'

                                                                        }</p>


                                                                        :

                                                                        <div>
                                                                            {
                                                                                this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.licenceMainDuration ?
                                                                                    <p>{this.state.form && this.state.form.sameAsAccountDetails && this.state.form.country && this.state.form.country != 'Sweden' || (!this.state.form.sameAsAccountDetails && this.state.formAccountDetails.country != 'Sweden') ?
                                                                                        (Number(this.priceNewPackage(false)) - (Number(this.state.moneyLeft)) - Number(this.cycleMoney(false))).toFixed(2) + ' SEK'
                                                                                        :
                                                                                        (Number(this.priceNewPackage(true)) - (Number(this.state.moneyLeft - (this.state.moneyLeft * 0.20))) - Number(this.cycleMoney(true))).toFixed(2) + ' SEK'

                                                                                    }</p>
                                                                                    :
                                                                                    <p>{this.state.form && this.state.form.sameAsAccountDetails && this.state.form.country && this.state.form.country != 'Sweden' || (!this.state.form.sameAsAccountDetails && this.state.formAccountDetails.country != 'Sweden') ?
                                                                                        (Number(this.priceNewPackage(false))).toFixed(2) + ' SEK'
                                                                                        :
                                                                                        (Number(this.priceNewPackage(true))).toFixed(2) + ' SEK'

                                                                                    }</p>

                                                                            }

                                                                        </div>
                                                                }

                                                            </div>

                                                            <div className='total-body-row'>
                                                                <label>{(this.state.form.country == 'Sweden' && this.state.form.sameAsAccountDetails) || (!this.state.form.sameAsAccountDetails && this.state.formAccountDetails.country == 'Sweden') ? 'VAT(25%)'.translate(this.props.lang) : 'VAT'.translate(this.props.lang)}</label>
                                                                {this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.licenceMainDuration === 1 && this.state.ceckMonthyYearly === 'Yearly' ?
                                                                    <p>{(this.state.form.country == 'Sweden' && this.state.form.sameAsAccountDetails) || (!this.state.form.sameAsAccountDetails && this.state.formAccountDetails.country == 'Sweden') ?
                                                                        (Number(this.priceYearlyNewPackage(true)) * 0.25).toFixed(2) + ' SEK'
                                                                        :
                                                                        0 + ' SEK'

                                                                    }</p>
                                                                    :
                                                                    <div>
                                                                        {
                                                                            this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.licenceMainDuration ?
                                                                                <p>{(this.state.form.country == 'Sweden' && this.state.form.sameAsAccountDetails) || (!this.state.form.sameAsAccountDetails && this.state.formAccountDetails.country == 'Sweden') ?
                                                                                    ((Number(this.priceNewPackage(true)) - (Number(this.state.moneyLeft - (this.state.moneyLeft * 0.20))) - Number(this.cycleMoney(true))) * 0.25).toFixed(2) + ' SEK'
                                                                                    :

                                                                                    0 + ' SEK'
                                                                                }</p>
                                                                                :
                                                                                <p>{(this.state.form.country == 'Sweden' && this.state.form.sameAsAccountDetails) || (!this.state.form.sameAsAccountDetails && this.state.formAccountDetails.country == 'Sweden') ?
                                                                                    (Number(this.priceNewPackage(true)) * 0.25).toFixed(2) + ' SEK'
                                                                                    :
                                                                                    0 + ' SEK'

                                                                                }</p>

                                                                        }
                                                                    </div>



                                                                }

                                                            </div>

                                                            <p>{this.state.form && this.state.form.sameAsAccountDetails && this.state.form.country && this.state.form.country != 'Sweden' || (!this.state.form.sameAsAccountDetails && this.state.formAccountDetails.country != 'Sweden') ? 'Reverse charge (reverse VAT liability) is applied in accordance with your local regulations'.translate(this.props.lang) : ''}</p>
                                                            <hr />

                                                            <div className='total-body-row' style={{ marginBottom: 0, paddingTop: 20 }}>
                                                                <label>{'Total'.translate(this.props.lang)}</label>
                                                                {this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.licenceMainDuration === 1 && this.state.ceckMonthyYearly === 'Yearly' ?
                                                                    <p>{(this.state.form.country == 'Sweden' && this.state.form.sameAsAccountDetails) || (!this.state.form.sameAsAccountDetails && this.state.formAccountDetails.country == 'Sweden') ?
                                                                        ((Number(this.priceYearlyNewPackage(true))) + (Number(this.priceYearlyNewPackage(true)) * 0.25)).toFixed(2) + ' SEK'
                                                                        :
                                                                        (Number(this.priceYearlyNewPackage(false))).toFixed(2) + ' SEK'

                                                                    }</p>
                                                                    :
                                                                    <div>
                                                                        {
                                                                            this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.licenceMainDuration ?
                                                                                <p>{this.state.form.country == 'Sweden' || this.state.formAccountDetails.country == 'Seeden' ?
                                                                                    ((Number(this.priceNewPackage(true)) - (Number(this.state.moneyLeft - (this.state.moneyLeft * 0.20))) - Number(this.cycleMoney(true))) + Number(((Number(this.priceNewPackage(true)) - (Number(this.state.moneyLeft - (this.state.moneyLeft * 0.20))) - Number(this.cycleMoney(true))) * 0.25).toFixed(2))).toFixed(2) + ' SEK'

                                                                                    :
                                                                                    (Number(this.priceNewPackage(false)) - (Number(this.state.moneyLeft)) - Number(this.cycleMoney(false))).toFixed(2) + ' SEK'
                                                                                }</p>
                                                                                :
                                                                                <p>{this.state.form.country == 'Sweden' || this.state.formAccountDetails.country == 'Seeden' ?
                                                                                    (Number(this.priceNewPackage(true)) + Number(Number(this.priceNewPackage(true))) * 0.25).toFixed(2) + ' SEK'

                                                                                    :
                                                                                    (Number(this.priceNewPackage(false))).toFixed(2) + ' SEK'
                                                                                }</p>

                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className='sub-cycle'>
                                                    <div onClick={() => this.setState({ step: 1 })} className='sub-cycle-title'>
                                                        <Isvg src={cycleIcon} />
                                                        <h4>{'Plan & subscription cycle'.translate(this.props.lang)}</h4>
                                                        <span>{'Edit'.translate(this.props.lang)}</span>
                                                    </div>
                                                    <div className='sub-cycle-body'>
                                                        <div className='cycle-option'>
                                                            <span>{'Plan'.translate(this.props.lang)}</span>
                                                            <h6>{this.props.lang == 'se' ? this.state.activeLicence.nameSv : this.state.activeLicence.name}</h6>
                                                        </div>
                                                        <div className='cycle-option'>
                                                            <span>{'Subscription'.translate(this.props.lang)}</span>
                                                            <h6>{this.state.ceckMonthyYearly.translate(this.props.lang)}</h6>
                                                        </div>
                                                        <div className='cycle-option'>
                                                            <span>{'Nr of license(s)'.translate(this.props.lang)}</span>
                                                            <h6>{this.state.licencesNumber}</h6>
                                                        </div>
                                                        <div className='cycle-option'>
                                                            <span>{'Next renewal'.translate(this.props.lang)}</span>
                                                            <h6>{(this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.licenceMainDuration === 1 && this.state.ceckMonthyYearly === 'Yearly') ?
                                                                moment(new Date(new Date(this.props.uData.licenceObj.recuringPaymentTime * 1000).getFullYear(), new Date(this.props.uData.licenceObj.recuringPaymentTime * 1000).getMonth() + 12, new Date(this.props.uData.licenceObj.recuringPaymentTime * 1000).getDate())).format('YYYY-MM-DD')
                                                                :
                                                                (this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.licenceMainDuration === 12 && this.state.ceckMonthyYearly === 'Yearly') ?
                                                                    moment(new Date(new Date(this.props.uData.licenceObj.licenceBoughtMain * 1000).getFullYear(), new Date(this.props.uData.licenceObj.licenceBoughtMain * 1000).getMonth() + 12, new Date(this.props.uData.licenceObj.licenceBoughtMain * 1000).getDate())).format('YYYY-MM-DD')
                                                                    :
                                                                    this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.licenceMainDuration === 1 && this.state.ceckMonthyYearly == 'Monthly' ? moment(new Date(new Date(this.props.uData.licenceObj.licenceBoughtMain * 1000).getFullYear(), new Date(this.props.uData.licenceObj.licenceBoughtMain * 1000).getMonth() + 1, new Date(this.props.uData.licenceObj.licenceBoughtMain * 1000).getDate())).format('YYYY-MM-DD') :
                                                                        this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.licenceMainDuration !== 1 && this.state.ceckMonthyYearly == 'Monthly' ? moment(new Date(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate())).format('YYYY-MM-DD')
                                                                            : moment(new Date(new Date().getFullYear(), new Date().getMonth() + 12, new Date().getDate())).format('YYYY-MM-DD')}</h6>

                                                        </div>

                                                    </div>
                                                    <div className='cycle-option'>
                                                        <span className='cycle-text'>{'Subscription will renew automatically by the end of your cycle. You will be charged each period of renewal until you cancel. You subscribe for a full period (monthly/yearly) and cancellations must be requested on the day before next renewal occurs to go into effect. You can manage and cancel your subscription in your payment settings.'.translate(this.props.lang)}</span>
                                                    </div>
                                                </div>

                                                <div className='payment-preview'>
                                                    <div onClick={() => this.setState({ step: 2 })} className='payment-preview-title'>
                                                        <Isvg src={paymIcon} />
                                                        <h4>{'Payment Method'.translate(this.props.lang)}</h4>
                                                        <span>{'Edit'.translate(this.props.lang)}</span>
                                                    </div>
                                                    <div className='payment-preview-body'>
                                                        <span>{'Pay By'.translate(this.props.lang)}</span>
                                                        <h4>{this.state.payment.translate(this.props.lang)}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        this.state.step == 4 ?
                                            <div id="checkout-container-div">
                                            </div>
                                            : null
                                    }

                                    {
                                        this.state.step == 5 ?
                                            <div className='completed'>
                                                <Isvg src={completed} />
                                                <h4>{'Congratulations!'.translate(this.props.lang)}</h4>
                                                <p>{"You've completed your purchase.".translate(this.props.lang)}</p>
                                                <Link to="/my-data/account">{'Go to homepage'.translate(this.props.lang)}</Link>
                                                {localStorage.removeItem('checkHost')}
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        this.state.error || this.state.errorAdditionalCountyVatId || this.state.errorAccountDetails || this.state.errorAdditionalCountyOragisationId ?
                                            <p style={{ color: 'red', textAlign: 'right' }}>{"Please fill out needed information".translate(this.props.lang)}</p>
                                            :
                                            null
                                    }
                                    {this.state.step == 5 ? null : <div className='buy-button'>
                                        {this.state.step > 1 ?
                                            <button onClick={() => this.setState({ step: this.state.step - 1 })}>{'Back'.translate(this.props.lang)}</button>

                                            :
                                            <button onClick={() => this.props[0].history.push('/buy-licence')}>{'Back'.translate(this.props.lang)}</button>

                                        }
                                        {this.state.step == 3 ?
                                            <button onClick={() => {
                                                if (this.state.payment && this.state.payment == 'Invoice') {
                                                    this.setState({
                                                        step: this.state.step + 2
                                                    }, () => this.invoicePayment()
                                                    )
                                                } else {
                                                    this.setState({
                                                        step: this.state.step + 1
                                                    }, () => this.netsPayment())
                                                }

                                            }}>
                                                {'Purchase'.translate(this.props.lang)}
                                            </button>
                                            :
                                            this.state.step == 1 && this.props.uData && this.props.uData.licenceObj && !(this.props.uData.licenceObj.numberOfLicencesMain < this.state.licencesNumber || this.props.uData.licenceObj.licenceIDMain != this.state.activeLicence._id || (this.props.uData.licenceObj.licenceMainDuration == 1 && this.state.ceckMonthyYearly == 'Yearly')) || this.state.step === 4 ?
                                                this.state.step === 4 ?
                                                    <></>
                                                    :
                                                    <button disabled style={{ backgroundColor: '#E6EDF5', color: 'black' }}>{'Continue'.translate(this.props.lang)}</button>
                                                :
                                                <button
                                                    disabled={this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.unpaidInvoice ? true : false}
                                                    style={this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.unpaidInvoice ? { backgroundColor: 'rgba(86,138,200,0.5)', borderColor: 'rgba(86,138,200,0.5)' } : {}}
                                                    onClick={() => {


                                                        if (this.state.step == 2) {
                                                            this.checkPaymentMethod()
                                                            this.checkRequiredField(true)
                                                        } else {
                                                            if (this.state.step == 1 && this.props.uData && this.props.uData.licenceObj && (this.props.uData.licenceObj.numberOfLicencesMain < this.state.licencesNumber || this.props.uData.licenceObj.licenceIDMain != this.state.activeLicence._id) && this.props.uData.licenceObj.licenceMainDuration === 1 && this.state.ceckMonthyYearly == 'Yearly') {
                                                                this.setState({
                                                                    upgradeModal: this.props.uData.licenceObj.transaction ? 'The subscription cycle change will be activated at the end of the current subscription cycle. Also, this change requires a change of payment method from card payment to invoice payment.'.translate(this.props.lang) : 'The subscription cycle change will be activated at the end of the current subscription cycle.'.translate(this.props.lang)
                                                                })
                                                            } else if (this.state.step == 1 && this.props.uData && this.props.uData.licenceObj && !(this.props.uData.licenceObj.numberOfLicencesMain < this.state.licencesNumber || this.props.uData.licenceObj.licenceIDMain != this.state.activeLicence._id) && this.props.uData.licenceObj.licenceMainDuration === 1 && this.state.ceckMonthyYearly == 'Yearly') {
                                                                this.setState({
                                                                    upgradeModal: this.props.uData.licenceObj.transaction ? 'The subscription cycle change will be activated at the end of the current subscription cycle. Also, this change requires a change of payment method from card payment to invoice payment.'.translate(this.props.lang) : 'The subscription cycle change will be activated at the end of the current subscription cycle.'.translate(this.props.lang)
                                                                })

                                                            } else {
                                                                this.setState({ step: this.state.step + 1 })

                                                            }
                                                        }
                                                    }}>{'Continue'.translate(this.props.lang)}</button>}
                                    </div>}
                                </div>
                            </div>
                        </Col>

                    </Row>

                </div>


                {
                    this.state.upgradeModal ?
                        <InfoModal
                            isOpen={this.state.upgradeModal}
                            header={'Info'.translate(this.props.lang)}
                            info={this.state.upgradeModal.translate(this.props.lang)}
                            buttons={[
                                <Button onClick={() => {
                                    this.setState({
                                        step: this.state.step + 1,
                                        upgradeModal: false
                                    })
                                }}>{'Continue'.translate(this.props.lang)}</Button>,
                                <Button onClick={() => {
                                    this.setState({
                                        upgradeModal: false
                                    })
                                }}>{'Cancel'.translate(this.props.lang)}</Button>
                            ]}

                        />
                        :
                        null
                }
            </div >
        );
    }
}



export default Page(BuyPacket);