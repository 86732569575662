import React, { Component } from "react";
import Page from "../../containers/page";

import {
    Container,
    Row,
    Col, Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Input
} from "reactstrap";

import {
    getSearchParams,
    generateSearchLink,
    generateSearchLinkMultiple,
} from "../../helpers/linkHelper";
import { API_ENDPOINT } from "../../constants";
import Isvg from "react-inlinesvg";
import PaysonSvg from '../../assets/svg/payson.svg';
import SwishSvg from '../../assets/svg/swish.svg';
import Checkbox from '../../components/forms/fields/checkbox';
import Swish from '../swish';
import Payson from '../../components/payson';
import InfoModal from "../../components/infoModal";
function parseJSON(response) {
    return response.json().then((json) => {
        return {
            result: json,
            status: response.status,
        };
    });
}

function parseText(response) {
    return response.text().then(text => {
        return {
            result: text,
            status: response.status
        }
    })
}

class Licence extends Component {
    constructor(props) {
        super(props);
        this.player = React.createRef();
        this.player2 = React.createRef();
        this.state = {
            showPlayer: false,
            showPlayer2: false,
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0,
                "query-group": null,
            },
            items: [],
            total: 0,
            loading: true,
            licence: [],
            smsLicence: [],
            languages: [],
            showConfirmationModal: false
        };
    }

    showPlayer = () => {
        this.setState({
            showPlayer: true,
        });
    };

    hidePlayer = () => {
        this.setState({
            showPlayer: false,
        });
    };

    get = () => {
        this.props.updateMeta(
            this.props.generateSeoTags(this.state, this.props.lang)
        );

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
                this.state.useState
                    ? this.state.params
                    : getSearchParams(this.props[0].location.search, {
                        entries: 10,
                        page: 0,
                        "query-group": null,
                    })
            ).then((data) => {
                this.setState(
                    {
                        ...data,
                        loading: null,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        );
                    }
                );
            });
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
                this.state.useState
                    ? this.state.params
                    : getSearchParams(this.props[0].location.search, {
                        entries: 10,
                        page: 0,
                        "query-group": null,
                    })
            ).then((data) => {
                this.setState(
                    {
                        ...data,
                        loading: null,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        );
                    }
                );
            });
        }
    };

    componentDidMount() {
        if (typeof window !== "undefined") {
            window.scrollTo(0, 0);
        }

        this.get();
        this.setState({ showForm: true })

    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState(
                {
                    loading: true,
                },
                () => {
                    this.get();
                }
            );
        }

        if (this.props.uData && this.props.uData.experienceDate) {
            let experienceDate = new Date(this.props.uData.experienceDate);
            experienceDate.setHours(23, 59, 59);
            let experienceDateTs = Math.floor(experienceDate.getTime() / 1000)
            let sevenDayBeforeExperienceDateTs = experienceDateTs - 60 * 60 * 24 * 7;
            let nowTs = Math.floor(new Date().getTime() / 1000);
            if (nowTs < experienceDateTs && nowTs > sevenDayBeforeExperienceDateTs) {
                if (!this.state.renewLicence)
                    this.setState({ renewLicence: true })
            }
        }
    }

    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state,
            });
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    [
                        { name: "sortField", value: field },
                        { name: "sortType", value: type },
                    ],
                    false
                )
            );
        }
    };

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj });
            }
        } else {
            this.props[0].history.push(
                generateSearchLink(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    name,
                    value,
                    restart
                )
            );
        }
    };

    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });
            }
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    fields,
                    restart
                )
            );
        }
    };

    stopAnimation = () => {
        this.player.current.play();
    };

    calculateLicence = (item) => {
        let check
        for (let i = 0; i < this.state.licence.length; i++) {
            if (this.props.uData.licenceID == this.state.licence[i]._id) {
                check = this.state.licence[i]
            }

        }
        if (Number(check.participants) < Number(item.participants)) {
            return true
        } else {
            return false
        }
    }

    buyOrUpgrade = (item) => {
        let buy = true
        let disable = false
        if (this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.licenceMain) {
            buy = false
            if (this.props.uData.licenceObj.licenceIDMain == item._id && (this.props.uData.licenceObj.numberOfLicencesMain) == Number(item.numberOfLicenses)) {
                disable = true
            } else if ((this.props.uData.licenceObj.licenceMain == 'Pro' && item.name == 'Standard') || (this.props.uData.licenceObj.licenceMain == 'Enterprise' && (item.name == 'Standard' || item.name == 'Pro'))) {
                disable = true
            }


        }
        if (!disable && this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.unpaidInvoice) {
            disable = true;
        }

        if (this.props.uData && this.props.uData.licenceObj && ((this.props.uData.licenceObj.upgrade && this.props.uData.licenceObj.upgrade === 'denied') || (this.props.uData.licenceObj.recuringPaymentTimeForReactivate && this.props.uData.licenceObj.recuringPaymentTimeForReactivate > Math.floor(new Date().getTime() / 1000)))) {
            return (
                <Button disabled>{'Denied'.translate(this.props.lang)}</Button>
            )
        } else {
            if (buy) {

                return (
                    < Button onClick={() => {
                        // if (this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.recuringPaymentTime && this.props.uData.licenceObj.recuringPaymentTime < new Date()) {
                        //     this.setState({ showConfirmationModal: true });
                        // } else {
                            this.props[0].history.push({ pathname: '/users/buy-packet', state: item })
                        // }
                    }
                    }> {'Buy now'.translate(this.props.lang)}</Button >
                )


            } else {
                return (
                    < Button disabled={disable} onClick={() => {
                        // if (this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.recuringPaymentTime && this.props.uData.licenceObj.recuringPaymentTime < new Date()) {
                        //     this.setState({ showConfirmationModal: true });
                        // } else {
                            this.props[0].history.push({ pathname: '/users/buy-packet', state: item })
                        // }
                    }
                    }> {'Buy now'.translate(this.props.lang)}</Button >
                )
            }
        }


    }

    updateUserRecuringTime = () => {
        fetch(API_ENDPOINT + '/users/users-recuring-time-cancel', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                userId: this.props.uData._id
            })
        }).then(parseJSON).then(({ result, status }) => {
            this.setState({
                cancelSubscriptionModal: false,
                // showConfirmationModal: false

            }, () => this.props.verifyUser())
        })
    }

    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, {
                entries: 10,
                page: 0,
            });
        }
        return (
            <div className="dashboard">
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className='panel'>
                                {
                                    <div className="licence-table-container">
                                        <h5 className="component-header">
                                            {this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ""}
                                        </h5>
                                        <div className="licence-table-row licence-fix">

                                            <div className='licence-table'>

                                                <div className="table-header">
                                                    <h6>{'Basic'.translate(this.props.lang)}</h6>

                                                    <p>{'Try Curoflow Video and invite people to meetings'.translate(this.props.lang)}</p>
                                                    <h5>{'Free'.translate(this.props.lang)}</h5>
                                                    <div className="button-container">
                                                        <Button onClick={() => this.props[0].history.push('/users/schedule-meeting')}>{'Start meeting'.translate(this.props.lang)}</Button>

                                                    </div>

                                                </div>

                                                <div className="table-body">
                                                    <h6>{'Max participants: 2'.translate(this.props.lang)}</h6>
                                                    <h6>{'Max meeting length: 20 min'.translate(this.props.lang)}</h6>

                                                    <ul >
                                                        <li>
                                                            <span>{'Unlimited number of meetings'.translate(this.props.lang)}</span>
                                                        </li>
                                                        <li>
                                                            <span>{'Chat'.translate(this.props.lang)}</span>
                                                        </li>
                                                        <li>
                                                            <span>{'Screen sharing'.translate(this.props.lang)}</span>
                                                        </li>
                                                        <li>
                                                            <span>{'Invite participants via email or link'.translate(this.props.lang)}</span>
                                                        </li>
                                                        <li>
                                                            <span>{'Authenticate participants with code'.translate(this.props.lang)}</span>
                                                        </li>
                                                        <li>
                                                            <span> {'Secure log-in'.translate(this.props.lang)} </span>
                                                        </li>
                                                        <li className='list-price'>
                                                            <div> <span> {'Co-host'.translate(this.props.lang)} </span></div>
                                                        </li>
                                                        <li className="list-price">
                                                            <span> {'Authenticate participants with BankID or SITHS'.translate(this.props.lang)}</span>
                                                        </li>
                                                        <li className="list-price">
                                                            <span>{'File sharing'.translate(this.props.lang)}</span>
                                                        </li>
                                                        <li className="list-price">
                                                            <span> {'Record meetings'.translate(this.props.lang)}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            {this.state.licence.map(item => {
                                                return (
                                                    <div className='licence-table'>

                                                        <div className="table-header">
                                                            <h6>{this.props.lang == 'en' ? item.name : item.nameSv}</h6>

                                                            <p>{this.props.lang == 'en' ? item.shorDescriptionEn : item.shorDescriptionSv}</p>
                                                            {/* <h5>{'From'.translate(this.props.lang) + ' ' + item.monthlyPriceWithDisount + ' ' + 'SEK/licence'.translate(this.props.lang)}</h5> */}
                                                            <h5><span className='small-txt'>{'From'.translate(this.props.lang)}</span> <span>{item.monthlyPriceWithDisount} </span><span>{"SEK".translate(this.props.lang)}</span><span className='small-txt'>{'/mån/anv'.translate(this.props.lang)}</span></h5>
                                                            <div className="button-container">
                                                                {this.buyOrUpgrade(item)}
                                                            </div>

                                                        </div>

                                                        <div className="table-body">
                                                            {/* <h6>{'Up to'.translate(this.props.lang) + ' ' + item.numberOfParticipants + ' ' + 'participants/meeting'.translate(this.props.lang)}</h6> */}
                                                            <h6>{'Max participants: '.translate(this.props.lang) + item.numberOfParticipants.translate(this.props.lang)}</h6>
                                                            <h6>{'Unlimited meeting length'.translate(this.props.lang)}</h6>

                                                            <div dangerouslySetInnerHTML={{ __html: this.props.lang == 'en' ? item.longDescriptionEn : item.longDescriptionSv }} onChange={() => { }}></div>
                                                        </div>
                                                        {/* <h6 className='payment-type-note'>*{'Monthly fee when purchasing an annual license'.translate(this.props.lang)}.</h6> */}
                                                    </div>

                                                )
                                            })}

                                        </div>
                                    </div>
                                }
                                <ul>
                                    <li>{"All listed prices are including VAT".translate(this.props.lang)}</li>
                                    <li>{"Companies outside Sweden will be charged without VAT (25% price deduction). Reverse charge (reverse VAT liability) is applied in accordance with your local regulations".translate(this.props.lang)}</li>
                                </ul>

                            </div>
                        </Col>
                    </Row>


                </Container>
                {
                    this.state.showConfirmationModal ?

                        <InfoModal
                            isOpen={this.state.showConfirmationModal}
                            onClose={(item) => {
                                this.setState({
                                    showConfirmationModal: item
                                })
                            }}
                            toggle={() => this.setState({ showConfirmationModal: !this.state.showConfirmationModal })}
                            header={'Warning'.translate(this.props.lang)}
                            info={' If you wish to upgrade your plan, please note that upgrading will result in the termination of your existing subscription.'.translate(this.props.lang)}
                            buttons={[

                                <Button color='primary' onClick={() => {
                                    this.updateUserRecuringTime();

                                }}>{'Yes'.translate(this.props.lang)}</Button>,

                                <Button color='primary' onClick={() => {
                                    this.setState({
                                        showConfirmationModal: false
                                    })

                                }}>{'No'.translate(this.props.lang)}</Button>


                            ]}
                        />

                        :
                        null
                }
            </div>

        );
    }
}


export default Page(Licence);
