import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';
import { formValueSelector, change, isDirty, submit } from 'redux-form';
import { connect } from 'react-redux';

import FormBuilder from '../../components/forms/modalFormBuilder';
import { required } from '../../components/forms/validation';

import Isvg from 'react-inlinesvg'
import Search from '../../components/search';

import profile from '../../assets/images/profile-pic.png'
import map from '../../assets/svg/map.svg'
import chart from '../../assets/svg/chart.svg'
import edit from '../../assets/svg/edit.svg'
import garabage from '../../assets/svg/garbage.svg'
import arrowDown from '../../assets/svg/arrow-down.svg'
import arrowUp from '../../assets/svg/arrow-up.svg'
import addIcon from '../../assets/svg/add-icon.svg';

import ListBuilder from '../../components/listBuilder';
import DeleteModal from '../../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import ErrorModal from '../../components/errorModal';

import profileUser from '../../assets/images/user.png';
import notification from '../../assets/svg/notification.svg';
import moreIcon from '../../assets/svg/more.svg';

import { Player, Controls } from '@lottiefiles/react-lottie-player';

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

/**
* Clinic groups page
* @author   Milan Stanojevic
*/
class UserNotifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            total: 0,
            loading: true
        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }

    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }


    /**
    * Insert new or update clinic group
    * @author   Milan Stanojevic
    * @Objectparam    {Object} data     form data
    * @Objectparam    {String} id       clinic group id
    */
    insertOrUpdate = (data) => {
        this.setState({
            loading: true,
            _saving: true
        }, () => {
            if (!this.state.form._id) {

                fetch(API_ENDPOINT + '/data/notificationsSettings/new', {
                    method: 'PUT',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    this.get();
                    this.setState({
                        _saving: false
                    }, () => {
                        setTimeout(() => {
                            this.setState({ _saving: false, form: null })
                        }, 1000)
                    })
                })
            } else {
                if (data.set) {
                    delete data.set
                }
                if (data.type) {
                    delete data.type
                }
                if (data.key) {
                    delete data.key
                }
                if (data.bodyH) {
                    delete data.bodyH
                }

                fetch(API_ENDPOINT + '/data/notificationsSettings/' + this.state.form._id, {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    this.get();
                    this.setState({
                        _saving: false
                    }, () => {
                        setTimeout(() => {
                            this.setState({ _saving: false, form: null })
                        }, 1000)
                    })
                })
            }
        })
    }


    stopAnimation = () => {
        this.player.current.play();
    }

    /**
    * Delete clinic group
    * @author   Milan Stanojevic
    * @Objectparam    {String} id       clinic group id
    */
    delete = (id) => {
        this.setState({
            loading: true
        }, () => {

            fetch(API_ENDPOINT + '/data/notificationsSettings/' + id, {
                method: 'DELETE',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(parseJSON).then(({ result, status }) => {
                if (result.error) {
                    this.setState({ error: result.error })
                }
                this.get();
            })
        })

    }




    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }
        const defaultOptions = {
            loop: true,
            autoplay: true,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        const humanReadable = {
            'set1doctor': 'New event'.translate(this.props.lang),
            'set2doctor': 'Notification of booked appointment'.translate(this.props.lang),
            'set3doctor': 'Info regarding denied patient request'.translate(this.props.lang),
            'set4doctor': 'New appointment'.translate(this.props.lang),
            'set5doctor': 'New message'.translate(this.props.lang),
            'set6doctor': 'New time for appointment'.translate(this.props.lang),
            'set7doctor': 'Reminder that a meeting starts in 1 hour'.translate(this.props.lang),

            'set1patient': 'Reminder that a appointment starts in 24 hours'.translate(this.props.lang),
            'set10patient': 'Reminder that a meeting starts in 1 hour'.translate(this.props.lang),
            'set2patient': 'Notification of received request'.translate(this.props.lang),
            'set3patient': 'Approved request'.translate(this.props.lang),
            'set4patient': 'A meeting has been booked'.translate(this.props.lang),
            'set5patient': 'Denied patient request'.translate(this.props.lang),
            'set6patient': 'Rebooked appointment'.translate(this.props.lang),
            'set7patient': 'New message'.translate(this.props.lang),
            'set8patient': 'Change time for appointment'.translate(this.props.lang),
            'set9patient': 'Deleted scheduled appointment'.translate(this.props.lang),

        }

        return (
            <div className="dashboard">

                <Container fluid>

                    <Row>
                        <Col xl="12">
                            <div className="panel">
                                <div className="panel-header">
                                    <h5 className="component-header">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h5>
                                    {/* <button className="btn add-button" onClick={() => this.setState({
                                        form: {}
                                    })}><Isvg src={addIcon} /> {'Add'.translate(this.props.lang)}</button> */}
                                </div>
                                <ListBuilder
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'key', label: 'Key'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'body', label: 'Message'.translate(this.props.lang), allowSort: true, multilang: true, maxWidth: 300 },
                                        { type: 'text', name: 'description', label: 'Description'.translate(this.props.lang), allowSort: true, multilang: true },
                                        { type: 'text', name: 'type', label: 'Type'.translate(this.props.lang), allowSort: true, },
                                        // { type: 'text', name: 'set', label: 'Group'.translate(this.props.lang), allowSort: true, },
                                    ]}
                                    items={this.state.items.map(item => {
                                        return {
                                            ...item,
                                            set: `${humanReadable[item.set + item.receiver]}`,

                                        }
                                    })}
                                    actions={
                                        [
                                            {
                                                component: <Isvg src={edit} className="edit-icon" />,
                                                onClick: (item) => this.setState({ form: item })
                                            },
                                            {
                                                component: <Isvg src={garabage} className="delete-icon" />,
                                                onClick: (item) => this.setState({ deleteModal: item })
                                            },
                                        ]
                                    }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                                <DeleteModal
                                    lang={this.props.lang}
                                    isOpen={this.state.deleteModal}
                                    toggle={() => this.setState({ deleteModal: null })}
                                    handler={() => { this.delete(this.state.deleteModal._id); this.setState({ deleteModal: null }) }}
                                >
                                    {'Delete group'.translate(this.props.lang)} <strong>{this.state.deleteModal && this.state.deleteModal.name}</strong>?
                                </DeleteModal>
                                {this.state.error ?

                                    <ErrorModal lang={this.props.lang}
                                        isOpen={this.state.error}
                                        toggle={() => this.setState({ error: null })}

                                    >
                                        {this.state.error.translate(this.props.lang)}
                                    </ErrorModal>

                                    :
                                    null
                                }

                            </div>
                        </Col>
                    </Row>

                </Container>

                {this.state.form ?
                    <FormBuilder dateFormat={this.props.dateFormat}
                        lang={this.props.lang}
                        onSubmit={(data) => this.insertOrUpdate(data)}
                        initialValues={this.state.form}
                        isOpen={this.state.form}
                        size={'lg'}
                        toggle={() => this.setState({ form: null })}
                        title={this.state.form._id ? 'Edit notification'.translate(this.props.lang) : 'Add new notification'.translate(this.props.lang)}
                        _saving={this.state._saving}
                        buttonConfirmText={'Saved'.translate(this.props.lang)}
                        buttonText={'Save'.translate(this.props.lang)}

                        fields={[
                            {
                                type: 'row',
                                children: [
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 12,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'highlightText',
                                                name: 'body.se',
                                                label: 'Message[SE]'.translate(this.props.lang),
                                            },
                                            {
                                                type: 'highlightText',
                                                name: 'body.en',
                                                label: 'Message[EN]'.translate(this.props.lang),
                                            },
                                            {
                                                type: 'textarea',
                                                name: 'description.se',
                                                label: 'Description[SE]'.translate(this.props.lang),
                                            },
                                            {
                                                type: 'textarea',
                                                name: 'description.en',
                                                label: 'Description[EN]'.translate(this.props.lang),
                                            },
                                            {
                                                type: 'text',
                                                name: 'key',
                                                label: 'Key'.translate(this.props.lang),
                                                disabled: true
                                            },
                                            // {
                                            //     type: 'text',
                                            //     name: 'set',
                                            //     label: 'Group'.translate(this.props.lang),
                                            //     disabled: true
                                            // },

                                            {
                                                type: 'text',
                                                name: 'type',
                                                disabled: true,
                                                label: 'Type'.translate(this.props.lang),
                                            },


                                        ]
                                    },

                                ]
                            }
                        ]}
                    ></FormBuilder>
                    :
                    null
                }


            </div>
        );
    }
}

export default (Page(UserNotifications));