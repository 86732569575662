import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Page from '../containers/page';
import AOS from 'aos';
import 'aos/dist/aos.css'
import { getSearchParams } from '../helpers/linkHelper';
import landingHome from '../assets/images/landing-home.jpg';
import talk from '../assets/images/talk.png';
import lf2 from '../assets/images/lf2.jpg';
import lf3 from '../assets/images/lf3.jpg';
import fs1 from '../assets/images/fs1.jpg';
import penna from '../assets/images/penna.png';
import telefon from '../assets/images/telefon.png';
import Isvg from 'react-inlinesvg';
import curoflowBig from '../assets/svg/curoflowBig.svg';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Marquee from "react-fast-marquee";

import { API_ENDPOINT } from '../constants';

import {
    Container,
    Row,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Input,
    Button
} from 'reactstrap';

var striptags = require('striptags');
function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

class HomePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            promotedProjects: [],
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            }
        };

        this.sliderRef1 = React.createRef();
        this.sliderRef2 = React.createRef();
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        AOS.init();

    }

    checkIfHost = () => {
        // localStorage.setItem('checkHost', true)

        this.props[0].history.push('/login')
    }

    findConversation = (id) => {
        fetch(API_ENDPOINT + "/data/home-landing/conversation/" + id, {
            method: "GET",
            headers: {
                'content-type': 'application/json'
            }
        }).then(parseJSON).then(({ result, status }) => {
            if (result) {
                this.props[0].history.push(`/video-call/${result._id}?roomId=${result.roomId}`);
            }
        })
    }

    stopPropagation = (e) => {
        e?.stopPropagation?.();
    }

    render() {
        const settings = {
            dots: false,
            // infinite: true,
            // speed: 500,
            slidesToShow: 3,
            slidesToScroll: 8,
            // arrows: false,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        dots: false,
                        infinite: true,
                        speed: 12000,
                        // slidesToShow: 3,
                        // slidesToScroll: 1,
                        arrows: false,
                        autoplay: true,
                        autoplaySpeed: 0,
                        cssEase: 'linear',
                        // pauseOnHover: true,
                        pauseOnFocus: true,
                        // lazyLoad: 'progressive'
                        // fade: true
                    },
                },
            ]
        };
        let params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        return (

            <div style={{ background: 'white' }}>
                {
                    this.state.joinConversationModal ?
                        <Modal isOpen={this.state.joinConversationModal} size="lg" centered>
                            <ModalHeader close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ joinConversationModal: !this.state.joinConversationModal })}>&times;</button>}>{'Enter meeting code'.translate(this.props.lang)}</ModalHeader>
                            <ModalBody>

                                <Input value={this.state.joinConversationIDNumber} placeholder={'Code'.translate(this.props.lang)} onChange={(e) => {

                                    this.setState({
                                        joinConversationIDNumber: e.target.value,
                                    })
                                }} />


                            </ModalBody>
                            <ModalFooter className='buttons-right-green'>

                                <Button onClick={() => {
                                    this.findConversation(this.state.joinConversationIDNumber)
                                }} >{'Join'.translate(this.props.lang)}</Button>

                                <Button onClick={() => this.setState({ joinConversationModal: !this.state.joinConversationModal })}>{'Close'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        :
                        null
                }

                <section className='home-1'>
                    <div className="home1-content">
                        <h1>{this.state.section0 && this.state.section0.heading ? Object.translate(this.state.section0, 'heading', this.props.lang) : null}</h1>
                        <h6>{this.state.section0 && this.state.section0.subheading ? Object.translate(this.state.section0, 'subheading', this.props.lang) : null}</h6>
                        <div className='home1-btns'>
                            <Link to={this.state.section0 && this.state.section0.buttonlink1 ? Object.translate(this.state.section0, 'buttonlink1', this.props.lang) : null}>
                                {this.state.section0 && this.state.section0.buttontext1 ? Object.translate(this.state.section0, 'buttontext1', this.props.lang) : null}

                            </Link>
                            <button onClick={() => this.setState({ joinConversationModal: true })}>{this.state.section0 && this.state.section0.buttontext2 ? Object.translate(this.state.section0, 'buttontext2', this.props.lang) : null}</button>
                        </div>


                        <img src={this.state.section0 && this.state.section0.image ? (API_ENDPOINT + this.state.section0.image) : null} alt={this.state.section0 && this.state.section0.image?.replace('/uploads/', '').slice(0, -4)} />
                        <Isvg src={curoflowBig} />
                    </div>


                </section>

                <section className='home-logos'>

                    <div className='home-container'>

                        <div className='img-logo'>
                            <img src={this.state.section01 && this.state.section01.image0 ? API_ENDPOINT + this.state.section01.image0 : null} alt={this.state.section01 && this.state.section01.image0?.replace('/uploads/', '').slice(0, -4)} />
                        </div>
                        <div className='img-logo'>
                            <img src={this.state.section01 && this.state.section01.image1 ? API_ENDPOINT + this.state.section01.image1 : null} alt={this.state.section01 && this.state.section01.image1?.replace('/uploads/', '').slice(0, -4)} />
                        </div>
                        <div className='img-logo'>
                            <img className='home01-logo-width-300' src={this.state.section01 && this.state.section01.image2 ? API_ENDPOINT + this.state.section01.image2 : null} alt={this.state.section01 && this.state.section01.image2?.replace('/uploads/', '').slice(0, -4)} />
                        </div>
                        <div className='img-logo'>
                            <img src={this.state.section01 && this.state.section01.image3 ? API_ENDPOINT + this.state.section01.image3 : null} alt={this.state.section01 && this.state.section01.image3?.replace('/uploads/', '').slice(0, -4)} />
                        </div>
                        <div className='img-logo'>
                            <img src={this.state.section01 && this.state.section01.image4 ? API_ENDPOINT + this.state.section01.image4 : null} alt={this.state.section01 && this.state.section01.image4?.replace('/uploads/', '').slice(0, -4)} />
                        </div>
                        <div className='img-logo'>
                            <img src={this.state.section01 && this.state.section01.image5 ? API_ENDPOINT + this.state.section01.image5 : null} alt={this.state.section01 && this.state.section01.image5?.replace('/uploads/', '').slice(0, -4)} />
                        </div>
                        <div className='img-logo'>
                            <img src={this.state.section01 && this.state.section01.image6 ? API_ENDPOINT + this.state.section01.image6 : null} alt={this.state.section01 && this.state.section01.image6?.replace('/uploads/', '').slice(0, -4)} />
                        </div>
                        <div className='img-logo'>
                            <img src={this.state.section01 && this.state.section01.image7 ? API_ENDPOINT + this.state.section01.image7 : null} alt={this.state.section01 && this.state.section01.image7?.replace('/uploads/', '').slice(0, -4)} />
                        </div>
                    </div>
                </section>




                {/* 
                <section className='mob-slider'>
                    <div>


                        <Slider {...settings} className='mob-slider-content'>
                    
                            <div>
                                <img className='first-img' src={this.state.section01 && this.state.section01.image0 ? API_ENDPOINT + this.state.section01.image0 : null} alt={this.state.section01 && this.state.section01.image0?.replace('/uploads/', '').slice(0, -4)} />
                            </div>
                            <div>
                                <img className='second-img' src={this.state.section01 && this.state.section01.image1 ? API_ENDPOINT + this.state.section01.image1 : null} alt={this.state.section01 && this.state.section01.image1?.replace('/uploads/', '').slice(0, -4)} />
                            </div>
                            <div>
                                <img className='third-img' src={this.state.section01 && this.state.section01.image2 ? API_ENDPOINT + this.state.section01.image2 : null} alt={this.state.section01 && this.state.section01.image2?.replace('/uploads/', '').slice(0, -4)} />
                            </div>
                            <div>
                                <img className='fourth-img' src={this.state.section01 && this.state.section01.image3 ? API_ENDPOINT + this.state.section01.image3 : null} alt={this.state.section01 && this.state.section01.image3?.replace('/uploads/', '').slice(0, -4)} />
                            </div>
                            <div >
                                <img className='five-img' src={this.state.section01 && this.state.section01.image4 ? API_ENDPOINT + this.state.section01.image4 : null} alt={this.state.section01 && this.state.section01.image4?.replace('/uploads/', '').slice(0, -4)} />
                            </div>
                            <div >
                                <img className='six-img' src={this.state.section01 && this.state.section01.image5 ? API_ENDPOINT + this.state.section01.image5 : null} alt={this.state.section01 && this.state.section01.image5?.replace('/uploads/', '').slice(0, -4)} />
                            </div>
                            <div>
                                <img className='seven-img' src={this.state.section01 && this.state.section01.image6 ? API_ENDPOINT + this.state.section01.image6 : null} alt={this.state.section01 && this.state.section01.image6?.replace('/uploads/', '').slice(0, -4)} />
                            </div>
                            <div>
                                <img className='eight-img' src={this.state.section01 && this.state.section01.image7 ? API_ENDPOINT + this.state.section01.image7 : null} alt={this.state.section01 && this.state.section01.image7?.replace('/uploads/', '').slice(0, -4)} />
                            </div>

                        </Slider>
                    </div>

                </section> */}



                {/**   <div className='container-logos' onTouchStart={(e) => {
                    e?.stopPropagation?.();
                    if (this.sliderRef1?.current?.style) this.sliderRef1.current.style.animationPlayState = 'paused';
                    if (this.sliderRef2?.current?.style) this.sliderRef2.current.style.animationPlayState = 'paused';
                }} onTouchEnd={() => {
                    if (this.sliderRef1?.current?.style) this.sliderRef1.current.style.animationPlayState = 'running';
                    if (this.sliderRef2?.current?.style) this.sliderRef2.current.style.animationPlayState = 'running';
                }}>
                    <div className="logo-slider">
                        <div ref={this.sliderRef1}>
                            <img onClick={this.stopPropagation} onTouchStart={this.stopPropagation} onTouchEnd={this.stopPropagation} src={this.state.section01 && this.state.section01.image0 ? API_ENDPOINT + this.state.section01.image0 : null} alt={this.state.section01 && this.state.section01.image0?.replace('/uploads/', '').slice(0, -4)} />
                            <img onClick={this.stopPropagation} onTouchStart={this.stopPropagation} onTouchEnd={this.stopPropagation} src={this.state.section01 && this.state.section01.image1 ? API_ENDPOINT + this.state.section01.image1 : null} alt={this.state.section01 && this.state.section01.image1?.replace('/uploads/', '').slice(0, -4)} />
                            <img onClick={this.stopPropagation} onTouchStart={this.stopPropagation} onTouchEnd={this.stopPropagation} src={this.state.section01 && this.state.section01.image2 ? API_ENDPOINT + this.state.section01.image2 : null} alt={this.state.section01 && this.state.section01.image2?.replace('/uploads/', '').slice(0, -4)} />
                            <img onClick={this.stopPropagation} onTouchStart={this.stopPropagation} onTouchEnd={this.stopPropagation} src={this.state.section01 && this.state.section01.image3 ? API_ENDPOINT + this.state.section01.image3 : null} alt={this.state.section01 && this.state.section01.image3?.replace('/uploads/', '').slice(0, -4)} />
                            <img onClick={this.stopPropagation} onTouchStart={this.stopPropagation} onTouchEnd={this.stopPropagation} src={this.state.section01 && this.state.section01.image4 ? API_ENDPOINT + this.state.section01.image4 : null} alt={this.state.section01 && this.state.section01.image4?.replace('/uploads/', '').slice(0, -4)} />
                            <img onClick={this.stopPropagation} onTouchStart={this.stopPropagation} onTouchEnd={this.stopPropagation} src={this.state.section01 && this.state.section01.image5 ? API_ENDPOINT + this.state.section01.image5 : null} alt={this.state.section01 && this.state.section01.image5?.replace('/uploads/', '').slice(0, -4)} />
                            <img onClick={this.stopPropagation} onTouchStart={this.stopPropagation} onTouchEnd={this.stopPropagation} src={this.state.section01 && this.state.section01.image6 ? API_ENDPOINT + this.state.section01.image6 : null} alt={this.state.section01 && this.state.section01.image6?.replace('/uploads/', '').slice(0, -4)} />
                            <img onClick={this.stopPropagation} onTouchStart={this.stopPropagation} onTouchEnd={this.stopPropagation} src={this.state.section01 && this.state.section01.image7 ? API_ENDPOINT + this.state.section01.image7 : null} alt={this.state.section01 && this.state.section01.image7?.replace('/uploads/', '').slice(0, -4)} />
                        </div>
                        <div ref={this.sliderRef2}>
                            <img onClick={this.stopPropagation} onTouchStart={this.stopPropagation} onTouchEnd={this.stopPropagation} src={this.state.section01 && this.state.section01.image0 ? API_ENDPOINT + this.state.section01.image0 : null} alt={this.state.section01 && this.state.section01.image0?.replace('/uploads/', '').slice(0, -4)} />
                            <img onClick={this.stopPropagation} onTouchStart={this.stopPropagation} onTouchEnd={this.stopPropagation} src={this.state.section01 && this.state.section01.image1 ? API_ENDPOINT + this.state.section01.image1 : null} alt={this.state.section01 && this.state.section01.image1?.replace('/uploads/', '').slice(0, -4)} />
                            <img onClick={this.stopPropagation} onTouchStart={this.stopPropagation} onTouchEnd={this.stopPropagation} src={this.state.section01 && this.state.section01.image2 ? API_ENDPOINT + this.state.section01.image2 : null} alt={this.state.section01 && this.state.section01.image2?.replace('/uploads/', '').slice(0, -4)} />
                            <img onClick={this.stopPropagation} onTouchStart={this.stopPropagation} onTouchEnd={this.stopPropagation} src={this.state.section01 && this.state.section01.image3 ? API_ENDPOINT + this.state.section01.image3 : null} alt={this.state.section01 && this.state.section01.image3?.replace('/uploads/', '').slice(0, -4)} />
                            <img onClick={this.stopPropagation} onTouchStart={this.stopPropagation} onTouchEnd={this.stopPropagation} src={this.state.section01 && this.state.section01.image4 ? API_ENDPOINT + this.state.section01.image4 : null} alt={this.state.section01 && this.state.section01.image4?.replace('/uploads/', '').slice(0, -4)} />
                            <img onClick={this.stopPropagation} onTouchStart={this.stopPropagation} onTouchEnd={this.stopPropagation} src={this.state.section01 && this.state.section01.image5 ? API_ENDPOINT + this.state.section01.image5 : null} alt={this.state.section01 && this.state.section01.image5?.replace('/uploads/', '').slice(0, -4)} />
                            <img onClick={this.stopPropagation} onTouchStart={this.stopPropagation} onTouchEnd={this.stopPropagation} src={this.state.section01 && this.state.section01.image6 ? API_ENDPOINT + this.state.section01.image6 : null} alt={this.state.section01 && this.state.section01.image6?.replace('/uploads/', '').slice(0, -4)} />
                            <img onClick={this.stopPropagation} onTouchStart={this.stopPropagation} onTouchEnd={this.stopPropagation} src={this.state.section01 && this.state.section01.image7 ? API_ENDPOINT + this.state.section01.image7 : null} alt={this.state.section01 && this.state.section01.image7?.replace('/uploads/', '').slice(0, -4)} />
                        </div>
                    </div>
                </div>
                       */}


                <section className='logo-slider-video'>
                    <Marquee>
                        <img src={this.state.section01 && this.state.section01.image0 ? API_ENDPOINT + this.state.section01.image0 : null} alt={this.state.section01 && this.state.section01.image0?.replace('/uploads/', '').slice(0, -4)} />
                        <img src={this.state.section01 && this.state.section01.image1 ? API_ENDPOINT + this.state.section01.image1 : null} alt={this.state.section01 && this.state.section01.image1?.replace('/uploads/', '').slice(0, -4)} />
                        <img src={this.state.section01 && this.state.section01.image2 ? API_ENDPOINT + this.state.section01.image2 : null} alt={this.state.section01 && this.state.section01.image2?.replace('/uploads/', '').slice(0, -4)} />
                        <img src={this.state.section01 && this.state.section01.image3 ? API_ENDPOINT + this.state.section01.image3 : null} alt={this.state.section01 && this.state.section01.image3?.replace('/uploads/', '').slice(0, -4)} />
                        <img src={this.state.section01 && this.state.section01.image4 ? API_ENDPOINT + this.state.section01.image4 : null} alt={this.state.section01 && this.state.section01.image4?.replace('/uploads/', '').slice(0, -4)} />
                        <img src={this.state.section01 && this.state.section01.image5 ? API_ENDPOINT + this.state.section01.image5 : null} alt={this.state.section01 && this.state.section01.image5?.replace('/uploads/', '').slice(0, -4)} />
                        <img src={this.state.section01 && this.state.section01.image6 ? API_ENDPOINT + this.state.section01.image6 : null} alt={this.state.section01 && this.state.section01.image6?.replace('/uploads/', '').slice(0, -4)} />
                        <img src={this.state.section01 && this.state.section01.image7 ? API_ENDPOINT + this.state.section01.image7 : null} alt={this.state.section01 && this.state.section01.image7?.replace('/uploads/', '').slice(0, -4)} />
                    </Marquee>
                </section>




                <section className='home-2'>
                    <div className="home-container">
                        <div className="left">
                            <h2>{this.state.section1 && this.state.section1.heading ? Object.translate(this.state.section1, 'heading', this.props.lang) : 'Curoflow Meetings'.translate(this.props.lang)}</h2>
                            {/* <p>{this.state.section1 && this.state.section1.description ? Object.translate(this.state.section1, 'description', this.props.lang) : 'Meet people with Curoflow video. Give yourself or your organization the tools to conduct safe, reliable and functional meetings, without apps to download or settings to adjust. Quick and easy sign-up and you are ready to go in a matter of minutes.'.translate(this.props.lang)}</p> */}
                            <div dangerouslySetInnerHTML={{ __html: Object.translate(this.state.section1 && this.state.section1, 'description', this.props.lang) }}></div>
                            <div className='leftButtons'>
                                <Link to={this.state.section0 && this.state.section0.buttonlink1 ? Object.translate(this.state.section0, 'buttonlink1', this.props.lang) : null}>
                                    {this.state.section0 && this.state.section0.buttontext1 ? Object.translate(this.state.section0, 'buttontext1', this.props.lang) : null}
                                </Link>
                                <Link to={this.state.section1 && this.state.section1.buttonLink ? Object.translate(this.state.section1, 'buttonLink', this.props.lang) : null}>
                                    {this.state.section1 && this.state.section1.buttonText ? Object.translate(this.state.section1, 'buttonText', this.props.lang) : null}
                                </Link>
                            </div>
                        </div>
                        <div className="right">
                            <img src={this.state.section1 && this.state.section1.image ? API_ENDPOINT + this.state.section1.image : null} alt={this.state.section1 && this.state.section1.image?.replace('/uploads/', '').slice(0, -4)} />
                        </div>
                    </div>
                </section>
                <section className="features-home">
                    <div className="title-features">
                        <h2>{this.state.section3 && this.state.section3.heading ? Object.translate(this.state.section3, 'heading', this.props.lang) : null} </h2>
                        <h6>{this.state.section3 && this.state.section3.text ? Object.translate(this.state.section3, 'text', this.props.lang) : null}</h6>
                    </div>
                    <div className="features-home-content1">
                        <div className="content-1">
                            <Isvg src={this.state.section3 && this.state.section3.image1 ? API_ENDPOINT + this.state.section3.image1 : null} alt={this.state.section3 && this.state.section3.image1?.replace('/uploads/', '').slice(0, -4)} />
                            <p>{this.state.section3 && this.state.section3.text1 ? Object.translate(this.state.section3, 'text1', this.props.lang) : null}</p>
                        </div>
                        <div className="content-1">
                            <Isvg src={this.state.section3 && this.state.section3.image2 ? API_ENDPOINT + this.state.section3.image2 : null} alt={this.state.section3 && this.state.section3.image2?.replace('/uploads/', '').slice(0, -4)} />
                            <p>{this.state.section3 && this.state.section3.text2 ? Object.translate(this.state.section3, 'text2', this.props.lang) : null}</p>
                        </div>
                        <div className="content-1">
                            <Isvg src={this.state.section3 && this.state.section3.image3 ? API_ENDPOINT + this.state.section3.image3 : null} alt={this.state.section3 && this.state.section3.image3?.replace('/uploads/', '').slice(0, -4)} />
                            <p>{this.state.section3 && this.state.section3.text3 ? Object.translate(this.state.section3, 'text3', this.props.lang) : null}</p>
                        </div>
                        <div className="content-1">
                            <Isvg src={this.state.section3 && this.state.section3.image4 ? API_ENDPOINT + this.state.section3.image4 : null} alt={this.state.section3 && this.state.section3.image4?.replace('/uploads/', '').slice(0, -4)} />
                            <p>{this.state.section3 && this.state.section3.text4 ? Object.translate(this.state.section3, 'text4', this.props.lang) : null}</p>
                        </div>

                    </div>
                    <div className="features-home-content1">
                        <div className="content-1">
                            <Isvg src={this.state.section3 && this.state.section3.image5 ? API_ENDPOINT + this.state.section3.image5 : null} alt={this.state.section3 && this.state.section3.image5?.replace('/uploads/', '').slice(0, -4)} />
                            <p>{this.state.section3 && this.state.section3.text5 ? Object.translate(this.state.section3, 'text5', this.props.lang) : null}</p>
                        </div>
                        <div className="content-1">

                            <Isvg src={this.state.section3 && this.state.section3.image6 ? API_ENDPOINT + this.state.section3.image6 : null} alt={this.state.section3 && this.state.section3.image6?.replace('/uploads/', '').slice(0, -4)} />
                            <p>{this.state.section3 && this.state.section3.text6 ? Object.translate(this.state.section3, 'text6', this.props.lang) : null}</p>
                        </div>
                        <div className="content-1">

                            <Isvg src={this.state.section3 && this.state.section3.image7 ? API_ENDPOINT + this.state.section3.image7 : null} alt={this.state.section3 && this.state.section3.image7?.replace('/uploads/', '').slice(0, -4)} />
                            <p>{this.state.section3 && this.state.section3.text7 ? Object.translate(this.state.section3, 'text7', this.props.lang) : null}</p>
                        </div>
                        <div className="content-1">

                            <Isvg src={this.state.section3 && this.state.section3.image8 ? API_ENDPOINT + this.state.section3.image8 : null} alt={this.state.section3 && this.state.section3.image8?.replace('/uploads/', '').slice(0, -4)} />
                            <p>{this.state.section3 && this.state.section3.text8 ? Object.translate(this.state.section3, 'text8', this.props.lang) : null}</p>
                        </div>

                    </div>
                </section>
                {this.props.lang === 'se' ?
                    <section className='home-5'>
                        <div className="home-container">
                            <div className="left">
                                <h2>{this.state.section5 && this.state.section5.heading ? Object.translate(this.state.section5, 'heading', this.props.lang) : 'Do you want more information?'.translate(this.props.lang)}</h2>
                                <div dangerouslySetInnerHTML={{ __html: Object.translate(this.state.section5 && this.state.section5, 'description', this.props.lang) }}></div>
                            </div>
                            <div className="right">
                                <img src={this.state.section5 && this.state.section5.image ? API_ENDPOINT + this.state.section5.image : null} alt={this.state.section5 && this.state.section5.image?.replace('/uploads/', '').slice(0, -4)} />
                            </div>
                        </div>
                    </section> : null

                }

                <section className='home-4'>
                    <div className="home-container">
                        <div className="left">

                            <img src={this.state.section4 && this.state.section4.image ? API_ENDPOINT + this.state.section4.image : null} alt={this.state.section4 && this.state.section4.image?.replace('/uploads/', '').slice(0, -4)} />
                        </div>

                        <div className="right">
                            <h2>{this.state.section4 && this.state.section4.heading ? Object.translate(this.state.section4, 'heading', this.props.lang) : 'Do you want more information?'.translate(this.props.lang)}</h2>
                            <p>{this.state.section4 && this.state.section4.description ? Object.translate(this.state.section4, 'description', this.props.lang) : 'Unsure if your hardware and software are compatible with Curoflow Video?'.translate(this.props.lang)}</p>
                            <Link to={this.state.section4 && this.state.section4.buttonlink ? Object.translate(this.state.section4, 'buttonlink', this.props.lang) : null} className="button-1">
                                {this.state.section0 && this.state.section4.buttontext ? Object.translate(this.state.section4, 'buttontext', this.props.lang) : null}
                            </Link>
                            <p>{this.state.section4 && this.state.section4.description1 ? Object.translate(this.state.section4, 'description1', this.props.lang) : 'Would you like to discuss how Curoflow Video can be used in your large organization?'.translate(this.props.lang)}</p>
                            <Link to={this.state.section4 && this.state.section4.buttonlink1 ? Object.translate(this.state.section4, 'buttonlink1', this.props.lang) : null} className="button-2">
                                {this.state.section0 && this.state.section4.buttontext1 ? Object.translate(this.state.section4, 'buttontext1', this.props.lang) : null}
                            </Link>
                        </div>
                    </div>
                </section>


            </div>
        );
    }
}

export default Page(HomePage);