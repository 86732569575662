import React, { Component } from 'react';
import moment from 'moment';
import Page from '../../containers/page';
import ListBuilder from '../../components/listBuilder';
import { Link } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Input, Label, Table
} from 'reactstrap';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import ErrorModal from '../../components/errorModal';
import profileUser from "../../assets/images/user.png";
import warrning from '../../assets/svg/warrning.svg';
import Isvg from 'react-inlinesvg';
import attach from '../../assets/svg/attach.svg';
import xIcon from '../../assets/svg/x.svg';
// import UpdateAssignedClinicGroup from '../../components/updateAssignedClinicGroup';
import { Player } from '@lottiefiles/react-lottie-player';
var striptags = require('striptags');

/**
* Support page
* @author   Stefan Pantic
*/
class HomePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            promotedProjects: [],
            ...props.initialData,
            form: false,

            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            tikets: [],
            category: {},
            messages: [],
            assignedList: true,
            doctorTikets: [],
            assignedTickets: [],
            tiket: -1,
            displayNone: false,
            pageNumber: 0

        };
    }

    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1, user: this.props.uData._id, alias: localStorage.getItem('groupAlias'), group: this.props.selectedGroup })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1, user: this.props.uData._id, alias: localStorage.getItem('groupAlias'), group: this.props.selectedGroup, pageNumber: this.state.pageNumber })).then((data) => {

                if (data && data.assignedTickets && this.state.assignedTickets && this.state.assignedTickets.length) {
                    for (let i = 0; i < data.assignedTickets.length; i++) {
                        for (let j = 0; j < this.state.assignedTickets.length; j++) {
                            if (this.state.assignedTickets[j]._id == data.assignedTickets[i]._id) {
                                this.state.assignedTickets.splice(j, 1)
                            }
                        }
                    }
                    data.assignedTickets = [...this.state.assignedTickets, ...data.assignedTickets]
                }

                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

                })
            })
        }

        this.props.verifyUser()

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();

        this.interval = setInterval(() => {
            this.get();

        }, 60 * 1000);

    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval)
        }
    }
    componentDidUpdate(prevProps, prevState) {


        if (prevProps.selectedGroup != this.props.selectedGroup) {
            this.get();
        }
        if (this.props[0].location && this.props[0].location.state && this.props[0].location.state.messageModal && this.state.assignedTickets && this.state.assignedTickets.length && !prevState.assignedTickets.length && !this.state.messageModal) {
            for (let i = 0; i < this.state.assignedTickets.length; i++) {
                if (this.props[0].location.state.messageModal.toString() == this.state.assignedTickets[i]._id.toString()) {
                    this.setState({
                        messageModal: this.state.assignedTickets[i]
                    }, () => this.seen(this.state.assignedTickets[i]._id), this.findMessages(this.state.assignedTickets[i]._id))
                }
            }
        }


    }

    seen = (id) => {

        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/support-category/tiket/update/' + id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify()
            }).then(res => res.json()).then((result) => {
                this.get();

            })

        })
    }

    updateMessgeModalState = () => {
        if (this.props[0].location && this.props[0].location.state && this.props[0].location.state.messageModal && this.state.assignedTickets && this.state.assignedTickets.length && !prevState.assignedTickets.length) {
            for (let i = 0; i < this.state.assignedTickets.length; i++) {
                if (this.props[0].location.state.messageModal.toString() == this.state.assignedTickets[i]._id.toString()) {
                    this.setState({
                        messageModal: this.state.assignedTickets[i]
                    }, () => this.seen(this.state.assignedTickets[i]._id))
                }
            }
        }
    }

    sendAnswer = (data) => {
        if (!data.userWhoCreatedTicket) {
            fetch(API_ENDPOINT + '/support-category/tiket/message-site', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ answer: data.answer, email: data.email, tiketNumber: data.tiketNumber, userID: this.props.uData._id, phone: data.phone, tiket: data._id, image: this.state.image, document: this.state.document })
            }).then(res => res.json()).then((result) => {
                if (result.error) {
                    this.setState({
                        error: result.error.translate(this.props.lang)
                    })
                } else {
                    this.get();

                    let messageModal = this.state.messageModal;
                    messageModal.answer = '';
                    this.setState({
                        messageModal,
                        image: null,
                        document: null
                    }, () => this.findMessages(data._id))
                }

            })
        } else {


        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/support-category/answer', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ answer: data.answer, tiket: data._id, user: this.props.uData._id, image: this.state.image, document: this.state.document })
            }).then(res => res.json()).then((result) => {
                if (result.error) {
                    this.setState({
                        error: result.error.translate(this.props.lang)
                    })
                } else {
                    this.get();

                    let messageModal = this.state.messageModal;
                    messageModal.answer = '';
                    this.setState({
                        messageModal,
                        image: null,
                        document: null
                    }, () => this.findMessages(data._id))
                }

            })

        })
        }
    }

    closeTiket = (data) => {

        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/support-category/close/' + data, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify()
            }).then(res => res.json()).then((result) => {
                this.setState({
                    closeTicketModal: null,
                    messageModal: null
                })
                this.get();

            })

        })
    }

    uploadDocument = (e) => {
        let input = e.target;
        if (input.files && input.files[0]) {

            let fsize = input.files[0].size;
            let file = Math.round((fsize / 1024))
            this.setState({
                uploadTimestampDoc: Math.floor(new Date().getTime() / 1000)
            })
            if (file > 5120) {
                this.setState({ error: "File size limit has been reached! (max 5 MB)".translate(this.props.lang) })
            } else {

                this.setState({
                    _documentUploading: true
                })

                let formData = new FormData();
                formData.append('file', input.files[0]);
                formData.append('group', this.props.selectedGroup)
                formData.append('fileType', 'clinicSupport')

                fetch(API_ENDPOINT + '/chat/upload/document', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,

                    },
                    body: formData
                }).then((res) => res.json()).then((img) => {
                    this.setState({
                        document: img
                    })
                    this.setState({
                        _documentUploading: null
                    })
                });
            }
        }
    }

    uploadImage = (e) => {
        let input = e.target;
        if (input.files && input.files[0]) {

            let fsize = input.files[0].size;
            let file = Math.round((fsize / 1024))
            this.setState({
                uploadTimestamp: Math.floor(new Date().getTime() / 1000)
            })
            if (file > 10240) {
                this.setState({ error: "File size limit has been reached! (max 10 MB)".translate(this.props.lang) })
            } else {

                this.setState({
                    _imageUploading: true,
                })

                let formData = new FormData();
                formData.append('file', input.files[0]);
                formData.append('group', this.props.selectedGroup)
                formData.append('fileType', 'clinicSupport')

                fetch(API_ENDPOINT + '/chat/upload/image', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,

                    },
                    body: formData
                }).then((res) => res.text()).then((img) => {
                    this.setState({
                        image: img
                    })
                    this.setState({
                        _imageUploading: null
                    })
                });
            }
        }
    }

    findMessages = (id) => {
        fetch(API_ENDPOINT + '/support-category/all-messages/' + id, {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({})
        }).then(res => res.json()).then((result) => {
            if (result.error) {
                this.setState({
                    error: result.error.translate(this.props.lang)
                })
            } else {

                this.setState({
                    messages: result
                })
            }

        })
    }

    render() {

        return (
            <div className="dashboard">
                {this.state._imageUploading || this.state._documentUploading ?
                    <div className="file-progress-loader">
                        <div>
                            <Player
                                onEvent={(event) => {
                                    if (event === "load" && this.player && this.player.current) this.player.current.play();
                                }}
                                ref={this.player} // set the ref to your class instance
                                autoplay={true}
                                loop={true}
                                controls={true}
                                src="/lf30_editor_l5cxzdyf.json"
                                renderer={'svg'}
                                style={{ height: "128px", width: "128px", }}
                            ></Player>
                            <h6>{'Processing file...'.translate(this.state.lang)}</h6>
                        </div>
                    </div>
                    : null
                }
                <Container fluid>
                    <div className="panel select-clinics-wrapper">
                        <h5 className="component-header mb-35">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h5>

                        <Row>

                            {this.state.assignedList ?
                                <Col lg="4">
                                    <div className={typeof window != 'undefined' && window.innerWidth < 768 && this.state.displayNone ? "support-ticket-none" : "support-ticket"}>

                                        <>
                                            {this.state.assignedTickets.sort(function (a, b) { return b.timestemp - a.timestemp }).sort(function (a, b) { return b.close - a.close }).map(item => {

                                                return (

                                                    <button className={this.state.messageModal && this.state.messageModal._id == item._id ? item.close ? 'ticket-closed active-ticket-red' : item.supportUserWhoTakeTicket ? 'ticked-open active-ticket-green' : 'ticket-inprogress active-ticket-inprogress' : item.close ? 'ticket-closed' : item.supportUserWhoTakeTicket ? 'ticked-open' : 'ticket-inprogress'} onClick={() => this.setState({ messageModal: item, displayNone: true }, () => { this.seen(item._id), this.findMessages(item._id) })}>
                                                        <h6>{item.name ? item.name : 'Site - support'}</h6>
                                                        <h6>{'Support-ID:'.translate(this.props.lang)} {item.tiketNumber} - {item.subject ? item.subject : 'Site'}</h6>
                                                        {/* <h6>{'Clinic:'.translate(this.props.lang)} {item.clinicName ? item.clinicName : item.organization ? item.organization : ''}</h6> */}
                                                        {item.userWhoCreatedTicket && item.userWhoCreatedTicket.toString() != this.props.uData._id.toString() ? !item.seenSupport ? <p className="ticket-notification"></p> : '' : !item.seenUser && item.userWhoCreatedTicket ? <p className="ticket-notification"></p> : item.userWhoCreatedTicket ? '' : !item.seenSupport ? <p className="ticket-notification"></p> : ''}
                                                        <p style={{ fontWeight: 600, color: '#3A3F56', textAlign: 'left' }}> {item.email ? `${'E-mail'.translate(this.props.lang)}: ${item.email}` : null}</p>
                                                        <h6 style={{ lineHeight: 0, display: 'block', marginBottom: 20 }}> {item.phone ? <p>{'Phone'.translate(this.props.lang)}: {item.phone}</p> : null}</h6>
                                                        <div className="ticket-body">
                                                            {item.profilePicture ?
                                                                <img src={API_ENDPOINT + item.profilePicture} />

                                                                :
                                                                <img src={profileUser} />

                                                            }

                                                            <h6>{item.userName ? item.userName : item.name ? item.name : ''}</h6>
                                                            <p>{moment.unix(item.timestemp).format(`${this.props.dateFormat} HH:mm`)}</p>
                                                        </div>

                                                        {this.props.uData.userLevel != 1 && this.props.uData.userLevel != 2 ?
                                                            <Button className={'close-support-button-text'} onClick={() => this.setState({ closeTicketModal: item._id })} disabled={item.close} >{item.close ? 'Closed'.translate(this.props.lang) : 'Close tiket'.translate(this.props.lang)}</Button> : null}
                                                        {/* {!item.userWhoCreatedTicket && !item.clinic ? <Button className={'close-support-button-text'} style={{ marginLeft: 20 }} onClick={() => this.setState({ assignedClinicModal: item._id })}>{'Assigned clinic'.translate(this.props.lang)}</Button> : null} */}
                                                    </button>
                                                )
                                            })}
                                        </>
                                    </div>
                                    {!(typeof window != 'undefined' && window.innerWidth < 768 && this.state.messageModal) ?
                                        <Button color='primary' onClick={() => this.setState({ pageNumber: this.state.pageNumber + 1 }, () => this.get())} >{'Load more'.translate(this.props.lang)}</Button>
                                        : null}
                                </Col>
                                : null}

                            <Col lg="8">
                                <div className="ticket-answer">
                                    {this.state.messageModal && typeof window != 'undefined' && window.innerWidth > 768 ?
                                        <div className="ticket-message-tab-container">

                                            {this.state.messages.sort(function (a, b) { return a.timestemp - b.timestemp }).map(item => {
                                                return (
                                                    <div className="mesage-box">
                                                        <div className="message-header">
                                                            <div className="message-header-container">
                                                                {item.profilePicture ?
                                                                    <img src={API_ENDPOINT + item.profilePicture} />

                                                                    :
                                                                    <img src={profileUser} />

                                                                }
                                                                <h6>{item.user}</h6>
                                                            </div>
                                                            <p>{moment.unix(item.timestemp).format(`${this.props.dateFormat} HH:mm`)}</p>
                                                        </div>
                                                        <p>
                                                            {item.message}
                                                            <div >
                                                                {item.image ? <a target='_blank' href={API_ENDPOINT + item.image}>{item.image}</a> : null}
                                                                {
                                                                    item.document ?
                                                                        <div className="document"><a target="_blank" download href={API_ENDPOINT + item.document.file}><Isvg src={attach} style={{ width: 20, height: 20 }} />{item.document.name}</a></div>
                                                                        :
                                                                        null
                                                                }
                                                            </div>

                                                        </p>
                                                    </div>

                                                )
                                            })}

                                            {this.state.messageModal.close ?
                                                null
                                                : <FormGroup className="ticket-message-group ticket-message-tab">
                                                    <Label>{'Message'.translate(this.props.lang)}</Label>
                                                    <Input type='textarea' rows="6" className="textarea-six-row" onChange={(e) => {
                                                        let messageModal = this.state.messageModal;
                                                        messageModal.answer = e.target.value;
                                                        this.setState({
                                                            messageModal
                                                        })
                                                    }} value={this.state.messageModal.answer} />
                                                </FormGroup>}
                                            <div style={{ background: 'white' }}>
                                                {this.state.image ? <a target='_blank' href={API_ENDPOINT + this.state.image}>{this.state.image}</a> : null}
                                                {
                                                    this.state.document ?
                                                        <div className="document"><a target="_blank" download href={API_ENDPOINT + this.state.document.file}><Isvg src={attach} style={{ width: 20, height: 20 }} />{this.state.document.name}</a></div>
                                                        :
                                                        null
                                                }
                                            </div>

                                            {this.state.messageModal.close ? null :
                                                <Button color="primary" style={{ marginBottom: 20, marginRight: 10 }} className={this.state._documentUploading ? 'attach uploading' : this.state.document ? 'attach uploaded' : "attach"} onClick={() => { if (this.documentInput) this.documentInput.click() }}>
                                                    {'Attach file'.translate(this.props.lang)}
                                                </Button>}
                                            {this.state.messageModal.close ? null :
                                                <Button color="primary" style={{ marginBottom: 20, marginRight: 10 }} className={this.state._imageUploading ? 'image uploading' : this.state.image ? 'image uploaded' : "image"} onClick={() => { if (this.imageInput) this.imageInput.click() }}>
                                                    {'Attach image'.translate(this.props.lang)}
                                                </Button>}
                                            {this.state.messageModal.close ? null :
                                                <Button className="news-ticket-button" disabled={this.state.messageModal.answer || this.state.image || this.state.document ? this.state.messageModal.answer != '' || this.state.image != '' || this.state.document != '' ? false : true : true} onClick={() => this.sendAnswer(this.state.messageModal)}>{'Send'.translate(this.props.lang)}</Button>
                                            }
                                        </div>
                                        :
                                        this.state.messageModal && typeof window != 'undefined' && window.innerWidth < 768 ?
                                            <div className="ticket-message-tab-container ticket-message-tab-container-fixed">
                                                <button className="support-back-button" onClick={() => { this.setState({ displayNone: false, messageModal: !this.state.messageModal }) }}>{'Back'.translate(this.props.lang)}</button>
                                                {this.state.messages.sort(function (a, b) { return a.timestemp - b.timestemp }).map(item => {
                                                    return (
                                                        <div className="mesage-box">
                                                            <div className="message-header">
                                                                <div className="message-header-container">
                                                                    {item.profilePicture ?
                                                                        <img src={API_ENDPOINT + item.profilePicture} />

                                                                        :
                                                                        <img src={profileUser} />

                                                                    }
                                                                    <h6>{item.user ? item.user : ''}</h6>
                                                                </div>
                                                                <p>{moment.unix(item.timestemp).format(`${this.props.dateFormat} HH:mm`)}</p>
                                                            </div>
                                                            <p>
                                                                {item.message}
                                                                <div >
                                                                    {item.image ? <a target='_blank' href={API_ENDPOINT + item.image}>{item.image}</a> : null}
                                                                    {
                                                                        item.document ?
                                                                            <div className="document"><a target="_blank" download href={API_ENDPOINT + item.document.file}><Isvg src={attach} style={{ width: 20, height: 20 }} />{item.document.name}</a></div>
                                                                            :
                                                                            null
                                                                    }
                                                                </div>
                                                            </p>
                                                        </div>

                                                    )
                                                })}

                                                {this.state.messageModal.close ?
                                                    null
                                                    : <FormGroup className="ticket-message-group ticket-message-tab">
                                                        <Label>{'Message'.translate(this.props.lang)}</Label>
                                                        <Input type='textarea' rows="6" className="textarea-six-row" onChange={(e) => {
                                                            let messageModal = this.state.messageModal;
                                                            messageModal.answer = e.target.value;
                                                            this.setState({
                                                                messageModal
                                                            })
                                                        }} value={this.state.messageModal.answer} />
                                                    </FormGroup>}
                                                <div style={{ background: 'white' }}>
                                                    {this.state.image ? <a target='_blank' href={API_ENDPOINT + this.state.image}>{this.state.image}</a> : null}
                                                    {
                                                        this.state.document ?
                                                            <div className="document"><a target="_blank" download href={API_ENDPOINT + this.state.document.file}><Isvg src={attach} style={{ width: 20, height: 20 }} />{this.state.document.name}</a></div>
                                                            :
                                                            null
                                                    }
                                                </div>
                                                <div className="chat-buttons-footer-support">
                                                    {this.state.messageModal.close ? null :
                                                        <Button color="primary" style={{ marginBottom: 20, marginRight: 10 }} className={this.state._documentUploading ? 'attach uploading' : this.state.document ? 'attach uploaded' : "attach"} onClick={() => { if (this.documentInput) this.documentInput.click() }}>
                                                            {'Attach file'.translate(this.props.lang)}
                                                        </Button>}
                                                    {this.state.messageModal.close ? null :
                                                        <Button color="primary" style={{ marginBottom: 20, marginRight: 10 }} className={this.state._imageUploading ? 'image uploading' : this.state.image ? 'image uploaded' : "image"} onClick={() => { if (this.imageInput) this.imageInput.click() }}>
                                                            {'Attach image'.translate(this.props.lang)}
                                                        </Button>}
                                                    {this.state.messageModal.close ? null :
                                                        <Button className="news-ticket-button" disabled={this.state.messageModal.answer || this.state.image || this.state.document ? this.state.messageModal.answer != '' || this.state.image != '' || this.state.document != '' ? false : true : true} onClick={() => this.sendAnswer(this.state.messageModal)}>{'Send'.translate(this.props.lang)}</Button>
                                                    }
                                                </div>
                                            </div>
                                            : null}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <input ref={(node) => this.documentInput = node} type="file" accept=".doc, .docx, .pdf" title={"No file chosen".translate(this.props.lang)} key={this.state.uploadTimestampDoc} onChange={this.uploadDocument} style={{ pointerEvents: 'none', opacity: 0 }} />
                <input ref={(node) => this.imageInput = node} type="file" title={"No file chosen".translate(this.props.lang)} accept="image/png, image/jpeg, image/jpg, image/gif, image/svg" key={this.state.uploadTimestamp} onChange={this.uploadImage} style={{ pointerEvents: 'none', opacity: 0 }} />

                {this.state.error ? (
                    <ErrorModal lang={this.props.lang}
                        isOpen={this.state.error}
                        toggle={() => this.setState({ error: null })}

                    >
                        {this.state.error.translate(this.props.lang)}
                    </ErrorModal>
                ) : null}

                {this.state.closeTicketModal ? (
                    <Modal isOpen={this.state.closeTicketModal} centered>
                        <ModalHeader>{'Close ticket'.translate(this.props.lang)}</ModalHeader>
                        <ModalBody className="delete-modal">
                            <Isvg src={warrning} />
                            <p className='message-info'>{'Do you really want to close this ticket?'.translate(this.props.lang)}</p>

                        </ModalBody>
                        <ModalFooter className='buttons-right-blue'>
                            <Button color="primary" style={{ margin: 10 }} onClick={() => this.closeTiket(this.state.closeTicketModal)}>{'Yes'.translate(this.props.lang)}</Button>
                            <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ closeTicketModal: null })}>{'No'.translate(this.props.lang)}</Button>

                        </ModalFooter>

                    </Modal>
                ) : null}

                {/* <Modal isOpen={this.state.assignedClinicModal} centered size="xl">
                    <ModalHeader toggle={() => this.setState({ assignedClinicModal: !this.state.assignedClinicModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ assignedClinicModal: !this.state.assignedClinicModal })}>&times;</button>}>{'Select clinic'.translate(this.props.lang)}</ModalHeader>

                    <ModalBody>
                        <UpdateAssignedClinicGroup {...this.props} assignedClinicModal={this.state.assignedClinicModal} close={(data) => {
                            this.setState({
                                assignedClinicModal: !this.state.assignedClinicModal,
                                messageModal: data
                            }, () => {
                                this.get()
                            })

                        }}></UpdateAssignedClinicGroup>
                    </ModalBody>

                </Modal> */}
            </div>

        );


    }
}

export default Page(HomePage);