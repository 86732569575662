import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

import { FormGroup, Label, Input, FormFeedback, FormText } from 'reactstrap';

/**
* Component for NetID
* @author   Milan Stanojevic
*/
class SocialSecurityNumber extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }






    render() {
        return (
            <FormGroup>
                {this.props.label ? <Label check={this.props.type == 'checkbox'} size="sm" className={this.props.error ? "required" : ""}>{this.props.label} ({'SE0000000000-0000'.substr(0, this.props.value ? this.props.value.length : 0)}<span style={{ color: 'red' }}>{'SE0000000000-0000'.substr(this.props.value ? this.props.value.length : 0, 17)}</span> ) {this.props.haveValidation ? <span className="required-label-span">*</span> : null}</Label> : null}
                <Input size="sm" disabled={this.props.disabled} name={this.props.name} invalid={this.props.error ? true : false} onChange={(e) => {
                    let value = e.target.value;
                    value = value.toUpperCase();

                    /*if (value.length > 12){
                        if (value[12] != '-'){
                            value = value.substr(0, 12) + '-' + value.substr(12, -1);
                        }
                    }*/

                    for (let i = 0; i < value.length; i++) {
                        if (i == 0 || i == 1) {
                            if (!(value[i] >= 'A' && value[i] <= 'z'))
                                return;
                        }

                        /*if (i >= 2 && i < 11) {
                            if (!(value[i] >= 0 && value[i] <= 9))
                                return;
                        }*/
                       /* if (i == 12) {
                            if (value[i] != '-')
                                return;
                        }*/
                        /*if (i >= 12 && i < 16) {
                            if (!(value[i] >= 0 && value[i] <= 9))
                                return;
                        }*/

                    }

                    /*if (value.length > 17) {
                        return;
                    }*/

                    this.props.onChange(value);
                }} value={this.props.value} placeholder={this.props.placeholder}></Input>
                {this.props.error ? <FormFeedback >{this.props.error}</FormFeedback> : null}
            </FormGroup>
        );
    }
}

export default SocialSecurityNumber;
