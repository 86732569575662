import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Button, Input
} from 'reactstrap';

import Isvg from 'react-inlinesvg'

import ListBuilder from '../../components/listBuilder';
import ErrorModal from '../../components/errorModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';
import viewIcon from '../../assets/svg/eye.svg';
import xIcon from '../../assets/svg/x.svg';
import garbageIcon from '../../assets/svg/garbage.svg';
import walletIcon from '../../assets/svg/wallet.svg';
import warrning from '../../assets/svg/warrning.svg';
import attachInvoice from '../../assets/svg/attach.svg'
import Payson from '../../components/payson';
import Swish from '../swish';
import FormGroup from 'reactstrap/lib/FormGroup';
import Label from 'reactstrap/lib/Label';
import PdfSave from '../../components/pdvSave';
import InfoModal from '../../components/infoModal';
import striptags from 'striptags';

function parseText(response) {
    return response.text().then(text => {
        return {
            result: text,
            status: response.status
        }
    })
}
/**
* Payments page
* @author   Stefan Pantic
*/
class Payments extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            itemsInvoice: [],
            groups: [],
            total: 0,
            totalInvoice: 0,
            loading: true,
            changeButton: false,
            paymentMethod: 0,
            tabIndex: 1,


        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1, alias: localStorage.getItem('groupAlias') })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1, alias: localStorage.getItem('groupAlias'), clinic: this.props.selectedClinic, group: this.props.selectedGroup })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }
    uploadDocument = (e) => {
        let input = e.target;
        if (input.files && input.files[0]) {

            let fsize = input.files[0].size;
            let file = Math.round((fsize / 1024))
            this.setState({
                uploadTimestampDoc: Math.floor(new Date().getTime() / 1000)
            })
            if (file > 5120) {
                this.setState({ error: "File size limit has been reached! (max 5 MB)".translate(this.props.lang) })
            } else {

                this.setState({
                    _documentUploading: true
                })

                let formData = new FormData();
                formData.append('file', input.files[0]);
                fetch(API_ENDPOINT + '/data/upload-document', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,

                    },
                    body: formData
                }).then(parseText).then(({ result, status }) => {
                    if (status != 200) {
                        this.setState({ error: result.translate(this.props.lang) })
                    } else {
                        this.setState({
                            choosenFile: result,
                            _documentUploading: null
                        })
                    }
                })
            }
        }
    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();


        this.interval = setInterval(() => {
            this.get();
        }, 60 * 1000);

    }
    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval)
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }

        if (prevProps.selectedClinic != this.props.selectedClinic) {
            this.get();
        }

    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 },
                    [
                        {
                            name: 'sortField', value: field
                        }
                        ,
                        {
                            name: 'sortType', value: type
                        }
                    ],
                    false));

        }
    }


    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 }, fields, restart));
        }
    }

    /**
    * Call api that generate invoice when referral is paid
    * @author   Stefan Pantic
    * @Objectparam    {String} id                       referral id
    * @Objectparam    {String} transaction              transaction id
    * @Objectparam    {String} alias                    group alias
    * @Objectparam    {String} group                    group id
    * @Objectparam    {String} paymentMethodReference   payment method
    */
    generateInvoice = (refData) => {

        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/generate-invoice/' + refData._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ type: refData.type, transaction: this.state.reference, alias: localStorage.getItem('groupAlias'), group: localStorage.getItem('group'), paymentMethodReference: refData.paymentMethod })
            }).then(res => res.json()).then((result) => {
                this.setState({ loading: false, referral: null, reference: '', paymentMethod: null })
                this.get()
            })
        })

    }

    deletePayment = (data) => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/patient/invoice/delete/' + data, {
                method: 'DELETE',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(res => res.json()).then((result) => {
                this.setState({ loading: false, deletePayment: null })
                this.get()
            })
        })

    }


    deleteUnpaidReferral = (data) => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/unpaid/delete/' + data._id, {
                method: 'DELETE',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(res => res.json()).then((result) => {
                this.setState({ loading: false, deleteUnpaid: null })
                this.get()
            })
        })
    }
    sendInvoice = (item) => {
        let obj = {
            attachedInvoice: this.state.choosenFile,
        }
        this.setState({ loading: true }, () => {
            fetch(API_ENDPOINT + '/referrals/send/invoice/' + item._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(obj)
            }).then(res => res.json()).then((result) => {
                this.setState({ choosenFile: null, attachInvoiceModal: null }, () => {
                    this.get()
                    this.props.verifyUser()
                })

            })
        })
    }

    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 })
        }

        return (
            <div className="dashboard">

                <Container fluid>
                    <Row>
                        <div className='panel'>
                            <h5 className="component-header">{'Payments'.translate(this.props.lang)}</h5>
                            <Col lg="12" className='col-no-padding'>
                                <div className="tabs-buttons-wrap">
                                    <Button className={this.state.tabIndex == 1 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.setState({ tabIndex: 1 })}>{"Unpaid".translate(this.props.lang)}</Button>
                                    <Button className={this.state.tabIndex == 2 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.setState({ tabIndex: 2 })}>{"Paid".translate(this.props.lang)}</Button>
                                </div>
                            </Col>
                            {this.state.tabIndex == 1 ?

                                <Col lg="12" className='col-no-padding'>

                                    <h5 className="component-header">{'Unpaid'.translate(this.props.lang)}</h5>
                                    {
                                        this.state.items.length > 0 ?
                                            <ListBuilder
                                                lang={this.props.lang}
                                                loading={this.state.loading}
                                                total={this.state.total}
                                                showNumeration={false}
                                                fields={[

                                                    { type: 'text', name: 'version', label: 'Case ID'.translate(this.props.lang), allowSort: true },
                                                    { type: 'text', name: 'recpientClinicName', label: 'Receiver'.translate(this.props.lang), allowSort: false },
                                                    { type: 'text', name: 'patient', label: 'Patient'.translate(this.props.lang), allowSort: true },
                                                    { type: 'text', name: 'questionaryName', label: 'Cause'.translate(this.props.lang), allowSort: true },
                                                    { type: 'text', name: 'price', label: 'Price'.translate(this.props.lang), allowSort: true },
                                                    { type: 'text', name: 'methodPayment', label: 'Payment method'.translate(this.props.lang), allowSort: false },
                                                    { type: 'text', name: 'status', label: 'Status'.translate(this.props.lang), allowSort: false },

                                                ]}
                                                rawItems={this.state.items}
                                                items={this.state.items.map((item => {
                                                    return {
                                                        ...item,
                                                        version: item.patient ? item.patient.referralNumber + (item.version != 0 ? '.' + item.version : '') : item.controlNumber ? `${item.controlNumber}` : '',
                                                        recpientClinicName: <>{item.recpientClinicName ? item.recpientClinicName : ''}<br /><span className="clinic-subgroup-name">{item.recpientClinicSubgroupName ? item.recpientClinicSubgroupName : ''} </span><br /> <span className="clinic-subgroup-name">{item.doctorName ? item.doctorName : ''}</span>  </>,
                                                        patient: <>{item.patient ? item.patient.name : item.patientName ? item.patientName : ''}<br /><span className="clinic-subgroup-name">{item.patient ? item.patient.socialSecurityNumber : item.socialSecurityNumber ? item.socialSecurityNumber : ''} </span> </>,
                                                        questionaryName: this.props.lang == 'en' ? item.questionaryName ? item.questionaryName.replace(' (onlinebesök)', ' (online visit)') : item.visitReasonName ? item.visitReasonName : '' : item.questionaryName ? item.questionaryName.replace(' (online visit)', ' (onlinebesök)') : item.visitReasonName ? item.visitReasonName : '',
                                                        price: item.questionaryPrice + " SEK",
                                                        methodPayment: item.type == 'control' && item.paymentMethod ? item.paymentMethod == 1 && Number(item.questionaryPrice) > 0 ? 'On site'.translate(this.props.lang) : item.paymentMethod == 2 && Number(item.questionaryPrice) > 0 ? 'Invoice'.translate(this.props.lang) : item.paymentMethod == 3 && Number(item.questionaryPrice) > 0 ? 'Online'.translate(this.props.lang) : ''
                                                            : item.questionaryPrice == 0 ? 'Free of charge'.translate(this.props.lang) : item.paymentMethod == 1 && Number(item.questionaryPrice) > 0 ? 'On site'.translate(this.props.lang) : item.paymentMethod == 2 && Number(item.questionaryPrice) > 0 ? 'Invoice'.translate(this.props.lang) : item.paymentMethod == 3 && Number(item.questionaryPrice) > 0 ? 'Online'.translate(this.props.lang)
                                                                : item.freePayment == 1 ? 'Free card'.translate(this.props.lang)
                                                                    : item.freePayment == 2 ? 'Under 23'.translate(this.props.lang)
                                                                        : item.freePayment == 3 ? 'Under 20'.translate(this.props.lang)
                                                                            : item.freePayment == 4 ? 'Under 18'.translate(this.props.lang)
                                                                                : item.freePayment == 5 ? 'Older than 85'.translate(this.props.lang)
                                                                                    : item.freePayment == 6 ? 'Under 18 (child)'.translate(this.props.lang)
                                                                                        :
                                                                                        null,
                                                        status: item.paymentMethod ?
                                                            item.paymentMethod == 1 && Number(item.questionaryPrice) > 0 ?
                                                                'Pending payment'.translate(this.props.lang)
                                                                :
                                                                item.paymentMethod == 2 && Number(item.questionaryPrice) > 0 && !item.attachedInvoice ?
                                                                    'Invoice requested'.translate(this.props.lang)
                                                                    :
                                                                    item.paymentMethod == 2 && Number(item.questionaryPrice) > 0 && item.attachedInvoice ?
                                                                        'Pending payment'.translate(this.props.lang)
                                                                        :
                                                                        ''
                                                            :
                                                            null,
                                                    }
                                                }))}
                                                actions={
                                                    [
                                                        {

                                                            component: <div className="tabel-action-tooltip"><Isvg src={walletIcon} className="wallet-icon" /> <div className="chat-icons-tooltip">{'Register payment'.translate(this.props.lang)}</div> </div>,
                                                            onClick: (item) => this.setState({ referral: item }),
                                                            condition: (item) => {
                                                                if (item.doctorPermission) {
                                                                    return false
                                                                } else if ((item.paymentMethod == 1 || item.paymentMethod == 2) && item.status == 'Pending payment'.translate(this.props.lang)) {
                                                                    return true
                                                                }
                                                            }
                                                        },
                                                        {

                                                            component: <div className="tabel-action-tooltip"><Isvg src={attachInvoice} className="wallet-icon" /> <div className="chat-icons-tooltip">{'Attach invoice'.translate(this.props.lang)}</div> </div>,
                                                            onClick: (item) => this.setState({ attachInvoiceModal: item }),
                                                            condition: (item) => {
                                                                if (item.doctorPermission) {
                                                                    return false
                                                                } else if (item.paymentMethod == 2 && item.status == 'Invoice requested'.translate(this.props.lang)) {
                                                                    return true
                                                                }
                                                            }
                                                        },
                                                        {

                                                            component: <div className="tabel-action-tooltip"><Isvg src={garbageIcon} style={{ width: 24, minWidth: 24 }} /><div style={{ width: 180 }} className="chat-icons-tooltip">{'Delete'.translate(this.props.lang)}</div> </div>,
                                                            onClick: (item) => this.setState({ deleteUnpaid: item }),
                                                            condition: (item) => {
                                                                if (item.doctorPermission || item.type == 'control') {
                                                                    return false
                                                                } else {
                                                                    return true
                                                                }
                                                            }
                                                        },

                                                    ]
                                                }
                                                params={params}
                                                sortField={params.sortField}
                                                sortType={params.sortType}
                                                updateSort={this.updateSort}
                                                updateParams={this.updateParams}
                                                updateMultipleParams={this.updateMultipleParams}
                                                hideFilters={true}
                                            ></ListBuilder>
                                            :
                                            <p> {'Do not have unpaid referrals'.translate(this.props.lang)}</p>
                                    }


                                </Col>
                                : null}

                            {
                                this.state.tabIndex == 2 ?
                                    <Col lg="12" className='col-no-padding'>
                                        <h5 className="component-header">{'Paid'.translate(this.props.lang)}</h5>
                                        <ListBuilder
                                            lang={this.props.lang}
                                            loading={this.state.loading}
                                            total={this.state.totalInvoice}
                                            showNumeration={false}
                                            fields={[
                                                { type: 'text', name: 'version', label: 'Case ID'.translate(this.props.lang), allowSort: true },
                                                { type: 'text', name: 'clinicInfo', label: 'Clinic'.translate(this.props.lang), allowSort: false },
                                                { type: 'text', name: 'patientInfo', label: 'Patient'.translate(this.props.lang), allowSort: true },
                                                { type: 'text', name: 'invoiceNumber', label: 'Reference'.translate(this.props.lang), allowSort: true },
                                                { type: 'text', name: 'methodPayment', label: 'Payment method'.translate(this.props.lang), allowSort: true },
                                                // { type: 'text', name: 'reference', label: 'Reference'.translate(this.props.lang), allowSort: true },
                                                { type: 'text', name: 'price', label: 'Price'.translate(this.props.lang), allowSort: true },

                                            ]}
                                            rawItems={this.state.itemsInvoice}
                                            items={this.state.itemsInvoice.map((item => {
                                                return {
                                                    ...item,
                                                    version: item.version,
                                                    price: item.price + " SEK",
                                                    patientInfo: <>{item.patient}<br /><span className="clinic-subgroup-name">{item.patientAddress} </span> </>,
                                                    clinicInfo: <>{item.clinic}<br /><span className="clinic-subgroup-name">{item.clinicAddress} </span> <br /> <span className="clinic-subgroup-name">{item.doctorName ? item.doctorName : ''}</span></>,

                                                    methodPayment: item.price == 0 && (item.freePayment < 1 || item.freePayment > 6) ? 'Free of charge'.translate(this.props.lang) : item.paymentMethod == 1 ? 'On site'.translate(this.props.lang) : item.paymentMethod == 2 ? 'Invoice'.translate(this.props.lang) : item.paymentMethod == 3 ? item.online == 'swish' ? 'Online - Swish'.translate(this.props.lang) : item.online == 'payson' ? 'Online - Card'.translate(this.props.lang) : 'Online'.translate(this.props.lang)
                                                        : item.freePayment == 1 ? 'Free of charge'.translate(this.props.lang) + " - " + 'Free card'.translate(this.props.lang)
                                                            : item.freePayment == 2 ? 'Free of charge'.translate(this.props.lang) + " - " + 'Under 23'.translate(this.props.lang)
                                                                : item.freePayment == 3 ? 'Free of charge'.translate(this.props.lang) + " - " + 'Under 20'.translate(this.props.lang)
                                                                    : item.freePayment == 4 ? 'Free of charge'.translate(this.props.lang) + " - " + 'Under 18'.translate(this.props.lang)
                                                                        : item.freePayment == 5 ? 'Free of charge'.translate(this.props.lang) + " - " + 'Older than 85'.translate(this.props.lang)
                                                                            : item.freePayment == 6 ? 'Free of charge'.translate(this.props.lang) + " - " + 'Under 18 (child)'.translate(this.props.lang)
                                                                                :
                                                                                null


                                                }
                                            }))}
                                            actions={
                                                [
                                                    {
                                                        component: <div className="tabel-action-tooltip"><Isvg src={viewIcon} style={{ width: 24, minWidth: 24 }} /><div style={{ width: 180 }} className="chat-icons-tooltip">{'Preview'.translate(this.props.lang)}</div> </div>,
                                                        onClick: (item) => this.setState({ invoice: item }),

                                                    },
                                                    {
                                                        component: <div className="tabel-action-tooltip"><Isvg src={garbageIcon} style={{ width: 24, minWidth: 24 }} /><div style={{ width: 180 }} className="chat-icons-tooltip">{'Delete'.translate(this.props.lang)}</div> </div>,
                                                        onClick: (item) => this.setState({ deletePayment: item }),
                                                        condition: (item) => {
                                                            if (item.doctorPermission) {
                                                                return false
                                                            } else {
                                                                return true
                                                            }
                                                        }
                                                    },

                                                ]
                                            }

                                            params={params}
                                            sortField={params.sortField}
                                            sortType={params.sortType}
                                            updateSort={this.updateSort}
                                            updateParams={this.updateParams}
                                            updateMultipleParams={this.updateMultipleParams}
                                            hideFilters={true}

                                        ></ListBuilder>

                                    </Col>
                                    :
                                    null
                            }
                        </div>
                    </Row>


                </Container>

                {this.state.invoice ?
                    <Modal isOpen={this.state.invoice} centered size='lg' className="modal-footer-modifed">
                        <ModalHeader toggle={() => this.setState({ invoice: !this.state.invoice })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ invoice: !this.state.invoice })}><Isvg src={xIcon} /></button>}>{'InvoiceKey'.translate(this.props.lang)}</ModalHeader>
                        <ModalBody>
                            <div dangerouslySetInnerHTML={{ __html: this.props.lang == 'en' && this.state.invoice.invoiceContentEn ? this.state.invoice.invoiceContentEn : this.state.invoice.invoiceContent }} onChange={() => { }}></div>
                            {/* <PrintHelper lang={this.props.lang} html={this.state.invoice.invoiceContent} /> */}
                        </ModalBody>
                        <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }}>
                            <PdfSave lang={this.props.lang} html={(this.props.lang == 'en' && this.state.invoice.invoiceContentEn ? this.state.invoice.invoiceContentEn : this.state.invoice.invoiceContent).replace(/č/g, 'c').replace(/Č/g, 'C').replace(/ć/g, 'c').replace(/Ć/g, 'C')} />
                            <Button color="primary" onClick={() => this.setState({ invoice: null })}> {'Close'.translate(this.props.lang)} </Button>


                        </ModalFooter>
                    </Modal>
                    : null}

                {this.state.referral ?
                    <Modal isOpen={this.state.referral} centered toggle={() => this.setState({ referral: !this.state.referral })}>
                        <ModalHeader toggle={() => this.setState({ referral: !this.state.referral, paymentMethod: null, reference: '' })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ referral: !this.state.referral, paymentMethod: null, reference: '' })}><Isvg src={xIcon} /></button>}>{'Payment'.translate(this.props.lang)}</ModalHeader>

                        <ModalBody>

                            {/* <FormGroup>
                                <Label>{'Payment method'.translate(this.props.lang)}</Label>
                                <Input type='select'
                                    onChange={(e) => this.setState({ paymentMethod: e.target.value })}>

                                    <option value={0}>{'None'.translate(this.props.lang)}</option>
                                    <option value={1}>{'On site'.translate(this.props.lang)}</option>
                                    <option value={2}>{'Invoice'.translate(this.props.lang)}</option>

                                </Input>
                            </FormGroup> */}
                            <FormGroup>
                                <Label>{'Payment method'.translate(this.props.lang)}</Label>
                                <Input type='text'
                                    value={this.state.referral.paymentMethod == 1 ? 'On site'.translate(this.props.lang) : this.state.referral.paymentMethod == 2 ? 'Invoice'.translate(this.props.lang) : this.state.referral.paymentMethod == 3 ? 'Online'.translate(this.props.lang) : ''}>



                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Label>{"Reference".translate(this.props.lang)}</Label>
                                <Input type='text'
                                    onChange={(e) => {

                                        this.setState({
                                            reference: striptags(e.target.value)
                                        })
                                    }}
                                    value={this.state.reference}
                                />
                            </FormGroup>

                        </ModalBody>
                        <ModalFooter className='buttons-right-blue'>
                            <Button color='primary' disabled={this.state.referral.paymentMethod != 0 ? false : true} onClick={() => this.generateInvoice(this.state.referral)}>{'Save'.translate(this.props.lang)}</Button>
                        </ModalFooter>


                    </Modal>

                    : null}
                <input ref={(node) => this.documentInput = node} type="file" accept=".pdf" key={this.state.uploadTimestampDoc} onChange={this.uploadDocument} style={{ pointerEvents: 'none', opacity: 0 }} />

                {
                    this.state.attachInvoiceModal ?
                        <Modal isOpen={this.state.attachInvoiceModal} centered toggle={() => this.setState({ attachInvoiceModal: !this.state.attachInvoiceModal, choosenFile: null })}>
                            <ModalHeader toggle={() => this.setState({ attachInvoiceModal: !this.state.attachInvoiceModal, choosenFile: null })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ attachInvoiceModal: !this.state.attachInvoiceModal, choosenFile: null })}><Isvg src={xIcon} /></button>}>{'Attach invoice'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody style={{ width: '100%' }}>

                                <button className={this.state._documentUploading ? 'attach-invoice uploading' : this.state.choosenFile ? 'attach-invoice uploaded' : "attach-invoice"} onClick={() => {
                                    if (this.documentInput) this.documentInput.click()
                                }}>
                                    <div className="button-menu-icon-wrap">
                                        <Isvg src={attachInvoice} />
                                    </div>
                                    <div className='chat-icons-text'>
                                        {
                                            this.state.choosenFile ?
                                                'File uploaded'.translate(this.props.lang)
                                                :
                                                'Attach file'.translate(this.props.lang)
                                        }
                                    </div>
                                </button>


                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button color='primary' disabled={!this.state.choosenFile} onClick={() => this.sendInvoice(this.state.attachInvoiceModal)}>{'Send'.translate(this.props.lang)}</Button>
                                <Button color='primary' onClick={() => this.setState({ attachInvoiceModal: null, choosenFile: null })}>{'Close'.translate(this.props.lang)}</Button>

                            </ModalFooter>


                        </Modal>
                        :
                        null
                }

                {this.state.paysonModal ?

                    <Payson
                        openModal={(item) => this.setState({
                            paysonModal: item
                        })}
                        referral={this.state.paysonModal._id}
                        data={this.state.paysonModal}
                        price={this.state.paysonModal.questionaryPrice}
                    />

                    : null}
                {this.state.swishModal ?

                    <Swish
                        open={this.state.swishModal}
                        openModal={(item) => this.setState({
                            swishModal: item
                        })}
                    />

                    : null}


                {this.state.error ?

                    <ErrorModal lang={this.props.lang}
                        isOpen={this.state.error}
                        toggle={() => this.setState({ error: null })}

                    >
                        {this.state.error.translate(this.props.lang)}
                    </ErrorModal>

                    : null}

                {this.state.deletePayment ?
                    <Modal isOpen={this.state.deletePayment} centered>
                        <ModalHeader style={{ margin: 'auto' }}>{'Warning'.translate(this.props.lang)}</ModalHeader>
                        <ModalBody className="delete-modal">
                            <img src={warrning} />
                            <p className="message">{`Are you sure you want to delete the payment?`.translate(this.props.lang)}</p>
                        </ModalBody>
                        <ModalFooter className='buttons-right-blue'>
                            <Button color="primary" style={{ margin: 10 }} onClick={() => this.deletePayment(this.state.deletePayment._id)}>{'Yes'.translate(this.props.lang)}</Button>
                            <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ deletePayment: false })}>{'No'.translate(this.props.lang)}</Button>

                        </ModalFooter>
                    </Modal>
                    : null}

                {this.state.deleteUnpaid ?
                    <InfoModal
                        isOpen={this.state.deleteUnpaid}
                        onClose={(item) => {
                            this.setState({
                                deleteUnpaid: item
                            })
                        }}
                        toggle={() => this.setState({ deleteUnpaid: !this.state.deleteUnpaid })}
                        header={'Warning'.translate(this.props.lang)}
                        info={'Are you sure you want to delete this referral!'.translate(this.props.lang)}
                        buttons={[

                            <Button color='primary' onClick={() => {
                                this.deleteUnpaidReferral(this.state.deleteUnpaid);

                            }}>{'Yes'.translate(this.props.lang)}</Button>,

                            <Button color='primary' onClick={() => {
                                this.setState({
                                    deleteUnpaid: false
                                })

                            }}>{'No'.translate(this.props.lang)}</Button>


                        ]}
                    />

                    :

                    null}

            </div >
        );
    }
}

const selector = formValueSelector('modalForm');

export default connect(state => {
    return { group: selector(state, 'group') }

}, {
    changeSubgroup: value => change("modalForm", "subgroup", value),

})(Page(Payments));