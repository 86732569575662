import React, { Component } from 'react';
import ListBuilder from './listBuilder';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../helpers/linkHelper';
import { API_ENDPOINT} from '../constants';
import moment from 'moment';
import Isvg from 'react-inlinesvg';
import attachIcon from '../assets/svg/attach.svg';
import viewIcon from '../assets/svg/eye.svg';
import unpinIcon from '../assets/svg/unpin.svg';
import xIcon from '../assets/svg/x.svg';
import downloadIcon from '../assets/svg/download.svg'
import walletIcon from '../assets/svg/wallet.svg'
import denyIcon from '../assets/svg/deny_icon.svg';
import info from '../assets/svg/info.svg'
import userIcon from '../assets/svg/l-user.svg';
import billingIcon from '../assets/svg/l-card.svg';

import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Button, Table, Input, Label, FormGroup

} from 'reactstrap';

import DatePicker from '../components/forms/fields/datePicker';


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}




const loadDataWithQuery = [
    (fetchFunction, lang, match, query) => {

        let queryFields = {};
        for (var key in query) {
            if (key && key.indexOf('query-') === 0) {
                queryFields[key.replace('query-', '')] = query[key];
            }
        }

        return fetchFunction(API_ENDPOINT + '/data/invoice/active-orders', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                page: query.page,
                entries: query.entries,
                filter: query.filter,
                sortType: query.sortType,
                sortField: query.sortField,
                group: query.group,
                query: queryFields,
                request: true
            })
        }).then(parseJSON).then(({ result, status }) => {
            if (status >= 200 && status < 300)
                return {
                    page: query.page ? query.page : 0,
                    items: result.items,
                    total: result.total,
                    resultFilter: result.filter
                }
        })


    },

]

/**
* List of patients for selected clinic and selected department
* @author   Milan Stanojevic
*/
class DeletionRequestsComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            regions: [],
            cities: [],
            groups: [],
            subgroups: [],
            ...props.initialData,
            useState: true,
            params: {
                entries: 10,
                page: 0,
            },
            items: [],
            total: 0,
            loading: true,
            suggestions: [],

        };
    }


    get = () => {
        let params = this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic });
        params.group = this.props.selectedGroup;
        params.clinic = this.props.selectedClinic;
        params._ts = new Date().getTime();

        this.setState({
            _ts: params._ts
        }, () => {
            for (let i = 0; i < loadDataWithQuery.length; i++) {
                loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, params).then((data) => {

                    this.setState({
                        ...data,
                        loading: null
                    })
                })
            }

        })


    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();




    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })

        }

        if (this.state.useState && prevState._useStateTs != this.state._useStateTs) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })

        }
        if (prevProps.selectedGroup != this.props.selectedGroup) {
            this.get();
        }
        if (prevProps.reloadList != this.props.reloadList) {
            this.get();
        }



    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field == 'waitingTime' ? 'waitingMin' : field;
            state.sortType = type;
            this.setState({
                params: state
            })

            this.setState({
                _useStateTs: new Date().getTime()
            })

        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field == 'waitingTime' ? 'waitingMin' : field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };

                obj[name] = value;

                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;


                this.setState({ params: obj })
            }

            this.setState({
                _useStateTs: new Date().getTime()
            })
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }

    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {

                    obj[fields[i].name] = fields[i].value;

                }
                this.setState({ params: obj })
            }

            this.setState({
                _useStateTs: new Date().getTime()
            })

        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }

    attachInvoice = (item) => {

        if (item && item._id) {
            this.setState({ loadingPage: true }, () => {
                fetch(API_ENDPOINT + "/fortnox/invoices/create-new", {
                    method: "POST",
                    headers: {
                        Authorization:
                            typeof localStorage !== "undefined"
                                ? `Bearer ${localStorage.getItem("authToken")}`
                                : null,
                        "content-type": "application/json"

                    },
                    body: JSON.stringify({ id: item._id })
                }).then(parseJSON).then(({ result, status }) => {
                    if (!result.error) {
                        this.setState({ loadingPage: false, successMessage: 'Invoice successfully attached'.translate(this.props.lang), attachInvoice: null })
                        this.get()
                    } else {
                        this.setState({ loadingPage: false, error: result.error ? result.error.translate(this.props.lang) : 'Error'.translate(this.props.lang), attachInvoice: null })
                    }


                });
            })
        }


    }

    denyInvoice = (data) => {
        console.log(data);
        fetch(API_ENDPOINT + "/update-deny-bill", {
            method: "POST",
            headers: {
                Authorization:
                    typeof localStorage !== "undefined"
                        ? `Bearer ${localStorage.getItem("authToken")}`
                        : null,
                "content-type": "application/json"

            },
            body: JSON.stringify({ id: data._id, notes: this.state.denyReason ? this.state.denyReason : '' })
        }).then(parseJSON).then(({ result, status }) => {
            if (result) {
                this.setState({
                    denyReason: '',
                    markAsPaidModal: null,
                    accountDetailsModal: null,
                    denyInvoice: null
                }, () => { this.get() })

            }

        });
    }

    // denyInvoice = (item) => {
    //     if (item && item._id && item.invoiceDetails && item.invoiceDetails.activeDocumentNumber) {
    //         this.setState({ loadingPage: true }, () => {
    //             fetch(API_ENDPOINT + "/fortnox/invoices/delete", {
    //                 method: "POST",
    //                 headers: {
    //                     Authorization:
    //                         typeof localStorage !== "undefined"
    //                             ? `Bearer ${localStorage.getItem("authToken")}`
    //                             : null,
    //                     "content-type": "application/json"

    //                 },
    //                 body: JSON.stringify({ billId: item._id, documentNumber: item.invoiceDetails.activeDocumentNumber, reason: this.state.denyReason ? this.state.denyReason : '' })
    //             }).then(parseJSON).then(({ result, status }) => {
    //                 if (!result.error) {
    //                     this.setState({ loadingPage: false, successMessage: 'Invoice successfully denied'.translate(this.props.lang), denyInvoice: null, denyReason: '' })
    //                     this.get()
    //                 } else {
    //                     this.setState({ loadingPage: false, error: 'Error'.translate(this.props.lang), denyInvoice: null, denyReason: '' })
    //                 }


    //             });
    //         })
    //     }
    // }

    bookkeepingInvoice = (item) => {
        if (item && item._id && item.invoiceDetails && item.invoiceDetails.activeDocumentNumber) {
            this.setState({ loadingPage: true }, () => {
                fetch(API_ENDPOINT + "/fortnox/invoices/bookkeeping", {
                    method: "POST",
                    headers: {
                        Authorization:
                            typeof localStorage !== "undefined"
                                ? `Bearer ${localStorage.getItem("authToken")}`
                                : null,
                        "content-type": "application/json"

                    },
                    body: JSON.stringify({ billId: item._id, documentNumber: item.invoiceDetails.activeDocumentNumber })
                }).then(parseJSON).then(({ result, status }) => {
                    if (!result.error) {
                        this.setState({ loadingPage: false, successMessage: 'Invoice successfully registered'.translate(this.props.lang), bookkeepingInvoice: null })
                        this.get()
                    } else {
                        this.setState({ loadingPage: false, error: 'Error'.translate(this.props.lang), bookkeepingInvoice: null })
                    }


                });
            })
        }
    }
    doneInvoice = (item) => {
        if (item && item._id && item.invoiceDetails && item.invoiceDetails.activeDocumentNumber) {
            this.setState({ loadingPage: true }, () => {
                fetch(API_ENDPOINT + "/fortnox/invoices/done", {
                    method: "POST",
                    headers: {
                        Authorization:
                            typeof localStorage !== "undefined"
                                ? `Bearer ${localStorage.getItem("authToken")}`
                                : null,
                        "content-type": "application/json"

                    },
                    body: JSON.stringify({ billId: item._id, documentNumber: item.invoiceDetails.activeDocumentNumber })
                }).then(parseJSON).then(({ result, status }) => {
                    if (!result.error) {
                        this.setState({ loadingPage: false, successMessage: 'Invoice successfully done'.translate(this.props.lang), setDoneInvoice: null })
                        this.get()
                    } else {
                        this.setState({ loadingPage: false, error: 'Error'.translate(this.props.lang), setDoneInvoice: null })
                    }


                });
            })
        }
    }

    markAsPaid = (data) => {
        if (data && !data.paidDate) {
            data.paidDate = moment(new Date()).format("YYYY-MM-DD")
        }

        fetch(API_ENDPOINT + "/update-invoice-bill", {
            method: "POST",
            headers: {
                Authorization:
                    typeof localStorage !== "undefined"
                        ? `Bearer ${localStorage.getItem("authToken")}`
                        : null,
                "content-type": "application/json"

            },
            body: JSON.stringify(data)
        }).then(parseJSON).then(({ result, status }) => {
            if (result) {

                if (result && result.bill && result.bill.invoiceDetails && result.bill.invoiceDetails.activeDocumentNumber && result.bill._id) {
                    this.markAsPaidOnFortnox(result.bill._id, result.bill.invoiceDetails.activeDocumentNumber)
                }


                this.setState({
                    markAsPaidModal: null
                }, () => {
                    this.props.changeTab()
                })

            }

        });
    }

    markAsPaidOnFortnox = (billId, documentNumber) => {

        if (billId, documentNumber) {
            fetch(API_ENDPOINT + "/fortnox/invoices/bookkeeping", {
                method: "POST",
                headers: {
                    Authorization:
                        typeof localStorage !== "undefined"
                            ? `Bearer ${localStorage.getItem("authToken")}`
                            : null,
                    "content-type": "application/json"

                },
                body: JSON.stringify({ billId: billId, documentNumber: documentNumber })
            }).then(parseJSON).then(({ result, status }) => {
                
            });
        }

    }


    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }
        return (
            <div className='delete-request-list'>
                <ListBuilder
                    lang={this.props.lang}
                    loading={this.state.loading}
                    total={this.state.total}
                    showNumeration={false}
                    fields={[
                        { type: 'text', name: 'companyName', label: 'Company'.translate(this.props.lang), allowSort: true },
                        { type: 'text', name: 'vatOrgNumber', label: 'Vat/Org.nr'.translate(this.props.lang), allowSort: true },
                        { type: 'text', name: 'dueDate', label: 'Due date'.translate(this.props.lang), allowSort: true },
                        { type: 'text', name: 'invoiceSent', label: 'Invoice sent'.translate(this.props.lang), allowSort: true },
                        { type: 'text', name: 'experienceDate', label: 'Renewal date'.translate(this.props.lang), allowSort: true },
                        { type: 'text', name: 'statusInvoice', label: 'Status'.translate(this.props.lang), allowSort: true },
                    ]}
                    items={this.state.items.map(item => {
                        let company
                        let vatOrgNumber
                        if (item && item.billingDetails && item.billingDetails.accountDetails && item.billingDetails.accountDetails.sameAsAccountDetails && item.billingDetails.accountDetails.organizationName && (item.billingDetails.billingDetailsOrgNumber || item.billingDetails.billingDetailsVatNumber)) {
                            company = item.billingDetails.accountDetails.organizationName
                            vatOrgNumber = item.billingDetails.billingDetailsOrgNumber ? item.billingDetails.billingDetailsOrgNumber : item.billingDetails.billingDetailsVatNumber
                        } else if (item && item.billingDetails && item.billingDetails.accountDetailsBilling && !item.billingDetails.accountDetails.sameAsAccountDetails && item.billingDetails.accountDetailsBilling.organizationName && item.billingDetails.accountDetailsBilling.country) {
                            company = item.billingDetails.accountDetailsBilling.organizationName
                            vatOrgNumber = item.billingDetails.billingDetailsOrgNumber ? item.billingDetails.billingDetailsOrgNumber : item.billingDetails.billingDetailsVatNumber
                        }

                        let attachmentLinkDate = Math.floor(new Date(item.experienceDate).getTime() / 1000)
                        let nowTs = Math.floor(new Date().getTime() / 1000)
                        let attachmentLink = false
                        let overDue = false
                        if ((nowTs + 10 * 24 * 60 * 60) > attachmentLinkDate) {
                            attachmentLink = true
                        } else if ((item.tsCreated + 10 * 24 * 60 * 60) < nowTs) {
                            overDue = true
                        }

                        return {
                            ...item,
                            companyName: company,
                            vatOrgNumber: vatOrgNumber,
                            startDate: <span>{moment.unix(item.tsCreated).format("YYYY-MM-DD")}</span>,
                            dueDate: <span>{moment.unix(item.tsCreated + 24*60*60*10).format("YYYY-MM-DD")}</span>,
                            invoiceSent: <span>{item.invoiceDetails && item.invoiceDetails.active && item.invoiceDetails.activeDocumentNumber && item.invoiceDetails.invoices && item.invoiceDetails.invoices.filter(el => el.documentNumber === item.invoiceDetails.activeDocumentNumber).length && item.invoiceDetails.invoices.filter(el => el.documentNumber === item.invoiceDetails.activeDocumentNumber)[0].attachedInvoiceTs ? moment.unix(item.invoiceDetails.invoices.filter(el => el.documentNumber === item.invoiceDetails.activeDocumentNumber)[0].attachedInvoiceTs).format("YYYY-MM-DD") : null}</span>,
                            statusInvoice: <div onClick={() => {
                                if (attachmentLink) {
                                    this.setState({ attachmentLink: item })
                                }
                            }}>{attachmentLink ? <span style={{ color: 'blue' }}>{'Attach invoice'.translate(this.props.lang)}</span> : overDue ? <span style={{ color: 'red' }}>{'Overdue'.translate(this.props.lang)}</span> : <span style={{ fontWeight: '600' }}>{'Unpaid'.translate(this.props.lang)}</span>}</div>
                        }
                    })}
                    rawItems={this.state.items}
                    actions={
                        [
                            {
                                component: <div className="tabel-action-tooltip"><Isvg src={viewIcon} style={{ width: 24, minWidth: 24 }} /><div style={{ width: 180 }} className="chat-icons-tooltip">{'Preview'.translate(this.props.lang)}</div> </div>,
                                onClick: (item) => this.setState({ accountDetailsModal: item }),

                            },
                            {
                                component: <div className="tabel-action-tooltip"><Isvg src={downloadIcon} style={{ width: 24, minWidth: 24 }} /><div style={{ width: 180 }} className="chat-icons-tooltip">{'Download'.translate(this.props.lang)}</div> </div>,
                                onClick: (item) => {
                                    if (item.invoiceDetails && item.invoiceDetails.active && item.invoiceDetails.activeDocumentNumber && item.invoiceDetails.invoices && item.invoiceDetails.invoices.filter(el => el.documentNumber === item.invoiceDetails.activeDocumentNumber).length && item.invoiceDetails.invoices.filter(el => el.documentNumber === item.invoiceDetails.activeDocumentNumber)[0].pdf) {
                                        let link = document.createElement("a");
                                        link.download = `Invoice_${item.invoiceDetails.invoices.filter(el => el.documentNumber === item.invoiceDetails.activeDocumentNumber)[0].documentNumber}.pdf`;
                                        link.href = `${API_ENDPOINT}${item.invoiceDetails.invoices.filter(el => el.documentNumber === item.invoiceDetails.activeDocumentNumber)[0].pdf}`;
                                        link.target = '_blank';
                                        document.body.appendChild(link);
                                        link.click();
                                        document.body.removeChild(link);
                                    }
                                },
                                condition: (item) => {
                                    if ((item.status && item.status == 'Unpaid') || overDue) {
                                        return true
                                    } else {
                                        return false
                                    }
                                }

                            },
                            {
                                component: <div className="tabel-action-tooltip"><Isvg src={walletIcon} style={{ width: 24, minWidth: 24 }} /><div style={{ width: 180 }} className="chat-icons-tooltip">{'Mark as paid'.translate(this.props.lang)}</div> </div>,
                                onClick: (item) => this.setState({ markAsPaidModal: item }),
                                condition: (item) => {
                                    if ((item.status && item.status == 'Unpaid') || overDue) {
                                        return true
                                    } else {
                                        return false
                                    }
                                }

                            },
                        ]
                    }
                    params={params}
                    sortField={params.sortField}
                    sortType={params.sortType}
                    updateSort={this.updateSort}
                    updateParams={this.updateParams}
                    updateMultipleParams={this.updateMultipleParams}
                ></ListBuilder>

                {this.state.accountDetailsModal ?
                    <Modal isOpen={this.state.accountDetailsModal} centered size='lg' className="modal-footer-modifed">
                        <ModalHeader toggle={() => this.setState({ accountDetailsModal: !this.state.accountDetailsModal })} style={{ paddingLeft: 30 }} close={
                            <button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ accountDetailsModal: !this.state.accountDetailsModal })}>
                                <Isvg src={xIcon} />
                            </button>}
                        >
                            {'Preview'.translate(this.props.lang)}
                        </ModalHeader>
                        <ModalBody className='active-order-tables'>


                            {/* <div >{this.state.accountDetailsModal}</div> */}
                            <h6 className='preview-heading' style={{ marginTop: 10 }}>
                                <Isvg src={userIcon} />
                                {'Account details'.translate(this.props.lang)}
                            </h6>
                            <Row className='preview-details-modal'>
                                <Col lg="6">
                                    <div className='info-group'>
                                        <label>{'First and last name'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetails.name}</h6>
                                    </div>
                                    <div className='info-group'>
                                        <label>{'Email'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetails.email}</h6>
                                    </div>
                                    <div className='info-group'>
                                        <label>{'Phone number'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetails.phone}</h6>
                                    </div>
                                    <div className='info-group'>
                                        <label>{'Organization name'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetails.organizationName}</h6>
                                    </div>
                                    <div className='info-group'>
                                        <label>{'Number of employees'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetails.numberOfEmployees ? this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetails.numberOfEmployees : null}</h6>
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className='info-group'>
                                        <label>{'Address'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetails.street}</h6>
                                    </div>
                                    <div className='info-group'>
                                        <label>{'City'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetails.city}</h6>
                                    </div>
                                    <div className='info-group'>
                                        <label>{'State'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetails.state ? this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetails.state : null}</h6>
                                    </div>
                                    <div className='info-group'>
                                        <label>{'Zip/Postal code'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetails.zip}</h6>
                                    </div>
                                    <div className='info-group'>
                                        <label>{'Country'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetails.country}</h6>
                                    </div>
                                </Col>
                            </Row>
                            <hr />
                    
                            {!this.state.accountDetailsModal.billingDetails.accountDetails.sameAsAccountDetails ?
                                <>
                                    <h6 className='preview-heading'>
                                        <Isvg src={billingIcon} />
                                        {'Billing details'.translate(this.props.lang)}
                                    </h6>
                                    <Row className='preview-details-modal'>
                                        <Col lg="6">
                                            <div className='info-group'>
                                                <label>{'First and last name'.translate(this.props.lang)}</label>
                                                <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetailsBilling.name}</h6>
                                            </div>
                                            <div className='info-group'>
                                                <label>{'Email'.translate(this.props.lang)}</label>
                                                <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetailsBilling.email}</h6>
                                            </div>
                                            <div className='info-group'>
                                                <label>{'Phone number'.translate(this.props.lang)}</label>
                                                <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetailsBilling.phone}</h6>
                                            </div>
                                            <div className='info-group'>
                                                <label>{'Organization name'.translate(this.props.lang)}</label>
                                                <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetailsBilling.organizationName}</h6>
                                            </div>
                                            <div className='info-group'>
                                                <label>{'Number of employees'.translate(this.props.lang)}</label>
                                                <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetailsBilling.numberOfEmployees}</h6>
                                            </div>

                                        </Col>
                                        <Col lg="6">
                                            <div className='info-group'>
                                                <label>{'Address'.translate(this.props.lang)}</label>
                                                <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetailsBilling.street}</h6>
                                            </div>
                                            <div className='info-group'>
                                                <label>{'City'.translate(this.props.lang)}</label>
                                                <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetailsBilling.city}</h6>
                                            </div>
                                            <div className='info-group'>
                                                <label>{'State'.translate(this.props.lang)}</label>
                                                <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetailsBilling.state}</h6>
                                            </div>
                                            <div className='info-group'>
                                                <label>{'Zip/Postal code'.translate(this.props.lang)}</label>
                                                <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetailsBilling.zip}</h6>
                                            </div>
                                            <div className='info-group'>
                                                <label>{'Country'.translate(this.props.lang)}</label>
                                                <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.accountDetails && this.state.accountDetailsModal.billingDetails.accountDetailsBilling.country}</h6>
                                            </div>


                                        </Col>
                                    </Row>
                                </> : null}

                            {this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.billingDetailsVatNumber || this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.billingDetailsOrgNumber ?
                                <>
                                    {this.state.accountDetailsModal.billingDetails.accountDetails.sameAsAccountDetails ?
                                        <h6 className='preview-heading'>
                                            <Isvg src={billingIcon} />
                                            {'Billing details'.translate(this.props.lang)}
                                        </h6>
                                        : null}
                                    <Row className='preview-details-modal' style={{ paddingTop: 0, marginTop: 0 }}>
                                        {this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.billingDetailsVatNumber ?
                                            <Col lg="6">

                                                <div className='info-group' style={!this.state.accountDetailsModal.billingDetails.accountDetails.sameAsAccountDetails ? { marginTop: 0, paddingTop: 0 } : null}>
                                                    <label>{'VAT ID Number'.translate(this.props.lang)}</label>
                                                    <h6>{this.state.accountDetailsModal.billingDetails.billingDetailsVatNumber}</h6>
                                                </div>

                                            </Col> : null}
                                        {this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.billingDetailsOrgNumber ?
                                            <Col lg="6">

                                                <div className='info-group' style={!this.state.accountDetailsModal.billingDetails.accountDetails.sameAsAccountDetails ? { marginTop: 0, paddingTop: 0 } : null}>
                                                    <label>{'Organisation ID'.translate(this.props.lang)}</label>
                                                    <h6>{this.state.accountDetailsModal.billingDetails.billingDetailsOrgNumber}</h6>
                                                </div>

                                            </Col> : null}
                                    </Row>
                                    <hr />
                                </>
                                :
                                null}



                            {/* <div className='table-left-space table-half'>
                                <Table>
                                    <thead>
                                        <tr>
                                            {this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.billingDetailsVatNumber ?
                                                <th>{'VAT ID Number'.translate(this.props.lang)}</th>
                                                : null
                                            }
                                            {this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.billingDetailsOrgNumber ?
                                                <th>{'Organisation ID'.translate(this.props.lang)}</th>
                                                : null
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            {this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.billingDetailsVatNumber ?
                                                <td> {this.state.accountDetailsModal.billingDetails.billingDetailsVatNumber}</td>
                                                : null
                                            }
                                            {this.state.accountDetailsModal && this.state.accountDetailsModal.billingDetails && this.state.accountDetailsModal.billingDetails.billingDetailsOrgNumber ?
                                                <td>{this.state.accountDetailsModal.billingDetails.billingDetailsOrgNumber}</td>
                                                : null}
                                        </tr>
                                    </tbody>
                                </Table>
                            </div> */}

                            {/* <h6 className='preview-heading'>
                                <Isvg src={billingIcon} />
                                {'Licence details'.translate(this.props.lang)}
                            </h6>
                            <div className='table-left-space'>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>{'Licence'.translate(this.props.lang)}</th>
                                            <th>{'Licence duration'.translate(this.props.lang)}</th>
                                            <th>{'Licence price'.translate(this.props.lang)}</th>
                                            <th>{'Number Of licences'.translate(this.props.lang)}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td> {this.state.accountDetailsModal && this.state.accountDetailsModal.licence && this.props.lang == 'en' ? this.state.accountDetailsModal.licence : this.state.accountDetailsModal.licenceSv}</td>
                                            <td> {this.state.accountDetailsModal && this.state.accountDetailsModal.licenceDuration}</td>
                                            <td> {this.state.accountDetailsModal && this.state.accountDetailsModal.licencePrice ? Number(this.state.accountDetailsModal.licencePrice) + 'SEK' : 0}</td>
                                            <td> {this.state.accountDetailsModal && this.state.accountDetailsModal.numberOfLicences ? Number(this.state.accountDetailsModal.numberOfLicences) : 1}</td>

                                        </tr>
                                    </tbody>
                                </Table>
                            </div> */}

                            <h6 className='preview-heading'>
                                <Isvg src={billingIcon} />
                                {'Licence details'.translate(this.props.lang)}
                            </h6>
                            <Row className='preview-details-modal'>
                                <Col lg="6">
                                    <div className='info-group'>
                                        <label>{'Licence'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.licence && this.props.lang == 'en' ? this.state.accountDetailsModal.licence : this.state.accountDetailsModal.licenceSv}</h6>
                                    </div>
                                    <div className='info-group'>
                                        <label>{'Licence price'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.licencePrice ? (Number(this.state.accountDetailsModal.licencePrice)).toFixed(2) + ' ' + 'SEK' : 0}</h6>
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className='info-group'>
                                        <label>{'Licence duration'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.licenceDuration + ' ' + 'month(s)'.translate(this.props.lang)}</h6>
                                    </div>
                                    <div className='info-group'>
                                        <label>{'Number of licences'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.numberOfLicences ? Number(this.state.accountDetailsModal.numberOfLicences) : 1}</h6>
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className='info-group'>
                                        <label>{'Renewel date'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.experienceDate}</h6>
                                    </div>
                                    {/* <div className='info-group'>
                                        <label>{'Number of licences'.translate(this.props.lang)}</label>
                                        <h6>{this.state.accountDetailsModal && this.state.accountDetailsModal.numberOfLicences ? Number(this.state.accountDetailsModal.numberOfLicences) : 1}</h6>
                                    </div> */}
                                </Col>
                            </Row>

                        </ModalBody>
                        <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }}>
                            <div className='button-separated'>
                                {/* <Button color="primary" onClick={() => this.setState({ attachInvoice: this.state.accountDetailsModal, attachInvoice: null })}> {'Attach invoice'.translate(this.props.lang)} </Button> */}
                                <Button color="primary" onClick={() => this.setState({ denyInvoice: this.state.accountDetailsModal })}> {'Deny request'.translate(this.props.lang)} </Button>
                            </div>



                        </ModalFooter>
                    </Modal>
                    : null}


                {this.state.markAsPaidModal ?
                    <Modal isOpen={this.state.markAsPaidModal} centered size='lg' className="modal-footer-modifed">
                        <ModalHeader toggle={() => this.setState({ markAsPaidModal: !this.state.markAsPaidModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ markAsPaidModal: !this.state.markAsPaidModal })}><Isvg src={xIcon} /></button>}>{'Mark as paid'.translate(this.props.lang)}</ModalHeader>
                        <ModalBody>
                            <DatePicker
                                label={'Date'.translate(this.props.lang)}
                                value={this.state.markAsPaidModal.paidDate ? this.state.markAsPaidModal.paidDate : moment(new Date()).format("YYYY-MM-DD")}
                                onChange={(e) => {
                                    let markAsPaidModal = this.state.markAsPaidModal;
                                    markAsPaidModal.paidDate = e;
                                    this.setState({
                                        markAsPaidModal
                                    })
                                }}
                            />
                            <FormGroup style={{ marginTop: 20 }}>
                                <Label>{'Notes'.translate(this.props.lang)}</Label>
                                <Input type='textarea' style={{ height: 100 }} value={this.state.markAsPaidModal.notes}
                                    onChange={(e) => {
                                        let markAsPaidModal = this.state.markAsPaidModal;
                                        markAsPaidModal.notes = e.target.value;
                                        this.setState({
                                            markAsPaidModal
                                        })
                                    }}

                                />
                            </FormGroup>


                        </ModalBody>
                        <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }}>
                            <div className='button-separated'>
                                <Button color="primary" onClick={() => this.markAsPaid(this.state.markAsPaidModal)}> {'Confirm'.translate(this.props.lang)} </Button>
                                <Button color="primary" onClick={() => this.setState({ markAsPaidModal: null })}> {'Cancel'.translate(this.props.lang)} </Button>
                            </div>

                        </ModalFooter>
                    </Modal>
                    : null}

                {
                    this.state.denyInvoice ?
                        <Modal isOpen={this.state.denyInvoice} centered>
                            <ModalHeader style={{ margin: 'auto' }}>
                                {'Info'.translate(this.props.lang)}
                            </ModalHeader>
                            <ModalBody className="delete-modal">
                                <Isvg src={info} />
                                <div className='message-info'>
                                    {'Are you sure you want to deny this request?'.translate(this.props.lang)}
                                </div>
                                <FormGroup style={{ marginTop: 20 }}>
                                    <Label>{'Notes'.translate(this.props.lang)}</Label>
                                    <Input type='textarea' style={{ height: 100 }} value={this.state.denyReason} onChange={(e) => this.setState({ denyReason: e.target.value })} />
                                </FormGroup>
                            </ModalBody>
                            <ModalFooter className="buttons-right-blue">
                                <Button color='primary' onClick={() => {
                                    this.denyInvoice(this.state.denyInvoice)

                                }}>{'Yes'.translate(this.props.lang)}</Button>

                                <Button color='primary' onClick={() => {
                                    this.setState({
                                        denyInvoice: false
                                    })

                                }}>{'No'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        :
                        null
                }
            </div>
        );


    }
}
export default DeletionRequestsComponent;