import React, { Component } from 'react';
import Isvg from 'react-inlinesvg';
import Page from '../../containers/page';
import { API_ENDPOINT } from "../../constants";
import { Player, Controls } from '@lottiefiles/react-lottie-player';

import {
    Container,
    Row,
    Col
} from 'reactstrap';

import ContactForm from '../../components/forms/contactForm';
import arrowDown from '../../assets/svg/arrow-down-d.svg';
import chat from '../../assets/images/chat.png';
import ChatForm from '../../components/forms/chatForm';
import AOS from 'aos';
import 'aos/dist/aos.css'

var striptags = require('striptags');

function parseJSON(response) {
    return response.json().then((json) => {
      return {
        result: json,
        status: response.status,
      };
    });
  }

class OurSolutions extends Component {
    constructor(props) {
        super(props);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.player = React.createRef(); 

        this.state = {
            promotedProjects: [],
            poolActive: false,
            chatForm: false,
            yScroll2: 0,
            ourSolutions: [],
            ...props.initialData
        };
    }

    listenToScroll = () => {
        const yScroll2 =
            document.body.scrollTop || document.documentElement.scrollTop


        this.setState({
            yScroll2: yScroll2,
        })
    }

    sendMessage1 = (data) => {
        if(data.checked) {
            data.lang = this.props.lang
            this.setState({ 
                loading1: true
            }, () => {
    
                fetch(API_ENDPOINT + '/data/contact/new', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({data})   
                }).then(parseJSON).then(({result, status}) => {
                    if (result.error) {
                        this.setState({ error: result.error })
                    } else {
                    }
                })
            })
        }
    }
    
    sendMessage2 = (data) => {
        if(data.checked) {
            data.lang = this.props.lang
            this.setState({
                loading2: true
            }, () => {
    
                setTimeout(() => {
                    this.player.current.pause()
                }, 1120)
                
                fetch(API_ENDPOINT + '/data/contact/new', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({data})   
                }).then(parseJSON).then(({result, status}) => {
                    if (result.error) {
                        this.setState({ error: result.error })
                    } else {
                    }
                })
            })
        }
    }

    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        this.get();

        if(this.props[0].location && !this.props[0].location.hash) {
            if (typeof window !== 'undefined') { window.scrollTo(0, 0); }
        } else if (this.props[0].location && this.props[0].location.hash) {
            document.getElementById(this.props[0].location.hash.replace('#', '')).scrollIntoView({ behavior: 'smooth', block: 'start' });
        }

        this.updateWindowDimensions();
        window.addEventListener('scroll', this.listenToScroll)

        AOS.init({
            duration: 1000
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            // this.get()
        }
    }
    
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    render() {

        const percentage1 = 78;
        const percentage2 = 52;
        const percentage3 = 83;

        return (
            <div>

                <div className="chat-icon">
                    <img src={chat} alt="chat" onClick={() => {
                        this.setState({
                            chatForm: !this.state.chatForm
                        })
                    }} />

                    <div className={this.state.chatForm ? 'chat-form-container opened' : 'chat-form-container'}>
                    <span className="close-icon" onClick={() => {
                            this.setState({
                                chatForm: false
                            })
                        }}>x</span>
                        <h2>{'Kontakta oss'.translate(this.props.lang)}</h2>
                                    {
                                        !this.state.loading1 ? 
                                        <ChatForm onSubmit={this.sendMessage1} lang={this.props.lang} />
                                        :null
                                    }
                                    {
                                        this.state.loading1 ? 
                                        <Player
                                            ref={this.player} 
                                            autoplay={true}
                                            keepLastFrame={true}
                                            //loop={true}
                                            controls={true}
                                            src="https://assets4.lottiefiles.com/packages/lf20_oaw8d1yt.json"
                                            style={{ height: '100px', width: '100px' }}
                                        ></Player>
                                        :
                                        null
                                    }
                        <div className="chat-info">
                            <h6>
                                {'Du kan aven kontakta oss via telefon:'.translate(this.props.lang)}
                                <a href="tel:0107500655">{this.props.lang == 'en' ? '+46 (0)10-750 06 55'  : '010-750 06 55'}</a>
                            </h6>
                        </div>
                    </div>
                </div>

                <Isvg src={arrowDown} alt="cta" className={this.state.yScroll2 > 355 ? 'cta-arrow-2 cta-arrow-2-show': 'cta-arrow-2'} onClick={() => {
                    window.scrollTo(0, 0);
                }} />

                <div className="home solutions-home" style={{ backgroundImage: `url(${API_ENDPOINT + (this.state.ourSolutions && this.state.ourSolutions[0] && this.state.ourSolutions[0].backgroundImage)})` }}>
                    <Container>
                        <Col lg="8">
                            <div className="home-content">
                                <h1><div dangerouslySetInnerHTML={{__html: Object.translate(this.state.ourSolutions && this.state.ourSolutions[0], 'title', this.props.lang)}}></div></h1>
                                <h6><div dangerouslySetInnerHTML={{__html: Object.translate(this.state.ourSolutions && this.state.ourSolutions[0], 'shortDescription', this.props.lang)}}></div></h6>
                                <a href="#solutions-contact" className="button-clasic"><div dangerouslySetInnerHTML={{__html: Object.translate(this.state.ourSolutions && this.state.ourSolutions[0] && this.state.ourSolutions[0].item && this.state.ourSolutions[0].item[0], 'buttonText', this.props.lang)}}></div></a>
                            </div>
                        </Col>
                    </Container>
                    <a href="#why-us" className="cta-arrow">
                        <Isvg src={arrowDown} />
                    </a>
                </div>

                <div id="why-us" className="why-us">
                    <Container>
                        <Row>
                            <Col lg="10" className="m-auto">
                                <Row>
                                    <Col lg="12">
                                        <div className="why-us-title">
                                            <h2><div dangerouslySetInnerHTML={{__html: Object.translate(this.state.ourSolutions && this.state.ourSolutions[1], 'title', this.props.lang)}}></div></h2>
                                        </div>
                                    </Col>
                                    <Col lg="4" sm="4">
                                        <div className="why-us-box" data-aos="fade-up">
                                            <img src={API_ENDPOINT + (this.state.ourSolutions && this.state.ourSolutions[1] && this.state.ourSolutions[1].item && this.state.ourSolutions[1].item[0] && this.state.ourSolutions[1].item[0].image)} />
                                            <h6><div dangerouslySetInnerHTML={{__html: Object.translate(this.state.ourSolutions && this.state.ourSolutions[1] && this.state.ourSolutions[1].item && this.state.ourSolutions[1].item[0], 'subtitle', this.props.lang)}}></div></h6>
                                            <p><div dangerouslySetInnerHTML={{__html: Object.translate(this.state.ourSolutions && this.state.ourSolutions[1] && this.state.ourSolutions[1].item && this.state.ourSolutions[1].item[0], 'description', this.props.lang)}}></div></p>
                                        </div>
                                    </Col>
                                    <Col lg="4" sm="4">
                                        <div className="why-us-box" data-aos="fade-up">
                                            <img src={API_ENDPOINT + (this.state.ourSolutions && this.state.ourSolutions[1] && this.state.ourSolutions[1].item && this.state.ourSolutions[1].item[0] && this.state.ourSolutions[1].item[1].image)} />
                                            <h6><div dangerouslySetInnerHTML={{__html: Object.translate(this.state.ourSolutions && this.state.ourSolutions[1] && this.state.ourSolutions[1].item && this.state.ourSolutions[1].item[1], 'subtitle', this.props.lang)}}></div></h6>
                                            <p><div dangerouslySetInnerHTML={{__html: Object.translate(this.state.ourSolutions && this.state.ourSolutions[1] && this.state.ourSolutions[1].item && this.state.ourSolutions[1].item[1], 'description', this.props.lang)}}></div></p>
                                        </div>
                                    </Col>
                                    <Col lg="4" sm="4">
                                        <div className="why-us-box" data-aos="fade-up">
                                            <img src={API_ENDPOINT + (this.state.ourSolutions && this.state.ourSolutions[1] && this.state.ourSolutions[1].item && this.state.ourSolutions[1].item[0] && this.state.ourSolutions[1].item[2].image)} />
                                            <h6><div dangerouslySetInnerHTML={{__html: Object.translate(this.state.ourSolutions && this.state.ourSolutions[1] && this.state.ourSolutions[1].item && this.state.ourSolutions[1].item[2], 'subtitle', this.props.lang)}}></div></h6>
                                            <p><div dangerouslySetInnerHTML={{__html: Object.translate(this.state.ourSolutions && this.state.ourSolutions[1] && this.state.ourSolutions[1].item && this.state.ourSolutions[1].item[2], 'description', this.props.lang)}}></div></p>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className="help">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <div className="help-content">
                                    <h2><div dangerouslySetInnerHTML={{__html: Object.translate(this.state.ourSolutions && this.state.ourSolutions[2], 'title', this.props.lang)}}></div></h2>
                                    <p><div dangerouslySetInnerHTML={{__html: Object.translate(this.state.ourSolutions && this.state.ourSolutions[2], 'shortDescription', this.props.lang)}}></div></p>
                                    <a href="#solutions-contact" className="button-clasic"><div dangerouslySetInnerHTML={{__html: Object.translate(this.state.ourSolutions && this.state.ourSolutions[2] && this.state.ourSolutions[2].item && this.state.ourSolutions[2].item[0], 'buttonText', this.props.lang)}}></div></a>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                
                <div className="plans">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <div className="plans-title">
                                    <h2><div dangerouslySetInnerHTML={{__html: Object.translate(this.state.ourSolutions && this.state.ourSolutions[3], 'title', this.props.lang)}}></div></h2>
                                </div>
                            </Col>
                        </Row>
                        <Row className="plan-row">
                            <Col lg="6" sm="6" style={{ padding: 0 }}>
                                <div className="plan">
                                    <div dangerouslySetInnerHTML={{__html: Object.translate(this.state.ourSolutions && this.state.ourSolutions[3] && this.state.ourSolutions[3].item && this.state.ourSolutions[3].item[0] && this.state.ourSolutions[3].item[0], 'shortDescription', this.props.lang)}}></div>
                                    <div className="plan-footer">
                                        <a href="#solutions-contact" className="button-clasic">{'Kontakta oss'.translate(this.props.lang)}</a>
                                    </div>
                                </div>
                            </Col>

                            <Col lg="6" sm="6" style={{ padding: 0 }}>
                                <div className="plan">
                                    <div dangerouslySetInnerHTML={{__html: Object.translate(this.state.ourSolutions && this.state.ourSolutions[3] && this.state.ourSolutions[3].item && this.state.ourSolutions[3].item[1] && this.state.ourSolutions[3].item[1], 'shortDescription', this.props.lang)}}></div>
                                    <div className="plan-footer">
                                        <a href="#solutions-contact" className="button-clasic">{'Kontakta oss'.translate(this.props.lang)}</a>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>

                            <Col lg="4" sm="4" style={{ padding: 0 }}>
                                <div className="plan">
                                    <div dangerouslySetInnerHTML={{__html: Object.translate(this.state.ourSolutions && this.state.ourSolutions[3] && this.state.ourSolutions[3].item && this.state.ourSolutions[3].item[2] && this.state.ourSolutions[3].item[2], 'shortDescription', this.props.lang)}}></div>
                                    <div className="plan-footer">
                                        <a href="#solutions-contact" className="button-clasic">{'Kontakta oss'.translate(this.props.lang)}</a>
                                    </div>
                                </div>
                            </Col>

                            <Col lg="4" sm="4" style={{ padding: 0 }}>
                                <div className="plan">
                                    <div dangerouslySetInnerHTML={{__html: Object.translate(this.state.ourSolutions && this.state.ourSolutions[3] && this.state.ourSolutions[3].item && this.state.ourSolutions[3].item[3] && this.state.ourSolutions[3].item[3], 'shortDescription', this.props.lang)}}></div>
                                    <div className="plan-footer">
                                        <a href="#solutions-contact" className="button-clasic">{'Kontakta oss'.translate(this.props.lang)}</a>
                                    </div>
                                </div>
                            </Col>

                            <Col lg="4" sm="4" style={{ padding: 0 }}>
                                <div className="plan">
                                    <div dangerouslySetInnerHTML={{__html: Object.translate(this.state.ourSolutions && this.state.ourSolutions[3] && this.state.ourSolutions[3].item && this.state.ourSolutions[3].item[4] && this.state.ourSolutions[3].item[4], 'shortDescription', this.props.lang)}}></div>
                                    <div className="plan-footer">
                                        <a href="#solutions-contact" className="button-clasic">{'Kontakta oss'.translate(this.props.lang)}</a>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className="curoflow-highlight">
                    <Container>
                        <Row id="curoflow-highlight-1" className="curoflow-highlight-row-1">
                            <Col lg="6" sm="6" data-aos="fade-left">
                                <div className="curoflow-highlight-box">
                                    <img src={API_ENDPOINT + (this.state.ourSolutions && this.state.ourSolutions[4] && this.state.ourSolutions[4].item && this.state.ourSolutions[4].item[0] && this.state.ourSolutions[4].item[0].image )} />
                                    <h5><div dangerouslySetInnerHTML={{__html: Object.translate(this.state.ourSolutions && this.state.ourSolutions[4] && this.state.ourSolutions[4].item && this.state.ourSolutions[4].item[0], 'title', this.props.lang)}}></div></h5>
                                    <p><div dangerouslySetInnerHTML={{__html: Object.translate(this.state.ourSolutions && this.state.ourSolutions[4] && this.state.ourSolutions[4].item && this.state.ourSolutions[4].item[0], 'description', this.props.lang)}}></div></p>
                                </div>
                            </Col>
                            <Col lg="6" sm="6" data-aos="fade-left">
                                <div className="curoflow-highlight-box">
                                    <img src={API_ENDPOINT + (this.state.ourSolutions && this.state.ourSolutions[4] && this.state.ourSolutions[4].item && this.state.ourSolutions[4].item[1] && this.state.ourSolutions[4].item[1].image )} />
                                    <h5><div dangerouslySetInnerHTML={{__html: Object.translate(this.state.ourSolutions && this.state.ourSolutions[4] && this.state.ourSolutions[4].item && this.state.ourSolutions[4].item[1], 'title', this.props.lang)}}></div></h5>
                                    <p><div dangerouslySetInnerHTML={{__html: Object.translate(this.state.ourSolutions && this.state.ourSolutions[4] && this.state.ourSolutions[4].item && this.state.ourSolutions[4].item[1], 'description', this.props.lang)}}></div></p>
                                </div>
                            </Col>
                        </Row>
                        <div className="invisible-div" ></div>
                        <Row  className="curoflow-highlight-row-2">
                            <Col lg="6" sm="6" data-aos="fade-left">
                                <div className="curoflow-highlight-box">
                                    <img src={API_ENDPOINT + (this.state.ourSolutions && this.state.ourSolutions[5] && this.state.ourSolutions[5].item && this.state.ourSolutions[5].item[0] && this.state.ourSolutions[5].item[0].image )} />
                                    <h6><div dangerouslySetInnerHTML={{__html: Object.translate(this.state.ourSolutions && this.state.ourSolutions[5] && this.state.ourSolutions[5].item && this.state.ourSolutions[5].item[0], 'info', this.props.lang)}}></div></h6>
                                    <h5><div dangerouslySetInnerHTML={{__html: Object.translate(this.state.ourSolutions && this.state.ourSolutions[5] && this.state.ourSolutions[5].item && this.state.ourSolutions[5].item[0], 'title', this.props.lang)}}></div></h5>
                                    <p><div dangerouslySetInnerHTML={{__html: Object.translate(this.state.ourSolutions && this.state.ourSolutions[5] && this.state.ourSolutions[5].item && this.state.ourSolutions[5].item[0], 'description', this.props.lang)}}></div></p>
                                </div>
                            </Col>
                            <Col lg="6" sm="6" data-aos="fade-left">
                                <div id="curoflow-highlight-2" className="curoflow-highlight-box">
                                    <img src={API_ENDPOINT + (this.state.ourSolutions && this.state.ourSolutions[5] && this.state.ourSolutions[5].item && this.state.ourSolutions[5].item[1] && this.state.ourSolutions[5].item[1].image )} />
                                    <h6><div dangerouslySetInnerHTML={{__html: Object.translate(this.state.ourSolutions && this.state.ourSolutions[5] && this.state.ourSolutions[5].item && this.state.ourSolutions[5].item[1], 'info', this.props.lang)}}></div></h6>
                                    <h5><div dangerouslySetInnerHTML={{__html: Object.translate(this.state.ourSolutions && this.state.ourSolutions[5] && this.state.ourSolutions[5].item && this.state.ourSolutions[5].item[1], 'title', this.props.lang)}}></div></h5>
                                    <p><div dangerouslySetInnerHTML={{__html: Object.translate(this.state.ourSolutions && this.state.ourSolutions[5] && this.state.ourSolutions[5].item && this.state.ourSolutions[5].item[1], 'description', this.props.lang)}}></div></p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div id="solutions-contact" className="contact-form-section">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <div className="contact-form-section-title">
                                    <h2><div dangerouslySetInnerHTML={{__html: Object.translate(this.state.ourSolutions && this.state.ourSolutions[6], 'title', this.props.lang)}}></div></h2>
                                    <p><div dangerouslySetInnerHTML={{__html: Object.translate(this.state.ourSolutions && this.state.ourSolutions[6], 'shortDescription1', this.props.lang)}}></div></p>
                                    {
                                        !this.state.loading2 ? 
                                        <ContactForm onSubmit={this.sendMessage2} lang={this.props.lang} />
                                        :null
                                    }
                                    {
                                        this.state.loading2 ? 
                                        <Player
                                            ref={this.player} 
                                            autoplay={true}
                                            loop={true}
                                            controls={true}
                                            src="https://assets4.lottiefiles.com/packages/lf20_oaw8d1yt.json"
                                            style={{ height: '300px', width: '300px' }}
                                        ></Player>
                                        :
                                        null
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

            </div>
        );
    }
}

export default Page(OurSolutions);

