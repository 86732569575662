import React, { Component } from 'react';
import moment from 'moment';
import Page from '../../containers/page';
import ListBuilder from '../../components/listBuilder';
import { Link } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Input, Label, Table
} from 'reactstrap';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import ErrorModal from '../../components/errorModal';
import profileUser from "../../assets/images/user.png";
import warrning from '../../assets/svg/warrning.svg';
import Isvg from 'react-inlinesvg';
import attach from '../../assets/svg/attach.svg';
import { Player } from '@lottiefiles/react-lottie-player';
var striptags = require('striptags');

/**
* Support page
* @author   Stefan Pantic
*/
class HomePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            promotedProjects: [],
            ...props.initialData,
            form: false,

            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            tikets: [],
            category: {},
            messages: [],
            sentList: true,
            doctorTikets: [],
            tiket: -1,
            groupError: false,
            pageNumber: 0

        };
    }

    get = () => {

        // if (this.props.uData && this.props.uData.lastNotifications) {
        //     let notif = localStorage.getItem('notif') ? JSON.parse(localStorage.getItem('notif')) : [];

        //     for (let i = 0; i < this.props.uData.lastNotifications.length; i++) {
        //         if (notif.indexOf(this.props.uData.lastNotifications[i]._id) == -1) {
        //             notif.push(this.props.uData.lastNotifications[i]._id);
        //         }
        //     }
        //     localStorage.setItem('notif', JSON.stringify(notif));
        //     this.props.verifyUser();
        // }


        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1, user: this.props.uData._id, alias: localStorage.getItem('groupAlias'), group: this.props.selectedGroup, clinic: this.props.selectedClinic })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1, user: this.props.uData._id, alias: localStorage.getItem('groupAlias'), group: this.props.selectedGroup, clinic: this.props.selectedClinic, pageNumber: this.state.pageNumber })).then((data) => {

                if (data && data.doctorTikets && this.state.doctorTikets && this.state.doctorTikets.length) {
                    for (let i = 0; i < data.doctorTikets.length; i++) {
                        for (let j = 0; j < this.state.doctorTikets.length; j++) {
                            if (this.state.doctorTikets[j]._id == data.doctorTikets[i]._id) {
                                this.state.doctorTikets.splice(j, 1)
                            }

                        }

                    }
                    data.doctorTikets = [...this.state.doctorTikets, ...data.doctorTikets]
                }

                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }
        this.props.verifyUser()

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();

        if (!localStorage.getItem('groupAlias') && (this.props.uData.userLevel == 1 || this.props.uData.userLevel == 2)) {
            // this.props[0].history.push(`/home-patient-level`);
            this.setState({
                groupError: true
            })
        }

        this.interval = setInterval(() => {
            this.get();

        }, 60 * 1000);

    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval)
        }
    }
    componentDidUpdate(prevProps, prevState) {

        if (prevState.messageModal != this.state.messageModal && this.state.messageModal) {
            if (this.scrollArea) {
                this.scrollArea.scrollTop = this.scrollArea.scrollHeight;
            }
        }
        if (prevProps.selectedGroup != this.props.selectedGroup) {
            this.get();
        }
        if (this.props[0].location && this.props[0].location.state && this.props[0].location.state.messageModal && this.state.doctorTikets && this.state.doctorTikets.length && !prevState.doctorTikets.length) {
            for (let i = 0; i < this.state.doctorTikets.length; i++) {
                if (this.props[0].location.state.messageModal.toString() == this.state.doctorTikets[i]._id.toString()) {
                    this.setState({
                        messageModal: this.state.doctorTikets[i]
                    }, () => {
                        this.seen(this.state.doctorTikets[i]._id), this.findMessages(this.state.doctorTikets[i]._id)

                    })
                }
            }
        }

    }
    /**
    * Function for ring question to support 
    * @author   Stefan Pantic
    * @Objectparam    {String} message      sent message
    * @Objectparam    {String} subject      queston subject
    * @Objectparam    {String} tiket        support category id
    * @Objectparam    {String} user         user id who send question
    * @Objectparam    {String} alias        group alias
    * @Objectparam    {String} group        group id
    */
    sendQuestion = (data) => {

        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/support-category/tiket', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ message: data.message, subject: data.subject, tiket: this.state.tiket, user: this.props.uData._id, /*alias: localStorage.getItem('groupAlias'), group: this.props.selectedGroup, clinic: this.props.selectedClinic, */image: this.state.imageTicket, document: this.state.documentTicket })
            }).then(res => res.json()).then((result) => {
                this.get();
                this.setState({
                    supportModal: null,
                    category: {},
                    image: null,
                    document: null

                })
            })

        })
    }

    /**
    * Function change seen option on support tickets
    * @author   Stefan Pantic
    * @Objectparam    {String} id      ticket id
    */
    seen = (id) => {

        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/support-category/tiket/update/' + id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify()
            }).then(res => res.json()).then((result) => {
                this.get();

            })

        })
    }

    /**
    * Function for sending reply on received ticket
    * @author   Stefan Pantic
    * @Objectparam    {String} answer       message that user send
    * @Objectparam    {String} tiket        ticket id
    * @Objectparam    {String} user         user id who send reply
    */
    sendAnswer = (data) => {

        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/support-category/answer', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ answer: data.answer, tiket: data._id, user: this.props.uData._id, image: this.state.image, document: this.state.document })
            }).then(res => res.json()).then((result) => {
                if (result.error) {
                    this.setState({
                        error: result.error.translate(this.props.lang)
                    })
                } else {
                    this.get();

                    let messageModal = this.state.messageModal;
                    messageModal.answer = '';
                    this.setState({
                        messageModal,
                        image: null,
                        document: null
                    }, () => this.findMessages(data._id))
                }

            })

        })
    }

    /**
    * Function for file upload
    * @author   Milan Stanojevic
    */
    uploadDocument = (e) => {
        let input = e.target;
        if (input.files && input.files[0]) {

            let fsize = input.files[0].size;
            let file = Math.round((fsize / 1024))
            this.setState({
                uploadTimestampDoc: Math.floor(new Date().getTime() / 1000)
            })
            if (file > 5120) {
                this.setState({ error: "File size limit has been reached! (max 5 MB)".translate(this.props.lang) })
            } else {

                this.setState({
                    _documentUploading: true
                })

                let formData = new FormData();
                formData.append('file', input.files[0]);
                formData.append('group', this.props.selectedGroup)
                formData.append('fileType', 'clinicSupport')

                fetch(API_ENDPOINT + '/chat/upload/document', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,

                    },
                    body: formData
                }).then((res) => res.json()).then((img) => {
                    if (this.state.newTicket) {
                        this.setState({
                            documentTicket: img,
                            newTicket: false
                        })
                    } else {
                        this.setState({
                            document: img
                        })
                    }

                    this.setState({
                        _documentUploading: null
                    })
                });
            }
        }
    }

    /**
    * Function for image upload
    * @author   Milan Stanojevic
    */
    uploadImage = (e) => {
        let input = e.target;
        if (input.files && input.files[0]) {

            let fsize = input.files[0].size;
            let file = Math.round((fsize / 1024))
            this.setState({
                uploadTimestamp: Math.floor(new Date().getTime() / 1000)
            })
            if (file > 10240) {
                this.setState({ error: "File size limit has been reached! (max 10 MB)".translate(this.props.lang) })
            } else {

                this.setState({
                    _imageUploading: true
                })

                let formData = new FormData();
                formData.append('file', input.files[0]);
                formData.append('group', this.props.selectedGroup)
                formData.append('fileType', 'clinicSupport')

                fetch(API_ENDPOINT + '/chat/upload/image', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,

                    },
                    body: formData
                }).then((res) => res.text()).then((img) => {
                    if (this.state.newTicket) {
                        this.setState({
                            imageTicket: img,
                            newTicket: false
                        })
                    } else {
                        this.setState({
                            image: img
                        })
                    }
-
                    this.setState({
                        _imageUploading: null
                    })
                });
            }
        }
    }

    findMessages = (id) => {
        fetch(API_ENDPOINT + '/support-category/all-messages/' + id, {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({})
        }).then(res => res.json()).then((result) => {
            if (result.error) {
                this.setState({
                    error: result.error.translate(this.props.lang)
                })
            } else {

                this.setState({
                    messages: result
                })
            }

        })
    }

    render() {
        // console.log(this.state.messageModal);
        // console.log(this.state.displayNone);
        // let params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        let level = this.props.uData.userLevel
        return (
            <div className="dashboard">
                {this.state._imageUploading || this.state._documentUploading ?
                    <div className="file-progress-loader">
                        <div>
                            <Player
                                onEvent={(event) => {
                                    if (event === "load" && this.player && this.player.current) this.player.current.play();
                                }}
                                ref={this.player} // set the ref to your class instance
                                autoplay={true}
                                loop={true}
                                controls={true}
                                src="/lf30_editor_l5cxzdyf.json"
                                renderer={'svg'}
                                style={{ height: "128px", width: "128px", }}
                            ></Player>
                            <h6>{'Processing file...'.translate(this.state.lang)}</h6>
                        </div>

                    </div>
                    : null
                }
                <Container fluid>
                    <div className="panel select-clinics-wrapper">
                        <h5 className="component-header mb-35">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h5>
                        {level > 20 ?
                            null
                            :
                            <Col lg="4" className="ml-0">
                                <Button className="news-ticket-button" onClick={() => this.setState({ supportModal: true })}>{'Open new tiket'.translate(this.props.lang)}</Button>
                            </Col>

                        }
                        <Row>
                            {this.state.sentList ?
                                <Col lg="4">
                                    <div className={typeof window != 'undefined' && window.innerWidth < 768 && this.state.displayNone ? "support-ticket-none" : "support-ticket"}>

                                        <>
                                            {this.state.doctorTikets.sort(function (a, b) { return b.timestemp - a.timestemp }).sort(function (a, b) { return a.close - b.close }).map(item => {

                                                return (

                                                    <button className={this.state.messageModal && this.state.messageModal._id == item._id ? item.close ? 'ticket-closed active-ticket-red' : item.supportUserWhoTakeTicket ? 'ticked-open active-ticket-green' : 'ticket-inprogress active-ticket-inprogress' : item.close ? 'ticket-closed' : item.supportUserWhoTakeTicket ? 'ticked-open' : 'ticket-inprogress'} onClick={() => this.setState({ messageModal: item, displayNone: true }, () => { this.seen(item._id), this.findMessages(item._id) })}>
                                                        <h6>{item.name ? item.name : 'Site - support'}</h6>
                                                        <h6>{'Support-ID:'.translate(this.props.lang)} {item.tiketNumber} - {item.subject ? item.subject : 'Site'}</h6>
                                                        {/* <h6>{'Clinic:'.translate(this.props.lang)} {item.clinicName ? item.clinicName : item.organization ? item.organization : ''}</h6> */}
                                                        {item.userWhoCreatedTicket && item.userWhoCreatedTicket.toString() != this.props.uData._id.toString() ? !item.seenSupport ? <p className="ticket-notification"></p> : '' : !item.seenUser && item.userWhoCreatedTicket ? <p className="ticket-notification"></p> : item.userWhoCreatedTicket ? '' : !item.seenSupport ? <p className="ticket-notification"></p> : ''}
                                                        <p style={{ fontWeight: 600, color: '#3A3F56', textAlign: 'left' }}>{item.email ? `${'E-mail'.translate(this.props.lang)}: ${item.email}` : null}</p>
                                                        <h6 style={{ lineHeight: 0, display: 'block', marginBottom: 20 }}> {item.phone ? <p>{'Phone'.translate(this.props.lang)}: {item.phone}</p> : null}</h6>
                                                        <div className="ticket-body">
                                                            {item.profilePicture ?
                                                                <img src={API_ENDPOINT + item.profilePicture} />

                                                                :
                                                                <img src={profileUser} />

                                                            }
                                                            <h6>{item.userName ? item.userName : item.name ? item.name : ''}</h6>
                                                            <p>{moment.unix(item.timestemp).format(`${this.props.dateFormat} HH:mm`)}</p>
                                                        </div>

                                                    </button>
                                                )
                                            })}
                                        </>
                                    </div>
                                    {!(typeof window != 'undefined' && window.innerWidth < 768 && this.state.messageModal) ?
                                        <Button color='primary' onClick={() => this.setState({ pageNumber: this.state.pageNumber + 1 }, () => this.get())} >{'Load more'.translate(this.props.lang)}</Button>
                                        : null}
                                </Col>
                                :
                                null}
                            <Col lg="8">
                                <div className="ticket-answer" ref={(node) => this.scrollArea = node}>
                                    {this.state.messageModal && typeof window != 'undefined' && window.innerWidth > 768 ?
                                        <div className="ticket-message-tab-container">

                                            {this.state.messages.sort(function (a, b) { return a.timestemp - b.timestemp }).map(item => {
                                                return (
                                                    <div className="mesage-box">
                                                        <div className="message-header">
                                                            <div className="message-header-container">
                                                                {item.profilePicture ?
                                                                    <img src={API_ENDPOINT + item.profilePicture} />

                                                                    :
                                                                    <img src={profileUser} />

                                                                }
                                                                <h6>{item.user ? item.user : ''}</h6>
                                                            </div>
                                                            <p>{moment.unix(item.timestemp).format(`${this.props.dateFormat} HH:mm`)}</p>
                                                        </div>
                                                        <p>

                                                            {item.message}
                                                            <div >
                                                                {item.image ? <a target='_blank' href={API_ENDPOINT + item.image}>{item.image}</a> : null}
                                                                {
                                                                    item.document ?
                                                                        <div className="document"><a target="_blank" download href={API_ENDPOINT + item.document.file}><Isvg src={attach} style={{ width: 20, height: 20 }} />{item.document.name}</a></div>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </p>
                                                    </div>

                                                )
                                            })}

                                            {this.state.messageModal.close ?
                                                null
                                                : <FormGroup className="ticket-message-group ticket-message-tab">
                                                    <Label>{'Message'.translate(this.props.lang)}</Label>
                                                    <Input type='textarea' rows="6" className="textarea-six-row" onChange={(e) => {
                                                        let messageModal = this.state.messageModal;
                                                        messageModal.answer = e.target.value;
                                                        this.setState({
                                                            messageModal
                                                        })
                                                    }} value={this.state.messageModal.answer} />
                                                </FormGroup>}
                                            <div style={{ background: 'white' }}>
                                                {this.state.image ? <a target='_blank' href={API_ENDPOINT + this.state.image}>{this.state.image}</a> : null}
                                                {
                                                    this.state.document ?
                                                        <div className="document"><a target="_blank" download href={API_ENDPOINT + this.state.document.file}><Isvg src={attach} style={{ width: 20, height: 20 }} />{this.state.document.name}</a></div>
                                                        :
                                                        null
                                                }
                                            </div>

                                            {this.state.messageModal.close || !this.state.messageModal.clinic ? null :
                                                <Button color="primary" style={{ marginBottom: 20, marginRight: 10 }} className={this.state._documentUploading ? 'attach uploading' : this.state.document ? 'attach uploaded' : "attach"} onClick={() => { if (this.documentInput) this.documentInput.click() }}>
                                                    {'Attach file'.translate(this.props.lang)}
                                                </Button>}
                                            {this.state.messageModal.close || !this.state.messageModal.clinic ? null :
                                                <Button color="primary" style={{ marginBottom: 20, marginRight: 10 }} className={this.state._imageUploading ? 'image uploading' : this.state.image ? 'image uploaded' : "image"} onClick={() => { if (this.imageInput) this.imageInput.click() }}>
                                                    {'Attach image'.translate(this.props.lang)}
                                                </Button>}
                                            {this.state.messageModal.close ? null :
                                                <Button className="news-ticket-button" disabled={this.state.messageModal.answer || this.state.image || this.state.document ? this.state.messageModal.answer  != '' || this.state.image !='' || this.state.document !='' ? false : true : true} onClick={() => this.sendAnswer(this.state.messageModal)}>{'Send'.translate(this.props.lang)}</Button>
                                            }
                                        </div>
                                        : this.state.messageModal && typeof window != 'undefined' && window.innerWidth < 768 ?
                                            <div className="ticket-message-tab-container ticket-message-tab-container-fixed">
                                                <button className="support-back-button" onClick={() => { this.setState({ displayNone: false, messageModal: !this.state.messageModal }) }}>{'Back'.translate(this.props.lang)}</button>
                                                {this.state.messages.sort(function (a, b) { return a.timestemp - b.timestemp }).map(item => {
                                                    return (
                                                        <div className="mesage-box">
                                                            <div className="message-header">
                                                                <div className="message-header-container">
                                                                    {item.profilePicture ?
                                                                        <img src={API_ENDPOINT + item.profilePicture} />

                                                                        :
                                                                        <img src={profileUser} />

                                                                    }
                                                                    <h6>{item.user ? item.user : ''}</h6>
                                                                </div>
                                                                <p>{moment.unix(item.timestemp).format(`${this.props.dateFormat} HH:mm`)}</p>
                                                            </div>
                                                            <p>
                                                                {item.message}
                                                                <div >
                                                                    {item.image ? <a target='_blank' href={API_ENDPOINT + item.image}>{item.image}</a> : null}
                                                                    {
                                                                        item.document ?
                                                                            <div className="document"><a target="_blank" download href={API_ENDPOINT + item.document.file}><Isvg src={attach} style={{ width: 20, height: 20 }} />{item.document.name}</a></div>
                                                                            :
                                                                            null
                                                                    }
                                                                </div>
                                                            </p>
                                                        </div>

                                                    )
                                                })}

                                                {this.state.messageModal.close ?
                                                    null
                                                    : <FormGroup className="ticket-message-group ticket-message-tab">
                                                        <Label>{'Message'.translate(this.props.lang)}</Label>
                                                        <Input type='textarea' rows="6" className="textarea-six-row" onChange={(e) => {
                                                            let messageModal = this.state.messageModal;
                                                            messageModal.answer = e.target.value;
                                                            this.setState({
                                                                messageModal
                                                            })
                                                        }} value={this.state.messageModal.answer} />
                                                    </FormGroup>}
                                                <div style={{ background: 'white' }}>
                                                    {this.state.image ? <a target='_blank' href={API_ENDPOINT + this.state.image}>{this.state.image}</a> : null}
                                                    {
                                                        this.state.document ?
                                                            <div className="document"><a target="_blank" download href={API_ENDPOINT + this.state.document.file}><Isvg src={attach} style={{ width: 20, height: 20 }} />{this.state.document.name}</a></div>
                                                            :
                                                            null
                                                    }
                                                </div>
                                                <div className="chat-buttons-footer-support">
                                                    {this.state.messageModal.close || !this.state.messageModal.clinic ? null :
                                                        <Button color="primary" style={{ marginBottom: 20, marginRight: 10 }} className={this.state._documentUploading ? 'attach uploading' : this.state.document ? 'attach uploaded' : "attach"} onClick={() => { if (this.documentInput) this.documentInput.click() }}>
                                                            {'Attach file'.translate(this.props.lang)}
                                                        </Button>}
                                                    {this.state.messageModal.close || !this.state.messageModal.clinic ? null :
                                                        <Button color="primary" style={{ marginBottom: 20, marginRight: 10 }} className={this.state._imageUploading ? 'image uploading' : this.state.image ? 'image uploaded' : "image"} onClick={() => { if (this.imageInput) this.imageInput.click() }}>
                                                            {'Attach image'.translate(this.props.lang)}
                                                        </Button>}
                                                    {this.state.messageModal.close ? null :
                                                        <Button className="news-ticket-button" disabled={this.state.messageModal.answer || this.state.image || this.state.document ? this.state.messageModal.answer  != '' || this.state.image !='' || this.state.document !='' ? false : true : true} onClick={() => this.sendAnswer(this.state.messageModal)}>{'Send'.translate(this.props.lang)}</Button>
                                                    }
                                                </div>
                                            </div>
                                            : null}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>

                {this.state.supportModal ?
                    <Modal isOpen={this.state.supportModal} centered>
                        <ModalHeader toggle={() => this.setState({ supportModal: !this.state.supportModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ supportModal: !this.state.supportModal })}>&times;</button>}>{'Your question'.translate(this.props.lang)}</ModalHeader>
                        <ModalBody>

                            <Input type='select'
                                className="mb-20"
                                onChange={e => this.setState({ tiket: e.target.value }, () => { this.get() })}>
                                <option value={-1}>{'None'.translate(this.props.lang)}</option>

                                {this.state.items.map(item => {
                                    return <option value={item._id}>
                                        {item.name}
                                    </option>
                                })}

                            </Input>
                            <FormGroup>
                                <Label>{'Subject'.translate(this.props.lang)}</Label>
                                <Input type='text' onChange={(e) => {
                                    let category = this.state.category;
                                    category.subject = e.target.value;
                                    this.setState({
                                        category
                                    })
                                }} value={this.state.category.subject} />
                            </FormGroup>
                            <FormGroup>
                                <Label>{'Message'.translate(this.props.lang)}</Label>

                                <Input type='textarea' rows="6" className="textarea-six-row" onChange={(e) => {
                                    let category = this.state.category;
                                    category.message = e.target.value;

                                    this.setState({
                                        category
                                    })
                                }} value={this.state.category.message} />
                            </FormGroup>
                            <div>
                                {this.state.imageTicket ? <a target='_blank' href={API_ENDPOINT + this.state.imageTicket}>{this.state.imageTicket}</a> : null}
                                {
                                    this.state.documentTicket ?
                                        <div className="document"><a target="_blank" download href={API_ENDPOINT + this.state.documentTicket.file}><Isvg src={attach} />{this.state.documentTicket.name}</a></div>
                                        :
                                        null
                                }
                            </div>


                        </ModalBody>
                        <ModalFooter className='buttons-right-blue'>
                            <Button color="primary" style={{ margin: 10 }} className={this.state._documentUploading ? 'attach uploading' : this.state.documentTicket ? 'attach uploaded' : "attach"} onClick={() => this.setState({ newTicket: true }, () => { if (this.documentInput) this.documentInput.click() })}>
                                <div className='chat-icons-tooltip'>
                                    {'Attach file'.translate(this.props.lang)}
                                </div>
                            </Button>
                            <Button color="primary" style={{ margin: 10 }} className={this.state._imageUploading ? 'image uploading' : this.state.imageTicket ? 'image uploaded' : "image"} onClick={() => this.setState({ newTicket: true }, () => { if (this.imageInput) this.imageInput.click() })}>
                                <div className='chat-icons-tooltip'>
                                    {'Attach image'.translate(this.props.lang)}
                                </div>
                            </Button>

                            <Button color="primary" style={{ margin: 10 }} disabled={this.state.category.message && this.state.category.subject && this.state.tiket != -1 ? false : true} onClick={() => this.sendQuestion(this.state.category)}>{'Send'.translate(this.props.lang)}</Button>
                        </ModalFooter>
                    </Modal>
                    : null}

                <input ref={(node) => this.documentInput = node} type="file" title={"No file chosen".translate(this.props.lang)} accept=".doc, .docx, .pdf" key={this.state.uploadTimestampDoc} onChange={this.uploadDocument} style={{ pointerEvents: 'none', opacity: 0 }} />
                <input ref={(node) => this.imageInput = node} type="file" title={"No file chosen".translate(this.props.lang)} accept="image/png, image/jpeg, image/jpg, image/gif, image/svg" key={this.state.uploadTimestamp} onChange={this.uploadImage} style={{ pointerEvents: 'none', opacity: 0 }} />


                {this.state.error ? (
                    <ErrorModal lang={this.props.lang}
                        isOpen={this.state.error}
                        toggle={() => this.setState({ error: null })}

                    >
                        {this.state.error.translate(this.props.lang)}
                    </ErrorModal>
                ) : null}
                {this.state.groupError ? (
                    <Modal isOpen={this.state.groupError} centered>
                        <ModalHeader>{'Error'.translate(this.props.lang)}</ModalHeader>
                        <ModalBody className="delete-modal">
                            <img src={warrning} />
                            <p className="message">{'You have to select the group!'.translate(this.props.lang)}</p>
                        </ModalBody>

                        <ModalFooter style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button onClick={() => {
                                this.props[0].history.push(`/home-patient-level`);
                            }}>{'Close'.translate(this.props.lang)}</Button>
                        </ModalFooter>
                    </Modal>
                ) : null}
            </div>

        );


    }
}

export default Page(HomePage);