import React, { Component } from 'react';

import {
    Modal, ModalBody, ModalHeader, FormGroup, Button
} from 'reactstrap';


import { getSearchParams } from '../helpers/linkHelper';
import { API_ENDPOINT } from '../constants';


/**
* Payson modal
* @author   Stefan Pantic
*/
class Payson extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            total: 0,
            loading: true,
            order: {},
            currency: 'sek',
            item: {},
            merchant: {},
            snippet: false
        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic, doctor: this.props.uData._id })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic, doctor: this.props.uData._id })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }
    }

    componentDidMount() {

        this.createCheckout();

    }

    componentDidUpdate(prevProps) {

    }


    /**
    * Schedule doctor events on calendar
    * @author   Stefan Pantic
    * @Objectparam    {String} name           product name
    * @Objectparam    {Number} unitPrice      unitPrice
    * @Objectparam    {Number} quantity        quantity
    * @Objectparam    {String} currency         currency
    * @Objectparam    {Number} taxRate          taxRate
    */
    createCheckout = () => {
        this.setState({
            loading: true
        }, () => {

            fetch(API_ENDPOINT + '/payson', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ lang: this.props.lang, name: 'payson', currency: 'sek', numberOfLicences: this.props.numberOfLicences, discount: this.props.discount, price: this.props.price, unitPrice: this.props.price, organisationPaying: this.props.organisationPaying, upgradeLicence: this.props.upgradeLicence, quantity: 1, userID: this.props.uData._id, licenceID: this.props.licenceID, licenceDuration: this.props.licenceDuration, redirectUrl: this.props.redirectUrl })
            }).then(res => res.json()).then((result) => {
                if (result.snippet) {
                    let scriptSrc = result.snippet.split('<script')[1].replace("type='text/javascript' src='", "").replace("'></script>", "")

                    this.setState({
                        iframeModal: true,
                        htmlSnippet: result.snippet
                    }, () => {
                        if (this.script) {
                            this.script.remove();
                        }

                        var ref = window.document.getElementsByTagName("script")[0];
                        var script = window.document.createElement("script");
                        script.src = scriptSrc;
                        this.script = script;

                        ref.parentNode.insertBefore(script, ref);

                    })
                }
            })
        })
    }

    render() {
        console.log(this.props);
        return (
            <div className="dashboard">
                <Modal isOpen={this.state.htmlSnippet} centered>
                    <ModalHeader toggle={() => this.setState({ htmlSnippet: null }, () => {
                        this.props.openModal(null);
                    })}
                        close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ htmlSnippet: null }, () => {
                            this.props.openModal(null);
                        })}
                        >&times;</button>}>{'Payment'.translate(this.props.lang)}</ModalHeader>

                    <ModalBody>
                        <div dangerouslySetInnerHTML={{ __html: this.state.htmlSnippet }} onChange={() => { }}></div>

                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default Payson;