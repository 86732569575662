import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Button, Input, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, FormGroup, Label, FormFeedback
} from 'reactstrap';

import FormBuilder from '../../components/forms/modalFormBuilder';
import FormBuilderLogin from "../../components/forms/formBuilder";
import { email, required } from '../../components/forms/validation';
import InfoModal from '../../components/infoModal';

import ErrorModal from '../../components/errorModal';
import DeleteModal from '../../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';
import moment, { duration } from 'moment';
import profilePicture from '../../assets/images/user.png';
import Isvg from 'react-inlinesvg';
import send from '../../assets/svg/send.svg';
import attach from '../../assets/svg/attach.svg';
import image from '../../assets/svg/image.svg';
import moreIcon from '../../assets/svg/more.svg';
import newMessage from '../../assets/svg/new-message.svg'
import videoChatIcon from '../../assets/svg/video.svg';
import userChatIcon from '../../assets/svg/user.svg';
import ChatIcon from '../../assets/svg/video-chat-icon.svg'
import addIcon from '../../assets/svg/add-icon.svg';
import barsIcon from '../../assets/svg/bars.svg';
import archiveIcon from '../../assets/svg/archive.svg';
import closeIcon from '../../assets/svg/close.svg';
import leaveIcon from '../../assets/svg/leave.svg';
import roomImage from "../../assets/images/room.jpg";
import NetId from '../../assets/images/netid.png'
import ArrowBack from '../../assets/svg/arrow-back.svg'

import micIcon from '../../assets/svg/mic.svg';
import micMutedIcon from '../../assets/svg/mic_muted.svg';
import cameraIcon from '../../assets/svg/camera.svg';
import cameraOffIcon from '../../assets/svg/camera_off.svg';
import screenShareIcon from '../../assets/svg/screen_share.svg';
import translateIcon from '../../assets/svg/tr.svg';
import handIcon from '../../assets/svg/hand.svg';
import handSound from '../../assets/sound/raise.mp3';
import notificationSound from '../../assets/sound/notification.wav';
import moveIcon from '../../assets/svg/move.svg';
import speakerIcon from '../../assets/svg/speaker.svg';
import shareIcon from '../../assets/svg/share.svg';
import settingsIcon from '../../assets/svg/settings.svg';
import secureIcon from '../../assets/svg/secure.svg';
import googleImg from '../../assets/images/google.png';
import outlookImg from '../../assets/images/outlook.jpg';
import { Player } from "@lottiefiles/react-lottie-player";
import garabage from '../../assets/svg/garbage.svg'
import flip from '../../assets/svg/flip.svg'
import pinIcon from '../../assets/svg/pin.svg';
import unpinIcon from '../../assets/svg/unpin.svg';

import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import warrning from '../../assets/svg/warrning.svg';
import peopleIcon from '../../assets/svg/people.svg';
import camIcon from '../../assets/svg/cam.svg';

import starIcon from '../../assets/svg/star.svg';
import fullStarIcon from '../../assets/svg/full-star.svg';
import photoIcon from '../../assets/svg/photo.svg';
import galleryIcon from '../../assets/svg/gallery.svg';
import newTab from '../../assets/svg/newtab.svg';
import phraseIcon from '../../assets/svg/frase_chat.svg';
import info from '../../assets/svg/info.svg'
import formIcon from '../../assets/svg/attach-form.svg';
import journalIcon from '../../assets/svg/journal-icon.svg';
import videoIcon from '../../assets/svg/video-icon.svg';
import call from '../../assets/svg/call.svg';
import PdfSaveV2 from '../../components/pdfSaveV2';
import PrintHelper from '../../components/printHelperV2';
import PdfSave from '../../components/saveAsPdf';
import DatePicker from '../../components/forms/fields/datePicker'
import TimePicker from '../../components/forms/fields/timepicker'
import TimePicker2 from '../../components/forms/fields/timepicker2'
import striptags from 'striptags'

import WebRTCTest from '../../components/webRTCTest';
import SoundMeter from '../../helpers/soundMeter';
import BankdIDIdentity from '../../components/bankIDIdentity';
import TimeZonePicker from '../../components/forms/fields/timeZonePicker';
import Checkbox from '../../components/forms/fields/checkbox';
import copyLinkIcon from '../../assets/svg/copy-link-icon.svg';
import infoIcon from '../../assets/svg/info-icon.svg';
import PaysonSvg from '../../assets/svg/payson.svg';
import SwishSvg from '../../assets/svg/swish.svg';
import Swish from '../swish';
const swishNumber = 123456;
import Payson from '../../components/payson';
import PhoneField from '../../components/forms/fields/phone';
import { Waypoint } from 'react-waypoint';
import blankVideo from '../../assets/blank.mp4';
import VideoGateway from './videoGateway';
import { SelfieSegmentation } from '@mediapipe/selfie_segmentation';
import { Camera } from '@mediapipe/camera_utils';
import soundTest from '../../assets/svg/test_sound.svg';
import VideoCallLogger from '../../components/videoCallLogger';
import { v4 as uuidv4 } from 'uuid';

var browserInfo = require('browser-info');

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

function iosDevice() {
    var userAgent = window.navigator.userAgent;
    if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
        return true;
    }
    else {
        return false;
    }
}




if (typeof window !== 'undefined') {
    CanvasRenderingContext2D.prototype.roundRect = function (x, y, w, h, r) {
        if (w < 2 * r) r = w / 2;
        if (h < 2 * r) r = h / 2;
        this.beginPath();
        this.moveTo(x + r, y);
        this.arcTo(x + w, y, x + w, y + h, r);
        this.arcTo(x + w, y + h, x, y + h, r);
        this.arcTo(x, y + h, x, y, r);
        this.arcTo(x, y, x + w, y, r);
        this.closePath();
        return this;
    }
}
function drawImageProp(ctx, img, x, y, w, h, offsetX, offsetY) {

    if (arguments.length === 2) {
        x = y = 0;
        w = ctx.canvas.width;
        h = ctx.canvas.height;
    }

    // default offset is center
    offsetX = typeof offsetX === "number" ? offsetX : 0.5;
    offsetY = typeof offsetY === "number" ? offsetY : 0.5;

    // keep bounds [0.0, 1.0]
    if (offsetX < 0) offsetX = 0;
    if (offsetY < 0) offsetY = 0;
    if (offsetX > 1) offsetX = 1;
    if (offsetY > 1) offsetY = 1;

    var iw = img.videoWidth,
        ih = img.videoHeight,
        r = Math.min(w / iw, h / ih),
        nw = iw * r,   // new prop. width
        nh = ih * r,   // new prop. height
        cx, cy, cw, ch, ar = 1;

    // decide which gap to fill    
    if (nw < w) ar = w / nw;
    if (Math.abs(ar - 1) < 1e-14 && nh < h) ar = h / nh;  // updated
    nw *= ar;
    nh *= ar;

    // calc source rectangle
    cw = iw / (nw / w);
    ch = ih / (nh / h);

    cx = (iw - cw) * offsetX;
    cy = (ih - ch) * offsetY;

    // make sure source rectangle is valid
    if (cx < 0) cx = 0;
    if (cy < 0) cy = 0;
    if (cw > iw) cw = iw;
    if (ch > ih) ch = ih;

    // fill image in dest. rectangle
    ctx.drawImage(img, cx, cy, cw, ch, x, y, w, h);
}


function detectMob() {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];

    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
}

function getOffset(el) {
    var _x = 0;
    var _y = 0;
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
        _x += el.offsetLeft - el.scrollLeft;
        _y += el.offsetTop - el.scrollTop;
        el = el.offsetParent;
    }
    return { top: _y, left: _x };
}


function getObjectFitSize(contains, containerWidth, containerHeight, width, height) {
    var doRatio = width / height;
    var cRatio = containerWidth / containerHeight;
    var targetWidth = 0;
    var targetHeight = 0;
    var test = contains ? (doRatio > cRatio) : (doRatio < cRatio);

    if (test) {
        targetWidth = containerWidth;
        targetHeight = targetWidth / doRatio;
    } else {
        targetHeight = containerHeight;
        targetWidth = targetHeight * doRatio;
    }

    return {
        width: targetWidth,
        height: targetHeight,
        x: (containerWidth - targetWidth) / 2,
        y: (containerHeight - targetHeight) / 2
    };
}

const RTCPeerConfiguration = {
    iceServers: [
        {
            urls: 'stun:stun.curoflow.se:443',
        },
        {
            urls: 'turn:turn.curoflow.se:443',
            username: 'jkZhfAU2LQRTtyMrRgZH',
            credential: 'MZJMdbQu8QcLwAaGsCxj'
        }
    ]

};

function isElementVisible(element) {
    let pos = element.getBoundingClientRect();
    if (pos.top < window.innerHeight &&
        pos.top > -element.offsetHeight
        && pos.left > -element.offsetWidth
        && pos.left < window.innerWidth) {
        return true;
    } else {

        return false;
    }
}

function convertFrom24To12StringFormat(time24) {
    if (time24) {
        let sHours = time24.split(":")[0]
        let minutes = time24.split(":")[1]
        // const [sHours, minutes] = time24.match(timeHs:timeMin).slice(1);
        const period = +sHours < 12 ? 'AM' : 'PM';
        const hours = +sHours % 12 || 12;

        return `${hours}:${minutes}`;
    }
}


class Timer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            timer: null
        }
    }

    componentDidMount() {
        let startTs = Math.floor(new Date(this.props.startTime).getTime() / 1000);
        this.timerHandler = setInterval(() => {

            let diff = Math.floor(new Date().getTime() / 1000) - startTs;
            var hrs = ~~(diff / 3600);
            var mins = ~~((diff % 3600) / 60);
            var secs = ~~diff % 60;



            this.setState({
                timer: `${hrs <= 9 ? '0' : ''}${hrs}:${mins <= 9 ? '0' : ''}${mins}:${secs <= 9 ? '0' : ''}${secs}`
            })
        }, 1000);

    }

    componentWillUnmount() {
        clearInterval(this.timerHandler);
        clearInterval(this.startNewClientAnimationInterval)
    }

    render() {
        if (!this.props.startTime) {
            return null;
        }

        return this.state.timer;
    }
}


/**
* Chat module
* @author   Milan Stanojevic
*/
class Chat extends Component {
    constructor(props) {
        super(props);

        this.peers = {};
        this.remoteVideo = {};
        this.peerStreams = {};
        this.tmpStreams = [];
        this.reconnectingPeer = {};

        this.newTabWindows = [];



        this.state = {
            ...props.initialData,
            isDragging: false,
            mutedParticipants: {},
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            kbps: 3600,
            items: [],
            groups: [],
            subgroups: [],
            total: 0,
            loading: true,
            conversations: [],
            messages: [],
            numberOfPatients: 0,
            message: '',
            showSeen: {},
            conversationsType: 0,
            videoElements: [],
            // videoElements: [1,2,3,4,5,6,7,8,9,10],
            layoutOutput: typeof window !== 'undefined' && window.innerWidth < 768 ? '-1' : 2,
            tooltip: 0,
            localVideoX: 0,
            localVideoY: 0,
            hands: {},
            layout: [],
            linkCopied: false,
            _connecting: {},
            guestNames: {},
            guestsVerificationStatus: {},
            connecting: {},

            speaking: {},
            cameraStatus: {},
            micStatus: {},
            shareScreenStatus: {},
            chatHelpIcons: false,
            //mainVideoElement: 'local-video',
            otherVideoElements: [],
            videoCallClients: [],
            timer: null,
            mobileBtnsShow: false,
            searchHealthCare: '',
            // questionaryModal: null,
            settedRef: false,
            activeQuestionary: null,
            questionaryAnswers: {},
            questionarySave: false,
            journalNote: false,
            countOFSections: 0,
            textClipboard: '',
            htmlJournal: '',
            openedJournalNotes: [],
            conversationPage: 0,
            openedReferralPreview: [],
            referralSections: { header: '', footer: '', body: '' },
            shareScreenSource: false,
            beforeConversationStartModal: true,
            guestImageVideoInput: false,
            shareModalFields: [
                {
                    input: ''
                },

            ],
            membersHoverState: {},
            libraryIcons: [],
            totalNumberOfConversations: 0,
            addPrevConversations: false,
            videoStartedWithMessagerApp: false,
            startNewClientAnimationColorChange: false,
            videoGatewayLogs: [],
            kbpsError: false
        };
        this.chatWrapRef = React.createRef();

        this.state.min = new Date();
        this.state.min.setHours(0, 0, 0)
        this.state.max = new Date();
        this.state.max.setHours(23, 59, 59)

        this.activeTab = true;

    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { groupId: this.props.selectedGroup, clinic: this.props.selectedClinic })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { groupId: this.props.selectedGroup, clinic: this.props.selectedClinic, /*filter: this.state.healthCareModal ? this.state.searchHealthCare : null*/ })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }
    indexOfIndexOf(arr, str) {
        for (let i = 0; i < arr.length; i++) {
            let idx = arr[i].indexOf(str);
            if (idx !== -1) {
                return i;
            }

        }

        return -1;
    }


    toggleHelpIcons = () => {
        this.setState({
            chatHelpIcons: !this.state.chatHelpIcons
        })
    }

    detectZoom = (ev) => {
        if (!this.state.call) {
            return;
        }

        let zoomFactorX = document.documentElement.clientWidth / window.innerWidth;
        let zoomFactorY = document.documentElement.clientHeight / window.innerHeight;
        let pageHasZoom = !(zoomFactorX === 1 && zoomFactorY === 1);
        if (pageHasZoom) {
            // page is zoomed

            if (zoomFactorX !== this.prevZoomFactorX || zoomFactorY !== this.prevZoomFactorY) {
                // page is zoomed with this event
                //this.generateLayout(true)
            }
        }
        this.prevZoomFactorX = zoomFactorX;
        this.prevZoomFactorY = zoomFactorY;

    }

    handleVisibilityChange = () => {
        let { hidden } = this.getVisibilityAPI();

        if (this.activeTab && document[hidden] && this.state.call && this.state.removeBg && iosDevice()) {
            this.getStream(true);
        }

        if (!this.activeTab && !document[hidden] && this.state.call && this.state.removeBg && this.screenShareTurnedOff) {
            this.getStream(true);
            this.screenShareTurnedOff = false;
        }

        if (this.state.screenSharing && this.state.conversation && this.state.screenSharingUID) {
            this.props.socket.emit('shareScreenStatus', { conversation: this.state.conversation._id, uid: this.state.screenSharingUID, value: this.state.mainVideoElement === this.state.screenSharingUID && !document[hidden] ? true : false });
        }

        this.activeTab = !document[hidden];
        // console.log('activeTab', this.activeTab);
        // document.title = 'activeTab ' + (this.activeTab ? '1' : '0') ;

    }


    getVisibilityAPI = () => {
        // Set the name of the hidden property and the change event for visibility
        let hidden, visibilityChange;
        if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support 
            hidden = "hidden";
            visibilityChange = "visibilitychange";
        } else if (typeof document.msHidden !== "undefined") {
            hidden = "msHidden";
            visibilityChange = "msvisibilitychange";
        } else if (typeof document.webkitHidden !== "undefined") {
            hidden = "webkitHidden";
            visibilityChange = "webkitvisibilitychange";
        }

        return {
            hidden,
            visibilityChange
        }
    }
    loadRaiseHandSound = () => {
        this.raiseHandSoundAudioCtx = new (window.AudioContext || window.webkitAudioContext)();

        let xhr = new XMLHttpRequest();
        xhr.open('GET', '/raise.mp3');
        xhr.responseType = 'arraybuffer';
        xhr.addEventListener('load', () => {

            this.raiseHandSoundAudioCtx.decodeAudioData(xhr.response).then((audioBuffer) => {
                this.raiseHandSoundAudioBuffer = audioBuffer;
            });
        });
        xhr.send();

    }

    loadScreenSharingStartedSound = () => {
        this.screenSharingStartedAudioCtx = new (window.AudioContext || window.webkitAudioContext)();

        let xhr = new XMLHttpRequest();
        xhr.open('GET', '/screen-sharing-started.mp3');
        xhr.responseType = 'arraybuffer';
        xhr.addEventListener('load', () => {

            this.screenSharingStartedAudioCtx.decodeAudioData(xhr.response).then((audioBuffer) => {
                this.screenSharingStartedAudioBuffer = audioBuffer;
            });
        });
        xhr.send();

    }
    loadScreenSharingStoppedSound = () => {
        this.screenSharingStoppedAudioCtx = new (window.AudioContext || window.webkitAudioContext)();

        let xhr = new XMLHttpRequest();
        xhr.open('GET', '/screen-sharing-stopped.mp3');
        xhr.responseType = 'arraybuffer';
        xhr.addEventListener('load', () => {

            this.screenSharingStoppedAudioCtx.decodeAudioData(xhr.response).then((audioBuffer) => {
                this.screenSharingStoppedAudioBuffer = audioBuffer;
            });
        });
        xhr.send();

    }


    playRaiseHandSound = () => {
        if (!this.raiseHandSoundAudioBuffer) {
            return;
        }

        this.raiseHandSoundSource = this.raiseHandSoundAudioCtx.createBufferSource();
        this.raiseHandSoundSource.buffer = this.raiseHandSoundAudioBuffer;
        this.raiseHandSoundSource.connect(this.raiseHandSoundAudioCtx.destination);
        this.raiseHandSoundSource.loop = false;

        if (this.state.audioOutputDevice) {
            this.raiseHandSoundAudioCtx.setSinkId(this.state.audioOutputDevice);
        }

        this.raiseHandSoundSource.start();
    }
    playScreenSharingStartedSound = () => {
        if (!this.screenSharingStartedAudioBuffer) {
            return;
        }

        this.screenSharingStartedSoundSource = this.screenSharingStartedAudioCtx.createBufferSource();
        this.screenSharingStartedSoundSource.buffer = this.screenSharingStartedAudioBuffer;
        this.screenSharingStartedSoundSource.connect(this.screenSharingStartedAudioCtx.destination);
        this.screenSharingStartedSoundSource.loop = false;

        if (this.state.audioOutputDevice) {
            this.screenSharingStartedAudioCtx.setSinkId(this.state.audioOutputDevice);
        }

        this.screenSharingStartedSoundSource.start();
    }
    playScreenSharingStoppedSound = () => {
        if (!this.screenSharingStoppedAudioBuffer) {
            return;
        }

        this.screenSharingStoppedSoundSource = this.screenSharingStoppedAudioCtx.createBufferSource();
        this.screenSharingStoppedSoundSource.buffer = this.screenSharingStoppedAudioBuffer;
        this.screenSharingStoppedSoundSource.connect(this.screenSharingStoppedAudioCtx.destination);
        this.screenSharingStoppedSoundSource.loop = false;

        if (this.state.audioOutputDevice) {
            this.screenSharingStoppedAudioCtx.setSinkId(this.state.audioOutputDevice);
        }

        this.screenSharingStoppedSoundSource.start();
    }


    componentDidMount() {

        this.loadRaiseHandSound()
        this.loadScreenSharingStartedSound()
        this.loadScreenSharingStoppedSound()

        if (this.isFacebookApp() && this.props[0].location.pathname.indexOf('video-call') !== -1) {
            this.setState({
                videoStartedWithMessagerApp: true
            })
            // alert('messager app browser not supported'.translate(this.props.lang))
            return
        }
        this.getDevices();

        document.body.style.overflowX = 'hidden';


        let { visibilityChange, hidden } = this.getVisibilityAPI();

        // Warn if the browser doesn't support addEventListener or the Page Visibility API
        if (typeof document.addEventListener === "undefined" || hidden === undefined) {
            console.log("This demo requires a browser, such as Google Chrome or Firefox, that supports the Page Visibility API.");
        } else {
            // Handle page visibility change   
            document.addEventListener(visibilityChange, this.handleVisibilityChange, false);
        }




        window.addEventListener('resize', this.resizeWindow);

        window.addEventListener("orientationchange", this.orientationChange, false);


        var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        if (isSafari) {
            window.addEventListener("touchmove", this.detectZoom);
        }


        if (this.props[0].location.pathname.indexOf('video-call') !== -1) {
            setTimeout(() => {
                if (!this.state.kbpsError)
                    this.setState({
                        kbpsError: true
                    })
            }, 2000)
            this.calculateKbps();

        }


        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();

        let params = getSearchParams(this.props[0].location.search);

        if (params.roomId) {

            if (params.displayName && params.displayName == 'true') {

                localStorage.removeItem('guestID');
                localStorage.removeItem('guestToken');

            }

            localStorage.setItem('roomId', params.roomId)
            localStorage.removeItem('authToken')
            this.props.initSocketForVideoRoom(params.roomId, () => {
                this.setState({
                    _registerEvents: true
                }, () => {
                    this.registerEvents();
                    this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage.profile : null, conversation: this.props[0].match.params.conversation && this.props[0].location.pathname.indexOf('/video-call') === 0 ? this.props[0].match.params.conversation : null });

                });
            });
        } else {


            if (this.props.uData && this.props.socket && !this.state._registerEvents) {
                this.setState({
                    _registerEvents: true
                }, () => {
                    this.registerEvents();

                    this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage.profile : null, conversation: this.props[0].match.params.conversation && this.props[0].location.pathname.indexOf('/video-call') === 0 ? this.props[0].match.params.conversation : null });

                })
            } else {
                console.log('didm events');
                this.socketInterval = setInterval(() => {
                    if (this.props.uData && this.props.socket && !this.state._registerEvents) {
                        if (this.socketInterval) {
                            clearInterval(this.socketInterval)
                        }
                        console.log('didm events 2');
                        this.setState({
                            _registerEvents: true
                        }, () => {
                            this.registerEvents();

                            this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage.profile : null, conversation: this.props[0].match.params.conversation && this.props[0].location.pathname.indexOf('/video-call') === 0 ? this.props[0].match.params.conversation : null });

                        })
                    }

                }, 150);
            }

        }

        if (this.props[0].location.state && this.props[0].location.state.form == true) {
            this.setState({
                conversationShedule: false,
                form: { timezone: this.props.uData && this.props.uData.timezone ? this.props.uData.timezone : null, scheduledDate: moment(new Date()).format(this.props.dateFormat), scheduledTime: moment(new Date()).format('HH:mm') }
            }, () => {
                this.props[0].history.push({
                    state: { form: null }
                })
            })
        }



        // if (params.roomId)
        //     fetch(API_ENDPOINT + `/users/bankid/v2/start/${this.props.lang == 'en' ? this.props.lang : 'sv'}`, {
        //         method: "GET",
        //         headers: {
        //             "content-type": "application/json",
        //         },
        //     })
        //         .then((res) => res.json())
        //         .then((result) => {

        //             if (result.redirect_url) {
        //                 this.setState({
        //                     bankIdRedirectUrl: result.redirect_url
        //                 })
        //             }
        //         });

        window.addEventListener('message',
            (e) => {
                if (e.data == 'conversation') {
                    this.props[0].history.push(`/video-call/${this.state.conversation._id}`);
                    window.location.reload()
                }
            }

            , false)
    }
    isFacebookApp = () => {
        var ua = navigator.userAgent || navigator.vendor || window.opera;
        return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1);
    }

    getStream = (reopen = false) => {
        if (reopen) {
            try {
                if (this.audioVideoStream) {
                    if (this.localVideo) {
                        this.localVideo.srcObject = null;
                    }

                    this.audioVideoStream.getAudioTracks().forEach(track => track.stop());
                    this.audioVideoStream.getVideoTracks().forEach(track => track.stop());


                    for (let i = 0; i < this.tmpStreams.length; i++) {
                        try {
                            if (this.tmpStreams[i]) {
                                this.tmpStreams[i].getTracks().forEach(track => track.stop());
                                this.tmpStreams[i] = null;
                            }
                        } catch (e) { }
                    }
                    this.audioVideoStream = null;

                }
            } catch (e) {
                console.log(e);
            }
        }

        return new Promise((resolve, reject) => {
            if (this.audioVideoStream) {
                resolve(this.audioVideoStream);
            } else {

                try {
                    if (!this.audioContext) {
                        window.AudioContext = window.AudioContext || window.webkitAudioContext;
                        this.audioContext = new AudioContext();
                    }
                }
                catch (e) {
                    alert('Web Audio API not supported.');
                }

                if (this.audioVideoStream) {


                    if (this.previewVideo) {
                        this.previewVideo.srcObject = null;
                    }

                    //this.audioVideoStream.getTracks().forEach(track => track.stop());
                    if (this.soundMeter)
                        this.soundMeter.stop();
                    if (this.meterRefresh) {
                        clearInterval(this.meterRefresh);
                    }

                    // this.audioVideoStream.getAudioTracks()[0].stop();
                    // this.audioVideoStream.getVideoTracks()[0].stop();
                    this.audioVideoStream.getTracks().forEach(track => track.stop());


                    for (let i = 0; i < this.tmpStreams.length; i++) {
                        try {
                            if (this.tmpStreams[i]) {
                                this.tmpStreams[i].getTracks().forEach(track => track.stop());
                                this.tmpStreams[i] = null;
                            }
                        } catch (e) { }
                    }

                }

                if (this.state.shareOn && this.state.micOff && this.state.cameraOff) {
                    navigator.mediaDevices.getDisplayMedia().then((stream) => {


                        this.screenStream = stream;

                        this.tmpStreams.push(stream);

                        this.audioVideoStream = stream;

                        VideoGateway.replaceStream(this.audioVideoStream);


                        const localVideo = this.localVideo;
                        if (localVideo) {
                            localVideo.autoPlay = true;
                            localVideo.autoplay = true;
                            localVideo.playsInline = true;
                            localVideo.playsinline = true;
                            localVideo.muted = true;
                            localVideo.srcObject = stream;

                        }
                        const previewVideo = this.previewVideo;
                        if (previewVideo) {
                            previewVideo.srcObject = stream;
                            previewVideo.autoplay = true;
                            previewVideo.playsInline = true;
                            previewVideo.muted = true;
                        }




                        resolve(stream);


                    })

                    return;
                }

                let resolutionDict = {
                    '480': {
                        width: 640,
                        height: 480
                    },
                    '720': {
                        width: 1280,
                        height: 720
                    },
                    '1080': {
                        width: 1920,
                        height: 1080
                    },
                }
                if (!this.state.fullHDSupport) {
                    resolutionDict['1080'] = {
                        width: 1280,
                        height: 720
                    }
                }

                let resolution = resolutionDict[this.state.videoResolution];


                if (this.state.removeBg) {
                    this.getStreamWithBackground(async (stream, orgStream) => {

                        console.warn('-------------------', stream);

                        stream.addTrack(orgStream.getAudioTracks()[0]);
                        if (this.state.shareOn) {
                            await this.getScreenStream();
                        }

                        if (this.state.cameraOff) {
                            stream.getVideoTracks().forEach(track => track.enabled = false);

                        }

                        if (this.state.micOff && stream && stream.getAudioTracks) {
                            stream.getAudioTracks().forEach(track => track.enabled = false);
                        }

                        this.tmpStreams.push(stream);

                        this.audioVideoStream = stream;

                        if (this.state.micOff && this.audioVideoStream && this.audioVideoStream.getAudioTracks) {
                            this.audioVideoStream.getAudioTracks().forEach(track => track.enabled = false);
                        }


                        if (this.state.micOff) {
                            setTimeout(() => {
                                if (this.state.micOff && this.audioVideoStream && this.audioVideoStream.getAudioTracks) {
                                    this.audioVideoStream.getAudioTracks().forEach(track => track.enabled = false);
                                }

                            }, 100);
                        } else {
                            setTimeout(() => {
                                if (!this.state.micOff && this.audioVideoStream && this.audioVideoStream.getAudioTracks) {
                                    this.audioVideoStream.getAudioTracks().forEach(track => track.enabled = true);
                                }

                            }, 100);
                        }

                        VideoGateway.replaceStream(this.audioVideoStream, this.state.shareOn);
                        if (this.state.micOff) {
                            if (this.audioVideoStream && this.audioVideoStream.getAudioTracks)
                                this.audioVideoStream.getAudioTracks().forEach(track => track.enabled = false);
                        } else {
                            if (this.audioVideoStream && this.audioVideoStream.getAudioTracks)
                                this.audioVideoStream.getAudioTracks().forEach(track => track.enabled = true);
                        }

                        setTimeout(() => {
                            if (this.state.micOff) {
                                if (this.audioVideoStream && this.audioVideoStream.getAudioTracks)
                                    this.audioVideoStream.getAudioTracks().forEach(track => track.enabled = false);
                            } else {
                                if (this.audioVideoStream && this.audioVideoStream.getAudioTracks)
                                    this.audioVideoStream.getAudioTracks().forEach(track => track.enabled = true);
                            }

                        }, 300);


                        const localVideo = this.localVideo;
                        if (localVideo) {
                            localVideo.autoPlay = true;
                            localVideo.autoplay = true;
                            localVideo.playsInline = true;
                            localVideo.playsinline = true;
                            localVideo.muted = true;

                            localVideo.srcObject = stream;

                        }
                        const previewVideo = this.previewVideo;
                        if (previewVideo) {
                            previewVideo.srcObject = stream;
                            previewVideo.autoplay = true;
                            previewVideo.playsInline = true;
                            previewVideo.muted = true;
                        }

                        this.soundMeter = new SoundMeter(this.audioContext);
                        this.soundMeter.connectToSource(stream, (e) => {
                            if (e) {
                                alert(e);
                                return;
                            }
                            this.meterRefresh = setInterval(() => {
                                this.setState({
                                    micMeterValue: this.soundMeter.instant.toFixed(2)
                                })
                            }, 200);
                        });


                        resolve(this.audioVideoStream);
                    });

                    return;
                }

                navigator.mediaDevices.getUserMedia(
                    { video: this.state.videoInputDevice ? { deviceId: this.state.videoInputDevice, width: { ideal: resolution.width }, height: { ideal: resolution.height } } : this.state.camera === true ? { facingMode: { exact: "environment" }, width: { ideal: resolution.width }, height: { ideal: resolution.height } } : this.state.camera === false ? { facingMode: "user", width: { ideal: resolution.width }, height: { ideal: resolution.height } } : { width: { ideal: resolution.width }, height: { ideal: resolution.height } }, audio: this.state.audioInputDevice ? { deviceId: this.state.audioInputDevice } : true }).then(
                        async stream => {


                            if (this.state.shareOn) {
                                await this.getScreenStream();
                            }

                            if (this.state.cameraOff) {
                                stream.getVideoTracks().forEach(track => track.enabled = false);

                            }

                            if (this.state.micOff) {
                                stream.getAudioTracks().forEach(track => track.enabled = false);
                            }

                            this.tmpStreams.push(stream);

                            this.audioVideoStream = stream;

                            if (this.state.micOff) {
                                this.audioVideoStream.getAudioTracks().forEach(track => track.enabled = false);
                            }


                            if (this.state.micOff) {
                                setTimeout(() => {
                                    if (this.state.micOff) {
                                        this.audioVideoStream.getAudioTracks().forEach(track => track.enabled = false);
                                    }

                                }, 100);
                            } else {
                                setTimeout(() => {
                                    if (!this.state.micOff) {
                                        this.audioVideoStream.getAudioTracks().forEach(track => track.enabled = true);
                                    }

                                }, 100);
                            }

                            VideoGateway.replaceStream(this.audioVideoStream, this.state.shareOn);

                            if (this.state.micOff) {
                                this.audioVideoStream.getAudioTracks().forEach(track => track.enabled = false);
                            } else {
                                this.audioVideoStream.getAudioTracks().forEach(track => track.enabled = true);
                            }

                            setTimeout(() => {
                                if (this.state.micOff) {
                                    this.audioVideoStream.getAudioTracks().forEach(track => track.enabled = false);
                                } else {
                                    this.audioVideoStream.getAudioTracks().forEach(track => track.enabled = true);
                                }

                            }, 300);

                            const localVideo = this.localVideo;
                            if (localVideo) {
                                localVideo.autoPlay = true;
                                localVideo.autoplay = true;
                                localVideo.playsInline = true;
                                localVideo.playsinline = true;
                                localVideo.muted = true;

                                localVideo.srcObject = stream;

                            }
                            const previewVideo = this.previewVideo;
                            if (previewVideo) {
                                previewVideo.srcObject = stream;
                                previewVideo.autoplay = true;
                                previewVideo.playsInline = true;
                                previewVideo.muted = true;
                            }

                            this.soundMeter = new SoundMeter(this.audioContext);
                            this.soundMeter.connectToSource(stream, (e) => {
                                if (e) {
                                    alert(e);
                                    return;
                                }
                                this.meterRefresh = setInterval(() => {
                                    this.setState({
                                        micMeterValue: this.soundMeter.instant.toFixed(2)
                                    })
                                }, 200);
                            });


                            resolve(stream);
                        }
                    ).catch((err) => { console.log(err); });
            }
        })
    }

    formatGmt = (dateStr, targetTimezone) => {
        let year = Number(dateStr.split('-')[0]);
        let month = Number(dateStr.split('-')[1]);
        let day = Number(dateStr.split('-')[2].split(' ')[0]);

        let hour = Number(dateStr.split(' ')[1].split(':')[0]);
        let minutes = Number(dateStr.split(':')[1]);

        let date = new Date(Date.UTC(year, month, day, hour, minutes, 0, 0));
        // console.log('UTC Date: ', date)
        // console.log(year, month, day, hour, minutes, targetTimezone)
        let tzDateTarget = new Date(date.toLocaleString('en-US', { timeZone: targetTimezone }));
        console.log(tzDateTarget);
        let gmt = tzDateTarget.toString().substring(25, 31);
        console.log(gmt);
        return gmt;
    }


    addEvent = (dataa, type) => {
        console.log(dataa)
        let data = this.state.conversations.filter(item => item._id == dataa.conversation)[0]

        let duration = '00:30:00';
        let startDate = moment.unix(new Date(data.scheduledDate + ' ' + data.scheduledTime).getTime() / 1000);
        let msDuration = (Number(duration.split(':')[0]) * 60 * 60 + Number(duration.split(':')[1]) * 60 + Number(duration.split(':')[2])) * 1000;
        let endDate = new Date(startDate + msDuration);
        let isoStartDate = new Date(startDate - new Date().getTimezoneOffset() * 60 * 1000).toISOString().split(".")[0];
        let isoEndDate = new Date(endDate.getTime() - (new Date().getTimezoneOffset(data.timezone)) * 60 * 1000).toISOString().split(".")[0];
        let recuringDateUntil;
        // console.log(isoStartDate + ' ' + isoEndDate)
        let recurring = data.recurringObj
        let frequency;
        let frequencyInterval;
        let frequencyDayOfWeek = [];

        if (recurring && recurring.frequencyType && recurring.frequencyType == 'day') {
            let endDateRecuring = moment.unix(new Date(recurring.frequencyDayScheduledDateTo).getTime() / 1000);
            recuringDateUntil = new Date(endDateRecuring - new Date().getTimezoneOffset() * 60 * 1000).toISOString().replace(/-|:|\./g, '').replace('000', '');
            frequency = 'DAILY' + ';UNTIL=' + recuringDateUntil + ';INTERVAL=' + recurring.frequencyDayText

        } else if (recurring && recurring.frequencyType && recurring.frequencyType == 'week') {

            let endDateRecuring = moment.unix(new Date(recurring.frequencyWeekScheduledDateTo).getTime() / 1000);
            recuringDateUntil = new Date(endDateRecuring - new Date().getTimezoneOffset() * 60 * 1000).toISOString().replace(/-|:|\./g, '').replace('000', '');

            let arr = []
            let count = 0
            frequencyDayOfWeek = ';BYDAY='
            if (recurring.checkedMonday) {
                count++
                arr.push('MO')
            }
            if (recurring.checkedTuesday) {
                count++
                arr.push('TU')
            }
            if (recurring.checkedWednesday) {
                count++
                arr.push('WE')
            }
            if (recurring.checkedThursday) {
                count++
                arr.push('TH')
            }
            if (recurring.checkedFriday) {
                count++
                arr.push('FR')
            }
            if (recurring.checkedSaturday) {
                count++
                arr.push('SA')
            }
            if (recurring.checkedSunday) {
                count++
                arr.push('SU')
            }

            frequencyDayOfWeek += arr.join(',')
            // console.log(frequencyDayOfWeek)

            if (frequencyDayOfWeek) {
                frequencyInterval = ';INTERVAL=1' //+ count
            }

            let countt = ';COUNT=' + count

            frequency = 'WEEKLY' + frequencyDayOfWeek + ';UNTIL=' + recuringDateUntil
            // console.log(frequency)

        } else if (recurring && recurring.frequencyType && recurring.frequencyType == 'month') {
            let endDateRecuring = moment.unix(new Date(recurring.frequencyMonthScheduledDateTo).getTime() / 1000);
            recuringDateUntil = new Date(endDateRecuring - new Date().getTimezoneOffset() * 60 * 1000).toISOString().replace(/-|:|\./g, '').replace('000', '');
            frequency = 'MONTHLY' + ';BYMONTHDAY=' + recurring.frequencyMonthScheduled + ';UNTIL=' + recuringDateUntil
        } else {
            frequency = 'DAILY;COUNT=1'
        }


        let gmt = this.formatGmt(data.scheduledDate + ' ' + data.scheduledTime, data.timezone)
        console.log(gmt);

        this.signInGoogle()
            .then((gapi) => {
                let passcode = data.passcode ? 'Passcode:' + data.passcode : ''
                var event = {
                    'summary': `${data.name}`,
                    'location': `${window.location.origin}/video-call/${data._id}?roomId=${data.roomId}`,
                    'description': `You have been invited to a video call ${data.name}\n\n The video call is scheduled for ${data.scheduledDate} at:${data.scheduledTime} ${gmt}\n\nClick on the link below and follow the on-screen instructions to join the video call.\n\n<a href='${window.location.origin}/video-call/${data._id}?roomId=${data.roomId}'>${window.location.origin}/video-call/${data._id}?roomId=${data.roomId}</a>\n\n ${passcode}`,
                    'start': {
                        'dateTime': isoStartDate,
                        'timeZone': `${data.timezone}`
                    },
                    'end': {
                        'dateTime': isoEndDate,
                        'timeZone': `${data.timezone}`
                    },
                    'recurrence': [
                        `RRULE:FREQ=${frequency}`
                    ],
                    'attendees': [
                        { 'email': `${this.props.uData.email}` },
                    ],
                    'reminders': {
                        'useDefault': false,
                        'overrides': [
                            { 'method': 'email', 'minutes': 24 * 60 },
                            { 'method': 'popup', 'minutes': 10 }
                        ]
                    }
                }

                var request = gapi.client.calendar.events.insert({
                    'calendarId': 'primary',
                    // 'eventId': `${data.conversationID}`,
                    'resource': event,
                })

                request.execute(event => {
                    console.log(event)
                    let obj = {
                        conversationID: data.conversationID,
                        eventGmail: event.id
                    }
                    fetch(API_ENDPOINT + "/gmail/add/conversation/" + data._id, {
                        method: "POST",
                        headers: {
                            "content-type": "application/json",
                            Authorization: typeof localStorage !== "undefined" ? `Bearer ${localStorage.getItem('authToken') ? localStorage.getItem("authToken") : localStorage.getItem('guestToken')}` : null,
                        },
                        body: JSON.stringify(obj)
                    }).then((res) => res.json()).then((result) => {
                        if (result && result.error) {
                            this.setState({
                                error: result.error
                            })
                        }
                        this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage.profile : null, conversation: this.props[0].match.params.conversation && this.props[0].location.pathname.indexOf('/video-call') === 0 ? this.props[0].match.params.conversation : null });
                    });
                    let htmllink = event.htmlLink.split('=')
                    // console.log(htmllink)
                    // console.log('https://calendar.google.com/calendar/u/0/r/eventedit/'+htmllink[1]+'?pli=1')
                    window.open('https://calendar.google.com/calendar/u/0/r/eventedit/' + htmllink[1])
                    // window.open(event.htmlLink)
                })

                this.setState({
                    calendarConnectModal: false
                })

            })

    }

    connectWithOutlook = (data) => {
        fetch(API_ENDPOINT + "/outlook/signin", {
            method: "POST",
            headers: {
                "content-type": "application/json",
                Authorization: typeof localStorage !== "undefined" ? `Bearer ${localStorage.getItem('authToken') ? localStorage.getItem("authToken") : localStorage.getItem('guestToken')}` : null,
            },
            body: JSON.stringify({
                conversationID: data.conversation,
                update: data.update
            })
        }).then((res) => res.json()).then((result) => {
            if (result && result.error) {
                this.setState({
                    error: result.error
                })
            } else {

                this.setState({
                    calendarConnectModal: false
                })

                window.location.href = `${result.url}`;
                // '_blank'

            }
        });
    }

    getScreenStream = async () => {
        if (this.screenStream) {
            return this.screenStream;
        } else {
            this.screenStream = await navigator.mediaDevices.getDisplayMedia();
            const localVideo = this.localVideo;
            if (localVideo) {
                localVideo.autoPlay = true;
                localVideo.autoplay = true;
                localVideo.playsInline = true;
                localVideo.playsinline = true;
                localVideo.muted = true;

                localVideo.srcObject = this.screenStream;

            }
            return this.screenStream;
        }
    }


    shareScreenCallback = () => {
        return new Promise((resolve, reject) => {
            this.shareScreen(() => {
                resolve();
            })
        })
    }

    shareScreen = async (callback) => {

        // let stateObj = {
        //     shareOn: !this.state.shareOn,
        //     screenSharing: !this.state.shareOn
        // }

        // if (!this.state.shareOn) {
        //     stateObj.layoutOutput = '-1';
        //     stateObj.oldLayoutOutput = this.state.layoutOutput;
        // } else {
        //     if (this.state.oldLayoutOutput) {
        //         stateObj.layoutOutput = this.state.oldLayoutOutput;
        //         stateObj.oldLayoutOutput = null;
        //     }
        // }

        this.setState({
            shareOn: !this.state.shareOn,
            screenSharing: !this.state.shareOn,
            shareScreenStatus: {}
        }, async () => {
            if (this.state.shareOn) {


                await this.getScreenStream();
                this.screenStream.getVideoTracks()[0].onended = () => {
                    this.shareScreen();
                    this.screenShareTurnedOff = true;
                }

                try {
                    if (screen.availWidth - window.innerWidth === 0 || !window.opener) {
                        this.setState({
                            resizeWindowAfter: true
                        })
                    } else {
                        window.resizeTo(200, window.innerHeight / 2);
                        this.setState({
                            showScreenShareMode: true,
                            windowDimensions: {
                                width: 200,
                                height: window.innerHeight
                            }
                        })

                    }
                } catch (e) {
                    console.log(e);
                }


                try {
                    if (callback) {
                        callback();
                    }
                } catch (e) {
                    console.log(e);
                }
                await VideoGateway.replaceVideoStream(this.screenStream, true);


                this.props.socket.emit('shareScreen', { conversation: this.state.conversation._id, value: true });

            } else {
                if (this.screenStream)
                    this.screenStream.getVideoTracks()[0].onended = null;


                if (this.state.windowDimensions) {
                    window.resizeTo(this.state.windowDimensions.width < 400 ? 800 : this.state.windowDimensions.width, this.state.windowDimensions.height < 600 ? 600 : this.state.windowDimensions.height);
                    this.setState({
                        windowDimensions: null
                    })
                }

                this.setState({
                    showScreenShareMode: false
                })


                await this.getStream(true);
                await VideoGateway.replaceVideoStream(this.audioVideoStream, !this.state.cameraOff);

                if (this.screenStream) {
                    this.screenStream.getTracks().forEach(track => track.stop());
                    this.screenStream = null;
                }

                const localVideo = this.localVideo;
                if (localVideo) {
                    localVideo.autoPlay = true;
                    localVideo.autoplay = true;
                    localVideo.playsInline = true;
                    localVideo.playsinline = true;
                    localVideo.muted = true;

                    localVideo.srcObject = this.audioVideoStream;

                }



                this.props.socket.emit('shareScreen', { conversation: this.state.conversation._id, value: false });
                if (this.state.cameraOff) {
                    this.props.socket.emit('cameraStatus', { conversation: this.state.conversation._id, value: false });
                }

            }

            this.generateLayout(true);
            setTimeout(() => {
                this.generateLayout(true);
            }, 2000);
        })
    }
    addToReferralPreview = () => {

        if (this.state.conversation && this.state.conversation._id) {
            let openedReferralPreview = this.state.openedReferralPreview;
            if (openedReferralPreview.indexOf(this.state.conversation._id) == -1) {
                openedReferralPreview.push(this.state.conversation._id);
                this.setState({ openedReferralPreview })
            }

        }
    }
    removeFromReferralPreview = () => {
        if (this.state.conversation && this.state.conversation._id) {
            let openedReferralPreview = this.state.openedReferralPreview;
            let index = openedReferralPreview.indexOf(this.state.conversation._id);

            if (index != -1) {
                openedReferralPreview.splice(index, 1);
                this.setState({ openedReferralPreview })
            }

        }
    }

    flipCamera = () => {
        this.setState({
            camera: !this.state.camera
        }, async () => {
            if (this.state.camera) {
                this.setState({ videoInputDevice: null });
            }

            let stream = await this.getStream(true);

        })


    }

    toggleCamera = () => {
        this.setState({
            cameraOff: !this.state.cameraOff
        }, async () => {
            if (this._reopenStream && !this.state.cameraOff) {
                await this.getStream(true);
            }

            this.props.socket.emit('cameraStatus', { conversation: this.state.conversation._id, value: !this.state.cameraOff })

            if (this.audioVideoStream) {
                this.audioVideoStream.getVideoTracks().forEach(track => track.enabled = !this.state.cameraOff);
            }
        })
    }

    toggleMic = () => {
        if (this.state.mutedByHost) {
            return;
        }


        this.setState({
            micOff: !this.state.micOff
        }, async () => {
            this.props.socket.emit('micStatus', { conversation: this.state.conversation._id, value: !this.state.micOff })

            if (this.audioVideoStream) {
                this.audioVideoStream.getAudioTracks().forEach(track => track.enabled = !this.state.micOff);
            }
        })
    }


    muteParticipant = (value) => {
        this.setState({
            micOff: value
        }, async () => {
            this.props.socket.emit('micStatus', { conversation: this.state.conversation._id, value: !this.state.micOff })

            if (this.audioVideoStream) {
                this.audioVideoStream.getAudioTracks().forEach(track => track.enabled = !this.state.micOff);
            }
        })
    }


    removePeer = (uuid) => {

        if (this.peerStreams[uuid]) {

            if (this.remoteVideo && this.remoteVideo[uuid] && this.remoteVideo[uuid].srcObject) {
                this.remoteVideo[uuid].srcObject.getTracks().forEach(track => track.stop());
                this.remoteVideo[uuid].srcObject = null;

            }

            this.peerStreams[uuid] = null;
        }

        delete this.peerStreams[uuid];


        let videoElements = this.state.videoElements;
        if (videoElements.indexOf(uuid) !== -1)
            videoElements.splice(videoElements.indexOf(uuid), 1);


        if (this.state.pinnedVideo === uuid) {
            this.setState({
                pinnedVideo: null
            }, () => {
                this.generateLayout(true);
            })
        }


        this.setState({
            videoElements
        }, () => {
            this.generateLayout(true);

            setTimeout(() => {
                this.forceUpdate();
            }, 500)
        })
    }

    call = async () => {

        this.setState({
            call: true
        }, async () => {
            await this.getStream(true);
            if (this.state.shareScreenSource) {
                await this.shareScreenCallback();
            }


            if (!this.logoutResetInterval) {
                this.logoutResetInterval = setInterval(() => {
                    this.props.resetLogoutInterval();
                }, 10000);
            }

            setTimeout(() => {
                this.generateLayout(true);

            }, 500);


            this.setState({ videoStartDateTime: new Date().toISOString(), conversation: { ...this.state.conversation, initiatorUid: this.props.uData._id, initiator: this.props.socket.id }, })
            this.props.socket.emit('call', {
                conversation: this.state.conversation._id,
                videoStartDateTime: new Date().toISOString(),
                browser: browserInfo(),
            })

        })


    }

    endCall = (emit = true, disableClientCountCheck = false, disableInitiatorCheck = false, hostEndCallEmit = true) => {
        if (this.state.conversation && this.state.conversation.mandatoryScreenName) {
            localStorage.removeItem('guestID');
            localStorage.removeItem('guestToken');
        }

        if (this.mediaRecorder && this.state.recording) {
            this.mediaRecorder.stop();
        }
        if (this.props.uData && this.state.conversation && this.state.conversation.members && (this.state.conversation.members[0] === this.props.uData._id || (this.state.conversation.cohosts && this.state.conversation.cohosts.indexOf(this.props.uData._id) !== -1))) {
            if ((!emit && hostEndCallEmit) && this.state.call) {
                this.setState({
                    _hostEndCall: true
                })
            } else {
                try {
                    setTimeout(() => {
                        window.open('', '_self').close();

                    }, 1000);
                } catch (e) {

                }


                if (window && window.top)
                    window.top.postMessage('end-video-call', '*')

            }
        } else {
            if (this.state.call) {
                this.setState({
                    _endCall: {}
                })
            }
        }

        if (this.timerHandler)
            clearInterval(this.timerHandler);

        if (this.logoutResetInterval)
            clearInterval(this.logoutResetInterval);


        this.setState({
            //timer: null,
            offerData: null,
            hands: {},
            _devicesPrepared: null,
            hand: false

        },
            () => {


                if (this.audioIncomingCall) {
                    this.audioIncomingCall.pause();
                    this.audioIncomingCall = null;
                }

                if (emit) {
                    this.props.socket.emit('endCall', {
                        conversation: this.state.conversation._id,
                        disableClientCountCheck: disableClientCountCheck,
                        disableInitiatorCheck: disableInitiatorCheck
                    })
                }


                for (var uuid in this.peerStreams) {
                    if (this.peerStreams[uuid]) {
                        if (this.remoteVideo && this.remoteVideo[uuid] && this.remoteVideo[uuid].srcObject) {
                            this.remoteVideo[uuid].srcObject.getTracks().forEach(track => track.stop());
                            this.remoteVideo[uuid].srcObject = null;
                        }

                        delete this.peerStreams[uuid];
                    }
                }

                if (this.audioVideoStream) {
                    if (this.localVideo) {
                        this.localVideo.srcObject = null;
                    }

                    this.audioVideoStream.getAudioTracks().forEach(track => track.stop());
                    this.audioVideoStream.getVideoTracks().forEach(track => track.stop());

                    this.audioVideoStream = null;

                    for (let i = 0; i < this.tmpStreams.length; i++) {
                        try {
                            if (this.tmpStreams[i]) {
                                this.tmpStreams[i].getTracks().forEach(track => track.stop());
                                this.tmpStreams[i] = null;
                            }
                        } catch (e) { }
                    }


                }

                this.setState({
                    videoElements: [],
                    call: null,
                    soundAllowed: null
                })

                try {
                    VideoGateway.clearState();
                } catch (e) {
                }
            })

        localStorage.removeItem('videoCallPeers');
        localStorage.removeItem('oldSocketId');
    }

    answer = async () => {
        let initiator = this.state.offerData.initiator;
        if (this.audioIncomingCall) {
            this.audioIncomingCall.pause();
            this.audioIncomingCall = null;
        }

        this.setState({
            call: true,
            offerData: null
        }, async () => {
            if (!this.logoutResetInterval) {
                this.logoutResetInterval = setInterval(() => {
                    this.props.resetLogoutInterval();
                }, 10000);
            }



            this.props.socket.emit('answer', {
                conversation: this.state.conversation._id,
                initiator: initiator,


                browser: browserInfo(),
            })
        });
    }


    configureStreamAndAnswer = (videoInputDevice, audioInputDevice, audioOutputDevice, cameraOff, micOff, shareScreenSource, videoResolution, removeBg, backgroundVideoImage, fullHDSupport) => {

        let params = getSearchParams(this.props[0].location.search, {});
        let videoCallConfig = this.state.prepareVideoCall;

        this.setState({
            videoInputDevice,
            audioInputDevice,
            audioOutputDevice,
            cameraOff,
            micOff,
            shareScreenSource: shareScreenSource,
            prepareVideoCall: null,
            videoResolution,
            _devicesPrepared: videoCallConfig,
            removeBg,
            backgroundVideoImage,
            fullHDSupport
        }, async () => {

            if (!videoCallConfig.initiator && (this.state.conversation && !this.state.conversation.activeCall)) {
                this.call();
            } else {
                this.setState({
                    call: true,
                    offerData: null,
                    peers: {},
                    videoElements: [],
                    hands: {},
                    hand: false,
                }, async () => {
                    if (!this.logoutResetInterval) {
                        this.logoutResetInterval = setInterval(() => {
                            this.props.resetLogoutInterval();
                        }, 10000);
                    }


                    if (iosDevice() && this.state.removeBg) {
                        // setTimeout(async () => {
                        //     await this.getStream(true);
                        // }, 2000);
                    } else {
                        await this.getStream(true);

                        if (this.state.shareScreenSource) {
                            await this.shareScreenCallback();
                        }

                    }
                    this.props.socket.emit('answer', {
                        conversation: videoCallConfig.conversation,
                        initiator: videoCallConfig.initiator,
                        initiatorRejoin: this.props.uData && this.state.conversation && this.state.conversation.activeCall && (this.state.conversation.initiatorUid === this.props.uData._id),
                        guestName: params.roomId ? this.state.guestName : null,
                        realName: params.roomId ? this.state.realName : null,

                        browser: browserInfo()
                    });

                    if (this.props.uData && this.state.conversation && this.state.conversation.activeCall && (this.state.conversation.initiatorUid === this.props.uData._id)) {
                        if (localStorage.videoCallClients) {
                            this.setState({
                                videoCallClients: JSON.parse(localStorage.videoCallClients)
                            })
                        }
                    }

                    setTimeout(() => {
                        this.generateLayout(true);

                        let cameraOffValue = this.state.cameraOff;
                        this.setState({
                            cameraOff: !cameraOffValue
                        }, () => {
                            setTimeout(() => {
                                this.setState({
                                    cameraOff: cameraOffValue
                                });
                            }, 100)
                        })

                        // if (iosDevice()) {
                        //     setTimeout(() => {

                        //         if (this.audioVideoStream) {
                        //             this.audioVideoStream.getVideoTracks().forEach(track => track.enabled = !this.state.cameraOff);
                        //         }

                        //         this.generateLayout(true);
                        //         this.forceUpdate();

                        //     }, 3000); 
                        // }

                    }, 1000);

                });
            }
        })

    }


    getBlankStream = () => {
        return new Promise((resolve, reject) => {
            if (!this.blankVideoRef) {
                resolve();
                return;
            }
            this.blankVideoRef.play();

            this.blankStream = this.blankVideoRef.captureStream();
            this.blankStream.onaddtrack = () => {
                resolve();
            };

            setTimeout(() => {
                resolve();
            }, 3000);

        })
    }



    drawVideoFramesOnCanvas = () => {
        if (!this.state.call && !this.state.recording) {
            return;
        }

        const canvas = this.recordingCanvas;
        const context = canvas.getContext('2d');

        const scaleWidth = canvas.width / window.innerWidth;
        canvas.height = window.innerHeight * scaleWidth;
        const scaleHeight = canvas.height / window.innerHeight;
        context.clearRect(0, 0, canvas.width, canvas.height);

        let layoutOutput = this.state.layoutOutput;
        if (this.state.layoutOutput == 2 && this.state.videoElements.length <= 1 && (typeof window !== 'undefined' && !(window.innerWidth >= 700 && window.innerWidth <= 1200))) {
            layoutOutput = -1;
        }

        for (var uid in this.remoteVideo) {
            if (!this.remoteVideo[uid]) {
                continue;
            }


            let user = this.state.conversation.users.filter(member => member.uid == uid);
            if (user.length) {
                user = user[0];
            } else {
                if (this.state.guestNames[uid]) {
                    user = {
                        uid: uid,
                        name: this.state.guestNames[uid],
                        bankIdVerified: this.state.guestsVerificationStatus ? this.state.guestsVerificationStatus[uid] : null
                    }
                } else {
                    user = null;
                }
            }

            if (user && user.uid) {
                if (this.state.guestNames[user.uid]) {
                    user.name = this.state.guestNames[user.uid];
                    user.bankIdVerified = this.state.guestsVerificationStatus ? this.state.guestsVerificationStatus[user.uid] : null;
                }
            }



            let videoPosition = null;

            if (this.remoteVideo[uid] && this.callArea && layoutOutput != 2) {
                let numberOfColumnsOnScreen = typeof window != 'undefined' && window.innerWidth < 768 && window.innerWidth < window.innerHeight ? 3 : 14;



                let videoElementHeight = (this.callArea && this.callArea.offsetWidth) / numberOfColumnsOnScreen;
                videoPosition = getObjectFitSize(true, this.callArea.offsetWidth, (this.callArea.offsetHeight - 80 - videoElementHeight), this.remoteVideo[uid].videoWidth, this.remoteVideo[uid].videoHeight);
            }

            let clientRect = this.remoteVideo[uid].getBoundingClientRect();


            if (this.state.shareOn && this.state.mainVideoElement == uid && this.localVideo) {
                clientRect = this.localVideo.getBoundingClientRect();
            }

            if (!videoPosition)
                drawImageProp(context, this.remoteVideo[uid], clientRect.left * scaleWidth, clientRect.top * scaleHeight, clientRect.width * scaleWidth, clientRect.height * scaleHeight)
            else {
                if (this.state.mainVideoElement == uid && !this.state.shareOn)
                    context.drawImage(this.remoteVideo[uid], videoPosition.x * scaleWidth, videoPosition.y * scaleHeight, videoPosition.width * scaleWidth, videoPosition.height * scaleHeight);
                else
                    drawImageProp(context, this.remoteVideo[uid], clientRect.left * scaleWidth, clientRect.top * scaleHeight, clientRect.width * scaleWidth, clientRect.height * scaleHeight)
            }








            const textInfo = context.measureText(user.name ?
                (user.name + (user.bankIdVerified === true ? ('(' + 'BankID verified'.translate(this.props.lang) + ')') : '')).length > 7 ?
                    (user.name + (user.bankIdVerified === true ? ('(' + 'BankID verified'.translate(this.props.lang) + ')') : '')).substring(0, 7) + '...'
                    : (user.name + (user.bankIdVerified === true ? ('(' + 'BankID verified'.translate(this.props.lang) + ')') : ''))
                : '');
            const height = Number(context.font.match(/\d+/).pop()) || 10;
            const width = textInfo.width;
            context.fillStyle = 'rgba(0, 0, 0, 0.30)';
            context.roundRect(clientRect.left * scaleWidth + 10, clientRect.top * scaleHeight + 10, width + 10, height + 10, 6).fill();
            context.fillStyle = '#fff';
            context.textBaseline = "top";
            context.fillText(user.name ?
                (user.name + (user.bankIdVerified === true ? ('(' + 'BankID verified'.translate(this.props.lang) + ')') : '')).length > 7 ?
                    (user.name + (user.bankIdVerified === true ? ('(' + 'BankID verified'.translate(this.props.lang) + ')') : '')).substring(0, 7) + '...'
                    : (user.name + (user.bankIdVerified === true ? ('(' + 'BankID verified'.translate(this.props.lang) + ')') : ''))
                : '', clientRect.left * scaleWidth + 15, clientRect.top * scaleHeight + 15);


        }

        if (this.localVideo) {
            let clientRect = this.localVideo.getBoundingClientRect();
            let localVideoPosition = null;

            if (this.localVideo && this.callArea && layoutOutput != 2) {
                let numberOfColumnsOnScreen = typeof window != 'undefined' && window.innerWidth < 768 && window.innerWidth < window.innerHeight ? 3 : 14;

                let videoElementHeight = (this.callArea && this.callArea.offsetWidth) / numberOfColumnsOnScreen;
                localVideoPosition = getObjectFitSize(true, this.callArea.offsetWidth, (this.callArea.offsetHeight - 80 - videoElementHeight), this.localVideo.videoWidth, this.localVideo.videoHeight);
            }

            if (this.state.shareOn && this.state.mainVideoElement != 'local-video' && this.remoteVideo[this.state.mainVideoElement]) {
                clientRect = this.remoteVideo[this.state.mainVideoElement].getBoundingClientRect();
            }

            if (!localVideoPosition)
                drawImageProp(context, this.localVideo, clientRect.left * scaleWidth, clientRect.top * scaleHeight, clientRect.width * scaleWidth, clientRect.height * scaleHeight)
            else {
                if (this.mainVideoElement == 'local-video' || this.state.shareOn)
                    context.drawImage(this.localVideo, localVideoPosition.x * scaleWidth, localVideoPosition.y * scaleHeight, localVideoPosition.width * scaleWidth, localVideoPosition.height * scaleHeight);
                else
                    drawImageProp(context, this.localVideo, clientRect.left * scaleWidth, clientRect.top * scaleHeight, clientRect.width * scaleWidth, clientRect.height * scaleHeight)
            }




            const textInfo = context.measureText(this.props.uData ? this.props.uData.name && this.props.uData.name.length > 7 ? this.props.uData.name.substring(0, 7) + '...' : this.props.uData.name : this.state.guestName && this.state.guestName.length > 7 ? this.state.guestName.substring(0, 7) + '...' : this.state.guestName);
            const height = Number(context.font.match(/\d+/).pop()) || 10;
            console.log(height + 2);
            const width = textInfo.width;
            context.fillStyle = 'rgba(0, 0, 0, 0.30)';
            context.roundRect(clientRect.left * scaleWidth + 10, clientRect.top * scaleHeight + 10, width + 10, height + 10, 6).fill();
            context.fillStyle = '#fff';
            context.textBaseline = "top";
            context.fillText(this.props.uData ? this.props.uData.name && this.props.uData.name.length > 7 ? this.props.uData.name.substring(0, 7) + '...' : this.props.uData.name : this.state.guestName && this.state.guestName.length > 7 ? this.state.guestName.substring(0, 7) + '...' : this.state.guestName, clientRect.left * scaleWidth + 15, clientRect.top * scaleHeight + 15);

        }

        window.requestAnimationFrame(this.drawVideoFramesOnCanvas);

    }



    handleRecording = () => {

        if (!(((!((this.props.uData && this.props.uData._id === this.state.conversation.members[0]) || (this.props.uData && this.state.conversation.cohosts && this.state.conversation.cohosts.indexOf(this.props.uData._id) !== -1)) || !this.props.uData) && this.state.conversation && this.state.conversation.recordConversationAsVideoMembers) || (((this.props.uData && this.props.uData._id === this.state.conversation.members[0]) || (this.props.uData && this.state.conversation.cohosts && this.state.conversation.cohosts.indexOf(this.props.uData._id) !== -1)) && this.checkSettingsForHostVideoRecord()))) {
            return;
        }

        var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

        if (isSafari || isFirefox) {
            return;
        }

        this.setState({
            recording: !this.state.recording
        }, () => {
            if (this.state.recording) {
                if (this.state.conversation && this.audioVideoStream.getAudioTracks().length) {
                    this.audioCtx = new AudioContext();
                    this.audioDestination = this.audioCtx.createMediaStreamDestination();





                    const source = this.audioCtx.createMediaStreamSource(this.audioVideoStream);
                    source.connect(this.audioDestination);

                    for (var uuid in this.peerStreams) {
                        const remoteSource = this.audioCtx.createMediaStreamSource(this.peerStreams[uuid]);
                        remoteSource.connect(this.audioDestination);
                    }


                    var temporaryStream = new MediaStream();
                    window.requestAnimationFrame(this.drawVideoFramesOnCanvas);
                    let canvasStream = this.recordingCanvas.captureStream();

                    temporaryStream.addTrack(canvasStream.getVideoTracks()[0]);
                    temporaryStream.addTrack(this.audioDestination.stream.getAudioTracks()[0]);

                    // this.mediaStreamVideo.srcObject.addTrack(canvasStream.getVideoTracks()[0]);
                    // this.mediaStreamVideo.srcObject.addTrack(this.audioDestination.stream.getAudioTracks()[0]);

                    // this.mediaStreamVideo.srcObject = temporaryStream;
                    // this.mediaStreamVideo.muted = true;
                    // this.mediaStreamVideo.play();
                    this.mediaRecorder = new MediaRecorder(temporaryStream);




                    // if (this.mediaStreamVideo) {
                    //     var mediaStream;
                    //     if (this.state.mainVideoElement == 'local-video' && this.localVideo && this.localVideo.captureStream) {
                    //         mediaStream = this.localVideo.captureStream();
                    //     } else {
                    //         if (this.remoteVideo[this.state.mainVideoElement] && this.remoteVideo[this.state.mainVideoElement].captureStream)
                    //             mediaStream = this.remoteVideo[this.state.mainVideoElement].captureStream();
                    //     }

                    //     if (this.mediaStreamVideo && this.mediaStreamVideo.srcObject)
                    //         this.mediaStreamVideo.srcObject.replaceVideoTrack(mediaStream.getVideoTracks()[0])//Video
                    // }


                    this.mediaRecorderChunks = [];

                    this.mediaRecorder.ondataavailable = (e) => {
                        console.log('this.mediaRecorder.ondataavailable', e.data);
                        this.mediaRecorderChunks.push(e.data);
                    }

                    this.mediaRecorder.onstop = (e) => {
                        console.log("data available after MediaRecorder.stop() called.");

                        var blob = new Blob(this.mediaRecorderChunks, { 'type': 'video/webm' });
                        var url = URL.createObjectURL(blob);
                        this.mediaRecorderChunks = [];


                        var a = document.createElement("a");
                        document.body.appendChild(a);
                        a.style = "display: none";
                        a.href = url;
                        a.download = 'record.webm';
                        a.click();

                        window.URL.revokeObjectURL(url);



                    }

                    this.mediaRecorder.start();



                }
            } else {
                if (this.mediaRecorder) {
                    this.mediaRecorder.stop();
                }
            }
        })
    }

    calculateKbps = () => {
        let ts = new Date().getTime();
        let contentLength = 5245329;
        let image = new Image();
        image.onload = () => {
            let duration = (new Date().getTime() - ts) / 1000;
            let kB = contentLength / 1024;
            console.log('duration: ', duration);
            console.log('kB: ', kB);
            console.log('kbps: ', (kB * 8) / duration);
            console.log('mbps: ', ((kB / 1024) * 8) / duration);
            this.setState({
                kbps: (kB * 8) / duration
            }, () => {
                // console.log("kbps speed slow  ", this.state.kbps);
                if (this.state.kbps < 5 * 1024) {
                    // console.log('error speed set');
                    this.setState({
                        kbpsError: true
                    })
                } else {
                    this.setState({
                        kbpsError: "checked"
                    })
                }
            })
        }

        image.src = `https://video.curoflow.se/5mb.jpeg?nnn=${ts}`
    }

    reconnect = () => {
        console.log('reconnect');
        try {
            for (var uuid in this.peerStreams) {
                if (this.peerStreams[uuid]) {
                    if (this.remoteVideo && this.remoteVideo[uuid] && this.remoteVideo[uuid].srcObject) {
                        this.remoteVideo[uuid].srcObject.getTracks().forEach(track => track.stop());
                        this.remoteVideo[uuid].srcObject = null;
                    }

                    delete this.peerStreams[uuid];
                }
            }

            if (this.audioVideoStream) {
                if (this.localVideo) {
                    this.localVideo.srcObject = null;
                }

                this.audioVideoStream.getAudioTracks()[0].stop();
                this.audioVideoStream.getVideoTracks()[0].stop();

                this.audioVideoStream = null;

                for (let i = 0; i < this.tmpStreams.length; i++) {
                    try {
                        if (this.tmpStreams[i]) {
                            this.tmpStreams[i].getTracks().forEach(track => track.stop());
                            this.tmpStreams[i] = null;
                        }
                    } catch (e) { }
                }


            }

            VideoGateway.clearState();
        } catch (e) {
            console.log(e);
        }

        this.setState({
            videoElements: [],
        }, () => {
            try {
                setTimeout(() => {
                    //alert('Reconnect iOS')
                    this.initVideoCall(this._initVideoCallData);
                }, 2000);
            } catch (e) {
                console.log(e)
            }
        })

    }


    getBackgroundImages = () => {
        const endpoint = typeof window != 'undefined' && window.innerWidth < 768 ? '/data-managment/background-video/images/pick-mobile' : '/data-managment/background-video/images/pick';
        fetch(API_ENDPOINT + endpoint, {
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            },
        }).then(parseJSON).then(({ result, status }) => {
            if (status >= 200 && status < 300) {
                if (result && result.gallery) {
                    this.setState({
                        libraryIcons: result.gallery,
                        loadLibrary: false
                    }, () => console.log(this.state.libraryIcons))
                } else {
                    this.setState({
                        libraryIcons: [],
                        loadLibrary: false
                    })
                }


            }

        })
    }


    checkSettingsForHostVideoRecord = () => {
        if (this.state.conversation) {
            let record = this.state.conversation.recordConversationAsVideo
            let conversation = this.state.conversation
            if (conversation.events && conversation.recurring) {
                for (let i = 0; i < conversation.events.length; i++) {
                    if (conversation.events[i].scheduledDate) {
                        let date = new Date(conversation.events[i].scheduledDate)
                        let dateNow = new Date()
                        if (date.getMonth() === dateNow.getMonth() && date.getDate() === dateNow.getDate()) {
                            record = conversation.events[i].recordConversationAsVideo
                        }
                        break;
                    }

                }
            }
            return record

        } else {
            return false
        }
    }

    checkAudioOutput = () => {
        if (navigator && this.state.audioOutputDevice) {
            navigator.mediaDevices.enumerateDevices().then((devices) => {


                let audioOutputFilter = [];
                let audioOutput = [];

                let found = false;
                let first = null;
                for (let i = 0; i < devices.length; i++) {



                    if (devices[i].kind == "audiooutput") {

                        if (devices[i].deviceId !== 'default' && devices[i].deviceId !== 'communications') {
                            audioOutputFilter.push(devices[i])
                        } else {
                            audioOutput.push(devices[i]);
                        }


                    }
                }


                let arr = audioOutputFilter.length ? audioOutputFilter : audioOutput;

                for (let i = 0; i < arr.length; i++) {
                    if (!first) {
                        first = arr[i].deviceId;
                    }

                    if (arr[i].deviceId == this.state.audioOutputDevice)
                        found = true;

                }

                if (!found && first) {
                    this.setState({
                        audioOutputDevice: first
                    }, () => {
                        this.generateLayout(true);
                    })
                }

            });
        }
    }


    initVideoCall = async (data) => {
        if (this.state._guestCallEnded || this.state._endCall) {
            return;
        }

        this._initVideoCallData = data;

        let stream = await this.getStream();
        this.videoUID = data.uid;

        this.setState({
            videoStartDateTime: data.videoStartDateTime,
            videoGatewayLogs: []
        })

        await this.checkConversationHostLicence(this.state.conversation)


        if (this.checkAudioOutputInterval) {
            clearInterval(this.checkAudioOutputInterval);
            this.checkAudioOutputInterval = null;
        }

        this.checkAudioOutputInterval = setInterval(this.checkAudioOutput, 3000);

        // console.log(this.state.conversation)
        // console.log('5 min warning', parseInt(this.state.conversation.durationHs) * 3600 * 1000 + parseInt(this.state.conversation.durationMin) * 60 * 1000 - 5 * 60 * 1000 - (new Date().getTime() - new Date(data.videoStartDateTime).getTime()))
        setTimeout(() => {
            this.setState({
                show5MinWarning: true
            });

        }, parseInt(this.state.conversation.durationHs) * 3600 * 1000 + parseInt(this.state.conversation.durationMin) * 60 * 1000 - 5 * 60 * 1000 - (new Date().getTime() - new Date(data.videoStartDateTime).getTime()))

        setTimeout(() => {
            if (!this.state.guestImageVideoInput)
                this.endCall(true)
        }, parseInt(this.state.conversation.durationHs) * 3600 * 1000 + parseInt(this.state.conversation.durationMin) * 60 * 1000 - (new Date().getTime() - new Date(data.videoStartDateTime).getTime()))

        VideoGateway.init(data.mediaServer, data.authToken, data.uid, stream, (consumer) => {
            console.log('consumer ----------------------', consumer)
            let videoElements = this.state.videoElements;


            if (consumer.status == 'failed' || consumer.status == 'closed') {
                this.removePeer(consumer.id);
                return;
            }

            if (consumer.status == 'connecting' || consumer.status == 'disconnected') {
                let connecting = this.state.connecting;
                connecting[consumer.id] = true;

                setTimeout(() => {
                    if (this.state.connecting[consumer.id]) {
                        this.removePeer(consumer.id);
                    }
                }, 20000);

                this.setState({
                    connecting
                })
            }

            if (consumer.status == 'connected') {
                let connecting = this.state.connecting;
                connecting[consumer.id] = null;
                this.setState({
                    connecting
                })
            }

            if (['failed', 'closed', 'disconnected'].indexOf(consumer.status) == -1) {
                if (consumer._new && this.state.videoElements.indexOf(consumer.id) != -1) {
                    console.log('replacing video element')
                    setTimeout(() => {
                        this.props.socket.emit('micStatus', { conversation: this.state.conversation._id, value: !this.state.micOff });
                        if (!this.state.shareOn)
                            this.props.socket.emit('cameraStatus', { conversation: this.state.conversation._id, value: !this.state.cameraOff })
                    }, 3000);

                    this.peerStreams[consumer.id] = null;
                    let videoElements = this.state.videoElements;
                    videoElements.splice(this.state.videoElements.indexOf(consumer.id), 1);


                    if (this.state.pinnedVideo === consumer.id) {
                        this.setState({
                            pinnedVideo: null
                        }, () => {
                            this.generateLayout(true);
                        })
                    }

                    this.setState({
                        videoElements
                    }, () => {
                        this.generateLayout(true);
                        let videoElements = this.state.videoElements;
                        videoElements.push(consumer.id);




                        this.setState({ videoElements }, () => {
                            this.generateLayout(true);
                        });
                    })
                } else {
                    if (videoElements.indexOf(consumer.id) == -1) {
                        setTimeout(() => {
                            this.props.socket.emit('micStatus', { conversation: this.state.conversation._id, value: !this.state.micOff });
                            if (!this.state.shareOn)
                                this.props.socket.emit('cameraStatus', { conversation: this.state.conversation._id, value: !this.state.cameraOff })

                        }, 3000);

                        console.log('not exists')
                        videoElements.push(consumer.id);
                        this.peerStreams[consumer.id] = null;
                        this.state.videoElements = videoElements;

                        let micStatus = this.state.micStatus;
                        let cameraStatus = this.state.cameraStatus;

                        micStatus[consumer.id] = true;
                        cameraStatus[consumer.id] = true;

                        this.setState({
                            videoElements,
                            cameraStatus,
                            micStatus,
                            _reconnecting: true
                        })
                    }
                }
            }

            if (consumer.track) {
                if (!this.peerStreams[consumer.id]) {
                    const newStream = new MediaStream();
                    this.peerStreams[consumer.id] = newStream;
                }

                console.warn('adding track', consumer.track.kind)
                this.peerStreams[consumer.id].addTrack(consumer.track);

                if (consumer.track.kind == 'audio' && this.state.recording && this.audioCtx) {
                    const remoteSource = this.audioCtx.createMediaStreamSource(this.peerStreams[consumer.id]);
                    remoteSource.connect(this.audioDestination);
                }

                this.generateLayout(true);
            }


        }, (volumes) => {
            try {
                if (!volumes.length) {
                    return;
                }


                this.tempVolumes = {};
                let layoutOutput = this.state.layoutOutput;
                if (false && this.state.layoutOutput == 2 && this.state.videoElements.length <= 1 && (typeof window !== 'undefined' && !(window.innerWidth >= 700 && window.innerWidth <= 1200))) {
                    layoutOutput = -1;
                }



                if (this.state.videoElements && this.state.videoElements.length && layoutOutput != 2) {
                    let oldVideoElements = [...this.state.videoElements];
                    let newVideoElements = volumes.filter(item => item.id != this.videoUID).filter(item => oldVideoElements.indexOf(item.id) != -1).map(item => item.id);

                    var isSame = (oldVideoElements.length == newVideoElements.length) && oldVideoElements.every(function (element, index) {
                        return element === newVideoElements[index];
                    });

                    if (!isSame) {
                        this.setState({
                            videoElements: newVideoElements
                        }, () => {
                            this.checkVideoElements();
                        })
                    }
                }


                let speaking = JSON.parse(JSON.stringify(this.state.speaking));

                let check = false;
                for (var uid in speaking) {
                    if (speaking[uid]) {
                        check = true;
                        break;
                    }
                }

                let same = true;

                let idx = 0;
                let max = volumes[0].volume;


                for (let i = 0; i < volumes.length; i++) {
                    this.tempVolumes[volumes[i].id] = volumes[i].volume;

                    if (speaking[volumes[i].id] != volumes[i].volume > -47) {
                        same = false;
                    }


                    speaking[volumes[i].id] = volumes[i].volume > -47;


                    if (volumes[i].volume > max) {
                        max = volumes[i].volume;
                        idx = i;
                    }

                }




                if (!same || this.state.activeClient != (!this.state.screenSharing && volumes[idx].volume > -47 ? volumes[idx].id : this.state.screenSharing && this.state.activeClient ? this.state.activeClient : this.state.activeClient ? this.state.activeClient : null)) {
                    this.setState({
                        speaking: speaking,
                        activeClient: !this.state.screenSharing && volumes[idx].volume > -47 ? volumes[idx].id : this.state.screenSharing && this.state.activeClient ? this.state.activeClient : this.state.activeClient ? this.state.activeClient : null,
                    }, this.generateLayout);
                }

            } catch (e) {
                console.log(e);
            }

        }, () => {
            // alert('Reconnect iOS L1')

            // if (iosDevice())
            this.reconnect();
        }, this.state.shareScreenSource ? this.screenStream : null, {

            error: (log) => {
                console.error(log.message);
                log.id = uuidv4();
                let videoGatewayLogs = this.state.videoGatewayLogs;
                videoGatewayLogs.push(log);



                if (log) {
                    // if (!log.data.client) {
                    //     log.data.client = this.props.uData._id
                    // }

                    fetch(API_ENDPOINT + `/video/call/error/log/new`, {
                        method: "POST",
                        headers: {
                            "content-type": "application/json",
                        },
                        body: JSON.stringify({
                            timestamp: Date.now(),
                            conversationId: this.state.conversation?._id,
                            message: log?.message,
                            type: log?.type,
                            userId: log.data && log.data.client ? log.data.client : this.props.uData._id
                        })
                    })
                        .then((res) => res.json())
                        .then((result) => {
                            console.log(result);
                        });
                }


                this.setState({ videoGatewayLogs });
            },
            log: (log) => {
                console.warn(log);
            },
            warn: (log) => {
                console.warn(log);
            }

        }, () => {
            this.setState({
                videoGatewayLogs: []
            })
        });

    }




    registerEvents = () => {
        this.props.socket.on('muteParticipantStatus', (data) => {
            if (data.conversation == this.state.conversation._id) {
                let mutedParticipants = this.state.mutedParticipants;
                mutedParticipants[data.uid] = data.value;
                this.setState({
                    mutedParticipants
                });

            }

        })


        this.props.socket.on('allowClient', (data) => {
            if (data.conversation == this.state.conversation._id && this.state.videoCallClients && this.state.videoCallClients.length) {
                let changed = false;
                let videoCallClients = this.state.videoCallClients;
                for (let i = 0; i < videoCallClients.length; i++) {
                    if (videoCallClients[i].uid == data.uid && !videoCallClients[i].allowed) {
                        videoCallClients[i].allowed = true;
                        changed = true;
                    }
                }

                if (changed) {
                    this.setState({
                        videoCallClients
                    })
                }
            }
        })


        this.props.socket.on('mediaServerAuthToken', async (data) => {
            if (data.videoCallClients && data.videoCallClients.length) {
                this.setState({
                    videoCallClients: data.videoCallClients
                })
            }
            this.initVideoCall(data);

            if (data.muteAllParticipants) {
                this.setState({
                    oldMicOff: this.state.micOff,
                    mutedByHost: true
                }, () => {
                    this.muteParticipant(true);
                })
            }
        })

        this.props.socket.on('muteParticipant', (data) => {
            if (!data.value) {
                this.muteParticipant(this.state.oldMicOff);
                this.setState({
                    mutedByHost: data.value,
                    oldMicOff: false,
                })
            } else {
                let oldMicOff = this.state.micOff;
                this.muteParticipant(true);
                this.setState({
                    mutedByHost: data.value,
                    oldMicOff: oldMicOff,
                })

            }

        })

        this.props.socket.on('linkForNewMessage', async ({ conversationId, messageId, links }) => {
            if (!conversationId || !messageId || !links) return;
            if (this.state.conversation && (String(this.state.conversation?._id) === conversationId) && this.state.messages && this.state.messages.find(msg => String(msg?._id) === messageId)) {
                this.setState(prev => ({
                    ...prev,
                    messages: prev.messages.map(msg => String(msg?._id) === messageId ? { ...msg, links } : msg)
                }));
            } else {
                this.linkForMessageTimer = setTimeout(() => {
                    if (this.state.conversation && (String(this.state.conversation?._id) === conversationId) && this.state.messages && this.state.messages.find(msg => String(msg?._id) === messageId)) {
                        this.setState(prev => ({
                            ...prev,
                            messages: prev.messages.map(msg => String(msg?._id) === messageId ? { ...msg, links } : msg)
                        }));
                    }
                }, 1500);
            }
        });

        this.props.socket.on('bankIdGenerateRedirectURL', (data) => {
            this.setState({
                _bankIdRedirectUrl: data.redirectUrl,
                bankIDkey: data.key,
                qrCodeLink: data.qrCodeLink
            })
        })
        this.props.socket.on('bankIdIdentificate', (data) => {
            if (this.state.conversation && this.state.conversation.mandatoryScreenName) {
                this.setState({
                    _requireDisplayName: true,
                    realName: data.name
                })
                return;
            } else {
                this.setState({
                    guestName: data.name
                })

            }
        })


        this.props.socket.on('call', async (data) => {
            let params = getSearchParams(this.props[0].location.search, {});
            // console.log('call', data, this.props[0].match.params.conversation && params.roomId);

            if (this.props[0].match.params.conversation && params.roomId) {


                if (this.state._devicesPrepared && this.state._devicesPrepared.initiator) {
                    if (this.state.call == true) {

                        this.setState({
                            call: true,
                        }, async () => {
                            if (!this.logoutResetInterval) {
                                this.logoutResetInterval = setInterval(() => {
                                    this.props.resetLogoutInterval();
                                }, 10000);
                            }


                            this.props.socket.emit('answer', {
                                conversation: this.state._devicesPrepared.conversation,
                                initiator: this.state._devicesPrepared.initiator,
                                guestName: params.roomId ? this.state.guestName : null,
                                realName: params.roomId ? this.state.realName : null,

                                browser: browserInfo(),
                            })
                        });
                    } else {

                        this.setState({
                            call: true,
                            offerData: null,
                            peers: {},
                            videoElements: [],
                            hands: {},
                            hand: false
                        }, async () => {
                            if (!this.logoutResetInterval) {
                                this.logoutResetInterval = setInterval(() => {
                                    this.props.resetLogoutInterval();
                                }, 10000);
                            }


                            this.props.socket.emit('answer', {
                                conversation: this.state._devicesPrepared.conversation,
                                initiator: this.state._devicesPrepared.initiator,
                                guestName: params.roomId ? this.state.guestName : null,
                                realName: params.roomId ? this.state.realName : null,

                                browser: browserInfo(),

                            })
                        });
                    }

                } else {
                    this.setState({
                        _guestCallEnded: null,
                        guestName: '',
                        prepareVideoCall: {
                            conversation: data.conversation,
                            initiator: data.initiator
                        }
                    });
                }


            }

            let conversations = this.state.conversations;

            for (let i = 0; i < conversations.length; i++) {
                if (conversations[i]?._id == data.conversation) {
                    if (this.props.uData && data.initiatorUid != this.props.uData._id)
                        conversations[i].currentCall = data;

                    let event = false
                    if (conversations[i].events && conversations[i].recurring) {
                        for (let m = 0; m < conversations[i].events.length; m++) {
                            if (conversations[i].events[m].scheduledDate) {
                                let date = new Date(conversations[i].events[m].scheduledDate)
                                let dateNow = new Date()
                                if (date.getMonth() === dateNow.getMonth() && date.getDate() === dateNow.getDate()) {
                                    event = conversations[i].events[m]
                                }
                                break;
                            }
                        }

                        if (event) {
                            conversations[i].scheduledTime = event.scheduledTime
                            if (event.passcode !== 'undefined')
                                conversations[i].passcode = event.passcode
                            if (event.requireBankID !== 'undefined')
                                conversations[i].requireBankID = event.requireBankID
                            if (event.requireNetID !== 'undefined')
                                conversations[i].requireNetID = event.requireNetID
                            if (event.muteParticipants !== 'undefined')
                                conversations[i].muteParticipants = event.muteParticipants
                            if (event.recordConversationAsVideo !== 'undefined')
                                conversations[i].recordConversationAsVideo = event.recordConversationAsVideo
                            if (event.mandatoryScreenName !== 'undefined')
                                conversations[i].mandatoryScreenName = event.mandatoryScreenName
                            if (event.recordConversationAsVideoMembers !== 'undefined')
                                conversations[i].recordConversationAsVideoMembers = event.recordConversationAsVideoMembers
                            // if (event.members !== 'undefined')
                            //     conversations[i].members = event.members
                            // if (event.invitedUsers !== 'undefined')
                            //     conversations[i].invitedUsers = event.invitedUsers
                        }
                    }
                }
            }

            this.setState({
                conversations
            })
        })

        this.props.socket.on('endCall', async (data) => {
            if (this.state.conversation && this.state.conversation._id == data.conversation) {

                if (data.logUUID) {
                    this.setState({
                        logUUID: data.logUUID
                    })
                }
                this.endCall(false);
            }
        });

        this.props.socket.on('logUUID', async (data) => {
            if (data.logUUID) {
                this.setState({
                    logUUID: data.logUUID
                })
            }
        });

        this.props.socket.on('removePeers', async (data) => {
            this.removePeer(data.uid);
        });

        this.props.socket.on('conversations', (data) => {
            console.log('emit conversations', data);
            if (data.total) {
                this.setState({
                    totalNumberOfConversations: Number(data.total)
                })
            }
            if (data.conversations) {
                if (this.state.addPrevConversations) {
                    this.setState(prev => ({ conversations: [...prev.conversations, ...data.conversations] }));

                } else {
                    this.setState({
                        conversations: data.conversations
                    })
                }

                if (this.state.conversation && data.conversations.filter(item => item._id == this.state.conversation._id).length) {
                    console.log('test 1');
                    let conversation = data.conversations.filter(item => item._id == this.state.conversation._id)[0]

                    this.setState({ conversation })
                }


                if (!this.state.conversation && data.conversations.length) {
                    console.log('conversations event');
                    let params = getSearchParams(this.props[0].location.search, {});


                    if (this.props[0].match.params.windowConversation) {
                        let idx = 0;
                        for (let i = 0; i < data.conversations.length; i++) {
                            if (data.conversations[i]._id == this.props[0].match.params.windowConversation) {
                                idx = i;
                                break;
                            }
                        }

                        //this.updateParams('conversation', null);
                        this.openConversation(data.conversations[idx], () => {
                        });
                        return;

                    }

                    if (this.props[0].match.params.conversation && params.roomId) {

                        let idx = 0;
                        for (let i = 0; i < data.conversations.length; i++) {
                            if (data.conversations[i]._id == this.props[0].match.params.conversation) {
                                idx = i;
                                break;
                            }
                        }

                        this.updateParams('conversation', null);
                        this.openConversation(data.conversations[idx], () => {
                        });
                        return;

                    }

                    if (this.props[0].match.params.conversation) {

                        let idx = 0;
                        for (let i = 0; i < data.conversations.length; i++) {
                            if (data.conversations[i]._id == this.props[0].match.params.conversation) {
                                idx = i;
                                break;
                            }
                        }


                        if ((!params.initiator && !data.conversations[idx].initiator)) {
                            this.updateParams('conversation', null);
                            this.updateParams('startVideoCall', null);
                            this.openConversation(data.conversations[idx], () => {
                                this.setState({
                                    prepareVideoCall: {
                                        conversation: data.conversations[idx]._id,
                                        initiator: null
                                    }
                                });
                            });
                            return;
                        }


                    }

                    if (params.conversation) {
                        console.log('test prosao');
                        let idx = 0;
                        for (let i = 0; i < data.conversations.length; i++) {
                            if (data.conversations[i]._id == params.conversation) {
                                idx = i;
                                break;
                            }
                        }
                        this.openConversation(data.conversations[idx]);
                        this.updateParams('conversation', null);
                        return;
                    }

                    if (this.props[0].match.params.conversation) {
                        let idx = 0;
                        for (let i = 0; i < data.conversations.length; i++) {
                            if (data.conversations[i]._id == this.props[0].match.params.conversation) {
                                idx = i;
                                break;
                            }
                        }

                        if (params.initator || data.conversations[idx].initiator) {
                            this.openConversation(data.conversations[idx]);
                            if (!this.state.call) {
                                this.setState({
                                    prepareVideoCall: {
                                        conversation: data.conversations[idx]._id,
                                        initiator: params.initator || data.conversations[idx].initiator
                                    }
                                });
                            }
                        }


                    }
                }
                this.props.verifyUser()
            }
        })

        this.props.socket.on('guestNames', (data) => {
            if (!this.state.call) {
                return;
            }


            let guestNames = this.state.guestNames;
            let guestsVerificationStatus = this.state.guestsVerificationStatus;


            if (this.state.conversation && data.conversation == this.state.conversation._id) {
                let conversation = this.state.conversation;
                for (var uid in data.guestNames) {


                    guestNames[uid] = data.guestNames[uid];
                    guestsVerificationStatus[uid] = data.guestsVerificationStatus ? data.guestsVerificationStatus[uid] : null;


                    let found = false;
                    for (let i = 0; i < conversation.users.length; i++) {
                        if (conversation.users[i].uid == uid) {
                            found = true;
                        }
                    }
                    if (!found) {
                        conversation.users.push({
                            uid: uid,
                            name: data.guestNames[uid],
                            bankIdVerified: data.guestsVerificationStatus ? data.guestsVerificationStatus[uid] : null
                        })
                    }
                }

                this.setState({
                    conversation,
                    guestNames,
                    guestsVerificationStatus

                })


                if (this.fetchConversationsOnGuestNamesTimer) {
                    clearTimeout(this.fetchConversationsOnGuestNamesTimer);
                }

                this.fetchConversationsOnGuestNamesTimer = setTimeout(() => {
                    this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage.profile : null, conversation: this.props[0].match.params.conversation && this.props[0].location.pathname.indexOf('/video-call') === 0 ? this.props[0].match.params.conversation : null });
                }, 5000);
            }

            //
        })


        this.props.socket.on('closeConversation', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation._id) {
                let conversation = this.state.conversation;
                conversation.closed = true;
                this.setState({
                    conversation
                })
            }

            this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage.profile : null, conversation: this.props[0].match.params.conversation && this.props[0].location.pathname.indexOf('/video-call') === 0 ? this.props[0].match.params.conversation : null });
        })
        this.props.socket.on('openConversation', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation._id) {
                let conversation = this.state.conversation;
                conversation.closed = false;
                this.setState({
                    conversation
                })
            }

            this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage.profile : null, conversation: this.props[0].match.params.conversation && this.props[0].location.pathname.indexOf('/video-call') === 0 ? this.props[0].match.params.conversation : null });
        })
        this.props.socket.on('deleteMessage', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation._id) {
                let conversation = this.state.conversation;
                this.setState({
                    conversation
                })
            }

            this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage.profile : null, conversation: this.props[0].match.params.conversation && this.props[0].location.pathname.indexOf('/video-call') === 0 ? this.props[0].match.params.conversation : null });
        })

        this.props.socket.on('deleteConversation', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation._id) {
                this.setState({
                    conversation: null
                })
            }

            this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage.profile : null, conversation: this.props[0].match.params.conversation && this.props[0].location.pathname.indexOf('/video-call') === 0 ? this.props[0].match.params.conversation : null });
        })


        this.props.socket.on('createConversation', (data) => {
            this.props.verifyUser()
            this.setState({
                _saving: null,

            }, () => {
                setTimeout(() => {
                    this.setState({
                        form: null,
                        passcodeCheckbox: null
                    }, () => {
                        if (this.state.openInNewTab) {
                            window.open(`/chat/${data.conversation}`, 'Conversation'.translate(this.props.lang) + ' ' + data.conversation, "resizable=0,width=360,height=640");
                            this.setState({
                                openInNewTab: false
                            })
                        }
                    })
                }, 1000)
            })
            this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage.profile : null, conversation: this.props[0].match.params.conversation && this.props[0].location.pathname.indexOf('/video-call') === 0 ? this.props[0].match.params.conversation : null });

            this.setState({
                calendarConnectModal: data
            })
        })

        this.props.socket.on('editConversation', (data) => {
            this.props.verifyUser()
            this.setState({
                _saving: null,

            }, () => {
                setTimeout(() => {
                    this.setState({
                        form: null,
                        passcodeCheckbox: null
                    }, () => {
                        if (this.state.openInNewTab) {
                            window.open(`/chat/${data.conversation}`, 'Conversation'.translate(this.props.lang) + ' ' + data.conversation, "resizable=0,width=360,height=640");
                            this.setState({
                                openInNewTab: false
                            })
                        }
                    })
                }, 1000)
            })
            this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage.profile : null, conversation: this.props[0].match.params.conversation && this.props[0].location.pathname.indexOf('/video-call') === 0 ? this.props[0].match.params.conversation : null });

            // console.log(data)

        })



        this.props.socket.on('messages', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation._id) {

                let messages = this.state.messages;

                if (this.state.page == 0) {
                    messages = [...data.messages];
                } else {
                    messages = [...data.messages, ...messages]
                }

                let oldScrollHeight = 0;
                if (this.messagesRef) {
                    oldScrollHeight = this.messagesRef.scrollHeight;
                }

                this.setState({ messages: messages, numberOfPatients: data.numberOfPatients }, () => {
                    if (this.messagesRef && this.state.page == 0) {
                        this.messagesRef.scrollTop = this.messagesRef.scrollHeight;
                    } else if (oldScrollHeight) {
                        this.messagesRef.scrollTop = this.messagesRef.scrollTop + (this.messagesRef.scrollHeight - oldScrollHeight);
                    }
                });
            }
        })
        this.props.socket.on('newMessage', (data) => {
            console.log('data', data);
            console.log('videoCallNewMessage', this.state.videoCallNewMessage);
            if (this.state.conversation && data.conversation == this.state.conversation._id) {

                if (!this.state.call || this.state.showChat) {
                    this.setState({
                        page: 0
                    }, () => {
                        this.props.socket.emit('messages', { conversation: this.state.conversation._id, page: this.state.page })
                    })
                }

                if (this.state.call && !this.state.showChat && !this.state.videoCallNewMessage && !data.redDot) {
                    if (data.specialMessage) {
                        this.setState({ videoCallNewMessage: false });
                    } else {
                        this.setState({ videoCallNewMessage: true });
                    }

                }
            } else {
                let conversations = this.state.conversations;
                for (let i = 0; i < conversations.length; i++) {
                    if (conversations[i]?._id == data.conversation) {
                        if (data.specialMessage) {
                            conversations[i]._newMessage = false;
                        } else {

                            conversations[i]._newMessage = true;
                        }
                    }
                }
                this.setState({
                    conversations
                })
            }
        })
        this.props.socket.on('setTranslationLanguage', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation._id) {
                let conversation = this.state.conversation;
                conversation.memberLanguages = data.memberLanguages;
                this.setState({
                    conversation
                })

                this.setState({
                    page: 0
                }, () => {
                    this.props.socket.emit('messages', { conversation: this.state.conversation._id, page: this.state.page })
                })
            }
        })



        this.props.socket.on('seenUpdate', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation._id) {
                if (this.state.messages) {
                    let messages = this.state.messages;
                    for (let i = 0; i < messages.length; i++) {
                        if (messages[i]._id == data.message) {
                            messages[i].seen = data.seen;
                        }
                    }
                    this.setState({
                        messages
                    })
                }

            }
        })


        // this.props.socket.on('searchMembers', (data) => {
        //     if (this.state.conversation && data.conversation == this.state.conversation._id) {
        //         console.log(data.users)
        //         this.setState({
        //             searchUsers: data.users
        //         })
        //     }
        // })
        this.props.socket.on('addMember', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation._id) {
                let conversation = this.state.conversation;
                conversation.members = data.members;
                conversation.users = data.users;
                this.setState({
                    conversation: conversation,
                    numberOfPatients: data.numberOfPatients

                }, this.forceUpdate)
            }
            this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage.profile : null, conversation: this.props[0].match.params.conversation && this.props[0].location.pathname.indexOf('/video-call') === 0 ? this.props[0].match.params.conversation : null });
        })


        // this.props.socket.on('removeMember', (data) => {
        //     if (this.state.conversation && data.conversation == this.state.conversation._id) {
        //         let conversation = this.state.conversation;
        //         conversation.members = data.members;
        //         conversation.users = data.users;

        //         this.setState({
        //             conversation: conversation,
        //             numberOfPatients: data.numberOfPatients

        //         }, this.forceUpdate)
        //     }
        //     this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage.profile : null, conversation: this.props[0].match.params.conversation && this.props[0].location.pathname.indexOf('/video-call') === 0 ? this.props[0].match.params.conversation : null });
        // })

        this.props.socket.on('removeInvitedContacts', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation._id) {
                let conversation = this.state.conversation;
                conversation.invitedContacts = data.invitedContacts;
                if (this.state.sentInvintations) {
                    this.setState({ sentInvintations: data.invitedContacts })
                }
                this.setState({
                    conversation: conversation,

                }, this.forceUpdate)
            }
            this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage.profile : null, conversation: this.props[0].match.params.conversation && this.props[0].location.pathname.indexOf('/video-call') === 0 ? this.props[0].match.params.conversation : null });
        })

        this.props.socket.on('updateJournalNote', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation._id) {
                let conversation = this.state.conversation;
                conversation.members = data.members;
                conversation.users = data.users;
                conversation.journalNote = data.journalNote;

                this.setState({
                    conversation: conversation,
                    numberOfPatients: data.numberOfPatients,
                }, this.forceUpdate)
            }

            this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage.profile : null, conversation: this.props[0].match.params.conversation && this.props[0].location.pathname.indexOf('/video-call') === 0 ? this.props[0].match.params.conversation : null });
        })


        this.props.socket.on('raiseHand', (data) => {

            if (this.state.call && this.state.conversation && data.conversation == this.state.conversation._id) {
                let hands = this.state.hands;
                hands[data.uid] = true;

                this.setState({
                    activeClient: !this.state.screenSharing ? data.uid : this.state.screenSharing && this.state.activeClient ? this.state.activeClient : null,

                }, this.generateLayout);

                try {
                    // let raiseHandSound = new Audio(handSound);
                    // if (this.state.audioOutputDevice)
                    //     this.attachSinkId(raiseHandSound, this.state.audioOutputDevice);

                    // raiseHandSound.play();
                    this.playRaiseHandSound();

                } catch (e) {
                    console.error(e);
                }

                this.setState({
                    hands: hands
                })
            }
        });

        this.props.socket.on('shareScreen', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation._id) {
                let cameraStatus = this.state.cameraStatus;
                if (data.value)
                    cameraStatus[data.uid] = true;

                if (data.value && data.uid) {
                    VideoGateway.changeLayer(data.uid, 1);
                }


                let stateObj = {
                    screenSharingUID: data.value ? data.uid : null,
                    oldLayoutOutput: this.state.layoutOutput,
                    screenSharing: data.value,
                    activeClient: data.uid,
                    cameraStatus,

                }

                if (data.value) {
                    stateObj.layoutOutput = '-1';
                    stateObj.oldLayoutOutput = this.state.layoutOutput;

                } else {
                    if (this.state.oldLayoutOutput) {
                        stateObj.layoutOutput = this.state.oldLayoutOutput;
                        stateObj.oldLayoutOutput = null;
                    }
                }


                try {
                    if (data.value && data.uid) {
                        this.playScreenSharingStartedSound();
                    }
                    if (!data.value && data.uid) {
                        this.playScreenSharingStoppedSound();
                    }
                } catch (e) {
                    console.log(e);
                }



                this.setState({
                    ...stateObj
                }, () => {

                    this.props.socket.emit('shareScreenStatus', { conversation: this.state.conversation._id, uid: this.state.screenSharingUID, value: true });
                    this.generateLayout(true)
                })
            }
        });


        this.props.socket.on('handDown', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation._id) {
                let hands = this.state.hands;
                hands[data.uid] = false;
                this.setState({
                    hands: hands
                })
            }
        });

        this.props.socket.on('callEnded', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation._id) {
                let conversation = this.state.conversation;
                conversation.currentCall = null;

                this.setState({
                    conversation
                })
            }

            let conversations = this.state.conversations;

            for (let i = 0; i < conversations.length; i++) {
                if (conversations[i]?._id == data.conversation) {
                    conversations[i].currentCall = null;
                }
            }

            this.setState({
                conversations
            })


        });

        this.props.socket.on('hostEndedCall', (data) => {
            if (data.conversation && this.state.conversation && String(data.conversation) === this.state.conversation._id) {
                if (data.members && this.props.uData && data.members.findIndex(member => String(member) === String(this.props.uData._id)) !== -1) {
                    this.props.endCall()
                }
                if (this.state.prepareVideoCall) {
                    let conversation = this.state.conversation
                    conversation.currentCall = null
                    this.setState({
                        hostEndedCall: true,
                        conversation
                    })
                }

            }
        });


        this.props.socket.on('rejoinData', (data) => {
            // console.log('rejoinData');
            if (this.state.conversation && data.conversation == this.state.conversation._id) {
                let conversation = this.state.conversation;
                conversation.currentCall = data;

                this.setState({
                    conversation
                })
            }

            let conversations = this.state.conversations;

            for (let i = 0; i < conversations.length; i++) {
                if (conversations[i]?._id == data.conversation) {
                    conversations[i].currentCall = data;
                }
            }

            this.setState({
                conversations
            })

        })


        this.props.socket.on('answerCallback', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation._id && this.state.conversation.currentCall !== null) {
                let conversation = this.state.conversation;
                conversation.currentCall = null;

                this.setState({
                    conversation
                })
            }

            let conversations = this.state.conversations;
            let updateNeeded = false;

            for (let i = 0; i < conversations.length; i++) {
                if (conversations[i]._id == data.conversation && conversations[i].currentCall !== null) {
                    conversations[i].currentCall = null;
                    updateNeeded = true;
                }
            }

            if (updateNeeded) {
                this.setState({
                    conversations
                })
            }
        })



        this.props.socket.on('userSpeak', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation._id) {
                let speaking = this.state.speaking;

                let check = false;
                for (var uid in speaking) {
                    if (speaking[uid]) {
                        check = true;
                        break;
                    }
                }

                speaking[data.uid] = data.value;



                this.setState({
                    speaking: speaking,
                    activeClient: (!this.state.screenSharing || this.state.fullScreen) && data.value && !check ? data.uid : this.state.screenSharing && this.state.activeClient ? this.state.activeClient : this.state.activeClient ? this.state.activeClient : null,
                }, this.generateLayout);
            }
        });
        this.props.socket.on('cameraStatus', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation._id) {
                let cameraStatus = this.state.cameraStatus;
                cameraStatus[data.uid] = data.value;
                this.setState({
                    cameraStatus: cameraStatus
                })
            }
        });
        this.props.socket.on('micStatus', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation._id && this.state.micStatus && this.state.micStatus[data.uid] !== data.value) {
                let micStatus = this.state.micStatus;
                micStatus[data.uid] = data.value;
                this.setState({
                    micStatus: micStatus
                })
            }
        });
        this.props.socket.on('shareScreenStatus', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation._id && this.state.shareScreenStatus && this.state.shareScreenStatus[data.uid] !== data.value) {
                let shareScreenStatus = this.state.shareScreenStatus;
                shareScreenStatus[data.uid] = data.value;
                this.setState({
                    shareScreenStatus: shareScreenStatus
                })
            }
        });




        this.props.socket.on('userTyping', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation._id) {
                this.setState({
                    userTyping: data.user
                })
            }
        });

        this.props.socket.on('userEndTyping', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation._id) {
                this.setState({
                    userTyping: null
                })
            }
        });


        this.props.socket.on('userOnline', (data) => {
            if (this.state.conversation) {
                let conversation = this.state.conversation;
                if (conversation.users) {
                    for (let i = 0; i < conversation.users.length; i++) {
                        if (conversation.users[i].uid == data.uid) {
                            conversation.users[i].online = true;
                        }
                    }
                }
                this.setState({
                    conversation
                })
            }
        });
        this.props.socket.on('userOffline', (data) => {
            if (this.state.conversation) {
                let conversation = this.state.conversation;
                if (conversation.users) {
                    for (let i = 0; i < conversation.users.length; i++) {
                        if (conversation.users[i].uid == data.uid) {
                            conversation.users[i].online = false;
                        }
                    }
                }
                this.setState({
                    conversation
                })
            }
        });

        this.props.socket.on('videoCallLimit', (data) => {
            if (this.state.conversation && this.state.conversation._id == data.conversation) {
                let videoCallClients = this.state.videoCallClients;
                for (let i = 0; i < this.state.videoCallClients.length; i++) {
                    if (this.state.videoCallClients[i].socketId == data.socketId) {
                        this.state.videoCallClients[i].allowed = false;
                    }
                }

                this.setState({
                    videoCallLimit: true,
                    videoCallClients: videoCallClients
                })
            }
        });

        this.props.socket.on('newClientConnected', (data) => {
            if (this.state.conversation && this.state.conversation._id == data.conversation) {
                let videoCallClients = this.state.videoCallClients;

                let found = false;
                for (let i = 0; i < videoCallClients.length; i++) {
                    if (videoCallClients[i].uid == data.uid) {
                        videoCallClients[i] = data;
                        videoCallClients[i].allowed = false;
                        found = true;
                    }
                }

                if (!found)
                    videoCallClients.push(data);

                this.setState({
                    videoCallClients: videoCallClients,
                    newClientConnectedNotification: true
                }, () => {
                    localStorage.setItem('videoCallClients', JSON.stringify(this.state.videoCallClients));
                    if (this.startNewClientAnimationInterval) {
                        clearInterval(this.startNewClientAnimationInterval);
                    }


                    let _notificationSound = new Audio(notificationSound);

                    if (this.state.videoStartDateTime) {
                        _notificationSound.play();
                    }


                    this.startNewClientAnimationInterval = setInterval(() => {
                        this.setState({
                            startNewClientAnimationColorChange: !this.state.startNewClientAnimationColorChange
                        })
                    }, 500);

                })
            }
        });

        this.props.socket.on('updateConnectedClient', (data) => {
            if (this.state.conversation && this.state.conversation._id == data.conversation) {
                let videoCallClients = this.state.videoCallClients;
                for (let i = 0; i < this.state.videoCallClients.length; i++) {
                    if (this.state.videoCallClients[i].socketId == data.oldSocketId) {
                        this.state.videoCallClients[i].socketId = data.socketId;
                    }
                }

                this.setState({
                    videoCallClients: videoCallClients
                })
            }
        });

        this.props.socket.on('removeConnectedClient', (data) => {
            if (this.state.conversation && this.state.conversation._id == data.conversation) {
                let index = -1;
                let videoCallClients = this.state.videoCallClients;
                for (let i = 0; i < this.state.videoCallClients.length; i++) {
                    if (this.state.videoCallClients[i].socketId == data.socketId) {
                        index = i;
                    }
                }

                if (index != -1) {
                    videoCallClients.splice(index, 1);
                }

                this.setState({
                    videoCallClients: videoCallClients
                })
            }
        });


        this.props.socket.on('removeClient', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation._id) {
                this.endCall(true, true);
            }
        })


        this.props.socket.on('newGuestUpdate', (data) => {
            if (data._id) {
                localStorage.setItem('guestID', data._id)
                localStorage.setItem('guestToken', data.token)
            }
        })


        this.props.socket.on('connect', this.socketConnect);


    }

    socketConnect = () => {

        let params = getSearchParams(this.props[0].location.search, {});
        if (params.roomId && !this.state.call) {
            this.props.socket.emit('guestConnected', {});
        }
    }

    orientationChange = () => {
        // alert(`orientationChange ${this.state.layoutOutput}, ${window.innerWidth}, ${window.innerHeight}`)
        // if (this.state.call && this.state.layoutOutput == '-1' && window.innerWidth > window.innerHeight && window.innerWidth < 1200) {
        //     alert('orientationChange condition')

        //     this.setState({
        //         layoutOutput: 2
        //     }, () => {

        //         if (iosDevice()) {
        //             this.getStream(true);
        //         }

        //         this.forceUpdate()
        //     })
        // }
        // document.documentElement.style.height = `initial`;
        // setTimeout(() => {
        //   document.documentElement.style.height = `100%`;
        //     setTimeout(() => {
        //       // this line prevents the content
        //       // from hiding behind the address bar
        //       window.scrollTo(0, 1);
        //     }, 500);
        // }, 500);

        if (iosDevice()) {
            if (this.callArea) {
                setTimeout(() => {
                    this.callArea.style.width = '100vw';
                    this.callArea.style.height = '100vh';
                }, 500);
            }
        }

    }

    resizeWindow = () => {
        //alert(`resizeWindow ${this.state.layoutOutput}, ${window.innerWidth}, ${window.innerHeight}`)

        if (this.state.call && !this.state.screenSharing && this.state.layoutOutput == '-1' && window.innerWidth > window.innerHeight && window.innerWidth < 1200) {
            setTimeout(() => {
                this.setState({
                    layoutOutput: 2
                }, async () => {
                    if (true || iosDevice()) {
                        //await this.getStream(true);
                        this.generateLayout(true);
                    }

                    this.forceUpdate();
                })

            }, 1000)
        }

        if (true || iosDevice()) {
            setTimeout(() => {
                this.generateLayout(true);
            }, 2000);

        } else {
            this.generateLayout(true);
        }
        // var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        // if (isSafari) {
        //     setTimeout(() => {
        //         this.generateLayout(true);
        //     }, 200)
        // }

        var maxHeight = window.screen.height,
            maxWidth = window.screen.width,
            curHeight = window.innerHeight,
            curWidth = window.innerWidth;

        let maximised = screen.availWidth - window.innerWidth === 0;
        if (this.state.shareOn && this.state.resizeWindowAfter && !maximised && window.opener) {
            try {

                window.resizeTo(200, window.innerHeight / 2);
                this.setState({
                    resizeWindowAfter: false,
                    showScreenShareMode: true,
                    windowDimensions: {
                        width: 200,
                        height: window.innerHeight
                    }
                })


            } catch (e) {
                // console.log(e);
            }




        } else {

            if (this.state.showScreenShareMode) {
                if (curWidth > 200) {
                    this.setState({
                        showScreenShareMode: false,
                        windowDimensions: null
                    })
                }
            }

            if (!this.state.showScreenShareMode && this.state.shareOn && curWidth < 300) {
                this.setState({
                    showScreenShareMode: true,
                    windowDimensions: {
                        width: curWidth,
                        height: curHeight
                    }
                }, this.generateLayout)
            }
        }


        if (window.webkitCurrentFullScreenElement !== 'undefined') {
            if (window.document && window.document.webkitCurrentFullScreenElement) {
                this.setState({
                    fullScreen: true
                }, () => {
                    this.generateLayout(true);
                    setTimeout(() => {
                        this.checkVideoElements();
                    }, 1000)
                })

            } else {
                this.setState({
                    fullScreen: false
                }, () => {
                    this.generateLayout(true);
                    setTimeout(() => {
                        this.checkVideoElements();
                    }, 1000)


                })

            }
        } else {
            if (maxWidth == curWidth && maxHeight == curHeight) {
                this.setState({ fullScreen: true }, () => {
                    this.generateLayout(true);
                    setTimeout(() => {
                        this.checkVideoElements();
                    }, 1000)

                })
                // do something
            } else {
                this.setState({ fullScreen: false }, () => {
                    this.generateLayout(true);
                    setTimeout(() => {
                        this.checkVideoElements();
                    }, 1000)

                })
            }
        }

    }

    componentWillUnmount() {

        document.body.style.overflowX = 'auto';

        if (this.logoutResetInterval) {
            clearInterval(this.logoutResetInterval);
        }

        try {
            let { visibilityChange } = this.getVisibilityAPI();

            window.removeEventListener(visibilityChange, this.handleVisibilityChange);
        } catch (e) {

        }


        window.removeEventListener('resize', this.resizeWindow);

        if (this.props.socket) {

            if (this.state.call) {
                this.props.socket.emit('hangUp', { conversation: this.state.conversation._id, uuids: Object.keys(this.peers) })
            }

            this.props.socket.removeListener('bankIdGenerateRedirectURL');
            this.props.socket.removeListener('bankIdIdentificate');
            this.props.socket.removeListener('endCall');
            this.props.socket.removeListener('answer');
            this.props.socket.removeListener('conversations');
            this.props.socket.removeListener('messages');
            this.props.socket.removeListener('newMessage');
            this.props.socket.removeListener('setTranslationLanguage');
            this.props.socket.removeListener('muteParticipantStatus');
            this.props.socket.removeListener('seenUpdate');
            this.props.socket.removeListener('raiseHand');
            this.props.socket.removeListener('allowClient');
            this.props.socket.removeListener('handDown');
            this.props.socket.removeListener('userTyping');
            this.props.socket.removeListener('userEndTyping');
            this.props.socket.removeListener('userOnline');
            this.props.socket.removeListener('userOffline');
            this.props.socket.removeListener('createConversation');
            this.props.socket.removeListener('editConversation');
            this.props.socket.removeListener('removePeers');
            this.props.socket.removeListener('shareScreen');
            this.props.socket.removeListener('guestNames');
            this.props.socket.removeListener('userSpeak');
            this.props.socket.removeListener('cameraStatus');
            this.props.socket.removeListener('micStatus');
            this.props.socket.removeListener('callEnded');
            this.props.socket.removeListener('videoCallLimit');
            this.props.socket.removeListener('newClientConnected');
            this.props.socket.removeListener('removeClient');
            this.props.socket.removeListener('updateConnectedClient');
            this.props.socket.removeListener('shareScreenStatus');
            this.props.socket.removeListener('newGuestUpdate')
            this.props.socket.removeListener('removeConnectedClient');
            this.props.socket.removeListener('logUUID');
            this.props.socket.removeListener('answerCallback');
            this.props.socket.removeListener('rejoinData');
            this.props.socket.removeListener('hostEndedCall');
            this.props.socket.removeListener('linkForNewMessage');
            this.props.socket.removeListener('connect', this.socketConnect);
        }

        if (this.videoCallConnectionInterval) {
            clearInterval(this.videoCallConnectionInterval);
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props[0].location.state && this.props[0].location.state.form == true) {
            this.setState({
                conversationShedule: false,
                form: { timezone: this.props.uData && this.props.uData.timezone ? this.props.uData.timezone : null, scheduledDate: moment(new Date()).format(this.props.dateFormat), scheduledTime: moment(new Date()).format('HH:mm') }
            }, () => {
                this.props[0].history.push({
                    state: { form: null }
                })
            })
        }

        if (this.state.form && ((this.state.form.scheduledTime != prevState.scheduledTime && prevState.scheduledTime) || (this.state.form.scheduledDate != prevState.scheduledDate && prevState.scheduledDate) || (this.state.form.timezone != prevState.timezone && prevState.timezone))) {
            this.setState({
                conversationSheduleError: false
            })
        }

        if (this.state.userPasscodeEnter && prevState.userPasscodeEnter != this.state.userPasscodeEnter) {
            this.setState({
                userPasscodeCheck: null
            })
        }

        if (!this.state.call && this.logoutResetInterval) {
            clearInterval(this.logoutResetInterval);
            this.logoutResetInterval = null;
        }



        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })

        }

        if (prevProps.selectedClinic != this.props.selectedClinic || prevProps.selectedGroup != this.props.selectedGroup) {
            this.get()
        }
        if (prevProps.selectedGroup != this.props.selectedGroup && !this.state.call) {
            this.setState({ conversation: null })
        }

        let layoutOutput = this.state.layoutOutput;
        if (this.state.layoutOutput == 2 && this.state.videoElements.length <= 1 && (typeof window !== 'undefined' && !(window.innerWidth >= 700 && window.innerWidth <= 1200))) {
            layoutOutput = -1;
        }

        if (layoutOutput == 2) {
            if (this.state.videoElements.length != prevState.videoElements.length) {
                if (this.state.videoElements.length <= 4) {
                    for (var uuid in this.peerStreams) {
                        VideoGateway.changeLayer(uuid, 1);
                    }

                } else {
                    for (var uuid in this.peerStreams) {
                        if (this.state.screenSharingUID !== uuid)
                            VideoGateway.changeLayer(uuid, 0);
                        else
                            VideoGateway.changeLayer(uuid, 1);
                    }
                }
            }
        } else {

            if (prevState.mainVideoElement != this.state.mainVideoElement) {


                if (this.state.screenSharing && this.state.screenSharingUID === this.state.mainVideoElement) {
                    this.props.socket.emit('shareScreenStatus', { conversation: this.state.conversation._id, uid: this.state.screenSharingUID, value: true });
                } else if (this.state.screenSharing && this.state.screenSharingUID !== this.state.mainVideoElement) {
                    this.props.socket.emit('shareScreenStatus', { conversation: this.state.conversation._id, uid: this.state.screenSharingUID, value: false });
                }


                if (this.state.mainVideoElement == 'local-video') {

                    for (var uuid in this.peerStreams) {
                        if (this.state.screenSharingUID !== uuid)
                            VideoGateway.changeLayer(uuid, 2);
                        else
                            VideoGateway.changeLayer(uuid, 1);

                    }

                    return;
                }


                if (this.peerStreams[this.state.mainVideoElement])
                    VideoGateway.changeLayer(this.state.mainVideoElement, 1);

                for (var uuid in this.peerStreams) {
                    if (uuid == this.state.mainVideoElement) {
                        continue;
                    }
                    if (this.state.screenSharingUID !== uuid)
                        VideoGateway.changeLayer(uuid, 2);
                    else {
                        VideoGateway.changeLayer(uuid, 1);
                    }
                }

            }
        }
        // novi task kojim se zabranjuje autoRecord samo manuelno pokretanje snimanja
        // if (this.state.call && this.checkSettingsForHostVideoRecord() && typeof window !== 'undefined' && !this.state.recording && this.audioVideoStream && !this.state.autoRecord) {
        //     this.setState({ autoRecord: true }, () => {
        //         this.handleRecording();
        //     })
        // }

        if (this.state.licence && this.state.licence.length && this.state.licenceName && this.state.licenceDuration && this.state.licenceName != -1 && this.state.licenceDuration != -1 && (this.state.licenceName != prevState.licenceName || this.state.licenceDuration != prevState.licenceDuration)) {
            for (let i = 0; i < this.state.licence.length; i++) {

                if (this.state.licence[i].name == this.state.licenceName) {
                    if (this.state.licenceDuration == 1) {
                        this.setState({
                            usersLicence: this.state.licence[i]._id,
                            usersLicencePrice: this.state.licence[i].price1,
                            usersLicenceName: this.props.lang == 'en' ? this.state.licence[i].name : this.state.licence[i].nameSv,  /*Object.translate(this.state.licence[i], 'name', 'en')*/
                        })
                    } else if (this.state.licenceDuration == 3) {
                        this.setState({
                            usersLicence: this.state.licence[i]._id,
                            usersLicencePrice: this.state.licence[i].price3,
                            usersLicenceName: this.props.lang == 'en' ? this.state.licence[i].name : this.state.licence[i].nameSv, /*Object.translate(this.state.licence[i], 'name', 'en')*/
                        })
                    } else if (this.state.licenceDuration == 6) {
                        this.setState({
                            usersLicence: this.state.licence[i]._id,
                            usersLicencePrice: this.state.licence[i].price6,
                            usersLicenceName: this.props.lang == 'en' ? this.state.licence[i].name : this.state.licence[i].nameSv,
                        })
                    } else if (this.state.licenceDuration == 12) {
                        this.setState({
                            usersLicence: this.state.licence[i]._id,
                            usersLicencePrice: this.state.licence[i].price12,
                            usersLicenceName: this.props.lang == 'en' ? this.state.licence[i].name : this.state.licence[i].nameSv,
                        })
                    } else if (this.state.licenceDuration == 0.004107) {
                        this.setState({
                            usersLicence: this.state.licence[i]._id,
                            usersLicencePrice: this.state.licence[i].callPrice,
                            usersLicenceName: this.props.lang == 'en' ? this.state.licence[i].name : this.state.licence[i].nameSv,
                        })
                    }

                }

            }

        }


    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, {}, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, {}, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, {}, fields, restart));
        }
    }



    openConversation = (conversation, callback) => {

        this.setState({
            conversation: conversation,
            journalNote: false,
            referral: null,
            journalNoteIntelligentSections: null,
            loseUploadedFiles: null,
            isDragging: false


        }, () => {
            // if (this.state.conversation && this.state.conversation._id && this.state.openedJournalNotes.indexOf(this.state.conversation._id) != -1) {
            //     this.findHealthCareFormular()
            // }
            // if (this.state.conversation && this.state.conversation._id && this.state.openedReferralPreview.indexOf(this.state.conversation._id) != -1) {
            //     this.setState({ referral: this.state.conversation.referral })
            // }

        });
        let conversations = this.state.conversations;
        for (let i = 0; i < conversations.length; i++) {
            if (conversations[i]._id == conversation) {
                conversations[i]._newMessage = null;
            }
        }
        this.setState({
            conversations
        }, callback)

        this.setState({
            page: 0
        }, () => {
            this.props.socket.emit('messages', { conversation: this.state.conversation._id, page: this.state.page })
        })
    }
    sendHealthCare = (item) => {
        this.setState({ message: item.name, healthCareId: item._id }, () => this.sendMessage())
    }
    checkUploadedFiles = (item) => {
        if (this.state.document || this.state.image || this.state.video) {
            this.setState({ loseUploadedFiles: item })
        } else {
            this.openConversation(item)
        }

    }

    updateJournalNote = (journalNote) => {

        let obj = {
            conversation: this.state.conversation._id,
            journalNote: journalNote
        }

        this.props.socket.emit('updateJournalNote', obj);
    }

    sendMessageFromDragAndDrop = async (conversationId, type, url) => new Promise((resolve, reject) => {
        if (!conversationId || !type || !url) return reject({ error: 'asdf' });
        let obj = {
            conversation: conversationId,
            message: this.state.message,
        }

        switch (type) {
            case 'document': { obj.document = url; break; }
            case 'image': { obj.image = url; break; }
            case 'video': { obj.video = url; break; }
        }

        this.props.socket.emit('sendMessage', obj);

        this.setState({
            message: '',
            document: null,
            image: null,
            video: null,
            healthCareId: null
        }, () => {
            return resolve(true);
        })
    })

    sendMessage = () => {
        if (!this.state.message && !this.state.document && !this.state.image && !this.state.video) {
            return;
        }



        if (!this.state.message.trim() && !this.state.document && !this.state.image && !this.state.video) {
            return;
        }


        if (!this.state.conversation) {
            return;
        }

        let obj = { conversation: this.state.conversation._id, message: this.state.message };
        if (this.state.document) {
            obj.document = this.state.document;
        }
        if (this.state.image) {
            obj.image = this.state.image;
        }
        if (this.state.video) {
            obj.video = this.state.video;
        }
        if (this.state.healthCareId) {
            obj.healthCareId = this.state.healthCareId;
        }


        this.props.socket.emit('sendMessage', obj);

        this.setState({
            message: '',
            document: null,
            image: null,
            video: null,
            healthCareId: null
        })
    }

    setTranslationLanguage = (language) => {
        this.props.socket.emit('setTranslationLanguage', { conversation: this.state.conversation._id, language: language });
    }

    emitUserTyping = () => {
        if (!this.state.conversation) {
            return;
        }

        if (this.typingTimer) {
            clearTimeout(this.typingTimer);
        }

        this.typingTimer = setTimeout(() => {
            this.props.socket.emit('userEndTyping', { conversation: this.state.conversation._id })
        }, 500);

        this.props.socket.emit('userTyping', { conversation: this.state.conversation._id })
    }


    close = (conversation) => {
        this.props.socket.emit('closeConversation', { conversation: conversation });
        this.setState({ conversation: null })
    }
    openCase = (conversation) => {

        this.props.socket.emit('openConversation', { conversation: conversation._id });

        if (conversation.referral && conversation.referral._id) {
            this.setState({
                loading: true
            }, () => {
                fetch(API_ENDPOINT + '/referrals/open/' + conversation.referral._id, {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken') ? localStorage.getItem("authToken") : localStorage.getItem('guestToken')}` : null,
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((result) => {


                    this.get();
                    this.setState({
                        loading: false
                    })
                })
            })
        }
    }
    closeCase = (conversation) => {

        this.props.socket.emit('closeConversation', { conversation: conversation._id });
        this.setState({ conversation: null })

    }
    open = (conversation) => {
        this.props.socket.emit('openConversation', { conversation: conversation });
    }

    search = () => {
        this.setState({ addPrevConversations: false }, () => {
            this.props.socket.emit('conversations', { search: this.state.search });
        })

    }

    /**
    * Function for file upload
    * @author   Milan Stanojevic
    */
    uploadDocument = (e, returnUrl = false) => new Promise((resolve, reject) => {
        let input = e.target;
        if (input.files && input.files[0]) {

            let fsize = input.files[0].size;
            let file = Math.round((fsize / 1024))
            this.setState({
                uploadTimestampDoc: Math.floor(new Date().getTime() / 1000)
            })
            if (file > 5120) {
                this.setState({ error: "File size limit has been reached! (max 5 MB)".translate(this.props.lang) })
            } else {

                if (!returnUrl) this.setState({
                    _documentUploading: this.state.conversation
                })

                let formData = new FormData();
                formData.append('file', input.files[0]);
                formData.append('group', this.props.selectedGroup)
                formData.append('fileType', 'clinicChat')

                fetch(API_ENDPOINT + '/chat/upload/document', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken') ? localStorage.getItem("authToken") : localStorage.getItem('guestToken')}` : null,

                    },
                    body: formData
                }).then((res) => res.json()).then((img) => {
                    if (returnUrl) {
                        if (img) return resolve(img);
                        return reject({ error: 'Error while uploading document!' })
                    }
                    this.setState({
                        document: img
                    })
                    if (this.state._documentUploading._id != this.state.conversation._id) {
                        this.setState({ succesUpload: 1 })
                    }
                    this.setState({ conversation: this.state._documentUploading }, () => {
                        this.sendMessage()
                        this.setState({
                            _documentUploading: null
                        })
                    })

                    return resolve(true);

                });
            }
        }
    })

    /**
    * Function for image upload
    * @author   Milan Stanojevic
    */
    uploadImage = (e, returnUrl = false) => new Promise((resolve, reject) => {
        let input = e.target;
        if (input.files && input.files[0]) {

            let fsize = input.files[0].size;
            let file = Math.round((fsize / 1024))
            this.setState({
                uploadTimestamp: Math.floor(new Date().getTime() / 1000)
            })
            if (file > 10240) {
                this.setState({ error: "File size limit has been reached! (max 10 MB)".translate(this.props.lang) })
            } else {

                if (!returnUrl) this.setState({
                    _imageUploading: this.state.conversation
                })

                let formData = new FormData();
                formData.append('file', input.files[0]);
                formData.append('group', this.props.selectedGroup)
                formData.append('fileType', 'clinicChat')

                fetch(API_ENDPOINT + '/chat/upload/image', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken') ? localStorage.getItem("authToken") : localStorage.getItem('guestToken')}` : null,

                    },
                    body: formData
                }).then((res) => res.text()).then((img) => {
                    if (returnUrl) {
                        if (img) return resolve(img);
                        return reject({ error: 'Error while uploading image!' });
                    }
                    this.setState({
                        image: img
                    })
                    if (this.state._imageUploading._id != this.state.conversation._id) {
                        this.setState({ succesUpload: 2 })
                    }
                    this.setState({ conversation: this.state._imageUploading }, () => {
                        this.sendMessage()
                        this.setState({
                            _imageUploading: null,

                        })
                    })

                    return resolve(true);
                });
            }
        }
    })
    /**
    * Function for video upload
    * @author   Aleksandar Dabic
    */
    uploadVideo = (e, returnUrl) => new Promise((resolve, reject) => {
        let input = e.target;
        if (input.files && input.files[0]) {

            let fsize = input.files[0].size;
            let file = Math.round((fsize / 1024))
            this.setState({
                uploadTimestampVideo: Math.floor(new Date().getTime() / 1000)
            })
            if (file > 262144) {
                this.setState({ error: "File size limit has been reached! (max 256 MB)".translate(this.props.lang) })
            } else {
                if (!returnUrl) this.setState({
                    _videoUploading: this.state.conversation

                })

                let formData = new FormData();
                formData.append('file', input.files[0]);
                formData.append('group', this.props.selectedGroup)
                formData.append('fileType', 'clinicChat')

                fetch(API_ENDPOINT + '/chat/upload/video', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken') ? localStorage.getItem("authToken") : localStorage.getItem('guestToken')}` : null,

                    },
                    body: formData
                }).then((res) => res.text()).then((video) => {
                    if (returnUrl) {
                        if (video) return resolve(video);
                        return reject({ error: 'Error while uploading video!' })
                    }
                    this.setState({
                        video: video
                    })
                    if (this.state._videoUploading._id != this.state.conversation._id) {
                        this.setState({ succesUpload: 3 })
                    }
                    this.setState({ conversation: this.state._videoUploading }, () => {
                        this.sendMessage()
                        this.setState({
                            _videoUploading: null
                        })
                    })
                    return resolve(true);
                });
            }
        }
    })

    addMember = (user) => {

        this.props.socket.emit('addMember', { conversation: this.state.conversation._id, member: user });
    }

    raiseHand = () => {
        this.setState({
            hand: true,
            localHand: true
        }, () => {
            this.props.socket.emit('raiseHand', { conversation: this.state.conversation._id });

        })
    }




    handDown = () => {
        this.setState({
            hand: false,
            localHand: false
        }, () => {
            this.props.socket.emit('handDown', { conversation: this.state.conversation._id });
        })
    }


    attachSinkId = (element, deviceId) => {

        if (typeof element.sinkId !== 'undefined') {

            element.setSinkId(deviceId).then(() => {
                // console.log(`Success, audio output device attached: ${deviceId}`);
            })
                .catch(error => {
                    let errorMessage = error;
                    if (error.name === 'SecurityError') {
                        errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
                    }
                    console.error(errorMessage);
                    // Jump back to first output device in the list as it's the default.
                    //this.setState({ selectedAudioOutputIndex: 0 })
                });

        } else {
            console.warn('Browser does not support output device selection.');
        }

    }

    attachSinkIdTestSound = (deviceId) => {

        if (typeof this.audioRef.sinkId !== 'undefined') {

            this.audioRef.setSinkId(deviceId).then(() => {
                console.log(`Success, audio output device attached: ${deviceId}`);
                // this.setState({ audioOutputDevice: deviceId })

            })
                .catch(error => {
                    let errorMessage = error;
                    if (error.name === 'SecurityError') {
                        errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
                    }
                    console.error(errorMessage);
                    // Jump back to first output device in the list as it's the default.
                    //this.setState({ selectedAudioOutputIndex: 0 })
                });

        } else {
            console.warn('Browser does not support output device selection.');
        }

    }


    getRecurring = () => {
        if (this.state.form && this.state.form.recurringObj && this.state.form.recurringObj.frequencyType) {
            let form = this.state.form;


            let obj = {}
            let recurringObj = this.state.form.recurringObj;

            obj = recurringObj;

            delete form.recurringObj
            // console.log(obj)
            form = { ...form, ...obj }
            this.setState({ form })
        }

    }
    checkRecurring = (data) => {
        let obj = { ...data }
        let recurringObj = {};

        if (obj.frequencyType) {
            recurringObj.frequencyType = obj.frequencyType;
            delete obj.frequencyType;
        }
        if (obj.frequencyDayText) {
            if (recurringObj.frequencyType == 'day')
                recurringObj.frequencyDayText = obj.frequencyDayText;
            delete obj.frequencyDayText;
        }
        if (obj.frequencyMonthScheduled) {
            if (recurringObj.frequencyType == 'month')
                recurringObj.frequencyMonthScheduled = obj.frequencyMonthScheduled;
            delete obj.frequencyMonthScheduled;
        }
        if (obj.frequencyDayScheduledDateFrom) {
            if (recurringObj.frequencyType == 'day') {
                recurringObj.frequencyDayScheduledDateFrom = obj.frequencyDayScheduledDateFrom;
                obj.scheduledDate = obj.frequencyDayScheduledDateFrom;
            }

            delete obj.frequencyDayScheduledDateFrom;
        }
        if (obj.frequencyDayScheduledDateTo) {
            if (recurringObj.frequencyType == 'day')
                recurringObj.frequencyDayScheduledDateTo = obj.frequencyDayScheduledDateTo;
            delete obj.frequencyDayScheduledDateTo;
        }
        if (obj.frequencyMonthScheduledDateFrom) {
            if (recurringObj.frequencyType == 'month') {
                recurringObj.frequencyMonthScheduledDateFrom = obj.frequencyMonthScheduledDateFrom;
                obj.scheduledDate = obj.frequencyMonthScheduledDateFrom;
            }

            delete obj.frequencyMonthScheduledDateFrom;
        }
        if (obj.frequencyMonthScheduledDateTo) {
            if (recurringObj.frequencyType == 'month')
                recurringObj.frequencyMonthScheduledDateTo = obj.frequencyMonthScheduledDateTo;
            delete obj.frequencyMonthScheduledDateTo;
        }
        if (obj.frequencyWeekScheduledDateFrom) {
            if (recurringObj.frequencyType == 'week') {
                recurringObj.frequencyWeekScheduledDateFrom = obj.frequencyWeekScheduledDateFrom;
                obj.scheduledDate = obj.frequencyWeekScheduledDateFrom;
            }

            delete obj.frequencyWeekScheduledDateFrom;
        }
        if (obj.frequencyWeekScheduledDateTo) {
            if (recurringObj.frequencyType == 'week')
                recurringObj.frequencyWeekScheduledDateTo = obj.frequencyWeekScheduledDateTo;
            delete obj.frequencyWeekScheduledDateTo;
        }
        if (obj.checkedMonday) { if (recurringObj.frequencyType == 'week') recurringObj.checkedMonday = obj.checkedMonday; delete obj.checkedMonday; }
        if (obj.checkedTuesday) { if (recurringObj.frequencyType == 'week') recurringObj.checkedTuesday = obj.checkedTuesday; delete obj.checkedTuesday; }
        if (obj.checkedWednesday) { if (recurringObj.frequencyType == 'week') recurringObj.checkedWednesday = obj.checkedWednesday; delete obj.checkedWednesday; }
        if (obj.checkedThursday) { if (recurringObj.frequencyType == 'week') recurringObj.checkedThursday = obj.checkedThursday; delete obj.checkedThursday; }
        if (obj.checkedFriday) { if (recurringObj.frequencyType == 'week') recurringObj.checkedFriday = obj.checkedFriday; delete obj.checkedFriday; }
        if (obj.checkedSaturday) { if (recurringObj.frequencyType == 'week') recurringObj.checkedSaturday = obj.checkedSaturday; delete obj.checkedSaturday; }
        if (obj.checkedSunday) { if (recurringObj.frequencyType == 'week') recurringObj.checkedSunday = obj.checkedSunday; delete obj.checkedSunday; }

        obj.recurringObj = recurringObj;
        return obj;
    }

    createConversation = (data) => {
        if (data && data.frequencyType) {
            data = this.checkRecurring(data);
        }
        if (this.state.conversationShedule && (!data.scheduledDate || !data.scheduledTime || !data.timezone)) {
            this.setState({
                conversationSheduleError: true
            })

        } else {
            data.group = this.props.selectedGroup;
            data.host = this.props.uData._id
            data.email = this.props.uData.email
            if (data.name == '' || !data.name) {
                this.setState({
                    error: 'You have to give some name to the meeting!'
                })
            } else {
                this.setState({
                    _saving: true,
                    openInNewTab: data.openInNewTab
                }, () => {
                    this.props.socket.emit('createConversation', data);


                    // addEvent(data);

                    // this.setState({
                    //     calendarConnectModal: data
                    // })
                })
            }

        }


    }

    editConversation = (data) => {
        if (data && data.frequencyType) {
            data = this.checkRecurring(data);
        }
        if (data.name == '' || !data.name) {
            this.setState({
                error: 'You have to give some name to the meeting!'
            })
        } else {
            this.setState({
                _saving: true,
                openInNewTab: data.openInNewTab,
                form: null,
                passcodeCheckbox: null
            }, () => {
                this.props.socket.emit('editConversation', data);
                setTimeout(() => {
                    if (data && data.eventGmail) {
                        this.getGmailEvents(data, (events) => {
                            this.updateGmailEvent(data, events);
                        })
                    } else if (data && data.outlookEvent) {
                        this.connectWithOutlook({
                            conversation: data._id,
                            update: true
                        })
                    }
                }, 3000);


                // this.updateOutlookEvent(data)
            })
        }

    }


    signInGoogle = () => {

        return new Promise((resolve, reject) => {
            if (this.gapi) {
                resolve(this.gapi);
                return;
            }

            var gapi = window.gapi
            var CLIENT_ID = '884844421012-efq8lc9gim4e52510i0tnst1q0hfkcnr.apps.googleusercontent.com';
            var API_KEY = 'AIzaSyCDx-ZCVWi8K3ge5W6vhsXqreNFhH2-1XU';
            var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
            var SCOPES = "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events";

            gapi.load('client:auth2', () => {

                gapi.client.init({
                    apiKey: API_KEY,
                    clientId: CLIENT_ID,
                    discoveryDocs: DISCOVERY_DOCS,
                    scope: SCOPES,
                })

                gapi.client.load('calendar', 'v3', () => gapi.auth2.getAuthInstance().signIn()
                    .then(() => {
                        this.gapi = gapi;
                        resolve(gapi);
                    }))
            })
        })
    }

    getGmailEvents = (data, callback) => {

        this.signInGoogle().then((gapi) => {
            gapi.client.calendar.events.list({
                'calendarId': 'primary',
                'timeMin': (new Date()).toISOString(), // vratitti datum iz konverzacije
                'showDeleted': false,
                'singleEvents': true,
                'maxResults': 10,
                'orderBy': 'startTime'
            }).then(function (response) {
                var events = response.result.items;
                callback(events)
            });

        })
    }



    updateGmailEvent = (dataa, events) => {
        console.log(dataa)
        console.log(events, "eventssssssss")

        let data = dataa

        let duration = '00:30:00';
        let startDate = moment.unix(new Date(data.scheduledDate + ' ' + data.scheduledTime).getTime() / 1000);
        let msDuration = (Number(duration.split(':')[0]) * 60 * 60 + Number(duration.split(':')[1]) * 60 + Number(duration.split(':')[2])) * 1000;
        let endDate = new Date(startDate + msDuration);
        let isoStartDate = new Date(startDate - new Date().getTimezoneOffset() * 60 * 1000).toISOString().split(".")[0];
        let isoEndDate = new Date(endDate.getTime() - (new Date().getTimezoneOffset(data.timezone)) * 60 * 1000).toISOString().split(".")[0];
        let recuringDateUntil;
        // console.log(isoStartDate + ' ' + isoEndDate)
        let recurring = data.recurringObj
        let frequency;
        let frequencyInterval;
        let frequencyDayOfWeek = [];

        if (recurring && recurring.frequencyType && recurring.frequencyType == 'day') {
            let endDateRecuring = moment.unix(new Date(recurring.frequencyDayScheduledDateTo).getTime() / 1000);
            recuringDateUntil = new Date(endDateRecuring - new Date().getTimezoneOffset() * 60 * 1000).toISOString().replace(/-|:|\./g, '').replace('000', '');
            frequency = 'DAILY' + ';UNTIL=' + recuringDateUntil + ';INTERVAL=' + recurring.frequencyDayText

        } else if (recurring && recurring.frequencyType && recurring.frequencyType == 'week') {

            let endDateRecuring = moment.unix(new Date(recurring.frequencyWeekScheduledDateTo).getTime() / 1000);
            recuringDateUntil = new Date(endDateRecuring - new Date().getTimezoneOffset() * 60 * 1000).toISOString().replace(/-|:|\./g, '').replace('000', '');

            let arr = []
            let count = 0
            frequencyDayOfWeek = ';BYDAY='
            if (recurring.checkedMonday) {
                count++
                arr.push('MO')
            }
            if (recurring.checkedTuesday) {
                count++
                arr.push('TU')
            }
            if (recurring.checkedWednesday) {
                count++
                arr.push('WE')
            }
            if (recurring.checkedThursday) {
                count++
                arr.push('TH')
            }
            if (recurring.checkedFriday) {
                count++
                arr.push('FR')
            }
            if (recurring.checkedSaturday) {
                count++
                arr.push('SA')
            }
            if (recurring.checkedSunday) {
                count++
                arr.push('SU')
            }

            frequencyDayOfWeek += arr.join(',')
            console.log(frequencyDayOfWeek)

            if (frequencyDayOfWeek) {
                frequencyInterval = ';INTERVAL=1' //+ count
            }

            let countt = ';COUNT=' + count

            frequency = 'WEEKLY' + frequencyDayOfWeek + ';UNTIL=' + recuringDateUntil
            console.log(frequency)

        } else if (recurring && recurring.frequencyType && recurring.frequencyType == 'month') {
            let endDateRecuring = moment.unix(new Date(recurring.frequencyMonthScheduledDateTo).getTime() / 1000);
            recuringDateUntil = new Date(endDateRecuring - new Date().getTimezoneOffset() * 60 * 1000).toISOString().replace(/-|:|\./g, '').replace('000', '');
            frequency = 'MONTHLY' + ';BYMONTHDAY=' + recurring.frequencyMonthScheduled + ';UNTIL=' + recuringDateUntil
        } else {
            frequency = 'DAILY;COUNT=1'
        }

        this.signInGoogle()
            .then((gapi) => {
                gapi.client.calendar.events.update({
                    "calendarId": "primary",
                    "eventId": dataa.eventGmail,
                    "alwaysIncludeEmail": false,
                    "sendNotifications": true,
                    "sendUpdates": "all",
                    "supportsAttachments": true,
                    "prettyPrint": true,
                    "alt": "json",
                    "resource": {
                        "recurrence": [
                            `RRULE:FREQ=${frequency}`
                        ],
                        "start": {
                            'dateTime': isoStartDate,
                            'timeZone': `${data.timezone}`
                        },
                        "end": {
                            'dateTime': isoEndDate,
                            'timeZone': `${data.timezone}`
                        },
                        "summary": `${data.name}`
                    }
                }).then(function (response) {

                    // Handle the results here (response.result has the parsed body).
                    console.log("Response", response);
                },
                    function (err) { console.error("Execute error", err); });

                this.setState({
                    calendarConnectModal: false
                })

            })



    }

    deleteGmailEvent = (data) => {
        this.signInGoogle().then((gapi) => {

            gapi.client.calendar.events.delete({
                "calendarId": "primary",
                "eventId": data.eventGmail,
            })
                .then(function (response) {
                    // Handle the results here (response.result has the parsed body).
                    console.log("Response", response);
                },
                    function (err) { console.error("Execute error", err); });

            this.setState({
                calendarConnectModal: false
            })

        })

    }


    updateOutlookEvent = (data) => {
        fetch(API_ENDPOINT + "/auth/update/callback", {
            method: "POST",
            headers: {
                Authorization: typeof localStorage !== "undefined" ? `Bearer ${localStorage.getItem('authToken') ? localStorage.getItem("authToken") : localStorage.getItem('guestToken')}` : null,
                "content-type": "application/json",
            },
            body: JSON.stringify(data),
        }).then(parseJSON).then(({ result, status }) => {
            if (result.error) {
                this.setState({ error: result.error });
            } else {
                this.get()
            }
        });
    }

    checkVideoElements = () => {
        // let pausedVideos = {};


        for (var uuid in this.peerStreams) {
            let remoteVideo = this.remoteVideo[uuid]

            if (remoteVideo) {
                let visibility = isElementVisible(remoteVideo);
                if (visibility) {
                    VideoGateway.playVideo(uuid);
                    // pausedVideos[uuid] = false;
                } else {
                    if (this.state.videoElements.length > 6) {
                        VideoGateway.pauseVideo(uuid);
                        // pausedVideos[uuid] = true;
                    }
                }

            }
        }


        // if (JSON.stringify(this.state.pausedVideos) != JSON.stringify(pausedVideos)) {
        //     this.setState({
        //         pausedVideos
        //     })
        // }


    }


    generateLayout = (reopen = false) => {
        console.log(`generateLayout(${reopen ? 'true' : 'false'})`)
        let mainVideoElement = 'local-video';


        if (!this.state.pinnedVideo) {
            let layoutOutput = this.state.layoutOutput;
            if (false && this.state.layoutOutput == 2 && this.state.videoElements.length <= 1 && (typeof window !== 'undefined' && !(window.innerWidth >= 700 && window.innerWidth <= 1200))) {
                layoutOutput = -1;
            }

            if (this.state.videoElements.length) {
                if (this.state.activeClient && !this.state.shareOn && layoutOutput != 2) {
                    let uuid = null;

                    for (var key in this.state.videoElements) {
                        if (this.state.videoElements[key] && this.state.videoElements[key] == this.state.activeClient) {
                            uuid = this.state.videoElements[key];
                            break;
                        }
                    }

                    if (uuid !== null) {
                        mainVideoElement = uuid;
                    } else {
                        if (this.state.videoElements.length == 1) {
                            mainVideoElement = this.state.videoElements[0];
                        } else {
                            mainVideoElement = 'local-video';
                        }
                    }


                } else {
                    mainVideoElement = this.state.videoElements[0];
                }
            }

        } else {
            mainVideoElement = this.state.pinnedVideo;
        }

        let localVideo = this.localVideo;

        console.log('localVideo', localVideo?.srcObject?.id);
        console.log('stream', this.audioVideoStream?.id);
        const localVideoStreamId = localVideo?.srcObject?.id;
        const streamId = this.state.shareOn && this.screenStream ? this.screenStream?.id : this.audioVideoStream?.id;

        if (localVideo && localVideoStreamId != streamId) {


            localVideo.autoPlay = true;
            localVideo.autoplay = true;
            localVideo.playsInline = true;
            localVideo.playsinline = true;
            localVideo.muted = true;


            if (this.state.shareOn)
                localVideo.srcObject = this.screenStream;
            else
                localVideo.srcObject = this.audioVideoStream;

        }


        if (mainVideoElement == this.state.mainVideoElement && !reopen) {
            return;
        }


        this.setState({
            mainVideoElement,
        }, () => {

            for (var uuid in this.peerStreams) {
                let remoteVideo = this.remoteVideo[uuid]

                const remoteVideoStreamId = remoteVideo?.srcObject?.id;
                const streamId = this.peerStreams[uuid]?.id;


                if (remoteVideo && (iosDevice() || (remoteVideoStreamId != streamId))) {
                    remoteVideo.autoplay = true;
                    remoteVideo.autoPlay = true;
                    remoteVideo.playsInline = true;
                    remoteVideo.playsinline = true;
                    remoteVideo.muted = false;

                    remoteVideo.srcObject = this.peerStreams[uuid];


                    if (this.state.audioOutputDevice)
                        this.attachSinkId(remoteVideo, this.state.audioOutputDevice);
                }

                this.checkVideoElements();



            }

            let localVideo = this.localVideo;
            const localVideoStreamId = localVideo?.srcObject?.id;
            const streamId = this.state.shareOn && this.screenStream ? this.screenStream?.id : this.audioVideoStream?.id;

            if (localVideo && localVideoStreamId != streamId) {



                localVideo.autoPlay = true;
                localVideo.autoplay = true;
                localVideo.playsInline = true;
                localVideo.playsinline = true;
                localVideo.muted = true;


                if (this.state.shareOn)
                    localVideo.srcObject = this.screenStream;
                else
                    localVideo.srcObject = this.audioVideoStream;


            }




        });

    }

    checkConversationHostLicence = (conversation) => {
        return new Promise((resolve, reject) => {
            if (conversation.host) {
                fetch(API_ENDPOINT + "/data/users/conversation/host/check", {
                    method: "POST",
                    headers: {
                        Authorization: typeof localStorage !== "undefined" ? `Bearer ${localStorage.getItem('authToken') ? localStorage.getItem("authToken") : localStorage.getItem('guestToken')}` : null,
                        "content-type": "application/json",
                    },
                    body: JSON.stringify({ host: conversation.host }),
                }).then(parseJSON).then(({ result, status }) => {
                    if (result.error) {
                        this.setState({ error: result.error, guestImageVideoInput: false }, () => resolve(false));
                    } else {
                        if (result.activeLicence && result.activeLicence != 'basic') {
                            this.setState({
                                guestImageVideoInput: true
                            }, () => resolve(true))
                        } else {
                            if (result.activeLicence && result.activeLicence != 'basic') {
                                this.setState({
                                    guestImageVideoInput: true
                                }, () => resolve(true))
                            } else {
                                this.setState({
                                    guestImageVideoInput: false
                                }, () => resolve(false))
                            }
                        }
                    }
                });

            } else {
                this.setState({
                    guestImageVideoInput: false
                }, () => resolve(false))
            }

        })
    }
    showCopiedLink = () => {
        this.setState({
            linkCopied: true
        })
        setTimeout(() => {
            this.setState({
                linkCopied: false
            })
        }, 1000)
    }


    renderOtherVideoElements = (renderMainVideoElement = true, renderOtherElements = true) => {
        let numberOfRows = 1;
        let numberOfColumnsOnScreen = typeof window != 'undefined' && window.innerWidth < 768 && window.innerWidth < window.innerHeight ? 3 : 14;



        let videoElementHeight = (this.callArea && this.callArea.offsetWidth) / numberOfColumnsOnScreen;
        let videoElementWidth = videoElementHeight;
        let otherVideosContainerHeight = (100 * videoElementHeight * numberOfRows) / (this.callArea && this.callArea.offsetHeight - 80);


        let localVideoPosition = null;

        if (this.localVideo && this.callArea) {
            localVideoPosition = getObjectFitSize(true, this.callArea.offsetWidth, (this.callArea.offsetHeight - 80 - videoElementHeight), this.localVideo.videoWidth, this.localVideo.videoHeight);
        }

        let videoElementsArray = [];

        let layoutOutput = this.state.layoutOutput;
        if (false && this.state.layoutOutput == 2 && this.state.videoElements.length <= 1 && (typeof window !== 'undefined' && !(window.innerWidth >= 700 && window.innerWidth <= 1200))) {
            layoutOutput = -1;
        }


        if (layoutOutput == 2) {
            if (this.state.videoElements.length > 20) {

                let arr1 = this.state.videoElements.slice().splice(0, 9);
                let arr2 = this.state.videoElements.slice().splice(9);
                arr2.sort((a, b) => {
                    return this.tempVolumes[b] - this.tempVolumes[a]
                })

                videoElementsArray = [...arr1, ...arr2];
            } else {
                videoElementsArray = this.state.videoElements;
            }

        } else {
            videoElementsArray = this.state.videoElements;
        }

        return (
            <>
                {
                    videoElementsArray.map((item, idx) => {
                        if (!renderMainVideoElement && this.state.mainVideoElement == item) {
                            return null;
                        }
                        if (!renderOtherElements && this.state.mainVideoElement != item) {
                            return null;
                        }

                        let user = this.state.conversation.users.filter(member => member.uid == item);
                        if (user.length) {
                            user = user[0];
                        } else {
                            if (this.state.guestNames[item]) {
                                user = {
                                    uid: item,
                                    name: this.state.guestNames[item],
                                    bankIdVerified: this.state.guestsVerificationStatus ? this.state.guestsVerificationStatus[item] : null
                                }
                            } else {
                                user = null;
                            }
                        }

                        if (user && user.uid) {
                            if (this.state.guestNames[user.uid]) {
                                user.name = this.state.guestNames[user.uid];
                                user.bankIdVerified = this.state.guestsVerificationStatus ? this.state.guestsVerificationStatus[user.uid] : null;
                            }
                        }

                        let videoPosition = null;

                        if (this.remoteVideo[item] && this.callArea) {
                            videoPosition = getObjectFitSize(true, this.callArea.offsetWidth, (this.callArea.offsetHeight - 80 - videoElementHeight), this.remoteVideo[item].videoWidth, this.remoteVideo[item].videoHeight);
                        }



                        return (
                            layoutOutput && !this.state.shareOn && layoutOutput != '-1' ?
                                <div key={item}
                                    className={!this.state.cameraStatus[item] ? `video-container camera-off ${idx <= 13 ? 'col' + (idx + 1) : ''} ${idx >= 14 ? 'col-off' : ''}` : `video-container ${idx <= 13 ? 'col' + (idx + 1) : ''} ${idx >= 14 ? 'col-off' : ''}`}>
                                    <video class="remote-video" ref={(node) => this.remoteVideo[item] = node}></video>
                                    {user ?
                                        <div className="user-info"
                                        >
                                            <img src={user.profilePicture ? API_ENDPOINT + user.profilePicture : profilePicture} />
                                            {/* {this.state.mainVideoElement == item ? */}
                                            <p style={{ display: 'flex', alignItems: 'center' }}>{user.bankIdVerified === true ? <Isvg src={secureIcon} style={{ width: 32, minWidth: 32 }} /> : ''} {user ? user.name : ''} {user.bankIdVerified === true ? <>({'BankID verified'.translate(this.props.lang)})</> : ''}</p>
                                            {/* :
                                                <p onMouseEnter={(e) => {
                                                    if (this.state.mainVideoElement == item) {
                                                        return;
                                                    }

                                                    this.setState({
                                                        memberTooltip: {
                                                            client: item,
                                                            top: layoutOutput == 2 ? e.clientY + 5 : e.clientY - 50,
                                                            left: getOffset(e.target).left - (e.target.offsetWidth / 2) + 20,
                                                            name: user ? user.name : '',

                                                        }
                                                    })

                                                }}

                                                    onMouseLeave={(e) => {
                                                        if (this.state.mainVideoElement == 'local-video') {
                                                            return;
                                                        }

                                                        this.setState({
                                                            memberTooltip: null
                                                        })

                                                    }}
                                                    style={{ display: 'flex', alignItems: 'center' }}
                                                >
                                                    {
                                                        user.bankIdVerified === true ?
                                                            <Isvg src={secureIcon} style={{ width: 16, minWidth: 16 }} />
                                                            :
                                                            ''
                                                    }
                                                    {
                                                        user.name ?
                                                            (user.name + (user.bankIdVerified === true ? ('(' + 'BankID verified'.translate(this.props.lang) + ')') : '')).length > 7 ?
                                                                (user.name + (user.bankIdVerified === true ? ('(' + 'BankID verified'.translate(this.props.lang) + ')') : '')).substring(0, 7) + '...'
                                                                : (user.name + (user.bankIdVerified === true ? ('(' + 'BankID verified'.translate(this.props.lang) + ')') : ''))
                                                            : ''}

                                                </p>
                                            } */}
                                        </div>
                                        :
                                        null
                                    }
                                    {
                                        this.state.hands[item] ?

                                            <div className="hand">
                                                <Player
                                                    onEvent={(event) => {
                                                        if (event === "load" && this.player && this.player.current) this.player.current.play();
                                                    }}
                                                    ref={this.player} // set the ref to your class instance
                                                    autoplay={true}
                                                    loop={true}
                                                    controls={true}
                                                    src="/12738-wave.json"
                                                    renderer={'svg'}
                                                    style={this.state.mainVideoElement == item && this.state.videoElements.length == 1 ? { height: "128px", width: "128px" } : { height: "48px", width: "48px" }}
                                                ></Player>
                                            </div>

                                            :

                                            null
                                    }

                                    {
                                        this.state._connecting[item] ?
                                            <div className="connecting" style={{ marginLeft: `-${(videoElementWidth - 20) / 2}px`, marginTop: `-${(videoElementWidth - 20) / 2}px` }}>
                                                <Player
                                                    autoplay={true}
                                                    loop={true}
                                                    src="/lf30_editor_l5cxzdyf.json"
                                                    renderer={'svg'}
                                                    style={{ height: `${videoElementWidth - 20}px`, width: `${videoElementWidth - 20}px` }}
                                                ></Player>
                                            </div>
                                            :
                                            null
                                    }

                                    {
                                        !this.state.micStatus[item] ?
                                            <div className="microphone-muted" style={this.remoteVideo[item] && this.state.mainVideoElement == item ? { /*top: videoPosition.y + videoPosition.height - 40, left: videoPosition.x + videoPosition.width - 40*/ } : {}}>
                                                <Isvg src={micMutedIcon} />
                                            </div>
                                            :
                                            null
                                    }

                                    {/* {
                                        this.state.pinnedVideo == item ?
                                            <button className="pin-video" onClick={() => this.setState({ pinnedVideo: null }, this.generateLayout)}>
                                                <Isvg src={unpinIcon} />
                                            </button>

                                            :
                                            <button className="pin-video" onClick={() => this.setState({ pinnedVideo: item }, this.generateLayout)}>
                                                <Isvg src={pinIcon} />
                                            </button>
                                    } */}



                                    {
                                        this.remoteVideo[item] && this.state.cameraStatus[item] && this.props.uData ?
                                            <button className="take-picture" style={{ top: '50%', marginTop: -10, left: 20 }} onClick={() => {

                                                var canvas = document.createElement('canvas');
                                                canvas.width = this.remoteVideo[item].videoWidth;
                                                canvas.height = this.remoteVideo[item].videoHeight;
                                                document.body.appendChild(canvas);

                                                var context = canvas.getContext('2d');
                                                context.drawImage(this.remoteVideo[item], 0, 0, this.remoteVideo[item].videoWidth, this.remoteVideo[item].videoHeight);

                                                canvas.toBlob((blob) => {
                                                    let file = new File([blob], "screenshot.png", { type: "image/png" })
                                                    this.setState({
                                                        takePhotoModal: {
                                                            message: '',
                                                            previewImage: canvas.toDataURL('image/png'),
                                                            image: file
                                                        }
                                                    })
                                                    canvas.remove();

                                                }, 'image/png');



                                            }}>
                                                <Isvg src={photoIcon} /><div className="chat-icons-tooltip">{'Take photo'.translate(this.props.lang)}</div>
                                            </button>
                                            :

                                            null
                                    }

                                    {
                                        // this.remoteVideo[item] && this.state.mainVideoElement == item ?
                                        //     <div className="timer" style={{/* top: this.state.fullScreen ? '90vh' : (videoPosition.y + videoPosition.height - 40),*/ right: videoPosition.x + 10 }}><Timer startTime={this.state.videoStartDateTime} /></div>
                                        //     :
                                        //     null

                                    }

                                </div>
                                :
                                <div key={item} style={
                                    this.state.speaking &&
                                        this.state.speaking[item] &&
                                        this.state.activeClient != item ?
                                        { opacity: this.state.fullScreen ? this.state.mainVideoElement == item ? 1 : 0 : 1, border: this.state.mainVideoElement == item ? '4px solid transparent' : '4px solid #60BEBB', width: this.state.mainVideoElement == item ? this.state.fullScreen ? '100vw' : '100%' : `${videoElementWidth}px`, height: this.state.mainVideoElement == item ? this.state.fullScreen ? '100vh' : `${100 - otherVideosContainerHeight}%` : `${videoElementHeight}px`, }
                                        :
                                        { opacity: this.state.fullScreen ? this.state.mainVideoElement == item ? 1 : 0 : 1, border: '4px solid transparent', width: this.state.mainVideoElement == item ? this.state.fullScreen ? '100vw' : '100%' : `${videoElementWidth}px`, height: this.state.mainVideoElement == item ? this.state.fullScreen ? '100vh' : `${100 - otherVideosContainerHeight}%` : `${videoElementHeight}px`, objectFit: this.state.mainVideoElement == item ? 'contain' : 'cover' }}

                                    className={!this.state.cameraStatus[item] ? `video-container camera-off ${this.state.mainVideoElement == item ? 'active-element' : ''}` : `video-container ${this.state.mainVideoElement == item ? 'active-element' : ''}`} onClick={() => this.setState({ activeClient: item }, this.generateLayout)}>
                                    <video style={{ objectFit: this.state.mainVideoElement == item ? 'contain' : 'cover' }} class="remote-video" ref={(node) => this.remoteVideo[item] = node}></video>
                                    {user ?
                                        <div className="user-info"
                                        >
                                            <img src={user.profilePicture ? API_ENDPOINT + user.profilePicture : profilePicture} />
                                            {this.state.mainVideoElement == item ?
                                                <p style={{ display: 'flex', alignItems: 'center' }}>{user.bankIdVerified === true ? <Isvg src={secureIcon} style={{ width: 32, minWidth: 32 }} /> : ''} {user ? user.name : ''} {user.bankIdVerified === true ? <>({'BankID verified'.translate(this.props.lang)})</> : ''}</p>
                                                :
                                                <p onMouseEnter={(e) => {
                                                    if (this.state.mainVideoElement == item) {
                                                        return;
                                                    }

                                                    this.setState({
                                                        memberTooltip: {
                                                            client: item,
                                                            top: layoutOutput == 2 ? e.clientY + 5 : e.clientY - 50,
                                                            left: getOffset(e.target).left - (e.target.offsetWidth / 2) + 20,
                                                            name: user ? user.name : '',

                                                        }
                                                    })

                                                }}

                                                    onMouseLeave={(e) => {
                                                        if (this.state.mainVideoElement == 'local-video') {
                                                            return;
                                                        }

                                                        this.setState({
                                                            memberTooltip: null
                                                        })

                                                    }}
                                                    style={{ display: 'flex', alignItems: 'center' }}
                                                >
                                                    {
                                                        user.bankIdVerified === true ?
                                                            <Isvg src={secureIcon} style={{ width: 16, minWidth: 16 }} />
                                                            :
                                                            ''
                                                    }
                                                    {
                                                        user.name ?
                                                            (user.name + (user.bankIdVerified === true ? ('(' + 'BankID verified'.translate(this.props.lang) + ')') : '')).length > 7 ?
                                                                (user.name + (user.bankIdVerified === true ? ('(' + 'BankID verified'.translate(this.props.lang) + ')') : '')).substring(0, 7) + '...'
                                                                : (user.name + (user.bankIdVerified === true ? ('(' + 'BankID verified'.translate(this.props.lang) + ')') : ''))
                                                            : ''}

                                                </p>
                                            }
                                        </div>
                                        :
                                        null
                                    }
                                    {
                                        this.state.hands[item] ?

                                            <div className="hand">
                                                <Player
                                                    onEvent={(event) => {
                                                        if (event === "load" && this.player && this.player.current) this.player.current.play();
                                                    }}
                                                    ref={this.player} // set the ref to your class instance
                                                    autoplay={true}
                                                    loop={true}
                                                    controls={true}
                                                    src="/12738-wave.json"
                                                    renderer={'svg'}
                                                    style={this.state.mainVideoElement == item ? { height: "128px", width: "128px" } : { height: "48px", width: "48px" }}
                                                ></Player>
                                            </div>

                                            :

                                            null
                                    }

                                    {
                                        this.state._connecting[item] ?
                                            <div className="connecting" style={{ marginLeft: `-${(videoElementWidth - 20) / 2}px`, marginTop: `-${(videoElementWidth - 20) / 2}px` }}>
                                                <Player
                                                    autoplay={true}
                                                    loop={true}
                                                    src="/lf30_editor_l5cxzdyf.json"
                                                    renderer={'svg'}
                                                    style={{ height: `${videoElementWidth - 20}px`, width: `${videoElementWidth - 20}px` }}
                                                ></Player>
                                            </div>
                                            :
                                            null
                                    }

                                    {
                                        !this.state.micStatus[item] ?
                                            <div className="microphone-muted" style={this.remoteVideo[item] && this.state.mainVideoElement == item ? { top: videoPosition.y + videoPosition.height - 40, left: videoPosition.x + videoPosition.width - 40 } : {}}>
                                                <Isvg src={micMutedIcon} />
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        this.state.shareOn ?

                                            this.state.shareScreenStatus[item] ?
                                                <div className="screen-share-indicator on" style={this.remoteVideo[item] && this.state.mainVideoElement == item ? { top: videoPosition.y + 20, left: videoPosition.x + videoPosition.width - 50 } : {}}>
                                                    <Isvg src={screenShareIcon} />
                                                </div>
                                                :
                                                <div className="screen-share-indicator off" style={this.remoteVideo[item] && this.state.mainVideoElement == item ? { top: videoPosition.y + 20, left: videoPosition.x + videoPosition.width - 50 } : {}}>
                                                    <Isvg src={screenShareIcon} />
                                                </div>

                                            :
                                            null
                                    }

                                    {
                                        this.state.pinnedVideo == item ?
                                            <button className="pin-video" onClick={() => this.setState({ pinnedVideo: null }, this.generateLayout)}>
                                                <Isvg src={unpinIcon} />
                                            </button>

                                            :
                                            <button className="pin-video" onClick={() => this.setState({ pinnedVideo: item }, this.generateLayout)}>
                                                <Isvg src={pinIcon} />
                                            </button>
                                    }

                                    {
                                        this.remoteVideo[item] && this.state.mainVideoElement == item && this.state.cameraStatus[item] && this.props.uData ?
                                            <button className="take-picture" style={{ bottom: this.state.fullScreen ? '120px' : videoPosition.y + videoPosition.height - 55, left: videoPosition.x + 12 }} onClick={() => {

                                                var canvas = document.createElement('canvas');
                                                canvas.width = this.remoteVideo[item].videoWidth;
                                                canvas.height = this.remoteVideo[item].videoHeight;
                                                document.body.appendChild(canvas);

                                                var context = canvas.getContext('2d');
                                                context.drawImage(this.remoteVideo[item], 0, 0, this.remoteVideo[item].videoWidth, this.remoteVideo[item].videoHeight);

                                                canvas.toBlob((blob) => {
                                                    let file = new File([blob], "screenshot.png", { type: "image/png" })
                                                    this.setState({
                                                        takePhotoModal: {
                                                            message: '',
                                                            previewImage: canvas.toDataURL('image/png'),
                                                            image: file
                                                        }
                                                    })
                                                    canvas.remove();

                                                }, 'image/png');



                                            }}>
                                                <Isvg src={photoIcon} /><div className="chat-icons-tooltip">{'Take photo'.translate(this.props.lang)}</div>
                                            </button>
                                            :

                                            null
                                    }

                                    {
                                        this.remoteVideo[item] && this.state.mainVideoElement == item ?
                                            <div className="timer" style={{ bottom: this.state.fullScreen ? '120px' : 'unset', top: this.state.fullScreen ? 'unset' : (videoPosition.y + videoPosition.height - 40), right: videoPosition.x + 10 }}><Timer startTime={this.state.videoStartDateTime} /></div>
                                            :
                                            null

                                    }

                                </div>
                        )
                    })
                }

                {(renderOtherElements && this.state.mainVideoElement != 'local-video') || (renderMainVideoElement && this.state.mainVideoElement == 'local-video') ? layoutOutput && !this.state.shareOn && layoutOutput != '-1' ?
                    <div className={this.state.cameraOff || this.state.shareOn ? `video-container camera-off ${this.state.videoElements.length <= 13 ? 'col' + (this.state.videoElements.length + 1) : 'col15'}` : `video-container ${this.state.videoElements.length <= 13 ? 'col' + (this.state.videoElements.length + 1) : 'col15'}`}
                        key="local-video">
                        <video controls={false} autoPlay={true} playsInline={true} muted={true} class="remote-video" ref={(node) => this.localVideo = node}></video>

                        <div className="user-info">
                            <img src={this.props.uData && this.props.uData.profilePicture ? API_ENDPOINT + this.props.uData.profilePicture : profilePicture} />
                            {/* {this.state.mainVideoElement == 'local-video' ? */}

                            <p>{this.props.uData ? this.props.uData.name : this.state.guestName}</p>
                            {/* :
                                    <p onMouseEnter={(e) => {
                                        if (this.state.mainVideoElement == 'local-video') {
                                            return;
                                        }

                                        this.setState({
                                            memberTooltip: {
                                                client: 'local-video',
                                                top: layoutOutput == 2 ? e.clientY + 5 : e.clientY - 50,
                                                left: getOffset(e.target).left - (e.target.offsetWidth / 2) + 20,
                                                name: this.props.uData ? this.props.uData.name : this.state.guestName
                                            }
                                        })

                                    }}

                                        onMouseLeave={(e) => {
                                            if (this.state.mainVideoElement == 'local-video') {
                                                return;
                                            }

                                            this.setState({
                                                memberTooltip: null
                                            })

                                        }}
                                    >{this.props.uData ? this.props.uData.name && this.props.uData.name.length > 7 ? this.props.uData.name.substring(0, 7) + '...' : this.props.uData.name : this.state.guestName && this.state.guestName.length > 7 ? this.state.guestName.substring(0, 7) + '...' : this.state.guestName}</p>
                                } */}
                        </div>
                        {
                            this.state.micOff ?
                                <div className="microphone-muted" style={this.localVideo && this.state.mainVideoElement == 'local-video' ? { /*top: localVideoPosition.y + localVideoPosition.height - 40, left: localVideoPosition.x + localVideoPosition.width - 40*/ } : {}}>
                                    <Isvg src={micMutedIcon} />
                                </div>
                                :
                                null
                        }
                        {
                            this.state.localHand ?

                                <div className="hand">
                                    <Player
                                        onEvent={(event) => {
                                            if (event === "load" && this.player && this.player.current) this.player.current.play();
                                        }}
                                        ref={this.player} // set the ref to your class instance
                                        autoplay={true}
                                        loop={true}
                                        controls={true}
                                        src="/12738-wave.json"
                                        renderer={'svg'}
                                        style={this.state.mainVideoElement == 'local-video' && this.state.videoElements.length == 1 ? { height: "128px", width: "128px" } : { height: "48px", width: "48px" }}
                                    ></Player>
                                </div>

                                :

                                null
                        }
                        {/* {
                                this.state.pinnedVideo == 'local-video' ?
                                    <button className="pin-video" onClick={() => this.setState({ pinnedVideo: null }, this.generateLayout)}>
                                        <Isvg src={unpinIcon} />
                                    </button>

                                    :
                                    <button className="pin-video" onClick={() => this.setState({ pinnedVideo: 'local-video' }, this.generateLayout)}>
                                        <Isvg src={pinIcon} />
                                    </button>
                            } */}



                        {
                            // this.localVideo && this.state.mainVideoElement == 'local-video' ?
                            //     <div className="timer" /*style={{ top: this.state.fullScreen ? '90vh' : localVideoPosition.y + localVideoPosition.height - 40, right: localVideoPosition.x + 10 }}*/><Timer startTime={this.state.videoStartDateTime} /></div>
                            //     :
                            //     null

                        }



                    </div>
                    :
                    <div className={this.state.cameraOff || this.state.shareOn ? `video-container camera-off ${this.state.mainVideoElement == 'local-video' ? 'active-element' : ''}` : `video-container ${this.state.mainVideoElement == 'local-video' ? 'active-element' : ''}`}
                        style={{ opacity: this.state.fullScreen ? this.state.mainVideoElement == 'local-video' ? 1 : 0 : 1, border: '4px solid transparent', width: this.state.mainVideoElement == 'local-video' ? this.state.fullScreen ? '100vw' : '100%' : `${videoElementWidth}px`, height: this.state.mainVideoElement == 'local-video' ? this.state.fullScreen ? '100vh' : `${100 - otherVideosContainerHeight}%` : `${videoElementHeight}px` }} key="local-video" onClick={() => this.setState({ activeClient: 'local-video' }, this.generateLayout)}>
                        <video controls={false} autoPlay={true} playsInline={true} muted={true} style={{ objectFit: this.state.mainVideoElement == 'local-video' ? 'contain' : 'cover' }} class="remote-video" ref={(node) => this.localVideo = node}></video>

                        <div className="user-info">
                            <img src={this.props.uData && this.props.uData.profilePicture ? API_ENDPOINT + this.props.uData.profilePicture : profilePicture} />

                            {this.state.mainVideoElement == 'local-video' ?

                                <div>


                                    <p>{this.props.uData ? this.props.uData.name : this.state.guestName}</p>
                                </div>
                                :
                                <p onMouseEnter={(e) => {
                                    if (this.state.mainVideoElement == 'local-video') {
                                        return;
                                    }

                                    this.setState({
                                        memberTooltip: {
                                            client: 'local-video',
                                            top: layoutOutput == 2 ? e.clientY + 5 : e.clientY - 50,
                                            left: getOffset(e.target).left - (e.target.offsetWidth / 2) + 20,
                                            name: this.props.uData ? this.props.uData.name : this.state.guestName
                                        }
                                    })

                                }}

                                    onMouseLeave={(e) => {
                                        if (this.state.mainVideoElement == 'local-video') {
                                            return;
                                        }

                                        this.setState({
                                            memberTooltip: null
                                        })

                                    }}
                                >{this.props.uData ? this.props.uData.name && this.props.uData.name.length > 7 ? this.props.uData.name.substring(0, 7) + '...' : this.props.uData.name : this.state.guestName && this.state.guestName.length > 7 ? this.state.guestName.substring(0, 7) + '...' : this.state.guestName}</p>
                            }
                        </div>
                        {
                            this.state.micOff ?
                                <div className="microphone-muted" style={this.localVideo && this.state.mainVideoElement == 'local-video' ? { top: localVideoPosition.y + localVideoPosition.height - 40, left: localVideoPosition.x + localVideoPosition.width - 40 } : {}}>
                                    <Isvg src={micMutedIcon} />
                                </div>
                                :
                                null
                        }
                        {this.state.localHand ?

                            <div className="hand">
                                <Player
                                    onEvent={(event) => {
                                        if (event === "load" && this.player && this.player.current) this.player.current.play();
                                    }}
                                    ref={this.player} // set the ref to your class instance
                                    autoplay={true}
                                    loop={true}
                                    controls={true}
                                    src="/12738-wave.json"
                                    renderer={'svg'}
                                    style={this.state.mainVideoElement == 'local-video' ? { height: "128px", width: "128px" } : { height: "48px", width: "48px" }}
                                ></Player>
                            </div>

                            :

                            null
                        }
                        {
                            this.state.pinnedVideo == 'local-video' ?
                                <button className="pin-video" onClick={() => this.setState({ pinnedVideo: null }, this.generateLayout)}>
                                    <Isvg src={unpinIcon} />
                                </button>

                                :
                                <button className="pin-video" onClick={() => this.setState({ pinnedVideo: 'local-video' }, this.generateLayout)}>
                                    <Isvg src={pinIcon} />
                                </button>
                        }



                        {
                            this.localVideo && this.state.mainVideoElement == 'local-video' ?
                                <div className="timer" style={{ bottom: this.state.fullScreen ? '120px' : 'unset', top: this.state.fullScreen ? 'unset' : localVideoPosition.y + localVideoPosition.height - 40, right: localVideoPosition.x + 10 }}><Timer startTime={this.state.videoStartDateTime} /></div>
                                :
                                null

                        }



                    </div>
                    :
                    null

                }

            </>
        )
    }


    getDevices = () => {
        if (navigator) {
            navigator.mediaDevices.enumerateDevices().then((devices) => {

                let videoInput = [];
                let audioInput = [];
                let audioOutput = [];
                let audioInputFilter = []
                let audioOutputFilter = []

                for (let i = 0; i < devices.length; i++) {
                    if (devices[i].kind == "audioinput") {
                        if (devices[i].deviceId !== 'default' && devices[i].deviceId !== 'communications') {
                            audioInputFilter.push(devices[i])
                        } else {
                            audioInput.push(devices[i]);
                        }
                        // audioInput.push(devices[i]);
                    } else if (devices[i].kind == "audiooutput") {
                        if (devices[i].deviceId !== 'default' && devices[i].deviceId !== 'communications') {
                            audioOutputFilter.push(devices[i])
                        } else {
                            audioOutput.push(devices[i]);
                        }
                        // audioOutput.push(devices[i]);
                    } else if (devices[i].kind == "videoinput") {
                        videoInput.push(devices[i]);
                    }
                }

                let stateObj = {
                    videoInput,
                    audioOutput: audioOutputFilter.length ? audioOutputFilter : audioOutput,
                    audioInput: audioInputFilter.length ? audioInputFilter : audioInput,

                }

                if (audioOutputFilter.length && !this.state.audioOutputDevice) {
                    stateObj.audioOutputDevice = audioOutputFilter[0].deviceId;
                }

                this.setState(stateObj)
            })
        }
    }


    savePhoto = () => {

        let formData = new FormData();
        formData.append('file', this.state.takePhotoModal.image);

        fetch(API_ENDPOINT + '/chat/upload/image', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken') ? localStorage.getItem("authToken") : localStorage.getItem('guestToken')}` : null,

            },
            body: formData
        }).then((res) => res.text()).then((img) => {
            this.props.socket.emit('sendMessage', { image: img, message: this.state.takePhotoModal.message, conversation: this.state.conversation._id });
            this.setState({
                takePhotoModal: null
            })
        });
    }
    sendInvitations = () => {

        if (!this.state.shareLinkTime) {
            this.setState({
                shareLinkTime: this.state.conversation.scheduledTime
            })
        }

        if (!this.state.shareLinkDate) {
            this.setState({
                shareLinkDate: this.state.conversation.scheduledDate
            })
        }

        let listOfInvitaions = [];
        if (this.state.shareModalFields && this.state.shareModalFields.length) {
            for (let i = 0; i < this.state.shareModalFields.length; i++) {
                if (this.state.shareModalFields[i].input && this.state.shareModalFields[i].input != '') {
                    listOfInvitaions.push(this.state.shareModalFields[i].input)

                }
            }
        }
        fetch(API_ENDPOINT + '/users/chat/send/invitations', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken') ? localStorage.getItem("authToken") : localStorage.getItem('guestToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                listOfInvitaions: listOfInvitaions,
                link: this.state.shareModal,
                // group: this.props.selectedGroup,
                lang: this.props.lang,
                conversation: this.state.conversation && this.state.conversation._id,
                date: this.state.shareLinkDate,
                time: this.state.shareLinkTime,
                host: this.state.conversation && this.state.conversation.host
            })
        }).then(res => res.json()).then((result) => {
            let shareModalFields = [{ input: '' }];
            this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage.profile : null, conversation: this.props[0].match.params.conversation && this.props[0].location.pathname.indexOf('/video-call') === 0 ? this.props[0].match.params.conversation : null });
            let successMessage = '';
            if (result && result.invitedContacts) {
                for (let i = 0; i < result.invitedContacts.length; i++) {
                    successMessage += result.invitedContacts[i].contact + ' ';
                }
            }
            if (result && result.addMember && result.addMember.length) {
                for (let i = 0; i < result.addMember.length; i++) {
                    this.addMember(result.addMember[i])
                }
            }

            this.setState({
                shareModal: null,
                inviteError: null,
                shareModalFields,
                invitedContactsSucces: result.invitedContacts && result.invitedContacts.length ? result.invitedContacts : null,
                successMessage: result.invitedContacts && result.invitedContacts.length ? successMessage : null,
            }, () => {

                if (result && result.checkError) {
                    let link
                    if (this.state.conversation.this.state.conversation) {
                        link = `${window.location.origin}/video-call/${this.state.conversation._id}?roomId=${this.state.conversation.roomId}&displayName=true`
                    } else {
                        link = `${window.location.origin}/video-call/${this.state.conversation._id}?roomId=${this.state.conversation.roomId}`
                    }
                    this.setState({
                        shareModal: link

                    }, () => {
                        this.setState({
                            error: 'Failure sms error'
                        })

                    })
                }
                this.props.verifyUser()
            })



        })


    }

    updateSentInvitationsList = (idx) => {
        if (this.state.conversation && this.state.conversation.invitedContacts && this.state.conversation.invitedContacts.length) {
            let newList = [];
            for (let i = 0; i < this.state.conversation.invitedContacts.length; i++) {
                if (i != idx) {
                    newList.push(this.state.conversation.invitedContacts[i])
                }
            }

            this.props.socket.emit('removeInvitedContacts', { conversation: this.state.conversation._id, newList: newList, userToRemove: this.state.conversation.invitedContacts[idx] });

            // if(newList && newList.length)

        }

    }

    getAnswer = (id) => {
        for (let i = 0; i < this.state.sections.length; i++) {
            if (this.state.sections[i].questions) {
                for (let j = 0; j < this.state.sections[i].questions.length; j++) {
                    if (this.state.sections[i].questions[j].answers) {
                        for (let k = 0; k < this.state.sections[i].questions[j].answers.length; k++) {
                            if (this.state.sections[i].questions[j].answers[k].id == id) {
                                return this.state.sections[i].questions[j].answers[k];
                            }
                        }
                    }
                }
            }
        }


        return {};
    }

    leaveChat = (conversation) => {

        this.props.socket.emit('removeMember', { conversation: conversation._id, member: this.props.uData._id });
        this.setState({ conversation: null })

    }
    deleteMessage = (message) => {


        this.props.socket.emit('deleteMessage', { id: message._id, conversation: this.state.conversation._id });

        this.setState({ deleteMessageModal: null })
    }
    deleteConversation = (conversation) => {
        this.props.socket.emit('deleteConversation', { conversation: conversation._id });
        this.setState({ conversation: null })
    }

    login = (data) => {

        if (typeof window !== 'undefined' && localStorage.getItem('roomId')) {
            data.roomId = localStorage.getItem('roomId');

            localStorage.removeItem('roomId')
        }

        fetch(API_ENDPOINT + "/users/login", {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.error) {
                    if (result.error.text) {
                        this.setState({
                            error: result.error.text
                        })
                    } else {
                        this.setState({
                            error: result.error
                        })
                    }
                } else {

                    localStorage.setItem("authToken", result.token);
                    localStorage.setItem('loginMethod', 'code');
                    localStorage.setItem("profile", "doctor");
                    localStorage.setItem("time", Math.floor(Date.now() / 1000));
                    this.props.registerLog("Logged in");
                    this.props.verifyUser(() => {

                        this.props[0].history.push(`/video-call/${this.state.conversation._id}`);
                        window.location.reload()


                    });



                }
            });
    };

    findConversation = (id) => {
        fetch(API_ENDPOINT + "/data/home-landing/conversation/" + id, {
            method: "GET",
            headers: {
                'content-type': 'application/json'
            }
        }).then(parseJSON).then(({ result, status }) => {
            if (result) {
                this.props[0].history.push(`/video-call/${result._id}?roomId=${result.roomId}`);
                // window.location.reload()
            }
        })
    }

    getStreamWithBackground = (callback) => {
        const videoElement = this.videoRef;

        let first = false;

        const onResults = (results) => {
            try {
                if (!first) {

                    first = true;
                }
                const canvasElement = this.canvasRef
                const canvasCtx = canvasElement.getContext('2d');

                if (!canvasElement) {
                    return;
                }

                if (!canvasElement.width || !canvasElement.height) {
                    return;
                }
                if (!results) {
                    return;
                }
                if (!results.image) {
                    return;
                }

                if (!results.image.width || !results.image.height) {
                    return;
                }


                canvasCtx.save();
                canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
                // canvasCtx.drawImage(image, 0, 0,
                //     canvasElement.width, canvasElement.height);

                canvasCtx.drawImage(results.segmentationMask, 0, 0,
                    canvasElement.width, canvasElement.height);

                // Only overwrite existing pixels.
                canvasCtx.globalCompositeOperation = 'source-out';
                if (this.roomImageRef && this.roomImageRefLoaded) {
                    canvasCtx.drawImage(this.roomImageRef, 0, 0,
                        canvasElement.width, canvasElement.height);

                } else {
                    canvasCtx.fillStyle = '#3a3f56';
                    canvasCtx.fillRect(0, 0, canvasElement.width, canvasElement.height);

                }

                // Only overwrite missing pixels.
                canvasCtx.globalCompositeOperation = 'destination-atop';

                if (this.localVideo && results.image && this.localVideo.videoHeight > this.localVideo.videoWidth) {

                    let scaleHeight = canvasElement.height / this.localVideo.videoHeight;
                    let newWidth = this.localVideo.videoWidth * scaleHeight;
                    let x = canvasElement.width / 2 - newWidth / 2;

                    canvasCtx.drawImage(
                        results.image, x, 0, newWidth, canvasElement.height);

                } else {
                    if (this.localVideo) {
                        let scaleWidth = canvasElement.width / this.localVideo.videoWidth;
                        let newHeight = this.localVideo.videoHeight * scaleWidth;
                        let y = canvasElement.height - newHeight;
                        canvasCtx.drawImage(
                            results.image, 0, y, canvasElement.width, newHeight);
                    }
                }


                canvasCtx.restore();
            } catch (e) {
                console.log('Error', e);
            }
        }

        const selfieSegmentation = new SelfieSegmentation({
            locateFile: (file) => {
                console.log(file);
                return `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation@0.1/${file}`;
            }
        });

        var isSafari = typeof window !== 'undefined' && /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        selfieSegmentation.setOptions({
            modelSelection: isSafari ? 0 : 1,
        });
        selfieSegmentation.onResults(onResults);

        // const camera = new Camera(videoElement, {
        //     onFrame: async () => {
        //         await selfieSegmentation.send({ image: videoElement });
        //     },
        //     width: 1280,
        //     height: 720
        // });
        // camera.start();
        let resolutionDict = {
            '480': {
                width: 640,
                height: 480
            },
            '720': {
                width: 1280,
                height: 720
            },
            '1080': {
                width: 1920,
                height: 1080
            },
        }
        if (!this.state.fullHDSupport) {
            resolutionDict['1080'] = {
                width: 1280,
                height: 720
            }
        }
        let resolution = resolutionDict[this.state.videoResolution];

        resolution = {
            width: 640,
            height: 480
        }

        if (this.state.micOff && this.audioVideoStream) {
            this.audioVideoStream.getAudioTracks().forEach(track => track.enabled = false);
        }

        navigator.mediaDevices.getUserMedia(
            { video: this.state.videoInputDevice ? { deviceId: this.state.videoInputDevice, width: { ideal: resolution.width }, height: { ideal: resolution.height } } : this.state.camera === true ? { facingMode: { exact: "environment" }, width: { ideal: resolution.width }, height: { ideal: resolution.height } } : this.state.camera === false ? { facingMode: "user", width: { ideal: resolution.width }, height: { ideal: resolution.height } } : { width: { ideal: resolution.width }, height: { ideal: resolution.height } }, audio: this.state.audioInputDevice ? { deviceId: this.state.audioInputDevice } : true }).then(
                async stream => {

                    if (this.state.micOff) {
                        stream.getAudioTracks().forEach(track => track.enabled = false);
                    }

                    const localVideo = this.videoRef;
                    if (localVideo) {
                        localVideo.autoPlay = true;
                        localVideo.autoplay = true;
                        localVideo.playsInline = true;
                        localVideo.playsinline = true;
                        localVideo.muted = true;

                        localVideo.srcObject = stream;

                        let lastTime = -1;


                        const draw = async (isSetTimeout = false, firstTime = false) => {
                            var time = localVideo.currentTime;
                            if (time !== lastTime && ((time - lastTime) * 1000 > 80) && ((!isSetTimeout && this.activeTab) || (isSetTimeout && !this.activeTab))) {
                                //todo: do your rendering here
                                //console.log(time- lastTime)
                                await selfieSegmentation.send({ image: this.videoRef });
                                lastTime = time;
                            }

                            //wait approximately 16ms and run again
                            if (this.activeTab && !isSetTimeout)
                                window.requestAnimationFrame(() => draw(false, false));

                            const mills = this.activeTab ? 1000 : 40;
                            if (isSetTimeout || firstTime)
                                setTimeout(() => draw(true), mills);
                        }

                        localVideo.onloadedmetadata = () => {
                            localVideo.play();

                            draw(false, true);


                            setTimeout(() => {
                                callback(this.canvasRef.captureStream(), stream);

                            }, 1000)

                        }
                    }




                })


    }
    emitConversations = () => {
        this.props.socket.emit('conversations', { pageNumber: this.state.conversationPage })
    }

    handleDrop = async (e) => {
        e?.preventDefault?.();
        this.setState({ isDragging: false }, () => {
            let oldScrollHeight = 0;
            if (this.messagesRef) {
                oldScrollHeight = this.messagesRef.scrollHeight;
            }

            if (this.messagesRef && this.state.page == 0) {
                this.messagesRef.scrollTop = this.messagesRef.scrollHeight;
            } else if (oldScrollHeight) {
                this.messagesRef.scrollTop = this.messagesRef.scrollTop + (this.messagesRef.scrollHeight - oldScrollHeight);
            }
        });

        if (!this.state.conversation) return;

        const droppedFiles = e?.dataTransfer?.files;
        if (!droppedFiles) return;

        if (Array.from(droppedFiles) && Array.from(droppedFiles)?.length) {
            const fileArray = Array.from(droppedFiles);

            if (fileArray.length > 10) return this.setState({ error: 'You can only select up to 10 files to upload, at a time.' });

            this.setState({ _dragNDropUpload: true }, async () => {
                console.log('drag n drop start: ', this.state._dragNDropUpload);

                for (let i = 0; i < fileArray.length; i++) {
                    let file = fileArray[i];

                    const obj = {
                        target: {
                            files: [file]
                        }
                    }

                    if (file && file.type && ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/svg'].includes(file.type)) {
                        const url = await this.uploadImage(obj, true);
                        console.log('url" , ', url);
                        if (url) await this.sendMessageFromDragAndDrop(this.state.conversation?._id, 'image', url);
                    } else if (file && file.type && ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf'].includes(file.type)) {
                        const url = await this.uploadDocument(obj, true);
                        if (url) await this.sendMessageFromDragAndDrop(this.state.conversation?._id, 'document', url);
                    } else if (file && file.type && ['video/mp4', 'video/x-msvideo'].includes(file.type)) {
                        const url = await this.uploadVideo(obj, true);
                        if (url) await this.sendMessageFromDragAndDrop(this.state.conversation?._id, 'video', url);
                    }
                }

                this.setState({ _dragNDropUpload: false })

                // fileArray.forEach(async (file, i) => {
                //     const obj = {
                //         target: {
                //             files: [file]
                //         }
                //     }

                //     if (i >= 10) return this.setState({ _dragNDropUpload: false });

                //     if (file && file.type && ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/svg'].includes(file.type)) {
                //         const url = await this.uploadImage(obj, true);
                //         console.log('url" , ', url);
                //         if (url) await this.sendMessageFromDragAndDrop(this.state.conversation?._id, 'image', url);
                //     } else if (file && file.type && ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf'].includes(file.type)) {
                //         const url = await this.uploadDocument(obj, true);
                //         if (url) await this.sendMessageFromDragAndDrop(this.state.conversation?._id, 'document', url);
                //     } else if (file && file.type && ['video/mp4', 'video/x-msvideo'].includes(file.type)) {
                //         const url = await this.uploadVideo(obj, true);
                //         if (url) await this.sendMessageFromDragAndDrop(this.state.conversation?._id, 'video', url);
                //     }
                // });


            })
        }
    }

    render() {

        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, {})
        }

        let memebersDict = {};

        if (this.state.conversation && this.state.conversation.users) {
            for (let i = 0; i < this.state.conversation.users.length; i++)
                memebersDict[this.state.conversation.users[i].uid] = this.state.conversation.users[i]
        }

        const humanReadable = {
            'waiting-for-approval': 'Waiting for approval',
            'waiting': 'Waiting',
            'in-progress': 'In progress',
            'disallowed': 'Disallowed',
            'allowed': 'Allowed',
            'return-for-update': 'Return for update',
            'patient-requested-another-time': 'Patient requested anoother time',
            'patient-rejected': 'Rejected by patient',
            'patient-accepted': 'Patient accepted',
            'clinic-accepted-requested-time': 'Clinic accepted requested time',
            'clinic-rejected-requested-time': 'Clinic rejected requested time',
            'approved-by-region-manager': 'Approved by region manager',
            'rejected-by-region-manager': 'Rejected by region manager',
            'scheduled': 'Scheduled',
            'not-booked': 'Not booked',
            'closed': 'Closed'

        }

        var isSafari = typeof window !== 'undefined' && /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        var isFirefox = typeof window !== 'undefined' && navigator.userAgent.toLowerCase().indexOf('firefox') > -1;


        if (this.props[0].location.search.split('=')[0] == '?roomId' && this.state.hostEndedCall) {
            return (
                <div className='guest-end-call-message'>
                    <p>{'Thank you for using our services.'.translate(this.props.lang)}</p>
                </div>
            );
        }

        if (this.state._hostEndCall || this.state.hostEndedCall) {
            return (
                <Modal isOpen={this.state._hostEndCall || this.state.hostEndedCall} centered toggle={() => this.setState({ _hostEndCall: !this.state._hostEndCall, hostEndedCall: !this.state.hostEndedCall }, () => {
                    try {
                        setTimeout(() => {
                            window.open('', '_self').close();

                        }, 1000);
                    } catch (e) {

                    }


                    if (window && window.top)
                        window.top.postMessage('end-video-call', '*')



                })} className="end-video-call-modal">
                    <ModalHeader style={{ margin: 'auto' }}

                    >{'Info'.translate(this.props.lang)} </ModalHeader>

                    <ModalBody className="video-call-limit delete-modal">
                        <Isvg src={info} />

                        <p>{'All participants left the video call.'.translate(this.props.lang)}</p>
                    </ModalBody>
                    <ModalFooter className="buttons-right-blue">
                        <Button onClick={() => this.setState({ _hostEndCall: !this.state._hostEndCall, hostEndedCall: !this.state.hostEndedCall }, () => {
                            try {
                                setTimeout(() => {
                                    window.open('', '_self').close();

                                }, 1000);

                            } catch (e) {

                            }

                            if (window && window.top)
                                window.top.postMessage('end-video-call', '*')


                        })} color="primary">{'Close video call'.translate(this.props.lang)}</Button>
                    </ModalFooter>

                </Modal>
            )

        }
        if (this.state._endCall) {
            return (
                <Modal isOpen={this.state._endCall} className="end-video-call-modal" centered>
                    <ModalHeader>{'Video call ended.'.translate(this.props.lang)}</ModalHeader>

                    <ModalBody className="video-call-limit ">
                        <p>{'Rate the quality of video call.'.translate(this.props.lang)}</p>
                        <div className="rating">
                            {this.state._endCall.rating && this.state._endCall.rating >= 1 ? <button onClick={() => { let _endCall = this.state._endCall; _endCall.rating = 1; this.setState({ _endCall }) }}><Isvg src={fullStarIcon} /></button> : <button onClick={() => { let _endCall = this.state._endCall; _endCall.rating = 1; this.setState({ _endCall }) }}><Isvg src={starIcon} /></button>}
                            {this.state._endCall.rating && this.state._endCall.rating >= 2 ? <button onClick={() => { let _endCall = this.state._endCall; _endCall.rating = 2; this.setState({ _endCall }) }}><Isvg src={fullStarIcon} /></button> : <button onClick={() => { let _endCall = this.state._endCall; _endCall.rating = 2; this.setState({ _endCall }) }}><Isvg src={starIcon} /></button>}
                            {this.state._endCall.rating && this.state._endCall.rating >= 3 ? <button onClick={() => { let _endCall = this.state._endCall; _endCall.rating = 3; this.setState({ _endCall }) }}><Isvg src={fullStarIcon} /></button> : <button onClick={() => { let _endCall = this.state._endCall; _endCall.rating = 3; this.setState({ _endCall }) }}><Isvg src={starIcon} /></button>}
                            {this.state._endCall.rating && this.state._endCall.rating >= 4 ? <button onClick={() => { let _endCall = this.state._endCall; _endCall.rating = 4; this.setState({ _endCall }) }}><Isvg src={fullStarIcon} /></button> : <button onClick={() => { let _endCall = this.state._endCall; _endCall.rating = 4; this.setState({ _endCall }) }}><Isvg src={starIcon} /></button>}
                            {this.state._endCall.rating && this.state._endCall.rating >= 5 ? <button onClick={() => { let _endCall = this.state._endCall; _endCall.rating = 5; this.setState({ _endCall }) }}><Isvg src={fullStarIcon} /></button> : <button onClick={() => { let _endCall = this.state._endCall; _endCall.rating = 5; this.setState({ _endCall }) }}><Isvg src={starIcon} /></button>}
                        </div>
                        {this.state._endCall.rating ?
                            <FormGroup>
                                <Input type="textarea" placeholder={'Comment'.translate(this.props.lang)} value={this.state._endCall.comment} onChange={(e) => { let _endCall = this.state._endCall; _endCall.comment = e.target.value; this.setState({ _endCall }) }} />
                            </FormGroup>
                            :
                            null
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => {
                            if (this.state._endCall.rating) {
                                this.props.socket.emit('rateVideoCall', { conversation: this.state.conversation._id, rating: this.state._endCall.rating, comment: this.state._endCall.comment, logUUID: this.state.logUUID, browser: browserInfo() })
                            }
                            if (window && window.top)
                                window.top.postMessage('end-video-call', '*')
                            try {
                                setTimeout(() => {
                                    window.open('', '_self').close();

                                }, 1000);

                            } catch (e) {

                            }


                            this.setState({ _endCall: !this.state._endCall, _guestCallEnded: true });
                        }

                        } color="primary">{this.state._endCall.rating ? 'Rate and close'.translate(this.props.lang) : 'Close'.translate(this.props.lang)}</Button>
                    </ModalFooter>

                </Modal>
            )

        }

        if (this.state.videoStartedWithMessagerApp) {
            return (
                <div className="waiting-room">
                    <p>{'Messager app browser not supported.'.translate(this.props.lang)}</p>

                </div>
            )

        }


        if (this.state._guestCallEnded) {
            if (this.props[0].location.search.split('=')[0] == '?roomId')
                return (
                    <div className='guest-end-call-message'>
                        <p>{'Thank you for using our services.'.translate(this.props.lang)}</p>
                    </div>

                );
            else
                return null
        }

        if (params.roomId && !this.state.call && !this.state.prepareVideoCall) {
            // let conversation
            // if (!conversation) {
            //     conversation = this.findMeConversationWithThisRoomID(params.roomId)
            // }
            return (
                <div className="waiting-room">
                    <div>
                        <div className="loader-wrap">
                            <Player
                                autoplay={true}
                                loop={true}
                                renderer={'svg'}
                                src="/lf30_editor_l5cxzdyf.json"
                                style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px" }}
                            ></Player>

                        </div>

                        {
                            this.state.hideMessage ?
                                <p>{'Please Wait for the Host to Start this Meeting'.translate(this.props.lang)}</p>
                                :
                                null
                        }

                        {
                            this.state.conversation && this.state.conversation.scheduledDate && this.state.conversation.scheduledTime && this.state.conversation.dateType ?

                                <div>
                                    {
                                        !this.state.hideMessage ?
                                            <div>
                                                <p>{'The meeting is currently inactive.'.translate(this.props.lang)}</p>

                                                <div className="push-button-center">
                                                    <Button color='primary' onClick={() => this.setState({ hideMessage: true })} >{'Wait for meeting to start'.translate(this.props.lang)}</Button>
                                                    <Button color='primary' onClick={() => this.setState({ _guestCallEnded: true, hideMessage: true })}>{'Come back later'.translate(this.props.lang)}</Button>
                                                </div>
                                            </div>

                                            :
                                            null
                                    }


                                </div>

                                :
                                <div>

                                    {
                                        !this.state.hideMessage ?
                                            <div>
                                                <p>{`This meeting is not active yet`.translate(this.props.lang)}</p>

                                                <div className="push-button-center">
                                                    <Button color='primary' onClick={() => this.setState({ hideMessage: true })} >{'Wait for meeting to start'.translate(this.props.lang)}</Button>
                                                    <Button color='primary' onClick={() => this.setState({ _guestCallEnded: true, hideMessage: true })}>{'Come back later'.translate(this.props.lang)}</Button>
                                                </div>
                                            </div>

                                            :
                                            null
                                    }


                                </div>
                        }

                    </div>

                </div>
            )
        }

        if (this.state.prepareVideoCall) {
            if ((!params.roomId || (params.roomId && this.state.guestName))) {
                return (
                    <WebRTCTest muteParticipants={this.state.conversation.muteParticipants} participantsInVideoCall={this.state.conversation ? this.state.conversation.participantsInVideoCall : 0} callback={this.configureStreamAndAnswer} lang={this.props.lang}></WebRTCTest>
                )
            } else {
                return (<div className="guest-name-modal">
                    <div>
                        {
                            this.state.conversation && this.state.conversation.passcode && this.state.conversation.passcode != '' && (!this.state.userPasscodeCheck || this.state.userPasscodeCheck != this.state.conversation.passcode) ?
                                <FormGroup>
                                    <h6>{'Enter passcode'.translate(this.props.lang)}</h6>
                                    <FormGroup className="guest-name-form-group">
                                        <Input type="text" value={this.state.userPasscodeEnter} onKeyDown={(e) => {
                                            if (e.key == 'Enter') {
                                                this.setState({ userPasscodeCheck: this.state.userPasscodeEnter })
                                            }
                                        }} onChange={(e) => {
                                            this.setState({
                                                userPasscodeEnter: e.target.value
                                            })
                                        }} />
                                        <Button color="primary" onClick={() => this.setState({ userPasscodeCheck: this.state.userPasscodeEnter })}>{'Send'.translate(this.props.lang)}</Button>
                                    </FormGroup>
                                    {
                                        this.state.userPasscodeCheck && this.state.userPasscodeCheck != this.state.conversation.passcode ?
                                            <p style={{ color: 'red' }}>{'Passcode is incorrect!'.translate(this.props.lang)}</p>
                                            :
                                            null
                                    }
                                </FormGroup>
                                :
                                null
                        }
                        {
                            !this.state.openSiths && this.state.conversation && this.state.conversation.requireBankID && !this.state._requireDisplayName && (this.state.userPasscodeCheck == this.state.conversation.passcode || this.state.conversation.passcode == '') ?
                                <BankdIDIdentity
                                    redirectUrl={this.state._bankIdRedirectUrl}
                                    qrCodeLink={this.state.qrCodeLink}
                                    bankIDkey={this.state.bankIDkey}
                                    conversation={this.state.conversation}
                                    generateBankIdRedirectUrl={() => {
                                        this.setState({ _bankIdStarted: true })
                                        this.props.socket.emit('bankIdGenerateRedirectURL', { conversation: this.state.conversation._id });
                                    }}
                                    handleBankIdIdentity={(key) => {
                                        this.props.socket.emit('bankIdIdentificate', { conversation: this.state.conversation._id, loginStorageKey: key })
                                    }}
                                    handleLoading={(value) => this.setState({ _bankIdLoading: value })}
                                    loading={this.state._bankIdLoading}
                                    lang={this.props.lang}
                                ></BankdIDIdentity>

                                :
                                null
                        }
                        {
                            !this.state._bankIdStarted && !this.state.openSiths && this.state.conversation && this.state.conversation.requireNetID && !this.state._requireDisplayName && (this.state.userPasscodeCheck == this.state.conversation.passcode || this.state.conversation.passcode == '') ?
                                <div className="bank-id-identity">
                                    <Button onClick={() => {
                                        fetch(API_ENDPOINT + `/users/netid/start`, {
                                            method: 'GET',
                                            headers: {
                                                'content-type': 'application/json'
                                            },
                                        }).then(res => res.json()).then((result) => {
                                            if (result.redirectUrl) {
                                                this.setState({
                                                    openSiths: result.redirectUrl
                                                }, () => {
                                                    // if (this.props.alias)
                                                    //     localStorage.setItem('groupAlias', this.props.alias)
                                                })
                                            }
                                        })


                                    }}><img src={NetId} /> {'Log in with SITHS'.translate(this.props.lang)}</Button>
                                </div>
                                :
                                null
                        }
                        {
                            this.state.openSiths ?
                                <div className='go-back-div'>
                                    <button onClick={() => {
                                        this.setState({
                                            _bankIdStarted: false,
                                            openSiths: false
                                        })
                                    }} className="login-back"> <Isvg src={ArrowBack} /> {'go back'.translate(this.props.lang)}</button>
                                    <iframe ref={(node) => this.bankIdIframe = node} onLoad={() => {
                                        try {
                                            if (this.bankIdIframe && this.bankIdIframe.contentWindow && this.bankIdIframe.contentWindow.location && this.bankIdIframe.contentWindow.location.href && this.bankIdIframe.contentWindow.location.href.indexOf('ui/bankidse') !== -1) {
                                                this.setState({
                                                    _hideIframe: true
                                                })
                                            }
                                        } catch (e) { }
                                    }} src={this.state.openSiths} style={this.state._hideIframe ? { opacity: 0 } : { width: '100%', height: 770, border: 'none' }} />
                                </div>
                                :
                                null
                        }
                        {
                            !this.state._bankIdStarted && (this.state.conversation && (!this.state.conversation.requireBankID) && (this.state.userPasscodeCheck == this.state.conversation.passcode || !this.state.conversation.passcode) && (this.state.conversation.videoLink == '0' || this.state.conversation.videoLink == '1')) ?
                                <div>
                                    <h6>{'Enter your name'.translate(this.props.lang)}</h6>
                                    <FormGroup className="guest-name-form-group">
                                        <Input type="text" className={this.state.emptyGuestName ? 'guest-name-form-group-required' : null} onKeyDown={(e) => {
                                            if (e.key == 'Enter') {
                                                if (!this.state._guestName) {
                                                    this.setState({ emptyGuestName: true })
                                                } else {

                                                    this.setState({ emptyGuestName: false, guestName: this.state._guestName })
                                                }
                                            }
                                        }} onChange={(e) => this.setState({ _guestName: e.target.value, emptyGuestName: false })} value={this.state._guestName} />
                                        <Button color="primary" onClick={() => {
                                            if (!this.state._guestName) {
                                                this.setState({ emptyGuestName: true })
                                            } else {

                                                this.setState({ emptyGuestName: false, guestName: this.state._guestName })
                                            }
                                        }
                                        }>{'Save'.translate(this.props.lang)}</Button>
                                    </FormGroup>
                                    {
                                        this.state.conversation && this.state.conversation.requireBankID && this.state.conversation.videoLink == '0' ?
                                            <div className="or-spacer">{'or'.translate(this.props.lang)}</div>
                                            :
                                            null
                                    }
                                </div>
                                :
                                null
                        }
                        {
                            this.state._requireDisplayName ?
                                <div>
                                    <h6>{'Enter your name'.translate(this.props.lang)}</h6>
                                    <FormGroup className="guest-name-form-group">
                                        <Input type="text" className={this.state.emptyGuestName ? 'guest-name-form-group-required' : null} onKeyDown={(e) => {
                                            if (e.key == 'Enter') {
                                                if (!this.state._guestName) {
                                                    this.setState({ emptyGuestName: true })
                                                } else {

                                                    this.setState({ emptyGuestName: false, guestName: this.state._guestName })
                                                }
                                            }
                                        }} onChange={(e) => this.setState({ _guestName: e.target.value, emptyGuestName: false })} value={this.state._guestName} />

                                        <Button color="primary" onClick={() => {
                                            if (!this.state._guestName) {
                                                this.setState({ emptyGuestName: true })
                                            } else {

                                                this.setState({ emptyGuestName: false, guestName: this.state._guestName })
                                            }

                                        }}>{'Save'.translate(this.props.lang)}</Button>
                                    </FormGroup>
                                </div>
                                :
                                null

                        }


                        {/*//                         {
//                             (this.state.conversation && !this.state.conversation.requireBankID && !this.state._requireDisplayName && (this.state.userPasscodeCheck == this.state.conversation.passcode || this.state.conversation.passcode == '') && (this.state.conversation.videoLink == '0' || this.state.conversation.videoLink == '2')) ?
//                                 <BankdIDIdentity
//                                     redirectUrl={this.state._bankIdRedirectUrl}
//                                     generateBankIdRedirectUrl={() => {
//                                         this.setState({ _bankIdStarted: true })
//                                         this.props.socket.emit('bankIdGenerateRedirectURL', { conversation: this.state.conversation._id });
//                                     }}
//                                     handleBankIdIdentity={(key) => {
//                                         this.props.socket.emit('bankIdIdentificate', { conversation: this.state.conversation._id, loginStorageKey: key })
//                                     }}
//                                     handleLoading={(value) => this.setState({ _bankIdLoading: value })}
//                                     loading={this.state._bankIdLoading}
//                                     lang={this.props.lang}
//                                 ></BankdIDIdentity>

//                                 :
//                                 null
//                         }*/}

                    </div>
                </div>
                )
            }
        }

        if (!this.state.call && this.props[0].match.params.conversation) {
            return (
                <div className="waiting-room" /*style={typeof window != 'undefined' && iosDevice() ? { maxWidth: `${window.innerWidth}px` } : {}}*/>
                    <div>
                        <div className="loader-wrap">
                            <Player
                                autoplay={true}
                                loop={true}
                                renderer={'svg'}
                                src="/lf30_editor_l5cxzdyf.json"
                                style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px" }}
                            ></Player>

                        </div>
                    </div>
                </div>

            )
        }






        if (this.state.call) {
            let numberOfColumnsOnScreen = typeof window != 'undefined' && window.innerWidth < 768 && window.innerWidth < window.innerHeight ? 3 : 14;

            let max = numberOfColumnsOnScreen;
            let elements = (this.state.kbps - 2000) / 400;
            let visibleNumberOfColumnsOnScreen = elements > max ? max : elements;
            let videoElementHeight = (this.callArea && this.callArea.offsetWidth) / numberOfColumnsOnScreen;

            let videoElementWidth = videoElementHeight;
            let scrollAreaPaddingLeft = (numberOfColumnsOnScreen - visibleNumberOfColumnsOnScreen) * videoElementWidth;

            let layoutOutput = this.state.layoutOutput;
            if (false && this.state.layoutOutput == 2 && this.state.videoElements.length <= 1 && (typeof window !== 'undefined' && !(window.innerWidth >= 700 && window.innerWidth <= 1200))) {
                layoutOutput = -1;
            }




            return (
                <div className={`mobile-fix ${this.state.showScreenShareMode ? 'share-screen-active' : ''}`}>
                    <video loop autoplay controls crossorigin volume={0.5} style={{ position: 'fixed', top: '-100%', left: '-100%', width: 100, height: 100, zIndex: 9999 }} ref={(node) => this.blankVideoRef = node}>
                        <source src={'/blank.mp4'} type="video/mp4" />
                    </video>
                    <div style={{ position: 'fixed', top: '-200%', left: '-200%', width: 1000, height: 1000, zIndex: 9999, opacity: 0 }}>
                        {this.state.backgroundVideoImage ?
                            <img crossOrigin="anonymous" src={this.state.backgroundVideoImage} ref={(node) => this.roomImageRef = node} width="640" height="480" onLoad={() => this.roomImageRefLoaded = true} />
                            :
                            null
                        }
                        {/* <video crossOrigin="anonymous" ref={(node) => this.videoRef = node} width={typeof window !== 'undefined' && window.innerWidth > window.innerHeight ? "640px" : "480px"} height={typeof window !== 'undefined' && window.innerWidth > window.innerHeight ? "480px" : "640px"}></video>
                        <canvas crossOrigin="anonymous" className="output_canvas" width={typeof window !== 'undefined' && window.innerWidth > window.innerHeight ? "640px" : "480px"} height={typeof window !== 'undefined' && window.innerWidth > window.innerHeight ? "480px" : "640px"} ref={(node) => this.canvasRef = node}></canvas> */}
                        <video crossOrigin="anonymous" ref={(node) => this.videoRef = node} width={typeof window !== 'undefined' && !detectMob() ? "640px" : "480px"} height={typeof window !== 'undefined' && !detectMob() ? "480px" : "640px"}></video>
                        <canvas crossOrigin="anonymous" className="output_canvas" width={typeof window !== 'undefined' && !detectMob() ? "640px" : "480px"} height={typeof window !== 'undefined' && !detectMob() ? "480px" : "640px"} ref={(node) => this.canvasRef = node}></canvas>

                    </div>

                    <canvas crossOrigin="anonymous" width="1280px" height="720px" style={{ position: 'fixed', top: '-2000px', left: '-5000px', zIndex: 9999, pointerEvents: 'none', opacity: 0 }} ref={(node) => this.recordingCanvas = node}></canvas>


                    <div className={`call-area `} ref={(node) => { this.callArea = node }}>
                        {layoutOutput == 2 ?
                            <div className="remote-container remote-container-box-layout" style={false && this.state.videoElements.length >= 8 && typeof window !== 'undefined' && window.innerWidth > window.innerHeight ? { paddingLeft: (window.innerWidth - ((window.innerHeight - 90) * (2 / 7) * (16 / 9) * 3)) / 2, paddingRight: (window.innerWidth - ((window.innerHeight - 90) * (2 / 7) * (16 / 9) * 3)) / 2 } : {}}>

                                {this.callArea ?
                                    <div className={`video-boxlayout ${this.state.videoElements.length <= 8 ? 'elements-' + (this.state.videoElements.length + 1) : 'elements'}`} style={typeof window != 'undefined' && iosDevice() ? { /*maxWidth: `${window.innerWidth}px`*/ } : {}}>

                                        {this.renderOtherVideoElements(true, true)}

                                    </div>
                                    :
                                    null
                                }
                                {this.state.videoStartDateTime ?
                                    <div className="timer" style={{/* top: this.state.fullScreen ? '90vh' : (videoPosition.y + videoPosition.height - 40),*/ }}><Timer startTime={this.state.videoStartDateTime} /></div>
                                    :
                                    null
                                }
                                {this.state.videoElements.length == 0 ?
                                    <div className="waiting-room">
                                        <div>
                                            <div className="loader-wrap">
                                                <Player
                                                    autoplay={true}
                                                    loop={true}
                                                    renderer={'svg'}
                                                    src="/lf30_editor_l5cxzdyf.json"
                                                    style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px" }}
                                                ></Player>
                                            </div>
                                            {this.props.uData && this.state.conversation && this.state.conversation.members && (this.state.conversation.members[0] === this.props.uData._id || (this.state.conversation.cohosts && this.state.conversation.cohosts.indexOf(this.props.uData._id) !== -1)) ?
                                                <p>{'Please wait for others to join the call.'.translate(this.props.lang)}</p>
                                                :
                                                this.state._reconnecting ?
                                                    <p>{'Reconnection in progress, please wait.'.translate(this.props.lang)}</p>
                                                    :
                                                    <p>{'Please wait for host to let you in.'.translate(this.props.lang)}</p>
                                            }
                                        </div>
                                    </div>
                                    :
                                    null

                                }

                            </div>

                            :
                            <div className="remote-container" >
                                {this.callArea ?
                                    <div className={"video-grid"} style={typeof window != 'undefined' && iosDevice() ? { /*maxWidth: `${window.innerWidth}px`*/ } : {}}>

                                        <div
                                            className="other-videos">
                                            <div className="scroll-area" style={{ paddingLeft: scrollAreaPaddingLeft }} ref={(node) => this.scrollArea = node}
                                                onScroll={() => {
                                                    this.checkVideoElements()
                                                }}
                                                onMouseDown={(e) => {
                                                    if (this.scrollArea) {
                                                        this.scrollArea.style.cursor = 'grabbing';


                                                        this.setState({
                                                            _dragging: {
                                                                x: e.clientX,
                                                                y: e.clientY,
                                                                left: this.scrollArea.scrollLeft,
                                                            }
                                                        })
                                                    }
                                                }}
                                                onMouseMove={(e) => {
                                                    if (this.state._dragging && this.scrollArea) {
                                                        // How far the mouse has been moved
                                                        const dx = e.clientX - this.state._dragging.x;

                                                        this.scrollArea.scrollLeft = this.state._dragging.left - dx;

                                                    }
                                                }}
                                                onMouseUp={(e) => {
                                                    this.scrollArea.style.cursor = 'grab';

                                                    this.setState({
                                                        _dragging: null
                                                    })
                                                }}
                                            >
                                                {
                                                    layoutOutput && !this.state.shareOn && layoutOutput != '-1' ?
                                                        this.renderOtherVideoElements(true, true)
                                                        :
                                                        this.renderOtherVideoElements(true, this.state.fullScreen ? false : true)
                                                }
                                            </div>
                                            {this.state.fullScreen && layoutOutput != 2 ?


                                                <div className="full-screen-other-elements">
                                                    <button><Isvg src={camIcon} /></button>
                                                    <div>
                                                        <div className="scroll-area"
                                                        >
                                                            {
                                                                layoutOutput && !this.state.shareOn && layoutOutput != '-1' ?
                                                                    null
                                                                    :
                                                                    this.renderOtherVideoElements(false, true)
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                    :
                                    null
                                }

                                {this.state.videoElements.length == 0 ?
                                    <div className="waiting-room">
                                        <div>
                                            <div className="loader-wrap">
                                                <Player
                                                    autoplay={true}
                                                    loop={true}
                                                    renderer={'svg'}
                                                    src="/lf30_editor_l5cxzdyf.json"
                                                    style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px" }}
                                                ></Player>

                                            </div>
                                            {this.props.uData && this.state.conversation && this.state.conversation.members && (this.state.conversation.members[0] === this.props.uData._id || (this.state.conversation.cohosts && this.state.conversation.cohosts.indexOf(this.props.uData._id) !== -1)) ?
                                                <p>{'Please wait for others to join the call.'.translate(this.props.lang)}</p>
                                                :
                                                this.state._reconnecting ?
                                                    <p>{'Reconnection in progress, please wait.'.translate(this.props.lang)}</p>
                                                    :
                                                    <p>{'Please wait for host to let you in.'.translate(this.props.lang)}</p>
                                            }
                                        </div>
                                    </div>
                                    :
                                    null

                                }

                            </div>
                        }

                        <video style={{ position: 'fixed', top: 0, left: '-3000px', width: 720, height: 720 }} autoPlay playsInline ref={(node) => this.mediaStreamVideo = node}></video>
                        {this.state.show5MinWarning ?
                            <div className="video-alert-message" style={layoutOutput == 2 ? { bottom: 160 } : {}}>
                                {'The meeting is scheduled to end in 5 minutes.'.translate(this.props.lang)}
                                <button onClick={() => this.setState({ show5MinWarning: false })}>&times;</button>
                            </div>
                            :
                            null
                        }
                        <VideoCallLogger items={this.state.videoGatewayLogs}></VideoCallLogger>

                        {this.state.kbpsError && this.state.kbpsError !== "checked" ?
                            <div className="video-alert-message" style={layoutOutput == 2 ? { bottom: 160 } : {}}>
                                {"Your internet speed is low!".translate(this.props.lang)}
                                <button onClick={() => this.setState({ kbpsError: false })}>&times;</button>
                            </div>
                            :
                            null
                        }
                        <button onClick={() => {
                            this.getDevices();
                            this.getBackgroundImages();
                            this.setState({ settings: !this.state.settings }, () => {
                                if (this.audioVideoStream) {
                                    const previewVideo = this.previewVideo;
                                    if (previewVideo) {
                                        previewVideo.srcObject = this.audioVideoStream;
                                        previewVideo.autoplay = true;
                                        previewVideo.playsInline = true;
                                        previewVideo.muted = true;
                                    }

                                }

                                if (this.state.audioOutputDevice)
                                    this.attachSinkIdTestSound(this.state.audioOutputDevice);
                            })
                        }} className={"settings-button"}>{<Isvg src={settingsIcon} />} <div className="chat-icons-tooltip">{'Settings'.translate(this.props.lang)}</div> </button>


                        {typeof window !== 'undefined' && detectMob() == true ?
                            <button onClick={this.flipCamera} className={this.state.camera ? "flip-camera" : "flip-camera off"}>{<Isvg src={flip} />} <div className="chat-icons-tooltip">{'Flip camera'.translate(this.props.lang)}</div> </button>
                            :
                            null
                        }

                        {this.state.videoElements && this.state.videoElements.length ?
                            <button onClick={this.state.hand ? this.handDown : this.raiseHand} className={this.state.hand ? `raise-hand ${typeof window !== 'undefined' && detectMob() ? 'mobile-raise-hand' : ''}` : `raise-hand off ${typeof window !== 'undefined' && detectMob() ? 'mobile-raise-hand' : ''}`}>{<Isvg src={handIcon} />} <div className="chat-icons-tooltip">{this.state.hand ? 'Hand down'.translate(this.props.lang) : 'Raise hand'.translate(this.props.lang)}</div> </button>
                            :
                            null
                        }
                        {this.state.conversation && !isSafari && !isFirefox && (((!((this.props.uData && this.props.uData._id === this.state.conversation.members[0]) || (this.props.uData && this.state.conversation.cohosts && this.state.conversation.cohosts.indexOf(this.props.uData._id) !== -1)) || !this.props.uData) && this.state.conversation && this.state.conversation.recordConversationAsVideoMembers) || (((this.props.uData && this.props.uData._id === this.state.conversation.members[0]) || (this.props.uData && this.state.conversation.cohosts && this.state.conversation.cohosts.indexOf(this.props.uData._id) !== -1)) && this.checkSettingsForHostVideoRecord())) ?


                            <button onClick={this.handleRecording} className={this.state.recording ? `record active` : `record`}>{<div className="icon"></div>} <div className="chat-icons-tooltip">{this.state.recording ? 'Stop recording'.translate(this.props.lang) : 'Start recording'.translate(this.props.lang)}</div> </button>
                            :
                            null
                        }

                        {typeof window !== 'undefined' && detectMob() == false ?
                            <button onClick={this.shareScreen} className={!this.state.shareOn ? "share-button off" : "share-button"}>{!this.state.shareOn ? <Isvg src={screenShareIcon} /> : <Isvg src={screenShareIcon} />} <div className="chat-icons-tooltip-off">{'Stop sharing'.translate(this.props.lang)}</div> <div className="chat-icons-tooltip-on">{'Share video'.translate(this.props.lang)}</div> </button>
                            :
                            null
                        }

                        {
                            typeof window !== 'undefined' && detectMob() == false ?

                                <button className="full-screen-button" onClick={() => {

                                    try {

                                        var elem = document.documentElement;

                                        if (!this.state.fullScreen) {


                                            if (this.state.showScreenShareMode) {
                                                this.setState({
                                                    showScreenShareMode: false
                                                })
                                            }

                                            if (elem.requestFullscreen) {
                                                elem.requestFullscreen();
                                            } else if (elem.webkitRequestFullscreen) { /* Safari */
                                                elem.webkitRequestFullscreen();
                                            } else if (elem.msRequestFullscreen) { /* IE11 */
                                                elem.msRequestFullscreen();
                                            }


                                        } else {
                                            if (!this.state.showScreenShareMode && this.state.shareOn) {
                                                this.setState({
                                                    showScreenShareMode: true
                                                })
                                            }


                                            if (document.exitFullscreen) {
                                                document.exitFullscreen();
                                            } else if (document.webkitExitFullscreen) { /* Safari */
                                                document.webkitExitFullscreen();
                                            } else if (document.msExitFullscreen) { /* IE11 */
                                                document.msExitFullscreen();
                                            }
                                        }
                                    } catch (e) { }
                                    //})
                                }}><Isvg src={moveIcon} /> <div className="chat-icons-tooltip">{this.state.fullScreen ? 'Exit from full screen'.translate(this.props.lang) : 'Switch to full screen'.translate(this.props.lang)}</div>  </button>
                                :

                                null
                        }


                        {
                            !this.state.showChat && (this.props.uData || (localStorage.guestID && this.state.videoElements && this.state.videoElements.length)) ?
                                <button className={this.state.videoCallNewMessage ? "open-chat-button open-chat-button-new-message" : "open-chat-button"} onClick={() => this.setState({ showChat: true, videoCallNewMessage: false }, () => {

                                    this.setState({
                                        page: 0
                                    }, () => {
                                        this.props.socket.emit('messages', { conversation: this.state.conversation._id, page: this.state.page })
                                    })

                                    this.checkConversationHostLicence(this.state.conversation)

                                    setTimeout(() => {
                                        if (this.messagesRef) {
                                            this.messagesRef.scrollTop = this.messagesRef.scrollHeight;
                                        }

                                    }, 100)

                                })}>
                                    <Isvg src={ChatIcon} />
                                    <div className='chat-icons-tooltip'>
                                        {this.state.videoCallNewMessage ? 'New message'.translate(this.props.lang) : 'Open chat'.translate(this.props.lang)}
                                    </div>

                                    {
                                        this.state.videoCallNewMessage ?
                                            <div className="new-message-circle"> </div>
                                            :

                                            null
                                    }
                                </button>
                                :
                                null
                        }

                        <div className="buttons">
                            {
                                this.state.videoInput && this.state.videoInput.length ?
                                    <button onClick={this.toggleCamera} className={this.state.cameraOff ? "camera-button off" : "camera-button"}>{this.state.cameraOff ? <Isvg src={cameraOffIcon} /> : <Isvg src={cameraIcon} />}  <div className="chat-icons-tooltip-off">{'Turn off camera'.translate(this.props.lang)}</div> <div className="chat-icons-tooltip-on">{'Turn on camera'.translate(this.props.lang)}</div> </button>
                                    :
                                    null
                            }
                            <button onClick={() => {
                                this.setState({
                                    endCallModal: true
                                })


                            }} className="end-call-button"><Isvg src={call} /> <div className="chat-icons-tooltip">{'End call'.translate(this.props.lang)}</div> </button>
                            {
                                this.state.audioInput && this.state.audioInput.length ?
                                    <button onClick={this.toggleMic} className={this.state.micOff ? "mic-button off" : "mic-button on"}>{this.state.micOff ? <Isvg src={micMutedIcon} /> : <Isvg src={micIcon} />} <div className="chat-icons-tooltip-off">{'Turn off microphone'.translate(this.props.lang)}</div> <div className="chat-icons-tooltip-on">{'Turn on microphone'.translate(this.props.lang)}</div></button>
                                    :
                                    null
                            }

                        </div>
                        {this.props.uData && this.state.conversation && this.state.conversation.members && (this.state.conversation.members[0] === this.props.uData._id || (this.state.conversation.cohosts && this.state.conversation.cohosts.includes(this.props.uData._id))) && this.state.videoStartDateTime && this.state.videoCallClients.length ?
                            <div className={this.state.newClientConnectedNotification && this.state.startNewClientAnimationColorChange ? `members-card new-client-notif ${this.state.showMembers ? 'show-members' : ''}` : `members-card ${this.state.showMembers ? 'show-members' : ''}`}
                                onMouseLeave={() => this.setState({ newClientConnectedNotification: false, showMembers: false }, () => {
                                    clearInterval(this.startNewClientAnimationInterval)
                                })} >

                                <div>
                                    <button onMouseEnter={() => this.setState({
                                        showMembers: true
                                    })} onClick={() => this.setState({
                                        showMembers: true
                                    })}><Isvg src={peopleIcon} /></button>
                                    <div className="list">
                                        <h6>{'Clients'.translate(this.props.lang)}
                                            <div>
                                                {this.state.videoCallClients && this.state.videoCallClients.filter(item => !item.allowed).length > 1 ?
                                                    <Button onClick={() => {
                                                        setTimeout(() => {
                                                            let videoCallClients = this.state.videoCallClients;

                                                            for (let i = 0; i < videoCallClients.length; i++) {
                                                                console.log('videoCallClients[i]', videoCallClients[i]);
                                                                if (!videoCallClients[i].allowed) {
                                                                    this.props.socket.emit('allowClient', { socketId: videoCallClients[i].socketId, ...videoCallClients[i].answerData, disableMic: this.state.conversation.muteParticipants ? true : false })
                                                                    videoCallClients[i].allowed = true;
                                                                }
                                                            }

                                                            this.setState({
                                                                videoCallClients

                                                            }, () => {
                                                                localStorage.setItem('videoCallClients', JSON.stringify(this.state.videoCallClients));
                                                            })

                                                        }, 2000);

                                                    }} color="primary">{'Let in all'.translate(this.props.lang)} </Button>
                                                    : null}

                                            </div>
                                        </h6>
                                        {

                                            this.state.videoCallClients.map((item, idx) => {
                                                return (
                                                    <div>
                                                        <div>
                                                            <img src={item.user.profilePicture ? API_ENDPOINT + item.user.profilePicture : profilePicture} />
                                                            {/* <p>{item.user.name}</p> */}
                                                            <p style={{ display: 'flex', alignItems: 'center' }}>
                                                                {
                                                                    item.user.bankIdVerified === true ?
                                                                        <Isvg src={secureIcon} style={{ width: 22, minWidth: 22 }} />
                                                                        :
                                                                        ''
                                                                }
                                                                {item.user.name + (item.user.bankIdVerified === true ? ('(' + 'BankID verified'.translate(this.props.lang) + ')') : '')}{item.user.realName ? ` - ${item.user.realName}` : ''}
                                                            </p>
                                                        </div>
                                                        <div>

                                                            {!item.allowed ?
                                                                <Button color="success" onClick={() => {
                                                                    setTimeout(() => {
                                                                        this.props.socket.emit('allowClient', { socketId: item.socketId, ...item.answerData, disableMic: this.state.conversation.muteParticipants ? true : false })
                                                                        let videoCallClients = this.state.videoCallClients;
                                                                        videoCallClients[idx].allowed = true;
                                                                        this.setState({
                                                                            videoCallClients
                                                                        }, () => {
                                                                            localStorage.setItem('videoCallClients', JSON.stringify(this.state.videoCallClients));
                                                                        })

                                                                    }, 2000);

                                                                }}>{'Let in'.translate(this.props.lang)}</Button>
                                                                :
                                                                <Button color="danger" onClick={() => {
                                                                    this.setState({
                                                                        removeClientModal: item,
                                                                        removeClientModalIdx: idx
                                                                    })
                                                                }}>{'Remove'.translate(this.props.lang)}</Button>
                                                            }
                                                            {item.allowed ?
                                                                <Button className='mute-client' onClick={() => {
                                                                    let mutedParticipants = this.state.mutedParticipants;
                                                                    mutedParticipants[item.uid] = !mutedParticipants[item.uid];
                                                                    this.setState({
                                                                        mutedParticipants
                                                                    }, () => {
                                                                        this.props.socket.emit('muteParticipant', { conversation: this.state.conversation._id, uid: item.uid, value: mutedParticipants[item.uid] })
                                                                    })

                                                                }}>{!this.state.mutedParticipants[item.uid] ? 'Mute'.translate(this.props.lang) : 'Unmute'.translate(this.props.lang)}</Button>
                                                                :
                                                                null
                                                            }

                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }


                                        <div style={{ justifyContent: 'flex-end' }} className="mute-buttons">
                                            {this.state.videoCallClients && this.state.videoCallClients.filter(item => item.allowed).length > 1 ?
                                                <Button onClick={() => {

                                                    let mutedParticipants = this.state.mutedParticipants;

                                                    let videoCallClients = this.state.videoCallClients;

                                                    for (let i = 0; i < videoCallClients.length; i++) {
                                                        if (mutedParticipants[videoCallClients[i].uid]) {
                                                            continue;
                                                        }
                                                        mutedParticipants[videoCallClients[i].uid] = true;
                                                        this.props.socket.emit('muteParticipant', { conversation: this.state.conversation._id, uid: videoCallClients[i].uid, value: mutedParticipants[videoCallClients[i].uid] })
                                                    }


                                                }} color="primary">{'Mute all'.translate(this.props.lang)} </Button>
                                                :
                                                null
                                            }
                                            {this.state.videoCallClients && this.state.videoCallClients.filter(item => item.allowed).length > 1 ?
                                                <Button style={{ marginLeft: 10 }} onClick={() => {

                                                    let mutedParticipants = this.state.mutedParticipants;

                                                    let videoCallClients = this.state.videoCallClients;

                                                    for (let i = 0; i < videoCallClients.length; i++) {
                                                        if (!mutedParticipants[videoCallClients[i].uid]) {
                                                            continue;
                                                        }
                                                        mutedParticipants[videoCallClients[i].uid] = false;
                                                        this.props.socket.emit('muteParticipant', { conversation: this.state.conversation._id, uid: videoCallClients[i].uid, value: mutedParticipants[videoCallClients[i].uid] })
                                                    }

                                                    this.setState({
                                                        mutedParticipants
                                                    })
                                                }} color="primary">{'Unmute all'.translate(this.props.lang)} </Button>
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }
                        {/* video chat */}
                        {
                            this.props.uData && this.state.showChat ?
                                <div onDrop={this.handleDrop}
                                    onDragEnter={(e) => {
                                        e?.preventDefault();
                                        e?.stopPropagation();
                                        this.setState({ isDragging: true })
                                    }}
                                    onDragLeave={(e) => {
                                        e?.preventDefault();
                                        e?.stopPropagation();
                                        this.setState({ isDragging: false }, () => {
                                            let oldScrollHeight = 0;
                                            if (this.messagesRef) {
                                                oldScrollHeight = this.messagesRef.scrollHeight;
                                            }

                                            if (this.messagesRef && this.state.page == 0) {
                                                this.messagesRef.scrollTop = this.messagesRef.scrollHeight;
                                            } else if (oldScrollHeight) {
                                                this.messagesRef.scrollTop = this.messagesRef.scrollTop + (this.messagesRef.scrollHeight - oldScrollHeight);
                                            }
                                        })
                                    }}
                                    onDragOver={e => {
                                        e?.preventDefault();
                                        e?.stopPropagation();
                                    }}
                                    className={this.state.showChat ? `messages-wrap show-chat` : 'messages-wrap'}>
                                    {this.state.isDragging ? <div className='drag-drop-container-video'>
                                        {'Drop to send'.translate?.(this.props.lang)}
                                    </div>
                                        :

                                        <><div class="messages-wrapper">
                                            {
                                                this.state.showChat ?
                                                    <button className="hide-chat" onClick={() => this.setState({ showChat: null })}>&times;</button>
                                                    :

                                                    null
                                            }

                                            <div className="messages" ref={(node) => this.messagesRef = node}>
                                                {
                                                    this.state.messages.map((item, idx) => {
                                                        if (item.type == 'special') {
                                                            return (
                                                                <>
                                                                    {this.state.messages.length > 15 && idx == 1 ?
                                                                        <div ><Waypoint
                                                                            onEnter={() => {
                                                                                this.setState({
                                                                                    page: this.state.page + 1
                                                                                }, () => {
                                                                                    this.props.socket.emit('messages', { conversation: this.state.conversation._id, page: this.state.page })
                                                                                })
                                                                            }}
                                                                        />
                                                                        </div>
                                                                        :
                                                                        null
                                                                    }

                                                                    {item.videoStarted ? <h6 className='video-chat-notify'>
                                                                        <Isvg src={videoChatIcon} />
                                                                        <span className='time-started'>{moment.unix(item.tsCreated).format(this.props.dateFormat + ' HH:mm')}</span>
                                                                        <span className='user-name'>{item.user.name}</span>
                                                                        <span className='notify'> {'Started video meeting'.translate(this.props.lang)}</span>
                                                                    </h6> : null}

                                                                    {item.videoJoined ? <h6 className='video-chat-notify'>
                                                                        <Isvg src={userChatIcon} />
                                                                        <span className='time-started'>{moment.unix(item.tsCreated).format(this.props.dateFormat + ' HH:mm')}</span>
                                                                        <span className='user-name'>{item.user.name}</span>
                                                                        <span className='notify'> {'Joined video meeting'.translate(this.props.lang)}</span>
                                                                    </h6> : null}

                                                                    {item.videoLeft ? <h6 className='video-chat-notify'>
                                                                        <Isvg src={userChatIcon} />
                                                                        <span className='time-started'>{moment.unix(item.tsCreated).format(this.props.dateFormat + ' HH:mm')}</span>
                                                                        <span className='user-name'>{item.user.name}</span>
                                                                        <span className='notify'> {'Left video meeting'.translate(this.props.lang)}</span>
                                                                    </h6> : null}

                                                                    {item.videoEnded ? <h6 className='video-chat-notify'>
                                                                        <Isvg src={userChatIcon} />
                                                                        <span className='time-started'>{moment.unix(item.tsCreated).format(this.props.dateFormat + ' HH:mm')}</span>
                                                                        <span className='user-name'>{item.user.name}</span>
                                                                        <span className='notify'> {'Ended video meeting'.translate(this.props.lang)}</span>
                                                                    </h6> : null}
                                                                </>
                                                            )

                                                        } else {
                                                            return (
                                                                <div className={item.uid == this.props.uData._id ? 'right' : ''} style={{ wordBreak: 'break-all' }}>
                                                                    {this.state.messages.length > 15 && idx == 1 ?
                                                                        <div ><Waypoint
                                                                            onEnter={() => {
                                                                                this.setState({
                                                                                    page: this.state.page + 1
                                                                                }, () => {
                                                                                    this.props.socket.emit('messages', { conversation: this.state.conversation._id, page: this.state.page })
                                                                                })
                                                                            }}
                                                                        />
                                                                        </div>
                                                                        :
                                                                        null
                                                                    }

                                                                    {
                                                                        item.uid != this.props.uData._id ?
                                                                            <img src={item.user && item.user.profilePicture ? API_ENDPOINT + item.user.profilePicture : profilePicture} />
                                                                            :
                                                                            null
                                                                    }
                                                                    <div>
                                                                        <div onClick={() => {
                                                                            let showSeen = this.state.showSeen;
                                                                            showSeen[item._id] = !showSeen[item._id];
                                                                            this.setState({
                                                                                showSeen
                                                                            })
                                                                        }}>
                                                                            {
                                                                                item.uid == this.props.uData._id && !item.deletedMessage ?
                                                                                    <div className="delete-message-icon" onClick={() => this.setState({ deleteMessageModal: item })}>&times;</div>
                                                                                    :
                                                                                    null
                                                                            }
                                                                            <div className="username">
                                                                                {item.user && item.user.name}
                                                                            </div>
                                                                            {
                                                                                item.deletedMessage ?
                                                                                    <p style={{ marginBottom: 0, fontStyle: 'italic', fontWeight: 400, fontSize: 14 }}>{item.message + ' ' + 'deleted a message'.translate(this.props.lang)}</p>
                                                                                    :
                                                                                    null
                                                                            }
                                                                            {item.image ? <a target='_blank' href={API_ENDPOINT + item.image}><img className="message-image" src={API_ENDPOINT + item.image} /></a> : null}

                                                                            {
                                                                                item.video ?
                                                                                    <div className="video-in-chat-wrap">
                                                                                        <video width="320" height="240" controls>
                                                                                            <source src={API_ENDPOINT + item.video} type="video/mp4" />
                                                                                            <source src={API_ENDPOINT + item.video} type="video/ogg" />
                                                                                        </video>
                                                                                    </div>
                                                                                    :
                                                                                    null
                                                                            }

                                                                            {
                                                                                !item.healthCareId && !item.deletedMessage ?
                                                                                    item.message == 'The patient filled out the form successfully' ?
                                                                                        item.message.translate(this.props.lang)
                                                                                        :
                                                                                        <>
                                                                                            {
                                                                                                item.message.split('\n').length ?
                                                                                                    item.message.split('\n').map((text, t) => {
                                                                                                        if (t != item.message.split('\n').length - 1)
                                                                                                            return (
                                                                                                                <>{text}<br /></>
                                                                                                            )
                                                                                                        else
                                                                                                            return (
                                                                                                                text
                                                                                                            )
                                                                                                    })
                                                                                                    :
                                                                                                    item.message
                                                                                            }
                                                                                        </>
                                                                                    :
                                                                                    null
                                                                            }
                                                                            {
                                                                                item.document ?
                                                                                    <div className={item.uid == this.props.uData._id ? 'document-white' : 'document'}><a target="_blank" download href={API_ENDPOINT + item.document.file}><Isvg src={attach} />{item.document.name}</a></div>
                                                                                    :
                                                                                    null
                                                                            }
                                                                            {!item.deletedMessage && item.links && item.links.length ? item.links.map(link => <a href={link.link} target='_blank' className={`message-link-container ${this.props.uData?._id === item.uid ? 'message-link-container-right' : ''} ${item.links.length > 1 ? 'message-link-adjust-width' : ''}`} onClick={(e) => e.stopPropagation()}>
                                                                                <div className='message-link-image'>
                                                                                    <img src={link.image} />
                                                                                </div>
                                                                                <div className='message-link-title-and-link'>
                                                                                    <h6>{link.title}</h6>
                                                                                    <p>{link.link}</p>
                                                                                </div>
                                                                            </a>) : null}

                                                                        </div>
                                                                        <div className="date date-out">{moment.unix(item.timestamp).format(`${this.props.dateFormat} HH:mm`)}</div>
                                                                        {
                                                                            (item.seen && item.seen.filter(seenMember => seenMember.uid != this.props.uData._id).length) && (idx == this.state.messages.length - 1 || this.state.showSeen[item._id]) ?
                                                                                <p>
                                                                                    {'Seen by: '.translate(this.props.lang)} {item.seen && item.seen.filter(seenMember => seenMember.uid != this.props.uData._id && seenMember.uid && seenMember.uid.indexOf('guest') == -1).map((seenMember) => { return memebersDict[seenMember.uid] ? memebersDict[seenMember.uid].name : '' }).join(', ')}
                                                                                </p>
                                                                                :
                                                                                null
                                                                        }

                                                                    </div>
                                                                </div>
                                                            )
                                                        }

                                                    })
                                                }
                                                {
                                                    this.state.userTyping ?
                                                        <span className="user-typing">{this.state.userTyping.name} {'is typing...'.translate(this.props.lang)}</span>
                                                        :
                                                        null
                                                }

                                            </div>
                                            {this.state.conversation && !this.state.conversation.closed ?
                                                <div className="send-message">

                                                    {
                                                        <div>

                                                            <button className='more-icon' onClick={() => { this.setState({ mobileBtnsShow: !this.state.mobileBtnsShow }) }}><Isvg src={moreIcon} /></button>
                                                            <div className={this.state.mobileBtnsShow ? 'mobile-btns-upload-phrase show-mobile-btns-upload-phrase' : 'mobile-btns-upload-phrase'}>
                                                                <div className="overlay-test" onClick={() => this.setState({ mobileBtnsShow: !this.state.mobileBtnsShow })} />
                                                                <button className={this.state._documentUploading ? 'attach-menu uploading' : this.state.document ? 'attach-menu uploaded' : "attach-menu"} onClick={() => {
                                                                    this.setState({ mobileBtnsShow: !this.state.mobileBtnsShow })
                                                                    if (this.documentInput) this.documentInput.click()
                                                                }}>
                                                                    <div className="button-menu-icon-wrap">
                                                                        <Isvg src={attach} />
                                                                    </div>
                                                                    <div className='chat-icons-text'>
                                                                        {'Attach file'.translate(this.props.lang)}
                                                                    </div>
                                                                </button>


                                                                <button className={this.state._imageUploading ? 'image-menu uploading' : this.state.image ? 'image-menu uploaded' : "image-menu"} onClick={() => {
                                                                    this.setState({ mobileBtnsShow: !this.state.mobileBtnsShow })
                                                                    if (this.imageInput) this.imageInput.click()
                                                                }}>
                                                                    <div className="button-menu-icon-wrap">
                                                                        <Isvg src={image} />
                                                                    </div>
                                                                    <div className='chat-icons-text'>
                                                                        {'Attach image'.translate(this.props.lang)}
                                                                    </div>
                                                                </button>
                                                                <button className={this.state._videoUploading ? 'video-menu uploading' : this.state.video ? 'video-menu uploaded' : "video-menu"} onClick={() => {
                                                                    this.setState({ mobileBtnsShow: !this.state.mobileBtnsShow })
                                                                    if (this.videoContentInput) this.videoContentInput.click()
                                                                }}>
                                                                    <div className="button-menu-icon-wrap">
                                                                        <Isvg src={videoIcon} />
                                                                    </div>
                                                                    <div className='chat-icons-text'>
                                                                        {'Attach video'.translate(this.props.lang)}
                                                                    </div>
                                                                </button>

                                                                {
                                                                    this.props.uData && this.props.uData.userLevel == 20 && this.state.conversation && this.state.conversation.host == this.props.uData._id && this.props.uData.host || this.props.uData.singleCallHost ?
                                                                        <button className={"phrase-menu"} onClick={() => { this.setState({ quickPhraseModal: true, mobileBtnsShow: !this.state.mobileBtnsShow }) }}>
                                                                            <div className="button-menu-icon-wrap"><Isvg src={phraseIcon} /></div>

                                                                            <div className='chat-icons-text'>
                                                                                {'Quick phrase'.translate(this.props.lang)}
                                                                            </div>
                                                                        </button>
                                                                        :
                                                                        null
                                                                }

                                                            </div>
                                                        </div>

                                                    }

                                                    <Input onKeyUp={(e) => {
                                                        if (e.keyCode == 13 && !e.shiftKey) {
                                                            e.preventDefault();
                                                            this.sendMessage();
                                                            return false;
                                                        }
                                                    }} onKeyDown={this.emitUserTyping} placeholder={"Write a message...".translate(this.props.lang)} type="textarea" onChange={(e) => this.setState({ message: e.target.value })} value={this.state.message} />

                                                    <button disabled={this.state._imageUploading} className="send" onClick={this.sendMessage}>
                                                        <Isvg src={send} />
                                                        <div className='chat-icons-tooltip'>
                                                            {'Send'.translate(this.props.lang)}
                                                        </div>
                                                    </button>
                                                </div>
                                                :
                                                null
                                            }
                                        </div></>
                                    }
                                </div>
                                :
                                localStorage.guestID && this.state.showChat ?
                                    <div onDrop={this.handleDrop}
                                        onDragEnter={(e) => {
                                            e?.preventDefault();
                                            e?.stopPropagation();
                                            this.setState({ isDragging: true })
                                        }}
                                        onDragLeave={(e) => {
                                            e?.preventDefault();
                                            e?.stopPropagation();
                                            this.setState({ isDragging: false }, () => {
                                                let oldScrollHeight = 0;
                                                if (this.messagesRef) {
                                                    oldScrollHeight = this.messagesRef.scrollHeight;
                                                }

                                                if (this.messagesRef && this.state.page == 0) {
                                                    this.messagesRef.scrollTop = this.messagesRef.scrollHeight;
                                                } else if (oldScrollHeight) {
                                                    this.messagesRef.scrollTop = this.messagesRef.scrollTop + (this.messagesRef.scrollHeight - oldScrollHeight);
                                                }
                                            })
                                        }}
                                        onDragOver={e => {
                                            e?.preventDefault();
                                            e?.stopPropagation();
                                        }}
                                        className={this.state.showChat ? `messages-wrap show-chat` : 'messages-wrap'}>
                                        {this.state.isDragging ? <div className='drag-drop-container-video'>
                                            {'Drop to send'.translate?.(this.props.lang)}
                                        </div> :
                                            <><div class="messages-wrapper">
                                                {
                                                    this.state.showChat ?
                                                        <button className="hide-chat" onClick={() => this.setState({ showChat: null })}>&times;</button>
                                                        :

                                                        null
                                                }

                                                <div className="messages" ref={(node) => this.messagesRef = node}>
                                                    {
                                                        this.state.messages.map((item, idx) => {
                                                            if (item.type == 'special') {
                                                                return (
                                                                    <>
                                                                        {this.state.messages.length > 15 && idx == 1 ?
                                                                            <div ><Waypoint
                                                                                onEnter={() => {
                                                                                    this.setState({
                                                                                        page: this.state.page + 1
                                                                                    }, () => {
                                                                                        this.props.socket.emit('messages', { conversation: this.state.conversation._id, page: this.state.page })
                                                                                    })
                                                                                }}
                                                                            />
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }

                                                                        {item.videoStarted ? <h6 className='video-chat-notify'>
                                                                            <Isvg src={videoChatIcon} />
                                                                            <span className='time-started'>{moment.unix(item.tsCreated).format(this.props.dateFormat + ' HH:mm')}</span>
                                                                            <span className='user-name'>{item.user.name}</span>
                                                                            <span className='notify'> {'Started video meeting'.translate(this.props.lang)}</span>
                                                                        </h6> : null}

                                                                        {item.videoJoined ? <h6 className='video-chat-notify'>
                                                                            <Isvg src={userChatIcon} />
                                                                            <span className='time-started'>{moment.unix(item.tsCreated).format(this.props.dateFormat + ' HH:mm')}</span>
                                                                            <span className='user-name'>{item.user.name}</span>
                                                                            <span className='notify'> {'Joined video meeting'.translate(this.props.lang)}</span>
                                                                        </h6> : null}

                                                                        {item.videoLeft ? <h6 className='video-chat-notify'>
                                                                            <Isvg src={userChatIcon} />
                                                                            <span className='time-started'>{moment.unix(item.tsCreated).format(this.props.dateFormat + ' HH:mm')}</span>
                                                                            <span className='user-name'>{item.user.name}</span>
                                                                            <span className='notify'> {'Left video meeting'.translate(this.props.lang)}</span>
                                                                        </h6> : null}

                                                                        {item.videoEnded ? <h6 className='video-chat-notify'>
                                                                            <Isvg src={userChatIcon} />
                                                                            <span className='time-started'>{moment.unix(item.tsCreated).format(this.props.dateFormat + ' HH:mm')}</span>
                                                                            <span className='user-name'>{item.user.name}</span>
                                                                            <span className='notify'> {'Ended video meeting'.translate(this.props.lang)}</span>
                                                                        </h6> : null}
                                                                    </>
                                                                )

                                                            } else {
                                                                return (
                                                                    <div className={item.uid == localStorage.guestID ? 'right' : ''} style={{ wordBreak: 'break-all' }}>
                                                                        {this.state.messages.length > 15 && idx == 1 ?
                                                                            <div ><Waypoint
                                                                                onEnter={() => {
                                                                                    this.setState({
                                                                                        page: this.state.page + 1
                                                                                    }, () => {
                                                                                        this.props.socket.emit('messages', { conversation: this.state.conversation._id, page: this.state.page })
                                                                                    })
                                                                                }}
                                                                            />
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }
                                                                        {
                                                                            item.uid != localStorage.guestID ?
                                                                                <img src={item.user && item.user.profilePicture ? API_ENDPOINT + item.user.profilePicture : profilePicture} />
                                                                                :
                                                                                null
                                                                        }
                                                                        <div>
                                                                            <div onClick={() => {
                                                                                let showSeen = this.state.showSeen;
                                                                                showSeen[item._id] = !showSeen[item._id];
                                                                                this.setState({
                                                                                    showSeen
                                                                                })
                                                                            }}>
                                                                                {
                                                                                    item.uid == localStorage.guestID && !item.deletedMessage ?
                                                                                        <div className="delete-message-icon" onClick={() => this.setState({ deleteMessageModal: item })}>&times;</div>
                                                                                        :
                                                                                        null
                                                                                }
                                                                                <div className="username">
                                                                                    {item.user && item.user.name}
                                                                                </div>
                                                                                {
                                                                                    item.deletedMessage ?
                                                                                        <p style={{ marginBottom: 0, fontStyle: 'italic', fontWeight: 400, fontSize: 14 }}>{item.message + ' ' + 'deleted a message'.translate(this.props.lang)}</p>
                                                                                        :
                                                                                        null
                                                                                }
                                                                                {item.image ? <a target='_blank' href={API_ENDPOINT + item.image}><img className="message-image" src={API_ENDPOINT + item.image} /></a> : null}

                                                                                {
                                                                                    item.video ?
                                                                                        <div className="video-in-chat-wrap">
                                                                                            <video width="320" height="240" controls>
                                                                                                <source src={API_ENDPOINT + item.video} type="video/mp4" />
                                                                                                <source src={API_ENDPOINT + item.video} type="video/ogg" />
                                                                                            </video>
                                                                                        </div>
                                                                                        :
                                                                                        null
                                                                                }
                                                                                {/* {item.message} */}
                                                                                {
                                                                                    !item.healthCareId && !item.deletedMessage ?
                                                                                        item.message == 'The patient filled out the form successfully' ?
                                                                                            item.message.translate(this.props.lang)
                                                                                            :
                                                                                            <>
                                                                                                {
                                                                                                    item.message.split('\n').length ?
                                                                                                        item.message.split('\n').map((text, t) => {
                                                                                                            if (t != item.message.split('\n').length - 1)
                                                                                                                return (
                                                                                                                    <>{text}<br /></>
                                                                                                                )
                                                                                                            else
                                                                                                                return (
                                                                                                                    text
                                                                                                                )
                                                                                                        })
                                                                                                        :
                                                                                                        item.message
                                                                                                }
                                                                                            </>
                                                                                        :
                                                                                        null
                                                                                }
                                                                                {
                                                                                    item.document ?
                                                                                        <div className={item.uid == localStorage.guestID ? 'document-white' : 'document'}><a target="_blank" download href={API_ENDPOINT + item.document.file}><Isvg src={attach} />{item.document.name}</a></div>
                                                                                        :
                                                                                        null
                                                                                }

                                                                                {
                                                                                    !item.deletedMessage && item.links && item.links.length ? item.links.map(link => <a href={link.link} target='_blank' className={`message-link-container ${this.props.uData?._id === item.uid ? 'message-link-container-right' : ''} ${item.links.length > 1 ? 'message-link-adjust-width' : ''}`} onClick={(e) => e.stopPropagation()}>
                                                                                        <div className='message-link-image'>
                                                                                            <img src={link.image} />
                                                                                        </div>
                                                                                        <div className='message-link-title-and-link'>
                                                                                            <h6>{link.title}</h6>
                                                                                            <p>{link.link}</p>
                                                                                        </div>
                                                                                    </a>) : null
                                                                                }

                                                                            </div>
                                                                            <div className="date date-out">{moment.unix(item.timestamp).format(`${this.props.dateFormat} HH:mm`)}</div>
                                                                            {
                                                                                (item.seen && item.seen.filter(seenMember => seenMember.uid != localStorage.guestID).length) && (idx == this.state.messages.length - 1 || this.state.showSeen[item._id]) ?
                                                                                    <p>
                                                                                        {'Seen by: '.translate(this.props.lang)} {item.seen && item.seen.filter(seenMember => seenMember.uid != localStorage.guestID && seenMember.uid && seenMember.uid.indexOf('guest') == -1).map((seenMember) => { return memebersDict[seenMember.uid] ? memebersDict[seenMember.uid].name : '' }).join(', ')}
                                                                                    </p>
                                                                                    :
                                                                                    null
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                )
                                                            }

                                                        })
                                                    }
                                                    {
                                                        this.state.userTyping ?
                                                            <span className="user-typing">{this.state.userTyping.name} {'is typing...'.translate(this.props.lang)}</span>
                                                            :
                                                            null
                                                    }

                                                </div>
                                                {this.state.conversation && !this.state.conversation.closed ?
                                                    <div className="send-message">
                                                        {
                                                            this.state.guestImageVideoInput ?
                                                                <div>

                                                                    <button className='more-icon' onClick={() => { this.setState({ mobileBtnsShow: !this.state.mobileBtnsShow }) }}><Isvg src={moreIcon} /></button>
                                                                    <div className={this.state.mobileBtnsShow ? 'mobile-btns-upload-phrase show-mobile-btns-upload-phrase' : 'mobile-btns-upload-phrase'}>
                                                                        <div className="overlay-test" onClick={() => this.setState({ mobileBtnsShow: !this.state.mobileBtnsShow })} />
                                                                        <button className={this.state._documentUploading ? 'attach-menu uploading' : this.state.document ? 'attach-menu uploaded' : "attach-menu"} onClick={() => {
                                                                            this.setState({ mobileBtnsShow: !this.state.mobileBtnsShow })
                                                                            if (this.documentInput) this.documentInput.click()
                                                                        }}>
                                                                            <div className="button-menu-icon-wrap">
                                                                                <Isvg src={attach} />
                                                                            </div>
                                                                            <div className='chat-icons-text'>
                                                                                {'Attach file'.translate(this.props.lang)}
                                                                            </div>
                                                                        </button>


                                                                        <button className={this.state._imageUploading ? 'image-menu uploading' : this.state.image ? 'image-menu uploaded' : "image-menu"} onClick={() => {
                                                                            this.setState({ mobileBtnsShow: !this.state.mobileBtnsShow })
                                                                            if (this.imageInput) this.imageInput.click()
                                                                        }}>
                                                                            <div className="button-menu-icon-wrap">
                                                                                <Isvg src={image} />
                                                                            </div>
                                                                            <div className='chat-icons-text'>
                                                                                {'Attach image'.translate(this.props.lang)}
                                                                            </div>
                                                                        </button>
                                                                        <button className={this.state._videoUploading ? 'video-menu uploading' : this.state.video ? 'video-menu uploaded' : "video-menu"} onClick={() => {
                                                                            this.setState({ mobileBtnsShow: !this.state.mobileBtnsShow })
                                                                            if (this.videoContentInput) this.videoContentInput.click()
                                                                        }}>
                                                                            <div className="button-menu-icon-wrap">
                                                                                <Isvg src={videoIcon} />
                                                                            </div>
                                                                            <div className='chat-icons-text'>
                                                                                {'Attach video'.translate(this.props.lang)}
                                                                            </div>
                                                                        </button>

                                                                    </div>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                        <Input onKeyUp={(e) => {
                                                            if (e.keyCode == 13 && !e.shiftKey) {
                                                                e.preventDefault();
                                                                this.sendMessage();
                                                                return false;
                                                            }
                                                        }} onKeyDown={this.emitUserTyping} placeholder={"Write a message...".translate(this.props.lang)} type="textarea" onChange={(e) => this.setState({ message: e.target.value })} value={this.state.message} />


                                                        <button disabled={this.state._imageUploading} className="send" onClick={this.sendMessage}>
                                                            <Isvg src={send} />
                                                            <div className='chat-icons-tooltip'>
                                                                {'Send'.translate(this.props.lang)}
                                                            </div>
                                                        </button>
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div></>
                                        }
                                    </div>
                                    :
                                    null
                        }
                        {/* end video chat */}
                        {
                            this.state.quickPhraseModal ?
                                <Modal isOpen={this.state.quickPhraseModal} size="lg" centered >
                                    <ModalHeader toggle={() => this.setState({ quickPhraseModal: !this.state.quickPhraseModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ quickPhraseModal: !this.state.quickPhraseModal })}>&times;</button>}>{'Quick phrases'.translate(this.props.lang)}</ModalHeader>
                                    <ModalBody>
                                        {
                                            this.state.phrases && this.state.phrases.map((item, idx) => {
                                                return (
                                                    <div className="quick-phrase-wrap">
                                                        <div className="content">
                                                            <div className="title">{item.title}</div>
                                                            <div className="phrase">
                                                                {
                                                                    item.phrase.split('\n').length ?
                                                                        item.phrase.split('\n').map((text, t) => {
                                                                            if (t != item.phrase.split('\n').length - 1)
                                                                                return (
                                                                                    <>{text}<br /></>
                                                                                )
                                                                            else
                                                                                return (
                                                                                    text
                                                                                )
                                                                        })
                                                                        :
                                                                        item.phrase
                                                                }
                                                            </div>
                                                        </div>
                                                        <Button color='primary' onClick={() => {
                                                            this.setState({ message: item.phrase, quickPhraseModal: false })
                                                        }}>{'Use'.translate(this.props.lang)}</Button>

                                                    </div>
                                                )
                                            })
                                        }

                                    </ModalBody>

                                    <ModalFooter style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button color="primary" onClick={() => this.setState({ quickPhraseModal: null })}> {'Close'.translate(this.props.lang)} </Button>
                                    </ModalFooter>
                                </Modal>
                                :
                                null
                        }

                        {this.state.error ?

                            <ErrorModal lang={this.props.lang}
                                isOpen={this.state.error}
                                toggle={() => this.setState({ error: null })}

                            >
                                {this.state.error.translate(this.props.lang)}
                            </ErrorModal>

                            :
                            null
                        }
                        {this.state.endCallModal ?
                            this.props.uData && this.state.conversation && this.state.conversation.members && (this.state.conversation.members[0] === this.props.uData._id || (this.state.conversation.cohosts && this.state.conversation.cohosts.indexOf(this.props.uData._id) !== -1)) ?
                                <Modal isOpen={this.state.endCallModal} style={{ marginTop: '50px' }} centered zIndex={99999999}>
                                    <ModalHeader style={{ margin: 'auto' }}>{'Warning'.translate(this.props.lang)}</ModalHeader>
                                    <ModalBody className="delete-modal">
                                        <img src={warrning} />
                                        <div style={{ marginTop: 10 }}>{'Do you really want to end this call?'.translate(this.props.lang)}</div>
                                    </ModalBody>
                                    {this.state.videoElements.length <= 1 ?
                                        <ModalFooter className='buttons-right-blue'>
                                            <Button color='primary' style={{ margin: 5, backgroundColor: "#3A3F56" }} onClick={() => {
                                                this.endCall();
                                            }}>{'Yes'.translate(this.props.lang)}</Button>
                                            <Button color='primary' style={{ margin: 5 }} onClick={() => this.setState({ endCallModal: !this.state.endCallModal })}>{'No'.translate(this.props.lang)}</Button>
                                        </ModalFooter>

                                        :
                                        <ModalFooter className='buttons-right-blue'>
                                            <Button color='primary' style={{ margin: 5, backgroundColor: "#3A3F56" }} onClick={() => {
                                                if (this.state.videoElements.length == 0) {

                                                    this.endCall(false);

                                                } else {
                                                    this.endCall(true, false, false);
                                                }

                                            }}>{'End meeting'.translate(this.props.lang)}</Button>

                                            <Button color='primary' style={{ margin: 5, backgroundColor: "#3A3F56" }} onClick={() => {
                                                this.endCall(true, false, true, false);
                                            }}>{'Leave metting'.translate(this.props.lang)}</Button>

                                            <Button color='primary' style={{ margin: 5 }} onClick={() => this.setState({ endCallModal: !this.state.endCallModal })}>{'No'.translate(this.props.lang)}</Button>
                                        </ModalFooter>
                                    }
                                </Modal>
                                :
                                <Modal isOpen={this.state.endCallModal} style={{ marginTop: '50px' }} centered zIndex={99999999}>
                                    <ModalHeader style={{ margin: 'auto' }}>{'Warning'.translate(this.props.lang)}</ModalHeader>
                                    <ModalBody className="delete-modal">
                                        <img src={warrning} />
                                        <div style={{ marginTop: 10 }}>{'Do you really want to end this call?'.translate(this.props.lang)}</div>
                                    </ModalBody>
                                    <ModalFooter className='buttons-right-blue'>
                                        <Button color='primary' style={{ margin: 5, backgroundColor: "#3A3F56" }} onClick={() => {
                                            if (this.props.uData && this.state.conversation && this.state.conversation.members && (this.state.conversation.members[0] === this.props.uData._id || (this.state.conversation.cohosts && this.state.conversation.cohosts.indexOf(this.props.uData._id) !== -1))) {
                                                this.endCall();
                                            } else {
                                                if (this.state.videoElements.length == 0) {
                                                    if (!this.props.uData) {
                                                        this.endCall(true, true);
                                                    } else {
                                                        this.endCall(false);
                                                    }
                                                } else {
                                                    this.endCall();
                                                }
                                            }
                                        }}>{'Yes'.translate(this.props.lang)}</Button>
                                        <Button color='primary' style={{ margin: 5 }} onClick={() => this.setState({ endCallModal: !this.state.endCallModal })}>{'No'.translate(this.props.lang)}</Button>
                                    </ModalFooter>
                                </Modal>
                            : null
                        }
                        <input ref={(node) => this.documentInput = node} type="file" accept=".doc, .docx, .pdf" key={this.state.uploadTimestampDoc} onChange={this.uploadDocument} style={{ pointerEvents: 'none', opacity: 0 }} />
                        <input ref={(node) => this.imageInput = node} type="file" accept="image/png, image/jpeg, image/jpg, image/gif, image/svg" key={this.state.uploadTimestamp} onChange={this.uploadImage} style={{ pointerEvents: 'none', opacity: 0 }} />
                        <input ref={(node) => this.videoContentInput = node} type="file" accept=".mp4, .avi" key={this.state.uploadTimestampVideo} onChange={this.uploadVideo} style={{ pointerEvents: 'none', opacity: 0 }} />

                        {
                            this.state.memberTooltip && this.state.mainVideoElement != this.state.memberTooltip.client && !this.state.fullScreen ?
                                <div className="member-name-tooltop" style={{ top: this.state.memberTooltip.top, left: this.state.memberTooltip.left, }}>
                                    {this.state.memberTooltip.name}
                                </div>
                                :

                                null
                        }

                    </div>
                    {
                        <div>
                            <audio ref={(node) => this.audioRef = node} >
                                <source src={notificationSound} type="audio/ogg"></source>
                                <source src={notificationSound} type="audio/mpeg"></source>
                            </audio>
                        </div>
                    }
                    {this.state.settings ?
                        <div className="web-rtc-test" style={{ zIndex: 9999999 }}>
                            <div>
                                <Row>
                                    <Col lg="8">
                                        {this.state.videoInput && this.state.videoInput.length ?
                                            <FormGroup>
                                                <Label>{'Video source'.translate(this.props.lang)}</Label>
                                                <div>
                                                    <Input type="select" disabled={this.state.shareScreenSource} value={this.state.videoInputDevice} onChange={(e) => this.setState({ videoInputDevice: e.target.value }, () => this.getStream(true))}>
                                                        {
                                                            this.state.videoInput ?
                                                                this.state.videoInput.map((item) => {
                                                                    return <option value={item.deviceId}>{item.label}</option>
                                                                })
                                                                :

                                                                null
                                                        }
                                                    </Input>
                                                    <Isvg src={cameraIcon} />
                                                </div>
                                            </FormGroup>
                                            :
                                            null
                                        }

                                        {this.state.videoInput && this.state.videoInput.length ?
                                            <FormGroup>
                                                <Label>{'Video resolution (maximum)'.translate(this.props.lang)}</Label>
                                                <div>
                                                    <Input type="select" value={this.state.videoResolution} onChange={(e) => this.setState({ videoResolution: e.target.value }, () => this.getStream(true))}>
                                                        <option value={'480'}>{'Standard definition'.translate(this.props.lang)}</option>
                                                        <option value={'720'}>{'High definition'.translate(this.props.lang)}</option>
                                                        {/* <option disabled={this.state.videoElements.length >= 10 || !this.state.fullHDSupport} value={'1080'}>{'Full high definition'.translate(this.props.lang)}</option> */}
                                                    </Input>
                                                    <Isvg src={cameraIcon} />
                                                </div>
                                            </FormGroup>
                                            :
                                            null
                                        }

                                        {this.state.audioInput && this.state.audioInput.length ?
                                            <FormGroup>
                                                <Label>{'Audio source'.translate(this.props.lang)}</Label>
                                                <div>
                                                    <Input type="select" value={this.state.audioInputDevice} onChange={(e) => this.setState({ audioInputDevice: e.target.value }, () => this.getStream(true))}>
                                                        {

                                                            this.state.audioInput ?
                                                                this.state.audioInput.map((item) => {
                                                                    return <option value={item.deviceId}>{item.label}</option>
                                                                })
                                                                :

                                                                null
                                                        }
                                                    </Input>
                                                    <Isvg src={micIcon} />

                                                </div>
                                            </FormGroup>
                                            :
                                            null
                                        }
                                        {this.state.audioOutput && this.state.audioOutput.length ?

                                            <FormGroup>
                                                <Label>{'Audio output'.translate(this.props.lang)}</Label>
                                                <div>
                                                    <Input type="select" onChange={(e) => {
                                                        this.setState({
                                                            audioOutputDevice: e.target.value
                                                        }, () => {
                                                            for (var uuid in this.peerStreams) {
                                                                let remoteVideo = this.remoteVideo[uuid]
                                                                if (remoteVideo) {
                                                                    if (this.state.audioOutputDevice)
                                                                        this.attachSinkId(remoteVideo, this.state.audioOutputDevice);
                                                                }
                                                            }
                                                            if (this.state.audioOutputDevice)
                                                                this.attachSinkIdTestSound(this.state.audioOutputDevice);
                                                        })
                                                    }} value={this.state.audioOutputDevice}>
                                                        {
                                                            this.state.audioOutput ?
                                                                this.state.audioOutput.map((item, idx) => {
                                                                    return <option value={item.deviceId}>{item.label}</option>
                                                                })
                                                                :
                                                                null
                                                        }
                                                    </Input>
                                                    <Isvg src={speakerIcon} />

                                                </div>
                                            </FormGroup>
                                            :
                                            null
                                        }
                                        {
                                            !this.state.shareOn && this.state.videoElements.length > 1 ?
                                                <FormGroup>
                                                    <Label>{'Layout'.translate(this.props.lang)}</Label>
                                                    <div>
                                                        <Input type="select" onChange={(e) => {
                                                            this.setState({
                                                                layoutOutput: e.target.value,
                                                                memberTooltip: null
                                                            }, () => {
                                                                this.generateLayout(true);
                                                            })



                                                        }} value={this.state.layoutOutput}>
                                                            <option value={'-1'}>{'Presentation'.translate(this.props.lang)}</option>
                                                            <option value={2}>{'Box layout'.translate(this.props.lang)}</option>
                                                        </Input>
                                                        <Isvg src={speakerIcon} />

                                                    </div>
                                                </FormGroup>
                                                :
                                                null
                                        }

                                        {typeof window !== 'undefined' && !detectMob() ?
                                            <FormGroup>
                                                <Checkbox label={typeof window !== 'undefined' && iosDevice() ? 'Remove background'.translate(this.props.lang) : 'Remove bg'.translate(this.props.lang)}
                                                    onChange={(e) => {
                                                        if (this._fetchingStream) {
                                                            return;
                                                        }
                                                        this._fetchingStream = true;

                                                        this.setState({ removeBg: e.target.checked }, async () => {


                                                            await this.getStream(true)
                                                            this.getBackgroundImages();
                                                            this._fetchingStream = false;

                                                        })
                                                    }} checked={this.state.removeBg}></Checkbox>
                                            </FormGroup>
                                            :
                                            null
                                        }
                                        {
                                            this.state.removeBg && (typeof window !== 'undefined' && !detectMob()) ?
                                                this.state.libraryIcons && this.state.libraryIcons.length ?
                                                    <div className="icon-library-wrap-webrtc">
                                                        <div className="icon-items">
                                                            {
                                                                this.state.libraryIcons && this.state.libraryIcons.map((icon, idx) => {
                                                                    return (
                                                                        <div onClick={() => {
                                                                            this.roomImageRefLoaded = false;
                                                                            this.setState({
                                                                                backgroundVideoImage: `${API_ENDPOINT + icon}`,
                                                                            })
                                                                        }} style={this.state.backgroundVideoImage == `${API_ENDPOINT + icon}` ? { border: '2px solid #000' } : {}} className="icon-item">
                                                                            <img src={API_ENDPOINT + icon} />

                                                                        </div>
                                                                    )
                                                                })
                                                            }

                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="empty-array">
                                                    </div>
                                                :
                                                null
                                        }
                                    </Col>
                                    <Col lg="4" className="preview-content">
                                        <video ref={(node) => this.previewVideo = node} autoPlay playsInline />
                                        <div className="audio-meter-wrap">
                                            <Isvg src={micIcon} />

                                            <div className="audio-meter"><div style={{ width: (this.state.micMeterValue * 100) + '%' }}></div></div>

                                        </div>
                                        <div className="control-buttons test-sound-button">
                                            {
                                                <button onClick={() => {
                                                    this.audioRef.play()
                                                }} className={"camera-button off"}><Isvg src={soundTest} />   {'Test sound'.translate(this.props.lang)}</button>

                                            }
                                        </div>
                                        <div className="control-buttons">
                                            <button onClick={this.toggleCamera} className={this.state.cameraOff ? "camera-button off" : "camera-button"}>{this.state.cameraOff ? <Isvg src={cameraOffIcon} /> : <Isvg src={cameraIcon} />}  <div className="chat-icons-tooltip-off">{'Turn off camera'.translate(this.props.lang)}</div> <div className="chat-icons-tooltip-on">{'Turn on camera'.translate(this.props.lang)}</div> </button>
                                            <button onClick={this.toggleMic} className={this.state.micOff ? "mic-button off" : "mic-button on"}>{this.state.micOff ? <Isvg src={micMutedIcon} /> : <Isvg src={micIcon} />} <div className="chat-icons-tooltip-off">{'Turn off microphone'.translate(this.props.lang)}</div> <div className="chat-icons-tooltip-on">{'Turn on microphone'.translate(this.props.lang)}</div></button>


                                        </div>

                                    </Col>
                                    <Col lg="12">
                                        <Button color="primary" onClick={() => {
                                            this.setState({
                                                settings: null
                                            })
                                        }
                                        }>{'Close'.translate(this.props.lang)}</Button>
                                    </Col>

                                </Row>

                            </div>

                        </div>
                        :
                        null

                    }
                    <DeleteModal
                        lang={this.props.lang}
                        isOpen={this.state.deleteMessageModal}
                        toggle={() => this.setState({ deleteMessageModal: null })}
                        handler={() => { this.deleteMessage(this.state.deleteMessageModal); this.setState({ deleteMessageModal: null }) }}
                    >
                        {'Are you sure you want to delete the selected message?'.translate(this.props.lang)}
                    </DeleteModal>
                    <DeleteModal
                        zIndex={9999999}
                        lang={this.props.lang}
                        isOpen={this.state.removeClientModal}
                        toggle={() => this.setState({ removeClientModal: null })}
                        handler={() => {
                            this.props.socket.emit('removeClient', { socketId: this.state.removeClientModal.socketId, ...this.state.removeClientModal.answerData })
                            let videoCallClients = this.state.videoCallClients;
                            videoCallClients.splice(this.state.removeClientModalIdx, 1);
                            this.setState({
                                videoCallClients
                            })

                            this.setState({ removeClientModal: null, removeClientModalIdx: null })
                        }}
                    >
                        {'Remove client: '.translate(this.props.lang)} <strong>{this.state.removeClientModal && this.state.removeClientModal.user && this.state.removeClientModal.user.name}</strong>?
                    </DeleteModal>

                    {this.state.videoCallLimit ?
                        <Modal zIndex={9999999} wrapClassName="video-call-limit-wrapper" isOpen={this.state.videoCallLimit} toggle={() => this.setState({ videoCallLimit: !this.state.videoCallLimit })}>
                            <ModalHeader toggle={() => this.setState({ videoCallLimit: !this.state.videoCallLimit })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ videoCallLimit: !this.state.videoCallLimit })}>&times;</button>}></ModalHeader>

                            <ModalBody className="video-call-limit">
                                <div className="loader-wrap">
                                    <Player
                                        autoplay={true}
                                        loop={true}
                                        renderer={'svg'}
                                        src="https://assets1.lottiefiles.com/packages/lf20_slGFhN.json"
                                        style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px" }}
                                    ></Player>

                                </div>

                                <p>{'You have reached the maximum number of users that can be included in a video call!'.translate(this.props.lang)}</p>
                            </ModalBody>
                        </Modal>

                        :
                        null
                    }
                    {this.state.takePhotoModal ?
                        <Modal zIndex={9999999} wrapClassName="video-call-limit-wrapper" isOpen={this.state.takePhotoModal} toggle={() => this.setState({ takePhotoModal: !this.state.takePhotoModal })}>
                            <ModalHeader toggle={() => this.setState({ takePhotoModal: !this.state.takePhotoModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ takePhotoModal: !this.state.takePhotoModal })}>&times;</button>}>{'Save photo'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody className="video-call-limit take-photo-modal">
                                <img src={this.state.takePhotoModal.previewImage} />
                                <Input type="textarea" placeholder={'Enter photo comment...'.translate(this.props.lang)} value={this.state.takePhotoModal.message} onChange={(e) => {
                                    let takePhotoModal = this.state.takePhotoModal;
                                    takePhotoModal.message = e.target.value;
                                    this.setState({
                                        takePhotoModal
                                    })
                                }} />

                                <p>{'Saved photo will be visible in conversation messages.'.translate(this.props.lang)}</p>
                            </ModalBody>

                            <ModalFooter>
                                <Button color="primary" onClick={() => this.setState({ takePhotoModal: null })}>{'Cancel'.translate(this.props.lang)}</Button>
                                <Button color="primary" onClick={this.savePhoto}>{'Save'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>

                        :
                        null
                    }
                </div>
            )

        }

        return (
            <div className={this.props[0].match.params.windowConversation ? "dashboard chat-new-window" : "dashboard"}>
                <div className={this.state.chatHelpIcons ? 'chat-layer chat-layer-show' : 'chat-layer'} onClick={() => { this.setState({ chatHelpIcons: false }) }}></div>
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="chat-area">
                                {(typeof window !== 'undefined' && window.innerWidth < 767 && !this.state.conversation) || (typeof window !== 'undefined' && window.innerWidth >= 767) ?
                                    <div className="conversations">
                                        <div className="conversations-container">
                                            <div>
                                                <div className="head">
                                                    <h6>
                                                        {'Conversations'.translate(this.props.lang)} {this.props.uData && this.props.uData.userLevel == 20 && localStorage.getItem('profile') == 'doctor' ?
                                                            <></>
                                                            : null}
                                                    </h6>

                                                </div>
                                                <p>{this.props.uData && (this.props.uData.userLevel == 1 || this.props.uData.userLevel == 2 || (localStorage.profile && localStorage.getItem('profile') == 'patient')) && this.state.conversations.filter(item => !item.closed && item.groupAlias && localStorage.groupAlias && item.groupAlias.indexOf(localStorage.getItem('groupAlias')) != -1).length == 0 ? 'Only healthcare professionals can initiate chat. You will be notified when a chat opens.'.translate(this.props.lang) : null}</p>

                                                <Input type="text" value={this.state.search} onChange={(e) => this.setState({ search: striptags(e.target.value) }, this.search)} placeholder={'Search...'.translate(this.props.lang)} />
                                                <ul>
                                                    {
                                                        this.state.conversations && this.state.conversations.length ? this.state.conversations.sort((a, b) => {
                                                            let aCall = a.currentCall
                                                            let bCall = b.currentCall
                                                            if (aCall && !bCall) {
                                                                return -1
                                                            }
                                                            if (!aCall && bCall) {
                                                                return 1
                                                            }
                                                            return 0
                                                        }).map((item, idx) => {
                                                            if ((!item.closed && this.state.conversationsType == 0) || (item.closed && this.state.conversationsType == 1))
                                                                return <li
                                                                    style={item.currentCall && (this.props.uData && this.props.uData._id != item.members[0]) ? { background: 'green' } : {}}
                                                                    className={this.state.conversation && this.state.conversation._id == item._id ? 'active' : item._newMessage ? '' : ''} onClick={(e) => { this.checkUploadedFiles(item); }}>
                                                                    {item.name}<br />
                                                                    <span>ID: {item.conversationID ? item.conversationID : null} </span>

                                                                    <br />
                                                                    <span>{item.singleCallTimeStamp ? 'Expires:'.translate(this.props.lang) : ''}{item.singleCallTimeStamp ? moment.unix(item.singleCallTimeStamp).format('YYYY-MM-DD HH:mm') : ''}</span>
                                                                    <h5 className="chat-members">
                                                                        {item.users && item.users.map((user, idx) => {
                                                                            return (
                                                                                user.name ?
                                                                                    <img key={idx} src={user.profilePicture ? (API_ENDPOINT + user.profilePicture) : profilePicture} />
                                                                                    : null
                                                                            )
                                                                        })}
                                                                    </h5>
                                                                    {
                                                                        item._newMessage ?

                                                                            <Isvg src={newMessage} className="new-message-icon" />

                                                                            :

                                                                            null

                                                                    }

                                                                    {typeof window != 'undefined' && window.innerWidth > 768 && this.props[0].match.path != '/chat/:windowConversation' ?
                                                                        <></>

                                                                        : null
                                                                    }

                                                                </li>
                                                        })
                                                            :
                                                            null
                                                    }
                                                    {this.state.totalNumberOfConversations && this.state.totalNumberOfConversations > ((this.state.conversationPage + 1) * 20) ?
                                                        <div ><span className='waypoint-hide-letter'>a</span><Waypoint
                                                            onEnter={() => {
                                                                this.setState(prev => ({ conversationPage: prev.conversationPage + 1, addPrevConversations: true }), () => {
                                                                    this.emitConversations()
                                                                });
                                                            }}
                                                        />
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </ul>
                                            </div>
                                            <div className="conversations-buttons">

                                                <div>
                                                    <Button onClick={() => this.setState({ conversationsType: 0, conversation: null, messages: [] })} className={this.state.conversationsType == 0 ? 'active' : null}>{'Active'.translate(this.props.lang)}</Button>
                                                    <Button onClick={() => this.setState({ conversationsType: 1, conversation: null, messages: [] })} className={this.state.conversationsType == 1 ? 'active' : null}>{'History'.translate(this.props.lang)}</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                                {
                                    this.state.loseUploadedFiles ?
                                        <Modal isOpen={this.state.loseUploadedFiles} centered size={this.props.size ? this.props.size : null}>
                                            <ModalHeader style={{ margin: 'auto' }}>{'Error'.translate(this.props.lang)}</ModalHeader>
                                            <ModalBody className="delete-modal">
                                                <img src={warrning} />
                                                <div className="message">
                                                    {"If you change the conversation before sending the uploaded files, they will be lost. Do you want to continue?".translate(this.props.lang)}
                                                </div>
                                            </ModalBody>
                                            <ModalFooter style={{ display: 'flex', justifyContent: 'center' }}>
                                                <Button type="submit" color="primary" onClick={() => this.setState({ video: null, image: null, document: null }, () => this.openConversation(this.state.loseUploadedFiles))} >{'Yes'.translate(this.props.lang)}</Button>{' '}
                                                <Button type="submit" color="primary" onClick={() => this.setState({ loseUploadedFiles: null })} >{'No'.translate(this.props.lang)}</Button>{' '}
                                            </ModalFooter>
                                        </Modal>
                                        :
                                        null
                                }
                                {
                                    this.state.joinConversationModal ?
                                        <Modal isOpen={this.state.joinConversationModal} size="lg" centered>
                                            <ModalHeader close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ joinConversationModal: !this.state.joinConversationModal })}>&times;</button>}>{'Enter meeting code'.translate(this.props.lang)}</ModalHeader>
                                            <ModalBody>

                                                <Input value={this.state.joinConversationIDNumber} placeholder={'Code'.translate(this.props.lang)} onChange={(e) => {

                                                    this.setState({
                                                        joinConversationIDNumber: e.target.value,
                                                    })
                                                }} />

                                            </ModalBody>
                                            <ModalFooter className='buttons-right-green'>

                                                <Button className='join-video-button' onClick={() => {
                                                    this.findConversation(this.state.joinConversationIDNumber)
                                                }} >{'Join'.translate(this.props.lang)}</Button>

                                                <Button onClick={() => this.setState({ joinConversationModal: !this.state.joinConversationModal })}>{'Close'.translate(this.props.lang)}</Button>
                                            </ModalFooter>
                                        </Modal>
                                        :
                                        null
                                }
                                {
                                    this.state.conversation ?

                                        <div
                                            onDrop={this.handleDrop}
                                            onDragEnter={(e) => {
                                                e?.preventDefault();
                                                e?.stopPropagation();
                                                this.setState({ isDragging: true })
                                            }}
                                            onDragLeave={(e) => {
                                                e?.preventDefault();
                                                e?.stopPropagation();
                                                this.setState({ isDragging: false }, () => {
                                                    let oldScrollHeight = 0;
                                                    if (this.messagesRef) {
                                                        oldScrollHeight = this.messagesRef.scrollHeight;
                                                    }

                                                    if (this.messagesRef && this.state.page == 0) {
                                                        this.messagesRef.scrollTop = this.messagesRef.scrollHeight;
                                                    } else if (oldScrollHeight) {
                                                        this.messagesRef.scrollTop = this.messagesRef.scrollTop + (this.messagesRef.scrollHeight - oldScrollHeight);
                                                    }
                                                })
                                            }}
                                            onDragOver={e => {
                                                e?.preventDefault();
                                                e?.stopPropagation();
                                            }}
                                            className={`chat-wrap ${this.state.call ? 'video-call' : ''} ${this.state.showChat ? 'show-chat' : ''} ${this.state.offerData ? 'incoming-offer' : ''} ${/*this.state.searchMembers || */this.state.showGallery ? 'search-members-active' : ''}`} ref={(node) => this.chatWrapRef = node}
                                        >
                                            {this.state.isDragging ? <div className='drag-drop-container'>
                                                {'Drop to send'.translate?.(this.props.lang)}
                                            </div>

                                                : <>
                                                    <div className="head">
                                                        <h6>

                                                            {this.state.conversation && this.state.conversation.referral ?
                                                                <div className="referral">
                                                                    <button className="chat-header-icon" onClick={() => this.setState({ referral: this.state.conversation.referral }, () => this.addToReferralPreview())}>{'Patient responses'.translate(this.props.lang)}</button>
                                                                </div>
                                                                :
                                                                null
                                                            }


                                                            <p>{this.state.conversation && this.state.conversation.name}</p> &nbsp;

                                                        </h6>
                                                        <div className="members-container">
                                                            {
                                                                this.state.conversation && this.state.conversation.members && this.state.conversation.members.length >= 2 ?
                                                                    <div className="overlay"></div>
                                                                    :
                                                                    null
                                                            }
                                                            {this.state.conversation && this.state.conversation.users.map((item, idx) => {
                                                                return (
                                                                    this.props.uData && this.props.uData.userLevel >= 20 && localStorage.getItem('profile') == 'doctor' ?
                                                                        <div className={item.level < 20 ? "chat-icons-tooltip" : ''}>{item.level < 20 ? 'Click on patient to see his profile'.translate(this.props.lang) : ''}</div>
                                                                        :
                                                                        null

                                                                )
                                                            })}
                                                            <div ref={(node) => this.membersScrollArea = node}
                                                                onMouseDown={(e) => {
                                                                    if (this.membersScrollArea) {
                                                                        this.membersScrollArea.style.cursor = 'grabbing';

                                                                        this.setState({
                                                                            _membersDragging: {
                                                                                x: e.clientX,
                                                                                y: e.clientY,
                                                                                left: this.membersScrollArea.scrollLeft,
                                                                            }
                                                                        })
                                                                    }
                                                                }}
                                                                onMouseMove={(e) => {
                                                                    if (this.state._membersDragging && this.membersScrollArea) {
                                                                        // How far the mouse has been moved
                                                                        const dx = e.clientX - this.state._membersDragging.x;

                                                                        this.membersScrollArea.scrollLeft = this.state._membersDragging.left - dx;
                                                                    }
                                                                }}
                                                                onMouseUp={(e) => {
                                                                    this.membersScrollArea.style.cursor = 'grab';

                                                                    this.setState({
                                                                        _membersDragging: null
                                                                    })
                                                                }}
                                                                className={
                                                                    this.state.conversation && this.state.conversation.members.length >= 2 && window.innerWidth > 768 && typeof window !== 'undefined' ? 'members members-scroll' : ((this.state.conversation && this.state.conversation.members.length >= 4 && window.innerWidth < 768 && typeof window !== 'undefined' ? 'members members-scroll-mobile' : 'members') || (this.state.conversation && this.state.conversation.members.length > 2 && typeof window != 'undefined' && window.innerWidth > 768 ? 'members members-scroll' : 'members'))}>
                                                                {
                                                                    this.state.conversation && this.state.conversation.users.map((item, idx) => {
                                                                        if (item.uid && item.name /*item.uid.indexOf('guest') == -1*/)
                                                                            return (
                                                                                <div className={this.state.memeberHoverState == item.uid ? "active-member" : ""}
                                                                                    onMouseEnter={(e) => {
                                                                                        e.stopPropagation();
                                                                                        this.setState({
                                                                                            memeberHoverState: item.uid
                                                                                        })
                                                                                    }}

                                                                                    onMouseLeave={(e) => {
                                                                                        e.stopPropagation();

                                                                                        this.setState({
                                                                                            memeberHoverState: null
                                                                                        })
                                                                                    }}

                                                                                    onClick={() => {
                                                                                        if (this.state.tooltip == 0 || this.state.tooltip != item.uid) {
                                                                                            this.setState({
                                                                                                tooltip: item.uid
                                                                                            })
                                                                                        } else if (this.state.tooltip == item.uid) {
                                                                                            this.setState({
                                                                                                tooltip: 0
                                                                                            })
                                                                                        }
                                                                                        if (this.state.memeberHoverState == item.uid) {

                                                                                            if ((this.props.uData.userLevel == 20 && typeof window != 'undefined' && localStorage.profile != 'patient') && (item.level && item.level < 20 || (item.socialSecurityNumber && this.state.conversation && this.state.conversation.referral && this.state.conversation.referral.patient && this.state.conversation.referral.patient.socialSecurityNumber && this.state.conversation.referral.patient.name && this.state.conversation.referral.patient.socialSecurityNumber == item.socialSecurityNumber) || (item.socialSecurityNumber && this.state.conversation && this.state.conversation.control && this.state.conversation.socialSecurityNumber && this.state.conversation.socialSecurityNumber == item.socialSecurityNumber))) {
                                                                                                fetch(API_ENDPOINT + '/users/users/' + item.uid.toString(), {
                                                                                                    method: 'GET',
                                                                                                    headers: {
                                                                                                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken') ? localStorage.getItem("authToken") : localStorage.getItem('guestToken')}` : null,
                                                                                                        'content-type': 'application/json'
                                                                                                    },
                                                                                                }).then(res => res.json()).then((result) => {
                                                                                                    this.setState({
                                                                                                        profileModal: result
                                                                                                    })
                                                                                                })
                                                                                            }
                                                                                            this.setState({
                                                                                                memeberHoverState: null
                                                                                            })

                                                                                        } else {
                                                                                            this.setState({
                                                                                                memeberHoverState: item.uid
                                                                                            })
                                                                                        }
                                                                                    }}>
                                                                                    <img onClick={() => {

                                                                                    }} src={item.profilePicture ? API_ENDPOINT + item.profilePicture : profilePicture} />
                                                                                    <div className={item.online ? 'status online' : 'status offline'}></div>
                                                                                    <p onClick={() => {
                                                                                    }} className={typeof window != 'undefined' && window.innerWidth > 768 ? this.state.tooltip != 0 && this.state.tooltip == item.uid ? 'name-visible' : 'name-none' : ''}>
                                                                                        {
                                                                                            item.socialSecurityNumber && this.state.conversation && this.state.conversation.referral && this.state.conversation.referral.patient && this.state.conversation.referral.patient.socialSecurityNumber && this.state.conversation.referral.patient.name && this.state.conversation.referral.patient.socialSecurityNumber == item.socialSecurityNumber ?
                                                                                                this.state.conversation.referral.patient.name
                                                                                                :
                                                                                                (item.socialSecurityNumber && this.state.conversation && this.state.conversation.control && this.state.conversation.socialSecurityNumber && this.state.conversation.socialSecurityNumber == item.socialSecurityNumber) ?
                                                                                                    this.state.conversation.name
                                                                                                    :
                                                                                                    item.name
                                                                                        }

                                                                                    </p>
                                                                                </div>
                                                                            )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>

                                                        <Isvg src={moreIcon} onClick={this.toggleHelpIcons} className="chat-more-icon" />

                                                        <div className={this.state.chatHelpIcons ? 'chat-helper chat-helper-show' : 'chat-helper'}>
                                                            <input ref={(node) => this.roomIdInput = node} style={{ width: 10, height: 10, background: 'transparent', border: 0, opacity: 0 }} value={this.state.conversation && typeof window != 'undefined' ? this.state.conversation.mandatoryScreenName ? `${window.location.origin}/video-call/${this.state.conversation._id}?roomId=${this.state.conversation.roomId}&displayName=true` : `${window.location.origin}/video-call/${this.state.conversation._id}?roomId=${this.state.conversation.roomId}` : null} />
                                                            {typeof window !== 'undefined' && window.innerWidth < 768 ? <button onClick={() => this.setState({ showGallery: true, chatHelpIcons: false })} className="search-members-button">
                                                                <Isvg src={galleryIcon} />
                                                                <div className="chat-icons-tooltip">{'Media'.translate(this.props.lang)}</div>
                                                            </button> : null}

                                                            {this.props.uData && this.props.uData.userLevel >= 20 && localStorage.getItem('profile') == 'doctor' && this.state.conversation && this.state.conversation.members && (this.state.conversation.members[0] === this.props.uData._id || (this.state.conversation.cohosts && this.state.conversation.cohosts.indexOf(this.props.uData._id) !== -1)) && this.props.uData.host ?
                                                                <button onClick={() => this.setState({ shareModal: this.state.conversation.mandatoryScreenName ? `${window.location.origin}/video-call/${this.state.conversation._id}?roomId=${this.state.conversation.roomId}&displayName=true` : `${window.location.origin}/video-call/${this.state.conversation._id}?roomId=${this.state.conversation.roomId}` })} className="search-members-button"><Isvg src={addIcon} style={{ position: 'absolute' }} /> <div className="chat-icons-tooltip">{'Add participant'.translate(this.props.lang)}</div></button>
                                                                :
                                                                null
                                                            }
                                                            {!this.props.videoCallUrl && this.props.uData && this.props.uData.userLevel >= 20 && this.state.conversation && this.state.conversation.members && (this.state.conversation.members[0] === this.props.uData._id || (this.state.conversation.cohosts && this.state.conversation.cohosts.indexOf(this.props.uData._id) !== -1)) && this.props.uData.licenceObj && this.props.uData.licenceObj.host ?
                                                                <button onClick={/*this.call*/() => {
                                                                    this.setState({ chatHelpIcons: false });
                                                                    this.props.startVideoCall(this.state.conversation._id);
                                                                }} className="call-button"><Isvg src={call} /> <div className="chat-icons-tooltip">{'Start video chat'.translate(this.props.lang)}</div> </button>
                                                                :
                                                                null
                                                            }
                                                            {
                                                                this.props.uData && this.props.uData.userLevel >= 20 && localStorage.getItem('profile') == 'doctor' && this.state.conversation && this.state.conversation.members && (this.state.conversation.members[0] === this.props.uData._id || (this.state.conversation.cohosts && this.state.conversation.cohosts.indexOf(this.props.uData._id) !== -1)) && !this.state.conversation.closed ?
                                                                    <>
                                                                        {
                                                                            this.state.conversation.singleCallConversation ?
                                                                                null
                                                                                :
                                                                                <button onClick={() => this.setState({ closeModal: this.state.conversation })} className="search-members-button close-meeting-icon">
                                                                                    <Isvg src={closeIcon} />
                                                                                    <div className="chat-icons-tooltip">{'Close meeting'.translate(this.props.lang)}</div>
                                                                                </button>
                                                                        }
                                                                    </>
                                                                    :
                                                                    this.props.uData && this.props.uData.userLevel >= 20 && localStorage.getItem('profile') == 'doctor' && this.state.conversation && this.state.conversation.members && (this.state.conversation.members[0] === this.props.uData._id || (this.state.conversation.cohosts && this.state.conversation.cohosts.indexOf(this.props.uData._id) !== -1)) && !this.state.conversation.closed ?
                                                                        <button onClick={() => this.setState({ leaveChatModal: this.state.conversation })} className="search-members-button">
                                                                            <Isvg src={leaveIcon} />
                                                                            <div className="chat-icons-tooltip">{'Leave chat'.translate(this.props.lang)}</div>
                                                                        </button>

                                                                        :
                                                                        null
                                                            }
                                                        </div>
                                                        <div className={this.state.chatMenu ? 'chat-menu-container open' : 'chat-menu-container'}>
                                                            <Isvg src={barsIcon} className='chat-menu-icon' onClick={() => this.setState({ chatMenu: !this.state.chatMenu })} />
                                                            <ul className='menu'>
                                                                <button onClick={() => this.setState({ showGallery: true, chatHelpIcons: false })}>{'Media'.translate(this.props.lang)}</button>

                                                                {
                                                                    this.props.uData && this.props.uData.userLevel >= 20 && localStorage.getItem('profile') == 'doctor' && this.state.conversation && this.state.conversation.members && (this.state.conversation.members[0] === this.props.uData._id || (this.state.conversation.cohosts && this.state.conversation.cohosts.indexOf(this.props.uData._id) !== -1)) && this.state.conversation.closed ?
                                                                        <Button onClick={() => this.setState({ openModal: this.state.conversation })} color="primary" size="sm">{'Open conversation'.translate(this.props.lang)} </Button>
                                                                        :
                                                                        null
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>

                                                    {this.state.showGallery ?
                                                        <div className="conversation-media">
                                                            <button className="close" onClick={() => this.setState({ showGallery: null })}>&times;</button>
                                                            <h6>{'Conversation media'.translate(this.props.lang)}</h6>
                                                            <div className="items">
                                                                {
                                                                    this.state.messages.filter(item => item.image || (item.healthCareReferral && !item.hideIcon) || item.video).map((message) => {
                                                                        return (
                                                                            <div className="item">
                                                                                {
                                                                                    message.image ?
                                                                                        <a href={API_ENDPOINT + message.image} target="_blank">
                                                                                            <img src={API_ENDPOINT + message.image} />
                                                                                        </a>
                                                                                        :
                                                                                        message.video ?
                                                                                            <div className="conversation-media-video">
                                                                                                <video width="320" height="240" controls>
                                                                                                    <source src={API_ENDPOINT + message.video} type="video/mp4" />
                                                                                                    <source src={API_ENDPOINT + message.video} type="video/ogg" />
                                                                                                </video>
                                                                                            </div>

                                                                                            :

                                                                                            null
                                                                                }

                                                                            </div>
                                                                        )
                                                                    })
                                                                }

                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                    }

                                                    <div className={this.state.journalNote ? 'messages-wrapper journal-note-active-modal' : 'messages-wrapper'}>
                                                        {
                                                            this.state.showChat ?
                                                                <button className="hide-chat" onClick={() => this.setState({ showChat: null })}>&times;</button>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            this.state.conversation && this.state.conversation.currentCall && !((this.props.uData && this.state.conversation.members[0] === this.props.uData._id) || (this.state.conversation.cohosts && this.state.conversation.cohosts.indexOf(this.props.uData._id) !== -1)) ?
                                                                <button onClick={() => this.setState({ chatHelpIcons: false }, () => {
                                                                    var strWindowFeatures = `height=${window.innerHeight - 1},width=${window.innerWidth - 1},resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes`;
                                                                    this.videoCallWindow = window.open(`/video-call/${this.state.conversation._id}?initiator=${this.state.conversation.currentCall.initiator}`, "_blank", strWindowFeatures);
                                                                    this.videoCallWindow.addEventListener('message',
                                                                        (e) => {
                                                                            if (e.data == 'end-video-call') {
                                                                                this.videoCallWindow.close();
                                                                            }

                                                                        }

                                                                        , false)
                                                                })} className="join-call-button"><Isvg src={call} /> <span>{'Rejoin call'.translate(this.props.lang)}</span></button>

                                                                :
                                                                null

                                                        }

                                                        <div className="messages" ref={(node) => this.messagesRef = node}>
                                                            {
                                                                this.state.messages.map((item, idx) => {


                                                                    if (item.type == 'special') {
                                                                        return (
                                                                            <>
                                                                                {this.state.messages.length > 15 && idx == 1 ?
                                                                                    <div ><Waypoint
                                                                                        onEnter={() => {
                                                                                            this.setState({
                                                                                                page: this.state.page + 1
                                                                                            }, () => {
                                                                                                this.props.socket.emit('messages', { conversation: this.state.conversation._id, page: this.state.page })
                                                                                            })
                                                                                        }}
                                                                                    />
                                                                                    </div>
                                                                                    :
                                                                                    null
                                                                                }
                                                                                {item.videoStarted ? <h6 className='video-chat-notify'>
                                                                                    <Isvg src={videoChatIcon} />
                                                                                    <span className='time-started'>{moment.unix(item.tsCreated).format(this.props.dateFormat + ' HH:mm')}</span>
                                                                                    <span className='user-name'>{item.user.name}</span>
                                                                                    <span className='notify'> {'Started video meeting'.translate(this.props.lang)}</span>
                                                                                </h6> : null}

                                                                                {item.videoJoined ? <h6 className='video-chat-notify'>
                                                                                    <Isvg src={userChatIcon} />
                                                                                    <span className='time-started'>{moment.unix(item.tsCreated).format(this.props.dateFormat + ' HH:mm')}</span>
                                                                                    <span className='user-name'>{item.user.name}</span>
                                                                                    <span className='notify'> {'Joined video meeting'.translate(this.props.lang)}</span>
                                                                                </h6> : null}

                                                                                {item.videoLeft ? <h6 className='video-chat-notify'>
                                                                                    <Isvg src={userChatIcon} />
                                                                                    <span className='time-started'>{moment.unix(item.tsCreated).format(this.props.dateFormat + ' HH:mm')}</span>
                                                                                    <span className='user-name'>{item.user.name}</span>
                                                                                    <span className='notify'> {'Left video meeting'.translate(this.props.lang)}</span>
                                                                                </h6> : null}

                                                                                {item.videoEnded ? <h6 className='video-chat-notify'>
                                                                                    <Isvg src={userChatIcon} />
                                                                                    <span className='time-started'>{moment.unix(item.tsCreated).format(this.props.dateFormat + ' HH:mm')}</span>
                                                                                    <span className='user-name'>{item.user.name}</span>
                                                                                    <span className='notify'> {'Ended video meeting'.translate(this.props.lang)}</span>
                                                                                </h6> : null}
                                                                            </>
                                                                        )

                                                                    } else {
                                                                        return (
                                                                            <div className={item.uid == this.props.uData._id ? 'right' : ''} style={{ wordBreak: 'break-all' }}>

                                                                                {this.state.messages.length > 15 && idx == 1 ?
                                                                                    <div ><Waypoint
                                                                                        onEnter={() => {
                                                                                            this.setState({
                                                                                                page: this.state.page + 1
                                                                                            }, () => {
                                                                                                this.props.socket.emit('messages', { conversation: this.state.conversation._id, page: this.state.page })
                                                                                            })
                                                                                        }}
                                                                                    />
                                                                                    </div>
                                                                                    :
                                                                                    null
                                                                                }
                                                                                {
                                                                                    item.uid != this.props.uData._id ?
                                                                                        <img src={item.user && item.user.profilePicture ? API_ENDPOINT + item.user.profilePicture : profilePicture} />
                                                                                        :
                                                                                        null
                                                                                }

                                                                                <div>
                                                                                    <div onClick={() => {
                                                                                        let showSeen = this.state.showSeen;
                                                                                        showSeen[item._id] = !showSeen[item._id];
                                                                                        this.setState({
                                                                                            showSeen
                                                                                        })
                                                                                    }}>
                                                                                        {
                                                                                            item.uid == this.props.uData._id && !item.deletedMessage ?
                                                                                                <div className="delete-message-icon" onClick={() => this.setState({ deleteMessageModal: item })}>&times;</div>
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                        <div className="username">
                                                                                            {item.user && item.user.name}
                                                                                        </div>
                                                                                        {
                                                                                            item.deletedMessage ?
                                                                                                <p style={{ marginBottom: 0, fontStyle: 'italic', fontWeight: 400, fontSize: 14 }}>{item.message + ' ' + 'deleted a message'.translate(this.props.lang)}</p>
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                        {item.image ? <a target='_blank' href={API_ENDPOINT + item.image}><img className="message-image" src={API_ENDPOINT + item.image} /></a> : null}
                                                                                        {
                                                                                            item.video ?
                                                                                                <div className="video-in-chat-wrap">
                                                                                                    <video width="320" height="240" controls>
                                                                                                        <source src={API_ENDPOINT + item.video} type="video/mp4" />
                                                                                                        <source src={API_ENDPOINT + item.video} type="video/ogg" />
                                                                                                    </video>
                                                                                                </div>
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                        {
                                                                                            !item.healthCareId && !item.deletedMessage ?
                                                                                                item.message == 'The patient filled out the form successfully' ?
                                                                                                    item.message.translate(this.props.lang)
                                                                                                    :
                                                                                                    <>
                                                                                                        {
                                                                                                            item.message.split('\n').length ?
                                                                                                                item.message.split('\n').map((text, t) => {
                                                                                                                    if (t != item.message.split('\n').length - 1)
                                                                                                                        return (
                                                                                                                            <>{text}<br /></>
                                                                                                                        )
                                                                                                                    else
                                                                                                                        return (
                                                                                                                            text
                                                                                                                        )
                                                                                                                })
                                                                                                                :
                                                                                                                item.message
                                                                                                        }
                                                                                                    </>
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                        {
                                                                                            item.document ?
                                                                                                <div className={item.uid == this.props.uData._id ? 'document-white' : 'document'}><a target="_blank" download href={API_ENDPOINT + item.document.file}><Isvg src={attach} />{item.document.name}</a></div>
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                        {
                                                                                            !item.deletedMessage && item.links && item.links.length ? item.links.map(link => <a href={link.link} target='_blank' className={`message-link-container ${this.props.uData?._id === item.uid ? 'message-link-container-right' : ''} ${item.links.length > 1 ? 'message-link-adjust-width' : ''}`} onClick={(e) => e.stopPropagation()}>
                                                                                                <div className='message-link-image'>
                                                                                                    <img src={link.image} />
                                                                                                </div>
                                                                                                <div className='message-link-title-and-link'>
                                                                                                    <h6>{link.title}</h6>
                                                                                                    <p>{link.link}</p>
                                                                                                </div>
                                                                                            </a>) : null
                                                                                        }

                                                                                    </div>
                                                                                    <div className="date date-out">{moment.unix(item.timestamp).format(`${this.props.dateFormat} HH:mm`)}</div>
                                                                                    {
                                                                                        (item.seen && item.seen.filter(seenMember => seenMember.uid != this.props.uData._id).length) && (idx == this.state.messages.length - 1 || this.state.showSeen[item._id]) ?
                                                                                            <p>
                                                                                                {'Seen by: '.translate(this.props.lang)} {item.seen && item.seen.filter(seenMember => seenMember.uid != this.props.uData._id && seenMember.uid && seenMember.uid.indexOf('guest') == -1).map((seenMember) => { return memebersDict[seenMember.uid] ? memebersDict[seenMember.uid].name : '' }).join(', ')}
                                                                                            </p>
                                                                                            :
                                                                                            null
                                                                                    }

                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                            {
                                                                this.state.userTyping ?
                                                                    <span className="user-typing">{this.state.userTyping.name} {'is typing...'.translate(this.props.lang)}</span>
                                                                    :
                                                                    null
                                                            }

                                                        </div>
                                                        {this.state.conversation && !this.state.conversation.closed ?

                                                            <div
                                                                className={
                                                                    this.props.uData && this.props.uData.userLevel == 20 && localStorage.getItem('profile') != 'patient' && this.state.conversation /*&& this.state.conversation.referral*/ && !this.state.journalNote && (this.state.conversation.users.filter(item => item.level < 20).length || this.state.conversation.referral || this.state.conversation.journalNote) && this.chatWrapRef && this.chatWrapRef.offsetWidth > 760 ?
                                                                        "send-message with-phrase with-journal"
                                                                        :
                                                                        this.props.uData && this.props.uData.userLevel == 20 && localStorage.getItem('profile') != 'patient' && this.state.conversation /*&& this.state.conversation.referral*/ && this.state.journalNote ?
                                                                            "send-message with-phrase with-journal with-journal-open"
                                                                            :
                                                                            this.props.uData && this.props.uData.userLevel == 20 && localStorage.getItem('profile') != 'patient' && this.state.conversation /*&& this.state.conversation.referral*/ && (this.chatWrapRef && this.chatWrapRef.offsetWidth <= 760) && (this.state.conversation.users.filter(item => item.level < 20).length || this.state.conversation.referral) ?
                                                                                "send-message with-phrase with-journal chat-wrap-small"
                                                                                :
                                                                                this.props.uData && this.props.uData.userLevel == 20 && localStorage.getItem('profile') != 'patient' ?
                                                                                    "send-message with-phrase" :
                                                                                    "send-message"
                                                                }
                                                            >
                                                                <div className='send-message-container'>
                                                                    <Input onKeyUp={(e) => {
                                                                        if (e.keyCode == 13 && !e.shiftKey) {
                                                                            e.preventDefault();
                                                                            this.sendMessage();
                                                                            return false;
                                                                        }
                                                                    }} onKeyDown={this.emitUserTyping} placeholder={"Write a message...".translate(this.props.lang)} type="textarea" onChange={(e) => this.setState({ message: e.target.value })} value={this.state.message} />

                                                                    <button disabled={this.state._imageUploading} className="send" onClick={this.sendMessage}>
                                                                        <Isvg src={send} />
                                                                        <div className='chat-icons-tooltip'>
                                                                            {'Send'.translate(this.props.lang)}
                                                                        </div>
                                                                    </button>
                                                                </div>

                                                                {
                                                                    typeof window != 'undefined' && window.innerWidth > 768 && !this.state.journalNote && this.chatWrapRef && this.chatWrapRef.offsetWidth > 760 ?
                                                                        <div className='video-chat-buttons'>
                                                                            <button className={this.state._documentUploading && this.state.conversation._id == this.state._documentUploading._id ? 'attach uploading' : this.state.document ? 'attach uploaded' : "attach"} onClick={() => { if (this.documentInput) this.documentInput.click() }}>
                                                                                <Isvg src={attach} />
                                                                                <div className='chat-icons-tooltip'>
                                                                                    {'Attach file'.translate(this.props.lang)}
                                                                                </div>
                                                                            </button>
                                                                            <button className={this.state._imageUploading && this.state.conversation._id == this.state._imageUploading._id ? 'image uploading' : this.state.image ? 'image uploaded' : "image"} onClick={() => { if (this.imageInput) this.imageInput.click() }}
                                                                            ><Isvg src={image} />
                                                                                <div className='chat-icons-tooltip'>
                                                                                    {'Attach image'.translate(this.props.lang)}
                                                                                </div>
                                                                            </button>
                                                                            <button className={this.state._videoUploading && this.state.conversation._id == this.state._videoUploading._id ? 'video uploading' : this.state.video ? 'video uploaded' : "video"} onClick={() => { if (this.videoContentInput) this.videoContentInput.click() }}><Isvg src={videoIcon} />
                                                                                <div className='chat-icons-tooltip'>
                                                                                    {'Attach video'.translate(this.props.lang)}
                                                                                </div>
                                                                            </button>


                                                                            {
                                                                                this.props.uData && this.props.uData.userLevel == 20 && this.state.conversation && this.state.conversation.host == this.props.uData._id && this.props.uData.host || this.props.uData.singleCallHost ?
                                                                                    <button className={"phrase"} onClick={() => { this.setState({ quickPhraseModal: true }) }}><Isvg src={phraseIcon} />
                                                                                        <div className='chat-icons-tooltip'>
                                                                                            {'Quick phrase'.translate(this.props.lang)}
                                                                                        </div>
                                                                                    </button>
                                                                                    :
                                                                                    null
                                                                            }

                                                                        </div>
                                                                        :
                                                                        <div>

                                                                            <button className='more-icon' onClick={() => { this.setState({ mobileBtnsShow: !this.state.mobileBtnsShow }) }}><Isvg src={moreIcon} style={{ position: 'absolute', width: 13, height: 13 }} /></button>
                                                                            <div className={this.state.mobileBtnsShow ? 'mobile-btns-upload-phrase show-mobile-btns-upload-phrase' : 'mobile-btns-upload-phrase'}>
                                                                                <div className="overlay-test" onClick={() => this.setState({ mobileBtnsShow: !this.state.mobileBtnsShow })} />
                                                                                <button className={this.state._documentUploading ? 'attach-menu uploading' : this.state.document ? 'attach-menu uploaded' : "attach-menu"} onClick={() => {
                                                                                    this.setState({ mobileBtnsShow: !this.state.mobileBtnsShow })
                                                                                    if (this.documentInput) this.documentInput.click()
                                                                                }}>
                                                                                    <div className="button-menu-icon-wrap">
                                                                                        <Isvg src={attach} style={{ position: 'absolute', width: 17, height: 17 }} />
                                                                                    </div>
                                                                                    <div className='chat-icons-text'>
                                                                                        {'Attach file'.translate(this.props.lang)}
                                                                                    </div>
                                                                                </button>

                                                                                <button className={this.state._imageUploading ? 'image-menu uploading' : this.state.image ? 'image-menu uploaded' : "image-menu"} onClick={() => {
                                                                                    this.setState({ mobileBtnsShow: !this.state.mobileBtnsShow })
                                                                                    if (this.imageInput) this.imageInput.click()
                                                                                }}>
                                                                                    <div className="button-menu-icon-wrap">
                                                                                        <Isvg src={image} style={{ position: 'absolute', width: 17, height: 17 }} />
                                                                                    </div>
                                                                                    <div className='chat-icons-text'>
                                                                                        {'Attach image'.translate(this.props.lang)}
                                                                                    </div>
                                                                                </button>
                                                                                <button className={this.state._videoUploading ? 'video-menu uploading' : this.state.video ? 'video-menu uploaded' : "video-menu"} onClick={() => {
                                                                                    this.setState({ mobileBtnsShow: !this.state.mobileBtnsShow })
                                                                                    if (this.videoContentInput) this.videoContentInput.click()
                                                                                }}>
                                                                                    <div className="button-menu-icon-wrap">
                                                                                        <Isvg src={videoIcon} style={{ position: 'absolute', width: 17, height: 17 }} />
                                                                                    </div>
                                                                                    <div className='chat-icons-text'>
                                                                                        {'Attach video'.translate(this.props.lang)}
                                                                                    </div>
                                                                                </button>

                                                                                {
                                                                                    this.props.uData && this.props.uData.userLevel == 20 && this.state.conversation && this.state.conversation.host == this.props.uData._id && this.props.uData.host ?
                                                                                        <button className={"phrase-menu"} onClick={() => { this.setState({ quickPhraseModal: true, mobileBtnsShow: !this.state.mobileBtnsShow }) }}>
                                                                                            <div className="button-menu-icon-wrap"><Isvg src={phraseIcon} style={{ position: 'absolute', width: 17, height: 17 }} /></div>

                                                                                            <div className='chat-icons-text'>
                                                                                                {'Quick phrase'.translate(this.props.lang)}
                                                                                            </div>
                                                                                        </button>
                                                                                        :
                                                                                        null
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                }
                                                            </div>
                                                            :
                                                            null

                                                        }
                                                        {this.state.conversation && this.state.conversation.closed && this.props.uData && this.props.uData.userLevel >= 20 && localStorage.getItem('profile') == 'doctor' && this.state.conversation && this.state.conversation.members && (this.state.conversation.members[0] === this.props.uData._id || (this.state.conversation.cohosts && this.state.conversation.cohosts.indexOf(this.props.uData._id) !== -1)) ?
                                                            <div className='delete-conversation-wrap'>
                                                                <Button color='danger' onClick={() => this.setState({ deleteConversationModal: this.state.conversation })}>{'Delete conversation'.translate(this.props.lang)}</Button>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                </>}
                                        </div>
                                        :

                                        null

                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
                {this.state.succesUpload ?
                    <InfoModal
                        isOpen={this.state.succesUpload}

                        toggle={() => this.setState({ succesUpload: null })}
                        header={'Info'.translate(this.props.lang)}
                        info={this.state.succesUpload == 1 ? 'Document is successfully uploaded'.translate(this.props.lang) : this.state.succesUpload == 2 ? 'Image is successfully uploaded'.translate(this.props.lang) : 'Video is successfully uploaded'.translate(this.props.lang)}
                        buttons={[
                            <Button color='primary' onClick={() => {
                                this.setState({
                                    succesUpload: null
                                })

                            }}>{'Ok'.translate(this.props.lang)}</Button>
                        ]}
                    />
                    :
                    null
                }

                <DeleteModal
                    lang={this.props.lang}
                    isOpen={this.state.deleteMessageModal}
                    toggle={() => this.setState({ deleteMessageModal: null })}
                    handler={() => { this.deleteMessage(this.state.deleteMessageModal); this.setState({ deleteMessageModal: null }) }}
                >
                    {'Are you sure you want to delete the selected message?'.translate(this.props.lang)}
                </DeleteModal>

                <DeleteModal
                    lang={this.props.lang}
                    isOpen={this.state.deleteConversationModal}
                    toggle={() => this.setState({ deleteConversationModal: null })}
                    handler={() => { this.deleteConversation(this.state.deleteConversationModal); this.setState({ deleteConversationModal: null }) }}
                >
                    {'Are you sure you want to delete this conversation?'.translate(this.props.lang)}
                </DeleteModal>

                <DeleteModal
                    lang={this.props.lang}
                    isOpen={this.state.closeCaseModal}
                    toggle={() => this.setState({ closeCaseModal: null })}
                    handler={() => { this.closeCase(this.state.closeCaseModal); this.setState({ closeCaseModal: null }) }}
                >
                    {'Do you want to close the case and conversation'.translate(this.props.lang)} <strong>{this.state.closeCaseModal && this.state.closeCaseModal.name}</strong>?
                </DeleteModal>
                <DeleteModal
                    lang={this.props.lang}
                    isOpen={this.state.leaveChatModal}
                    toggle={() => this.setState({ leaveChatModal: null })}
                    handler={() => { this.leaveChat(this.state.leaveChatModal); this.setState({ leaveChatModal: null }) }}
                >
                    {'Do you really want to leave this conversation'.translate(this.props.lang)} <strong>{this.state.leaveChatModal && this.state.leaveChatModal.name}</strong>?
                </DeleteModal>

                <DeleteModal
                    lang={this.props.lang}
                    isOpen={this.state.closeModal}
                    toggle={() => this.setState({ closeModal: null })}
                    handler={() => { this.close(this.state.closeModal._id); this.setState({ closeModal: null }) }}
                >
                    {'Close conversation'.translate(this.props.lang)} <strong>{this.state.closeModal && this.state.closeModal.name}</strong>?
                </DeleteModal>

                <DeleteModal
                    lang={this.props.lang}
                    isOpen={this.state.openModal}
                    toggle={() => this.setState({ openModal: null })}
                    handler={() => {
                        if (this.state.openModal && this.state.openModal.referral && this.state.openModal.referral.dropIn) {
                            this.openCase(this.state.openModal);
                        } else {
                            this.open(this.state.openModal._id);
                        }

                        this.setState({ openModal: null, conversationsType: 0 })
                    }}
                >
                    {'Open conversation'.translate(this.props.lang)} <strong>{this.state.openModal && this.state.openModal.name}</strong>?
                </DeleteModal>

                {this.state.error && this.state.error.translate ?

                    <ErrorModal lang={this.props.lang}
                        isOpen={this.state.error}
                        toggle={() => this.setState({ error: null })}
                    >
                        {this.state.error.translate(this.props.lang)}
                    </ErrorModal>

                    :
                    null
                }
                {
                    this.state.quickPhraseModal ?
                        <Modal isOpen={this.state.quickPhraseModal} size="lg" centered >
                            <ModalHeader toggle={() => this.setState({ quickPhraseModal: !this.state.quickPhraseModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ quickPhraseModal: !this.state.quickPhraseModal })}>&times;</button>}>{'Quick phrases'.translate(this.props.lang)}</ModalHeader>
                            <ModalBody>
                                {
                                    this.state.phrases && this.state.phrases.map((item, idx) => {
                                        return (
                                            <div className="quick-phrase-wrap">
                                                <div className="content">
                                                    <div className="title">{item.title}</div>
                                                    <div className="phrase">
                                                        {
                                                            item.phrase.split('\n').length ?
                                                                item.phrase.split('\n').map((text, t) => {
                                                                    if (t != item.phrase.split('\n').length - 1)
                                                                        return (
                                                                            <>{text}<br /></>
                                                                        )
                                                                    else
                                                                        return (
                                                                            text
                                                                        )
                                                                })
                                                                :
                                                                item.phrase
                                                        }
                                                    </div>
                                                </div>
                                                <Button color='primary' onClick={() => {
                                                    this.setState({ message: item.phrase, quickPhraseModal: false })
                                                }}>{'Use'.translate(this.props.lang)}</Button>

                                            </div>
                                        )
                                    })
                                }

                            </ModalBody>
                            <ModalFooter style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button color="primary" onClick={() => this.setState({ quickPhraseModal: null })}> {'Close'.translate(this.props.lang)} </Button>
                            </ModalFooter>
                        </Modal>
                        :
                        null
                }
                {this.state.referral ?
                    <div className="chat-referral-preview-wrap">
                        <div className="chat-referral-preview-title">
                            <h4>{'Referral preview'.translate(this.props.lang)}</h4>
                        </div>
                        <div className="chat-referral-preview-body">
                            <div dangerouslySetInnerHTML={{ __html: this.state.referral.referralContent }} onChange={() => { }}></div>
                            <div>
                                <p> <strong>{'Status:'.translate(this.props.lang)}</strong> {humanReadable[this.state.referral.status].translate(this.props.lang)}</p>
                                {this.state.referral.reason ?
                                    <p> <strong>{'Reason:'.translate(this.props.lang)}</strong> {this.state.referral.reason ? this.state.referral.reason : ''}</p>
                                    :
                                    null}
                                {this.state.referral.patientReservedTimestamp ?
                                    <p> <strong>{'Scheduled time:'.translate(this.props.lang)}</strong> {moment.unix(this.state.referral.patientReservedTimestamp).format(`${this.props.dateFormat} HH:mm`)}</p>
                                    :
                                    null}

                                {
                                    this.state.referral.status == 'patient-requested-another-time' ?
                                        <p> <strong>{'Requested time:'.translate(this.props.lang)}</strong> {moment.unix(this.state.referral.patientRequestedTimestamp).format(`${this.props.dateFormat} HH:mm`)}</p>

                                        :
                                        null
                                }

                            </div>
                        </div>
                        <div className="chat-referral-preview-footer">
                            <Button color="primary" onClick={() => this.setState({ referral: null }, () => this.removeFromReferralPreview())}> {'Close'.translate(this.props.lang)} </Button>
                        </div>
                    </div>

                    :
                    null
                }

                {this.state.profileModal ?
                    <Modal isOpen={this.state.profileModal} centered>
                        <ModalHeader toggle={() => this.setState({ profileModal: !this.state.profileModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ profileModal: !this.state.profileModal })}>&times;</button>}>
                            <h4>{'Patient profile'.translate(this.props.lang)}</h4>
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label>{'Social security number'.translate(this.props.lang)}</Label>
                                <Input type='text' disabled
                                    value={this.state.profileModal.socialSecurityNumber} />
                            </FormGroup>
                            <FormGroup>
                                <Label>{'Name'.translate(this.props.lang)}</Label>
                                <Input type='text' disabled
                                    value={this.state.profileModal.userData && this.state.profileModal.userData.name} />
                            </FormGroup>
                            <FormGroup>
                                <Label>{'Email'.translate(this.props.lang)}</Label>
                                <Input type='text' disabled
                                    value={this.state.profileModal.parents ? this.state.profileModal.parents[0].email : this.state.profileModal.email} />
                            </FormGroup>
                            <FormGroup>
                                <Label>{'Phone'.translate(this.props.lang)}</Label>
                                <Input type='text' disabled
                                    value={this.state.profileModal.parents ? this.state.profileModal.parents[0].phone : this.state.profileModal.phone} />
                            </FormGroup>
                            <FormGroup>
                                <Label>{'Address'.translate(this.props.lang)}</Label>
                                <Input type='text' disabled
                                    value={this.state.profileModal.parents ? this.state.profileModal.parents[0].address : this.state.profileModal.street + ',' + ' ' + this.state.profileModal.zip + ' ' + this.state.profileModal.city} />
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter className='buttons-right-blue'>
                            <Button color="primary" onClick={() => this.setState({ profileModal: !this.state.profileModal })}>{'Close'.translate(this.props.lang)}</Button>

                        </ModalFooter>
                    </Modal>

                    : null
                }

                <input ref={(node) => this.documentInput = node} type="file" accept=".doc, .docx, .pdf" key={this.state.uploadTimestampDoc} onChange={this.uploadDocument} style={{ pointerEvents: 'none', opacity: 0 }} />
                <input ref={(node) => this.imageInput = node} type="file" accept="image/png, image/jpeg, image/jpg, image/gif, image/svg" key={this.state.uploadTimestamp} onChange={this.uploadImage} style={{ pointerEvents: 'none', opacity: 0 }} />
                <input ref={(node) => this.videoContentInput = node} type="file" accept=".mp4" key={this.state.uploadTimestampVideo} onChange={this.uploadVideo} style={{ pointerEvents: 'none', opacity: 0 }} />

                {
                    this.state.form ?
                        <Modal isOpen={this.state.form} centered>
                            <ModalHeader toggle={() => this.setState({ form: !this.state.form, passcodeCheckbox: null })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ form: !this.state.form, passcodeCheckbox: null })}>&times;</button>}>
                                {
                                    this.state.form._id ?
                                        <h4>{'Edit conversation'.translate(this.props.lang)}</h4>
                                        :
                                        <h4>{'New conversation'.translate(this.props.lang)}</h4>
                                }
                            </ModalHeader>
                            <ModalBody>
                                <FormGroup>
                                    <Label>{'Conversation name'.translate(this.props.lang)}</Label>
                                    <Input type='text' value={this.state.form.name} onChange={(e) => {
                                        let form = this.state.form;
                                        form.name = e.target.value;
                                        this.setState({
                                            form
                                        })
                                    }} />
                                </FormGroup>
                                <FormGroup>
                                    <Button style={{ marginBottom: 15 }} color='primary' onClick={() => this.setState({ conversationShedule: !this.state.conversationShedule })}>{'Schedule'.translate(this.props.lang)}</Button>
                                    {
                                        this.state.conversationShedule ?
                                            <div>
                                                <div>
                                                    <hr />
                                                    <FormGroup>
                                                        <Label>{'Start time'.translate(this.props.lang)}</Label>
                                                        <TimePicker
                                                            format={'HH:mm'}
                                                            value={this.state.form.scheduledDate && this.state.form.scheduledTime ? moment(new Date(this.state.form.scheduledDate.replace(/-/g, "/") + " " + this.state.form.scheduledTime)) : moment(new Date())}
                                                            minValue={moment.unix(this.state.min.getTime() / 1000).format("HH:mm")}
                                                            maxValue={moment.unix(this.state.max.getTime() / 1000).format("HH:mm")}
                                                            flag={0}
                                                            onChange={(time1, timeString) => {
                                                                let form = this.state.form;
                                                                form.scheduledTime = timeString

                                                                this.setState({ form })
                                                            }}
                                                            allowClear={false}
                                                        />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label >{'Frequency'.translate(this.props.lang) + '*'}</Label>
                                                        <Input type='select'
                                                            value={this.state.form.frequencyType}

                                                            onChange={(e) => {
                                                                let val = null;
                                                                if (e.target.value != -1) {
                                                                    val = e.target.value;
                                                                }
                                                                let form = this.state.form;
                                                                form.frequencyType = val;
                                                                let date = moment(new Date()).format(this.props.dateFormat);
                                                                if (form.date) {
                                                                    date = moment(new Date(form.date)).format(this.props.dateFormat);
                                                                }
                                                                if (val == 'day') {
                                                                    form.frequencyDayScheduledDateFrom = date;
                                                                    form.frequencyDayScheduledDateTo = date
                                                                } else if (val == 'week') {
                                                                    form.frequencyWeekScheduledDateFrom = date;
                                                                    form.frequencyWeekScheduledDateTo = moment.unix(Math.floor(new Date(date).getTime() / 1000) + 7 * 24 * 60 * 60).format(this.props.dateFormat);
                                                                } else if (val == 'month') {
                                                                    form.frequencyMonthScheduledDateFrom = date;
                                                                    form.frequencyMonthScheduledDateTo = moment.unix(Math.floor(new Date(date).getTime() / 1000) + 30 * 24 * 60 * 60).format(this.props.dateFormat);
                                                                }
                                                                this.setState({
                                                                    form,
                                                                    errorEvent: null
                                                                })
                                                            }}>
                                                            <option value={-1}>
                                                                {'Choose frequency'.translate(this.props.lang)}
                                                            </option>
                                                            <option value={'day'}>
                                                                {'Day'.translate(this.props.lang)}
                                                            </option>
                                                            <option value={'week'}>
                                                                {'Week'.translate(this.props.lang)}
                                                            </option>
                                                            <option value={'month'}>
                                                                {'Month'.translate(this.props.lang)}
                                                            </option>
                                                        </Input>
                                                    </FormGroup>

                                                    {this.state.errorEvent ? <FormFeedback >{this.state.errorEvent}</FormFeedback> : null}
                                                    {this.state.form.frequencyType == 'day' ?
                                                        <div>
                                                            <Row>
                                                                <Col lg="12">
                                                                    <div style={{ display: 'flex', textAlign: 'center', alignItems: 'center', marginBottom: 5, marginTop: 10 }}>
                                                                        <Label style={{ marginRight: 5 }}>{'Every'.translate(this.props.lang)}</Label>
                                                                        <Input style={{ width: '25%' }} type='text' value={this.state.form.frequencyDayText} onChange={(e) => {
                                                                            let value = e.target.value
                                                                            value = value.replace(/[^0-9]/g, '')

                                                                            let form = this.state.form;
                                                                            form.frequencyDayText = value;
                                                                            this.setState({
                                                                                form
                                                                            })
                                                                        }} />
                                                                        <Label style={{ marginLeft: 5 }}>{'day(s)'.translate(this.props.lang) + '*'}</Label>
                                                                    </div>
                                                                    {this.state.errorEvent ? <FormFeedback >{this.state.errorEvent}</FormFeedback> : null}
                                                                    <DatePicker
                                                                        label={'Start date'.translate(this.props.lang) + '*'}
                                                                        minDate={new Date()}
                                                                        dateFormat={this.props.dateFormat}
                                                                        value={this.state.form.frequencyDayScheduledDateFrom ? this.state.form.frequencyDayScheduledDateFrom : moment(new Date()).format(this.props.dateFormat)}
                                                                        onChange={(e) => {
                                                                            let form = this.state.form;
                                                                            form.frequencyDayScheduledDateFrom = e;
                                                                            this.setState({
                                                                                form
                                                                            })

                                                                            if (this.state.form.frequencyDayScheduledDateTo && this.state.form.frequencyDayScheduledDateFrom) {
                                                                                let dateTo = new Date(this.state.form.frequencyDayScheduledDateTo)
                                                                                let dateFrom = new Date(this.state.form.frequencyDayScheduledDateFrom)
                                                                                if (dateFrom.getTime() > dateTo.getTime()) {
                                                                                    let form = this.state.form;
                                                                                    form.frequencyDayScheduledDateTo = this.state.form.frequencyDayScheduledDateFrom;
                                                                                    this.setState({
                                                                                        form
                                                                                    })

                                                                                }
                                                                            } else if (!this.state.form.frequencyDayScheduledDateTo && this.state.form.frequencyDayScheduledDateFrom) {
                                                                                let form = this.state.form;
                                                                                form.frequencyDayScheduledDateTo = this.state.form.frequencyDayScheduledDateFrom;
                                                                                this.setState({
                                                                                    form
                                                                                })
                                                                            }

                                                                        }}
                                                                    />
                                                                    {this.state.errorEvent ? <FormFeedback >{this.state.errorEvent}</FormFeedback> : null}
                                                                </Col>
                                                                <Col lg="12">
                                                                    <div style={{ marginTop: 5 }}>
                                                                        <DatePicker
                                                                            label={'End date'.translate(this.props.lang) + '*'}
                                                                            dateFormat={this.props.dateFormat}
                                                                            minDate={new Date(this.state.form.frequencyDayScheduledDateFrom) != 'Invalid Date' ? new Date(this.state.form.frequencyDayScheduledDateFrom) : new Date()}
                                                                            value={this.state.form.frequencyDayScheduledDateTo ? this.state.form.frequencyDayScheduledDateTo : moment(new Date()).format(this.props.dateFormat)}
                                                                            onChange={(e) => {
                                                                                let form = this.state.form;
                                                                                form.frequencyDayScheduledDateTo = e;
                                                                                this.setState({
                                                                                    form
                                                                                })

                                                                            }}
                                                                        />
                                                                    </div>
                                                                    {this.state.errorEvent ? <FormFeedback >{this.state.errorEvent}</FormFeedback> : null}
                                                                </Col>
                                                            </Row>

                                                        </div>
                                                        : null
                                                    }

                                                    {this.state.form.frequencyType == 'week' ?
                                                        <div style={{ marginTop: 10, marginBottom: 15 }}>
                                                            <Row>
                                                                <Col lg="12">
                                                                    <Checkbox
                                                                        onChange={(e) => {
                                                                            let form = this.state.form;
                                                                            form.checkedMonday = e.target.checked
                                                                            this.setState({
                                                                                form
                                                                            })
                                                                        }}
                                                                        checked={this.state.form.checkedMonday}
                                                                        label={'Monday'.translate(this.props.lang)}
                                                                    />
                                                                    <Checkbox
                                                                        onChange={(e) => {
                                                                            let form = this.state.form;
                                                                            form.checkedTuesday = e.target.checked
                                                                            this.setState({
                                                                                form
                                                                            })
                                                                        }}
                                                                        checked={this.state.form.checkedTuesday}
                                                                        label={'Tuesday'.translate(this.props.lang)}
                                                                    />
                                                                    <Checkbox
                                                                        onChange={(e) => {
                                                                            let form = this.state.form;
                                                                            form.checkedWednesday = e.target.checked
                                                                            this.setState({
                                                                                form
                                                                            })
                                                                        }}
                                                                        checked={this.state.form.checkedWednesday}
                                                                        label={'Wednesday'.translate(this.props.lang)}
                                                                    />
                                                                    <Checkbox
                                                                        onChange={(e) => {
                                                                            let form = this.state.form;
                                                                            form.checkedThursday = e.target.checked
                                                                            this.setState({
                                                                                form
                                                                            })
                                                                        }}
                                                                        checked={this.state.form.checkedThursday}
                                                                        label={'Thursday'.translate(this.props.lang)}
                                                                    />
                                                                    <Checkbox
                                                                        onChange={(e) => {
                                                                            let form = this.state.form;
                                                                            form.checkedFriday = e.target.checked
                                                                            this.setState({
                                                                                form
                                                                            })
                                                                        }}
                                                                        checked={this.state.form.checkedFriday}
                                                                        label={'Friday'.translate(this.props.lang)}
                                                                    />
                                                                    <Checkbox
                                                                        onChange={(e) => {
                                                                            let form = this.state.form;
                                                                            form.checkedSaturday = e.target.checked
                                                                            this.setState({
                                                                                form
                                                                            })
                                                                        }}
                                                                        checked={this.state.form.checkedSaturday}
                                                                        label={'Saturday'.translate(this.props.lang)}
                                                                    />
                                                                    <Checkbox
                                                                        onChange={(e) => {
                                                                            let form = this.state.form;
                                                                            form.checkedSunday = e.target.checked
                                                                            this.setState({
                                                                                form
                                                                            })
                                                                        }}
                                                                        checked={this.state.form.checkedSunday}
                                                                        label={'Sunday'.translate(this.props.lang)}
                                                                    />
                                                                    {this.state.errorEvent ? <FormFeedback >{this.state.errorEvent}</FormFeedback> : null}
                                                                    <DatePicker
                                                                        label={'Start date'.translate(this.props.lang) + '*'}
                                                                        minDate={new Date()}
                                                                        dateFormat={this.props.dateFormat}
                                                                        // completeStaeInput={this.state.form}
                                                                        value={this.state.form.frequencyWeekScheduledDateFrom ? this.state.form.frequencyWeekScheduledDateFrom : moment(new Date()).format(this.props.dateFormat)}
                                                                        onChange={(e) => {
                                                                            let form = this.state.form;
                                                                            form.frequencyWeekScheduledDateFrom = e;
                                                                            this.setState({
                                                                                form
                                                                            })

                                                                            if (this.state.form.frequencyWeekScheduledDateTo && this.state.form.frequencyWeekScheduledDateFrom) {
                                                                                let dateTo = new Date(this.state.form.frequencyWeekScheduledDateTo)
                                                                                let dateFrom = new Date(this.state.form.frequencyWeekScheduledDateFrom)
                                                                                if (dateFrom.getTime() + 1000 * 60 * 60 * 24 * 7 > dateTo.getTime()) {
                                                                                    let form = this.state.form;
                                                                                    let date = new Date(this.state.form.frequencyWeekScheduledDateFrom)
                                                                                    date.setDate(date.getDate() + 7)
                                                                                    form.frequencyWeekScheduledDateTo = moment(new Date(date)).format(this.props.dateFormat ? this.props.dateFormat : 'YYYY-MM-DD');
                                                                                    this.setState({
                                                                                        form
                                                                                    })

                                                                                }
                                                                            } else if (!this.state.form.frequencyWeekScheduledDateTo && this.state.form.frequencyWeekScheduledDateFrom) {
                                                                                let form = this.state.form;
                                                                                let date = new Date(this.state.form.frequencyWeekScheduledDateFrom)
                                                                                date.setDate(date.getDate() + 7)
                                                                                form.frequencyWeekScheduledDateTo = moment(new Date(date)).format(this.props.dateFormat ? this.props.dateFormat : 'YYYY-MM-DD');
                                                                                this.setState({
                                                                                    form
                                                                                })
                                                                            }

                                                                        }}
                                                                    />
                                                                    {this.state.errorEvent ? <FormFeedback >{this.state.errorEvent}</FormFeedback> : null}
                                                                </Col>
                                                                <Col lg="12">
                                                                    <DatePicker
                                                                        label={'End date'.translate(this.props.lang) + '*'}
                                                                        dateFormat={this.props.dateFormat}
                                                                        minDate={new Date(this.state.form.frequencyWeekScheduledDateFrom) != 'Invalid Date' ? new Date(this.state.form.frequencyWeekScheduledDateFrom) : new Date(this.state.weekDate)}
                                                                        // completeStaeInput={this.state.form}
                                                                        value={this.state.form.frequencyWeekScheduledDateTo ? this.state.form.frequencyWeekScheduledDateTo : moment(this.state.weekDate).format(this.props.dateFormat)}
                                                                        onChange={(e) => {
                                                                            let form = this.state.form;
                                                                            form.frequencyWeekScheduledDateTo = e;
                                                                            this.setState({
                                                                                form
                                                                            })

                                                                        }}
                                                                    />
                                                                    {this.state.errorEvent ? <FormFeedback >{this.state.errorEvent}</FormFeedback> : null}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        : null}
                                                    {this.state.form.frequencyType == 'month' ?
                                                        <div style={{ marginBottom: 5 }}>
                                                            <Row>
                                                                <Col lg="12">
                                                                    <div style={{ display: 'flex', textAlign: 'center', alignItems: 'center', marginBottom: 5, marginTop: 10 }}>
                                                                        <Label style={{ marginRight: 5 }}>{'Every'.translate(this.props.lang)}</Label>
                                                                        <Input style={{ width: '30%' }} type='select'
                                                                            value={this.state.form.frequencyMonthScheduled}
                                                                            onChange={(e) => {
                                                                                let val = null;
                                                                                if (e.target.value != '-1') {
                                                                                    val = e.target.value;
                                                                                }
                                                                                let form = this.state.form;
                                                                                form.frequencyMonthScheduled = val;
                                                                                this.setState({
                                                                                    form
                                                                                })
                                                                            }}>
                                                                            <option value={'-1'}>

                                                                            </option>
                                                                            <option value={'1'}>
                                                                                {'1st'.translate(this.props.lang)}
                                                                            </option>
                                                                            <option value={'2'}>
                                                                                {'2nd'.translate(this.props.lang)}
                                                                            </option>
                                                                            <option value={'3'}>
                                                                                {'3rd'.translate(this.props.lang)}
                                                                            </option>
                                                                            <option value={'4'}>
                                                                                {'4th'.translate(this.props.lang)}
                                                                            </option>
                                                                            <option value={'5'}>
                                                                                {'5th'.translate(this.props.lang)}
                                                                            </option>
                                                                            <option value={'6'}>
                                                                                {'6th'.translate(this.props.lang)}
                                                                            </option>
                                                                            <option value={'7'}>
                                                                                {'7th'.translate(this.props.lang)}
                                                                            </option>
                                                                            <option value={'8'}>
                                                                                {'8th'.translate(this.props.lang)}
                                                                            </option>
                                                                            <option value={'9'}>
                                                                                {'9th'.translate(this.props.lang)}
                                                                            </option>
                                                                            <option value={'10'}>
                                                                                {'10th'.translate(this.props.lang)}
                                                                            </option>
                                                                            <option value={'11'}>
                                                                                {'11th'.translate(this.props.lang)}
                                                                            </option>
                                                                            <option value={'12'}>
                                                                                {'12th'.translate(this.props.lang)}
                                                                            </option>
                                                                            <option value={'13'}>
                                                                                {'13th'.translate(this.props.lang)}
                                                                            </option>
                                                                            <option value={'14'}>
                                                                                {'14th'.translate(this.props.lang)}
                                                                            </option>
                                                                            <option value={'15'}>
                                                                                {'15th'.translate(this.props.lang)}
                                                                            </option>
                                                                            <option value={'16'}>
                                                                                {'16th'.translate(this.props.lang)}
                                                                            </option>
                                                                            <option value={'17'}>
                                                                                {'17th'.translate(this.props.lang)}
                                                                            </option>
                                                                            <option value={'18'}>
                                                                                {'18th'.translate(this.props.lang)}
                                                                            </option>
                                                                            <option value={'19'}>
                                                                                {'19th'.translate(this.props.lang)}
                                                                            </option>
                                                                            <option value={'20'}>
                                                                                {'20th'.translate(this.props.lang)}
                                                                            </option>
                                                                            <option value={'21'}>
                                                                                {'21st'.translate(this.props.lang)}
                                                                            </option>
                                                                            <option value={'22'}>
                                                                                {'22nd'.translate(this.props.lang)}
                                                                            </option>
                                                                            <option value={'23'}>
                                                                                {'23rd'.translate(this.props.lang)}
                                                                            </option>
                                                                            <option value={'24'}>
                                                                                {'24th'.translate(this.props.lang)}
                                                                            </option>
                                                                            <option value={'25'}>
                                                                                {'25th'.translate(this.props.lang)}
                                                                            </option>
                                                                            <option value={'26'}>
                                                                                {'26th'.translate(this.props.lang)}
                                                                            </option>
                                                                            <option value={'27'}>
                                                                                {'27th'.translate(this.props.lang)}
                                                                            </option>
                                                                            <option value={'28'}>
                                                                                {'28th'.translate(this.props.lang)}
                                                                            </option>
                                                                            <option value={'29'}>
                                                                                {'29th'.translate(this.props.lang)}
                                                                            </option>
                                                                            <option value={'30'}>
                                                                                {'30th'.translate(this.props.lang)}
                                                                            </option>
                                                                            <option value={'31'}>
                                                                                {'31st'.translate(this.props.lang)}
                                                                            </option>

                                                                        </Input>
                                                                        <Label style={{ marginLeft: 5 }}>{'day of the month'.translate(this.props.lang) + '*'}</Label>
                                                                    </div>
                                                                    {this.state.errorEvent ? <FormFeedback >{this.state.errorEvent}</FormFeedback> : null}
                                                                    <DatePicker
                                                                        label={'Start date'.translate(this.props.lang) + '*'}
                                                                        minDate={new Date()}
                                                                        dateFormat={this.props.dateFormat}
                                                                        value={this.state.form.frequencyMonthScheduledDateFrom ? this.state.form.frequencyMonthScheduledDateFrom : moment(new Date()).format(this.props.dateFormat)}
                                                                        onChange={(e) => {
                                                                            let form = this.state.form;
                                                                            form.frequencyMonthScheduledDateFrom = e;
                                                                            this.setState({
                                                                                form
                                                                            })

                                                                            if (this.state.form.frequencyMonthScheduledDateTo && this.state.form.frequencyMonthScheduledDateFrom) {
                                                                                let dateTo = new Date(this.state.form.frequencyMonthScheduledDateTo)
                                                                                let dateFrom = new Date(this.state.form.frequencyMonthScheduledDateFrom)
                                                                                if (dateFrom.getTime() + 1000 * 60 * 60 * 24 * 31 > dateTo.getTime()) {
                                                                                    let form = this.state.form;
                                                                                    let date = new Date(this.state.form.frequencyMonthScheduledDateFrom)
                                                                                    date.setDate(date.getDate() + 31)
                                                                                    form.frequencyMonthScheduledDateTo = moment(new Date(date)).format(this.props.dateFormat ? this.props.dateFormat : 'YYYY-MM-DD');
                                                                                    this.setState({
                                                                                        form
                                                                                    })

                                                                                }
                                                                            } else if (!this.state.form.frequencyMonthScheduledDateTo && this.state.form.frequencyMonthScheduledDateFrom) {
                                                                                let form = this.state.form;
                                                                                let date = new Date(this.state.form.frequencyMonthScheduledDateFrom)
                                                                                date.setDate(date.getDate() + 31)
                                                                                form.frequencyMonthScheduledDateTo = moment(new Date(date)).format(this.props.dateFormat ? this.props.dateFormat : 'YYYY-MM-DD');
                                                                                this.setState({
                                                                                    form
                                                                                })
                                                                            }
                                                                        }}
                                                                    />
                                                                    {this.state.errorEvent ? <FormFeedback >{this.state.errorEvent}</FormFeedback> : null}
                                                                </Col>
                                                                <Col lg="12">
                                                                    <DatePicker
                                                                        label={'End date'.translate(this.props.lang) + '*'}
                                                                        dateFormat={this.props.dateFormat}
                                                                        minDate={new Date(this.state.form.frequencyMonthScheduledDateFrom) != 'Invalid Date' ? new Date(this.state.form.frequencyMonthScheduledDateFrom) : new Date(this.state.monthDate)}
                                                                        value={this.state.form.frequencyMonthScheduledDateTo ? this.state.form.frequencyMonthScheduledDateTo : moment(this.state.monthDate).format(this.props.dateFormat)}
                                                                        onChange={(e) => {
                                                                            let form = this.state.form;
                                                                            form.frequencyMonthScheduledDateTo = e;
                                                                            this.setState({
                                                                                form
                                                                            })

                                                                        }}
                                                                    />
                                                                    {this.state.errorEvent ? <FormFeedback >{this.state.errorEvent}</FormFeedback> : null}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        : null}
                                                    <hr />
                                                </div>
                                            </div>
                                            :
                                            null
                                    }
                                </FormGroup>

                                <FormGroup>
                                    <Checkbox
                                        onChange={(e) => {

                                            var pwdChars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
                                            var pwdLen = 10;
                                            var randPassword = Array(pwdLen).fill(pwdChars).map(function (x) { return x[Math.floor(Math.random() * x.length)] }).join('');
                                            let form = this.state.form;
                                            form.passcode = randPassword
                                            this.setState({
                                                passcodeCheckbox: e.target.checked,
                                                form
                                            })
                                        }}
                                        checked={this.state.passcodeCheckbox}
                                        label={'Passcode'.translate(this.props.lang)}
                                    />
                                    {
                                        this.state.passcodeCheckbox ?
                                            <FormGroup>
                                                <Label>{'Your passcode'.translate(this.props.lang)}</Label>
                                                <Input type='text' value={this.state.form.passcode} onChange={(e) => {
                                                    let form = this.state.form;
                                                    form.passcode = e.target.value;
                                                    this.setState({
                                                        form
                                                    })
                                                }} />
                                            </FormGroup>

                                            :
                                            null
                                    }
                                </FormGroup>
                                <FormGroup>
                                    <Checkbox
                                        onChange={(e) => {
                                            let form = this.state.form;
                                            form.requireBankID = e.target.checked
                                            this.setState({
                                                form
                                            })
                                        }}
                                        checked={this.state.form.requireBankID}
                                        label={'Require BankID'.translate(this.props.lang)}
                                    />

                                </FormGroup>
                                <FormGroup>
                                    <Checkbox
                                        onChange={(e) => {
                                            let form = this.state.form;
                                            form.requireNetID = e.target.checked
                                            this.setState({
                                                form
                                            })
                                        }}
                                        checked={this.state.form.requireNetID}
                                        label={'Require SITHS'.translate(this.props.lang)}
                                    />

                                </FormGroup>
                                <FormGroup>
                                    <Checkbox
                                        onChange={(e) => {
                                            let form = this.state.form;
                                            form.muteParticipants = e.target.checked
                                            this.setState({
                                                form
                                            })
                                        }}
                                        checked={this.state.form.muteParticipants}
                                        label={'Mute Participants'.translate(this.props.lang)}
                                    />

                                </FormGroup>
                                <FormGroup>
                                    <Checkbox
                                        onChange={(e) => {
                                            let form = this.state.form;
                                            form.recordConversationAsVideo = e.target.checked
                                            this.setState({
                                                form
                                            })
                                        }}
                                        checked={this.state.form.recordConversationAsVideo}
                                        label={'Record conversation as video'.translate(this.props.lang)}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Checkbox
                                        onChange={(e) => {
                                            let form = this.state.form;
                                            form.openInNewTab = e.target.checked
                                            this.setState({
                                                form
                                            })
                                        }}
                                        checked={this.state.form.openInNewTab}
                                        label={'Open in new tab'.translate(this.props.lang)}
                                    />

                                </FormGroup>
                            </ModalBody>
                            <ModalFooter>
                                {this.state.form._id ?
                                    <Button
                                        disabled={!this.state.form.frequencyType || (this.state.form.frequencyType && (
                                            (this.state.form.frequencyType == 'day' && this.state.form.frequencyDayText && this.state.form.frequencyDayScheduledDateFrom && this.state.form.frequencyDayScheduledDateTo)
                                            || (this.state.form.frequencyType == 'month' && this.state.form.frequencyMonthScheduled && this.state.form.frequencyMonthScheduledDateFrom && this.state.form.frequencyMonthScheduledDateTo)
                                            || (this.state.form.frequencyType == 'week' && (this.state.form.checkedMonday || this.state.form.checkedTuesday || this.state.form.checkedThursday || this.state.form.checkedWednesday || this.state.form.checkedFriday || this.state.form.checkedSaturday || this.state.form.checkedSunday) && this.state.form.frequencyWeekScheduledDateFrom && this.state.form.frequencyWeekScheduledDateTo)
                                        ))
                                            ? false : true}
                                        color={'primary'} onClick={() => this.editConversation(this.state.form)}>{'Save'.translate(this.props.lang)}</Button>
                                    :
                                    <Button
                                        disabled={!this.state.form.frequencyType || (this.state.form.frequencyType && (
                                            (this.state.form.frequencyType == 'day' && this.state.form.frequencyDayText && this.state.form.frequencyDayScheduledDateFrom && this.state.form.frequencyDayScheduledDateTo)
                                            || (this.state.form.frequencyType == 'month' && this.state.form.frequencyMonthScheduled && this.state.form.frequencyMonthScheduledDateFrom && this.state.form.frequencyMonthScheduledDateTo)
                                            || (this.state.form.frequencyType == 'week' && (this.state.form.checkedMonday || this.state.form.checkedTuesday || this.state.form.checkedThursday || this.state.form.checkedWednesday || this.state.form.checkedFriday || this.state.form.checkedSaturday || this.state.form.checkedSunday) && this.state.form.frequencyWeekScheduledDateFrom && this.state.form.frequencyWeekScheduledDateTo)
                                        ))
                                            ? false : true}
                                        color={'primary'} onClick={() => this.createConversation(this.state.form)}>{'Create'.translate(this.props.lang)}</Button>
                                }
                            </ModalFooter>
                        </Modal>
                        :
                        null
                }

                {this.state._dragNDropUpload || this.state._imageUploading || this.state._videoUploading || this.state._documentUploading ? (
                    <div className="file-progress-loader">
                        <div>
                            <Player
                                onEvent={(event) => {
                                    if (event === "load" && this.player && this.player.current) this.player.current.play();
                                }}
                                ref={this.player} // set the ref to your class instance
                                autoplay={true}
                                loop={true}
                                controls={true}
                                src="/lf30_editor_l5cxzdyf.json"
                                renderer={'svg'}
                                style={{ height: "128px", width: "128px", }}
                            ></Player>
                            <h6>{'Processing file...'.translate(this.state.lang)}</h6>
                        </div>

                    </div>
                ) : null}

                {
                    this.state.invitedContactsSucces ?
                        <InfoModal
                            isOpen={this.state.invitedContactsSucces}

                            toggle={() => this.setState({ invitedContactsSucces: null, successMessage: null })}
                            header={'Info'.translate(this.props.lang)}
                            info={
                                <>
                                    <span style={{ fontWeight: 600 }}>{'Invitations sent successfully'.translate(this.props.lang)}</span>
                                    <div className='table-error-dropin'>
                                        {
                                            this.state.invitedContactsSucces && this.state.invitedContactsSucces.map((item, idx) => {
                                                return (
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        {item.contact}
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </>
                            }
                            buttons={[
                                <Button color='primary' onClick={() => {
                                    this.setState({
                                        invitedContactsSucces: null,
                                        successMessage: null
                                    })

                                }}>{'Close'.translate(this.props.lang)}</Button>
                            ]}
                        />
                        :
                        null

                }
                {
                    this.state.sentInvintations ?
                        <Modal isOpen={this.state.sentInvintations} size='xl' centered>
                            <ModalHeader>
                                {'Sent invitations list'.translate(this.props.lang)}
                            </ModalHeader>
                            <ModalBody>
                                <div className='sent-invitations-table-wrap'>
                                    <table className="sent-invitations-table" style={{ width: '100%' }}>
                                        <tr>
                                            <th>{'Contact'.translate(this.props.lang)}</th>
                                            <th>{'Type'.translate(this.props.lang)}</th>
                                            <th>{'Sent'.translate(this.props.lang)}</th>
                                            <th>{'Reserved'.translate(this.props.lang)}</th>
                                            <th>{'Actions'.translate(this.props.lang)}</th>
                                        </tr>
                                        {
                                            this.state.sentInvintations && this.state.sentInvintations.map((item, idx) => {
                                                return (
                                                    <tr>
                                                        <td>{item.contact}</td>
                                                        <td className="uppercase-text">{item.type}</td>
                                                        <td>{moment.unix(item.timestamp).format(this.props.dateTimeFormat)}</td>
                                                        <td>{moment.unix(item.scheduleTimestamp).format(this.props.dateTimeFormat)}</td>
                                                        <td><Button color="primary" onClick={() => this.updateSentInvitationsList(idx)}>{'Remove'.translate(this.props.lang)}</Button></td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </table>
                                </div>

                            </ModalBody>
                            <ModalFooter>
                                <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center' }}>

                                    <Button color='primary' onClick={() => {
                                        this.setState({ sentInvintations: null })
                                    }}>{'Close'.translate(this.props.lang)}</Button>
                                </div>
                            </ModalFooter>
                        </Modal>
                        : null
                }
                {
                    this.state.shareModal ?

                        <Modal isOpen={this.state.shareModal} size='lg' centered className="share-modal-wrap">
                            <ModalHeader>
                                {'Invite external participants to video calls'.translate(this.props.lang)}
                            </ModalHeader>
                            <ModalBody>
                                <div className="">
                                    <Row>
                                        <Col lg="6">
                                            <FormGroup>
                                                <DatePicker
                                                    label={'Date'.translate(this.props.lang)}
                                                    value={this.state.shareLinkDate ? this.state.shareLinkDate : this.state.conversation && this.state.conversation.scheduledDate && this.state.conversation.scheduledDate != "Invalid date" ? this.state.conversation.scheduledDate : moment(new Date()).format(this.props.format)}
                                                    heightInput={'48px'}

                                                    onChange={(e) => {
                                                        this.setState({ shareLinkDate: e })
                                                    }}
                                                    marginBottom={'5px'}
                                                />

                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup  >
                                                <Label style={{ "margin-bottom": 11 }}>{'Time'.translate(this.props.lang)}</Label>
                                                <TimePicker2
                                                    format={'HH:mm'}
                                                    value={this.state.shareLinkTime ? this.state.shareLinkTime : this.state.conversation && this.state.conversation.scheduledTime && this.state.conversation.scheduledTime != "Invalid date" ? this.state.conversation.scheduledTime : moment(new Date()).format('HH:mm')}
                                                    minValue={'00:00'}
                                                    maxValue={'23:59'}
                                                    flag={0}
                                                    onChange={(timeString) => {
                                                        this.setState({ shareLinkTime: timeString })
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {/* </div> */}
                                    <FormGroup>
                                        <Label style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                                            {'Fill in mobile number or e-mail address'.translate(this.props.lang)}
                                            <div className="share-modal-info">
                                                <Isvg src={infoIcon} style={{ width: 20, minWidth: 20, height: 20 }} />
                                                <div className="tooltip-on-element">{'SMS or e-mail is sent with an invitation link to the video call.'.translate(this.props.lang)}</div>
                                            </div>
                                        </Label>
                                        {
                                            this.state.shareModalFields && this.state.shareModalFields.map((item, idx) => {
                                                return (
                                                    <div className="input-mobile-number-or-email-wrap">
                                                        <Row>
                                                            <Col lg='3'>
                                                                <Input style={{ marginTop: 0 }} type='select'
                                                                    value={this.state.inviteInputType}
                                                                    onChange={(e) => {
                                                                        let shareModalFields = this.state.shareModalFields;
                                                                        shareModalFields[idx].type = e.target.value;
                                                                        if (e.target.value == 'email') {
                                                                            shareModalFields[idx].input = ''
                                                                        }
                                                                        this.setState({ shareModalFields })
                                                                    }}
                                                                >
                                                                    <option value={'email'}>{'Email address'.translate(this.props.lang)}</option>
                                                                    <option value={'phone'}>{'Mobile number'.translate(this.props.lang)}</option>

                                                                </Input>

                                                            </Col>
                                                            <Col lg='9'>
                                                                {
                                                                    this.state.shareModalFields[idx].type == 'phone' ?

                                                                        <PhoneField
                                                                            value={this.state.shareModalFields[idx].input}
                                                                            onChange={(e) => {
                                                                                this.setState({
                                                                                    inviteError: null
                                                                                })
                                                                                let shareModalFields = this.state.shareModalFields;
                                                                                shareModalFields[idx].input = e;
                                                                                this.setState({ shareModalFields })
                                                                            }} />
                                                                        :
                                                                        <Input type='text' value={item.input} onChange={(e) => {
                                                                            this.setState({
                                                                                inviteError: null
                                                                            })
                                                                            let shareModalFields = this.state.shareModalFields;
                                                                            shareModalFields[idx].input = e.target.value;
                                                                            this.setState({ shareModalFields })
                                                                        }} placeholder={'Email address'.translate(this.props.lang)} style={{ marginBottom: 15 }} />

                                                                }
                                                                <div className="remove-item" onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    let shareModalFields = this.state.shareModalFields;
                                                                    shareModalFields.splice(idx, 1)
                                                                    this.setState({ shareModalFields })

                                                                }}>&times;</div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )
                                            })
                                        }
                                        <p style={{ color: 'red' }}>{this.state.inviteError}</p>
                                    </FormGroup>
                                    <div>
                                        <div style={{ display: 'flex', width: '25%', alignItems: 'center', cursor: 'pointer' }} onClick={() => {
                                            let shareModalFields = this.state.shareModalFields;
                                            shareModalFields.push({
                                                input: ''
                                            })
                                            this.setState({ shareModalFields })
                                        }}>
                                            <div style={{ marginRight: 10 }} className="blue-add-icon">
                                                <Isvg src={addIcon} />
                                            </div>
                                            <h6 style={{ marginBottom: 0 }}>{'Add participants'.translate(this.props.lang)}…</h6>

                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: 20, width: '100%' }}>
                                        <div className="link-input-wrap" style={{ width: '50%' }}>
                                            <Input type="text" value={this.state.shareModal} disabled />
                                            <div className="tooltip-on-input">{'Copy direct link to the video call'.translate(this.props.lang)}</div>
                                        </div>
                                        <div style={{ marginLeft: 20, display: 'flex', alignItems: 'center', cursor: 'pointer' }} className="copy-link-text" onClick={() => {
                                            this.setState({ chatHelpIcons: false });
                                            // this.showCopiedLink();
                                            navigator.clipboard.writeText(this.state.shareModal)
                                        }}>
                                            <Isvg src={copyLinkIcon} style={{ width: 20, minWidth: 20, height: 20 }} />
                                            <div style={{ marginLeft: 8 }} >
                                                {
                                                    this.state.linkCopied ?
                                                        'The link is copied to clipboard'.translate(this.props.lang)
                                                        :
                                                        'Copy link'.translate(this.props.lang)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ModalBody>
                            <ModalFooter className="share-modal-footer">
                                <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>

                                    <Button color='primary' disabled={this.state.conversation && this.state.conversation.invitedContacts && this.state.conversation.invitedContacts.length ? false : true} onClick={() => {
                                        this.setState({
                                            sentInvintations: this.state.conversation && this.state.conversation.invitedContacts ? this.state.conversation.invitedContacts : []
                                        })
                                    }} >{'Sent invitations'.translate(this.props.lang)}</Button>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>

                                        <Button color='primary' onClick={() => {
                                            if (this.state.shareModalFields && this.state.shareModalFields.length) {
                                                let check = true
                                                for (let i = 0; i < this.state.shareModalFields.length; i++) {
                                                    if (this.state.shareModalFields[i].input && this.state.shareModalFields[i].input != '') {
                                                        if (/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i.test(this.state.shareModalFields[i].input) || ((this.state.shareModalFields[i].input.startsWith('+') || this.state.shareModalFields[i].input.startsWith('00')) && this.state.shareModalFields[i].input.length >= 11 && this.state.shareModalFields[i].input.indexOf('@') == -1)) {
                                                            check = true

                                                        } else {
                                                            check = false
                                                            this.setState({
                                                                inviteError: 'Email or phone number is incorrect!'.translate(this.props.lang)
                                                            })
                                                        }
                                                    }
                                                }
                                                if (check) {
                                                    this.sendInvitations()
                                                }
                                            }
                                        }}
                                            disabled={this.state.shareModalFields && this.state.shareModalFields.filter(input => input.input).length ? false : true}
                                        >{'Send invitation'.translate(this.props.lang)}</Button>
                                        <Button color='primary' style={{ marginLeft: 10 }} onClick={() => {
                                            let shareModalFields = [{ input: '' }];

                                            this.setState({ shareModal: null, inviteError: null, shareModalFields })
                                        }}>{'Close'.translate(this.props.lang)}</Button>
                                    </div>
                                </div>

                            </ModalFooter>
                        </Modal>
                        : null
                }
                {
                    this.state.buyConversationModal ?

                        <Modal isOpen={this.state.buyConversationModal} centered>
                            <ModalHeader close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ buyConversationModal: false }, () => {
                                this.props[0].history.push({
                                    state: {
                                        failureModal: null
                                    }
                                })
                            })}>&times;</button>}>{'Buy a licence'.translate(this.props.lang)}</ModalHeader>
                            <ModalBody>
                                <FormGroup>
                                    <Label>
                                        {"Licence type".translate(this.props.lang)}
                                    </Label>
                                    <Input
                                        type="select"
                                        onChange={(e) => {
                                            if (e.target.value == -1) {
                                                this.setState({
                                                    usersLicencePrice: null
                                                })
                                            }
                                            this.setState({
                                                licenceName: e.target.value,
                                            }, () => {
                                            });
                                        }}
                                        value={this.state.licenceName}
                                    >
                                        <option value={-1}>
                                            {"Select licence type".translate(this.props.lang)}
                                        </option>
                                        {
                                            this.state.licence && this.state.licence.length ?
                                                this.state.licence.map(item => {
                                                    return (
                                                        <option value={item.name}>{this.props.lang == 'en' ? item.name : item.nameSv}</option>
                                                    )
                                                })
                                                :
                                                null
                                        }
                                    </Input>
                                </FormGroup>

                                <FormGroup>
                                    <Label>
                                        {"Licence duration".translate(this.props.lang)}
                                    </Label>
                                    <Input
                                        type="select"
                                        onChange={(e) => {
                                            if (e.target.value == -1) {
                                                this.setState({
                                                    usersLicencePrice: null,
                                                    licenceDuration: null
                                                })
                                            } else {
                                                this.setState({
                                                    licenceDuration: e.target.value,
                                                });
                                            }

                                        }}
                                        value={this.state.licenceDuration}
                                    >
                                        <option value={-1}>
                                            {"Select duration".translate(this.props.lang)}
                                        </option>
                                        <option value={0.004107}>
                                            {"Single call - 3 hours".translate(this.props.lang)}
                                        </option>
                                        <option value={1}>
                                            {"1 month".translate(this.props.lang)}
                                        </option>
                                        <option value={3}>
                                            {"3 months".translate(this.props.lang)}
                                        </option>
                                        <option value={6}>
                                            {"6 months".translate(this.props.lang)}
                                        </option>
                                        <option value={12}>
                                            {"12 months".translate(this.props.lang)}
                                        </option>

                                    </Input>
                                </FormGroup>
                                {
                                    this.props.uData.organisationName ?
                                        <Checkbox
                                            onChange={(e) => {
                                                this.setState({
                                                    organisationPaying: e.target.checked
                                                })
                                            }}
                                            checked={this.state.organisationPaying}
                                            label={'I pay as an organisation'.translate(this.props.lang)}
                                        />
                                        :
                                        null
                                }
                                <p>{`Price for selected type and duration is:`.translate(this.props.lang)} {this.state.usersLicencePrice ? (this.state.usersLicencePrice + ' SEK') : '0 SEK'}</p>
                                <Button color="primary" disabled={!this.state.usersLicencePrice} onClick={() => this.setState({ formModal: true, buyConversationModal: false }, () => {
                                    this.props[0].history.push({
                                        state: {
                                            failureModal: null
                                        }
                                    })
                                })}>{'Buy licence'.translate(this.props.lang)}</Button>
                                <Button color="primary" style={{ marginLeft: 15 }} onClick={() => {
                                    this.setState({ buyConversationModal: null, licenceName: null, licenceDuration: null, usersLicencePrice: null }, () => {
                                        this.props[0].history.push({
                                            state: {
                                                failureModal: null
                                            }
                                        })
                                    })
                                }}>{'Use free version'.translate(this.props.lang)}</Button>
                            </ModalBody>
                            <ModalFooter>
                            </ModalFooter>
                        </Modal>
                        : null
                }
                {
                    this.state.formModal ?

                        <Modal isOpen={this.state.formModal} centered>
                            <ModalHeader close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ formModal: false })}>&times;</button>}>{'Select a payment method'.translate(this.props.lang)}</ModalHeader>
                            <ModalBody>
                                <div className="payment-container">
                                    <Button className="payment-button-payson" onClick={() => this.setState({ paysonModal: true, formModal: false, addLicence: false }, () => {

                                    })}><Isvg src={PaysonSvg} /> <span>{'Credit card'.translate(this.props.lang)}</span> </Button>
                                    <Button className="payment-button-swish" onClick={() => this.setState({ swishModal: true, formModal: false, addLicence: false }, () => {

                                    })}><Isvg src={SwishSvg} /></Button>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                            </ModalFooter>
                        </Modal>
                        : null
                }
                {
                    this.state.calendarConnectModal ?

                        <Modal isOpen={this.state.calendarConnectModal} centered>
                            <ModalHeader close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ calendarConnectModal: false })}>&times;</button>}>{'Connect with'.translate(this.props.lang)}</ModalHeader>
                            <ModalBody className="custom-social-buttons">
                                <button onClick={() => this.addEvent(this.state.calendarConnectModal, "gmail")}><img src={googleImg} />{'Gmail'.translate(this.props.lang)}</button>
                                <button onClick={() => this.connectWithOutlook(this.state.calendarConnectModal, "outlook")}><img src={outlookImg} />{'Outlook'.translate(this.props.lang)}</button>
                            </ModalBody>
                            <ModalFooter>
                            </ModalFooter>
                        </Modal>
                        : null
                }
                {
                    this.state.paysonModal ?

                        <Payson
                            uData={this.props.uData}
                            open={this.state.paysonModal}
                            openModal={(item) => {
                                this.setState({
                                    paysonModal: item
                                })

                            }}
                            swishNumber={this.props.uData.accountNumber}
                            phone={this.props.uData.phone}
                            price={this.state.usersLicencePrice}
                            message={""}
                            licenceDuration={this.state.licenceDuration}
                            licenceID={this.state.usersLicence}
                            redirectUrl={`/chat`}
                            organisationPaying={this.state.organisationPaying ? this.state.organisationPaying : false}

                        />
                        : null
                }
                {
                    this.state.swishModal ?

                        <Swish
                            uData={this.props.uData}
                            verifyUser={() => this.props.verifyUser()}
                            lang={this.props.lang}
                            history={this.props[0]}
                            open={this.state.swishModal}
                            openModal={(item) => {
                                this.setState({
                                    swishModal: item
                                })

                            }}
                            swishNumber={this.props.uData.accountNumber}
                            phone={this.props.uData.phone}
                            price={this.state.usersLicencePrice}
                            licenceDuration={this.state.licenceDuration}
                            licence={this.state.usersLicenceName}
                            licenceID={this.state.usersLicence}
                            redirectUrl={`/chat`}
                            organisationPaying={this.state.organisationPaying ? this.state.organisationPaying : false}

                        />
                        : null
                }



            </div >
        );
    }
}

const selector = formValueSelector('modalForm');

export default connect(state => {
    return { group: selector(state, 'group') }

}, {
    changeSubgroup: value => change("modalForm", "subgroup", value),

})(Page(Chat));
