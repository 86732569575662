import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col, Modal, ModalBody, ModalHeader
} from 'reactstrap';

import Isvg from 'react-inlinesvg';
import attach from '../../assets/svg/attach.svg';
import edit from '../../assets/svg/edit.svg'
import viewIcon from '../../assets/svg/eye.svg';
import garabage from '../../assets/svg/garbage.svg';
import profileUser from "../../assets/images/user.png";
import ListBuilder from '../../components/listBuilder';
import DeleteModal from '../../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';
import moment from 'moment';

/**
* Support archive page show tickets that are closed
* @author   Stefan Pantic
*/
class SupportArchive extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            groups: [],
            subgroups: [],
            total: 0,
            loading: true,
            category: {}
        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }

        if (prevProps.selectedGroup != this.props.selectedGroup) {
            this.get();
        }

        if (prevProps.selectedClinic != this.props.selectedClinic) {
            this.get();
        }

    }

    /**
    * Delete support ticket
    * @author   Stefan Pantic
    * @Objectparam    {String} id                  support ticket id
    */
    delete = (id) => {

        fetch(API_ENDPOINT + '/support-ticket/delete/' + id, {
            method: 'DELETE',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
        }).then(res => res.json()).then((result) => {
            this.get();
        })
    }

    findMessages = (id) => {
        fetch(API_ENDPOINT + '/support-category/all-messages/' + id, {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({})
        }).then(res => res.json()).then((result) => {
            if (result.error) {
                this.setState({
                    error: result.error.translate(this.props.lang)
                })
            } else {
                
                this.setState({
                    messages: result
                })
            }

        })
    }

    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic })
        }


        let subgroups = [{ name: 'None', value: null }];

        if (this.props.group) {
            for (let i = 0; i < this.state.subgroups.length; i++) {
                if (this.state.subgroups[i].groups && this.state.subgroups[i].groups.indexOf(this.props.group) !== -1) {
                    subgroups.push(this.state.subgroups[i]);
                }
            }
        }

        return (
            <div className="dashboard">

                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="panel">


                                <ListBuilder
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'name', label: 'Category'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'subjectt', label: 'Subject'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'date', label: 'Date'.translate(this.props.lang), allowSort: true },
                                    ]}
                                    items={this.state.items.map((item => {
                                        return {
                                            ...item,
                                            subjectt: <>{'Ticket ID:'.translate(this.props.lang) + ' ' + item.tiketNumber + ' ' + item.subject}</>,
                                            date: <>{moment.unix(item.tsCreated).format(`${this.props.dateFormat} HH:mm`)} - {moment.unix(item.timestemp).format(`${this.props.dateFormat} HH:mm`)}</>,
                                        }
                                    }))}
                                    actions={
                                        [
                                            {
                                                component: <Isvg src={viewIcon} />,
                                                onClick: (item) => {
                                                    this.setState({ supportModal: item }, () => this.findMessages(item._id))
                                                }
                                            },
                                            {
                                                component: <Isvg src={garabage} />,
                                                onClick: (item) => {
                                                    this.setState({ deleteModal: item })
                                                }
                                            },

                                        ]
                                    }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}

                                ></ListBuilder>

                                {this.state.supportModal ?
                                    <Modal isOpen={this.state.supportModal} size='xl' centered>
                                        <ModalHeader close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ supportModal: !this.state.supportModal })}>&times;</button>}>{'Ticket ID:'.translate(this.props.lang) + this.state.supportModal.tiketNumber + ' ' + this.state.supportModal.subject}</ModalHeader>
                                        <ModalBody className={'support-archive-scroll'}>
                                            {this.state.messages && this.state.messages.sort(function (a, b) { return b.timestemp - a.timestemp }).map(item => {
                                                return (
                                                    <div className="mesage-box" style={{ marginBottom: 5, background: '#E6EDF5', borderRadius: '6px', padding: 10 }}>
                                                        <div className="message-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <div className="message-header-container" style={{ display: 'flex', alignItems: 'center' }}>
                                                                {item.profilePicture ?
                                                                    <img src={API_ENDPOINT + item.profilePicture} style={{ width: 32, height: 32, borderRadius: '50%', objectFit: 'cover', marginRight: 10 }} />

                                                                    :
                                                                    <img src={profileUser} style={{ width: 32, height: 32, borderRadius: '50%', objectFit: 'cover', marginRight: 10 }} />

                                                                }
                                                                <h6>{item.user}</h6>
                                                            </div>
                                                            <p>{moment.unix(item.timestemp).format(`${this.props.dateFormat} HH:mm`)}</p>
                                                        </div>
                                                        <p>
                                                            {item.message}
                                                            <div >
                                                                {item.image ? <a target='_blank' href={API_ENDPOINT + item.image}>{item.image}</a> : null}
                                                                {
                                                                    item.document ?
                                                                        <div className="document"><a target="_blank" download href={API_ENDPOINT + item.document.file}><Isvg src={attach} style={{width: 20, height: 20}} />{item.document.name}</a></div>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </p>
                                                    </div>

                                                )
                                            })}
                                        </ModalBody>
                                    </Modal>
                                    : null}

                                {
                                    this.state.deleteModal ?
                                        <DeleteModal
                                            lang={this.props.lang}
                                            isOpen={this.state.deleteModal}
                                            toggle={() => this.setState({ deleteModal: null })}
                                            handler={() => { this.delete(this.state.deleteModal._id); this.setState({ deleteModal: null }) }}
                                        >
                                            {'Delete ticket'.translate(this.props.lang)} <strong>{this.state.deleteModal && this.state.deleteModal.subject}</strong>?
                                        </DeleteModal>
                                        : null}
                            </div>
                        </Col>
                    </Row>
                </Container>




            </div>
        );
    }
}

const selector = formValueSelector('modalForm');

export default connect(state => {
    return { group: selector(state, 'group') }

}, {
    changeSubgroup: value => change("modalForm", "subgroup", value),

})(Page(SupportArchive));