import React from 'react';
import { jsPDF } from "jspdf";

import {
    Container,
    Row,
    Col,
    Button,
    Modal, ModalBody, ModalFooter, ModalHeader,
    Input
} from 'reactstrap';

import { API_ENDPOINT } from '../constants';

import Isvg from 'react-inlinesvg';

import { Document, Page as PdfPage, pdfjs } from 'react-pdf';


class PreviewFortnoxInvoice extends React.Component {

    constructor(props) {
        super(props);
        if (typeof window != 'undefined')
            pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

        this.state = {
            numPages: 0,
            pageNumber: 1
        }
    }

    componentDidMount() {


    }

    onDocumentLoadSuccess = ({ numPages }) => {
        if (this.state.numPages != numPages)
            this.setState({
                numPages: numPages
            })
    }


    nextPage = () => {
        if (this.state.pageNumber < this.state.numPages)
            this.setState({
                pageNumber: this.state.pageNumber + 1
            })
    }
    backPage = () => {
        if (this.state.pageNumber != 1)
            this.setState({
                pageNumber: this.state.pageNumber - 1
            })
    }


    render() {
        return (
            <div>
                {
                    this.props.data && this.props.data.pdf ?
                        <Modal isOpen={this.props.data} centered size="lg">
                            <ModalHeader>
                                {'Preview invoce'.translate(this.props.lang)}{this.props.data.documentNumber ? ` - ${this.props.data.documentNumber}` : ''}
                            </ModalHeader>
                            <ModalBody className='preview-invoice-body-wrap'>
                                {
                                    typeof window != 'undefined' ?
                                        <div style={{ width: '100%' }} ref={(node) => this.pdfDocument = node}>
                                            <Document
                                                file={{ url: this.props.data.pdf }}
                                                onLoadSuccess={this.onDocumentLoadSuccess}

                                                error={"Failed to load PDF file.".translate(this.props.lang)}
                                                loading={"Loading PDF...".translate(this.props.lang)}
                                                noData={"No page specified.".translate(this.props.lang)}
                                            >
                                                <PdfPage width={this.pdfDocument ? this.pdfDocument.clientWidth : null} pageNumber={this.state.pageNumber} />
                                            </Document>
                                            {
                                                this.state.numPages > 1 ?
                                                    <div className="page-pagination">
                                                        <Button onClick={this.backPage}>{'Previous'.translate(this.props.lang)}</Button>
                                                        <p>{'Page'.translate(this.props.lang)} {this.state.pageNumber} {'of'.translate(this.props.lang)} {this.state.numPages}</p>
                                                        <Button onClick={this.nextPage}>{'Next'.translate(this.props.lang)}</Button>
                                                    </div>
                                                    :
                                                    null
                                            }


                                        </div>
                                        :
                                        null
                                }

                            </ModalBody>
                            <ModalFooter className='buttons-right-blue' style={{ justifyContent: 'flex-end' }}>
                                {
                                    this.props.data.pdf ?
                                        <Button color="primary" onClick={() => {
                                            let link = document.createElement("a");
                                            link.download = `Invoice_${this.props.data.documentNumber}.pdf`;
                                            link.href = this.props.data.pdf;
                                            link.target = '_blank';
                                            document.body.appendChild(link);
                                            link.click();
                                            document.body.removeChild(link);

                                        }}>{"Download pdf".translate(this.props.lang)}</Button>
                                        :
                                        null
                                }
                                <Button color="primary" onClick={() => {
                                    this.setState({
                                        numPages: 0,
                                        pageNumber: 1
                                    }, () => {
                                        if (this.props.closePreview) {
                                            this.props.closePreview()
                                        }
                                    })
                                    this.pdfDocument = null;
                                }}>{"Close".translate(this.props.lang)}</Button>

                            </ModalFooter>
                        </Modal>
                        :
                        null

                }
            </div>
        )
    }
}

export default PreviewFortnoxInvoice;