import React from 'react';


import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button, Label
} from 'reactstrap';

import FormBuilder from './forms/formBuilder';
import { required, phoneNumber, emailAddress, validateStrongPassword, vatNumberValidation } from './forms/validation'
import InfoModal from "../components/infoModal";
import { API_ENDPOINT } from '../constants'
import Checkbox from './forms/fields/checkbox';
import Link from "../components/link";
import { formValueSelector, change } from "redux-form"; // ES6
import { connect } from "react-redux";

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

/**
* A model for entering patient data is displayed if not all required data is available
* @author Milan Stanojevic
*/
class PatientAccountModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            privatePersonProfile: true,
            companyData: false
        }
    }

    componentDidMount() {
        fetch(API_ENDPOINT + '/languages', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                sortType: 1,
                sortField: 'key',
                query: {}
            })
        }).then(parseJSON).then(({ result, status }) => {
            if (status >= 200 && status < 300)
                this.setState({
                    languages: result
                })
        })



        // this.props.changeSetCountry("Sweden");
        // console.log(this.props.selectCountry);

        // fetch(API_ENDPOINT + '/users/account/number', {
        //     method: 'GET',
        //     headers: {
        //         'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
        //         'content-type': 'application/json'
        //     }
        // }).then(parseJSON).then(({ result, status }) => {
        //     if (status >= 200 && status < 300)
        //         this.setState({
        //             // languages: result
        //         })
        // })
        if (this.props.invationRegistration && this.props.uData.email) {
            this.setState({ invationRegistration: true }, () => {
                this.props.invationRegistration(false)
            })
        }


    }

    componentDidUpdate(prevState, prevProps) {
        if (this.props.uData && !this.props.selectCountry) {
            this.props.changeSetCountry("Sweden");

        }

        if (this.props.notApplicable) {
            this.props.changeVatNumber('')
        }
        if (this.props.uData && !this.state.companyData && this.props.uData.companyID) {
            fetch(API_ENDPOINT + "/users/account/company-data/" + this.props.uData.companyID, {
                method: "GET",
                headers: {
                    "content-type": "application/json",
                    Authorization: typeof localStorage !== "undefined" ? `Bearer ${localStorage.getItem("authToken")}` : null,
                },
            }).then((res) => res.json()).then((result) => {
                // console.log(result);
                if (result) {
                    this.setState({
                        companyData: result
                    })
                }

            });
        }
    }

    /**
    * Update patient's profile
    * @author Milan Stanojevic
    */
    update = (data) => {
        // form is not updated
        data.language = this.props.uData.language
        // if (this.state.privatePersonProfile) {
        //     data.profileType = 'personal'
        // } else if (this.state.companyProfile) {
        if (this.props.uData.profileType != 'personal') {
            data.profileType = 'company'
        }

        // }
        fetch(API_ENDPOINT + '/users/account/update', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(parseJSON).then(({ result, status }) => {
            if (result.error) {
                this.setState({ error: result.error.translate(this.props.lang) })
            } else {
                this.props.closePatientAccountModal()
                if (result.logoutAndVerify) {
                    this.setState({
                        registrationSuccessful: true
                    })
                } else {
                    this.setState({
                        message: result.message
                    })

                    this.props.verifyUser();
                    window.location.reload()

                }

                //this.props.toggle();
            }

        })


    }

    updateLanguage = (data) => {
        this.setState(
            {
                loadLang: true
            },
            () => {
                fetch(API_ENDPOINT + "/users/account/update-userdata", {
                    method: "POST",
                    headers: {
                        Authorization:
                            typeof localStorage !== "undefined"
                                ? `Bearer ${localStorage.getItem("authToken")}`
                                : null,
                        "content-type": "application/json",
                    },
                    body: JSON.stringify(data),
                })
                    .then(parseJSON)
                    .then(({ result, status }) => {
                        if (result) {
                            this.props.verifyUser()


                        }
                    });
            }
        );
    };

    render() {

        // console.log(this.props.selectCountry);
        return (
            <div>


                <Modal isOpen={this.props.patientAccountModal} centered zIndex={1060}>

                    <ModalHeader style={{ display: 'block', width: '100%' }}>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                            <p style={{ width: '50%', marginBottom: 0 }}>{'My profile'.translate(this.props.lang)}</p>
                            <select
                                style={{ width: '30%' }}
                                className='profile-modal-select'
                                value={this.props.uData && this.props.uData.language}
                                onChange={(e) => {
                                    this.updateLanguage({ language: e.target.value });

                                }}
                            >

                                {this.state.languages &&
                                    this.state.languages.map((item, idx) => {
                                        return (
                                            <option key={idx} value={item._id}>
                                                {item.name}
                                            </option>
                                        );
                                    })}

                            </select>
                        </div>



                    </ModalHeader>
                    <ModalBody className="bankid-modal toggle-password-fix">
                        {/* <div>
                            <p>
                                {'I want to create my profile as'.translate(this.props.lang)}
                            </p>
                            <div style={{ display: 'flex', marginBottom: 30 }}>
                                <div style={{ marginRight: 15 }}>
                                    <Checkbox
                                        onChange={(e) => {
                                            this.setState({
                                                privatePersonProfile: true,
                                                companyProfile: false
                                            })
                                        }}
                                        checked={this.state.privatePersonProfile}
                                        label={'Private person'.translate(this.props.lang)}
                                    />
                                </div>

                                <Checkbox
                                    onChange={(e) => {
                                        this.setState({
                                            companyProfile: true,
                                            privatePersonProfile: false
                                        })
                                    }}
                                    checked={this.state.companyProfile}
                                    label={'Company'.translate(this.props.lang)}
                                />
                            </div>

                        </div> */}

                        {

                            this.props.uData && this.props.uData.profileType == 'personal' && this.state.companyData ?
                                <FormBuilder dateFormat={this.props.dateFormat}
                                    lang={this.props.lang}
                                    onSubmit={(data) => this.update(data)}
                                    initialValues={{ ...this.props.uData, timezone: 'Europe/Amsterdam', companyData: this.state.companyData }}
                                    toggle={() => this.setState({ form: null })}
                                    buttonText={'Save'.translate(this.props.lang).translate(this.props.lang)}
                                    additionalButton={

                                        <Button style={{ marginLeft: 10 }} color="danger" onClick={() => this.props.signOut()}>{'Logout'.translate(this.props.lang)}</Button>
                                    }
                                    fields={[
                                        {
                                            type: 'row',
                                            children: [
                                                {
                                                    type: 'col',
                                                    width: {
                                                        lg: 12,
                                                        sm: 12,
                                                        xs: 12
                                                    },
                                                    children: [
                                                        {
                                                            type: 'text',
                                                            // disabled: true,
                                                            autocomplete: "new-password",
                                                            name: 'socialSecurityNumber',
                                                            label: 'Social security number'.translate(this.props.lang),
                                                            // validate: [required('Social security number is required!'.translate(this.props.lang))]

                                                        },
                                                    ]
                                                },

                                                {
                                                    type: 'col',
                                                    width: {
                                                        lg: 3,
                                                        sm: 12,
                                                        xs: 12
                                                    },
                                                    children: [
                                                        {
                                                            type: 'text',
                                                            autocomplete: "new-password",
                                                            name: 'userData.title',
                                                            label: 'Title'.translate(this.props.lang),
                                                            // disabled: this.props.alias
                                                        },
                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    width: {
                                                        lg: 9,
                                                        sm: 12,
                                                        xs: 12
                                                    },
                                                    children: [
                                                        {
                                                            type: 'text',
                                                            autocomplete: "new-password",
                                                            name: 'userData.name',
                                                            label: 'First and last name'.translate(this.props.lang),
                                                            disabled: this.props.alias,
                                                            validate: [required('First and last name is required!'.translate(this.props.lang))]

                                                        },
                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    width: {
                                                        lg: 12,
                                                        sm: 12,
                                                        xs: 12
                                                    },
                                                    children: [
                                                        {
                                                            type: 'email',
                                                            autocomplete: "new-password",
                                                            name: 'email',
                                                            disabled: this.state.invationRegistration,
                                                            label: 'Email address'.translate(this.props.lang),
                                                            validate: [required('Email address is required!'.translate(this.props.lang)), emailAddress('Invalid email address'.translate(this.props.lang))]
                                                        },
                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    width: {
                                                        lg: 12,
                                                        sm: 12,
                                                        xs: 12
                                                    },
                                                    children: [
                                                        {
                                                            type: 'phone',
                                                            autocomplete: "new-password",
                                                            name: 'phone',
                                                            label: 'Phone number'.translate(this.props.lang),
                                                            // validate: [required('Phone number is required!'.translate(this.props.lang)), phoneNumber(9, 'Phone number must be 9 numbers or more.'.translate(this.props.lang))]
                                                        },
                                                    ]
                                                },
                                                {

                                                    type: "col",
                                                    width: {
                                                        lg: 12,
                                                        sm: 12,
                                                        xs: 12,
                                                    },
                                                    children: [
                                                        {
                                                            type: "timeZonePicker",
                                                            autocomplete: "new-password",
                                                            name: "timezone",
                                                            label: "Select time zone".translate(this.props.lang),
                                                            validate: [required('Timezone is required!'.translate(this.props.lang))]
                                                        },
                                                    ],

                                                },
                                                {
                                                    type: 'col',
                                                    width: {
                                                        lg: 12,
                                                        sm: 12,
                                                        xs: 12
                                                    },
                                                    children: [
                                                        {
                                                            type: 'text',
                                                            autocomplete: "new-password",
                                                            name: 'street',
                                                            label: 'Street'.translate(this.props.lang),
                                                            // validate: [required('Street is required!'.translate(this.props.lang))]
                                                        },
                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    width: {
                                                        lg: 3,
                                                        sm: 12,
                                                        xs: 12
                                                    },
                                                    children: [
                                                        {
                                                            type: 'text',
                                                            autocomplete: "new-password",
                                                            name: 'zip',
                                                            pattern: new RegExp(/[0-9 ]+/i),
                                                            label: 'Postal code'.translate(this.props.lang),
                                                            // validate: [required('Postal code is required!'.translate(this.props.lang))]
                                                        },
                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    width: {
                                                        lg: 9,
                                                        sm: 12,
                                                        xs: 12
                                                    },
                                                    children: [
                                                        {
                                                            type: 'text',
                                                            autocomplete: "new-password",
                                                            name: 'city',
                                                            pattern: new RegExp(/^[a-zA-Z \u00c0-\u017e]$/i),
                                                            label: 'City'.translate(this.props.lang),
                                                            // validate: [required('City is required!'.translate(this.props.lang))]
                                                        },
                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    width: {
                                                        lg: 12,
                                                        sm: 12,
                                                        xs: 12
                                                    },
                                                    children: [
                                                        {
                                                            type: 'text',
                                                            name: 'companyData.userData.companyName',
                                                            label: 'Company name'.translate(this.props.lang),
                                                            disabled: true
                                                            // validate: [required('City is required!'.translate(this.props.lang))]
                                                        },
                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    width: {
                                                        lg: 12,
                                                        sm: 12,
                                                        xs: 12
                                                    },
                                                    children: [
                                                        {
                                                            type: 'text',
                                                            name: 'companyData.userData.vatNumber',
                                                            label: 'VAT Number'.translate(this.props.lang),
                                                            disabled: true
                                                        },
                                                    ]
                                                },

                                                {
                                                    type: 'col',
                                                    width: {
                                                        lg: 12,
                                                        sm: 12,
                                                        xs: 12
                                                    },
                                                    children: [
                                                        {
                                                            type: 'text',
                                                            name: 'companyData.userData.orgNr',
                                                            label: 'Org.Nr'.translate(this.props.lang),
                                                            disabled: true
                                                        },
                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    width: {
                                                        lg: 12,
                                                        sm: 12,
                                                        xs: 12
                                                    },
                                                    children: [
                                                        {
                                                            type: 'phone',
                                                            name: 'companyData.phone',
                                                            label: 'Phone number'.translate(this.props.lang),
                                                            disabled: true

                                                        },
                                                    ]
                                                },
                                                {

                                                    type: "col",
                                                    width: {
                                                        lg: 12,
                                                        sm: 12,
                                                        xs: 12,
                                                    },
                                                    children: [
                                                        {
                                                            type: "timeZonePicker",
                                                            autocomplete: "new-password",
                                                            name: "companyData.timezone",
                                                            label: "Select time zone".translate(this.props.lang),
                                                            disabled: true
                                                        },
                                                    ],

                                                },

                                                this.props.uData.pk == '' ?
                                                    {
                                                        type: "col",
                                                        width: {
                                                            lg: 12,
                                                            sm: 12,
                                                            xs: 12,
                                                        },
                                                        children: [
                                                            {
                                                                type: "checkbox",
                                                                autocomplete: "new-password",
                                                                name: "checkbox",
                                                                label: <>{'Accept the'.translate(this.props.lang)} <span><Link lang={this.props.lang} target='_blank' to="/termsandconditions">{'terms and conditions'.translate(this.props.lang)}</Link> </span> {'and'.translate(this.props.lang)} <span><Link lang={this.props.lang} target='_blank' to="/integritetspolicy">{'privacy policy'.translate(this.props.lang)}</Link></span></>,
                                                                validate: [required("Privacy policy required!".translate(this.props.lang))],
                                                            },
                                                        ],
                                                    } : null,
                                                this.props.uData.pk == '' ?
                                                    {
                                                        type: "col",
                                                        width: {
                                                            lg: 12,
                                                            sm: 12,
                                                            xs: 12,
                                                        },
                                                        children: [
                                                            {
                                                                type: "password",
                                                                autocomplete: "new-password",
                                                                name: "password",
                                                                // pattern: new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$"),
                                                                label: "Password".translate(this.props.lang),
                                                                validate: [required("Password is required!".translate(this.props.lang)), validateStrongPassword('Minimum 10 characters, at least one uppercase letter, one lowercase letter, one number and one special character:'.translate(this.props.lang))],
                                                            },
                                                        ],
                                                    } : null,
                                                this.props.uData.pk == '' ?

                                                    {
                                                        type: "col",
                                                        width: {
                                                            lg: 12,
                                                            sm: 12,
                                                            xs: 12,
                                                        },
                                                        children: [
                                                            {
                                                                type: "password",
                                                                autocomplete: "new-password",
                                                                name: "repeatPassword",
                                                                // pattern: new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$"),
                                                                label: "Repeat password".translate(this.props.lang),
                                                                validate: [required("Repeat password is required!".translate(this.props.lang)), validateStrongPassword('Minimum 10 characters, at least one uppercase letter, one lowercase letter, one number and one special character:'.translate(this.props.lang))],
                                                            },
                                                        ],
                                                    } : null,


                                            ]
                                        }
                                    ]}
                                ></FormBuilder>


                                :
                                this.props.uData.profileType != 'personal' ?
                                    <FormBuilder dateFormat={this.props.dateFormat}
                                        lang={this.props.lang}
                                        onSubmit={(data) => this.update(data)}
                                        initialValues={{ ...this.props.uData, timezone: 'Europe/Amsterdam' }}
                                        toggle={() => this.setState({ form: null })}
                                        buttonText={'Save'.translate(this.props.lang).translate(this.props.lang)}
                                        additionalButton={

                                            <Button style={{ marginLeft: 10 }} color="danger" onClick={() => this.props.signOut()}>{'Logout'.translate(this.props.lang)}</Button>
                                        }
                                        fields={[
                                            {
                                                type: 'row',
                                                children: [
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 12,
                                                            sm: 12,
                                                            xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'text',
                                                                autocomplete: "new-password",
                                                                name: 'userData.companyName',
                                                                label: 'Company name'.translate(this.props.lang),
                                                                disabled: this.props.alias,
                                                                validate: [required('Company name is required!'.translate(this.props.lang))]

                                                            }
                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 12,
                                                            sm: 12,
                                                            xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: "selectCountryOption",
                                                                name: "userData.country",
                                                                autoComplete: 'new-password',
                                                                label: 'Country'.translate(this.props.lang),
                                                                validate: [required('Country is required!'.translate(this.props.lang))],
                                                                lang: this.props.lang
                                                            },
                                                        ]
                                                    },

                                                    this.props.selectCountry && this.props.selectCountry == 'Other' ?
                                                        {
                                                            type: 'col',
                                                            width: {
                                                                lg: 12,
                                                                sm: 12,
                                                                xs: 12
                                                            },
                                                            children: [
                                                                {
                                                                    type: "text",
                                                                    name: "userData.otherCountry",
                                                                    autoComplete: 'new-password',
                                                                    label: 'Other country'.translate(this.props.lang),
                                                                    validate: [required('Country is required!'.translate(this.props.lang))]
                                                                },
                                                            ]
                                                        }

                                                        : null,
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 12,
                                                            sm: 12,
                                                            xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'text',
                                                                autocomplete: "new-password",
                                                                name: 'userData.name',
                                                                label: 'Your name'.translate(this.props.lang),
                                                                labelInfo: "Your name is displayed in video meetings".translate(this.props.lang),
                                                                disabled: this.props.alias,
                                                                validate: [required('Your name is required!'.translate(this.props.lang))]

                                                            },
                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 6,
                                                            sm: 12,
                                                            xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'text',
                                                                autocomplete: "new-password",
                                                                name: 'userData.vatNumber',
                                                                disabled: this.props.notApplicable ? true : false,
                                                                hideValidation: this.props.selectCountry && this.props.selectCountry != 'Sweden' && !this.props.notApplicable ? false : true,
                                                                label: 'VAT Number'.translate(this.props.lang),
                                                                labelInfo: this.props.selectCountry && this.props.selectCountry != 'Sweden' ? "Your tax registration number".translate(this.props.lang) : '',
                                                                validate: this.props.selectCountry && this.props.selectCountry != 'Sweden' && !this.props.notApplicable ? [required('VAT Number is required!'.translate(this.props.lang)), vatNumberValidation('Vat Number is not valid'.translate(this.props.lang))] : [vatNumberValidation('Vat Number is not valid'.translate(this.props.lang))]

                                                            },
                                                            this.props.selectCountry && this.props.selectCountry != 'Sweden' ?
                                                                {
                                                                    type: 'checkbox',
                                                                    label: 'Not applicable'.translate(this.props.lang),
                                                                    name: 'userData.notApplicable',
                                                                }
                                                                : null
                                                        ]
                                                    },

                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 6,
                                                            sm: 12,
                                                            xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'text',
                                                                autocomplete: "new-password",
                                                                name: 'userData.orgNr',
                                                                label: 'Org.Nr'.translate(this.props.lang),
                                                                validate: this.props.selectCountry && this.props.selectCountry == 'Sweden' ? [required('Org.Nr is required!'.translate(this.props.lang))] : ''
                                                            },
                                                        ]
                                                    },

                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 12,
                                                            sm: 12,
                                                            xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'email',
                                                                autocomplete: "new-password",
                                                                name: 'email',
                                                                disabled: this.state.invationRegistration,
                                                                label: 'Email address'.translate(this.props.lang),
                                                                validate: [required('Email address is required!'.translate(this.props.lang)), emailAddress('Invalid email address'.translate(this.props.lang))]
                                                            },
                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 12,
                                                            sm: 12,
                                                            xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'phone',
                                                                autocomplete: "new-password",
                                                                name: 'phone',
                                                                label: 'Phone number'.translate(this.props.lang),
                                                                // validate: [required('Phone number is required!'.translate(this.props.lang)), phoneNumber(9, 'Phone number must be 9 numbers or more.'.translate(this.props.lang))]
                                                            },
                                                        ]
                                                    },
                                                    {

                                                        type: "col",
                                                        width: {
                                                            lg: 12,
                                                            sm: 12,
                                                            xs: 12,
                                                        },
                                                        children: [
                                                            {
                                                                type: "timeZonePicker",
                                                                autocomplete: "new-password",
                                                                name: "timezone",
                                                                label: "Select time zone".translate(this.props.lang),
                                                                validate: [required('Timezone is required!'.translate(this.props.lang))]
                                                            },
                                                        ],

                                                    },
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 12,
                                                            sm: 12,
                                                            xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'text',
                                                                autocomplete: "new-password",
                                                                name: 'street',
                                                                label: 'Street'.translate(this.props.lang),
                                                                validate: [required('Street is required!'.translate(this.props.lang))]
                                                            },
                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 3,
                                                            sm: 12,
                                                            xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'text',
                                                                autocomplete: "new-password",
                                                                name: 'zip',
                                                                pattern: new RegExp(/[0-9 ]+/i),
                                                                label: 'Postal code'.translate(this.props.lang),
                                                                validate: [required('Postal code is required!'.translate(this.props.lang))]
                                                            },
                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 9,
                                                            sm: 12,
                                                            xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'text',
                                                                autocomplete: "new-password",
                                                                name: 'city',
                                                                pattern: new RegExp(/^[a-zA-Z \u00c0-\u017e]$/i),
                                                                label: 'City'.translate(this.props.lang),
                                                                validate: [required('City is required!'.translate(this.props.lang))]
                                                            },
                                                        ]
                                                    },
                                                    this.props.uData.pk == '' ?
                                                        {
                                                            type: "col",
                                                            width: {
                                                                lg: 12,
                                                                sm: 12,
                                                                xs: 12,
                                                            },
                                                            children: [
                                                                {
                                                                    type: "checkbox",
                                                                    autocomplete: "new-password",
                                                                    name: "checkbox",
                                                                    label: <>{'Accept the'.translate(this.props.lang)} <span><Link lang={this.props.lang} target='_blank' to="/termsandconditions">{'terms and conditions'.translate(this.props.lang)}</Link> </span> {'and'.translate(this.props.lang)} <span><Link lang={this.props.lang} target='_blank' to="/integritetspolicy">{'privacy policy'.translate(this.props.lang)}</Link></span></>,
                                                                    validate: [required("Privacy policy required!".translate(this.props.lang))],
                                                                },
                                                            ],
                                                        } : null,
                                                    this.props.uData.pk == '' ?
                                                        {
                                                            type: "col",
                                                            width: {
                                                                lg: 12,
                                                                sm: 12,
                                                                xs: 12,
                                                            },
                                                            children: [
                                                                {
                                                                    type: "password",
                                                                    autocomplete: "new-password",
                                                                    name: "password",
                                                                    // pattern: new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$"),
                                                                    label: "Password".translate(this.props.lang),
                                                                    validate: [required("Password is required!".translate(this.props.lang)), validateStrongPassword('Minimum 10 characters, at least one uppercase letter, one lowercase letter, one number and one special character:'.translate(this.props.lang))],
                                                                },
                                                            ],
                                                        } : null,
                                                    this.props.uData.pk == '' ?

                                                        {
                                                            type: "col",
                                                            width: {
                                                                lg: 12,
                                                                sm: 12,
                                                                xs: 12,
                                                            },
                                                            children: [
                                                                {
                                                                    type: "password",
                                                                    autocomplete: "new-password",
                                                                    name: "repeatPassword",
                                                                    // pattern: new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$"),
                                                                    label: "Repeat password".translate(this.props.lang),
                                                                    validate: [required("Repeat password is required!".translate(this.props.lang)), validateStrongPassword('Minimum 10 characters, at least one uppercase letter, one lowercase letter, one number and one special character:'.translate(this.props.lang))],
                                                                },
                                                            ],
                                                        } : null,


                                                ]
                                            }
                                        ]}
                                    ></FormBuilder>
                                    :
                                    null


                        }
                        <p style={{ color: 'red' }} className="error-message">{this.state.error}</p>
                    </ModalBody>
                </Modal>
                {
                    this.state.registrationSuccessful ?
                        <InfoModal
                            isOpen={this.state.registrationSuccessful}
                            // toggle={() => this.setState({ registrationSuccessful: !this.state.registrationSuccessful })}
                            // image={Brev}
                            header={'Info'.translate(this.props.lang)}
                            info={'Check your email and verify your account.'.translate(this.props.lang)}
                            buttons={[
                                <Button color='primary' onClick={() => {
                                    this.setState({
                                        registrationSuccessful: null
                                    }, () => {
                                        this.props.signOut()
                                    })
                                }}>{'Ok'.translate(this.props.lang)}</Button>,
                            ]}

                        />
                        :
                        null
                }
            </div>
        )
    }
}

const selector = formValueSelector('form')
export default connect(
    (state) => {
        return {
            selectCountry: selector(state, 'userData.country'),
            notApplicable: selector(state, 'userData.notApplicable'),
            vatNumber: selector(state, 'userData.vatNumber'),
        };
    },
    {
        changeVatNumber: (value) => change("form", "userData.vatNumber", value),
        changeSetCountry: (value) => change("form", "userData.country", value),
    }
)(PatientAccountModal);
