import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Routes from './routes'
import { Provider } from 'react-redux'
import { createStore, combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { withRouter } from 'react-router-dom';
import DocumentMeta from 'react-document-meta';

import { API_ENDPOINT } from './constants';
import socketIO from 'socket.io-client';
import incomingCall from './assets/sound/incoming_call.mp3';
import emergency from './assets/sound/emergency.mp3';
import call from './assets/svg/call.svg';
import profilePicture from './assets/images/user.png';
import Isvg from 'react-inlinesvg';
import { Button } from 'reactstrap';
import ErrorModal from './components/errorModal';

import { matchPath } from 'react-router-dom';
import { routes } from './routesList';
import { SENTRY_DSN } from './constants';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
var browserInfo = require('browser-info');

const APP_VERSION = '1.0.7';

const rootReducer = combineReducers({
  form: formReducer
});

const LOGOUT_TIMEOUTV2 = 20 * 59; //ms

const store = createStore(rootReducer)
Number.prototype.formatPrice = function (sep = 2) {
  let dec_point = '.';
  let thousands_sep = ',';

  var parts = parseFloat(this).toFixed(sep).split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_sep);

  return parts.join(dec_point);
}

String.prototype.formatPrice = function (sep = 2) {
  let dec_point = '.';
  let thousands_sep = ',';

  var parts = parseFloat(this).toFixed(sep).split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_sep);

  return parts.join(dec_point);
}




function generateAlias(str) {
  str = str.toLowerCase();
  str = str.replace(/ä/g, 'a');
  str = str.replace(/ö/g, 'o');
  str = str.replace(/ü/g, 'u');
  str = str.replace(/ß/g, 'b');

  str = str.replace(/[^a-zA-Z0-9]/gi, '-').toLowerCase()
  str = str.replace(/-+/g, '-');

  return str;
}
//web notifications
function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

const publicVapidKey = 'BAhhWYormQopBNXSFIrv73BSUFEv3enfourjBWuYYG1o_5ZPYmA_0HarJBobZQDuyy_qtaz2Z1sZ8wBRAe_PevU';
//ent web notifications 

if (String.prototype.generateAlias == null) {
  String.prototype.generateAlias = function () {
    return generateAlias(this);
  }
}


Object.translate = function (o, s, lang) {
  if (!o) {
    return '';
  }

  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, '');           // strip a leading dot
  var a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o[lang] ? o[lang] : o['en'];
}


Object.get = function (o, s) {
  if (!o) {
    return null;
  }

  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, '');           // strip a leading dot
  var a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
}


if (String.prototype.translate == null) {
  String.prototype.translate = function (lang, initLangs = null) {

    let langs = typeof window !== 'undefined' ? window.langs : initLangs ? initLangs : null;
    // console.log(langs)
    if (!langs) {
      return this;
    }

    if (langs[lang] && langs[lang][this]) {
      return langs[lang][this];
    }
    else return this;
  }
}



class App extends Component {
  constructor(props) {
    super(props);
    this.translate = this.translate.bind(this);
    this.setLang = this.setLang.bind(this);
    this.setLightMode = this.setLightMode.bind(this);
    this.allowCookies = this.allowCookies.bind(this);
    this.allowCookiesLogin = this.allowCookiesLogin.bind(this);


    /*if (typeof window !== 'undefined')
      window.lang = 'se';*/


    if (typeof window !== 'undefined' && navigator.userAgent.toLowerCase().indexOf('firefox') == -1) {
      let getUserMedia = typeof navigator !== 'undefined' ? navigator.mediaDevices.getUserMedia : null;

      navigator.mediaDevices.getUserMedia = (constraints) => {
        if (!window._webRTCStreams) {
          window._webRTCStreams = [];
        }

        for (let i = 0; i < window._webRTCStreams.length; i++) {
          if (window._webRTCStreams[i]) {
            try {
              window._webRTCStreams[i].getTracks().forEach(track => track.stop());
              window._webRTCStreams[i] = null;
            } catch (e) { console.log(e) }
          }
        }

        return new Promise((resolve, reject) => {
          getUserMedia(constraints).then(stream => {
            window._webRTCStreams.push(stream);

            resolve(stream);

          }).catch((err) => {
            if (constraints && constraints.video && constraints.video.facingMode && constraints.video.facingMode.exact && constraints.video.facingMode.exact == "environment") {
              console.log('Trying to find back camera...')
              for (let i = 0; i < window._webRTCStreams.length; i++) {
                if (window._webRTCStreams[i]) {
                  try {
                    window._webRTCStreams[i].getTracks().forEach(track => track.stop());
                    window._webRTCStreams[i] = null;
                  } catch (e) { console.log(e) }
                }
              }

              try {
                navigator.mediaDevices.enumerateDevices().then(async (devices) => {
                  let backCameras = [];
                  for (let i = 0; i < devices.length; i++) {
                    if (devices[i].kind == "videoinput" && /back|rear|environment/gi.test(devices[i].label)) {
                      console.log('Found back camera', devices[i]);
                      try {
                        constraints.video = { deviceId: devices[i].deviceId };
                        let stream = await getUserMedia(constraints);
                        window._webRTCStreams.push(stream);
                        resolve(stream);
                        return;
                      } catch (e) { console.log('not working...') }
                    }
                  }

                  reject('error back camera not found');

                })
              } catch (err) { reject(err) }

            } else {
              reject(err);
            }
          });
        })
      }
    }

    this.state = {
      lang: 'se',
      lightMode: 0,
      services: [],
      latestNews: [],
      modulesTree: [],
      availablePaths: [],
      ...props.appInitialData,
      linksMeta: {},
      userVerificationInProgress: true,
      dateFormat: 'YYYY-MM-DD',
      sidebarWide: false,
      videoCallX: 0,
      videoCallY: 0,
      logoutMessage: false,
      referralStatusBefore: false,
      lastNotifications: [],
      newNotif: false,
      groupSettingsModal: false,
      // participantName: '',
      changeLinkModal: false,
      actionLink: false,
      _toManyRequests: null,
      cookies: false,
      cookiesLoginRegistration: false,
      redLinePopUp: false
    };

  }


  detectAutomatedTool = () => {
    return false
    var documentDetectionKeys = [
      "__webdriver_evaluate",
      "__selenium_evaluate",
      "__webdriver_script_function",
      "__webdriver_script_func",
      "__webdriver_script_fn",
      "__fxdriver_evaluate",
      "__driver_unwrapped",
      "__webdriver_unwrapped",
      "__driver_evaluate",
      "__selenium_unwrapped",
      "__fxdriver_unwrapped",
    ];

    var windowDetectionKeys = [
      "_phantom",
      "__nightmare",
      "_selenium",
      "callPhantom",
      "callSelenium",
      "_Selenium_IDE_Recorder",
    ];

    for (const windowDetectionKey in windowDetectionKeys) {
      const windowDetectionKeyValue = windowDetectionKeys[windowDetectionKey];
      if (window[windowDetectionKeyValue]) {
        return true;
      }
    };
    for (const documentDetectionKey in documentDetectionKeys) {
      const documentDetectionKeyValue = documentDetectionKeys[documentDetectionKey];
      if (window['document'][documentDetectionKeyValue]) {
        return true;
      }
    };

    for (const documentKey in window['document']) {
      if (documentKey.match(/\$[a-z]dc_/) && window['document'][documentKey]['cache_']) {
        return true;
      }
    }

    if (window['external'] && window['external'].toString() && (window['external'].toString()['indexOf']('Sequentum') != -1)) return true;

    if (window['document']['documentElement']['getAttribute']('selenium')) return true;
    if (window['document']['documentElement']['getAttribute']('webdriver')) return true;
    if (window['document']['documentElement']['getAttribute']('driver')) return true;
    if (!window['navigator']) return true;
    if (window['navigator']['webdriver']) return true;

    return false;
  };


  toggleSidebar = () => {
    const currentState = this.state.sidebarWide
    this.setState({ sidebarWide: !currentState })
  }


  formatName = (title, name) => {
    return this.state.lang == 'se' ? (name ? name : '') + " " + (title ? title : '') : (title ? title : '') + " " + (name ? name : '')
  }


  changeLang = () => {
    let lang = 'se';
    console.log(lang, 'TEST')
    if (this.state.lang == 'se') {
      lang = 'en'
    }

    if (lang) {
      this.setState({
        lang
      }, () => {
        if (typeof window != 'undefined') {
          localStorage.setItem('lang', lang)
          if (lang == 'se') {
            window.location.replace('/')
          } else {
            window.location.replace('/en')
          }
        }
      })
    } else {
      this.setState({
        lang: 'se'
      }, () => {
        if (typeof window != 'undefined') {
          localStorage.setItem('lang', 'se')
        }
      })
    }
  }

  setLang(lang) {
    this.setState({
      lang: lang
    }, () => {
      localStorage.setItem('lang', lang)
    });
  }
  setLightMode(val) {
    this.setState({
      lightMode: val
    });
  }



  translate(text) {
    return text;
  }


  updateMeta = (data) => {
    this.setState({
      metaTags: data
    })
  }

  generateModulesMeta = (tree) => {
    let linksMeta = {};
    for (let i = 0; i < tree.length; i++) {
      linksMeta[tree[i].link] = tree[i].name;
      if (tree[i].modules) {
        for (let j = 0; j < tree[i].modules.length; j++) {
          linksMeta[tree[i].modules[j].link] = tree[i].modules[j].name;
        }
      }
    }
    this.setState({
      linksMeta
    })
  }


  statusBefore = (status) => {
    console.log(status)
    this.setState({
      referralStatusBefore: status
    })
  }

  ring112Audio = (item) => {
    if (!item && this.emergency) {
      try {
        this.emergency.pause();
        this.emergency.currentTime = 0;
      } catch (error) {
        console.log(error)
      }


    }
    if (item) {
      this.emergency = new Audio(emergency);
      this.emergency.loop = true
      this.emergency.play();
    }
  }

  clearStatusBefore = () => {
    this.setState({
      referralStatusBefore: null
    })
  }

  closePatientAccountModal = () => {
    this.setState({
      patientAccountModal: null
    })
  }

  groupSettingsChanged = () => {
    this.setState({
      groupSettingsModal: true
    })
  }

  openModal = (item) => {
    this.setState({
      changeLinkModal: item,
      groupSettingsModal: false,
    }, () => console.log(this.state.changeLinkModal, this.state.groupSettingsModal))
  }


  abortAction = () => {

    this.setState({
      changeLinkModal: null,
      groupSettingsModal: false,
    })
  }

  enableLogoutMessage = () => {
    this.setState({ logoutMessage: true })
  }
  disableLogoutMessage = () => {
    this.setState({ logoutMessage: false })
  }
  verifyUser = (callback = null, setGroupCallback = null, disablePatientCheck = false) => {

    if (!localStorage.getItem('authToken')) {
      this.setState({
        userVerificationInProgress: false
      })
      return;
    }

    if (localStorage.profile) {
      localStorage.removeItem('bankidlogin');

      this.setState({
        selectedProfile: localStorage.getItem('profile')
      });
    }

    if (typeof localStorage && localStorage.getItem('groupAlias') != 'undefined' && localStorage.getItem('groupAlias') != null) {
      let alias = localStorage.getItem('groupAlias')
      let lastGroup = localStorage.getItem('lastGroup')
      let profile = localStorage.getItem('profile')
      let groupId = null;
      let clinicId = null;
      if (this.state.selectedGroup) {
        groupId = this.state.selectedGroup;
      }
      if (this.state.selectedClinic) {
        clinicId = this.state.selectedClinic;
      }

      fetch(API_ENDPOINT + (lastGroup ? `/users/verify?grupa=${alias}&profile=${profile}&groupId=${groupId}&lastGroup=${lastGroup}&clinicId=${clinicId}` : `/users/verify?grupa=${alias}&profile=${profile}&groupId=${groupId}&clinicId=${clinicId}`), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
      }).then(res => {
        return res.json()
      }).then((result) => {

        if (result && result.verificationCodeNeeded) {
          let email = ''
          if (result.email) {
            // email = result.email.split('@')[0] + result.email.split('@')[1].replace(/[^0-9,.]/g, "*")
            email = result.email
          }
          this.props.history.push({
            pathname: '/verification-code',
            state: {
              email: email
            }
          })
          return;
        }

        this.setState({
          userVerificationInProgress: false
        })
        if (!result.error) {

          if (!localStorage.getItem('authToken')) {
            this.setState({
              userVerificationInProgress: false
            })
            return;
          }



          if (localStorage.bankidlogin) {
            localStorage.setItem('profile', 'doctor')
            localStorage.setItem('userLevel', 20);
            localStorage.removeItem('bankidlogin')
          }
          if (localStorage.loginLandingPage) {
            localStorage.setItem('profile', 'patient')
            localStorage.setItem('userLevel', 2);
            localStorage.removeItem('loginLandingPage')

          }

          if (typeof window !== 'undefined')
            window.lang = result.langKey;

          this.setState({
            uData: result,
            lang: result.langKey,
            dateFormat: result.langKey == 'se' ? 'YYYY-MM-DD' : 'MM-DD-YYYY',

          }, () => {
            this.forceUpdate()
            if (callback) {
              callback();
            }

          })

          if (result.hostGroup) {
            this.setState({
              selectedGroup: result.hostGroup
            })
          }

          if (result.hostClinic) {
            this.setState({
              selectedClinic: result.hostClinic
            })
          }

          if (!disablePatientCheck) {

            if (
              (result.userLevel == 20) && (!result.email || !result.timezone) && !result.guest
            ) {
              this.setState({
                patientAccountModal: true
              })
            } else {
              this.setState({
                patientAccountModal: null
              })
            }


            if (!this.socket) {
              this.socket = socketIO(API_ENDPOINT, { transports: ['websocket'], query: { token: localStorage.getItem('authToken') } });
              this.registerSocketEvents();

              this.socket.on('call', async (data) => {
                console.log(data, 'data call');
                if (data.initiatorUid == this.state.uData._id)
                  return;

                this.setState({
                  offerData: data
                }, () => {
                  if (localStorage.getItem('rejectedCalls') && localStorage.getItem('rejectedCalls') == data.conversation) {
                    localStorage.removeItem('rejectedCalls')
                  }
                })
              })

              this.socket.emit('activeVideoCalls', {})



            }
          }

          let doctorPermission;
          if (result && result.clinicModules && result.clinicModules.length) {
            for (let i = 0; i < result.clinicModules.length; i++) {
              if (result.clinicModules[i].name == "Vårdutövare") {
                doctorPermission = result.clinicModules[i]._id;
              }
            }

          }

          let isDoctor = false;
          if (result.groupPermissionsUser && result.groupPermissionsUser[this.state.selectedGroup] && result.groupPermissionsUser[this.state.selectedGroup][this.state.selectedClinic] && result.groupPermissionsUser[this.state.selectedGroup][this.state.selectedClinic].indexOf(doctorPermission) != -1) {
            isDoctor = true;
          }
          let treeIdExists = false;
          if ((result.userLevel == 20 && isDoctor) || (typeof window != 'undefined' && localStorage.getItem('profile') == 'patient')) {
            treeIdExists = true;
          }

          let treeId = 'none';
          if (treeIdExists) {
            treeId = localStorage.getItem('profile');
          }

          fetch(API_ENDPOINT + '/users/modules/tree/' + treeId, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
          }).then(res => {
            return res.json()
          }).then((result) => {
            if (!localStorage.getItem('authToken')) {
              this.setState({
                userVerificationInProgress: false
              })
              return;
            }

            this.generateModulesMeta(result);
            this.setState({
              modulesTree: result
            })
          });

          fetch(API_ENDPOINT + '/users/modules/available-paths', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
          }).then(res => {
            return res.json()
          }).then((result) => {
            if (!localStorage.getItem('authToken')) {
              this.setState({
                userVerificationInProgress: false
              })
              return;
            }

            this.setState({
              availablePaths: result
            })
          });


        } else {
          this.setState({
            uData: null
          }, () => {
            this.forceUpdate()
          })
        }

        fetch(API_ENDPOINT + '/notification/last', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },

        }).then(res => res.json()).then((result) => {
          if (result.seen && typeof window != 'undefined') {
            localStorage.setItem('notif', result._id);
            this.setState({
              newNotif: false
            })
          } else {
            this.setState({
              newNotif: true
            })
          }

        })
      })
    } else {
      let profile = localStorage.getItem('profile')
      let groupId = null;
      let clinicId = null;
      if (this.state.selectedGroup) {
        groupId = this.state.selectedGroup
      }
      if (this.state.selectedClinic) {
        clinicId = this.state.selectedClinic;
      }
      let lastGroup = localStorage.getItem('lastGroup')

      fetch(API_ENDPOINT + (lastGroup ? `/users/verify?profile=${profile}&groupId=${groupId}&lastGroup=${lastGroup}&clinicId=${clinicId}` : `/users/verify?profile=${profile}&groupId=${groupId}&clinicId=${clinicId}`), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
      }).then(res => {
        return res.json()
      }).then((result) => {

        if (result && result.verificationCodeNeeded) {
          let email = ''
          if (result.email) {
            // email = result.email.split('@')[0] + result.email.split('@')[1].replace(/[^0-9,.]/g, "*")
            email = result.email
          }
          this.props.history.push({
            pathname: '/verification-code',
            state: {
              email: email
            }
          })
          return;
        }

        this.setState({
          userVerificationInProgress: false
        })
        if (!result.error) {
          if (!localStorage.getItem('authToken')) {
            this.setState({
              userVerificationInProgress: false
            })
            return;
          }


          if (localStorage.bankidlogin) {
            localStorage.setItem('profile', 'doctor')
            localStorage.setItem('userLevel', 20);
            localStorage.removeItem('bankidlogin')
          }
          if (localStorage.loginLandingPage) {
            localStorage.setItem('profile', 'patient')
            localStorage.setItem('userLevel', 2);
            localStorage.removeItem('loginLandingPage')

          }

          if (typeof window !== 'undefined')
            window.lang = result.langKey;

          this.setState({
            uData: result,
            lang: result.langKey,
            dateFormat: result.langKey == 'se' ? 'YYYY-MM-DD' : 'MM-DD-YYYY',

          }, () => {
            // console.log(this.state.uData)
            this.forceUpdate()
            if (callback) {
              callback();
            }
            // if (!this.socket) {
            //   this.socket = socketIO(API_ENDPOINT, { transports: ['websocket'], query: { token: localStorage.getItem('authToken') } });
            //   this.registerSocketEvents();
            //   this.socket.emit('activeVideoCalls', {})

            // }
          })

          if (result.hostGroup) {
            this.setState({
              selectedGroup: result.hostGroup
            })
          }

          if (result.hostClinic) {
            this.setState({
              selectedClinic: result.hostClinic
            })
          }

          if (!disablePatientCheck) {

            if (result.userLevel == 20 && (!result.userData || !result.email || !result.timezone) && !result.guest) {
              this.setState({
                patientAccountModal: true
              })
            } else {
              this.setState({
                patientAccountModal: null
              })
            }


            if (!this.socket) {
              this.socket = socketIO(API_ENDPOINT, { transports: ['websocket'], query: { token: localStorage.getItem('authToken') } });
              this.registerSocketEvents();

              this.socket.on('call', async (data) => {
                if (data.initiatorUid == this.state.uData._id)
                  return;

                this.setState({
                  offerData: data
                }, () => {
                  if (localStorage.getItem('rejectedCalls') && localStorage.getItem('rejectedCalls') == data.conversation) {
                    localStorage.removeItem('rejectedCalls')
                  }

                })
              })

              this.socket.emit('activeVideoCalls', {})
            }
          }

          let doctorPermission;
          if (result && result.clinicModules && result.clinicModules.length) {
            for (let i = 0; i < result.clinicModules.length; i++) {
              if (result.clinicModules[i].name == "Vårdutövare") {
                doctorPermission = result.clinicModules[i]._id;
              }
            }

          }

          let isDoctor = false;
          if (result.groupPermissionsUser && result.groupPermissionsUser[this.state.selectedGroup] && result.groupPermissionsUser[this.state.selectedGroup][this.state.selectedClinic] && result.groupPermissionsUser[this.state.selectedGroup][this.state.selectedClinic].indexOf(doctorPermission) != -1) {
            isDoctor = true;
          }
          let treeIdExists = false;
          if ((result.userLevel == 20 && isDoctor) || (typeof window != 'undefined' && localStorage.getItem('profile') == 'patient')) {
            treeIdExists = true;
          }
          let treeId = 'none';

          if (treeIdExists) {
            treeId = localStorage.getItem('profile');
          }
          fetch(API_ENDPOINT + '/users/modules/tree/' + treeId, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
          }).then(res => {
            return res.json()
          }).then((result) => {
            if (!localStorage.getItem('authToken')) {
              this.setState({
                userVerificationInProgress: false
              })
              return;
            }

            this.generateModulesMeta(result);
            this.setState({
              modulesTree: result
            })
          });

          fetch(API_ENDPOINT + '/users/modules/available-paths', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
          }).then(res => {
            return res.json()
          }).then((result) => {

            if (!localStorage.getItem('authToken')) {
              this.setState({
                userVerificationInProgress: false
              })
              return;
            }


            this.setState({
              availablePaths: result
            })
          });


        } else {
          this.setState({
            uData: null
          }, () => {
            this.forceUpdate()
          })
        }
      })

    }
  }

  componentWillUnmount() {
    if (this.logoutIntervalV2) {
      clearInterval(this.logoutIntervalV2)
    }
  }

  registerLog = (message) => {
    fetch(API_ENDPOINT + '/data/logs/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      },
      body: JSON.stringify({
        pathname: this.props.location.pathname,
        search: this.props.location.search,
        message: message,
        timestamp: Math.floor(new Date().getTime() / 1000)
      })
    })
  }

  checkToken = () => {

    let profile = localStorage.getItem('profile')
    let groupId = null;
    if (this.state.selectedGroup) {
      groupId = this.state.selectedGroup
    }
    let clinicId = null;
    if (this.state.selectedClinic) {
      clinicId = this.state.selectedGroup
    }

    fetch(API_ENDPOINT + `/users/verify?profile=${profile}&groupId=${groupId}&clinicId=${clinicId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      },
    }).then(res => {
      return res.json()
    }).then((result) => {

      if (result && result.verificationCodeNeeded) {
        let email = ''
        if (result.email) {
          // email = result.email.split('@')[0] + result.email.split('@')[1].replace(/[^0-9,.]/g, "*")
          email = result.email
        }
        this.props.history.push({
          pathname: '/verification-code',
          state: {
            email: email
          }
        })
        return;
      }

      if (this.state.userVerificationInProgress)
        this.setState({
          userVerificationInProgress: false
        })
      if (result.error) {
        if (this.state.uData) {
          this.signOut()
        } else {
          this.setState({
            uData: null
          }, () => {
            setTimeout(() => {

              this.forceUpdate();

            }, 200);
          })
        }

      }
    })

  }

  triggerPushNotification = async () => {
    try {
      if ('serviceWorker' in navigator) {
        const register = await navigator.serviceWorker.register('/serviceWorker.js', {
          scope: '/'
        });

        const subscription = await register.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
        });
        await fetch(API_ENDPOINT + '/subscribe', {
          method: 'POST',
          body: JSON.stringify(subscription),
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'Content-Type': 'application/json',
          },
        });
      } else {
        console.error('Service workers are not supported in this browser');
      }
    } catch (error) {

    }

  }
  changeProfile = (profile) => {
    if (profile == null) {
      localStorage.removeItem('profile')
    } else {
      localStorage.setItem('profile', profile)
    }

    this.setState({
      selectedProfile: profile
    }, () => {

      let doctorPermission;
      if (this.state.uData && this.state.uData.clinicModules && this.state.uData.clinicModules.length) {
        for (let i = 0; i < this.state.uData.clinicModules.length; i++) {
          if (this.state.uData.clinicModules[i].name == "Vårdutövare") {
            doctorPermission = this.state.uData.clinicModules[i]._id;
          }
        }
      }

      let isDoctor = false;
      if (this.state.uData.groupPermissionsUser && this.state.uData.groupPermissionsUser[this.state.selectedGroup] && this.state.uData.groupPermissionsUser[this.state.selectedGroup][this.state.selectedClinic] && this.state.uData.groupPermissionsUser[this.state.selectedGroup][this.state.selectedClinic].indexOf(doctorPermission) != -1) {
        isDoctor = true;
      }
      let treeIdExists = false;
      if ((result.userLevel == 20 && isDoctor) || (typeof window != 'undefined' && localStorage.getItem('profile') == 'patient')) {
        treeIdExists = true;
      }
      let treeId = 'none';

      if (treeIdExists) {
        treeId = this.state.selectedProfile;
      }

      fetch(API_ENDPOINT + '/users/modules/tree/' + treeId, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
      }).then(res => {
        return res.json()
      }).then((result) => {
        this.generateModulesMeta(result);
        this.setState({
          modulesTree: result
        })
      });

      fetch(API_ENDPOINT + '/users/modules/available-paths', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
      }).then(res => {
        return res.json()
      }).then((result) => {
        this.setState({
          availablePaths: result
        })
      });
    })
  }


  changeGroup = (group) => {
    if (group == null) {
      localStorage.removeItem('group')
    } else {
      localStorage.setItem('group', group)
    }

    this.setState({
      selectedGroup: group
    }, () => {
      this.verifyUser()
      let location = this.props.location
      if (location.pathname && this.state.selectedGroup) {
        let links = {};
        for (let i = 0; i < this.state.modulesTree.length; i++) {
          links[this.state.modulesTree[i].link] = this.state.modulesTree[i];
          if (this.state.modulesTree[i].modules) {
            for (let j = 0; j < this.state.modulesTree[i].modules.length; j++) {
              links[this.state.modulesTree[i].modules[j].link] = this.state.modulesTree[i].modules[j];
            }
          }
        }

        if (links[location.pathname]) {

          let selectedGroupIdx = 0;
          if (this.state.selectedGroup && this.state.uData.clinicGroups) {
            for (let i = 0; i < this.state.uData.clinicGroups.length; i++) {
              if (this.state.uData.clinicGroups[i]._id == this.state.selectedGroup) {
                selectedGroupIdx = i;
                break;
              }
            }
          }


          let sub = links[location.pathname];

          if ((!sub.clinicEntryModule || (sub.clinicEntryModule && this.state.uData && this.state.uData.groupPermissions && this.state.uData.groupPermissions[this.state.selectedGroup] && this.state.uData.groupPermissions[this.state.selectedGroup][this.state.selectedClinic] && this.state.uData.groupPermissions[this.state.selectedGroup][this.state.selectedClinic].indexOf(sub._id) !== -1) &&
            (!sub.clinicEntryModuleCondition ||
              (
                sub.clinicEntryModuleCondition &&
                this.state.uData &&
                this.state.uData.clinicGroups &&
                this.state.uData.clinicGroups[selectedGroupIdx] &&
                //this.props.uData.clinicGroups[selectedGroupIdx][sub.clinicEntryModuleCondition]
                this.clinicEntryModuleCondition(this.state.uData.clinicGroups[selectedGroupIdx], sub.clinicEntryModuleCondition)
              )
            )) && (!sub.uDataCondition || (sub.uDataCondition && this.state.uData && this.uDataCondition(sub.uDataCondition)))) {

          } else {
            if (this.state.modulesTree[0].modules && this.state.modulesTree[0].modules[0]) {
              this.props.history.push(this.state.modulesTree[0].modules[0].link);
            } else {
              this.props.history.push(this.state.modulesTree[0].link);
            }
          }
        }


      }
    })

    if (this.state.uData && this.state.uData.hostClinic) {
      this.setState({
        selectedClinic: this.state.uData.hostClinic
      })
    }
  }

  getSelectedGroup = () => {
    if (this.state.selectedGroup && this.state.uData.clinicGroups) {
      for (let i = 0; i < this.state.uData.clinicGroups.length; i++) {
        if (this.state.uData.clinicGroups[i]._id == this.state.selectedGroup) {
          return this.state.uData.clinicGroups[i];

        }
      }
    }

  }

  getSelectedClinic = () => {
    let group = this.getSelectedGroup();
    if (group && group.clinics) {
      for (let i = 0; i < group.clinics.length; i++) {
        if (group.clinics[i]._id == this.state.selectedClinic) {
          return group.clinics[i];
        }
      }
    }

  }

  setGroupAlias = (alias) => {
    this.setState({
      groupAlias: alias
    })
  }

  redLinePopUp = () => {
    this.setState({
      redLinePopUp: true
    })
    setTimeout(() => {
      this.setState({
        redLinePopUp: false
      })


    }, 300);
  }


  signOut = (disabledRedirect = false) => {

    fetch(API_ENDPOINT + '/users/logout', {
      method: 'POST',
      headers: {
        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        token: localStorage.getItem('authToken')
      })
    }).then(res => res.json()).then((result) => {


    })


    this.setState({ sidebarWide: false })
    let alias = localStorage.groupAlias;
    let logoutLanding = localStorage.logoutLandingPage;
    let userLevel;
    if (this.state.uData && this.state.uData.userLevel) {
      userLevel = this.state.uData.userLevel;
    } else {
      userLevel = localStorage.getItem('userLevel');
    }

    if (localStorage.getItem('loginGroupAlias')) {
      alias = localStorage.getItem('loginGroupAlias');
    }
    let profile = localStorage.profile;
    localStorage.removeItem('authToken');
    localStorage.removeItem('groupAlias');
    localStorage.removeItem('group');
    localStorage.removeItem('time')
    localStorage.removeItem('doctorList')
    localStorage.removeItem('profile');
    localStorage.removeItem('bankidlogin');
    localStorage.removeItem('loginLandingPage');
    localStorage.removeItem('logoutLandingPage');
    localStorage.removeItem('loginGroupAlias');
    localStorage.removeItem('userLevel');
    localStorage.removeItem('rejectedCalls')

    if (this.socket && !this.isSocketForGuestVideoCall) {
      this.socket.disconnect();
      this.socket = null;
    }
    this.setState({ uData: null, selectedGroup: null, selectedClinic: null }, () => {
      let matchedRoute = null;


      for (let i = 0; i < routes.length; i++) {
        let route = routes[i];

        const match = matchPath(this.props.location.pathname, route);
        if (match && match.isExact) {
          matchedRoute = route;
          break;
        }
      }

      if (matchedRoute && matchedRoute.path != '/:alias' && this.props.location.pathname.indexOf('video-call') == -1) {
        if (alias && logoutLanding && userLevel && userLevel == 20 && profile == 'doctor') {
          this.props.history.push(`/${alias}/login`);
        } else if (alias && logoutLanding && userLevel && (userLevel == 1 || userLevel == 2 || userLevel == 20) && profile == 'patient') {
          this.props.history.push(`/${alias}`);
        } else {
          this.props.history.push(`/login`);
        }
      }


    })

  }

  resetLogoutInterval = () => {
    if (this.state.uData) {


      if (Math.floor(Date.now() / 1000) > localStorage.getItem('time') && this.state.uData) {
        localStorage.setItem('time', Math.floor(Date.now() / 1000))
      }



    }
  }

  componentDidUpdate(prevProps, prevState) {



    if (prevState.uData != this.state.uData && !prevState.uData) {
      this.resetLogoutInterval();
    }

    let prevUrl = prevProps.location.pathname.split('/');
    let url = this.props.location.pathname.split('/');
    let checkCreateReferralPrevUrl = false;
    let checkCreateReferralUrl = false;

    if (url && url.length == 3 && url[0] == '' && (url[2] == 'personal-referral' || (url[2] == 'choose-time' && this.state.uData))) {
      checkCreateReferralUrl = true;
    }
    if (prevUrl && prevUrl.length == 3 && prevUrl[0] == '' && (prevUrl[2] == 'personal-referral' || (prevUrl[2] == 'choose-time' && this.state.uData))) {
      checkCreateReferralPrevUrl = true;
    }
    if (checkCreateReferralPrevUrl && !checkCreateReferralUrl) {
      this.verifyUser()
    } else if (!checkCreateReferralPrevUrl && checkCreateReferralUrl) {
      this.verifyUser()
    }
  }



  answer = () => {
    // if (this.audioIncomingCall) {
    //   this.audioIncomingCall.pause();
    // }

    var strWindowFeatures = `height=${window.innerHeight - 1},width=${window.innerWidth - 1},resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes`;
    this.videoCallWindow = window.open(`/video-call/${this.state.offerData.conversation}?initiator=${this.state.offerData.initiator}`, "_blank", strWindowFeatures);
    this.videoCallWindow.addEventListener('message',
      (e) => {
        if (e.data == 'end-video-call') {
          this.videoCallWindow.close();
        }


      }

      , false)

    localStorage.setItem('rejectedCalls', this.state.offerData.conversation)
    this.setState({
      offerData: null,
    }, () => {

    })
  }

  endCall = () => {
    // if (this.audioIncomingCall) {
    //   this.audioIncomingCall.pause();
    // }
    console.log('end call');
    this.setState({
      offerData: null
    }, () => {
      console.log(this.state.offerData, '4');
    })
  }


  initSocketForVideoRoom = (roomId, callback) => {
    this.isSocketForGuestVideoCall = true;
    this.socket = socketIO(API_ENDPOINT, { transports: ['websocket'], query: { roomId: roomId, guestID: localStorage.guestID ? localStorage.getItem('guestID') : false } });
    this.registerSocketEvents();

    callback();
  }


  registerSocketEvents = () => {
    this.socket.on('connect', () => {
      this.setState({
        socketId: this.socket.id
      })
    });

    this.socket.on('reconnect', () => {
      //alert('reconnect')
      this.socket.emit('clientReconnect', { socketId: this.state.socketId });
      this.setState({
        socketId: this.socket.id
      })
    })

    this.socket.on('hostEndedCall', (data) => {
      if (data.conversation && this.state.offerData && String(data.conversation) === this.state.offerData.conversation && data.members && this.state.uData && data.members.findIndex(member => String(member) === String(this.state.uData._id)) !== -1) {
        this.endCall()
      }
    });

    this.socket.on('activeVideoCalls', (data) => {
      let rejectedCalls = typeof window !== 'undefined' && localStorage.rejectedCalls ? JSON.parse(JSON.stringify(localStorage.rejectedCalls)) : []
      for (var key in data.myVideoCallsDisconnected) {
        // console.log(data[key]);
        if (rejectedCalls.indexOf(key) === -1 && data.myVideoCallsDisconnected[key] && data.myVideoCallsDisconnected[key].conversation) {
          console.log('set offer data.myVideoCallsDisconnected', data.myVideoCallsDisconnected[key]);
          this.setState({
            offerData: { ...data.myVideoCallsDisconnected[key] }
          })

          break
        }
      }


    });


    this.socket.on('newNotification', () => {
      fetch(API_ENDPOINT + '/notification/last', {
        method: 'POST',
        headers: {
          'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
          'content-type': 'application/json'
        },

      }).then(res => res.json()).then((result) => {

        if (result) {

          if (localStorage.getItem('notif') != result._id) {
            this.verifyUser()
            this.setState({
              newNotif: true
            })

          } else {
            this.setState({
              newNotif: false
            })
          }
        }

      })

    });
    this.socket.on('newContact', () => {
      this.verifyUser()
    });
  }

  invationRegistration = (data) => {
    this.setState({
      invationRegistration: data
    })
  }


  allowCookies(check, type) {
    localStorage.allowCookies = true;
    localStorage.setItem('buttonType', type);
    this.setState({
      cookies: true
    }, () => {
      if (check) {
        this.consentGranted2()
      } else {
        this.consentGranted1()
      }

    });
  }

  allowCookiesLogin(check, type) {
    localStorage.allowCookiesLogin = true;
    localStorage.setItem('buttonType', type);
    this.setState({
      cookiesLoginRegistration: true
    }, () => {
      if (check) {
        this.consentGranted2()
      } else {
        this.consentGranted1()
      }

    });
  }

  consentGranted1 = () => {
    localStorage.setItem('analytics_storage', 'true');

    if (!window.gtag) {
      return window.gtag;
    }

    window.gtag('consent', 'update', {
      'analytics_storage': 'granted',
    });
    window.location.reload(true);
  }

  consentGranted2 = () => {
    localStorage.setItem('ad_storage', 'true');
    localStorage.setItem('analytics_storage', 'true');
    localStorage.setItem('functionality_storage', 'true');
    localStorage.setItem('personalization_storage', 'true');
    localStorage.setItem('security_storage', 'true');

    if (!window.gtag) {
      return window.gtag;
    }

    window.gtag('consent', 'update', {
      'ad_storage': 'granted',
      'analytics_storage': 'granted',
      'functionality_storage': 'granted',
      'personalization_storage': 'granted',
      'security_storage': 'granted'

    });
    window.location.reload(true);
  }

  render() {
    let meta;
    // console.log(this.state.offerData);
    if (this.state.metaTags) {
      meta = {
        title: this.state.linksMeta && this.state.linksMeta[this.props.location.pathname] ? this.state.linksMeta[this.props.location.pathname][this.state.lang] : this.state.metaTags.title,
        description: this.state.metaTags.description ? this.state.metaTags.description : null,
        meta: {
          charset: 'utf-8',
          name: {
            'og:title': this.state.linksMeta && this.state.linksMeta[this.props.location.pathname] ? this.state.linksMeta[this.props.location.pathname][this.state.lang] : this.state.metaTags.title,
            'og:image': this.state.metaTags['og:image'] ? this.state.metaTags['og:image'] : null,
            'og:description': this.state.metaTags.description ? this.state.metaTags.description : null
          }
        }
      };
    }


    let routerProps = [];
    routerProps[0] = this.props;

    if ((this.state.browserName && this.state.browserName !== 'Chrome' && this.state.browserName !== 'Safari' && this.state.browserName !== 'Firefox' && this.state.browserName !== 'Edge') || (this.state.browserNameUA && this.state.browserNameUA !== 'Chrome' && this.state.browserNameUA !== 'Safari' && this.state.browserNameUA !== 'Firefox' && this.state.browserNameUA !== 'Edge')) {
      return (
        <div className='guest-end-call-message'>
          <p>{'This browser is not supported.'.translate(this.state.lang)}</p>
        </div>
      )
    }

    // console.log(this.state.cookiesLoginRegistration);
    return (
      <Provider store={store}>
        {
          meta ?

            <DocumentMeta {...meta}>
            </DocumentMeta>

            :
            null
        }
        <Routes
          {...this.state}
          translate={this.translate}
          setLang={this.setLang}
          setLightMode={this.setLightMode}
          initialData={this.props.initialData ? this.props.initialData : {}}
          updateMeta={this.updateMeta}
          changeLang={this.changeLang}
          verifyUser={this.verifyUser}
          formatName={this.formatName}
          ring112Audio={this.ring112Audio}
          toggleSidebar={this.toggleSidebar}
          registerLog={this.registerLog}
          signOut={this.signOut}
          changeGroup={this.changeGroup}
          changeProfile={this.changeProfile}
          setGroupAlias={this.setGroupAlias}
          getSelectedGroup={this.getSelectedGroup}
          getSelectedClinic={this.getSelectedClinic}
          resetLogoutInterval={this.resetLogoutInterval}
          initSocketForVideoRoom={this.initSocketForVideoRoom}
          disableLogoutMessage={this.disableLogoutMessage}
          statusBefore={this.statusBefore}
          clearStatusBefore={this.clearStatusBefore}
          closePatientAccountModal={this.closePatientAccountModal}
          invationRegistration={this.invationRegistration}
          groupSettingsChanged={this.groupSettingsChanged}
          openModal={this.openModal}
          abortAction={this.abortAction}
          redLinePopUp={this.redLinePopUp}
          endCall={this.endCall}
          updateSidebarScrollTop={(sidebarScrollTop) => this.setState({ sidebarScrollTop })}
          socket={this.socket}
          startVideoCall={(conversation) => {
            var strWindowFeatures = `height=${window.innerHeight - 1},width=${window.innerWidth - 1},resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes`;
            this.videoCallWindow = window.open(`/video-call/${conversation}`, "_blank", strWindowFeatures);
            this.videoCallWindow.addEventListener('message',
              (e) => {
                if (e.data == 'end-video-call') {
                  this.videoCallWindow.close();
                }

              }
              , false)

          }}
        /*
        allowCookies={() => {
          localStorage.allowCookies = true;
          this.setState({
            cookies: true
          });
        }}
        */
        />

        {typeof window !== 'undefined' && (this.props.location.pathname === '/login' || this.props.location.pathname === '/registration') && this.props.location.pathname.indexOf('video-call/') == -1 && !this.state.cookiesLoginRegistration ?
          <div className={this.state.redLinePopUp ? 'cookies-popup-red' : "cookies-popup-login"}>
            <h6>{'Cookies - security'.translate(this.state.lang)}</h6>
            <p>{'We use a few cookies for security purposes to provide Curoflow video in a secure manner. You need to accept this in order to use the service'.translate(this.state.lang)}</p>
            <button className="cp-button cp-button-big" onClick={() => this.allowCookiesLogin(true, 1)}>{'Accept'.translate(this.state.lang)}</button>
            <button className="cp-button" onClick={() => this.props.history.push('/')}>{'No thanks'.translate(this.state.lang)}</button>
            <span><Link key={this.props.lang} to="/integritetspolicy-video-service">{'Read more'.translate(this.state.lang)}</Link></span>
          </div>
          :
          null
        }

        {typeof window !== 'undefined' && (this.props.location.pathname === '/' || this.props.location.pathname === '/choose-plan-for-video-service' || this.props.location.pathname === '/contact' || this.props.location.pathname === '/gdpr') && !this.state.cookies ?
          <div className={"cookies-popup"}>
            <h6>{'We use cookies'.translate(this.state.lang)}</h6>
            <p>{'To optimize your use of our website, Curoflow uses necessary cookies. If you agree, we also use statistics & marketing cookies in order to analyze website traffic and offer more relevant communications. The data is shared with the cookie provider and you can withdraw your consent at any time.'.translate(this.state.lang)}</p>
            <button className="cp-button cp-button-big" onClick={() => this.allowCookies(true, 1)}>{'Accept all'.translate(this.state.lang)}</button>
            <button className="cp-button" onClick={() => this.allowCookies(false, 2)}>{'Accept the necessary'.translate(this.state.lang)}</button>
            <span><Link key={this.props.lang} to={this.props.lang == 'en' ? "/website/cookies" : "/website/cookies"}>{'Read more'.translate(this.state.lang)}</Link></span>
          </div>
          :
          null
        }


        {
          this.state.offerData && this.props.location.pathname.indexOf('/video-call') == -1 ?
            <div className="video-call-pop-up">
              <div class="caller">
                <img src={this.state.offerData.user && this.state.offerData.user.profilePicture ? API_ENDPOINT + this.state.offerData.user.profilePicture : profilePicture} />
                <p>{this.state.offerData && this.state.offerData.user ? this.state.offerData.user.name : ''}</p>
              </div>
              <div className="buttons">
                <button onClick={this.answer} className="answer-button"><Isvg src={call} /></button>
                <button onClick={this.endCall} className="decline-button"><Isvg src={call} /></button>
              </div>

            </div>
            :
            null

        }

        {
          this.state.refreshRequired ?
            <div className="refresh-required">
              <div>
                <p>{'Curoflow updated to new version, please reload page to continue!'.translate(this.state.lang)}</p>
                <Button color="primary" onClick={() => {
                  window.location.reload();

                }}>{'Reload'.translate(this.state.lang)}</Button>
              </div>
            </div>

            :

            null
        }

        {
          this.state._forbidden ?
            <ErrorModal lang={this.state.lang} isOpen={this.state._forbidden} toggle={() => this.setState({ _forbidden: null })}>
              {'Action not allowed!'.translate(this.state.lang)}
            </ErrorModal>
            :
            null
        }
        {
          this.state._toManyRequests ?
            <ErrorModal lang={this.state.lang} isOpen={this.state._toManyRequests} toggle={() => this.setState({ _toManyRequests: null }, () => {
              this.props.history.push('/')
            }
            )}>
              {this.state._toManyRequests}
            </ErrorModal>
            :
            null
        }
        {
          this.state.botModal ?
            <ErrorModal lang={this.state.lang} isOpen={this.state.botModal} toggle={() => { }}>
              {'Action not allowed!'.translate(this.state.lang)}
            </ErrorModal>
            :
            null
        }


      </Provider >

    );

  }




  uDataCondition = (condition) => {
    let arr = condition.split('||');
    for (let i = 0; i < arr.length; i++) {
      if (this.state.uData[arr[i]]) {
        return true;
      }
    }

    return false;
  }

  clinicEntryModuleCondition = (group, condition) => {
    let arr = condition.split('||');
    for (let i = 0; i < arr.length; i++) {
      if (group[arr[i]]) {
        return true;
      }
    }

    return false;
  }

  checkTimePhone = () => {
    if (localStorage.time && Math.floor(Date.now() / 1000) - localStorage.getItem('time') > LOGOUT_TIMEOUTV2) {
      this.signOut();
      this.enableLogoutMessage();
    } else {
      this.resetLogoutInterval

    }

  }


  fetchPolyfill = (url, options) => {
    return new Promise((resolve, reject) => {
      fetch(url, options).then((res) => {
        if (res.status == 403) {
          this.setState({
            _forbidden: true
          })
        }


        resolve(res);
      }).catch((error) => reject(error));
    })
  }

  componentDidMount() {

    let browserName = browserInfo();

    let userAgent = navigator.userAgent;
    let browserNameUA = 'No browser detection'
    if (userAgent.match(/chrome|chromium|crios/i)) {
      browserNameUA = "Chrome";
    } else if (userAgent.match(/firefox|fxios/i)) {
      browserNameUA = "Firefox";
    } else if (userAgent.match(/safari/i)) {
      browserNameUA = "Safari";
    } else if (userAgent.match(/edg/i)) {
      browserNameUA = "Edge";
    }

    this.setState({
      browserName: browserName.name,
      browserNameUA: browserNameUA
    }, () => {
      if (typeof window !== 'undefined' && window.innerWidth && window.innerWidth < 768) {
        if ((userAgent.indexOf("FBAN") > -1) || (userAgent.indexOf("FBAV") > -1)) {
          browserNameUA = 'Facebook'
        }

        this.setState({
          browserNameUA: browserNameUA
        })
      }
    })

    this.logoutIntervalV2 = setInterval(() => {
      if (this.state.uData) {


        if (Math.floor(Date.now() / 1000) - localStorage.getItem('time') > LOGOUT_TIMEOUTV2) {
          this.signOut()
          this.enableLogoutMessage()
        }

      }
    }, 2 * 60 * 1000)

    if (this.detectAutomatedTool()) {
      this.props.history.replace('/');
      this.setState({
        botModal: true
      })
      return;
    }

    const originalFetch = window.fetch;
    window.fetch = (url, options) => new Promise((resolve, reject) => {
      originalFetch(url, options).then((res) => {
        if (res.status == 403) {
          this.setState({
            _forbidden: true
          })
        } else if (res.status == 429) {
          this.setState({
            _toManyRequests: 'Too many accounts created from this IP, please try again later'.translate(this.state.lang)
          })
        }

        resolve(res);
      }).catch((error) => reject(error));
    })







    this.socketListener = socketIO(API_ENDPOINT, { /*transports: ['websocket'],*/transports: ['websocket'], query: { listener: true } });
    this.socketListener.on('checkVersion', (data) => {
      if (data.version && data.version !== APP_VERSION) {
        this.setState({
          refreshRequired: true
        })
      }
    })


    if (localStorage.time && Math.floor(Date.now() / 1000) - localStorage.getItem('time') > LOGOUT_TIMEOUTV2) {
      this.signOut()
      this.enableLogoutMessage()
    }

    document.addEventListener('mousemove', this.resetLogoutInterval)
    document.addEventListener('touchmove', this.checkTimePhone)


    this.props.history.listen((location) => {
      let isMobile = typeof window != 'undefined' && window.innerWidth < 1200;
      if (isMobile && location.pathname != this.props.location.pathname) {
        this.setState({
          sidebarWide: null
        })
      }


      if (this.state.uData && localStorage.getItem('authToken')) {

        routes.some(route => {
          // use `matchPath` here

          const match = matchPath(location.pathname, route);
          if (match && match.isExact) {
            // console.log(route.path, this.state.availablePaths);

            if (this.state.availablePaths && this.state.availablePaths.length && !route.disableAvailablePaths && this.state.availablePaths.indexOf(route.path) == -1) {
              if (this.state.modulesTree && this.state.modulesTree.length) {
                this.props.history.push(this.state.modulesTree[0].link)
              } else {
                this.props.history.push(this.state.availablePaths[0])
              }

            }

          }
          return match && match.isExact;
        });

      }






    })

    try {
      this.triggerPushNotification();
    } catch (error) {

    }

    if (localStorage.allowCookies) {

      this.setState({
        cookies: true
      });
    }

    if (localStorage.allowCookiesLogin) {

      this.setState({
        cookiesLoginRegistration: true
      });
    }

    this.verifyUser();

    setInterval(this.checkToken, 60000);

    fetch(API_ENDPOINT + '/language-translate', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      },
    }).then(res => {
      return res.json()
    }).then((result) => {
      window.langs = result;
      let origLang = this.state.lang;
      this.setState({
        lang: null
      }, () => {
        this.setState({
          lang: origLang
        })
      })
      this.forceUpdate();
    });



    window.addEventListener('message',
      (e) => {
        if (e.data == 'end-video-call') {
          this.setState({
            videoCallUrl: null
          })
        }

        if (e.data == 'toggle-video-call') {
          this.setState({
            videoCallMin: !this.state.videoCallMin,
            videoCallX: !this.state.videoCallMin ? typeof window != 'undefined' ? window.innerWidth - 320 : 0 : 0,
            videoCallY: !this.state.videoCallMin ? typeof window != 'undefined' ? 20 : 0 : 0,

          })
        }

      }, false)

    if (this.props.uData) {
      this.setState({
        loggedIn: true
      })
    }

    if (typeof window !== 'undefined' && localStorage.lang) {
      this.setState({ lang: localStorage.lang })
    }
    if (API_ENDPOINT.indexOf('localhost') === -1) {
      Sentry.init({
        dsn: SENTRY_DSN,
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
      });
    }

  }

}

export default withRouter(App);
