
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

import { FormGroup, Label, Input, FormFeedback, FormText, Button, Row, Col } from 'reactstrap';
import Checkbox from './checkbox';
/**
* Custom multi checkbox select
* @author   Milan Stanojevic
*/
class Text extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filter: null
        };
    }


    render() {
        const chars = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'Å', 'Ä', 'Ö'];

        return (
            <FormGroup>
                {this.props.label ? <Label size="sm" className={this.props.error ? "required" : ""}>{this.props.label}{this.props.haveValidation ? <span className="required-label-span">*</span> : null}</Label> : null}
                {
                    this.props.beforeText ? <span className="field-before-text">{this.props.beforeText}</span> : null
                }

                {!this.props.disableFilter ? <div className="letters-helper">
                    <Button type="button" color={this.state.filter === null ? 'primary' : 'secondary'} onClick={() => this.setState({ filter: null })} size="sm"> {'All'.translate(this.props.lang)} </Button>
                    {
                        chars.map((item, idx) => {
                            return (
                                <Button color={this.state.filter === item ? 'primary' : 'secondary'} onClick={() => this.setState({ filter: item })} size="sm" key={idx}>{item}</Button>
                            )
                        })
                    }
                </div>
                    :
                    null
                }

                {!this.props.disabled ?
                        <Col lg="12" className="checkbox-container checkbox-container-all">
                            <Checkbox checked={this.state.check} onChange={() => {
                                this.setState({check : !this.state.check}, () => {
                                    if(this.state.check) {
                                        this.props.onChange(this.props.values.map(item => item.value));
                                    } else {
                                        this.props.onChange([]);
                                    }
                                })
                            }} label={<>{'Check all'.translate(this.props.lang)} / {'Uncheck all'.translate(this.props.lang)}</>}></Checkbox>
                        </Col>
                        :
                        null
                    }
                <hr />

                <Row className="multiselect-checkboxes">
                    {this.props.values && this.props.values.length ? this.props.values.map((option, oidx) => {
                        if (!this.state.filter || (this.state.filter && option.name.indexOf(this.state.filter) == 0))
                            return (
                                <Col lg={this.props.width ? this.props.width : "4"}>
                                <FormGroup>
                                <Checkbox disabled={this.props.disabled || option.disabled} checked={this.props.value && this.props.value.indexOf(option.value) !== -1} onChange={() => {
                                        let value = this.props.value;
                                        if (!value) {
                                            value = [];
                                        }

                                        let idx = value.indexOf(option.value);
                                        if (idx == -1) {
                                            value.push(option.value);
                                        } else {
                                            value.splice(idx, 1);
                                        }

                                        this.props.onChange(value);
                                        this.forceUpdate();

                                    }} type="checkbox" label={option.name}/>
                                    </FormGroup>
                                </Col>
                            )
                    })
                        :
                        null
                    }
                </Row>

                {
                    this.props.afterText ? <span className="field-after-text">{this.props.afterText}</span> : null
                }
                {this.props.error ? <FormFeedback >{this.props.error}</FormFeedback> : null}
            </FormGroup>
        );
    }
}

export default Text;