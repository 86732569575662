import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Button

} from 'reactstrap';

import Isvg from 'react-inlinesvg'
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';
import InfoModal from '../../components/infoModal';
import FormBuilder from '../../components/forms/modalFormBuilder';
import { required } from '../../components/forms/validation';
import ListBuilder from '../../components/listBuilder';
import DeleteModal from '../../components/deleteModal';
import ErrorModal from '../../components/errorModal';
import edit from '../../assets/svg/edit.svg'
import garabage from '../../assets/svg/garbage.svg'
import forgotPassword from '../../assets/svg/forgot_password.svg';
import addIcon from '../../assets/svg/add-icon.svg';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import moment from 'moment';

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

/**
* Users page
* @author   Milan Stanojevic
*/
class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            endpoints: [],
            formModules: [],
            userLevels: [],
            languages: [],
            formUsers: [],
            total: 0,
            loading: true,
            supportCategory: []
        };
    }

    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }
    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }
        if (prevProps.userLevel != this.props.userLevel && this.props.userLevel != '5f6b126329ef8110f415087b') {
            //alert(true)
            this.props.changeParentAccount(null);
            this.forceUpdate();
        }
        if (prevProps.email != this.props.email && this.state.form && this.props.email && this.props.email != '') {
            this.getUsername();
        }

        if (prevProps.userLevel != this.props.userLevel) {
            for (let i = 0; i < this.state.userLevels.length; i++) {
                if (this.state.userLevels[i]._id == this.props.userLevel) {
                    this.setState({
                        _disableSupportCategory: this.state.userLevels[i].level <= 20 ? true : false
                    })

                    break;
                }
            }
        }
    }

    componentWillReceiveProps(nextProps) {
    }

    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }

    /**
    * Create new or update user
    * @author   Milan Stanojevic
    * @Objectparam    {String} socialSecurityNumber     social securiy number of user
    * @Objectparam    {String} title                    user title
    * @Objectparam    {String} name                     user name
    * @Objectparam    {String} email                    user email
    * @Objectparam    {String} phone                    user phone
    * @Objectparam    {String} password                 user password
    * @Objectparam    {String} supportCategory          user support category
    * @Objectparam    {String} language                 user language
    * @Objectparam    {String} username                 user username created from user name
    * @Objectparam    {Boolean} articles                    
    */
    insertOrUpdate = (data) => {
        if (!data.language) {
            data.language = this.state.languages.filter(item => { if (item.key == 'en') return (item) })._id
        }
        this.setState({
            loading: true,
            _saving: true
        }, () => {
            if (!this.state.form._id) {

                fetch(API_ENDPOINT + '/users/users/new', {
                    method: 'PUT',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(parseJSON).then(({ result, status }) => {
                    if (result.error) {
                        this.setState({ error: result.error })
                    } else {
                        this.setState({
                            form: null
                        })
                    }
                    this.get();
                    this.setState({
                        _saving: false
                    }, () => {
                        setTimeout(() => {
                            this.setState({ _saving: false })
                        }, 1000)
                    })
                })
            } else {
                fetch(API_ENDPOINT + '/users/users/' + this.state.form._id, {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(parseJSON).then(({ result, status }) => {
                    if (result.error) {
                        this.setState({ error: result.error })
                    } else {
                        this.setState({
                            form: null
                        })
                    }
                    this.get();
                    this.setState({
                        _saving: false
                    }, () => {
                        setTimeout(() => {
                            this.setState({ _saving: false })
                        }, 1000)
                    })
                })
            }
        })
    }


    stopAnimation = () => {
        this.player.current.play();
    }


    /**
    * Delete user
    * @author   Milan Stanojevic
    * @Objectparam    {String} id     user id
    */
    delete = (id) => {
        this.setState({
            loading: true
        }, () => {

            fetch(API_ENDPOINT + '/users/users/' + id, {
                method: 'DELETE',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(parseJSON).then(({ result, status }) => {
                if (result.error) {
                    this.setState({ error: result.error })
                }
                this.get();
            })
        })

    }

    /**
    * Generate username of user
    * @author   Milan Stanojevic
    */
    getUsername = () => {
        if (this.state.form._disableEdit) {
            return;
        }

        fetch(API_ENDPOINT + '/users/users/get-username', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                userId: this.state.form._id ? this.state.form._id : null,
                name: this.props.name,
                email: this.props.email
            })
        }).then(parseJSON).then(({ result, status }) => {
            if (status == 200 && result && result.username) {
                this.props.changeUsername(result.username)
            }
        })

    }

    forgotPassword = (data) => {
        fetch(API_ENDPOINT + '/users/user/forgotpassword', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ uid: data._id })
        }).then(res => res.json()).then((result) => {
            if (result.error) {
                this.setState({
                    error: result.error,
                    forgotPassword: null
                })
            } else {
                this.setState({
                    message: result.message,
                    disable: true,
                    forgotPassword: null
                })
            }
        })
    }

    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }
        const defaultOptions = {
            loop: true,
            autoplay: true,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        let parentAccounts = [];
        for (let i = 0; i < this.state.formUsers.length; i++) {
            if (this.state.formUsers[i].userLevel == '5f6b125929ef8110f415087a') {
                parentAccounts.push(this.state.formUsers[i])
            }
        }
        return (
            <div className="dashboard">
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="panel">
                                <div className="panel-header">
                                    <h5 className="component-header">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h5>
                                    <button className="btn add-button" onClick={() => this.setState({
                                        form: {}
                                    })}><Isvg src={addIcon} /> {'Add'.translate(this.props.lang)}</button>
                                </div>

                                <ListBuilder
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'username', label: 'Username'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'name', label: 'Name'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'companyName', label: 'Company'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'userLevelName', multilang: true, label: 'User level'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'licence', label: 'Licence type'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'experienceDate', label: 'Expiration date'.translate(this.props.lang), allowSort: true },
                                    ]}
                                    items={this.state.items.map(item => {
                                        let licence = ''
                                        if (this.props.lang == 'en' && item.licenceObj && item.licenceObj.licenceMain) {
                                            licence = item.licenceObj.licenceMain
                                        } else if (this.props.lang == 'se' && item.licenceObj && item.licenceObj.licenceMainSv) {
                                            licence = item.licenceObj.licenceMainSv
                                        }
                                        return {
                                            ...item,
                                            companyName: item.userData && item.userData.companyName ? item.userData.companyName : '',
                                            licence: licence,
                                            experienceDate: item.licenceObj && item.licenceObj.recuringPaymentTime ? moment.unix(item.licenceObj.recuringPaymentTime).format(this.props.dateFormat) : '',
                                            name: item && item.userData && item.userData.title && item.name ? this.props.formatName(item.userData.title, item.userData.name) : item.name,
                                        }
                                    })}
                                    actions={
                                        [
                                            {
                                                component: <div className="tabel-action-tooltip"><Isvg src={forgotPassword} className="edit-icon" /> <div className="chat-icons-tooltip">{'Reset password'.translate(this.props.lang)}</div> </div>,
                                                onClick: (item) => this.setState({ forgotPassword: item })
                                            },
                                            {
                                                component: <Isvg src={edit} className="edit-icon" />,
                                                onClick: (item) => {
                                                    if (item && item.companyID && item.profileType == 'personal') {
                                                        fetch(API_ENDPOINT + "/users/account/company-data/" + item.companyID, {
                                                            method: "GET",
                                                            headers: {
                                                                "content-type": "application/json",
                                                                Authorization: typeof localStorage !== "undefined" ? `Bearer ${localStorage.getItem("authToken")}` : null,
                                                            },
                                                        }).then((res) => res.json()).then((result) => {
                                                            if (result) {
                                                                this.setState({
                                                                    companyData: result,
                                                                    form: item
                                                                })
                                                            }

                                                        });

                                                    } else {
                                                        this.setState({
                                                            companyData: item,
                                                            form: item
                                                        })
                                                    }
                                                }
                                            },
                                            {
                                                component: <Isvg src={garabage} className="delete-icon" />,
                                                onClick: (item) => this.setState({ deleteModal: item })
                                            },
                                        ]
                                    }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                                <DeleteModal
                                    lang={this.props.lang}
                                    isOpen={this.state.deleteModal}
                                    toggle={() => this.setState({ deleteModal: null })}
                                    handler={() => { this.delete(this.state.deleteModal._id); this.setState({ deleteModal: null }) }}
                                >
                                    {'Delete user'.translate(this.props.lang)} <strong>{this.state.deleteModal && this.state.deleteModal.name}</strong>?
                                </DeleteModal>
                                {this.state.removeClinicAdminModal ?
                                    <DeleteModal
                                        lang={this.props.lang}
                                        isOpen={this.state.removeClinicAdminModal}
                                        toggle={() => this.setState({ removeClinicAdminModal: null })}
                                        handler={() => {

                                            fetch(API_ENDPOINT + '/users/users/clinic/' + this.state.removeClinicAdminModal.item._id + '/' + this.state.removeClinicAdminModal.item.clinics[this.state.removeClinicAdminModal.idx]._id, {
                                                method: 'DELETE',
                                                headers: {
                                                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                                    'content-type': 'application/json'
                                                },
                                            }).then(parseJSON).then(({ result, status }) => {
                                                if (result.error) {
                                                    this.setState({ error: result.error })
                                                }
                                                this.setState({
                                                    removeClinicAdminModal: null
                                                })
                                                this.get();
                                            })

                                        }}
                                    >
                                        {'Remove admin'.translate(this.props.lang)} {this.state.removeClinicAdminModal.item.name}  from {this.state.removeClinicAdminModal.item.clinics[this.state.removeClinicAdminModal.idx].name}
                                    </DeleteModal>
                                    :
                                    null
                                }
                                <DeleteModal
                                    lang={this.props.lang}
                                    isOpen={this.state.aliasModal}
                                    toggle={() => this.setState({ aliasModal: null })}
                                    handler={() => {
                                        this.setState({
                                            loading: true,
                                        }, () => {

                                            fetch(API_ENDPOINT + '/users/users/create-alias/' + this.state.aliasModal._id, {
                                                method: 'GET',
                                                headers: {
                                                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                                    'content-type': 'application/json'
                                                },
                                            }).then(parseJSON).then(({ result, status }) => {
                                                if (result.error) {
                                                    this.setState({ error: result.error })
                                                }
                                                this.setState({
                                                    aliasModal: null
                                                })
                                                this.get();
                                            })
                                        })

                                    }}
                                >
                                    {'Create alias for user'.translate(this.props.lang)} <strong>{this.state.aliasModal && this.state.aliasModal.name}</strong>?
                                </DeleteModal>
                                <DeleteModal
                                    lang={this.props.lang}
                                    isOpen={this.state.unlinkModal}
                                    toggle={() => this.setState({ unlinkModal: null })}
                                    handler={() => {
                                        this.setState({
                                            loading: true,
                                        }, () => {

                                            fetch(API_ENDPOINT + '/users/users/' + this.state.unlinkModal._id, {
                                                method: 'POST',
                                                headers: {
                                                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                                    'content-type': 'application/json'
                                                },
                                                body: JSON.stringify({ parentAccount: null, parentAccountName: null })

                                            }).then(parseJSON).then(({ result, status }) => {
                                                if (result.error) {
                                                    this.setState({ error: result.error })
                                                }
                                                this.get();
                                                this.setState({
                                                    unlinkModal: null,
                                                    form: null
                                                })
                                            })
                                        })
                                    }}
                                >
                                    {'Unlink sub account'.translate(this.props.lang)} <strong>{this.state.unlinkModal && this.state.unlinkModal.name}</strong>?
                                </DeleteModal>
                                {this.state.error ?

                                    <ErrorModal lang={this.props.lang}
                                        isOpen={this.state.error}
                                        toggle={() => this.setState({ error: null })}

                                    >
                                        {this.state.error.translate(this.props.lang)}
                                    </ErrorModal>

                                    :
                                    null
                                }
                                {this.state.forgotPassword ?
                                    <InfoModal
                                        isOpen={this.state.forgotPassword}
                                        toggle={() => this.setState({ forgotPassword: null })}
                                        header={'Password recovery'.translate(this.props.lang)}
                                        info={'Are sure you want to send reset password link to selected user?'.translate(this.props.lang)}
                                        buttons={[
                                            <Button color='primary' onClick={() => {
                                                this.forgotPassword(this.state.forgotPassword);

                                            }}>{'Yes'.translate(this.props.lang)}</Button>,

                                            <Button color='primary' onClick={() => {
                                                this.setState({ forgotPassword: null });

                                            }}>{'No'.translate(this.props.lang)}</Button>
                                        ]}
                                    />
                                    :
                                    null}
                            </div>
                        </Col>
                    </Row>
                </Container>
                {this.state.form ?
                    <FormBuilder dateFormat={this.props.dateFormat}
                        onSubmit={(data) => {
                            if (!this.state.form._disableEdit)
                                this.insertOrUpdate(data)
                        }}
                        initialValues={{ ...this.state.form, companyData: { ...this.state.companyData } }}
                        size="xl"
                        isOpen={this.state.form}
                        toggle={() => this.setState({ form: null })}
                        title={this.state.form._id ? 'Edit user'.translate(this.props.lang) : 'Add new user'.translate(this.props.lang)}
                        _saving={this.state._saving}
                        buttonConfirmText={'Saved'.translate(this.props.lang)}
                        buttonText={'Save'.translate(this.props.lang)}
                        disableSave={this.state.form._disableEdit}

                        lang={this.props.lang}
                        fields={[
                            {
                                type: 'row',
                                children: [
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'socialSecurityNumber',
                                                name: 'socialSecurityNumber',
                                                label: 'Social security number'.translate(this.props.lang),
                                                // validate: [required('Social security number is required!'.translate(this.props.lang))]

                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'accountNumber',
                                                label: 'Account number'.translate(this.props.lang),
                                                disabled: true
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 2,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'userData.title',
                                                label: 'Title'.translate(this.props.lang),
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 4,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'userData.name',
                                                label: 'First and last name'.translate(this.props.lang),
                                                disabled: this.props.alias,
                                                validate: [required('Name is required!'.translate(this.props.lang))]


                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: this.state.form._disableEdit ? 'text' : 'email',
                                                name: 'email',
                                                label: 'Email address'.translate(this.props.lang),
                                                validate: [required('Email address is required!'.translate(this.props.lang))]

                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'username',
                                                label: 'Username'.translate(this.props.lang),
                                                disabled: true,
                                                validate: [required('Username is required!'.translate(this.props.lang))]

                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'select',
                                                name: 'language',
                                                label: 'Language'.translate(this.props.lang),
                                                values: this.state.languages.map(item => { return { name: item.name, value: item._id } })
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'select',
                                                name: 'userLevel',
                                                label: 'User level'.translate(this.props.lang),
                                                values: [{ name: 'Select user level'.translate(this.props.lang), value: null }, ...this.state.userLevels.map(item => { return { name: Object.translate(item, 'name', this.props.lang), value: item._id } })],
                                                validate: [required('User level is required!'.translate(this.props.lang))]
                                            },
                                        ]
                                    },
                                    this.state.form._id ?
                                        null
                                        :
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'password',
                                                    name: 'password',
                                                    autoComplete: 'new-password',
                                                    label: 'Password'.translate(this.props.lang),
                                                    validate: [required('Password is required!'.translate(this.props.lang))]
                                                },
                                            ]
                                        },
                                    this.state.form._id ?
                                        null
                                        :
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'password',
                                                    name: 'repeatPassword',
                                                    autoComplete: 'new-password',
                                                    label: 'Repeat password'.translate(this.props.lang),
                                                    validate: [required('Repeat password is required!'.translate(this.props.lang))]
                                                },
                                            ]
                                        },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: this.state.form._disableEdit ? 'text' : 'phone',
                                                name: 'phone',
                                                label: 'Phone'.translate(this.props.lang),
                                                validate: [required('Phone is required!'.translate(this.props.lang))]
                                            },
                                        ]
                                    },
                                    !this.state._disableSupportCategory ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'list-checkbox',
                                                    name: 'supportCategory',
                                                    multiple: true,
                                                    label: 'Support category'.translate(this.props.lang),
                                                    values: [...this.state.supportCategory.map(item => { return { name: item.name /*Object.translate(item, 'name', this.props.lang)*/, value: item._id } })],
                                                },
                                            ]
                                        }
                                        : null,

                                   
                                ]
                            },
                            this.state.companyData ?
                            {
                                type: 'row',
                                children: [
                                    {

                                        type: "col",
                                        width: {
                                            lg: 12,
                                            sm: 12,
                                            xs: 12,
                                        },
                                        children: [
                                            {
                                                type: 'h6',
                                                text: 'Company data'.translate(this.props.lang)
                                            },
                                        ]
                                    },
                                    {

                                        type: "col",
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12,
                                        },
                                        children: [
                                            {
                                                type: "text",
                                                name: "companyData.userData.companyName",
                                                label: 'Company name'.translate(this.props.lang),
                                                disabled: true
                                            },
                                        ]
                                    },
                                    {

                                        type: "col",
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12,
                                        },
                                        children: [
                                            {
                                                type: "text",
                                                name: "companyData.userData.country",
                                                label: 'Country'.translate(this.props.lang),
                                                lang: this.props.lang,
                                                disabled: true
                                            },
                                        ]
                                    },
                                    {

                                        type: "col",
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12,
                                        },
                                        children: [
                                            {
                                                type: "text",
                                                name: "companyData.userData.name",
                                                label: 'Company admin name'.translate(this.props.lang),
                                                disabled: true
                                            },
                                        ]
                                    },

                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12,
                                        },
                                        children: [
                                            {
                                                type: 'text',
                                                className: 'vat-label-height',
                                                name: 'companyData.userData.vatNumber',
                                                label: 'VAT Number'.translate(this.props.lang),
                                                disabled: true
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12,
                                        },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'companyData.userData.orgNr',
                                                label: 'Org.Nr'.translate(this.props.lang),
                                                disabled: true
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12,
                                        },
                                        children: [
                                            {
                                                type: "phone",
                                                name: "companyData.phone",
                                                autoComplete: 'new-password',
                                                label: "Phone number".translate(this.props.lang),
                                                disabled: true
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12,
                                        },
                                        children: [
                                            {
                                                type: "timeZonePicker",
                                                name: "companyData.timezone",
                                                label: "Time zone".translate(this.props.lang),
                                                disabled: true
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12,
                                        },
                                        children: [
                                            {
                                                type: "text",
                                                name: "companyData.street",
                                                label: "Street".translate(this.props.lang),
                                                disabled: true
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 4,
                                            sm: 12,
                                            xs: 12,
                                        },
                                        children: [
                                            {
                                                type: "text",
                                                name: "companyData.city",
                                                label: "City".translate(this.props.lang),
                                                disabled: true
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 2,
                                            sm: 12,
                                            xs: 12,
                                        },
                                        children: [
                                            {
                                                type: "text",
                                                name: "companyData.zip",
                                                label: "Postal code".translate(this.props.lang),
                                                disabled: true
                                            },
                                        ]

                                    },
                                ]
                            }

                            : null
                        ]}
                    ></FormBuilder>
                    :
                    null
                }

            </div>
        );
    }
}


const selector = formValueSelector('modalForm');

export default connect(state => {
    return {
        userLevel: selector(state, 'userLevel'),
        alias: selector(state, 'alias'),
        username: selector(state, 'username'),
        name: selector(state, 'userData.name'),
        email: selector(state, 'email')


    }


}, {
    changeParentAccount: value => change("modalForm", "parentAccount", value),
    changeUsername: value => change("modalForm", "username", value),
})(Page(Users));