import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

import { FormGroup, Label, Input, FormFeedback, FormText } from 'reactstrap';

function isSocialSecurityNumberValid(answer) {
    if (!answer){
        return false;
    }

    let year = parseInt(`${answer[0]}${answer[1]}${answer[2]}${answer[3]}`);
    let month = parseInt(`${answer[4]}${answer[5]}`);
    let day = parseInt(`${answer[6]}${answer[7]}`);
    //let date = new Date(year, month - 1, day);
    if (month > 12){
        return false;
    }

    if (day > 31){
        return false;
    }


    let daysInMoth = new Date(year, month, 0).getDate();

    if (day > daysInMoth){
        return false;
    }
    

    return true;
}

/**
* Component for socialSecurityNumber
* @author   Milan Stanojevic
*/
class SocialSecurityNumber extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }






    render() {
        return (
            <FormGroup>
                {this.props.label ? <Label check={this.props.type == 'checkbox'} size="sm" className={this.props.error ? "required" : ""}>{this.props.label} ({'ÅÅÅÅMMDDNNNN'.substr(0, this.props.value ? this.props.value.length : 0)}<span style={{ color: 'red' }}>{'ÅÅÅÅMMDDNNNN'.substr(this.props.value ? this.props.value.length : 0, 12)}</span> ) {this.props.haveValidation ? <span className="required-label-span">*</span> : null}</Label> : null}
                <Input size="sm" disabled={this.props.disabled} name={this.props.name} invalid={this.props.error || !isSocialSecurityNumberValid(this.props.value) ? true : false} onChange={(e) => {
                    let value = e.target.value.replace(/[^\d]/g, '')
                    if (value.length > 12) {
                        return;
                    }
                    this.props.onChange(value);
                }} value={this.props.value} placeholder={this.props.placeholder}></Input>
                {this.props.error ? <FormFeedback >{this.props.error}</FormFeedback> : null}
            </FormGroup>
        );
    }
}

export default SocialSecurityNumber;