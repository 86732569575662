import { matchPath } from 'react-router-dom';


const allLinks = [
    '/',
    '/var-losning',
    '/om-oss',
    '/kontakt',
    '/gdpr',
    '/for-partners',
    '/cookies',
    '/integritetspolicy',
    '/integritetspolicy-videotjanst',
    '/integritetspolicy-webbsida'
]

const linkTranslates = {
  
    'en': {
        '/' : '/en',
        '/var-losning' : '/en/our-solutions',
        '/om-oss' : '/en/about-us',
        '/kontakt' : '/en/contact',
        '/gdpr' : '/en/gdpr',
        '/integritetspolicy-videotjanst' : '/en/privacy-policy-video-service',
        '/for-partners' : '/en/for-partners',
        '/cookies' : '/en/cookies',
        '/integritetspolicy' : '/en/privacy-policy',
        '/integritetspolicy-webbsida': '/en/privacy-policy-website'
        
    },
}

export default function (link, lang){
    let to = link;
    if (!lang){
        lang = 'se';
    }

    if (lang !== 'se') {
        allLinks.some(route => {

            const match = matchPath(to, route);
            if (match && match.isExact) {
                to = to.replace(route.split(':')[0], linkTranslates[lang][route].split(':')[0])
            }
            return match && match.isExact;
        });
    }


    return to;
}