import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Button, Table, Input, Label, FormGroup

} from 'reactstrap';

import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';

import DeleteModal from '../../components/deleteModal';
import ErrorModal from '../../components/errorModal';

import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';

import DeletionRequestsComponent from '../../components/deletinRequestsComponent';
import DeletionLogsComponent from '../../components/deletionLogComponent';



function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

/**
* Clinic users page
* @author   Milan Stanojevic
*/
class DeletionAccounts extends Component {
    constructor(props) {
        super(props);
        this.player = React.createRef();
        this.player2 = React.createRef();
        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            doctors: [],
            endpoints: [],
            formModules: [],
            userLevels: [],
            languages: [],
            formUsers: [],
            professions: [],
            total: 0,
            loading: true,
            questionaryModal: false,
            questionaryList: [],
            tabIndex: 1,
            transferToUser: null,
            changeEmail: false
        };
    }


    showPlayer = () => {
        this.setState({
            showPlayer: true,
        });
    };

    hidePlayer = () => {
        this.setState({
            showPlayer: false,
        });
    };

    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic })).then((data) => {
                let dataToSet = { ...data }
                if (dataToSet.page || dataToSet.items || dataToSet.total) {
                    dataToSet.loading = null;
                    let checkFilter = '';
                    if (dataToSet.resultFilter) {
                        checkFilter = String(dataToSet.resultFilter)
                        delete dataToSet.resultFilter;
                    }
                    let search = this.props[0].location.search;
                    let serachParams = getSearchParams(search);
                    let searchParamsFilter = '';
                    if (serachParams.filter) {
                        searchParamsFilter = serachParams.filter;
                    }
                    if (searchParamsFilter != checkFilter) {
                        delete dataToSet.page;
                        delete dataToSet.items;
                        delete dataToSet.total;
                        delete dataToSet.loading;
                    }
                }
                this.setState({
                    ...dataToSet,
                    // loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }



    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }



        this.get();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }

        if (prevProps.selectedGroup != this.props.selectedGroup) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })

        }
        if (prevProps.deletionRequestRefresh != this.props.deletionRequestRefresh) {
            this.setState({ getRequests: Math.floor(new Date().getTime() / 1000), getLogs: Math.floor(new Date().getTime() / 1000) })
        }

    }

    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }






    stopAnimation = () => {
        this.player.current.play();
    };

    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }

        let selectedGroupIdx = 0;
        if (this.props.selectedGroup && this.props.uData.clinicGroups) {
            for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                    selectedGroupIdx = i;
                    break;
                }
            }
        }


        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>

                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="panel">

                                <div className="panel-header">
                                    <h5 className="component-header">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}
                                    </h5>

                                </div>
                                <DeletionRequestsComponent
                                    {...this.props}
                                    getLogs={() => { this.setState({ getLogs: Math.floor(new Date().getTime() / 1000) }) }}
                                    getRequests={this.state.getRequests}
                                />

                            </div>
                        </Col>


                        <Col lg="12">
                            <div className="panel">

                                <div className="panel-header">
                                    <h5 className="component-header">{'Deletion log'.translate(this.props.lang)}</h5>
                                </div>
                                <DeletionLogsComponent
                                    {...this.props}
                                    getLogs={this.state.getLogs}
                                />




                            </div>
                        </Col>



                        <DeleteModal
                            lang={this.props.lang}
                            isOpen={this.state.deleteModal}
                            toggle={() => this.setState({ deleteModal: null })}
                            handler={() => { this.delete(this.state.deleteModal); this.setState({ deleteModal: null }) }}
                        >
                            {'Delete user'.translate(this.props.lang)} <strong>{this.state.deleteModal && this.state.deleteModal.name}</strong>?
                        </DeleteModal>
                        {this.state.error ?

                            <ErrorModal lang={this.props.lang}
                                isOpen={this.state.error}
                                toggle={() => this.setState({ error: null })}

                            >
                                {this.state.error.translate(this.props.lang)}
                            </ErrorModal>

                            :

                            null
                        }
                    </Row>

                </Container>




            </div>
        );
    }
}


const selector = formValueSelector('modalForm');

export default connect(state => {
    return {


    }


}, {


})(Page(DeletionAccounts));