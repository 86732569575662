import React, { Component } from "react";
import Page from "../../containers/page";

import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import Isvg from "react-inlinesvg";
import ModalFormBuilder from "../../components/forms/modalFormBuilder";
import FormBuilder from "../../components/forms/formBuilder";
import { required, vatNumberValidation } from "../../components/forms/validation";
import DeleteModal from "../../components/deleteModal";
import {
  getSearchParams,
  generateSearchLink,
  generateSearchLinkMultiple,
} from "../../helpers/linkHelper";
import { API_ENDPOINT } from "../../constants";
import ErrorModal from "../../components/errorModal";
import { formValueSelector, change } from "redux-form"; // ES6
import { connect } from "react-redux";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import profileUser from "../../assets/images/user.png";
import PaysonSvg from '../../assets/svg/payson.svg';
import SwishSvg from '../../assets/svg/swish.svg';
import addIcon from '../../assets/svg/add-icon.svg';
import garabage from '../../assets/svg/garbage.svg'

import Swish from '../swish';
import moment from 'moment';
const swishNumber = 123456;
import Checkbox from '../../components/forms/fields/checkbox';
import InfoModal from "../../components/infoModal";
import Payson from '../../components/payson';
import ListBuilder from "../../components/listBuilder";
import BankIDModal_v5 from "../../components/bankIDModal_v5";



function parseJSON(response) {
  return response.json().then((json) => {
    return {
      result: json,
      status: response.status,
    };
  });
}

function parseText(response) {
  return response.text().then(text => {
    return {
      result: text,
      status: response.status
    }
  })
}
/**
* My profile page
* @author   Milan Stanojevic
*/
class Account extends Component {
  constructor(props) {
    super(props);
    this.player = React.createRef();
    this.player2 = React.createRef();
    this.state = {
      showPlayer: false,
      showPlayer2: false,
      ...props.initialData,
      form: false,
      useState: false,
      params: {
        entries: 10,
        page: 0,
        "query-group": null,
      },
      items: [],
      total: 0,
      loading: true,
      groups: [],
      languages: [],
      clinicIdx: 0,
      costLicence: 0,
      licence: [],
      licenceName: '',
      licenceDuration: '',
      activeTab: 1,
      addLicence: false,
      userAssignLicence: {},
      showForm: false,
      renewLicence: false,
      upgradeLicenceCheck: false,
      assignedLicence: [],
      companyData: false
    };
  }

  showPlayer = () => {
    this.setState({
      showPlayer: true,
    });
  };

  hidePlayer = () => {
    this.setState({
      showPlayer: false,
    });
  };

  get = () => {
    this.props.updateMeta(
      this.props.generateSeoTags(this.state, this.props.lang)
    );

    for (let i = 0; i < this.props.loadData.length; i++) {
      this.props.loadData[i](
        window.fetch,
        this.props.lang,
        this.props[0].match,
        this.state.useState
          ? this.state.params
          : getSearchParams(this.props[0].location.search, {
            entries: 10,
            page: 0,
            "query-group": null,
          })
      ).then((data) => {
        this.setState(
          {
            ...data,
            loading: null,
          },
          () => {
            this.props.updateMeta(
              this.props.generateSeoTags(this.state, this.props.lang)
            );
          }
        );
      });
    }

    for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
      this.props.loadDataWithQuery[i](
        window.fetch,
        this.props.lang,
        this.props[0].match,
        this.state.useState
          ? this.state.params
          : getSearchParams(this.props[0].location.search, {
            entries: 10,
            page: 0,
            "query-group": null,
            user: this.props.uData._id
          })
      ).then((data) => {
        this.setState(
          {
            ...data,
            loading: null,
          },
          () => {
            this.props.updateMeta(
              this.props.generateSeoTags(this.state, this.props.lang)
            );
          }
        );
      });
    }


  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
    if (this.props[0].location.state && this.props[0].location.state.bankIdError) {
      this.setState({ bankIdError: this.props[0].location.state.bankIdError })
    }

    this.get();
    this.setState({ showForm: true })


    // if (localStorage.getItem('checkHost') && typeof localStorage !== 'undefined' && this.props.uData.userLevel != 100) {
    //   if (this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.licenceMain) {
    //     this.props[0].history.push('/data-managment/meetings')
    //   }
    //   localStorage.removeItem('checkHost')
    // }

    if (this.props.uData && this.props.uData.experienceDate) {
      let experienceDate = new Date(this.props.uData.experienceDate);
      experienceDate.setHours(23, 59, 59);
      let experienceDateTs = Math.floor(experienceDate.getTime() / 1000)
      let sevenDayBeforeExperienceDateTs = experienceDateTs - 60 * 60 * 24 * 7;
      let nowTs = Math.floor(new Date().getTime() / 1000);
      if (nowTs < experienceDateTs && nowTs > sevenDayBeforeExperienceDateTs) {
        this.setState({ renewLicence: true })
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps[0].location.search != this.props[0].location.search) {
      this.setState({ loading: true, }, () => {
        this.get();
      });
    }

    if (this.props.uData && !this.state.companyData && this.props.uData.companyID && this.props.uData.profileType == 'personal') {
      fetch(API_ENDPOINT + "/users/account/company-data/" + this.props.uData.companyID, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: typeof localStorage !== "undefined" ? `Bearer ${localStorage.getItem("authToken")}` : null,
        },
      }).then((res) => res.json()).then((result) => {
        if (result) {
          this.setState({
            companyData: result
          })
        }

      });

    };

    if (prevProps.selectCountry != this.props.selectCountry) {
      this.setState({
        refresh: new Date().getTime()
      })
    }




    if (this.state.licence && this.state.licence.length && this.state.licenceName && this.state.licenceDuration && this.state.licenceName != -1 && this.state.licenceDuration != -1 && (this.state.licenceName != prevState.licenceName || this.state.licenceDuration != prevState.licenceDuration)) {
      // for (let i = 0; i < this.state.licence.length; i++) {

      //   if (this.state.licence[i].name == this.state.licenceName) {
      //     if (this.state.licenceDuration == 1) {
      //       this.setState({
      //         usersLicence: this.state.licence[i]._id,
      //         usersLicencePrice: this.state.licence[i].price1,
      //         usersLicenceName: this.props.lang == 'en' ? this.state.licence[i].name : this.state.licence[i].nameSv,
      //       })
      //     } else if (this.state.licenceDuration == 3) {
      //       this.setState({
      //         usersLicence: this.state.licence[i]._id,
      //         usersLicencePrice: this.state.licence[i].price3,
      //         usersLicenceName: this.props.lang == 'en' ? this.state.licence[i].name : this.state.licence[i].nameSv,
      //       })
      //     } else if (this.state.licenceDuration == 6) {
      //       this.setState({
      //         usersLicence: this.state.licence[i]._id,
      //         usersLicencePrice: this.state.licence[i].price6,
      //         usersLicenceName: this.props.lang == 'en' ? this.state.licence[i].name : this.state.licence[i].nameSv,
      //       })
      //     } else if (this.state.licenceDuration == 12) {
      //       this.setState({
      //         usersLicence: this.state.licence[i]._id,
      //         usersLicencePrice: this.state.licence[i].price12,
      //         usersLicenceName: this.props.lang == 'en' ? this.state.licence[i].name : this.state.licence[i].nameSv,
      //       })
      //     } else if (this.state.licenceDuration == 0.004107) {
      //       this.setState({
      //         usersLicence: this.state.licence[i]._id,
      //         usersLicencePrice: this.state.licence[i].callPrice,
      //         usersLicenceName: this.props.lang == 'en' ? this.state.licence[i].name : this.state.licence[i].nameSv,
      //       })
      //     }

      //   }

      // }
    }

    // if (this.state.smsLicenceName && this.state.smsLicenceName != -1 && this.state.smsLicence && this.state.smsLicence.length && this.state.smsLicenceName != prevState.smsLicenceName) {
    //   for (let i = 0; i < this.state.smsLicence.length; i++) {
    //     if (this.state.smsLicenceName == this.state.smsLicence[i]._id) {
    //       this.setState({
    //         smsLicenceName: this.state.smsLicence[i].name,
    //         smsUserLicencePrice: this.state.smsLicence[i].smsPrice,
    //         smsUsersLicence: this.state.smsLicence[i]._id
    //       })
    //     }

    //   }
    // }

    if (this.props.notApplicable) {
      this.props.changeVatNumber('')
    }

    if (typeof prevProps.selectedWaitingTimeType != "undefined" && prevProps.selectedWaitingTimeType != this.props.selectedWaitingTimeType) {
      if (this.props.selectedWaitingTimeType == 0) {
        this.props.changeMinValue("");
        this.props.changeMaxValue("");
      } else if (this.props.selectedWaitingTimeType == 1) {
        this.props.changeMinValue(0);
        this.props.changeMaxValue(0);
      } else if (this.props.selectedWaitingTimeType == 2) {
        this.props.changeMinValue("");
        this.props.changeMaxValue(0);
      } else if (this.props.selectedWaitingTimeType == 3) {
        this.props.changeMinValue(0);
        this.props.changeMaxValue("");
      } else if (this.props.selectedWaitingTimeType == 4) {
        this.props.changeMinValue("");
        this.props.changeMaxValue("");
      } else if (this.props.selectedWaitingTimeType == 5) {
        this.props.changeMinValue("");
        this.props.changeMaxValue("");
      }
    }

    if (prevProps.uData != this.props.uData && this.props.uData && this.props.uData.experienceDate) {
      let experienceDate = new Date(this.props.uData.experienceDate);
      experienceDate.setHours(23, 59, 59);
      let experienceDateTs = Math.floor(experienceDate.getTime() / 1000)
      let sevenDayBeforeExperienceDateTs = experienceDateTs - 60 * 60 * 24 * 7;
      let nowTs = Math.floor(new Date().getTime() / 1000);
      if (nowTs < experienceDateTs && nowTs > sevenDayBeforeExperienceDateTs) {
        this.setState({ renewLicence: true })
      }
    }
  }

  updateSort = (field, type) => {
    if (this.state.useState) {
      let state = this.state.params;
      state.sortField = field;
      state.sortType = type;
      this.setState({
        params: state,
      });
    } else {
      this.props[0].history.push(
        generateSearchLinkMultiple(
          this.props[0].location.search,
          { entries: 10, page: 0 },
          [
            { name: "sortField", value: field },
            { name: "sortType", value: type },
          ],
          false
        )
      );
    }
  };

  updateParams = (name, value, restart = false) => {
    if (this.state.useState) {
      if (restart) {
        let obj = {
          entries: 10,
          page: 0,
        };
        obj[name] = value;
        this.setState({ params: obj });
      } else {
        let obj = this.state.params;
        obj[name] = value;
        this.setState({ params: obj });
      }
    } else {
      this.props[0].history.push(
        generateSearchLink(
          this.props[0].location.search,
          { entries: 10, page: 0 },
          name,
          value,
          restart
        )
      );
    }
  };
  updateMultipleParams = (fields, restart = false) => {
    if (this.state.useState) {
      if (restart) {
        let obj = {
          entries: 10,
          page: 0,
        };
        for (let i = 0; i < fields.length; i++) {
          obj[fields[i].name] = fields[i].value;
        }
        this.setState({ params: obj });
      } else {
        let obj = this.state.params;
        for (let i = 0; i < fields.length; i++) {
          obj[fields[i].name] = fields[i].value;
        }
        this.setState({ params: obj });
      }
    } else {
      this.props[0].history.push(
        generateSearchLinkMultiple(
          this.props[0].location.search,
          { entries: 10, page: 0 },
          fields,
          restart
        )
      );
    }
  };

  stopAnimation = () => {
    this.player.current.play();
  };

  /**
  * Update profile data
  * @author   Milan Stanojevic
  * @Objectparam    {Object} data    form data
  */
  update = (data) => {
    if ((data.organisationName != '' && data.organisationName) || (data.organisationNumber != '' && data.organisationNumber) || (data.organisationStreet != '' && data.organisationStreet) || (data.organisationCity != '' && data.organisationCity) || (data.organisationZip != '' && data.organisationZip)) {
      if (!(data.organisationName != '' && data.organisationNumber != '' && data.organisationStreet != '' && data.organisationCity != '' && data.organisationZip != '' && data.organisationName && data.organisationNumber && data.organisationStreet && data.organisationCity && data.organisationZip)) {
        this.setState({
          error: 'If you have organisation you have to fill all fields in organisation form!'.translate(this.props.lang)
        })
      } else {
        this.setState(
          {
            showPlayer: true,
          },
          () => {
            this.player.current.play();
            fetch(API_ENDPOINT + "/users/account/update", {
              method: "POST",
              headers: {
                Authorization:
                  typeof localStorage !== "undefined"
                    ? `Bearer ${localStorage.getItem("authToken")}`
                    : null,
                "content-type": "application/json",
              },
              body: JSON.stringify(data),
            })
              .then(parseJSON)
              .then(({ result, status }) => {
                if (result.error) {
                  this.setState({ error: result.error, showPlayer: false });
                } else {
                  if (result.logoutAndVerify) {
                    this.setState({
                      registrationSuccessful: true
                    })
                  } else {
                    this.setState(
                      {
                        message: result.message,
                        showPlayer: false,
                        showPlayer2: true,
                      },
                      () => {
                        setTimeout(() => {
                          this.setState({ showPlayer2: false });
                        }, 1000);
                      }
                    );
                    //this.player.current.stop();
                    this.props.verifyUser(() => {
                      this.forceUpdate()
                    });
                  }
                }
              });
          }
        );
      }
    } else {
      this.setState(
        {
          showPlayer: true,
        },
        () => {
          this.player.current.play();
          fetch(API_ENDPOINT + "/users/account/update", {
            method: "POST",
            headers: {
              Authorization:
                typeof localStorage !== "undefined"
                  ? `Bearer ${localStorage.getItem("authToken")}`
                  : null,
              "content-type": "application/json",
            },
            body: JSON.stringify(data),
          })
            .then(parseJSON)
            .then(({ result, status }) => {
              if (result.error) {
                this.setState({ error: result.error, showPlayer: false });
              } else {
                if (result.logoutAndVerify) {
                  this.setState({
                    registrationSuccessful: true
                  })
                } else {
                  this.setState(
                    {
                      message: result.message,
                      showPlayer: false,
                      showPlayer2: true,
                    },
                    () => {
                      setTimeout(() => {
                        this.setState({ showPlayer2: false });
                      }, 1000);
                    }
                  );
                  //this.player.current.stop();
                  this.props.verifyUser(() => {
                    this.forceUpdate()
                  });
                }

              }
            });
        }
      );
    }

  };

  /**
  * Update language for user
  * @author   Milan Stanojevic
  * @Objectparam    {Object} data    form data
  */
  updateLanguage = (data) => {
    this.setState(
      {
        showPlayer: true,
        loadLang: true
      },
      () => {
        this.player.current.play();
        fetch(API_ENDPOINT + "/users/account/update-userdata", {
          method: "POST",
          headers: {
            Authorization:
              typeof localStorage !== "undefined"
                ? `Bearer ${localStorage.getItem("authToken")}`
                : null,
            "content-type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then(parseJSON)
          .then(({ result, status }) => {
            if (result.error) {
              this.setState({ error: result.error, showPlayer: false });
            } else {
              this.setState(
                {
                  message: result.message,
                  showPlayer: false,
                  showPlayer2: true,

                },
                () => {
                  setTimeout(() => {
                    this.setState({ showPlayer2: false, loadLang: false });
                  }, 1000);
                }
              );
              //this.player.current.stop();
              this.props.verifyUser(() => {
                this.setState({
                  showForm: null,
                }, () => {
                  this.setState({
                    showForm: true
                  })
                })
              });
            }
          });
      }
    );
  };

  /**
  * Upload profile image
  * @author   Milan Stanojevic
  */
  uploadImage = (e) => {

    let input = e.target;
    if (input.files && input.files[0]) {
      let fsize = input.files[0].size;
      let file = Math.round((fsize / 1024))

      if (file > 5120) {
        this.setState({ error: "File size limit has been reached! (max 5 MB)".translate(this.props.lang) })
      } else {
        this.setState({
          _loading: true,
        });

        let formData = new FormData();
        formData.append("file", input.files[0]);

        fetch(API_ENDPOINT + "/data/upload-image", {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization:
              typeof localStorage !== "undefined"
                ? `Bearer ${localStorage.getItem("authToken")}`
                : null,
          },
          body: formData,
        })
          .then(parseText).then(({ result, status }) => {
            if (status != 200) {
              this.setState({ error: result })
            } else {
              this.updateLanguage({ profilePicture: result });
              this.setState({
                _loading: null
              })
            }

          })
      }
    }
  };
  disconnect = (field) => {
    this.setState({ loading: true }, () => {


      fetch(API_ENDPOINT + "/users/account/disconnect", {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization:
            typeof localStorage !== "undefined"
              ? `Bearer ${localStorage.getItem(
                "authToken"
              )}`
              : null,
        },
        body: JSON.stringify({
          field: field
        })
      }).then((res) => res.json())
        .then((result) => {
          this.props.verifyUser(() => {
            this.forceUpdate()
          });
        });
    })
  }

  openPayson = (data) => {
    this.setState({
      paysonModalControl: data,

    })
  }
  openSwish = (data) => {
    this.setState({
      swishModalControl: data,

    })
  }

  calculateLicence = (item) => {
    let check
    for (let i = 0; i < this.state.licence.length; i++) {
      if (this.props.uData.licenceID == this.state.licence[i]._id) {
        check = this.state.licence[i]
      }

    }
    if (Number(check.participants) < Number(item.participants)) {
      return true
    } else {
      return false
    }
  }

  findUser = (email) => {
    fetch(API_ENDPOINT + "/users/account/find-user/" + email, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: typeof localStorage !== "undefined" ? `Bearer ${localStorage.getItem("authToken")}` : null,
      },
    }).then((res) => res.json()).then((result) => {
      if (result.name) {
        this.setState({
          name: result.name
        })
      } else {
        this.setState({
          name: ''
        })
      }

    });
  }

  // assignLicence = (data) => {

  //   fetch(API_ENDPOINT + "/users/account/set-licence", {
  //     method: "POST",
  //     headers: {
  //       "content-type": "application/json",
  //       Authorization: typeof localStorage !== "undefined" ? `Bearer ${localStorage.getItem("authToken")}` : null,
  //     },
  //     body: JSON.stringify({ email: data.email, parentUser: this.props.uData._id, name: data.name })
  //   }).then((res) => res.json()).then((result) => {
  //     this.setState({
  //       form: null,
  //       name: '',
  //       userAssignLicence: {}
  //     }, () => this.get())
  //   });

  // }

  deleteConnectedUser = (data) => {
    fetch(API_ENDPOINT + '/users/account/delete-licence/' + data._id, {
      method: 'POST',
      headers: {
        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
        'content-type': 'application/json'
      },
    }).then(res => res.json()).then((result) => {
      this.get();
    })

  }

  deleteMyAccount = () => {
    if (this.state.deleteMyAccountModal && this.state.deleteMyAccountModal.reason) {
      let data = { ...this.state.deleteMyAccountModal };



      fetch(API_ENDPOINT + '/users/account/delete-account/request', {
        method: 'POST',
        headers: {
          'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
          'content-type': 'application/json'
        },
        body: JSON.stringify(data)
      }).then(res => res.json()).then((result) => {
        if (result.error) {
          this.setState({ error: result.error })
        } else {
          this.setState({ deleteMyAccountModal: null })
        }
      })
    }
  }



  render() {

    let params = {};
    if (this.state.useState) {
      params = this.state.params;
    } else {
      params = getSearchParams(this.props[0].location.search, {
        entries: 10,
        page: 0,
      });
    }
    // console.log(this.props);
    const waitingTimeDisabled = [
      [true, true],
      [true, true],
      [false, true],
      [true, false],
      [false, false],
      [false, false],
    ];

    const defaultOptions = {
      loop: true,
      autoplay: true,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    let selectedGroupIdx = -1;
    if (this.props.selectedGroup && this.props.uData.clinicGroups) {
      for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
        if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
          selectedGroupIdx = i;
          break;
        }
      }
    }
    // console.log(this.props[0]);
    return (
      <div className="dashboard">
        <div className={this.props.to}>
          <Container fluid>
            <Row>
              <Col lg="12">

                {this.state.error ? (
                  <ErrorModal lang={this.props.lang}
                    isOpen={this.state.error}
                    toggle={() => this.setState({ error: null })}

                  >
                    {this.state.error.translate(this.props.lang)}
                  </ErrorModal>
                ) : null}
                {this.state.bankIdError ? (
                  <ErrorModal lang={this.props.lang}
                    isOpen={this.state.bankIdError}
                    toggle={() => this.setState({ bankIdError: null })}

                  >
                    {this.state.bankIdError.translate(this.props.lang)}
                  </ErrorModal>
                ) : null}

                <div className="panel">
                  <Row>
                    <Col lg="12">

                      <div className="account-title">
                        <h5 className="component-header">
                          {this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ""}
                        </h5>
                      </div>
                      <div className="account-header">
                        <div className="profile">
                          <div style={{ position: 'relative', marginRight: 15 }}>
                            <img src={this.props.uData && this.props.uData.profilePicture ? API_ENDPOINT + this.props.uData.profilePicture : profileUser}
                            />
                            {
                              this.state._loading ?
                                <div className="file-progress-loader">
                                  <div>
                                    <Player
                                      onEvent={(event) => {
                                        if (event === "load" && this.player && this.player.current) this.player.current.play();
                                      }}
                                      ref={this.player} // set the ref to your class instance
                                      autoplay={true}
                                      loop={true}
                                      controls={true}
                                      src="/lf30_editor_l5cxzdyf.json"
                                      renderer={'svg'}
                                      style={{ height: "128px", width: "128px", }}
                                    ></Player>
                                    <h6>{'Processing file...'.translate(this.state.lang)}</h6>
                                  </div>

                                </div>
                                :
                                null
                            }
                          </div>


                          {this.props.uData && this.props.uData.profilePicture ?
                            <Button
                              className="upload-file"
                              onClick={() => {
                                this.update({ profilePicture: null, notifications: this.props.uData.notifications });
                              }}
                            >
                              {'Remove'.translate(this.props.lang)}
                            </Button>
                            :
                            <Button className="upload-file" onClick={() => { this.uploadButton.click(); }}
                            >
                              {'Upload'.translate(this.props.lang)}
                              <input
                                type="file"
                                title={"No file chosen".translate(this.props.lang)}
                                accept=".jpg, .jpeg, .JPG, .JPEG, .png, .gif"
                                ref={(node) => {
                                  this.uploadButton = node;
                                }}
                                onChange={this.uploadImage}
                              />
                            </Button>
                          }
                        </div>
                        <Col lg={!(this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.host && (this.props.uData.licenceObj.licenceMain || this.props.uData.licenceObj.licenceType === 'basic')) ? "1" : "3"} className='mb-p0'>
                          {this.props.uData && this.props.uData.userLevel != 100 ?

                            <FormGroup className="account-clinics-group">

                              {
                                this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.host && this.props.uData.licenceObj.licenceMain ?

                                  <div className="bottom-line">

                                    <div className="profile-two-box">
                                      <div>
                                        <div className="licence-type">
                                          <strong> {'Licence type:'.translate(this.props.lang)}  </strong>
                                          <p style={{ marginBottom: 0, marginLeft: 8 }}>
                                            {this.props.uData && this.props.uData.licenceObj ? this.props.lang == 'en' ? this.props.uData.licenceObj.licenceMain : this.props.uData.licenceObj.licenceMainSv : ''} - {
                                              this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.numberOfLicencesMain ?
                                                this.props.uData.licenceObj.numberOfLicencesMain + " " + 'licences'.translate(this.props.lang)
                                                :
                                                null
                                            }
                                          </p>
                                        </div>
                                        <div className="flex">
                                          <strong> {'Renewal date:'.translate(this.props.lang)} </strong>
                                          <p style={{ marginBottom: 7, marginLeft: 8 }}> {
                                            this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.experienceDateMain ?
                                              this.props.uData.licenceObj.experienceDateMain
                                              :
                                              null
                                          } </p>
                                        </div>
                                        {/* <div className="flex">
                                        <strong> {'Number of licencies:'.translate(this.props.lang)} </strong>
                                        <p style={{ marginBottom: 7, marginLeft: 8 }}> {
                                          this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.numberOfLicencesMain ?
                                            this.props.uData.licenceObj.numberOfLicencesMain
                                            :
                                            null
                                        } </p>
                                      </div> */}
                                        {
                                          this.state.renewLicence && this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.licenceMainDuration >= 1 && !this.props.uData.licenceObj.unpaidInvoice ?
                                            <div className="flex">
                                              <Button color='primary' onClick={() => this.setState({ addLicence: true })}>{'Renew licence'.translate(this.props.lang)}</Button>
                                              {this.props.uData.licenceObj && this.props.uData.licenceObj.licenceMain.indexOf('Enterprise') == -1 ?
                                                <Button style={{ marginLeft: 10 }} color='primary' onClick={() => this.props[0].history.push('/buy-licence')  /*this.setState({ upgradeLicence: true })*/}>{'Upgrade licence'.translate(this.props.lang)}</Button>
                                                :
                                                null}
                                            </div>
                                            :
                                            this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.licenceMain.indexOf('Enterprise') == -1 && !this.props.uData.licenceObj.unpaidInvoice ?
                                              <Button color='primary' onClick={() => this.props[0].history.push('/buy-licence')  /*this.setState({ upgradeLicence: true })*/}>{'Upgrade licence'.translate(this.props.lang)}</Button>
                                              :
                                              null

                                        }
                                      </div>

                                    </div>

                                  </div>

                                  :
                                  this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.host && this.props.uData.licenceObj.licenceType === 'basic' ?

                                    <div className="bottom-line">

                                      <div className="profile-two-box">
                                        <div>
                                          <div className="licence-type" style={{ marginBottom: this.props.uData.assignedLicence && this.props.uData.assignedLicence.experienceDate && this.props.uData.activeLicence ? 0 : 10 }}>
                                            <strong> {'Licence type:'.translate(this.props.lang)}  </strong>
                                            <p style={{ marginBottom: 0, marginLeft: 8 }}>
                                              {
                                                this.props.uData.assignedLicence && this.props.uData.assignedLicence.experienceDate && this.props.uData.activeLicence && !this.props.uData.companyDeactivated ?
                                                  this.props.uData.activeLicence
                                                  :
                                                  'Basic'.translate(this.props.lang)

                                              }

                                            </p>
                                          </div>
                                          {
                                            this.props.uData.assignedLicence && this.props.uData.assignedLicence.experienceDate && this.props.uData.assignedLicence && this.props.uData.activeLicence && !this.props.uData.companyDeactivated ?
                                              <div className="flex">
                                                <strong> {'Renewal date:'.translate(this.props.lang)} </strong>
                                                <p style={{ marginBottom: 7, marginLeft: 8 }}> {moment.unix(this.props.uData.assignedLicence.experienceDate).format(this.props.dateFormat)} </p>
                                              </div>
                                              :
                                              null
                                          }


                                          {
                                            !(this.props.uData.assignedLicence && this.props.uData.assignedLicence.experienceDate && this.props.uData.assignedLicence && this.props.uData.activeLicence) && this.props.uData.licenceObj.licenceType !== 'basic' ?
                                              <Button color='primary' onClick={() => this.props[0].history.push('/buy-licence')}>{'Upgrade licence'.translate(this.props.lang)}</Button>
                                              :
                                              null
                                          }

                                        </div>

                                      </div>

                                    </div>

                                    :
                                    null

                              }


                            </FormGroup>
                            : null}
                        </Col>
                        <Col lg={!(this.props.uData && this.props.uData.licenceObj && this.props.uData.licenceObj.host && (this.props.uData.licenceObj.licenceMain || this.props.uData.licenceObj.licenceType === 'basic')) ? "8" : !(this.props.uData && this.props.uData.assignedLicence && this.props.uData.assignedLicence.userAssigned) ? "3" : "5"}>
                          {this.props.uData && this.props.uData.assignedLicence && this.props.uData.assignedLicence.userAssigned ?

                            <FormGroup className="account-assigned-licence">
                              {

                                <div className="bottom-line">

                                  {/* <div className="profile-two-box">
                                    <div>
                                      <h6 style={{ fontWeight: '700' }}>{'Assigned license(s)'.translate(this.props.lang)}</h6>
                                      <div className="licence-type">
                                        <strong> {'Assigned by:'.translate(this.props.lang)}  </strong>
                                        <p style={{ marginBottom: 0, marginLeft: 8 }}>
                                          {this.props.uData && this.props.uData.assignedLicence && this.props.uData.assignedLicence.userAssigned ?
                                            this.props.uData.assignedLicence.userAssigned
                                            :
                                            null
                                          }
                                        </p>
                                      </div>
                                      <div className="flex">
                                        <strong> {'Plan:'.translate(this.props.lang)} </strong>
                                        <p style={{ marginBottom: 0, marginLeft: 8 }}>
                                          {
                                            this.props.uData && this.props.uData.activeLicence ?
                                              this.props.uData.activeLicence
                                              :
                                              null
                                          } </p>
                                      </div>
                                      <div className="flex">
                                        <strong> {'Expiration date:'.translate(this.props.lang)} </strong>
                                        <p style={{ marginBottom: 7, marginLeft: 8 }}>
                                          {this.props.uData && this.props.uData.assignedLicence && this.props.uData.assignedLicence.experienceDate ?
                                            moment.unix(this.props.uData.assignedLicence.experienceDate).format('YYYY-MM-DD')
                                            :
                                            null
                                          } </p>
                                      </div>
                                      <div className="profile-footer">
                                        <button onClick={() => {
                                          this.setState({
                                            showAll: true
                                          })
                                        }}>{'Show all'.translate(this.props.lang)}</button>
                                      </div>

                                    </div>

                                  </div> */}

                                </div>
                              }


                            </FormGroup>
                            : null}
                        </Col>

                        <div className="language">
                          <label for="languages">{'Language'.translate(this.props.lang)}</label>
                          <select
                            value={this.props.uData && this.props.uData.language}
                            onChange={(e) => {
                              this.updateLanguage({ language: e.target.value });
                            }}
                          >
                            {this.state.languages &&
                              this.state.languages.map((item, idx) => {
                                return (
                                  <option key={idx} value={item._id}>
                                    {item.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                    </Col>
                    <Col lg="12">
                      <div className="form-group-row">
                        <div className="form-group-col">
                          {
                            // this.props.profileType == 'personal' ?
                            //   <FormGroup className="account-details">

                            //     {"First and last name".translate(this.props.lang)}

                            //     <br /> <strong>{this.props.uData.userData && this.props.uData.userData.title ? this.props.formatName(this.props.uData.userData.title, this.props.uData.userData.name) : ''}</strong>
                            //   </FormGroup>
                            //   :
                            <FormGroup className="account-details">

                              {this.props.uData.profileType == 'personal' ? 'First and last name'.translate(this.props.lang) : "Company name".translate(this.props.lang)}

                              {/* <br /><strong>{this.props.uData.userData && this.props.uData.profileType == 'personal' ? this.props.uData.userData.name : ''}</strong> */}
                              <br /> <strong>{this.props.uData.userData && this.props.uData.profileType == 'personal' ? this.props.uData.userData.name : this.props.uData && this.props.uData.userData && this.props.uData.userData.companyName ? this.props.uData.userData.companyName : ''}</strong>
                            </FormGroup>
                          }


                          <FormGroup className="account-details">
                            {"Email address / Username".translate(this.props.lang)}: <br />{" "}
                            <strong>{this.props.uData.email}</strong>
                          </FormGroup>
                        </div>

                        <div className="form-group-col">

                          {this.props.uData.userLevel != 1 && this.props.uData.userLevel != 2 && (typeof localStorage && localStorage.getItem('profile') != 'patient') ? (
                            <div>

                              <FormGroup className="account-details">
                                {"Account number".translate(this.props.lang)}: <br />{" "}
                                <strong>{this.props.uData.accountNumber}</strong>
                              </FormGroup>

                            </div>
                          ) : null}
                        </div>

                        {/* <div className="form-group-col">
                          {
                            this.props.uData.userLevel != 1 && this.props.uData.userLevel != 2 && (typeof localStorage && localStorage.getItem('profile') != 'patient') ?
                              (
                                <FormGroup className="account-details">
                                  {"BankID".translate(this.props.lang)}: <br />
                                  {this.props.uData.bankId ? (
                                    <>
                                      <strong>{"Connected".translate(this.props.lang)} </strong>
                                      <Button
                                        onClick={() => this.setState({ disconnectModal: 'bankId' })}
                                        color="primary"
                                        size="sm"
                                      >
                                        {"Disconnect".translate(this.props.lang)}
                                      </Button>
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      <strong>
                                        {"Not connected".translate(this.props.lang)}
                                      </strong>{" "}
                                      <Button
                                        onClick={() => {
                                          this.setState({
                                            openBankIDModal: true
                                          })
                                        }}
                                        color="primary"
                                        size="sm"
                                      >
                                        {"Connect".translate(this.props.lang)}
                                      </Button>
                                    </>
                                  )}
                                </FormGroup>
                              )
                              :
                              null
                          }
                          {
                            this.props.uData.userLevel != 1 && this.props.uData.userLevel != 2 && (typeof localStorage && localStorage.getItem('profile') != 'patient') ?
                              (
                                <FormGroup className="account-details">
                                  {"SITHS".translate(this.props.lang)}: <br />
                                  {this.props.uData.netId ? (
                                    <>
                                      <strong>{"Connected".translate(this.props.lang)} </strong>
                                      <Button
                                        onClick={() => this.setState({ disconnectModal: 'netId' })}
                                        color="primary"
                                        size="sm"
                                      >
                                        {"Disconnect".translate(this.props.lang)}
                                      </Button>
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      <strong>
                                        {"Not connected".translate(this.props.lang)}
                                      </strong>{" "}
                                      <Button
                                        onClick={() => {
                                          fetch(API_ENDPOINT + "/users/netid/account/start", {
                                            method: "GET",
                                            headers: {
                                              "content-type": "application/json",
                                              Authorization: typeof localStorage !== "undefined" ? `Bearer ${localStorage.getItem("authToken")}` : null,
                                            },
                                          }).then((res) => res.json()).then((result) => {
                                            if (result.redirectUrl) {
                                              window.location.replace(
                                                result.redirectUrl
                                              );
                                            }
                                          });
                                        }}
                                        color="primary"
                                        size="sm"
                                      >
                                        {"Connect".translate(this.props.lang)}
                                      </Button>
                                    </>
                                  )}
                                </FormGroup>
                              )
                              :
                              null
                          }
                          {
                            this.props.uData.userLevel != 1 && this.props.uData.userLevel != 2 && (typeof localStorage && localStorage.getItem('profile') != 'patient') ?
                              (
                                <FormGroup className="account-details">
                                  {"Login code to mail".translate(this.props.lang)}: <br />
                                  {this.props.uData.bankId ? (
                                    <>
                                      <strong>{"Activated".translate(this.props.lang)} </strong>
                                      <Button
                                        onClick={() => this.setState({ disconnectModal: 'code' })}
                                        color="primary"
                                        size="sm"
                                      >
                                        {"Deactivate".translate(this.props.lang)}
                                      </Button>
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      <strong>
                                        {"Not activated".translate(this.props.lang)}
                                      </strong>{" "}
                                      <Button
                                        onClick={() => {
                                          //todo 
                                        }}
                                        color="primary"
                                        size="sm"
                                      >
                                        {"Activate".translate(this.props.lang)}
                                      </Button>
                                    </>
                                  )}
                                </FormGroup>
                              )
                              :
                              null
                          }


                        </div> */}

                        <div className="form-group-col form-account-fields">
                          {
                            this.props.uData.activeLicence && this.props.uData.activeLicence !== 'basic' && typeof localStorage && localStorage.loginMethod && localStorage.loginMethod !== 'netid' ?
                              <FormGroup className="account-details">
                                <div className="account-details-content account-details-content1">
                                  {"BankID".translate(this.props.lang)}:
                                  <strong>{this.props.uData.bankId ? "Active".translate(this.props.lang) : "Not active".translate(this.props.lang)} </strong>
                                </div>
                                {this.props.uData.bankId ? (
                                  <div>
                                    <Button
                                      onClick={() => this.setState({ disconnectModal: 'bankId' })}
                                      color="primary"
                                      size="sm"
                                    >
                                      {"Deactivate".translate(this.props.lang)}
                                    </Button>
                                  </div>
                                ) : (
                                  <div>
                                    <Button
                                      disabled={typeof localStorage && localStorage.loginMethod && localStorage.loginMethod === 'netid'}
                                      onClick={() => {
                                        this.setState({
                                          openBankIDModal: true
                                        })
                                      }}
                                      color="primary"
                                      size="sm"
                                    >
                                      {"Activate".translate(this.props.lang)}
                                    </Button>
                                  </div>
                                )}
                              </FormGroup>
                              :
                              null
                          }
                          {
                            this.props.uData.activeLicence && this.props.uData.activeLicence !== 'basic' && typeof localStorage && localStorage.loginMethod && localStorage.loginMethod !== 'bankid' ?
                              <FormGroup className="account-details">
                                <div className="account-details-content account-details-content2">
                                  {"SITHS".translate(this.props.lang)}:
                                  <strong>{this.props.uData.netId ? "Active".translate(this.props.lang) : "Not active".translate(this.props.lang)} </strong>
                                </div>

                                {this.props.uData.netId ? (
                                  <div>
                                    <Button
                                      onClick={() => this.setState({ disconnectModal: 'netId' })}
                                      color="primary"
                                      size="sm"
                                    >
                                      {"Deactivate".translate(this.props.lang)}
                                    </Button>
                                  </div>
                                ) : (
                                  <div>
                                    <Button
                                      disabled={typeof localStorage && localStorage.loginMethod && localStorage.loginMethod === 'bankid'}
                                      onClick={() => {
                                        fetch(API_ENDPOINT + "/users/netid/account/start", {
                                          method: "GET",
                                          headers: {
                                            "content-type": "application/json",
                                            Authorization: typeof localStorage !== "undefined" ? `Bearer ${localStorage.getItem("authToken")}` : null,
                                          },
                                        }).then((res) => res.json()).then((result) => {
                                          if (result.redirectUrl) {
                                            window.location.replace(
                                              result.redirectUrl
                                            );
                                          }
                                        });
                                      }}
                                      color="primary"
                                      size="sm"
                                    >
                                      {"Activate".translate(this.props.lang)}
                                    </Button>
                                  </div>
                                )}
                              </FormGroup>
                              :
                              null
                          }
                          {
                            typeof localStorage && localStorage.loginMethod && localStorage.loginMethod !== 'bankid' && localStorage.loginMethod !== 'netid' ?
                              <FormGroup className="account-details">
                                <div className="account-details-content account-details-content3">
                                  {"Login code to mail".translate(this.props.lang)}:
                                  <strong>{this.props.uData.loginCode ? "Active".translate(this.props.lang) : "Not active".translate(this.props.lang)} </strong>
                                </div>
                                {this.props.uData.loginCode ? (
                                  <div>
                                    <Button
                                      onClick={() => this.setState({ disconnectModal: 'code' })}
                                      color="primary"
                                      size="sm"
                                    >
                                      {"Deactivate".translate(this.props.lang)}
                                    </Button>
                                  </div>
                                ) : (
                                  <div>
                                    <Button
                                      disabled={typeof localStorage && localStorage.loginMethod && (localStorage.loginMethod === 'bankid' || localStorage.loginMethod === 'netid')}
                                      onClick={() => {
                                        fetch(API_ENDPOINT + "/data/users/accounts/login-code", {
                                          method: "POST",
                                          headers: {
                                            "content-type": "application/json",
                                            Authorization: typeof localStorage !== "undefined" ? `Bearer ${localStorage.getItem("authToken")}` : null,
                                          },
                                        }).then((res) => res.json()).then((result) => {

                                          this.props.verifyUser(() => {
                                            this.forceUpdate()
                                          });
                                        });
                                      }}
                                      color="primary"
                                      size="sm"
                                    >
                                      {"Activate".translate(this.props.lang)}
                                    </Button>
                                  </div>
                                )}
                              </FormGroup>
                              :
                              null
                          }
                          {
                            typeof localStorage && localStorage.loginMethod && localStorage.loginMethod === 'bankid' || localStorage.loginMethod === 'netid' ?
                              <div className="manage-bankid-netid-text">
                                {'To manage alternative login methods, you must be logged in using username and password.'.translate(this.props.lang)}
                              </div>
                              :
                              null
                          }

                        </div>

                      </div>


                      {(this.state.showForm && this.props.uData.profileType != 'personal') || (this.props.uData.profileType == 'personal' && this.state.companyData && this.state.showForm) ?
                        <FormBuilder dateFormat={this.props.dateFormat}
                          lang={this.props.lang}
                          onSubmit={(data) => this.update(data)}
                          initialValues={{ timezone: 'Europe/Amsterdam', ...this.props.uData, companyData: this.state.companyData }}
                          buttonText={
                            this.state.showPlayer ? (
                              <Player
                                onEvent={(event) => {
                                  if (event === "load" && this.player && this.player.current) this.stopAnimation();
                                }}
                                onStateChange={this.toggleVisibility}
                                ref={this.player} // set the ref to your class instance
                                autoplay={true}
                                loop={true}
                                controls={true}
                                src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                style={{ height: "30px", width: "30px" }}
                              ></Player>

                            ) : this.state.showPlayer2 ? (
                              <div style={{ display: 'flex', alignItems: 'center' }}><Player
                                onStateChange={this.toggleVisibility}
                                ref={this.player2} // set the ref to your class instance
                                autoplay={true}
                                loop={true}
                                controls={true}
                                src="https://assets10.lottiefiles.com/packages/lf20_CxyxLk.json"
                                style={{ height: "30px", width: "30px" }}
                              ></Player>
                                {'Saved'.translate(this.props.lang)}
                              </div>
                            ) : (
                              "Save".translate(this.props.lang)
                            )
                          }
                          fields={[
                            {
                              type: "row",
                              children: [

                                this.props.uData.userLevel == 20 && this.props.profileType == 'company' ?
                                  {
                                    type: "col",
                                    width: {
                                      lg: 4,
                                      sm: 12,
                                      xs: 12,
                                    },
                                    children: [
                                      {
                                        type: 'h6',
                                        text: 'Company data'.translate(this.props.lang)
                                      },
                                      {
                                        type: "text",
                                        name: "userData.companyName",
                                        autoComplete: 'new-password',
                                        label: 'Company name'.translate(this.props.lang),
                                        validate: [required("Company name is required!".translate(this.props.lang))],
                                      },
                                      {
                                        type: "selectCountryOption",
                                        name: "userData.country",
                                        autoComplete: 'new-password',
                                        label: 'Country'.translate(this.props.lang),
                                        lang: this.props.lang,
                                        validate: [required('Country is required!'.translate(this.props.lang))]
                                      },
                                      this.props.selectCountry && this.props.selectCountry == 'Other' ?
                                        {
                                          type: "text",
                                          name: "userData.otherCountry",
                                          autoComplete: 'new-password',
                                          label: 'Other country'.translate(this.props.lang),
                                          validate: [required('Country is required!'.translate(this.props.lang))]
                                        }
                                        :
                                        null,
                                      {
                                        type: "text",
                                        name: "userData.name",
                                        autoComplete: 'new-password',
                                        label: 'Your name'.translate(this.props.lang),
                                        validate: [required("Your name is required!".translate(this.props.lang))],
                                        labelInfo: "Your name is displayed in video meetings.".translate(this.props.lang),
                                      },
                                      {
                                        type: 'row',
                                        children: [

                                          {
                                            type: 'col',
                                            width: {
                                              lg: 12,
                                              sm: 12,
                                              xs: 12,
                                            },
                                            children: [
                                              {
                                                type: 'text',
                                                className: 'vat-label-height',
                                                name: 'userData.vatNumber',
                                                label: 'VAT Number'.translate(this.props.lang),
                                                hideValidation: this.props.selectCountry && this.props.selectCountry != 'Sweden' && !this.props.notApplicable ? false : true,
                                                disabled: this.props.notApplicable ? true : false,
                                                labelInfo: this.props.selectCountry && this.props.selectCountry != 'Sweden' ? "Your tax registration number".translate(this.props.lang) : '',
                                                validate: this.props.selectCountry && this.props.selectCountry != 'Sweden' && !this.props.notApplicable ? [required('VAT Number is required!'.translate(this.props.lang)), vatNumberValidation('Vat Number is not valid'.translate(this.props.lang))] : [vatNumberValidation('Vat Number is not valid'.translate(this.props.lang))]
                                              },
                                              this.props.selectCountry && this.props.selectCountry != 'Sweden' ?
                                                {
                                                  type: 'checkbox',
                                                  label: 'Not applicable'.translate(this.props.lang),
                                                  name: 'userData.notApplicable',
                                                }
                                                : null

                                            ]
                                          },
                                          {
                                            type: 'col',
                                            width: {
                                              lg: 12,
                                              sm: 12,
                                              xs: 12,
                                            },
                                            children: [
                                              {
                                                type: 'text',
                                                name: 'userData.orgNr',
                                                label: 'Org.Nr'.translate(this.props.lang),
                                                validate: this.props.selectCountry && this.props.selectCountry == 'Sweden' ? [required('Org.Nr is required!'.translate(this.props.lang))] : ''
                                              },
                                            ]
                                          }
                                        ]
                                      },
                                      {

                                        type: "email",
                                        name: "email",
                                        label: "Email address / Username".translate(this.props.lang),

                                      },
                                      {
                                        type: "phone",
                                        name: "phone",
                                        autoComplete: 'new-password',
                                        label: "Phone number".translate(this.props.lang),
                                      },
                                      {
                                        type: "timeZonePicker",
                                        name: "timezone",
                                        validate: [required("Select time zone is required!".translate(this.props.lang))],
                                        label: "Time zone".translate(this.props.lang),
                                      },
                                      {
                                        type: "text",
                                        name: "street",
                                        label: "Street".translate(this.props.lang),
                                        validate: [required("Street is required!".translate(this.props.lang))],
                                      },
                                      {
                                        type: 'row',
                                        children: [
                                          {
                                            type: 'col',
                                            width: {
                                              lg: typeof window != 'undefined' && window.innerWidth < 1253 && window.innerWidth > 1200 ? 12 : 7,
                                              sm: 12,
                                              xs: 12,
                                            },
                                            children: [
                                              {
                                                type: "text",
                                                name: "city",
                                                label: "City".translate(this.props.lang),
                                                validate: [required("City is required!".translate(this.props.lang))],
                                              },
                                            ]
                                          },
                                          {
                                            type: 'col',
                                            width: {
                                              lg: typeof window != 'undefined' && window.innerWidth < 1253 && window.innerWidth > 1200 ? 12 : 5,
                                              sm: 12,
                                              xs: 12,
                                            },
                                            children: [
                                              {
                                                type: "text",
                                                name: "zip",
                                                label: "Postal code".translate(this.props.lang),
                                                validate: [required("Zip is required!".translate(this.props.lang))],
                                              },
                                            ]
                                          }
                                        ]
                                      }
                                    ],
                                  }
                                  :
                                  {
                                    type: "col",
                                    width: {
                                      lg: 4,
                                      sm: 12,
                                      xs: 12,
                                    },
                                    children: [
                                      {
                                        type: 'h6',
                                        text: 'User data'.translate(this.props.lang)
                                      },
                                      {
                                        type: 'row',
                                        children: [
                                          {
                                            type: 'col',
                                            width: {
                                              lg: 4,
                                              sm: 12,
                                              xs: 12,
                                            },
                                            children: [
                                              {
                                                type: "text",
                                                name: "userData.title",
                                                autoComplete: 'new-password',
                                                label: "Title".translate(this.props.lang),
                                              },
                                            ]
                                          },
                                          {
                                            type: 'col',
                                            width: {
                                              lg: 8,
                                              sm: 12,
                                              xs: 12,
                                            },
                                            children: [
                                              {
                                                type: "name",
                                                name: "userData.name",
                                                autoComplete: 'new-password',
                                                label: "First and last name".translate(this.props.lang),
                                                validate: [required("First and last name is required!".translate(this.props.lang))],
                                              },
                                            ]
                                          }
                                        ]
                                      },
                                      {
                                        type: "text",
                                        name: "name",
                                        label: "Display name".translate(this.props.lang),
                                      },
                                      {

                                        type: "email",
                                        name: "email",
                                        label: "Email address / Username".translate(this.props.lang) + '*',

                                      },
                                      {
                                        type: "phone",
                                        name: "phone",
                                        autoComplete: 'new-password',
                                        label: "Phone number".translate(this.props.lang),
                                      },
                                      {
                                        type: "timeZonePicker",
                                        name: "timezone",
                                        validate: [required("Select time zone is required!".translate(this.props.lang))],
                                        label: "Time zone".translate(this.props.lang),
                                      },
                                      {
                                        type: "text",
                                        name: "street",
                                        label: "Street".translate(this.props.lang),
                                      },
                                      {
                                        type: 'row',
                                        children: [
                                          {
                                            type: 'col',
                                            width: {
                                              lg: typeof window != 'undefined' && window.innerWidth < 1253 && window.innerWidth > 1200 ? 12 : 7,
                                              sm: 12,
                                              xs: 12,
                                            },
                                            children: [
                                              {
                                                type: "text",
                                                name: "city",
                                                label: "City".translate(this.props.lang),
                                              },
                                            ]
                                          },
                                          {
                                            type: 'col',
                                            width: {
                                              lg: typeof window != 'undefined' && window.innerWidth < 1253 && window.innerWidth > 1200 ? 12 : 5,
                                              sm: 12,
                                              xs: 12,
                                            },
                                            children: [
                                              {
                                                type: "text",
                                                name: "zip",
                                                label: "Postal code".translate(this.props.lang),
                                              },
                                            ]
                                          }
                                        ]
                                      },
                                    ],
                                  },
                                !this.props.uData.bankId || !this.props.uData.netId ?
                                  {
                                    type: "col",
                                    width: {
                                      lg: 4,
                                      sm: 6,
                                      xs: 12,
                                    },
                                    children: [
                                      {
                                        type: 'h6',
                                        text: 'Change password'.translate(this.props.lang)
                                      },
                                      this.props.uData.accounts[0].pk ?
                                        {
                                          type: "password",
                                          name: "oldPassword",
                                          autoComplete: 'new-password',
                                          label: "Old password".translate(this.props.lang),
                                        }
                                        : null,
                                      {
                                        type: "password",
                                        name: "newPassword",
                                        autoComplete: 'new-password',
                                        label: "New password".translate(this.props.lang),
                                      },
                                      {
                                        type: "password",
                                        name: "repeatPassword",
                                        autoComplete: 'new-password',
                                        label: "Repeat new password".translate(this.props.lang),
                                      },
                                    ],
                                  }
                                  :
                                  null,
                                this.props.uData.profileType == 'personal' && this.state.companyData ?
                                  {

                                    type: "col",
                                    width: {
                                      lg: 4,
                                      sm: 6,
                                      xs: 12,
                                    },
                                    children: [
                                      {
                                        type: 'h6',
                                        text: 'Company data'.translate(this.props.lang)
                                      },
                                      {
                                        type: "text",
                                        name: "companyData.userData.companyName",
                                        label: 'Company name'.translate(this.props.lang),
                                        disabled: true
                                      },
                                      {
                                        type: "text",
                                        name: "companyData.userData.country",
                                        label: 'Country'.translate(this.props.lang),
                                        lang: this.props.lang,
                                        disabled: true
                                      },
                                      this.props.selectCountry && this.props.selectCountry == 'Other' ?
                                        {
                                          type: "text",
                                          name: "companyData.userData.otherCountry",
                                          label: 'Other country'.translate(this.props.lang),
                                          disabled: true
                                        }
                                        :
                                        null,
                                      {
                                        type: "text",
                                        name: "companyData.userData.name",
                                        label: 'Company admin name'.translate(this.props.lang),
                                        disabled: true
                                      },
                                      {
                                        type: 'row',
                                        children: [

                                          {
                                            type: 'col',
                                            width: {
                                              lg: 12,
                                              sm: 12,
                                              xs: 12,
                                            },
                                            children: [
                                              {
                                                type: 'text',
                                                className: 'vat-label-height',
                                                name: 'companyData.userData.vatNumber',
                                                label: 'VAT Number'.translate(this.props.lang),
                                                disabled: true
                                              },
                                            ]
                                          },
                                          {
                                            type: 'col',
                                            width: {
                                              lg: 12,
                                              sm: 12,
                                              xs: 12,
                                            },
                                            children: [
                                              {
                                                type: 'text',
                                                name: 'companyData.userData.orgNr',
                                                label: 'Org.Nr'.translate(this.props.lang),
                                                disabled: true
                                              },
                                            ]
                                          },
                                          {
                                            type: 'col',
                                            width: {
                                              lg: 12,
                                              sm: 12,
                                              xs: 12,
                                            },
                                            children: [
                                              {
                                                type: "phone",
                                                name: "companyData.phone",
                                                autoComplete: 'new-password',
                                                label: "Phone number".translate(this.props.lang),
                                                disabled: true
                                              },
                                            ]
                                          },
                                          {
                                            type: 'col',
                                            width: {
                                              lg: 12,
                                              sm: 12,
                                              xs: 12,
                                            },
                                            children: [
                                              {
                                                type: "timeZonePicker",
                                                name: "companyData.timezone",
                                                validate: [required("Select time zone is required!".translate(this.props.lang))],
                                                label: "Time zone".translate(this.props.lang),
                                                disabled: true
                                              },
                                            ]
                                          },
                                          {
                                            type: 'col',
                                            width: {
                                              lg: 12,
                                              sm: 12,
                                              xs: 12,
                                            },
                                            children: [
                                              {
                                                type: "text",
                                                name: "companyData.street",
                                                label: "Street".translate(this.props.lang),
                                                disabled: true
                                              },
                                            ]
                                          },
                                          {
                                            type: 'col',
                                            width: {
                                              lg: typeof window != 'undefined' && window.innerWidth < 1253 && window.innerWidth > 1200 ? 12 : 7,
                                              sm: 12,
                                              xs: 12,
                                            },
                                            children: [
                                              {
                                                type: "text",
                                                name: "companyData.city",
                                                label: "City".translate(this.props.lang),
                                                disabled: true
                                              },
                                            ]
                                          },
                                          {
                                            type: 'col',
                                            width: {
                                              lg: typeof window != 'undefined' && window.innerWidth < 1253 && window.innerWidth > 1200 ? 12 : 5,
                                              sm: 12,
                                              xs: 12,
                                            },
                                            children: [
                                              {
                                                type: "text",
                                                name: "companyData.zip",
                                                label: "Postal code".translate(this.props.lang),
                                                disabled: true
                                              },
                                            ]

                                          },

                                        ]
                                      },
                                    ],
                                  }
                                  : null


                              ],
                            },
                          ]}
                        ></FormBuilder>
                        :
                        null
                      }

                      {
                        this.props.uData.userLevel != 100 ?

                          <div className="profile-footer">
                            <div></div>
                            <button onClick={() => {
                              // this.setState({ deleteMyAccountModal: {reason: ''} })
                              this.setState({ deleteMyAccountInfoModal: true })
                            }}>{'Delete my account'.translate(this.props.lang)}</button>
                          </div>
                          :
                          null
                      }
                    </Col>
                  </Row>
                  {
                    this.state.disconnectModal ?
                      <DeleteModal
                        lang={this.props.lang}
                        isOpen={this.state.disconnectModal}
                        toggle={() => this.setState({ disconnectModal: null })}
                        handler={() => { this.disconnect(this.state.disconnectModal); this.setState({ disconnectModal: null }) }}
                      >
                        {
                          this.state.disconnectModal == 'bankId' ?
                            <>
                              {'Are you sure you want to disconnect from BankID'.translate(this.props.lang)}?
                            </>
                            :
                            this.state.disconnectModal == 'netId' ?
                              <>
                                {'Are you sure you want to disconnect from Net ID (SITHS)'.translate(this.props.lang)}?
                              </>
                              :
                              <>
                                {'Are you sure you want to deactivate security code to email'.translate(this.props.lang)}?
                              </>

                        }
                      </DeleteModal>
                      :
                      null
                  }

                  {
                    this.state.loadLang ?
                      <div className="loader-wrap loader-wrap-account" >
                        <Player
                          autoplay={true}
                          loop={true}
                          src="/lf30_editor_l5cxzdyf.json"
                          style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", position: 'absolute', top: "50%", left: '50%', transform: 'translate(-50%, -50%)' }}
                        ></Player>
                      </div>
                      :
                      null
                  }
                  {
                    this.state.addLicence || (this.props[0].location && this.props[0].location.state && this.props[0].location.state.failureModal) ?

                      <Modal isOpen={this.state.addLicence || (this.props[0].location && this.props[0].location.state && this.props[0].location.state.failureModal)} centered>
                        <ModalHeader close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ addLicence: false }, () => {
                          // localStorage.removeItem('checkHost')
                          this.props[0].history.push({
                            state: {
                              failureModal: null
                            }
                          })
                        })}>&times;</button>}>{'Buy a licence'.translate(this.props.lang)}</ModalHeader>
                        <ModalBody>
                          {
                            this.props[0].location && this.props[0].location.state && this.props[0].location.state.failureModal ?
                              <div>
                                {"Payment failed. Please try again or use other payment method.".translate(this.props.lang)}
                              </div>
                              :
                              null
                          }
                          <FormGroup>
                            <Label>
                              {"Licence type".translate(this.props.lang)}
                            </Label>
                            <Input
                              type="select"
                              onChange={(e) => {
                                if (e.target.value == -1) {
                                  this.setState({
                                    usersLicencePrice: null
                                  })
                                }
                                this.setState({
                                  licenceName: e.target.value,
                                }, () => {
                                  // console.log(this.state.licenceName)
                                });
                              }}
                              value={this.state.licenceName}
                            >
                              <option value={-1}>
                                {"Select licence type".translate(this.props.lang)}
                              </option>
                              {this.props.uData && this.props.uData.licence ?
                                this.state.licence && this.state.licence.length ?
                                  this.state.licence.map(item => {
                                    if (this.props.uData.licence == item.name)
                                      return (
                                        <option value={item.name}>{this.props.lang == 'en' ? item.name : item.nameSv}</option>
                                      )
                                  })
                                  :
                                  null
                                :
                                this.state.licence && this.state.licence.length ?
                                  this.state.licence.map(item => {
                                    return (
                                      <option value={item.name}>{this.props.lang == 'en' ? item.name : item.nameSv}</option>
                                    )
                                  })
                                  :
                                  null
                              }

                            </Input>
                          </FormGroup>
                          <FormGroup>
                            <Label>
                              {"Licence duration".translate(this.props.lang)}
                            </Label>
                            <Input
                              type="select"
                              onChange={(e) => {
                                if (e.target.value == -1) {
                                  this.setState({
                                    usersLicencePrice: null,
                                    licenceDuration: null
                                  })
                                } else {
                                  this.setState({
                                    licenceDuration: e.target.value,
                                  });
                                }

                              }}
                              value={this.state.licenceDuration}
                            >
                              <option value={-1}>
                                {"Select duration".translate(this.props.lang)}
                              </option>
                              {
                                this.props.uData && this.props.uData.licence ?
                                  null
                                  :
                                  <option value={0.004107}>
                                    {"Single call - 3 hours".translate(this.props.lang)}
                                  </option>
                              }

                              <option value={1}>
                                {"1 month".translate(this.props.lang)}
                              </option>
                              <option value={3}>
                                {"3 months".translate(this.props.lang)}
                              </option>
                              <option value={6}>
                                {"6 months".translate(this.props.lang)}
                              </option>
                              <option value={12}>
                                {"12 months".translate(this.props.lang)}
                              </option>

                            </Input>

                          </FormGroup>
                          {
                            this.props.uData.organisationName ?
                              <Checkbox
                                onChange={(e) => {
                                  this.setState({
                                    organisationPaying: e.target.checked
                                  })
                                }}
                                checked={this.state.organisationPaying}
                                label={'I pay as an organisation'.translate(this.props.lang)}
                              />
                              :
                              null
                          }
                          <p>{`Price for selected type and duration is:`.translate(this.props.lang)} {this.state.usersLicencePrice ? (this.state.usersLicencePrice + ' SEK') : '0 SEK'}</p>
                          <Button color="primary" disabled={!this.state.usersLicencePrice} onClick={() => this.setState({ formModal: true, addLicence: false }, () => {
                            // localStorage.removeItem('checkHost')
                            this.props[0].history.push({
                              state: {
                                failureModal: null
                              }
                            })
                          })}>{'Buy licence'.translate(this.props.lang)}</Button>
                          {
                            this.props.uData && this.props.uData.licence ?
                              null
                              :
                              <Button color="primary" style={{ marginLeft: 15 }} onClick={() => {
                                this.setState({ addLicence: null, licenceName: null, licenceDuration: null, usersLicencePrice: null }, () => {
                                  this.props[0].history.push({
                                    state: {
                                      failureModal: null
                                    }
                                  })
                                })
                              }}>{'Use free version'.translate(this.props.lang)}</Button>
                          }
                        </ModalBody>
                        <ModalFooter>
                        </ModalFooter>
                      </Modal>
                      : null
                  }
                  {
                    this.state.upgradeLicence || (this.props[0].location && this.props[0].location.state && this.props[0].location.state.failureModalUpgradeLicence) ?

                      <Modal isOpen={this.state.upgradeLicence || (this.props[0].location && this.props[0].location.state && this.props[0].location.state.failureModalUpgradeLicence)} centered>
                        <ModalHeader close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ upgradeLicence: false }, () => {
                          // localStorage.removeItem('checkHost')
                          this.props[0].history.push({
                            state: {
                              failureModalUpgradeLicence: null
                            }
                          })
                        })}>&times;</button>}>{'Upgrade licence'.translate(this.props.lang)}</ModalHeader>
                        <ModalBody>
                          {
                            this.props[0].location && this.props[0].location.state && this.props[0].location.state.failureModalUpgradeLicence ?
                              <div>
                                {"Payment failed. Please try again or use other payment method.".translate(this.props.lang)}
                              </div>
                              :
                              null
                          }
                          <FormGroup>
                            <Label>
                              {"Upgrade to".translate(this.props.lang)}
                            </Label>
                            <Input
                              type="select"
                              onChange={(e) => {
                                if (e.target.value == -1) {
                                  this.setState({
                                    usersLicencePrice: null
                                  })
                                }
                                this.setState({
                                  licenceName: e.target.value,
                                }, () => {

                                  let licenceBought = this.props.uData.licenceBought ? this.props.uData.licenceBought : Math.floor(new Date() / 1000)
                                  let experienceDateTs = Math.floor(new Date(this.props.uData.experienceDate) / 1000)
                                  let nowTs = Math.floor(new Date() / 1000)
                                  let selectedDuration = (experienceDateTs - licenceBought)
                                  let leftDuration = (experienceDateTs - nowTs)

                                  let procent = leftDuration / selectedDuration

                                  for (let i = 0; i < this.state.licence.length; i++) {
                                    if (this.state.licenceName == this.state.licence[i].name) {
                                      if (this.props.uData.licenceDuration == 1) {
                                        this.setState({
                                          usersLicence: this.state.licence[i]._id,
                                          usersLicencePrice: Math.round(this.state.licence[i].price1 * procent * 100) / 100,
                                          usersLicenceName: this.props.lang == 'en' ? this.state.licence[i].name : this.state.licence[i].nameSv,  /*Object.translate(this.state.licence[i], 'name', 'en')*/
                                        })
                                      } else if (this.props.uData.licenceDuration == 3) {
                                        this.setState({
                                          usersLicence: this.state.licence[i]._id,
                                          usersLicencePrice: Math.round(this.state.licence[i].price3 * procent * 100) / 100,
                                          usersLicenceName: this.props.lang == 'en' ? this.state.licence[i].name : this.state.licence[i].nameSv, /*Object.translate(this.state.licence[i], 'name', 'en')*/
                                        })
                                      } else if (this.props.uData.licenceDuration == 6) {
                                        this.setState({
                                          usersLicence: this.state.licence[i]._id,
                                          usersLicencePrice: Math.round(this.state.licence[i].price6 * procent * 100) / 100,
                                          usersLicenceName: this.props.lang == 'en' ? this.state.licence[i].name : this.state.licence[i].nameSv,
                                        })
                                      } else if (this.props.uData.licenceDuration == 12) {
                                        this.setState({
                                          usersLicence: this.state.licence[i]._id,
                                          usersLicencePrice: Math.round(this.state.licence[i].price12 * procent * 100) / 100,
                                          usersLicenceName: this.props.lang == 'en' ? this.state.licence[i].name : this.state.licence[i].nameSv,
                                        })
                                      } else if (this.props.uData.licenceDuration == 0.004107) {
                                        this.setState({
                                          usersLicence: this.state.licence[i]._id,
                                          usersLicencePrice: Math.round(this.state.licence[i].callPrice * procent * 100) / 100,
                                          usersLicenceName: this.props.lang == 'en' ? this.state.licence[i].name : this.state.licence[i].nameSv,
                                        })
                                      }
                                    }

                                  }

                                });
                              }}
                              value={this.state.licenceName}
                            >
                              <option value={-1}>
                                {"Select licence type".translate(this.props.lang)}
                              </option>
                              {
                                this.state.licence && this.state.licence.length ?
                                  this.state.licence.map((item, idx, arr) => {
                                    if (this.calculateLicence(arr[idx]))
                                      return (
                                        <option value={item.name}>{this.props.lang == 'en' ? item.name : item.nameSv}</option>
                                      )
                                  })
                                  :
                                  null
                              }

                            </Input>

                          </FormGroup>
                          {
                            this.props.uData.organisationName ?
                              <Checkbox
                                onChange={(e) => {
                                  this.setState({
                                    organisationPaying: e.target.checked
                                  })
                                }}
                                checked={this.state.organisationPaying}
                                label={'I pay as an organisation'.translate(this.props.lang)}
                              />
                              :
                              null
                          }
                          <p>{`Price for selected upgrade:`.translate(this.props.lang)} {this.state.usersLicencePrice ? (Math.round(this.state.usersLicencePrice).toFixed(2) + ' SEK') : '0 SEK'}</p>
                          <Button color="primary" disabled={!this.state.usersLicencePrice} onClick={() => this.setState({ formModal: true, upgradeLicence: false, upgradeLicenceCheck: true }, () => {
                            // localStorage.removeItem('checkHost')
                            this.props[0].history.push({
                              state: {
                                failureModalUpgradeLicence: null
                              }
                            })
                          })}>{'Upgrade licence'.translate(this.props.lang)}</Button>

                        </ModalBody>
                        <ModalFooter>
                        </ModalFooter>
                      </Modal>
                      : null
                  }
                  {/* {
                        this.state.addSmsPackage || (this.props[0].location && this.props[0].location.state && this.props[0].location.state.failureModalSMS) ?

                          <Modal isOpen={this.state.addSmsPackage || (this.props[0].location && this.props[0].location.state && this.props[0].location.state.failureModalSMS)} centered>
                            <ModalHeader close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ addSmsPackage: false, smsLicenceName: null, smsUserLicencePrice: null }, () => {
                              this.props[0].history.push({
                                state: {
                                  failureModalSMS: null
                                }
                              })
                            })}>&times;</button>}>
                              {'Buy SMS package'.translate(this.props.lang)}
                            </ModalHeader>
                            <ModalBody>
                              {
                                this.props[0].location && this.props[0].location.state && this.props[0].location.state.failureModalSMS ?
                                  <div>
                                    {"Payment failed. Please try again or use other payment method.".translate(this.props.lang)}
                                  </div>
                                  :
                                  null
                              }
                              <FormGroup>
                                <Label>
                                  {"Select package".translate(this.props.lang)}
                                </Label>
                                <Input
                                  type="select"
                                  onChange={(e) => {
                                    if (e.target.value == -1) {
                                      this.setState({
                                        smsUserLicencePrice: null
                                      })
                                    }
                                    this.setState({
                                      smsLicenceName: e.target.value,
                                    }, () => {
                                      // console.log(this.state.licenceName)
                                    });
                                  }}
                                // value={this.state.smsLicenceName}
                                >
                                  <option value={-1}>
                                    {"Select SMS package".translate(this.props.lang)}
                                  </option>
                                  {
                                    this.state.smsLicence && this.state.smsLicence.length ?
                                      this.state.smsLicence.map(item => {
                                        return (
                                          <option value={item._id}>{this.props.lang == 'en' ? item.name + ' (' + item.licenceType.translate(this.props.lang) + ')' : item.nameSv + ' (' + item.licenceType.translate(this.props.lang) + ')'}</option>
                                        )
                                      })
                                      :
                                      null
                                  }

                                </Input>

                              </FormGroup>
                              {
                                this.props.uData.organisationName ?
                                  <Checkbox
                                    onChange={(e) => {
                                      this.setState({
                                        organisationPaying: e.target.checked
                                      })
                                    }}
                                    checked={this.state.organisationPaying}
                                    label={'I pay as an organisation'.translate(this.props.lang)}
                                  />
                                  :
                                  null
                              }
                              <p>{`Price for selected SMS package:`.translate(this.props.lang)} {this.state.smsUserLicencePrice ? (Math.round(this.state.smsUserLicencePrice).toFixed(2) + ' SEK') : '0 SEK'}</p>
                              <Button color="primary" disabled={!this.state.smsUserLicencePrice} onClick={() => this.setState({ addSmsPackage: false, formModal: true }, () => {
                                this.props[0].history.push({
                                  state: {
                                    failureModalSMS: null
                                  }
                                })
                              })}>{'Buy package'.translate(this.props.lang)}</Button>
                              <Button color="primary" style={{ marginLeft: 15 }} onClick={() => {
                                this.setState({ addSmsPackage: null, smsLicenceName: null, smsUserLicencePrice: null }, () => {
                                  this.props[0].history.push({
                                    state: {
                                      failureModalSMS: null
                                    }
                                  })
                                })
                              }}>{'Close'.translate(this.props.lang)}</Button>
                            </ModalBody>
                            <ModalFooter>
                            </ModalFooter>
                          </Modal>
                          : null
                      } */}
                  {
                    this.state.formModal ?

                      <Modal isOpen={this.state.formModal} centered>
                        <ModalHeader close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ formModal: false })}>&times;</button>}>{'Select a payment method'.translate(this.props.lang)}</ModalHeader>
                        <ModalBody>
                          <div className="payment-container">
                            <Button className="payment-button-payson" onClick={() => this.setState({ paysonModal: true, formModal: false, addLicence: false }, () => {

                            })}><Isvg src={PaysonSvg} /> <span>{'Credit card'.translate(this.props.lang)}</span> </Button>
                            <Button className="payment-button-swish" onClick={() => this.setState({ swishModal: true, formModal: false, addLicence: false }, () => {

                            })}><Isvg src={SwishSvg} /></Button>
                          </div>
                        </ModalBody>
                        <ModalFooter>
                        </ModalFooter>
                      </Modal>
                      : null
                  }

                  {
                    this.state.paysonModal ?

                      <Payson
                        uData={this.props.uData}
                        open={this.state.paysonModal}
                        openModal={(item) => {
                          this.setState({
                            paysonModal: item
                          })

                        }}
                        swishNumber={this.props.uData.accountNumber}
                        phone={this.props.uData.phone}
                        price={this.state.usersLicencePrice ? this.state.usersLicencePrice : this.state.smsUserLicencePrice}
                        message={""}
                        licenceDuration={this.state.licenceDuration ? this.state.licenceDuration : null}
                        licenceID={this.state.usersLicence ? this.state.usersLicence : this.state.smsUsersLicence}
                        upgradeLicence={this.state.upgradeLicenceCheck ? this.state.upgradeLicenceCheck : false}
                        organisationPaying={this.state.organisationPaying ? this.state.organisationPaying : false}
                      />
                      : null
                  }
                  {
                    this.state.swishModal ?

                      <Swish
                        uData={this.props.uData}
                        verifyUser={() => this.props.verifyUser()}
                        lang={this.props.lang}
                        history={this.props[0]}
                        open={this.state.swishModal}
                        openModal={(item) => {
                          this.setState({
                            swishModal: item
                          })

                        }}
                        swishNumber={this.props.uData.accountNumber}
                        phone={this.props.uData.phone}
                        price={this.state.usersLicencePrice ? this.state.usersLicencePrice : this.state.smsUserLicencePrice}
                        licenceDuration={this.state.licenceDuration ? this.state.licenceDuration : null}
                        licence={this.state.usersLicenceName ? this.state.usersLicenceName : this.state.smsLicenceName}
                        licenceID={this.state.usersLicence ? this.state.usersLicence : this.state.smsUsersLicence}
                        upgradeLicence={this.state.upgradeLicenceCheck ? this.state.upgradeLicenceCheck : false}
                        organisationPaying={this.state.organisationPaying ? this.state.organisationPaying : false}
                      />
                      : null
                  }
                </div>
                {/*      :
                     null
                 } */}

                {/* {
                  this.state.activeTab == 2 ?
                    <div className="panel">
                      <div className="panel-header">
                        <h5 className="component-header">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h5>

                        <div>
                          <Label>
                            {'Licences'.translate(this.props.lang)} {Number(this.props.uData.numberOfLicences) - 1 - this.state.items.length} / {Number(this.props.uData.numberOfLicences) - 1}
                          </La
                          

                        <button disabled={(Number(this.props.uData.numberOfLicences) - 1) == this.state.items.length ? true : false} className="btn add-button" onClick={() => this.setState({
                          form: {}
                        })}><Isvg src={addIcon} /> {'Assign licence'.translate(this.props.lang)}</button>
                      </div>

                      <ListBuilder
                        lang={this.props.lang}
                        loading={this.state.loading}
                        total={this.state.total}
                        showNumeration={false}
                        fields={[
                          { type: 'text', name: 'name', label: 'Name'.translate(this.props.lang), allowSort: true },

                        ]}
                        items={this.state.items.map(item => {
                          return {
                            ...item,

                            name: this.props.formatName(item.userData.title, item.userData.name),
                          }
                        })}
                        actions={
                          [

                            {
                              component: <Isvg src={garabage} className="delete-icon" />,
                              onClick: (item) => this.setState({ deleteModal: item })
                            },
                          ]
                        }
                        params={params}
                        sortField={params.sortField}
                        sortType={params.sortType}
                        updateSort={this.updateSort}
                        updateParams={this.updateParams}
                        updateMultipleParams={this.updateMultipleParams}
                      ></ListBuilder>

                      {
                        this.state.form ?
                          <Modal isOpen={this.state.form} centered>
                            <ModalHeader close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ form: null, name: '', userAssignLicence: {} }, () => {

                            })}>&times;</button>}>
                              {'Assign licence'.translate(this.props.lang)}
                            </ModalHeader>
                            <ModalBody>
                              <FormGroup>
                                <Label>{'Email'.translate(this.props.lang)}</Label>
                                <Input type="email" onChange={(e) => {
                                  this.findUser(e.target.value)
                                  let userAssignLicence = this.state.userAssignLicence
                                  userAssignLicence.email = e.target.value
                                  this.setState({
                                    userAssignLicence
                                  })
                                }} />
                              </FormGroup>
                              {
                                this.state.name != '' && this.state.userAssignLicence && this.state.userAssignLicence.email ?
                                  <FormGroup>
                                    <Label>{'Name'.translate(this.props.lang)}</Label>
                                    <Input type="text" disabled value={this.state.name} />
                                  </FormGroup>
                                  :
                                  <FormGroup>
                                    <Label>{'Name'.translate(this.props.lang)}</Label>
                                    <Input type="text" value={this.state.userAssignLicence && this.state.userAssignLicence.name ? this.state.userAssignLicence.name : ''} onChange={(e) => {
                                      let userAssignLicence = this.state.userAssignLicence
                                      userAssignLicence.name = e.target.value
                                      this.setState({
                                        userAssignLicence
                                      })
                                    }} />
                                  </FormGroup>
                              }



                            </ModalBody>
                            <ModalFooter>
                              <Button disabled={this.state.userAssignLicence && this.state.userAssignLicence.email && this.state.userAssignLicence.email.indexOf('@') !== -1 && this.state.userAssignLicence.email.indexOf('.') !== -1 && (this.state.name || this.state.userAssignLicence.name) ? false : true} onClick={() => {
                                this.assignLicence(this.state.userAssignLicence)
                              }}>{'Assign'.translate(this.props.lang)}</Button>
                              <Button onClick={() => {
                                this.setState({
                                  form: null
                                })
                              }}>{'Close'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                          </Modal>
                          :
                          null
                      }
                    </div>
                    :
                    null
                } */}

              </Col>
            </Row>


          </Container>



        </div>
        {
          this.state.registrationSuccessful ?
            <InfoModal
              isOpen={this.state.registrationSuccessful}
              // toggle={() => this.setState({ registrationSuccessful: !this.state.registrationSuccessful })}
              // image={Brev}
              header={'Info'.translate(this.props.lang)}
              info={'Check your email and verify your account.'.translate(this.props.lang)}
              buttons={[
                <Button color='primary' onClick={() => {
                  this.setState({
                    registrationSuccessful: null
                  }, () => {
                    this.props.signOut()
                  })
                }}>{'Ok'.translate(this.props.lang)}</Button>,
              ]}

            />
            :
            null
        }
        {
          this.state.deleteModal ?
            <DeleteModal
              lang={this.props.lang}
              isOpen={this.state.deleteModal}
              toggle={() => this.setState({ deleteModal: null })}
              handler={() => { this.deleteConnectedUser(this.state.deleteModal); this.setState({ deleteModal: null }) }}
            >
              {'Delete account'.translate(this.props.lang)} <strong>{this.state.deleteModal && this.state.deleteModal.name}</strong>?
            </DeleteModal>
            : null
        }
        {
          this.props[0].location && this.props[0].location.state && this.props[0].location.state.successModal ?
            <InfoModal
              isOpen={this.props[0].location.state.successModal}
              // toggle={() => this.setState({ registrationSuccessful: !this.state.registrationSuccessful })}
              // image={Brev}
              header={'Info'.translate(this.props.lang)}
              info={this.props[0].location.state.successModal.translate(this.props.lang)}
              buttons={[
                <Button color='primary' onClick={() => {
                  this.props[0].history.push({
                    state: {
                      successModal: null
                    }
                  })
                }}>{'Ok'.translate(this.props.lang)}</Button>,
              ]}

            />
            :
            null
        }
        {
          this.state.showAll ?
            <Modal isOpen={this.state.showAll} centered size="lg">
              <ModalHeader close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ showAll: null }, () => {

              })}>&times;</button>}>
                {'Assigned license(s)'.translate(this.props.lang)}
              </ModalHeader>
              <ModalBody>
                <table style={{ width: '100%' }}>
                  <tr>
                    <th>{'Assigned by'.translate(this.props.lang)}</th>
                    <th>{'Plan'.translate(this.props.lang)}</th>
                    <th>{'Expiration date'.translate(this.props.lang)}</th>
                  </tr>

                  {this.state.assignedLicence.map(item => {
                    return (
                      <tr>
                        <td>{item.name ? item.name : ''}</td>
                        <td>{item.licenceType ? item.licenceType : ''}</td>
                        <td>{item.experienceDate ? moment.unix(item.experienceDate).format('YYYY-MM-DD') : ''}</td>
                      </tr>
                    )
                  })}
                </table>
              </ModalBody>
              <ModalFooter>

                <Button onClick={() => {
                  this.setState({
                    showAll: null
                  })
                }}>{'Close'.translate(this.props.lang)}</Button>
              </ModalFooter>
            </Modal>
            : null
        }
        {
          this.state.deleteMyAccountInfoModal ?
            <InfoModal
              isOpen={this.state.deleteMyAccountInfoModal}
              toggle={() => this.setState({ deleteMyAccountInfoModal: null })}
              header={'Delete my account'.translate(this.props.lang)}
              info={'Are you sure you want to delete your account? If you confirm, your account will be deleted within 7 days.'.translate(this.props.lang)}
              buttons={[
                <Button color='primary'
                  onClick={() => {
                    this.setState({
                      deleteMyAccountInfoModal: null,
                      deleteMyAccountModal: {
                        reason: ''
                      }
                    })

                  }}>{'Confirm'.translate(this.props.lang)}</Button>,
                <Button color='primary'
                  onClick={() => {
                    this.setState({ deleteMyAccountInfoModal: null })

                  }}>{'Cancel'.translate(this.props.lang)}</Button>,



              ]}
            />
            :
            null

        }
        {
          this.state.deleteMyAccountModal ?
            <Modal isOpen={this.state.deleteMyAccountModal} centered>
              <ModalHeader >{'Delete my account'.translate(this.props.lang)}</ModalHeader>
              <ModalBody>

                <FormGroup>
                  <Label>{'Reason for account deletion'.translate(this.props.lang)}*</Label>
                  <Input type="textarea" style={{ height: 100 }} value={this.state.deleteMyAccountModal.reason ? this.state.deleteMyAccountModal.reason : ''} onChange={(e) => {
                    let deleteMyAccountModal = this.state.deleteMyAccountModal;
                    deleteMyAccountModal.reason = e.target.value
                    this.setState({
                      deleteMyAccountModal
                    })
                  }} />
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button
                  disabled={this.state.deleteMyAccountModal && this.state.deleteMyAccountModal.reason ? false : true}
                  onClick={() => {
                    this.deleteMyAccount()
                  }}
                >{'Request account deletion'.translate(this.props.lang)}</Button>
                <Button onClick={() => {
                  this.setState({
                    deleteMyAccountModal: null
                  })
                }}>{'Cancel'.translate(this.props.lang)}</Button>
              </ModalFooter>
            </Modal>
            :
            null
        }

        {
          this.state.openBankIDModal ?
            <Modal isOpen={this.state.openBankIDModal} centered>
              <ModalHeader close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ openBankIDModal: null }, () => {
              })}>&times;</button>}>
              </ModalHeader>
              <ModalBody>
                <BankIDModal_v5 {...this.props} errorModal={(error) => {
                  this.setState({
                    bankIdError: error,
                    openBankIDModal: null
                  })
                }} openBankIDModal={this.state.openBankIDModal} close={() => {
                  this.setState({
                    openBankIDModal: null
                  }, () => {
                    window.location.reload()
                  })
                }} />
              </ModalBody>
            </Modal>

            :
            null
        }

        {
          this.state.securityModal ?
            <Modal isOpen={this.state.securityModal} centered>
              <ModalHeader style={{ margin: 'auto' }} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ securityModal: null }, () => {
              })}>&times;</button>}> {"Activate secure log-in".translate(this.props.lang)}
              </ModalHeader>
              <ModalBody>
                <div className="activate-security-modal">
                  <p style={{ width: '80%', textAlign: 'center' }}>
                    {'You will not be able to log-in using username and password after activating BankId or SITHS the secure log-in method.'.translate(this.props.lang)}
                  </p>
                  {
                    this.props.uData.userLevel != 1 && this.props.uData.userLevel != 2 && (typeof localStorage && localStorage.getItem('profile') != 'patient') ?
                      (
                        <FormGroup className="account-details">
                          {/* {"BankID".translate(this.props.lang)}: <br /> */}
                          {this.props.uData.bankId ? (
                            <>
                              {/* <strong>{"Connected".translate(this.props.lang)} </strong> */}
                              <Button
                                onClick={() => this.setState({ disconnectModal: 'bankId' })}
                                color="primary"
                                size="sm"
                              >
                                {"Deactivate Mobile BankID".translate(this.props.lang)}
                              </Button>
                            </>
                          ) : (
                            <>
                              {/* {" "}
                              <strong>
                                {"Not connected".translate(this.props.lang)}
                              </strong>{" "} */}
                              <Button
                                onClick={() => {
                                  this.setState({
                                    openBankIDModal: true
                                  })
                                }}
                                color="primary"
                                size="sm"
                              >
                                {"Activate Mobile BankID".translate(this.props.lang)}
                              </Button>
                            </>
                          )}
                        </FormGroup>
                      )
                      :
                      null
                  }
                  {
                    this.props.uData.userLevel != 1 && this.props.uData.userLevel != 2 && (typeof localStorage && localStorage.getItem('profile') != 'patient') ?
                      (
                        <FormGroup className="account-details">
                          {/* {"SITHS".translate(this.props.lang)}: <br /> */}
                          {this.props.uData.netId ? (
                            <>
                              {/* <strong>{"Connected".translate(this.props.lang)} </strong> */}
                              <Button
                                disabled={this.props.uData.bankId}
                                onClick={() => this.setState({ disconnectModal: 'netId' })}
                                color="primary"
                                size="sm"
                              >
                                {"Deactivate SITHS".translate(this.props.lang)}
                              </Button>
                            </>
                          ) : (
                            <>
                              {/* {" "}
                              <strong>
                                {"Not connected".translate(this.props.lang)}
                              </strong>{" "} */}
                              <Button
                                disabled={this.props.uData.bankId}
                                onClick={() => {
                                  fetch(API_ENDPOINT + "/users/netid/account/start", {
                                    method: "GET",
                                    headers: {
                                      "content-type": "application/json",
                                      Authorization: typeof localStorage !== "undefined" ? `Bearer ${localStorage.getItem("authToken")}` : null,
                                    },
                                  }).then((res) => res.json()).then((result) => {
                                    if (result.redirectUrl) {
                                      window.location.replace(
                                        result.redirectUrl
                                      );
                                    }
                                  });
                                }}
                                color="primary"
                                size="sm"
                              >
                                {"Activate SITHS".translate(this.props.lang)}
                              </Button>
                            </>
                          )}
                        </FormGroup>
                      )
                      :
                      null
                  }
                  {
                    this.props.uData.userLevel != 1 && this.props.uData.userLevel != 2 && (typeof localStorage && localStorage.getItem('profile') != 'patient') ?
                      (
                        <FormGroup className="account-details">
                          {/* {"Login code to mail".translate(this.props.lang)}: <br /> */}
                          {this.props.uData.loginCode ? (
                            <>
                              {/* <strong>{"Activated".translate(this.props.lang)} </strong> */}
                              <Button
                                disabled={this.props.uData.bankId}
                                onClick={() => this.setState({ disconnectModal: 'code' })}
                                color="primary"
                                size="sm"
                              >
                                {"Deactivate login code to email".translate(this.props.lang)}
                              </Button>
                            </>
                          ) : (
                            <>
                              {/* {" "}
                              <strong>
                                {"Not activated".translate(this.props.lang)}
                              </strong>{" "} */}
                              <Button
                                disabled={this.props.uData.bankId}
                                onClick={() => {
                                  fetch(API_ENDPOINT + "/data/users/accounts/login-code", {
                                    method: "POST",
                                    headers: {
                                      "content-type": "application/json",
                                      Authorization: typeof localStorage !== "undefined" ? `Bearer ${localStorage.getItem("authToken")}` : null,
                                    },
                                  }).then((res) => res.json()).then((result) => {

                                    this.props.verifyUser(() => {
                                      this.forceUpdate()
                                    });
                                  });
                                }}
                                color="primary"
                                size="sm"
                              >
                                {"Activate login code to email".translate(this.props.lang)}
                              </Button>
                            </>
                          )}
                        </FormGroup>
                      )
                      :
                      null
                  }


                </div>
              </ModalBody>
            </Modal>

            :
            null
        }

      </div >

    );
  }
}

const selector = formValueSelector("modalForm");
const selector2 = formValueSelector('form')
export default connect(
  (state) => {
    return {
      selectedWaitingTimeType: selector(state, "selectedWaitingTimeType"),
      selectCountry: selector2(state, 'userData.country'),
      profileType: selector2(state, 'profileType'),
      vatNumber: selector2(state, 'userData.vatNumber'),
      notApplicable: selector2(state, 'userData.notApplicable'),
    };
  },
  {
    changeVatNumber: (value) => change("form", "userData.vatNumber", value),
    changeMinValue: (value) => change("modalForm", "waitingMin", value),
    changeMaxValue: (value) => change("modalForm", "waitingMax", value),
    changeSelectedWaitingTimeType: (value) =>
      change("modalForm", "selectedWaitingTimeType", value),
  }
)(Page(Account));
