import React, { Component } from "react";
import Page from "../../containers/page";

import {
    Container,
    Row,
    Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, FormGroup, Label
} from "reactstrap";

import FormBuilder from '../../components/forms/modalFormBuilder';
import ListBuilder from '../../components/listBuilder';
import { required } from "../../components/forms/validation";
import DeleteModal from '../../components/deleteModal';
import xIcon from '../../assets/svg/x.svg';
import Isvg from 'react-inlinesvg'
import addIcon from '../../assets/svg/add-icon.svg';
import edit from '../../assets/svg/edit.svg';
import garabage from '../../assets/svg/garbage.svg';
import viewIcon from '../../assets/svg/eye.svg';
import exportIcon from '../../assets/svg/export.svg';
import {
    getSearchParams,
    generateSearchLink,
    generateSearchLinkMultiple,
} from "../../helpers/linkHelper";
import { API_ENDPOINT } from "../../constants";
import ErrorModal from "../../components/errorModal";
import PdfSave from '../../components/pdvSave';
import moment from 'moment'
import DatePicker from '../../components/forms/fields/datePicker';

function parseJSON(response) {
    return response.json().then((json) => {
        return {
            result: json,
            status: response.status,
        };
    });
}

function parseText(response) {
    return response.text().then(text => {
        return {
            result: text,
            status: response.status
        }
    })
}

class VideoPayments extends Component {
    constructor(props) {
        super(props);

        let currDate = new Date();
        let date1 = new Date(currDate.getFullYear(), 0, 1);
        let date2 = new Date();

        this.player = React.createRef();
        this.player2 = React.createRef();
        this.state = {
            showPlayer: false,
            showPlayer2: false,
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0,
                "query-group": null,
            },
            items: [],
            total: 0,
            loading: true,
            groups: [],
            languages: [],
            clinicIdx: 0,
            showForm: false,
            dateFrom: moment.unix(Math.floor(date1.getTime() / 1000)).format('YYYY-MM-DD'),
            dateTo: moment.unix(Math.floor(date2.getTime() / 1000)).format('YYYY-MM-DD'),
            tabIndex: 1
        };
    }

    showPlayer = () => {
        this.setState({
            showPlayer: true,
        });
    };

    hidePlayer = () => {
        this.setState({
            showPlayer: false,
        });
    };

    get = () => {
        this.props.updateMeta(
            this.props.generateSeoTags(this.state, this.props.lang)
        );

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
                this.state.useState
                    ? this.state.params
                    : getSearchParams(this.props[0].location.search, {
                        entries: 10,
                        page: 0,
                        sortField: 'tsCreated', sortType: -1,
                        "query-group": null,
                        dateFrom: this.state.dateFrom, dateTo: this.state.dateTo
                    })
            ).then((data) => {
                this.setState(
                    {
                        ...data,
                        loading: null,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        );
                    }
                );
            });
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
                this.state.useState
                    ? this.state.params
                    : getSearchParams(this.props[0].location.search, {
                        entries: 10,
                        page: 0,
                        sortField: 'tsCreated', sortType: -1,
                        "query-group": null,
                        dateFrom: this.state.dateFrom, dateTo: this.state.dateTo
                    })
            ).then((data) => {
                this.setState(
                    {
                        ...data,
                        loading: null,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        );
                    }
                );
            });
        }
    };

    componentDidMount() {
        if (typeof window !== "undefined") {
            window.scrollTo(0, 0);
        }

        this.get();
        this.setState({ showForm: true })

    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState(
                {
                    loading: true,
                },
                () => {
                    this.get();
                }
            );
        }
    }

    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state,
            });
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    [
                        { name: "sortField", value: field },
                        { name: "sortType", value: type },
                    ],
                    false
                )
            );
        }
    };

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj });
            }
        } else {
            this.props[0].history.push(
                generateSearchLink(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    name,
                    value,
                    restart
                )
            );
        }
    };

    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });
            }
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    fields,
                    restart
                )
            );
        }
    };

    stopAnimation = () => {
        this.player.current.play();
    };


    export = (dateFrom, dateTo) => {
        this.setState({
            loading: true,
            _saving: true
        }, () => {

            fetch(API_ENDPOINT + '/data/video/payments/export', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ dateFrom, dateTo })
            }).then(parseJSON).then(({ result, status }) => {
                // console.log(result);
                if (result.error) {
                    this.setState({ error: result.error })
                } else {
                    window.open(API_ENDPOINT + result.link)
                    this.setState({
                        loading: false
                    })
                }
                this.get();
            })
        })
    }

    sendMailExport = (dateFrom, dateTo, emails) => {
        this.setState({
            loading: true,
            _saving: true
        }, () => {

            fetch(API_ENDPOINT + '/data/video/payments/sendmail/export', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ dateFrom, dateTo, emails })
            }).then(parseJSON).then(({ result, status }) => {
                // console.log(result);
                if (result.error) {
                    this.setState({ error: result.error })
                } else {
                    this.setState({
                        loading: false,
                        emailSendStatistic: ''
                    })
                }
                this.get();
            })
        })
    }

    delete = (id) => {
        this.setState({
            loading: true
        }, () => {

            fetch(API_ENDPOINT + '/data/payments/' + id, {
                method: 'DELETE',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(parseJSON).then(({ result, status }) => {
                if (result.error) {
                    this.setState({ error: result.error })
                }
                this.get();
            })
        })

    }

    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, {
                entries: 10,
                page: 0,
            });
        }
        return (
            <div className="dashboard">
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="panel">
                                <div className="panel-header panel-payment-header">
                                    <h5 className="component-header">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h5>
                                    <div className="payment-header-buttons">
                                        <Button className="btn add-button" onClick={() => this.export(this.state.dateFrom, this.state.dateTo)}><Isvg src={exportIcon} /> {'Export'.translate(this.props.lang)}</Button>
                                        <Button className="btn add-button" disabled={!(this.state.emailSendStatistic && this.state.emailSendStatistic.length)} onClick={() => this.sendMailExport(this.state.dateFrom, this.state.dateTo, this.state.emailSendStatistic)}><Isvg src={exportIcon} /> {'Send'.translate(this.props.lang)}</Button>
                                    </div>
                                </div>

                                <Row>
                                    <Col lg="6">
                                        <FormGroup>
                                            <DatePicker
                                                label={'Date from'.translate(this.props.lang)}
                                                value={this.state.dateFrom}
                                                onChange={(e) => {
                                                    if (this.state.dateTo < e) {
                                                        this.setState({
                                                            error: 'Date start can not be greater than the end date'
                                                        })
                                                    } else {
                                                        this.setState({ dateFrom: e }, () => this.get())
                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <DatePicker
                                                label={'Date to'.translate(this.props.lang)}
                                                value={this.state.dateTo}
                                                onChange={(e) => {
                                                    if (this.state.dateFrom > e) {
                                                        this.setState({
                                                            error: 'Date end can not be greater than the start date'
                                                        })
                                                    } else {
                                                        this.setState({ dateTo: e }, () => this.get())
                                                    }

                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12">
                                        <div style={{ marginBottom: 35 }}>
                                            <Label>{'Send statistics to email'.translate(this.props.lang)}</Label>
                                            <Input size="sm" type="text" value={this.state.emailSendStatistic} onChange={(e) => {
                                                this.setState({
                                                    emailSendStatistic: e.target.value
                                                })

                                            }}></Input>
                                        </div>
                                    </Col>
                                </Row>

                                <ListBuilder
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'orderNumber', label: 'Order number'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'date', label: 'Date'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'participant', label: 'Participant'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'vatOrgNumber', label: 'Vat/Org.nr'.translate(this.props.lang), allowSort: false },
                                        { type: 'text', name: 'licence', label: 'Licence type'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'experienceDate', label: 'Renewal date'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'price', label: 'Price'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'paymentMethod', label: 'Payment method'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'reference', label: 'Reference'.translate(this.props.lang), allowSort: true },

                                    ]}
                                    items={this.state.items.map(item => {
                                        let vatOrgNumber
                                        if (item && item.billingDetails && item.billingDetails.accountDetails && item.billingDetails.accountDetails.sameAsAccountDetails && item.billingDetails.accountDetails.organizationName && (item.billingDetails.billingDetailsOrgNumber || item.billingDetails.billingDetailsVatNumber)) {
                                            vatOrgNumber = item.billingDetails.billingDetailsOrgNumber ? item.billingDetails.billingDetailsOrgNumber : item.billingDetails.billingDetailsVatNumber
                                        } else if (item && item.billingDetails && item.billingDetails.accountDetailsBilling && !item.billingDetails.accountDetails.sameAsAccountDetails && item.billingDetails.accountDetailsBilling.organizationName && item.billingDetails.accountDetailsBilling.country) {
                                            vatOrgNumber = item.billingDetails.billingDetailsOrgNumber ? item.billingDetails.billingDetailsOrgNumber : item.billingDetails.billingDetailsVatNumber
                                        }
                                        return {
                                            ...item,
                                            date: <span>{moment.unix(item.tsCreated).format("YYYY-MM-DD")}</span>,
                                            paymentMethod: item.paymentMethod ? item.paymentMethod.translate(this.props.lang) : '',
                                            licence: item.licence ? item.licenceDuration && item.licenceDuration == 1 ? this.props.lang == 'en' ? item.licence + " - " + item.licenceDuration + ' month'.translate(this.props.lang) : item.licenceSv ? item.licenceSv + " - " + item.licenceDuration + ' month'.translate(this.props.lang) : item.licence + " - " + item.licenceDuration + ' month'.translate(this.props.lang) : item.licenceDuration && item.licenceDuration == 0.004107 ? this.props.lang == 'en' ? item.licence + " - 3 " + "hours".translate(this.props.lang) : item.licenceSv ? item.licenceSv + " - 3 " + "hours".translate(this.props.lang) : item.licence + " - 3 " + "hours".translate(this.props.lang) : this.props.lang == 'en' && item.licenceDuration ? item.licence + " - " + item.licenceDuration + ' months'.translate(this.props.lang) : item.licenceSv && item.licenceDuration ? item.licenceSv + " - " + item.licenceDuration + ' months'.translate(this.props.lang) : item.licenceDuration ? item.licence + " - " + item.licenceDuration + ' months'.translate(this.props.lang) : this.props.lang == 'en' ? item.licence : item.licenceSv : '',
                                            price: item.licencePrice ? (Number(item.licencePrice)).toFixed(2) + ' SEK' : '',
                                            participant: <><strong>{item.participant ? item.participant : ''}</strong><br /><span>{item.username ? item.username : ''}</span></>,
                                            vatOrgNumber: vatOrgNumber,
                                        }
                                    })}
                                    actions={
                                        [
                                            {
                                                component: <div className="tabel-action-tooltip"><Isvg src={viewIcon} style={{ width: 24, minWidth: 24 }} /><div style={{ width: 180 }} className="chat-icons-tooltip">{'Preview'.translate(this.props.lang)}</div> </div>,
                                                onClick: (item) => this.setState({ invoice: item }),

                                            },
                                        ]
                                    }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>

                            </div>
                        </Col>
                    </Row>
                </Container>

                {
                    this.state.invoice ?
                        <Modal isOpen={this.state.invoice} centered size='lg' className="modal-footer-modifed card-payment-modal">
                            <ModalHeader toggle={() => this.setState({ invoice: !this.state.invoice })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ invoice: !this.state.invoice })}><Isvg src={xIcon} /></button>}>{'Kvitto'.translate(this.props.lang)}</ModalHeader>
                            <ModalBody>

                                <div dangerouslySetInnerHTML={{ __html: this.props.lang == 'en' ? this.state.invoice.html : this.state.invoice.htmlSv }} onChange={() => { }}></div>
                                {/* <PrintHelper lang={this.props.lang} html={this.state.invoice.invoiceContent} /> */}
                            </ModalBody>
                            <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }}>
                                <PdfSave lang={this.props.lang} html={(this.state.invoice && this.state.invoice.html ? this.state.invoice.html : '').replace(/č/g, 'c').replace(/Č/g, 'C').replace(/ć/g, 'c').replace(/Ć/g, 'C')} />
                                <Button color="primary" onClick={() => this.setState({ invoice: null })}> {'Close'.translate(this.props.lang)} </Button>


                            </ModalFooter>
                        </Modal>
                        : null
                }
            </div >

        );
    }
}


export default Page(VideoPayments);
