import React, { Component } from 'react';
import Isvg from "react-inlinesvg";
import SoundMeter from '../helpers/soundMeter';
import micIcon from '../assets/svg/mic.svg';
import soundTest from '../assets/svg/test_sound.svg';
import cameraIcon from '../assets/svg/camera.svg';
import speakerIcon from '../assets/svg/speaker.svg';
import micMutedIcon from '../assets/svg/mic_muted.svg';
import cameraOffIcon from '../assets/svg/camera_off.svg';
import Checkbox from '../components/forms/fields/checkbox';

import screenShareIcon from '../assets/svg/screen_share.svg';
import screenShareOffIcon from '../assets/svg/screen_share_off.svg';
import notificationSound from '../assets/sound/notification.wav';
import { API_ENDPOINT } from "../constants";

import {
  Row,
  Col,
  Button, Input, FormGroup, Label, Modal, ModalHeader, ModalBody
} from 'reactstrap';
function parseJSON(response) {
  return response.json().then((json) => {
    return {
      result: json,
      status: response.status,
    };
  });
}

function iosDevice() {
  var userAgent = window.navigator.userAgent;
  if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
    return true;
  }
  else {
    return false;
  }
}


function detectMob() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}

class WebRTCTest extends Component {
  constructor(props) {
    super(props);

    this.tmpStreams = [];

    this.state = {
      audioInput: [],
      audioOutput: [],
      videoInput: [],
      shareScreenSource: false,
      videoResolution: '480',
      fullHDSupport: false,
      libraryIcons: [],
      notAllowedError: true,
    };

  }

  checkPermissions = () => {

    if (typeof window !== 'undefined') {
      navigator?.mediaDevices?.getUserMedia({ video: { width: { exact: 1920 }, height: { exact: 1080 } }, audio: true }).then(stream => {
        this.setState({ notAllowedError: false });
        if (stream) {
          stream.getTracks().forEach(track => track.stop());
        }

        this.setState({
          fullHDSupport: true
        })
        this.getStream(true);

      }).catch(err => {
        if (err?.name === 'NotAllowedError') {
          this.setState({ notAllowedError: true });
          return;
        } else if (err?.name === 'OverconstrainedError') {
          this.setState({
            fullHDSupport: false,
            notAllowedError: false
          })
          this.getStream(true);

          return;
        }
      });

    }

  }

  attachSinkId = (deviceId) => {

    if (typeof this.previewVideo.sinkId !== 'undefined') {

      this.previewVideo.setSinkId(deviceId).then(() => {
        console.log(`Success, audio output device attached: ${deviceId}`);
        this.setState({ audioOutputDevice: deviceId })

      })
        .catch(error => {
          let errorMessage = error;
          if (error.name === 'SecurityError') {
            errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
          }
          console.error(errorMessage);
          // Jump back to first output device in the list as it's the default.
          //this.setState({ selectedAudioOutputIndex: 0 })
        });

    } else {
      console.warn('Browser does not support output device selection.');
    }


    if (typeof this.audioRef.sinkId !== 'undefined') {

      this.audioRef.setSinkId(deviceId).then(() => {
        console.log(`Success, audio test device attached: ${deviceId}`);
        // this.setState({ audioOutputDevice: deviceId })

      })
        .catch(error => {
          let errorMessage = error;
          if (error.name === 'SecurityError') {
            errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
          }
          console.error(errorMessage);
          // Jump back to first output device in the list as it's the default.
          //this.setState({ selectedAudioOutputIndex: 0 })
        });

    } else {
      console.warn('Browser does not support output device selection.');
    }


  }


  getStream = (reload = false) => {
    try {

      window.AudioContext = window.AudioContext || window.webkitAudioContext;
      this.audioContext = new AudioContext();
    }
    catch (e) {
      alert('Web Audio API not supported.');
    }

    return new Promise((resolve, reject) => {
      if (this.audioVideoStream && !reload) {
        resolve(this.audioVideoStream);
      } else {
        if (this.audioVideoStream) {


          if (this.previewVideo) {
            this.previewVideo.srcObject = null;
          }

          //this.audioVideoStream.getTracks().forEach(track => track.stop());
          if (this.soundMeter)
            this.soundMeter.stop();
          if (this.meterRefresh) {
            clearInterval(this.meterRefresh);
          }

          // this.audioVideoStream.getAudioTracks()[0].stop();
          // this.audioVideoStream.getVideoTracks()[0].stop();
          this.audioVideoStream.getTracks().forEach(track => track.stop());


          for (let i = 0; i < this.tmpStreams.length; i++) {
            try {
              if (this.tmpStreams[i]) {
                this.tmpStreams[i].getTracks().forEach(track => track.stop());
                this.tmpStreams[i] = null;
              }
            } catch (e) { }
          }

        }

        navigator.mediaDevices.getUserMedia(
          { video: this.state.videoInputDevice ? { deviceId: this.state.videoInputDevice, width: { ideal: 1920 }, height: { ideal: 1080 } } : { width: { ideal: 1920 }, height: { ideal: 1080 } }, audio: this.state.audioInputDevice ? { deviceId: this.state.audioInputDevice } : true }).then(
            stream => {

              if (stream && stream.getVideoTracks() && stream.getVideoTracks().length) {
                console.log(stream.getVideoTracks()[0].getSettings());
                if (stream.getVideoTracks()[0].getSettings && stream.getVideoTracks()[0].getSettings().width >= 1920) {
                  this.setState({
                    fullHDSupport: true
                  })
                }
              }

              if (stream) {
                this.setState({
                  notAllowedError: false
                })
              }

              this.tmpStreams.push(stream);

              if (this.previewVideo) {
                this.previewVideo.srcObject = stream;
                this.previewVideo.autoplay = true;
                this.previewVideo.playsInline = true;
                this.previewVideo.muted = true;

              }

              this.audioVideoStream = stream;


              this.soundMeter = new SoundMeter(this.audioContext);
              this.soundMeter.connectToSource(stream, (e) => {
                if (e) {
                  alert(e);
                  return;
                }
                this.meterRefresh = setInterval(() => {
                  this.setState({
                    micMeterValue: this.soundMeter.instant.toFixed(2)
                  })
                }, 200);
              });
              resolve(stream);
            }

          ).catch((err) => {
            // if (err?.name === 'NotAllowedError') {
              this.setState({ notAllowedError: true });
              // return;
            // }
            console.log(err)
          });
      }




    })
  }

  getDevices = () => {
    if (navigator) {
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        console.log('get devices', devices);

        let videoInput = [];
        let audioInput = [];
        let audioOutput = [];
        let audioOutputFilter = []
        let audioInputFilter = []

        let check = false;
        for (let i = 0; i < devices.length; i++) {

          if (devices[i].kind == "audioinput") {
            if (devices[i].deviceId !== 'default' && devices[i].deviceId !== 'communications') {
              audioInputFilter.push(devices[i])
            } else {
              audioInput.push(devices[i]);
            }
            // audioInput.push(devices[i]);
          } else if (devices[i].kind == "audiooutput") {
            if (devices[i].deviceId !== 'default' && devices[i].deviceId !== 'communications') {
              audioOutputFilter.push(devices[i])
            } else {
              audioOutput.push(devices[i]);
            }
          } else if (devices[i].kind == "videoinput") {
            videoInput.push(devices[i]);
          }

          if (devices[i].label && devices[i].deviceId) {
            check = true;
          }
        }


        let stateObj = {
          videoInput,
          audioOutput: audioOutputFilter.length ? audioOutputFilter : audioOutput,
          audioInput: audioInputFilter.length ? audioInputFilter : audioInput,

        }

        if (audioOutputFilter.length) {
          stateObj.audioOutputDevice = audioOutputFilter[0].deviceId;
        }

        this.setState(stateObj, () => {
          console.log(this.state.audioOutputDevice, 'aaaaaaaaaaaaaaaaaaaaaaaa');
          if (audioOutputFilter.length) {
            this.attachSinkId(this.state.audioOutputDevice);
          }

          if (this.state.videoInput.length == 0 && this.state.audioInput.length == 0) {
            this.setState({ shareScreenSource: true })
          }
          if (this.state.audioInput.length == 0) {
            this.setState({ micOff: true })
          }
          if (this.state.videoInput.length == 0) {
            this.setState({ cameraOff: true })
          }
        })

        if (devices.length && this.getDevicesInterval && check) {
          clearInterval(this.getDevicesInterval);
        }

      })
    }
  }

  componentDidMount() {
    this.getStream();
    this.getDevices();

    if (this.props.muteParticipants) {
      this.setState({
        micOff: true
      })
    }

    this.getDevicesInterval = setInterval(() => {
      this.getDevices();
      //this.checkPermissions();
    }, 1000);

    //this.checkPermissions()
  }


  toggleCamera = () => {
    this.setState({
      cameraOff: !this.state.cameraOff
    }, async () => {


      if (this.audioVideoStream) {
        this.audioVideoStream.getVideoTracks().forEach(track => track.enabled = !this.state.cameraOff);
      }
    })
  }

  toggleMic = () => {
    this.setState({
      micOff: !this.state.micOff
    }, async () => {

      if (this.audioVideoStream) {
        this.audioVideoStream.getAudioTracks().forEach(track => track.enabled = !this.state.micOff);
      }
    })
  }


  componentWillUnmount() {
    if (this.audioVideoStream) {
      //this.audioVideoStream.getTracks().forEach(track => track.stop());


      if (this.soundMeter)
        this.soundMeter.stop();
      if (this.meterRefresh) {
        clearInterval(this.meterRefresh);
      }

      if (this.previewVideo) {
        this.previewVideo.srcObject = null;
      }

      // this.audioVideoStream.getAudioTracks()[0].stop();
      // this.audioVideoStream.getVideoTracks()[0].stop();
      this.audioVideoStream.getTracks().forEach(track => track.stop());


      for (let i = 0; i < this.tmpStreams.length; i++) {
        try {
          if (this.tmpStreams[i]) {
            this.tmpStreams[i].getTracks().forEach(track => track.stop());
            this.tmpStreams[i] = null;
          }
        } catch (e) { }
      }

    }
  }


  render() {

    var isSafari = typeof window !== 'undefined' && /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    return (
      <div className="web-rtc-test">
        <div>
          <Row>
            <Col lg="8">
              {this.state.videoInput && this.state.videoInput.length ?
                <FormGroup>
                  <Label>{'Video source'.translate(this.props.lang)}</Label>
                  <div>
                    <Input type="select" value={this.state.videoInputDevice} onChange={(e) => this.setState({ videoInputDevice: e.target.value }, () => this.getStream(true))}>
                      {
                        this.state.videoInput ?
                          this.state.videoInput.map((item) => {
                            return <option value={item.deviceId}>{item.label}</option>
                          })
                          :

                          null
                      }
                    </Input>
                    <Isvg src={cameraIcon} />
                  </div>
                </FormGroup>
                :
                null
              }
              {this.state.videoInput && this.state.videoInput.length ?
                <FormGroup>
                  <Label>{'Video resolution (maximum)'.translate(this.props.lang)}</Label>
                  <div>
                    <Input type="select" value={this.state.videoResolution} onChange={(e) => this.setState({ videoResolution: e.target.value }, () => this.getStream(true))}>
                      <option value={'480'}>{'Standard definition'.translate(this.props.lang)}</option>
                      <option value={'720'}>{'High definition'.translate(this.props.lang)}</option>
                      {/* <option disabled={this.props.participantsInVideoCall >= 10 || !this.state.fullHDSupport} value={'1080'}>{'Full high definition'}</option> */}
                    </Input>
                    <Isvg src={cameraIcon} />
                  </div>
                </FormGroup>
                :
                null
              }

              {this.state.audioInput && this.state.audioInput.length ?

                <FormGroup>
                  <Label>{'Audio source'.translate(this.props.lang)}</Label>
                  <div>
                    <Input type="select" value={this.state.audioInputDevice} onChange={(e) => this.setState({ audioInputDevice: e.target.value }, () => this.getStream(true))}>
                      {

                        this.state.audioInput ?
                          this.state.audioInput.map((item) => {
                            // console.log(item, 'audioInput');
                            return <option value={item.deviceId}>{item.label}</option>
                          })
                          :

                          null
                      }
                    </Input>
                    <Isvg src={micIcon} />

                  </div>
                </FormGroup>
                :
                null
              }
              {this.state.audioOutput && this.state.audioOutput.length ?

                <FormGroup>
                  <Label>{'Audio output'.translate(this.props.lang)}</Label>
                  <div>
                    <Input type="select" onChange={(e) => this.attachSinkId(e.target.value)} value={this.state.audioOutputDevice}>
                      {

                        this.state.audioOutput ?
                          this.state.audioOutput.map((item, idx) => {
                            return <option value={item.deviceId}>{item.label}</option>
                          })
                          :
                          null
                      }
                    </Input>
                    <Isvg src={speakerIcon} />

                  </div>
                </FormGroup>
                :
                null
              }
              {
                <div>
                  <audio ref={(node) => this.audioRef = node} >
                    <source src={notificationSound} type="audio/ogg"></source>
                    <source src={notificationSound} type="audio/mpeg"></source>
                  </audio>
                </div>
              }

              {typeof window !== 'undefined' && !detectMob() ?

                <FormGroup>
                  <Checkbox label={typeof window !== 'undefined' && iosDevice() ? 'Remove background'.translate(this.props.lang) : 'Remove bg'.translate(this.props.lang)} onChange={(e) => this.setState({ removeBg: e.target.checked }, () => {
                    const endpoint = typeof window != 'undefined' && window.innerWidth < 768 ? '/data-managment/background-video/images/pick-mobile' : '/data-managment/background-video/images/pick'
                    fetch(API_ENDPOINT + endpoint, {
                      method: 'GET',
                      headers: {
                        'content-type': 'application/json'
                      },
                    }).then(parseJSON).then(({ result, status }) => {
                      if (status >= 200 && status < 300) {
                        if (result && result.gallery) {
                          this.setState({
                            libraryIcons: result.gallery,
                            loadLibrary: false
                          })
                        } else {
                          this.setState({
                            libraryIcons: [],
                            loadLibrary: false
                          })
                        }


                      }

                    })
                  })} checked={this.state.removeBg}></Checkbox>
                </FormGroup>
                :
                null
              }
              {/* :
                null
              } */}

              {
                this.state.removeBg && (typeof window !== 'undefined' && !detectMob()) ?
                  this.state.libraryIcons && this.state.libraryIcons.length ?
                    <div className="icon-library-wrap-webrtc">
                      <div className="icon-items">
                        {
                          this.state.libraryIcons && this.state.libraryIcons.map((icon, idx) => {
                            return (
                              <div onClick={() => {
                                this.setState({
                                  backgroundVideoImage: `${API_ENDPOINT + icon}`,
                                  // libraryIcons: []
                                })
                              }} style={this.state.backgroundVideoImage == `${API_ENDPOINT + icon}` ? { border: '2px solid #000' } : {}} className="icon-item">
                                <img src={API_ENDPOINT + icon} />

                              </div>
                            )
                          })
                        }

                      </div>
                    </div>
                    :
                    <div className="empty-array">
                      {/* <h6>{'No icons uploaded'.translate(this.props.lang)}</h6> */}
                    </div>
                  :
                  null
              }


            </Col>
            <Col lg="4" className="preview-content">
              <video ref={(node) => this.previewVideo = node} autoPlay playsInline />
              {
                this.state.audioInput && this.state.audioInput.length ?

                  <div className="audio-meter-wrap">

                    <Isvg src={micIcon} />

                    <div className="audio-meter"><div style={{ width: (this.state.micMeterValue * 100) + '%' }}></div></div>
                    
                   

                  </div>
                  :
                  null
              }
                  <div className="control-buttons test-sound-button">
                    {
                      <button onClick={() => {
                        this.audioRef.play()
                      }} className={"camera-button off"}><Isvg src={soundTest} />   {'Test sound'.translate(this.props.lang)}</button>

                    }
                    </div>
              <div className="control-buttons">
                {typeof window !== 'undefined' && !detectMob() ?
                  <button onClick={() => {
                    this.setState({ shareScreenSource: !this.state.shareScreenSource })
                  }} className={!this.state.shareScreenSource ? "camera-button off" : "camera-button"}>{this.state.shareScreenSource ? <Isvg src={screenShareIcon} /> : <Isvg src={screenShareOffIcon} />}  <div className="chat-icons-tooltip-off">{'Turn off share screen'.translate(this.props.lang)}</div> <div className="chat-icons-tooltip-on">{'Turn on share screen'.translate(this.props.lang)}</div> </button> : null}
                {
                  this.state.videoInput && this.state.videoInput.length ?
                    <button onClick={() => {
                      if (this.state.videoInput && this.state.videoInput.length) {
                        this.toggleCamera()
                      }
                    }} className={this.state.cameraOff ? "camera-button off" : "camera-button"}>{this.state.cameraOff ? <Isvg src={cameraOffIcon} /> : <Isvg src={cameraIcon} />}  <div className="chat-icons-tooltip-off">{'Turn off camera'.translate(this.props.lang)}</div> <div className="chat-icons-tooltip-on">{'Turn on camera'.translate(this.props.lang)}</div> </button>
                    :
                    null
                }

                {
                  this.state.audioInput && this.state.audioInput.length ?
                    <button onClick={() => {
                      if (this.state.audioInput && this.state.audioInput.length) {
                        this.toggleMic()
                      }
                    }} className={this.state.micOff ? "mic-button off" : "mic-button on"}>{this.state.micOff ? <Isvg src={micMutedIcon} /> : <Isvg src={micIcon} />} <div className="chat-icons-tooltip-off">{'Turn off microphone'.translate(this.props.lang)}</div> <div className="chat-icons-tooltip-on">{'Turn on microphone'.translate(this.props.lang)}</div></button>
                    :
                    null
                }



              </div>
            </Col>
            <Col lg="12" className='join-video-button-container'>
              {this.state.notAllowedError ? <div>
                {'You have not authorized this website to access you microphone and/or camera! Please reset you permissions to allow access for microphone and camera to this website, by going to settings or clicking on lock icon above, and then clicking on "Reset Permissions" button, and then try again.'.translate(this.props.lang)}
              </div> : null}
              <Button onClick={() => {
                if (this.audioVideoStream) {
                  //this.audioVideoStream.getTracks().forEach(track => track.stop());


                  if (this.soundMeter)
                    this.soundMeter.stop();
                  if (this.meterRefresh) {
                    clearInterval(this.meterRefresh);
                  }

                  if (this.previewVideo) {
                    this.previewVideo.srcObject = null;
                  }

                  this.audioVideoStream.getTracks().forEach(track => track.stop());

                  // this.audioVideoStream.getAudioTracks()[0].stop();
                  // this.audioVideoStream.getVideoTracks()[0].stop();

                  for (let i = 0; i < this.tmpStreams.length; i++) {
                    try {
                      if (this.tmpStreams[i]) {
                        this.tmpStreams[i].getTracks().forEach(track => track.stop());
                        this.tmpStreams[i] = null;
                      }
                    } catch (e) { }
                  }

                }

                this.props.callback(this.state.videoInputDevice, this.state.audioInputDevice, this.state.audioOutputDevice, this.state.cameraOff, this.state.micOff, this.state.shareScreenSource, this.state.videoResolution, this.state.removeBg, this.state.backgroundVideoImage, this.state.fullHDSupport)
              }
              } disabled={this.state.notAllowedError}>{'Join'.translate(this.props.lang)}</Button>
            </Col>
          </Row>

        </div>
        {/* {
          this.state.libraryIcons && this.state.libraryIcons.length ?
            <Modal isOpen={this.state.libraryIcons} size='xl' centered className="icon-library-wrap">
              <ModalHeader>
                {'Background video images'.translate(this.props.lang)}
                <div style={{ cursor: 'pointer' }} onClick={() => {
                  this.setState({ libraryIcons: [] })
                }}> &times;</div>
              </ModalHeader>

              <ModalBody>
                {
                  this.state.libraryIcons && this.state.libraryIcons.length ?
                    <div className="icon-items">
                      {
                        this.state.libraryIcons && this.state.libraryIcons.map((icon, idx) => {
                          return (
                            <div onClick={() => {
                              this.setState({
                                backgroundVideoImage: `${API_ENDPOINT + icon}`,
                                libraryIcons: []
                              })
                            }} className="icon-item">
                              <img src={API_ENDPOINT + icon} />

                            </div>
                          )
                        })
                      }

                    </div>
                    :
                    <div className="empty-array">
                      <h6>{'No icons uploaded'.translate(this.props.lang)}</h6>
                    </div>

                }
              </ModalBody>

            </Modal>
            :
            null
        } */}
      </div >

    )
  }
}

export default WebRTCTest
