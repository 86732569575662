/**
* Validation
* @author   Milan Stanojevic
*/
export const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined
export const number = value => value && isNaN(Number(value)) ? 'Must be a number' : undefined
export const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined
export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
    'Invalid email address' : undefined
export const phoneNumber = (min, message) => value =>
  value && value.length < min ? message : undefined

export const emailAddress = (message) => value => value && !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i.test(value) ? message : undefined

export const validateStrongPassword = (message) => value => value && !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$/i.test(value) ? message : undefined

export const required = (message = 'Required') => {
  return (value) => value ? undefined : message;
}

export const fileUploading = (message = 'Required') => {
  return (value) => value == 'loading' || value == undefined ? message : undefined;
}

export const fileUploadingRequired = (message = 'Required') => {
  return (value) => value == 'loading' ? message : value ? undefined : message;
}
export const requiredNumber = (message = 'Required', number) => {
  return (value) => value && Number(value) > Number(number) ? undefined : message;
}

export const vatNumberValidation = (message = 'Vat Number is not valid') => {
  return (value) => {
    if (!value) {
      return undefined;
    }

    if (value) {
      if (!/^[A-Za-z0-9]*$/.test(value)) {
        return message;
      } else {
        if (value.length < 2 || (value.length >= 2 && !/[a-zA-Z]{2}/.test(value.substr(0, 2)))) {
          return message;
        }
        if (value.length < 6) {
          return message;
        }

        return undefined
      }
    }

  };
}