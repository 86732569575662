import React from 'react'
import reactCSS from 'reactcss'
import {
    Container,
    Row,
    Col,
    FormGroup, Label
} from 'reactstrap';
import Checkbox from './checkbox';

/**
* Custom checkbox
* @author   Milan Stanojevic
*/
class CustomCheckbox extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }



    render() {
        // console.log(this.props.disabled)

        return (
            <FormGroup>
                {/*{this.props.label ? <Label>{this.props.label}</Label> : null}*/}
                <Checkbox onChange={(e) => {
                    if (!this.props.disabled)
                        this.props.onChange(e.target.checked);
                }} checked={this.props.value} label={this.props.label ? <Label style={{marginBottom:0}} check={this.props.type == 'checkbox'} size="sm" className={this.props.error ? "required" : ""}>{this.props.label ? this.props.label : this.props.afterText ? this.props.afterText : ''}{this.props.haveValidation ? <span className="required-label-span">*</span> : null}</Label> : null} disabled={this.props.disabled} />
               
                {/*
                <div className="checkbox-container move-left ">

                    <input onChange={this.props.onChange} checked={this.props.value} type="checkbox" />
                    {this.props.label ? <Label>{this.props.label}</Label> : null}
                    {this.props.afterText ? <Label>{this.props.afterText}</Label> : null}
                    <Label className="custom-checkbox-small"></Label>
            </div>*/}
            </FormGroup>

        )
    }
}

export default CustomCheckbox