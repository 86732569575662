
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    FormGroup, Label
} from 'reactstrap';

import { Editor } from '@tinymce/tinymce-react';
import { API_ENDPOINT } from '../../../constants'
/**
* Component for html field
* @author   Milan Stanojevic
*/
class HtmlImage extends Component {
    constructor(props) {
        super(props);
        this.selectFile = this.selectFile.bind(this);

        this.state = {

        };
    }

    selectFile(e) {
        let input = e.target;
        if (input.files && input.files[0]) {
            var reader = new FileReader();

            reader.onload = (e) => {
                this.props.onChange({
                    type: 'image',
                    value: e.target.result
                })
            }

            reader.readAsDataURL(input.files[0]);
        }
    }

    render() {
        // console.log(this.props)
        return (
            <FormGroup>
                {this.props.label ? <Label>{this.props.label}</Label> : null}

                <Editor
                    disabled={this.props.disabled}
                    apiKey="lweh8ghdiwccyg0ogbblrkzamnybbrxoay2b7ontj3tusq7c"
                    init={{ plugins: 'link table code print image', height: this.props.height ? this.props.height : 500, content_css: '/content.css' }}
                    init={{
                        plugins: 'link table code print image', height: this.props.height ? this.props.height : 500, content_css: '/content.css', images_upload_url: `${API_ENDPOINT}/upload/image/tinymceUpload`, image_advtab: true, file_picker_types: 'file image media', file_picker_callback: function (callback, value, meta) {
                            // Provide file and text for the link dialog
                            if (meta.filetype == 'file') {
                                callback('mypage.html', { text: 'My text' });
                            }

                            // Provide image and alt text for the image dialog
                            if (meta.filetype == 'image') {
                                callback('myimage.jpg', { alt: 'My alt text' });
                            }

                            // Provide alternative source and posted for the media dialog
                            if (meta.filetype == 'media') {
                                callback('movie.mp4', { source2: 'alt.ogg', poster: 'image.jpg' });
                            }
                        }
                    }}
                    value={this.props.multilang ? (this.props.value && this.props.value[this.props.lang]) ? this.props.value[this.props.lang] : '' : this.props.value}
                    onEditorChange={(val) => {

                        if (this.props.multilang) {
                            let value = this.props.value;
                            if (!value) {
                                value = {};
                            }
                            value[this.props.lang] = val;

                            this.props.onChange(value);
                        } else {

                            this.props.onChange(val);
                        }
                        this.forceUpdate();


                    }} />

            </FormGroup>




        );
    }
}

export default HtmlImage;