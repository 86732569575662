import React, { Component } from 'react';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom'
import notification from '../assets/svg/notification.svg';
import moreIcon from '../assets/svg/more.svg';
import info from '../assets/svg/info.svg'
import profileUser from '../assets/images/user.png';
import { API_ENDPOINT } from '../constants';


/**
* Checks if there is a new notification
* @author Stefan Pantic
*/

import {
    Container,
    Row,
    Col, Input, Label, Modal, ModalBody, ModalHeader, FormGroup, Button
} from 'reactstrap';
import ModalFooter from 'reactstrap/lib/ModalFooter';

export class Notification extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }


    componentDidMount() {
        // this.interval = setInterval(() => {
        //     this.check();
        // }, 5000);

        if (this.props.uData && this.props.uData.lastNotifications) {
            let count = 0
            for (let i = 0; i < this.props.uData.lastNotifications.length; i++) {
                if (!this.props.uData.lastNotifications[i].seen) {
                    count++
                }
            }

            this.setState({
                notifCount: count
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.uData && prevProps.uData && this.props.uData.lastNotifications != prevProps.uData.lastNotifications) {
            if (this.props.uData && this.props.uData.lastNotifications) {
                let count = 0
                for (let i = 0; i < this.props.uData.lastNotifications.length; i++) {
                    if (!this.props.uData.lastNotifications[i].seen) {
                        count++
                    }
                }

                this.setState({
                    notifCount: count
                })
            }
        }
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval)
        }
    }

    /**
    * Checks if there is a new notification for logged user
    * @author   Stefan pantic
    */
    check = () => {
        fetch(API_ENDPOINT + '/notification/last', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },

        }).then(res => res.json()).then((result) => {

            if (result) {
                if (localStorage.getItem('notif') != result._id) {
                    this.props.verifyUser()
                    this.setState({
                        newNotif: true
                    })

                } else {
                    this.setState({
                        newNotif: false
                    })
                }
            }

        })


    }


    render() {
        if (this.props[0].match.path == '/:alias' || this.props[0].match.path == '/:mainAlias/:alias' || this.props[0].match.path == '/:mainAlias/:alias/information' || this.props[0].match.path == '/:mainAlias/:alias/login' || this.props[0].match.path == '/video-call/:conversation' || this.props[0].match.path == '/:alias/login' || this.props[0].match.path == '/my-data/clinic-data/landing-configurator/:id/:tab/:group/:type' || this.props[0].match.path == '/data-managment/landing-configurator/:tab/:id/:type' || this.props[0].match.path == '/:alias/information' || !this.props.uData || this.props[0].match.path == '/:alias/privacy-policy' || this.props[0].match.path == '/:alias/terms-of-use' || this.props[0].match.path == '/:alias/accessibility-report' || this.props[0].match.path == '/chat/:windowConversation') {
            return (
                <>
                    {
                        this.props.logoutMessage ?
                            <Modal isOpen={this.props.logoutMessage} centered >
                                <ModalHeader style={{ margin: 'auto' }}>{'Notification'.translate(this.props.lang)}</ModalHeader>

                                <ModalBody className='delete-modal'>
                                    <img src={info} />
                                    <p className='message'>{'You have been logged out due to inactivity.'.translate(this.props.lang)}</p>
                                </ModalBody>
                                <ModalFooter className='buttons-right-blue'>
                                    <Button color="primary" style={{ float: 'right' }} onClick={() => this.props.disableLogoutMessage()}>{"Ok".translate(this.props.lang)}</Button>

                                </ModalFooter>
                            </Modal>

                            :
                            null
                    }
                </>
            );
        }
        return (
            <div className='component notifications'>
                <Link to='/notifications'>
                    <div className="nofitication-container">

                    </div>
                </Link>
                <div className='component-header-right'>
                    <div className='host-meeting'>
                        {this.props.uData.host ?
                            <Link className="component-link" to={{ pathname: '/users/schedule-meeting', state: { form: true } }}>{'Schedule meeting'.translate(this.props.lang)}</Link>
                            :
                            <Link className="component-link" to='/users/schedule-meeting'>{'Schedule meeting'.translate(this.props.lang)}</Link>
                        }
                    </div>
                    <div className='join-a-meeting'>
                        <button className="component-link" onClick={() => { this.setState({ joinConversationModal: true }) }}>{'Join a meeting'.translate(this.props.lang)}</button>
                    </div>

                    <div className='profile'>

                        <Link check={this.props.groupSettingsModal} openModal={(item) => this.props.openModal(item)} onClick={() => this.setState({ dropdownMenu: !this.state.dropdownMenu })}>
                            {
                                this.props.uData.userData && this.props.uData.profileType ? this.props.uData.profileType == 'personal' ?
                                    <span className='name'>{this.props.uData && this.props.uData.userData.name && this.props.uData.userData.title ? this.props.uData.userData.name + " " + this.props.uData.userData.title : this.props.uData.name}</span>
                                    :
                                    <span className='name' >{this.props.uData && this.props.uData.userData && this.props.uData.userData.name ? this.props.uData.userData.name : this.props.uData && this.props.uData.userData && this.props.uData.userData.companyName ? this.props.uData.userData.companyName : ''}</span>
                                    :
                                    <span className='name' >{this.props.uData ? this.props.uData.username : null}</span>
                            }
                            <img src={this.props.uData && this.props.uData.profilePicture ? API_ENDPOINT + this.props.uData.profilePicture : profileUser} className="profile-image" />
                            <Isvg src={moreIcon} />
                            <ul className={this.state.dropdownMenu ? 'notification-dropdown dropdown-opened' : 'notification-dropdown'} ref={node => this.wrapperRef = node}>
                                <li>
                                    <Link to="/my-data/account">{'My profile'.translate(this.props.lang)}</Link>
                                </li>
                                <li>
                                    <a onClick={() => {
                                        this.props.signOut()
                                        this.props.abortAction()
                                        this.props.registerLog('Logged out')
                                    }
                                    }>
                                        {'Logout'.translate(this.props.lang)}
                                    </a>
                                </li>
                            </ul>
                        </Link>

                    </div>
                </div>

                {
                    this.props.logoutMessage ?
                        <Modal isOpen={this.props.logoutMessage} centered >
                            <ModalHeader style={{ margin: 'auto' }}>{'Notification'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody className='delete-modal'>
                                <img src={info} />
                                <p className='message'>{'You have been logged out due to inactivity.'.translate(this.props.lang)}</p>
                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button color="primary" style={{ float: 'right' }} onClick={() => this.props.disableLogoutMessage()}>{"Ok".translate(this.props.lang)}</Button>

                            </ModalFooter>
                        </Modal>

                        :
                        null
                }

                {
                    this.state.joinConversationModal ?
                        <Modal isOpen={this.state.joinConversationModal} size="lg" centered>
                            <ModalHeader close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ joinConversationModal: !this.state.joinConversationModal })}>&times;</button>}>{'Enter meeting code'.translate(this.props.lang)}</ModalHeader>
                            <ModalBody>

                                <Input value={this.state.joinConversationIDNumber} placeholder={'Code'.translate(this.props.lang)} onChange={(e) => {

                                    this.setState({
                                        joinConversationIDNumber: e.target.value,
                                    })
                                }} />


                            </ModalBody>
                            <ModalFooter className='buttons-right-green'>

                                <Button onClick={() => {
                                    this.findConversation(this.state.joinConversationIDNumber)
                                }} >{'Join'.translate(this.props.lang)}</Button>

                                <Button onClick={() => this.setState({ joinConversationModal: !this.state.joinConversationModal })}>{'Close'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        :
                        null
                }
            </div>
        )
    }
}

export default Notification;