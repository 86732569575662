import React, { Component } from 'react';
import Link from '../components/link';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';


import FormBuilder from '../components/forms/modalFormBuilder';
import { required } from '../components/forms/validation';

import ListBuilder from '../components/listBuilder';
import DeleteModal from '../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../helpers/linkHelper';
import { API_ENDPOINT } from '../constants';


var striptags = require('striptags');

/**
* Home page for patient if he doesnt have any referrals
* @author   Stefan Pantic
*/
class HomePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            promotedProjects: [],
            ...props.initialData,
            form: false,

            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
        };
    }

    componentDidMount() {
        this.props.signOut(true);
        this.props.verifyUser()
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        
        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }
    }



    toggle = () => {
        this.setState({
            form: !this.state.form
        })
    }



    render() {
        let params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        //console.log(params);
        return (
            <div className="dashboard">
                <h1>{'Du har inte aktiv konto hos oss! För inloggning som patient använd aktuell klinik landningssida…'.translate(this.props.lang)}</h1>

            </div>

        );


    }
}

export default Page(HomePage);