import React, { Component } from 'react';
import Link from '../components/link';
import { connect } from 'react-redux';
import { API_ENDPOINT } from "../constants";
import InfoModal from '../../src/components/infoModal';
import Checkbox from './forms/fields/checkbox';
import logoFooter from '../assets/images/logoFooter.png';

function parseJSON(response) {
    return response.json().then((json) => {
        return {
            result: json,
            status: response.status,
        };
    });
}

import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Label

} from 'reactstrap';

import mark from '../assets/images/mark.png';
import Brev from '../assets/images/brev@2x.png'
import logo from '../assets/svg/logo_video.svg';
import Fb from '../assets/images/facebook.png'
import Ints from '../assets/images/instagram.png'
import Ln from '../assets/images/ln.png'

export class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            privacyPolicyCheckbox: false,
            error: false,
            errorPrivacy: false
        };
    }

    componentDidMount() {
        // fetch(API_ENDPOINT + '/data/pages/all-footer', {
        //     method: 'GET',
        //     headers: {
        //         'content-type': 'application/json'
        //     },
        // }).then(parseJSON).then(({ result, status }) => {
        //     this.setState({
        //         footerInfo: result
        //     })
        // })
    }

    sendNewLetter = (data) => {
        // console.log(data)
        fetch(API_ENDPOINT + '/data/newsletter/new', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ email: data })
        }).then(parseJSON).then(({ result, status }) => {
            if (status != 200) {
                this.setState({
                    error: result.error ? result.error.translate(this.props.lang) : '',
                    newsInfo: false,
                    privacyPolicyCheckbox: !this.state.privacyPolicyCheckbox,
                    errorPrivacy: false
                })
            } else {
                this.setState({
                    error: false,
                    newsInfo: true,
                    email: '',
                    privacyPolicyCheckbox: !this.state.privacyPolicyCheckbox,
                    errorPrivacy: false
                })
            }
        })

    }

    updateLanguage = (data) => {
        this.setState({
            loadLang: true
        }, () => {
            fetch(API_ENDPOINT + "/users/account/update-userdata", {
                method: "POST",
                headers: {
                    Authorization: typeof localStorage !== "undefined" ? `Bearer ${localStorage.getItem("authToken")}` : null,
                    "content-type": "application/json",
                },
                body: JSON.stringify(data),
            }).then(parseJSON).then(({ result, status }) => {
                if (result.error) {
                    this.setState({ error: result.error });
                } else {
                    this.setState({
                        message: result.message
                    });
                    this.props.verifyUser();
                }
            });
        }
        );
    };

    checkPrivacyPolisy = () => {
        if (this.state.privacyPolicyCheckbox) {
            this.sendNewLetter(this.state.email)
        } else {
            this.setState({
                error: false,
                errorPrivacy: 'You must accept our Privacy Policy in order to continue.'.translate(this.props.lang)

            })
        }
    }






    render() {
        // console.log(this.state.footerInfo)
        return (
            <div>
                <footer className='footer'>
                    <div className="footer-content1">
                        <div className="footer-left">
                            <Link to="/">
                                <img src={logoFooter} className='logo-footer' />
                            </Link>
                            <span>{'Email'.translate(this.props.lang)}:</span>
                            <p>video-contact@curoflow.se</p>
                            <span>{'Telephone'.translate(this.props.lang)}:</span>
                            <p>+46 (0)10 - 750 06 55 </p>
                        </div>
                        <div className="footer-right">
                            <div className="subscribe-container">
                                {/* <h6>{'The latest'.translate(this.props.lang)}</h6> */}
                                <p>{'Subscribe to our newsletter'.translate(this.props.lang)}</p>
                                <div>
                                    <input placeholder={'Your email'.translate(this.props.lang)} value={this.state.email} onChange={(e) => {

                                        this.setState({
                                            email: e.target.value
                                        })

                                    }} />

                                    <div className='subscribe'>
                                        <button onClick={() => this.checkPrivacyPolisy()}>{'Subscribe'.translate(this.props.lang)}</button>
                                        <div className='sub-text'>


                                            <Checkbox checked={this.state.privacyPolicyCheckbox}
                                                onChange={(e) => {
                                                    this.setState({
                                                        privacyPolicyCheckbox: !this.state.privacyPolicyCheckbox,
                                                        errorPrivacy: false
                                                    })
                                                }}
                                                label={'I accept the website'.translate(this.props.lang)} />
                                            <Link lang={this.props.lang} to="/integritetspolicy-webbsida">{'privacy policy'.translate(this.props.lang)}</Link>
                                        </div>
                                    </div>

                                    {
                                        this.state.error ?
                                            <p className='error-newsletter-message'>{this.state.error}</p>
                                            :
                                            null

                                    }
                                    {
                                        this.state.errorPrivacy ?
                                            <p className='error-newsletter-message'>{this.state.errorPrivacy}</p>
                                            :
                                            null

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-content2">
                        <div className="content">
                            <h6>{'Navigate'.translate(this.props.lang)}</h6>
                            <ul className="footer-list">
                                <li>
                                    <Link to="/">{'Home'.translate(this.props.lang)}</Link>
                                </li>
                                <li>
                                    <Link to="/plans-for-secure-video-meetings">{'Choose plan'.translate(this.props.lang)}</Link>
                                </li>
                                <li>
                                    <Link to="/technical-requirements">{'System requirements'.translate(this.props.lang)}</Link>
                                </li>
                                <li>
                                    <Link to="/contact">{'Contact us'.translate(this.props.lang)}</Link>
                                </li>
                                <li>
                                    <Link to="/gdpr-compilance-in-secure-video-meetings">{'GDPR'.translate(this.props.lang)}</Link>
                                </li>
                            </ul>
                        </div>

                        <div className="content">
                            <h6>{'Video service'.translate(this.props.lang)}</h6>
                            <ul className="footer-list">
                                <li>
                                    <Link lang={this.props.lang} to="/integritetspolicy-videotjanst">{'Privacy policy'.translate(this.props.lang)}</Link>
                                </li>
                                {/* 
                                <li>
                                    <Link lang={this.props.lang} to="/cookies">{'Cookies'.translate(this.props.lang)}</Link>
                                </li>
                                */}
                                <li>
                                    <Link lang={this.props.lang} to="/termsandconditions">{'Terms and conditions'.translate(this.props.lang)}</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="content">
                            <h6>{'Website'.translate(this.props.lang)}</h6>
                            <ul className="footer-list">
                                <li>
                                    <Link lang={this.props.lang} to="/integritetspolicy-webbsida">{'Privacy policy'.translate(this.props.lang)}</Link>
                                </li>
                                <li>
                                    <Link lang={this.props.lang} to="/website/cookies">{'Cookies'.translate(this.props.lang)}</Link>
                                </li>

                                {/* 
                            <li>
                                <Link lang={this.props.lang} to="/termsandconditions">{'Terms and Conditions'.translate(this.props.lang)}</Link>
                            </li>
                            */}
                            </ul>
                        </div>
                        <div className="content">
                            <h6>{'Language'.translate(this.props.lang)}</h6>
                            <ul className="footer-list">
                                <li>
                                    <button className='footer-lan' onClick={() => {
                                        this.props.setLang('se')
                                        if(this.props.uData){
                                            this.updateLanguage({ language: 'se' });
                                        }
                                    }}>{'Svenska'.translate(this.props.lang)}</button>
                                </li>

                                <li>
                                    <button className='footer-lan' onClick={() => {
                                        this.props.setLang('en')
                                        if(this.props.uData){
                                            this.updateLanguage({ language: 'en' });
                                        }
                                    }}> {'English'.translate(this.props.lang)}</button>
                                </li>

                            </ul>
                        </div>
                        <div className="content">
                            <h6 className='social-linked'>{'Social media'.translate(this.props.lang)}</h6>
                            <ul className="footer-list">
                                <li>
                                    {/*}  <a href="https://www.facebook.com/Curoflow/" className="social-link" target="_blank"><img src={Fb} /> </a>
                            <a href="https://www.instagram.com/curoflow/" className="social-link" target="_blank"><img src={Ints}/> </a>
                            */}
                                    <a href="https://www.linkedin.com/company/curoflow-video/" className="social-link" target="_blank"><img src={Ln} /> </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="footer-content3">
                        <div className="footer-bottom1">
                            <p>{'Curoflow Video is a Swedish-developed video solution that stores data on dedicated servers in Sweden. All data is encrypted, stored and processed in accordance with the GDPR.'.translate(this.props.lang)}</p>
                        </div>
                        <div className='footer-copyright'>
                            <p>{`Copyright © ${(new Date()).getFullYear()} Curoflow Technology AB`.translate(this.props.lang)}</p>
                        </div>
                    </div>
                </footer>

                {
                    this.state.newsInfo ?
                        <InfoModal
                            isOpen={this.state.newsInfo}
                            toggle={() => this.setState({ newsInfo: !this.state.newsInfo })}
                            image={Brev}
                            header={'Info'.translate(this.props.lang)}
                            info={'Thank you for signing up for our newsletter'.translate(this.props.lang)}
                            buttons={[
                                <Button className='modal-button' onClick={() => {
                                    this.setState({
                                        newsInfo: false

                                    })

                                }}>{'Close'.translate(this.props.lang)}</Button>

                            ]}

                        />
                        :
                        null
                }

            </div>

        )

    }
}

export default Footer;